import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../_Sections/SectionDetails';
import SectionGallery from '../_Sections/SectionGallery';
import SectionDropship from '../_Sections/SectionDropship';
import SectionPairings from '../_Sections/SectionPairings';
import SectionBranding from '../_Sections/SectionBranding';
import SectionSizes from '../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../_Sections/SectionPricing';
import tKey from '../../Common/Utils/tKey';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import ProductSEO from '../_Sections/ProductSEO';
import { BlackCheckedIcon } from '../../Common/_components/CheckBoxes';

const BackingOptions = styled('div')(({ theme }) => ({
  background: 'black',
  padding: '120px 0',
  color: 'white',
  'h3.textwhite, p': {
    color: 'white',
    textAlign: 'center',
  }
}))

const PRODUCT_ID = 123;
const VIEW_PRODUCT_ID = 144;

export default function VeganLeatherBackpackPage() {
  const [t] = useTranslation('page/products/vegan-leather-backpack');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Vegan Leather Backpacks | Products')}
      description={t('Design and create your own custom printed Vegan Leather Backpacks featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#abd76f"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '48%',
      }}
      tag={t('The all-over print')}
      title={t('Vegan Leather Backpacks')}
      text={t('Get ready to meet our envy-worthy vegan leather backpack! Made from premium smooth vegan leather, you can print anything on this backpack. Made on-demand, each backpack features metal hardware, full lining, and adjustable straps. Design your own vegan leather backpack that is guaranteed to turn heads!')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/vegan-leather-backpack/banner-1-' + locale + '.png')}
      alt={t('Picture of Vegan Leather Backpack')}
      details={[
        {
          img: require('@resources/img/pages/products/vegan-leather-backpack/icon-1.svg?url'),
          alt: t('Vegan Leather Fabric icon'),
          text: t('Made from premium smooth vegan leather fabric.'),
        },
        {
          img: require('@resources/img/pages/products/vegan-leather-backpack/icon-2.svg?url'),
          alt: t('Backpack icon'),
          text: t('All-over print on backpack front. Choose from brown or black colorway.'),
        },
        {
          img: require('@resources/img/pages/products/vegan-leather-backpack/icon-3.svg?url'),
          alt: t('Metal hardware icon'),
          text: t('Metal hardware in your choice of antique gold (on brown) or gunmetal silver (on black)'),
        },
        {
          img: require('@resources/img/pages/products/vegan-leather-backpack/icon-4.svg?url'),
          alt: t('Buckle icon'),
          text: t('Removable shoulder pad and adjustable buckle straps.'),
        },
        {
          img: require('@resources/img/pages/products/vegan-leather-backpack/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/vegan-leather-backpack/gallery_0.jpg'),
      require('@resources/img/pages/products/vegan-leather-backpack/gallery_1.jpg'),
      require('@resources/img/pages/products/vegan-leather-backpack/gallery_2.jpg'),
      require('@resources/img/pages/products/vegan-leather-backpack/gallery_3.jpg'),
      require('@resources/img/pages/products/vegan-leather-backpack/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Vegan Leather <br/>Backpack <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Vegan Leather Backpack! At prices designed for reselling, Vegan Leather Backpack made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
    />

    <SectionSizes
      productId={PRODUCT_ID}
      title={t('Vegan Leather Backpack Sizing')}
      text={t('All measurements are approximate. Since our products are made by hand, there may be variations in the finished size of our products between orders or even within an order.')}
    >
      <img
        src={require('@resources/img/pages/products/vegan-leather-backpack/measurement.svg?url')}
        style={{
          marginRight: 0,
        }}
      />
    </SectionSizes>

    <BackingOptions>
      <Container maxWidth="md">
        <Stack spacing={2} alignItems="center" textAlign="center">
          <Stack spacing={-4}>
            <Typography
              variant="h1-accent"
              component="h3"
            >{t('Choose from')}</Typography>
            <Typography
              variant="h1"
              component="h3"
              className="textwhite"
            >{t('two back color options')}</Typography>
          </Stack>
        </Stack>
        <Grid container spacing={4}>
          <Grid item sm={6}>
            <img
              src={require('@resources/img/pages/products/vegan-leather-backpack/option1.png')}
              style={{
                width: '100%',
                marginTop: '60px',
                marginBottom: '15px',
              }}
            />
            <Typography
              variant="h4"
              component="h3"
              className="textwhite"
            >{t('Black / Gunmetal Silver')}</Typography>
          </Grid>
          <Grid item sm={6}>
            <img
              src={require('@resources/img/pages/products/vegan-leather-backpack/option2.png')}
              style={{
                width: '100%',
                marginTop: '60px',
                marginBottom: '15px',
              }}
            />
            <Typography
              variant="h4"
              component="h3"
              className="textwhite"
            >{t('Brown / Antique Gold')}</Typography>
          </Grid>
        </Grid>
      </Container>
    </BackingOptions>

    <SectionBranding
      title={t('Brand your Vegan Leather Backpack')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Vegan Leather Backpack with these great products to create your own unique styles.')}
      productSlugs={['vegan-leather-makeup-bag', 'vegan-leather-tote-bag', 'vegan-leather-crossbody-purse']}
    />
  </div>
}