import { useCallback } from 'react'
import DialogButton from '../../../Common/Buttons/DialogButton'
import Dialog from '../../../Common/Dialog/Dialog'
import DialogActions from '../../../Common/Dialog/DialogActions'
import DialogContent from '../../../Common/Dialog/DialogContent'
import DialogTitle from '../../../Common/Dialog/DialogTitle'
import { useTranslation } from 'react-i18next'
import { type UserTaxNumberStore } from '../../../UserData/_stores/UserTaxNumberStore'
import { DeleteUserTaxNumber } from '../../../UserData/_actions/UserTaxNumberActions'
import { useAppDispatch } from '../../../Common/_hooks/useAppDispatch'

interface Props {
  taxNumber: UserTaxNumberStore
  opened: boolean
  onClose: () => void
}

export default function DeleteTaxNumberDialog(props: Props) {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()

  const onConfirm = useCallback(() => {
    const fd = new FormData()
    fd.append('id', String(props.taxNumber.get('id')))

    dispatch(DeleteUserTaxNumber(fd))
    props.onClose()
  }, [props.taxNumber, props.onClose])

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <DialogTitle>{t('Delete {{name}}', { name: props.taxNumber.get('type') })}</DialogTitle>
    <DialogContent>
      <p>{t('Are you sure that you want to delete this tax number?')}</p>
    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
      >{t('Cancel')}</DialogButton>
      <DialogButton
        color="error"
        onClick={onConfirm}
        autoFocus
      >{t('Delete')}</DialogButton>
    </DialogActions>
  </Dialog>
}
