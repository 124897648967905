import { Grid, type GridOwnProps, styled } from '@mui/material'

const CartGridContainer = styled<typeof Grid>((props: GridOwnProps) => (
  <Grid
    container
    columnSpacing={{
      xs: 2,
      lg: 3
    }}
    rowSpacing={2}
    sx={{
      flexDirection: {
        xs: 'column-reverse',
        md: 'row'
      }
    }}
    {...props}
  />
))()

export default CartGridContainer
