import { useEffect, useState } from 'react';
import { Vector3 } from 'three'
import { CAMERA_LIGHT_DISTANCE } from './ModelViewerSettings'
import { useThree } from '@react-three/fiber'

export interface ModelViewerLightsProps {
    cameraDirection: Vector3
    lightDistance?: number
}

export default function ModelViewerLights(props: ModelViewerLightsProps) {
    const {
        cameraDirection,
        lightDistance = CAMERA_LIGHT_DISTANCE
    } = props

    const camera = useThree((state) => state.camera)

    const getLightPosition = () => {
        return new Vector3 (
            camera.position.x + cameraDirection.x * lightDistance,
            camera.position.y + cameraDirection.y * lightDistance,
            camera.position.z + cameraDirection.z * lightDistance,
        )
    }

    const [lightPosition, setLightPosition] = useState(getLightPosition())

    useEffect(() => {
        setLightPosition(getLightPosition())
    }, [cameraDirection, camera.position, lightDistance])

    return <>
        <directionalLight color={0xffffff} intensity={1} position={lightPosition} castShadow shadow-bias={-0.00002} shadow-radius={2} shadow-mapSize={[2048, 2048]} />
    </>
}
