import * as Immutable from 'immutable';

export type UserAddressPayload = {
	id: number
	title: string
	name: string
	address: string
	address2: string
	postal_code: string
	city: string
	province: string
	country: number
	phone: string
	default_billing: boolean
	default_shipping: boolean
	deleted: boolean
}

export interface IUserAddressStore {
	id: number,
	title: string,
	name: string,
	address: string,
	address2: string,
	postal_code: string,
	city: string,
	province: string,
	country: number,
	phone: string,
	default_billing: boolean,
	default_shipping: boolean,
	deleted: boolean,
}

const defaultUserAddressStore = Immutable.Record<IUserAddressStore>({
	id: 0,
	title: '',
	name: '',
	address: '',
	address2: '',
	postal_code: '',
	city: '',
	province: '',
	country: 0,
	phone: '',
	default_billing: false,
	default_shipping: false,
	deleted: false,
});

export class UserAddressStore extends defaultUserAddressStore implements IUserAddressStore {}