import { Divider, Grid, Paper, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import CartListStack from '../../Cart/CartContent/CartListStack'
import { CartStack } from '../../Cart/CartContent/CartStack'
import { DateTime } from 'luxon'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import CartListItems from '../../Cart/CartItem/CartListItems'
import { useCallback, useEffect, useMemo } from 'react'
import { type PackageItemStore } from '../../UserData/_stores/PackageItemStore'
import { Link } from 'react-router-dom'
import ReprintRow from './ReprintRow'
import { useTranslation } from 'react-i18next'
import { type PackageStore } from '../../UserData/_stores/PackageStore'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { type RefundStore } from '../../UserData/_stores/RefundStore'
import CartItem from '../../Cart/CartItem/CartItem'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { FetchPaginationItems } from '../../UserData/_actions/UserDataActions'
import { FetchBrandingOptions } from '../../UserData/_actions/BrandingOptionActions'

const QST_PROVINCES = [
  'Quebec',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Nova Scotia',
  'Ontario',
  'Prince Edward Island'
]

const PriceLine = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#999',

  '&.total': {
    fontSize: '18px',
    fontWeight: '500',
    color: '#000'
  },

  '&.discount': {
    color: theme.palette.accent.main
  }
}))

interface Props {
  order: UserCartStore
}

export default function OrderPageContent(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')
  const theme = useTheme()
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const shippingType = useAppSelector(state => state.get('appData').get('shippingTypes').get(String(props.order.get('id_shipping_type'))))

  useEffect(() => {
    dispatch(FetchBrandingOptions())
  }, [])

  useEffect(() => {
    if (props.order.get('reprints').count() === 0) return

    // Fetch reprints
    const fd = new FormData()
    props.order.get('reprints').forEach(reprintId => {
      fd.append('ids[]', String(reprintId))
    })

    dispatch(FetchPaginationItems('reprints', fd, 'orders'))
  }, [props.order])

  const shippingAddressLine1 = useMemo(() => {
    return [
      props.order.get('address'),
      props.order.get('address2')
    ].filter(value => value !== '').join(', ')
  }, [props.order])

  const shippingAddressLine2 = useMemo(() => {
    return [
      props.order.get('city'),
      props.order.get('postalCode'),
      props.order.get('province'),
      props.order.get('country')
    ].filter(value => value !== '').join(', ')
  }, [props.order])

  const billingAddressLine1 = useMemo(() => {
    return [
      props.order.get('billing_address'),
      props.order.get('billing_address2')
    ].filter(value => value !== '').join(', ')
  }, [props.order])

  const billingAddressLine2 = useMemo(() => {
    return [
      props.order.get('billing_city'),
      props.order.get('billing_postalCode'),
      props.order.get('billing_province'),
      props.order.get('billing_country')
    ].filter(value => value !== '').join(', ')
  }, [props.order])

  const renderPackageItem = useCallback((item: PackageItemStore) => {
    let orderItem = props.order.get('items').get(String(item.get('id')))
    if (!orderItem) return null

    // Modify order item to set package quantity
    orderItem = orderItem.set('quantity', item.get('quantity'))

    return <CartItem
      key={item.get('id')}
      cart={props.order}
      item={orderItem}
      short
      hidePrice
    />
  }, [props.order])

  const renderPackage = useCallback((thePackage: PackageStore) => {
    const trackingNumber = thePackage.get('tracking_number');
    const trackingUrl = thePackage.get('tracking_url');

    return <Paper
      key={thePackage.get('id')}
      elevation={0}
    >
      <CartStack>
        <Typography
          variant="h4"
          sx={{ color: 'success.main' }}
        >{t('Package shipped on {{date}}', { date: DateTime.fromISO(thePackage.get('date_shipped')).toLocaleString(DateTime.DATETIME_FULL) })}</Typography>
        {trackingNumber && trackingUrl ? <Typography
          variant="body2"
          color="secondary"
        >
          {t('Tracking number: ')}
          <Link
            to={trackingUrl}
            target="_blank"
            rel="noopener"
          >{trackingNumber}</Link>
        </Typography> : null }
      </CartStack>
      <Divider variant="bold"/>
      <Stack
        divider={<Divider/>}
      >
        { thePackage.get('listItems').valueSeq().map(renderPackageItem) }
      </Stack>
    </Paper>
  }, [t, renderPackageItem])

  const renderRefund = useCallback((refund: RefundStore) => {
    return <CartStack
      key={refund.get('id')}
      direction="row"
      justifyContent="space-between"
    >
      <Typography variant="h4">{DateTime.fromISO(refund.get('date_refunded')).toLocaleString(DateTime.DATETIME_MED)}</Typography>
      <Typography variant="h3">{refund.get('amount').toDinero().toFormat()}</Typography>
    </CartStack>
  }, [])

  const renderReprint = useCallback((reprintId: number) => {
    return <ReprintRow
      key={reprintId}
      id={reprintId}
    />
  }, [])

  const isPaid = useMemo(() => {
    return ['paid', 'in-production', 'ready', 'shipped'].includes(props.order?.get('status') ?? '')
  }, [props.order])

  return <CartListStack>
    { props.order.get('packages').valueSeq().map(renderPackage)}

    <Paper elevation={0}>
      <CartStack>
        <Grid container spacing={2}>
          { ['paid', 'in-production', 'ready', 'shipped'].includes(props.order.get('status')) ? <Grid item xs={12}>
            <Typography variant="h4" color="primary">{t('Paid on ')}{DateTime.fromISO(props.order.get('date_paid')).toLocaleString(DateTime.DATETIME_FULL) }</Typography>
          </Grid> : null }
          <Grid item xs={12} sm={6}>
            { props.order.get('pickup') ? <>
              <Typography variant="h3">{t('Pickup')}</Typography>
            </> : <>
              <Typography variant="h3">{t('Shipping')}</Typography>
              {shippingAddressLine1}<br/>
              {shippingAddressLine2}<br/>
              {props.order.get('shipping_phone')}
            </>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t('Billing')}</Typography>
            {billingAddressLine1}<br/>
            {billingAddressLine2}<br/>
            {props.order.get('billing_phone')}
          </Grid>
        </Grid>
      </CartStack>
      <Divider variant="bold"/>
      <CartListItems
        cart={props.order}
        short={!['cart', 'ordered'].includes(props.order.get('status'))}
        showReorder={isPaid}
      />
      <Divider variant="bold"/>
      <CartStack style={{
        alignItems: 'flex-end'
      }}>
        <Stack spacing={1}>
          <PriceLine direction="row" spacing={4}>
            <div>{t('Subtotal')}</div>
            <div>{props.order.get('subtotal').toDinero().toFormat()}</div>
          </PriceLine>
          <PriceLine direction="row" spacing={4}>
            <div>{t('Shipping') + (shippingType ? ' (' + shippingType.get('name') + ')' : '')}</div>
            <div>{props.order.get('shipping').toDinero().toFormat()}</div>
          </PriceLine>
          {!props.order.get('discount').toDinero().isZero() ? <PriceLine direction="row" spacing={4} className="discount">
            <div>{t('Discount')}</div>
            <div>{props.order.get('discount').toDinero().multiply(-1).toFormat()}</div>
          </PriceLine> : null }
          <PriceLine direction="row" spacing={4}>
            <div>{t('Taxes')}</div>
            <div>{props.order.get('taxes').toDinero().toFormat()}</div>
          </PriceLine>
          <Divider/>
          <PriceLine direction="row" spacing={4} className="total">
            <div>{t('Total')}</div>
            <div>{props.order.get('total').toDinero().toFormat()}</div>
          </PriceLine>
        </Stack>
      </CartStack>
      <CartStack
        direction={{ xs: 'column', sm: 'row' }}
        divider={<Divider
          orientation={breakpointSmDown ? 'horizontal' : 'vertical'}
          flexItem
        />}
        alignItems="center"
        sx={{
          textAlign: {
            xs: 'center',
            sm: 'left'
          }
        }}
        spacing={1}
      >
        <Typography variant="caption">
          Art of Where<br/>
          5534 Ferrier<br/>
          Mont-Royal, QC H4P 1M2, Canada
        </Typography>
        { ['Canada', 'United Kingdom'].includes(props.order.get('country')) ? <Typography variant="caption">
          { props.order.get('country') === 'Canada' ? <>
            GST: 831650338 RT 0001
            { QST_PROVINCES.includes(props.order.get('province')) ? <>
              <br/>QST: 1219943063 TQ 0001
            </> : null }
            { props.order.get('province') === 'Saskatchewan' ? <>
              <br/>PST (SK): 7395783
            </> : null }
            { props.order.get('province') === 'British Columbia' ? <>
              <br/>PST (BC): 1443-9371
            </> : null }
          </> : null}

          { props.order.get('country') === 'United Kingdom' ? <>
            VAT (UK): 381 4991 65
          </> : null}
        </Typography> : null }
      </CartStack>
    </Paper>

    <div>
      <Grid container spacing={4}>
        { props.order.get('refunds').count() > 0 ? <Grid item xs={12} lg={6}>
          <Paper elevation={0}>
              <CartStack>
                <Typography variant="h3">{t('Refunds')}</Typography>
              </CartStack>
              <Divider variant="bold"/>
              <Stack
                divider={<Divider/>}
              >
                { props.order.get('refunds').valueSeq().map(renderRefund) }
              </Stack>
          </Paper>
        </Grid> : null }

        { props.order.get('reprints').count() > 0 ? <Grid item xs={12} lg={6}>
          <Paper elevation={0} style={{ overflow: 'hidden' }}>
              <CartStack>
                <Typography variant="h3">{t('Reprints')}</Typography>
              </CartStack>
              <Divider variant="bold"/>
              <Stack
                divider={<Divider/>}
              >
                { props.order.get('reprints').valueSeq().map(renderReprint) }
              </Stack>
          </Paper>
        </Grid> : null }
      </Grid>
    </div>
  </CartListStack>
}
