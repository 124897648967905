import { useCallback, useState } from 'react'
import * as React from 'react'
import Dialog from '../../Common/Dialog/Dialog'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import DialogContent from '../../Common/Dialog/DialogContent'
import { TextField } from '@mui/material'
import DialogActions from '../../Common/Dialog/DialogActions'
import DialogButton from '../../Common/Buttons/DialogButton'
import { useTranslation } from 'react-i18next'
import { type UserImageStore } from '../../UserData/_stores/UserImageStore'
import { RenamePaginationItem } from '../../UserData/_actions/UserDataActions'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'

interface Props {
  image: UserImageStore
  opened: boolean
  onClose: () => void
}

export default function RenameImageDialog(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')

  const [name, setName] = useState(props.image.get('name'))

  const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const fd = new FormData()
    fd.append('id', props.image.get('id'))
    fd.append('name', name)
    dispatch(RenamePaginationItem(fd, 'images'))

    props.onClose()
  }, [name, props.image])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }, [])

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <form onSubmit={onSubmit}>
      <DialogTitle>{t('Rename Image')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          variant="filled"
          label={t('Name')}
          value={name}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <DialogButton
          color="secondary"
          onClick={props.onClose}
        >{t('Cancel')}</DialogButton>
        <DialogButton
          color="primary"
          type="submit"
        >{t('Confirm')}</DialogButton>
      </DialogActions>
    </form>
  </Dialog>
}
