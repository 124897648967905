import { Paper, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UpscaledImagePayload } from '../../../UIData/_actions/DesignLabActions';
import UpscaleConfirm from './UpscaleConfirm';
import UpscaleGenerating from './UpscaleGenerating';
import Dialog from "../../../Common/Dialog/Dialog";

const StyledDialogPaper = styled(Paper)({
    backgroundImage: 'url('+require('@resources/img/theme/dialog/ornaments-shapes-1.svg?url')+')',
    backgroundSize: '100%',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
})

export type UpscaleState = 'none'|'generating'|'done';

type Props = {
    upscaleState: UpscaleState
    upscaledImage?: UpscaledImagePayload
    onConfirm?: () => void
    onTryAgain?: () => void
    onClose?: () => void
}

export default function UpscaleDialog(props: Props) {
    const [t] = useTranslation('design-lab');

    return <Dialog
        open={props.upscaleState !== 'none'}
        onClose={props.onClose}
        maxWidth={"md"}
        PaperComponent={StyledDialogPaper}
    >
        { props.upscaleState === 'generating' ? <UpscaleGenerating /> : null }
        { props.upscaleState === 'done' && props.upscaledImage ? <UpscaleConfirm
            upscaledImage={props.upscaledImage}
            onConfirm={props.onConfirm}
            onTryAgain={props.onTryAgain}
            onClose={props.onClose} 
        /> : null }
    </Dialog>
}