import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';

export default function useLabData():Record<string,any>|undefined {
    const activeProductId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
    const activeSubproduct = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeSubproduct'));
    const productData = useSelector((state:WebsiteStore) => state.get('appData').get('products').get(String(activeProductId)));

    const fullLabData = productData?.get('labData');

    if(!fullLabData) return undefined;

    if(fullLabData.has_subproducts) {
        return activeSubproduct ? fullLabData.subproducts[activeSubproduct]?.originalData : undefined;
    }

    return fullLabData.originalData;
}