import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ResetProductListings } from '../../Dashboard/_actions/DashboardActions';
import { FetchUserStorePages } from '../../UserData/_actions/UserStorePageActions';
import MassProductButton, { Props } from '../_components/MassProductButton';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({

});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	resetProductListings: () => {dispatch(ResetProductListings())},
	fetchUserStorePages: (store: string) => {dispatch(FetchUserStorePages(store))},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MassProductButton);