import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';

const PRODUCT_ID = 121;
const VIEW_PRODUCT_ID = 138;

export default function GlitterYogaShortsPage() {
  const [t] = useTranslation('page/products/glitter-yoga-shorts');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Glitter Yoga Shorts | Products')}
      description={t('Design and create your own custom printed Glitter Yoga Shorts featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#7283d5"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '48%',
      }}
      tag={t('The all-over print')}
      title={t('Glitter Yoga Shorts')}
      text={t('Introducing glitter yoga shorts, combining functionality with sparkle. Crafted from our signature performance fabric with a shimmer finish, these print-on-demand shorts provide flexibility and support for every pose. The high-rise waistband offers a secure, flattering fit, and the durable shiny finish withstands regular washing. Perfect for those who want to stand out on and off the mat.')}
      createUrl={productData.get('url_slug') + '?option_fabric=glitter-performance-knit'}
      img={require('@resources/img/pages/products/glitter-yoga-shorts/banner-1-' + locale + '.png')}
      alt={t('Picture of Glitter Yoga Shorts')}
      details={[
        {
          img: require('@resources/img/pages/products/glitter-yoga-shorts/icon-1.svg?url'),
          alt: t('Fabric icon'),
          text: t('Classic yoga cut with high waistband'),
        },
        {
          img: require('@resources/img/pages/products/glitter-yoga-shorts/icon-2.svg?url'),
          alt: t('Worldwide icon'),
          text: t('6” short inseam'),
        },
        {
          img: require('@resources/img/pages/products/glitter-yoga-shorts/icon-3.svg?url'),
          alt: t('UPF icon'),
          text: t('Made from 88% polyester/12 % spandex performance glitter fabric'),
        },
        {
          img: require('@resources/img/pages/products/glitter-yoga-shorts/icon-4.svg?url'),
          alt: t('Ink icon'),
          text: t('Printed with vibrant and permanent sublimation inks'),
        },
        {
          img: require('@resources/img/pages/products/glitter-yoga-shorts/icon-5.svg?url'),
          alt: t('Yoga icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/glitter-yoga-shorts/gallery_0.jpg'),
      require('@resources/img/pages/products/glitter-yoga-shorts/gallery_1.jpg'),
      require('@resources/img/pages/products/glitter-yoga-shorts/gallery_2.jpg'),
      require('@resources/img/pages/products/glitter-yoga-shorts/gallery_3.jpg'),
      require('@resources/img/pages/products/glitter-yoga-shorts/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your glitter <br/>yoga shorts <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love glitter yoga shorts! At prices designed for reselling, yoga shorts made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
      options={['glitter-performance-knit']}
    />

    <SectionSizes
      chart="bottoms"
      productId={PRODUCT_ID}
      title={t('Glitter Yoga shorts Sizing')}
      text={t('All measurements are approximate. Since our products are made by hand, there may be variations in the finished size of our products between orders or even within an order.')}
    />

    <SectionBranding
      hasLabel
      title={t('Brand your Glitter Yoga Shorts')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your glitter yoga shorts with these great products to create your own unique styles.')}
      productSlugs={['premium-t-shirt', 'glitter-athletic-crop-top', 'day-tote']}
    />
  </div>
}