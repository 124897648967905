import { useTranslation } from 'react-i18next'
import AccountHeader from '../AccountHeader'
import { Button, Card, CardContent, Container, Divider, FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { type ChangeEvent, type FormEvent, useCallback, useEffect, useState } from 'react'
import { type Currency, type Locale } from '../../UserData/_stores/UserPrefsStore'
import {
  AccountCircle as AccountCircleIcon,
  EmailOutlined as EmailOutlinedIcon,
  LocalPhoneOutlined as LocalPhoneOutlinedIcon
} from '@mui/icons-material'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { SaveContactDetails, SaveUserPrefs, UserDataActionType } from '../../UserData/_actions/UserDataActions'
import { useNavigate } from 'react-router-dom'
import AuthCheckDialog from './AuthCheckDialog'

export default function Info() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')
  const navigate = useNavigate()

  const user = useAppSelector(state => state.get('userData'))
  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('userData'))

  const [name, setName] = useState(user.get('name'))
  const [email, setEmail] = useState(user.get('email'))
  const [phone, setPhone] = useState(user.get('phone'))

  const [locale, setLocale] = useState<Locale>(user.get('prefs').get('lang'))
  const [currency, setCurrency] = useState<Currency>(user.get('prefs').get('currency'))

  const [passCheckDialogOpened, setPassCheckDialogOpened] = useState(false)

  useEffect(() => {
    setName(user.get('name'))
    setEmail(user.get('email'))
    setPhone(user.get('phone'))

    setLocale(user.get('prefs').get('lang'))
    setCurrency(user.get('prefs').get('currency'))
  }, [user])

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setName(e.target.value), [])
  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim()), [])
  const onPhoneChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setPhone(e.target.value), [])

  const onChangeLocale = useCallback((e: ChangeEvent<HTMLInputElement>) => setLocale(e.target.value as Locale), [])
  const onChangeCurrency = useCallback((e: ChangeEvent<HTMLInputElement>) => setCurrency(e.target.value as Currency), [])

  const onPassCheckDialogClose = useCallback(() => {
    setPassCheckDialogOpened(false)
  }, [])

  const onAuthCheck = useCallback((password: string) => {
    const fd = new FormData()
    fd.append('name', name)
    fd.append('phone', phone)
    fd.append('email', email)
    fd.append('password', password)

    dispatch(SaveContactDetails(fd).set({
      onSuccess: () => {
        setPassCheckDialogOpened(false)
      }
    }))
  }, [name, email, phone])

  const onSubmitContact = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // Trigger auth check if email has changed
    if (email !== user.get('email')) {
      setPassCheckDialogOpened(true)
      return
    }

    const fd = new FormData()
    fd.append('name', name)
    fd.append('phone', phone)

    dispatch(SaveContactDetails(fd))
  }, [name, email, phone, user])

  const onSubmitPreferences = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const fd = new FormData()
    fd.append('locale', locale)
    fd.append('currency', currency)

    dispatch(SaveUserPrefs(fd, true, () => {
      // TODO: Once website is a full SPA, change this so we don't have to reload
      navigate(0)
    }))
  }, [locale, currency])

  return <div>
    <AccountHeader
      title={t('Personal info')}
    />

    <Container
      maxWidth="sm"
      disableGutters
      style={{ margin: 0 }}
    >
      <Stack spacing={4}>
        <Card elevation={0}>
          <form onSubmit={onSubmitContact}>
            <CardContent sx={{ py: 2 }}>
              <Stack spacing={1}>
                <Typography
                  variant="h3"
                >{t('Contact')}</Typography>
              </Stack>
            </CardContent>
            <Divider />
            <CardContent>
              <Stack spacing={2}>
                <TextField
                  label={t('Full name')}
                  value={name}
                  onChange={onNameChange}
                  error={name === ''}
                  disabled={loader}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">
                      <AccountCircleIcon/>
                    </InputAdornment>
                  }}
                />
                <TextField
                  label={t('Email')}
                  value={email}
                  onChange={onEmailChange}
                  type="email"
                  error={email === ''}
                  disabled={loader}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">
                      <EmailOutlinedIcon/>
                    </InputAdornment>
                  }}
                />
                <TextField
                  label={t('Phone')}
                  value={phone}
                  onChange={onPhoneChange}
                  type="tel"
                  disabled={loader}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">
                      <LocalPhoneOutlinedIcon/>
                    </InputAdornment>
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={email === '' || name === '' || loader}
                  style={{
                    alignSelf: 'flex-start'
                  }}
                >{t('Save contact details')}</Button>
              </Stack>
            </CardContent>
          </form>
        </Card>

        <Card elevation={0}>
          <form onSubmit={onSubmitPreferences}>
            <CardContent sx={{ py: 2 }}>
              <Stack spacing={1}>
                <Typography
                  variant="h3"
                >{t('Preferences')}</Typography>
              </Stack>
            </CardContent>
            <Divider />
            <CardContent>
              <Stack spacing={2}>
                <FormControl
                  disabled={loader}
                >
                  <FormLabel>{t('Language')}</FormLabel>
                  <RadioGroup
                    value={locale}
                    onChange={onChangeLocale}
                    row
                  >
                    <FormControlLabel value="en_CA" control={<Radio />} label="English" />
                    <FormControlLabel value="fr_CA" control={<Radio />} label="Français" />
                  </RadioGroup>
                </FormControl>

                <FormControl
                  disabled={loader}
                >
                  <FormLabel>{t('Currency')}</FormLabel>
                  <RadioGroup
                    value={currency}
                    onChange={onChangeCurrency}
                    row
                  >
                    <FormControlLabel value="USD" control={<Radio />} label="USD" />
                    <FormControlLabel value="CAD" control={<Radio />} label="CAD" />
                  </RadioGroup>
                </FormControl>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loader}
                  style={{
                    alignSelf: 'flex-start'
                  }}
                >{t('Save preferences')}</Button>
              </Stack>
            </CardContent>
          </form>
        </Card>
      </Stack>
    </Container>

    <AuthCheckDialog
      opened={passCheckDialogOpened}
      onConfirm={onAuthCheck}
      onClose={onPassCheckDialogClose}
      loaderKey="userData"
      errorKey={UserDataActionType.SaveContactDetails}
    />
  </div>
}
