import { useEffect } from 'react';
import * as React from 'react';
import Dinero from 'dinero.js';
import { useDispatch, useSelector } from 'react-redux';
import { GetAppData } from '../_actions/AppDataActions';
import WebsiteStore from '../../WebsiteStore';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

export type Props = React.PropsWithChildren<{
	loaderComponent?: React.ReactNode
}>

export default function ApplicationDataLayer(props:Props) {
	const dispatch = useAppDispatch();

	const ready = useSelector((state:WebsiteStore) => 
		state.get('appData').get('countries').count() > 0
		&& state.get('appData').get('products').count() > 0
		&& state.get('appData').get('productCategories').count() > 0
		&& state.get('appData').get('productSubCategories').count() > 0
	);
	const loading = useSelector((state:WebsiteStore) => state.get('UIData').get('loaders').get('appData'));
	const currency = useSelector((state:WebsiteStore) => state.get('userData').get('prefs').get('currency'));

	useEffect(() => {
		if(loading || ready) return;

		dispatch(GetAppData())
		setCurrency();
	}, []);

	useEffect(() => {
		setCurrency();
	}, [currency]);

	const setCurrency = () => Dinero.globalLocale = currency == 'USD' ? 'en-US' : window.LOCALE.replace('_','-');

	if(loading || !ready) {
		return props.loaderComponent ? props.loaderComponent : <></>
	}
	
	return props.children
}