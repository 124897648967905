import { useEffect, useRef, useState } from 'react';
import * as React from 'react';

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'onChange'|'value'> & {
    value: number
    onChange?: (value:number) => void
}

export default function(props:Props) {
    const { value, onBlur, onChange, ...otherProps } = props;
    const [tmpValue, setTmpValue] = useState(String(value));
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        //Don't update when focused
        if(document && inputRef.current && inputRef.current === document.activeElement) {
            return;
        }

        setTmpValue(String(Math.round(value*100)/100));
    }, [value]);

    useEffect(() => {
        //Update only when focused
        if(document && inputRef.current && inputRef.current !== document.activeElement) {
            return;
        }

        const processedValue = Number(tmpValue);
        if(isNaN(processedValue)) return;

        onChange && onChange(processedValue);
    }, [tmpValue]);

    return <input
        ref={inputRef}
        type="text"
        value={tmpValue}
        onChange={(e) => setTmpValue(e.target.value)}
        onBlur={(e) => {
            setTmpValue(String(Math.round(value*100)/100));
            onBlur && onBlur(e);
        }}
        {...otherProps}
    />
}