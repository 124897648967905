import { Card, CardActions, CardContent, Chip, Grid, Stack, Typography } from '@mui/material'
import { type BrandingOptionStore } from '../../UserData/_stores/BrandingOptionStore'
import CardButton from '../../Common/Buttons/CardButton'
import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import DeleteBrandingOptionDialog from './DeleteBrandingOptionDialog'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import EditBrandingOptionDialog from './EditBrandingOptionDialog'

interface Props {
  brandingOption: BrandingOptionStore
}

export default function BrandingOption(props: Props) {
  const [t] = useTranslation('account-v2')

  const type = useAppSelector(state => state.get('appData').get('brandingOptionTypes').get(String(props.brandingOption.get('id_type'))))

  const [editDialogOpened, setEditDialogOpened] = useState(false)
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)

  const onEditOpen = useCallback(() => {
    setEditDialogOpened(true)
  }, [])

  const onEditClose = useCallback(() => {
    setEditDialogOpened(false)
  }, [])

  const onDeleteOpen = useCallback(() => {
    setDeleteDialogOpened(true)
  }, [])

  const onDeleteClose = useCallback(() => {
    setDeleteDialogOpened(false)
  }, [])

  return <Grid item xs={12} md={12} lg={6} xl={4} key={props.brandingOption.get('id')}>
    <Card
      elevation={3}
      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <CardContent
        component={Stack}
        direction="row"
        spacing={2}
        sx={{
          flexGrow: 1
        }}
      >
        <div style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          width: '100px',
          height: '80px',
          flexShrink: 0
        }}>
          <img
            src={props.brandingOption.get('file_url')}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              display: 'block',
              boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
            }}
          />
        </div>
        <Stack>
          <Typography variant="h3">{ props.brandingOption.get('name') }</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2">{ type?.get('name') }</Typography>
            { props.brandingOption.get('is_default') ? <Chip
              size="small"
              variant="square-filled"
              color="primary"
              label={t('Auto')}
            /> : null }
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <CardButton
          color="secondary"
          onClick={onDeleteOpen}
        >{t('Delete')}</CardButton>
        <CardButton
          color="primary"
          onClick={onEditOpen}
        >{t('Edit')}</CardButton>
      </CardActions>
    </Card>

    <EditBrandingOptionDialog
      brandingOption={props.brandingOption}
      opened={editDialogOpened}
      onClose={onEditClose}
    />

    <DeleteBrandingOptionDialog
      brandingOption={props.brandingOption}
      opened={deleteDialogOpened}
      onClose={onDeleteClose}
    />
  </Grid>
}
