export class ModelViewerTexture {
    pre = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: Object) => {}
    post = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: Object) => {}
}

const Resources:Record<string,HTMLImageElement|null> = {
    'unisex-joggers-mask': null
}

export default function useCustomProductTexture(product: number): ModelViewerTexture {
    var modelViewerTexture = new ModelViewerTexture

    switch(product) {
        case 122:
            modelViewerTexture.post = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: Object) => {
                if (fxTextureCanvas == null) return
                let product_texture_context = fxTextureCanvas.getContext("2d")
                if(product_texture_context === null) return

                product_texture_context.fillStyle = "#FFFFFF"
                product_texture_context.globalCompositeOperation = "source-over"
                product_texture_context.fillRect(0, 0, fxTextureCanvas.width, fxTextureCanvas.height)
                product_texture_context.globalCompositeOperation = "destination-in"
                product_texture_context.drawImage(productTextureCanvas, 0, 0)
            }
        case 126:
        case 127:
        case 128:
            modelViewerTexture.post = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: Object) => {
                if (fxTextureCanvas == null) return
                let product_texture_context = fxTextureCanvas.getContext("2d")
                if(product_texture_context === null) return

                if(!("color" in options)) return
                let color = window.Products.getProduct(product).getColor(options.color)

                product_texture_context.fillStyle = "#"+color.getHexString()
                product_texture_context.globalCompositeOperation = "source-over"
                product_texture_context.fillRect(0, 0, fxTextureCanvas.width, fxTextureCanvas.height)
                product_texture_context.drawImage(productTextureCanvas, 0, 0)
                product_texture_context.fillRect(459, 675, 130, 176)

                if ([128].includes(product)) {
                    const apply_mask = () => {
                        product_texture_context.globalCompositeOperation = "destination-in"
                        product_texture_context.drawImage(Resources['unisex-joggers-mask'] as CanvasImageSource, 0, 0)
                    }

                    if(!Resources['unisex-joggers-mask']) {
                        Resources['unisex-joggers-mask'] = new Image()
                    }
                    
                    if (!Resources['unisex-joggers-mask'].complete) {
                        const mask_url = new URL('@resources/3d-models/textures/unisex-joggers-mask.png', import.meta.url)
                        Resources['unisex-joggers-mask'].onload = apply_mask
                        Resources['unisex-joggers-mask'].src = mask_url.href
                    } else {
                        apply_mask()
                    }
                }
            }
    }

    return modelViewerTexture
}