import { useCallback } from "react"
import FacebookLogin, { FailResponse, ProfileSuccessResponse, SuccessResponse } from '@greatsumini/react-facebook-login';
import config from '../../../config/config.json'
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import FacebookIcon from '@mui/icons-material/Facebook';
import { useAppDispatch } from "../Common/_hooks/useAppDispatch";
import { AuthActionType, SubmitFbLogin } from "./_actions/AuthActions";
import { useAppSelector } from "../Common/_hooks/useAppSelector";
import { ApiError } from "../UIData/_actions/UIDataActions";

interface Props {
  signup?: boolean
  newsletter?: boolean
  onLoginSuccess: () => void
  onSignupSuccess: () => void
  onAccountLink: () => void
}

export default function FacebookLoginButton(props:Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('auth')

  const lang = useAppSelector(state => state.get('userData').get('prefs').get('lang'))
  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('auth') || state.get('UIData').get('loaders').get('auth-navigating'))

  const onSuccess = useCallback((response:SuccessResponse) => {
    dispatch(SubmitFbLogin(props.newsletter).set({
      onSuccess: (response) => {
        switch(response.data.login_type) {
          case 'login':
            props.onLoginSuccess();
            break;

          case 'signup':
            props.onSignupSuccess();
            break;

          case 'account-link':
            props.onAccountLink();
            break;
        }
      }
    }))
  }, [props.onLoginSuccess, props.onSignupSuccess, props.onAccountLink, props.newsletter])

  const onFail = useCallback((error:FailResponse) => {
    console.log('fb fail', error);
    dispatch(ApiError(AuthActionType.SubmitFbLogin, {
      error: 'fb-login-cancelled'
    }))
  }, [])

  const renderButton = useCallback((buttonProps:{
    onClick?: () => void,
    logout?: (callback:() => void) => void
  }) => {
    return <Button
      variant="contained"
      size="large"
      fullWidth
      onClick={buttonProps.onClick}
      disabled={!!loader}
      sx={{
        backgroundColor: '#1877f2',
        fontSize: '14px', //This is to match the Google sign in button
      }}
      startIcon={<FacebookIcon/>}
    >{props.signup ? t('Sign up with Facebook') : t('Sign in with Facebook')}</Button>
  }, [props.signup, loader, t])

  return <FacebookLogin
    appId={config.FB_APP_ID}
    onSuccess={onSuccess}
    onFail={onFail}
    render={renderButton}
    language={lang}
    scope="public_profile,email"
    loginOptions={{
      auth_type: 'rerequest'
    }}
    initParams={{
      cookie: true,
      //@ts-ignore
      version: config.FB_GRAPH_VERSION,
    }}
  />
}