import Point from "./Math/Point";

export const CanvasToLab = (position: Point, offset: Point, zoom: number): Point => {
    return {
        x: position.x / zoom + offset.x,
        y: position.y / zoom + offset.y,
    }
}

// This works by inverting the math in the zoom reducer, can be used with the zoom event to supply the desired offset directly
// Does nothing when zoom == 1
export const InverseZoom = (position: Point, offset: Point, zoom: number): Point => {
    if(zoom == 1) return position
    var z2 = zoom*zoom
    return {
        x: (zoom * (offset.x - offset.x * z2 + position.x * z2))/(-1 + zoom),
        y: (zoom * (offset.y - offset.y * z2 + position.y * z2))/(-1 + zoom),
    }
}