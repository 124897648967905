import { Button, Typography, styled } from '@mui/material';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import useFixedView from '../../ModelViewer/_hooks/useFixedView';
import usePreviewSize from '../_hooks/usePreviewSize';
import Model3DViewer from './Model3DViewer';
import { useCookies } from 'react-cookie'
import LabModelViewer from './LabModelViewer';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

const PreviewTitle = styled('h3')(({theme}) => ({
    marginTop: '0',
    padding: '0 15px 0',
    fontSize: '16px',
    textTransform: 'uppercase',
    lineHeight: '40px',
    color: '#333',
    marginBottom: '0',
    textAlign: 'center',
    position: 'relative',
    zIndex: 1,
    textShadow: '0 0 10px #eee, 0 0 10px #eee, 0 0 10px #eee, 0 0 10px #eee, 0 0 10px #eee',
    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        display: 'none',
    }
}));

const PreviewViewerContainer = styled('div')(({theme}) => ({
    paddingBottom: '30px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignSelf: 'stretch',

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: 1070px)']: {
        paddingBottom: '15px',
    },
}));

const GreyRoundedButton = styled(Button)({
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'inherit',
    borderRadius: '30px',
    padding: '0 15px',
    lineHeight: '35px',
    height: 'auto',
    fontWeight: 500,
    fontFamily: 'inherit',
    fontSize: '14px',

    '&:focus': {
        background: 'rgba(0, 0, 0, 0.1)',
    }
})

const Model3DWrapper = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    position: 'relative',
    marginTop: '-40px',

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        marginTop: 0,
    }
}));

const ViewButtonsContainer = styled('div')(({theme}) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 10,
    margin: '10px 15px 0',

    ['@media (max-height: 1070px)']: {
        position: 'absolute',
        bottom: 0,
        margin: '0 15px',
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        ['@media (max-height: '+theme.height_breakpoints.values.lab_mobile+'px) and (min-width: 700px)']: {
            position: 'absolute',
            bottom: 15,
        }
    },
}));

type Props = {}

export default function PreviewViewer(props:Props) {
    const [t] = useTranslation('design-lab')
    const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'))
    const viewerVersion = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId))?.get('viewerVersion').get('version'))
    const previewSize = usePreviewSize()
    const [cookies, setCookie] = useCookies(['lab_previewWarning_confirm'])
    const [fixedView, setFixedView] = useFixedView()

    return <PreviewViewerContainer>
        <PreviewTitle>{t('3D Preview')}</PreviewTitle>

        <Model3DWrapper>
            <div
                style={{
                    width: previewSize.width,
                    height: previewSize.height,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >{
                viewerVersion == 1 ? <Model3DViewer 
                        productId={activeProductId}
                        width={previewSize.width}
                        height={previewSize.height} />
                : viewerVersion == 2 ? <LabModelViewer />
                : <></>
            }</div>
            <ViewButtonsContainer>
                <GreyRoundedButton
                    onClick={() => setFixedView('front')}
                >{t('Front')}</GreyRoundedButton>
                <GreyRoundedButton
                    onClick={() => setFixedView('back')}
                >{t('Back')}</GreyRoundedButton>
                <GreyRoundedButton
                    onClick={() => setFixedView('left')}
                >{t('Left')}</GreyRoundedButton>
                <GreyRoundedButton
                    onClick={() => setFixedView('right')}
                >{t('Right')}</GreyRoundedButton>
            </ViewButtonsContainer>
        </Model3DWrapper>

        { !cookies.lab_previewWarning_confirm ? <div style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 30,
            pointerEvents: 'none',
        }}><div style={{
                width: '80%',
                maxWidth: '500px',
                background: 'rgba(0, 0, 0, 0.8)',
                color: '#fff',
                borderRadius: '20px',
                padding: '25px 25px 15px',
                pointerEvents: 'auto',
                position: 'relative',
            }}>
                <div 
                    style={{
                        background: '#ea008a',
                        width: '36px',
                        height: '36px',
                        borderRadius: '18px',
                        color: '#fff',
                        lineHeight: '36px',
                        fontSize: '30px',
                        fontWeight: '600',
                        textAlign: 'center',
                        position: 'absolute',
                        left: '-26px',
                        top: '26px',
                    }}
                >!</div>
                <Typography
                    variant="h4"
                    sx={{
                        color: '#ea008a',
                        lineHeight: 1.2,
                        marginBottom: '10px',
                    }}
                >{ t('There may be some differences between this 3D mockup and the final printed product.') }</Typography>
                <Typography
                    variant="body2"
                    style={{
                        marginBottom: '10px',
                    }}
                >{ t('Make sure you keep any important elements outside of the bleed (red and hatched zones) in the lab template. For clothing, the preview represents the Large size most accurately.') }</Typography>
                <Button
                    style={{
                        color: '#fff',
                    }}
                    onClick={() => {
                        setCookie('lab_previewWarning_confirm', true);
                    }}
                >{ t('I understand') }</Button>
            </div>
        </div> : null }
    </PreviewViewerContainer>
}