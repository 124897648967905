import { Button } from '@mui/material';
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from '../Common/_hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import GoogleIcon from '@mui/icons-material/Google';
import { useAppSelector } from '../Common/_hooks/useAppSelector';
import config from '../../../config/config.json'
import { AuthActionType, SubmitGoogleLogin } from './_actions/AuthActions';
import { ApiError } from '../UIData/_actions/UIDataActions';

interface Props {
  signup?: boolean
  newsletter?: boolean
  onLoginSuccess: () => void
  onSignupSuccess: () => void
  onAccountLink: () => void
}

export default function GoogleLoginButton(props:Props) {
  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement|null>(null)

  const lang = useAppSelector(state => state.get('userData').get('prefs').get('lang'))
  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('auth') || state.get('UIData').get('loaders').get('auth-navigating'))

  const [width,setWidth] = useState<number|undefined>(undefined)

  const onSuccess = useCallback((credentialResponse:CredentialResponse) => {
    if(!credentialResponse.credential) {
      console.log('g error no credential', credentialResponse);
      dispatch(ApiError(AuthActionType.SubmitGoogleLogin, {
        error: 'g-error'
      }))
      return;
    }

    dispatch(SubmitGoogleLogin(credentialResponse.credential, props.newsletter).set({
      onSuccess: (response) => {
        switch(response.data.login_type) {
          case 'login':
            props.onLoginSuccess();
            break;

          case 'signup':
            props.onSignupSuccess();
            break;

          case 'account-link':
            props.onAccountLink();
            break;
        }
      }
    }))
  }, [props.onLoginSuccess, props.onSignupSuccess, props.onAccountLink, props.newsletter])

  const onError = useCallback(() => {
    dispatch(ApiError(AuthActionType.SubmitGoogleLogin, {
      error: 'g-error'
    }))
  }, [])

  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setWidth(containerRef.current?.offsetWidth)
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  return <div 
    ref={containerRef}
    style={{
      filter: !!loader ? 'invert(1) grayscale(1)' : undefined,
      opacity: !!loader ? 0.5 : 1,
      pointerEvents: !!loader ? 'none' : undefined,
    }}
  >
    <GoogleLogin
      shape="pill"
      locale={lang}
      size="large"
      text={props.signup ? 'signup_with' : 'signin_with'}
      type="standard"
      ux_mode="popup"
      theme="filled_black"
      width={width}
      containerProps={{
        style: {
          display: 'flex',
          justifyContent: 'center',
        }
      }}
      onSuccess={onSuccess}
      onError={onError}
    />
  </div>
}