import * as Immutable from 'immutable';
import { PaginationItem } from './PaginationStore'

export type CollectionItemPayload = {
	id: number
  id_collection: number
  id_product_design: number
  theorder: number
}

interface ICollectionItemStore extends PaginationItem {
	id: number
  id_collection: number
  id_product_design: number
  theorder: number
}

const defaultCollectionItemStore = Immutable.Record<ICollectionItemStore>({
	id: 0,
  id_collection: 0,
  id_product_design: 0,
  theorder: 0
});

export class CollectionItemStore extends defaultCollectionItemStore implements ICollectionItemStore {}