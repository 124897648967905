import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Box, Collapse, Container, Divider, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, styled } from '@mui/material'
import {
  Style as StyleIcon,
  ShoppingBasket as ShoppingBasketIcon,
  PhotoLibrary as PhotoLibraryIcon,
  PhotoCamera as PhotoCameraIcon,
  EditLocation as EditLocationIcon,
  CreditCard as CreditCardIcon,
  AccountBalance as AccountBalanceIcon,
  LabelOutlined as LabelOutlinedIcon,
  AddBox as AddBoxIcon,
  LockOutlined as LockOutlinedIcon,
  AccountCircle as AccountCircleIcon,
  NotificationsOutlined as NotificationsOutlinedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon
} from '@mui/icons-material'
import { Outlet, useLocation, NavLink } from 'react-router-dom'
import { useAppSelector } from '../Common/_hooks/useAppSelector'
import { useCallback, useEffect, useMemo, useState } from 'react'

const Nav = styled(Stack)(({ theme }) => ({
  width: '300px',
  padding: theme.spacing(3.5, 0, 4),
  flexShrink: 0,
  backgroundImage: 'url(' + require('@resources/img/account/sidebar-ornaments.svg?url') + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom right',
  backgroundSize: '50%',
  position: 'sticky',
  top: '32px',
  height: '100vh',
  overflow: 'auto',
  // transition: 'box-shadow 250ms',
  boxShadow: 'none',

  '&.white-page': {
    boxShadow: '-120px 0 0 rgba(255, 255, 255, 1), 0 0 100px rgba(0, 0, 0, 0.1)'
  },

  [theme.breakpoints.down('lg')]: {
    width: '260px'
  }
}))

const NavList = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 0, 2, 0),

  'a.active': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
}))

const ListHeader = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 2, 1),
  fontSize: '1.5rem',
  fontWeight: '400',
  lineHeight: 'normal',
  textTransform: 'none'
}))

const NavIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: theme.spacing(5)
}))

const MainWrapper = styled('div')(({ theme }) => ({
  // transition: 'background-image 250ms',
  backgroundImage: 'linear-gradient(90deg, #fff 0%, #fff 50%, #f0f0f0 50%, #f0f0f0 100%)',
  backgroundColor: '#fff',

  '&.white-page': {
    backgroundImage: 'none'
  }
}))

export default function Account() {
  const [t] = useTranslation('account-v2')
  const [tR] = useTranslation('routes')
  const location = useLocation()

  const stores = useAppSelector(state => state.get('userData').get('stores'))

  const [settingsOpened, setSettingsOpened] = useState(location.pathname.indexOf(tR('/account/settings')) === 0)

  useEffect(() => {
    setSettingsOpened(location.pathname.indexOf(tR('/account/settings')) === 0)
  }, [location, tR])

  const isWhitePage = useMemo(() => {
    return location.pathname === tR('/account/products')
  }, [location])

  const onSettingsToggle = useCallback(() => {
    setSettingsOpened(value => !value)
  }, [])

  return <>
    <Helmet
      titleTemplate={t('%s | Account | Art of Where')}
    >

    </Helmet>
    <MainWrapper className={isWhitePage ? 'white-page' : ''}>
      <Container
        maxWidth="xl"
        disableGutters
      >
        <Stack direction="row">
          <Nav
            divider={<Divider />}
            className={isWhitePage ? 'white-page' : ''}
            sx={{
              display: {
                xs: 'none',
                md: 'block'
              }
            }}
          >
            <NavList>
              <ListHeader variant="h3">{t('Account')}</ListHeader>
              <ListItemButton component={NavLink} to={tR('/account/products')}>
                <NavIcon><StyleIcon /></NavIcon>
                <ListItemText>{t('Products')}</ListItemText>
              </ListItemButton>
              <ListItemButton component={NavLink} to={tR('/account/orders')}>
                <NavIcon><ShoppingBasketIcon /></NavIcon>
                <ListItemText>{t('Orders')}</ListItemText>
              </ListItemButton>
              <ListItemButton component={NavLink} to={tR('/account/images')}>
                <NavIcon><PhotoLibraryIcon /></NavIcon>
                <ListItemText>{t('Image Library')}</ListItemText>
              </ListItemButton>
              <ListItemButton component={NavLink} to={tR('/account/branding-options')}>
                <NavIcon><LabelOutlinedIcon /></NavIcon>
                <ListItemText>{t('Branding options')}</ListItemText>
              </ListItemButton>
              <ListItemButton component={NavLink} to={tR('/account/photo-lab')}>
                <NavIcon><PhotoCameraIcon /></NavIcon>
                <ListItemText>{t('Photo Lab')}</ListItemText>
              </ListItemButton>
            </NavList>
            <NavList>
              <ListHeader variant="h3">{t('Stores')}</ListHeader>
              {stores.valueSeq().map(store => {
                return <ListItemButton key={store.get('id')} component={NavLink} to={tR('/d') + '/' + store.get('slug')} reloadDocument>
                  <NavIcon><img style={{ width: '24px' }} src={require('@resources/img/account/' + store.get('slug') + '-icon.svg?url')} /></NavIcon>
                  <ListItemText>{store.get('name')}</ListItemText>
                </ListItemButton>
              })}
              <ListItemButton component={NavLink} to={tR('/d/new-store')} reloadDocument>
                <NavIcon><AddBoxIcon /></NavIcon>
                <ListItemText>{t('Add Store')}</ListItemText>
              </ListItemButton>
            </NavList>
            <NavList>
              <ListItemButton onClick={onSettingsToggle}>
                <ListHeader
                  variant="h3"
                  sx={{
                    p: 0,
                    flexGrow: 1
                  }}
                >{t('Settings')}</ListHeader>
                <ExpandMoreRoundedIcon
                  fontSize="large"
                  sx={{
                    transform: settingsOpened ? 'scaleY(-1)' : 'scaleY(1)',
                    transition: 'transform 250ms'
                  }}
                />
              </ListItemButton>
              <Collapse in={settingsOpened} timeout="auto">
                <ListItemButton component={NavLink} to={tR('/account/settings/info')}>
                  <NavIcon><AccountCircleIcon /></NavIcon>
                  <ListItemText>{t('Personal info')}</ListItemText>
                </ListItemButton>
                <ListItemButton component={NavLink} to={tR('/account/settings/notifications')}>
                  <NavIcon><NotificationsOutlinedIcon /></NavIcon>
                  <ListItemText>{t('Notifications')}</ListItemText>
                </ListItemButton>
                <ListItemButton component={NavLink} to={tR('/account/settings/security')}>
                  <NavIcon><LockOutlinedIcon /></NavIcon>
                  <ListItemText>{t('Security')}</ListItemText>
                </ListItemButton>
                <ListItemButton component={NavLink} to={tR('/account/settings/addresses')}>
                  <NavIcon><EditLocationIcon /></NavIcon>
                  <ListItemText>{t('Addresses')}</ListItemText>
                </ListItemButton>
                <ListItemButton component={NavLink} to={tR('/account/settings/credit-cards')}>
                  <NavIcon><CreditCardIcon /></NavIcon>
                  <ListItemText>{t('Credit cards')}</ListItemText>
                </ListItemButton>
                <ListItemButton component={NavLink} to={tR('/account/settings/tax-numbers')}>
                  <NavIcon><AccountBalanceIcon /></NavIcon>
                  <ListItemText>{t('Tax numbers')}</ListItemText>
                </ListItemButton>
              </Collapse>
            </NavList>
          </Nav>
          <Box component="div" sx={{
            flexGrow: 1,
            minWidth: 0,
            pt: {
              xs: 5
            },
            px: {
              xs: 2,
              md: 3,
              lg: 4
            },
            pb: {
              xs: 6
            },
            // transition: 'background-color 250ms',
            backgroundColor: isWhitePage ? '#ffffff' : '#f0f0f0'
          }}>
            <Outlet />
          </Box>
        </Stack>
      </Container>
    </MainWrapper>
  </>
}
