import * as Immutable from 'immutable';

export type PackageItemPayload = {
	id: number
  quantity: number
}

interface IPackageItemStore {
	id: number
  quantity: number
}

const defaultPackageItemStore = Immutable.Record<IPackageItemStore>({
	id: 0,
  quantity: 0,
});

export class PackageItemStore extends defaultPackageItemStore implements IPackageItemStore {}