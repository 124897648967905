import i18next from 'i18next';
import { Dispatch } from 'redux';

import { createAction } from '../../Common/_actions/Action';
import { AjaxCallback, AjaxResponse, createAjaxAction } from '../../Common/_actions/AjaxAction';
import { Toast } from '../../UIData/_actions/UIDataActions';
import { UserStorePresetPayload } from '../_stores/UserStorePresetStore';

export enum UserStorePresetActionType {
	AddUserStorePresets   = "ADD_USER_STORE_PRESETS",
	EditUserStorePresets  = "EDIT_USER_STORE_PRESETS",
	RemoveUserStorePreset = "REMOVE_USER_STORE_PRESET",

	FetchUserStorePresets = 'USER_STORE_PRESET_FETCH_USER_STORE_PRESETS',
}

export const RemoveUserStorePreset = (id: string) => createAction(UserStorePresetActionType.RemoveUserStorePreset, {
	id: id
});

export const AddUserStorePresets = (storePresets: object) => createAction(UserStorePresetActionType.AddUserStorePresets, {
	storePresets: storePresets
});

export const EditUserStorePresets = (storePresets: object) => createAction(UserStorePresetActionType.EditUserStorePresets, {
	storePresets: storePresets
});

export const FetchUserStorePresets = () => createAjaxAction<{
	storePresets: UserStorePresetPayload[]
}>(UserStorePresetActionType.FetchUserStorePresets, {
	url: "ajax/api/user/store-presets",
	method: "GET",
	preventDuplicates: true,
	onSuccess: (response, dispatch) => {
		dispatch(AddUserStorePresets(response.data.storePresets));
	},
	onFailure: (response, dispatch) => {
		if(response && response.data && response.data.error)
			dispatch(Toast(response.data.error));
		else
			dispatch(Toast(i18next.t('Something went wrong!')));
	}
});

export type UserStorePresetAction =
	ReturnType<typeof AddUserStorePresets>		|
	ReturnType<typeof EditUserStorePresets>		|
	ReturnType<typeof RemoveUserStorePreset>