import { DialogActions as MuiDialogActions, DialogActionsProps as MuiDialogActionsProps, styled } from '@mui/material';


const StyledDialogActions = styled(MuiDialogActions)({
    justifyContent: 'space-between',
    padding: 0,
    background: 'rgba(0, 0, 0, 0.06)',
});

type Props = MuiDialogActionsProps & {}

export default function DialogActions(props: Props) {
    return <StyledDialogActions {...props} />
}