import * as React from 'react'
import { type UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore'
import { Card, Grid, styled } from '@mui/material'

const ProductCard = styled(Card)({
  borderRadius: '20px',
  position: 'relative',
  overflow: 'hidden',
  background: '#fff',
  height: '100%',
  transition: 'none',
  display: 'flex',
  flexDirection: 'column',

  boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.05), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.08)'

  /* '&:before': {
      border: '2px solid transparent',
      borderRadius: '20px',
      bottom: 0,
      content: '""',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      transition: 'border 250ms',
  },

  '&.selected:before': {
      borderColor: '#000',
  } */
})

const ProductThumbnail = styled('img')({
  width: '100%',
  height: 'auto',
  aspectRatio: '1120 / 1000',
  display: 'block'
})

export const ProductCardWrapper = styled(Grid)(({ theme }) => ({
  width: '20%',

  ['@media (max-width: ' + theme.breakpoints.values.xl + 'px)']: {
    width: '25%'
  },

  ['@media (max-width: ' + theme.breakpoints.values.lg + 'px)']: {
    width: '33.3%'
  },

  ['@media (max-width: ' + theme.breakpoints.values.md + 'px)']: {
    width: '50%'
  },

  ['@media (max-width: ' + theme.breakpoints.values.sm + 'px)']: {
    width: '50%'
  },

  '&.list-view': {
    width: '100%',
    paddingTop: 0,

    '&:last-child': {
      [`${ProductCard}`]: {
        borderBottom: '0'
      }
    },

    [`${ProductCard}`]: {
      borderRadius: '0px',
      boxShadow: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '10px 0',
      alignItems: 'center',
      background: 'transparent',
      flexDirection: 'row'
    },

    [`${ProductThumbnail}`]: {
      width: '80px',
      borderRadius: '15px'
    },

    [`${ProductTitle}`]: {
      padding: '5px 15px'
    },

    [`${ButtonsWrapper}`]: {
      padding: '0 0 0 15px'
    }
  }
}))

const ProductTitle = styled('div')({
  color: 'black',
  fontSize: '1.05rem',
  fontWeight: 500,
  padding: '10px 15px',
  flexGrow: 1
})

const ButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 0 10px 15px',
  gap: '10px'
})

interface Props {
  view: 'grid' | 'list'
  item: UserProductDesignStore
  actions?: React.ReactNode
}

export default function ProductDesign(props: Props) {
  return <ProductCardWrapper item key={props.item.get('id')} className={props.view === 'list' ? 'list-view' : ''}>
    <ProductCard elevation={props.view === 'grid' ? 4 : 0}>
      <ProductThumbnail src={props.item.get('thumbnail')} />
      <ProductTitle>{props.item.get('name')}</ProductTitle>
      { !!props.actions ? <ButtonsWrapper>
        { props.actions }
      </ButtonsWrapper> : null }
    </ProductCard>
  </ProductCardWrapper>
}
