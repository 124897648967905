import i18next from "i18next";
import { createAction } from "../../Common/_actions/Action";
import { createAjaxAction } from "../../Common/_actions/AjaxAction";
import { Toast } from "../../UIData/_actions/UIDataActions";
import { UserTaxNumberPayload } from "../_stores/UserTaxNumberStore";

export enum UserTaxNumberActionType {
	EditUserTaxNumber			= "EDIT_USER_TAX_NUMBER",
    EditManyUserTaxNumbers		= "EDIT_MANY_USER_TAX_NUMBERS",
	RemoveUserTaxNumber			= "REMOVE_TAX_NUMBER",

	CreateUserTaxNumber = 'USER_CREATE_USER_TAX_NUMBER',
	GetUserTaxNumbers = 'USER_GET_USER_TAX_NUMBERS',
	DeleteUserTaxNumber = 'USER_DELETE_USER_TAX_NUMBER',
}

export const EditUserTaxNumber = (taxNumber: UserTaxNumberPayload) => createAction(UserTaxNumberActionType.EditUserTaxNumber, {
	taxNumber: taxNumber
});

export const EditManyUserTaxNumbers = (taxNumbers: UserTaxNumberPayload[]) => createAction(UserTaxNumberActionType.EditManyUserTaxNumbers, {
	taxNumbers: taxNumbers
});

export const RemoveUserTaxNumber = (id: number) => createAction(UserTaxNumberActionType.RemoveUserTaxNumber, {
	id: id
});

export const CreateUserTaxNumber = (data: FormData) => createAjaxAction<{taxNumber: UserTaxNumberPayload}>(UserTaxNumberActionType.CreateUserTaxNumber, {
	url: "ajax/api/user/tax-numbers",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(EditUserTaxNumber(response.data.taxNumber));
        dispatch(Toast(i18next.t('Tax number added!')));
	},
	onFailure: (response, dispatch) => {
		dispatch(Toast(i18next.t('Something went wrong!')));
	}
});

export const GetUserTaxNumbers = () => createAjaxAction<{taxNumbers: UserTaxNumberPayload[]}>(UserTaxNumberActionType.GetUserTaxNumbers, {
	url: "ajax/api/user/tax-numbers",
	method: "GET",
	preventDuplicates: true,
	onSuccess: (response, dispatch) => {
		dispatch(EditManyUserTaxNumbers(response.data.taxNumbers));
	},
	onFailure: (response, dispatch) => {
		if(response.data?.error)
			dispatch(Toast(response.data.error));
		else
			dispatch(Toast(i18next.t('Something went wrong!')));
	}
});

export const DeleteUserTaxNumber = (data:FormData) => createAjaxAction<{id: number}>(UserTaxNumberActionType.DeleteUserTaxNumber, {
	url: "ajax/api/user/tax-numbers/delete",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(RemoveUserTaxNumber(response.data.id));
		dispatch(Toast(i18next.t('Tax number deleted successfully!')));
	},
	onFailure: (response, dispatch) => {
		if(response && response.data && response.data.error)
			dispatch(Toast(response.data.error));
		else
			dispatch(Toast(i18next.t('Something went wrong!')));
	}
});

export type UserTaxNumberAction =
    ReturnType<typeof EditUserTaxNumber> |
	ReturnType<typeof EditManyUserTaxNumbers> |
	ReturnType<typeof RemoveUserTaxNumber>