import { useTranslation } from 'react-i18next';
import ViewCategoryStore from '../AppData/_stores/ViewCategoryStore';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import WebsiteStore from '../WebsiteStore';

type UrlParams = { category1?: string, category2?: string };

export type Props = {}

const HeaderContent = styled('div')({
	alignSelf: 'center',
    maxWidth: '650px',
    marginBottom: '2rem',

	['@media (max-width: 600px)']: {
		textAlign: 'center',
	}
});

const PinkTitle = styled('h4')({
	fontFamily: 'Rottersand',
	fontWeight: 400,
	fontSize: '36px',
	color: '#DA3192',
});

export default function Header(props: Props) {
    const [t] = useTranslation('products')
    const params = useParams()
    const viewCategories = useSelector((state:WebsiteStore) => state.get('appData').get('viewCategories'));

    const ct1 = params.category1;
    const ct2 = params.category2;
    let selectedCategory = null;
    let parentCategoryTitle = undefined;

    if (ct1 && !ct2) selectedCategory = viewCategories.find((category:ViewCategoryStore) => category.get('url_slug') === ct1);
    if (ct1 && ct2) selectedCategory = viewCategories.find((category:ViewCategoryStore) => category.get('url_slug') === ct2);
    if (ct1) {
        parentCategoryTitle = viewCategories.find((category:ViewCategoryStore) => category.get('url_slug') === ct1).get('name');
    }

    return <>
        <HeaderContent>
            {parentCategoryTitle && <PinkTitle>
                {parentCategoryTitle}
            </PinkTitle>}
            <h1>{selectedCategory ? selectedCategory.get('name') : t('All Products')}</h1>
            <div>{selectedCategory && selectedCategory.get('description')}</div>
        </HeaderContent>
    </>
}