import { createSelector } from "reselect"
import { selectUserData } from "./UserDataSelectors"
import UserRewardStore from "../_stores/UserRewardStore"
import Immutable from "immutable"


export const selectUnusedRewards = createSelector(
  [selectUserData], 
  userData => userData.get('rewards').filter(reward => !reward.get('used')),
  {
    memoizeOptions: {
      resultEqualityCheck: (a:Immutable.OrderedMap<string, UserRewardStore>, b:Immutable.OrderedMap<string, UserRewardStore>) => {
        return a === b || (
          a instanceof Immutable.OrderedMap
          && b instanceof Immutable.OrderedMap
          && a.count() === b.count() 
          && a.every((order,key) => order.equals(b.get(key)))
        )
      }
    }
  }
)