import { type ChangeEvent, type SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DialogButton from '../../Common/Buttons/DialogButton'
import Dialog from '../../Common/Dialog/Dialog'
import DialogActions from '../../Common/Dialog/DialogActions'
import DialogContent from '../../Common/Dialog/DialogContent'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { Alert, AlertTitle, Box, Button, Checkbox, FormControlLabel, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import ImageLibraryBrowser from '../../DesignLab/ImageLibraryBrowser/_components/ImageLibraryBrowser'
import { type UserImageStore } from '../../UserData/_stores/UserImageStore'
import ReactCrop, { centerCrop, makeAspectCrop, type Crop } from 'react-image-crop'
import { Rotate90DegreesCwRounded as Rotate90DegreesCwRoundedIcon } from '@mui/icons-material'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { CreateBrandingOption } from '../../UserData/_actions/BrandingOptionActions'
import type BrandingOptionTypeStore from '../../AppData/_stores/BrandingOptionTypeStore'

interface Props {
  opened: boolean
  onClose: () => void
}

export default function AddBrandingOptionDialog(props: Props) {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()

  const listTypes = useAppSelector(state => state.get('appData').get('brandingOptionTypes'))
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))
  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('edit_branding_option'))

  const imageRef = useRef<HTMLImageElement>(null)

  const [selectedTypeId, setSelectedTypeId] = useState<number | undefined>(undefined)
  const [libraryOpened, setLibraryOpened] = useState(false)
  const [selectedImage, setSelectedImage] = useState<UserImageStore | null>(null)
  const [crop, setCrop] = useState<Crop>()
  const [pixelCrop, setPixelCrop] = useState<Crop>()
  const [rotated, setRotated] = useState(false)
  const [name, setName] = useState('')
  const [isDefault, setIsDefault] = useState(false)

  useEffect(() => {
    if (!props.opened) {
      setSelectedTypeId(undefined)
      setLibraryOpened(false)
      setSelectedImage(null)
      setRotated(false)
      setName('')
      setIsDefault(false)
    }
  }, [props.opened])

  const selectedType = useMemo(() => {
    return listTypes.get(String(selectedTypeId))
  }, [listTypes, selectedTypeId])

  const aspect = useMemo(() => {
    if (!selectedType) return 1

    const width = selectedType.get('width')
    const height = selectedType.get('height')
    return rotated ? height / width : width / height
  }, [selectedType, rotated])

  useEffect(() => {
    // Update crop on aspect change
    if (!imageRef.current) return
    setPixelCrop(centerAspectPixelCrop(imageRef.current.width, imageRef.current.height, aspect))
    setCrop(centerAspectCrop(imageRef.current.width, imageRef.current.height, aspect))
  }, [aspect])

  const onConfirm = useCallback(() => {
    if (selectedTypeId === undefined || !crop || !selectedImage) return

    dispatch(CreateBrandingOption(
      selectedTypeId,
      selectedImage.get('fileid'),
      name,
      isDefault,
      {
        x: crop.x / 100,
        y: crop.y / 100,
        width: crop.width / 100,
        height: crop.height / 100,
        rotation: rotated ? 90 : 0
      }
    ).set({
      onSuccess: () => {
        props.onClose()
      }
    }))
  }, [selectedTypeId, selectedImage, crop, rotated, name, isDefault])

  const onLibraryOpen = useCallback(() => {
    setLibraryOpened(true)
  }, [])

  const onLibraryClose = useCallback(() => {
    setLibraryOpened(false)
  }, [])

  const onSelectImage = useCallback((item: UserImageStore) => {
    setSelectedImage(item)
  }, [])

  const centerAspectCrop = useCallback((width: number, height: number, aspect: number) => {
    return centerCrop(
      makeAspectCrop({
        unit: '%',
        width: 100
      }, aspect, width, height),
      width, height)
  }, [])

  const centerAspectPixelCrop = useCallback((width: number, height: number, aspect: number) => {
    return centerCrop(
      makeAspectCrop({
        unit: 'px',
        width: width
      }, aspect, width, height),
      width, height)
  }, [])

  const onImageLoad = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setPixelCrop(centerAspectPixelCrop(width, height, aspect))
    setCrop(centerAspectCrop(width, height, aspect))
  }, [aspect])

  const onRotate = useCallback(() => {
    setRotated(value => !value)
  }, [])

  const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }, [])

  const onChangeDefault = useCallback((e: SyntheticEvent<Element>, checked: boolean) => {
    setIsDefault(checked)
  }, [])

  const renderType = useCallback((type: BrandingOptionTypeStore) => {
    return <Grid key={type.get('id')} item xs={12} md={6}>
      <Stack direction="row" spacing={3} alignItems="center">
        <Paper
          elevation={0}
          sx={{
            overflow: 'hidden',
            width: {
              xs: '40%',
              sm: '50%'
            },
            maxWidth: '220px',
            flexShrink: 0
          }}
        >
          <img
            src={require('@resources/img/account/branding-options/' + type.get('slug') + '.jpg')}
            style={{
              display: 'block',
              width: '100%',
              height: 'auto'
            }}
          />
        </Paper>
        <Stack spacing={1} alignItems="flex-start">
          <Typography variant="h4">{type.get('name')}</Typography>
          <Typography variant="body1">{type.get('description')}</Typography>
          <Button
            key={type.get('id')}
            variant="outlined"
            color="secondary"
            onClick={() => setSelectedTypeId(type.get('id'))}
            disabled={loader}
          >{t('Create {{type}}', { type: type.get('name') })}</Button>
        </Stack>
      </Stack>
    </Grid>
  }, [selectedTypeId, loader])

  const valid = useMemo(() => {
    return selectedImage && name !== '' && selectedType
  }, [selectedImage, name, selectedType])

  return <Dialog
    open={props.opened}
    maxWidth={selectedType ? 'md' : 'lg'}
    onClose={props.onClose}
    showCloseButton
    ornaments="shapes-3-top"
  >
    <DialogTitle
      textAlign={{
        xs: 'left',
        sm: 'center'
      }}
    >{t('Create Branding option')}</DialogTitle>
    <DialogContent>
      { !selectedType ? <Box
        component="div"
        sx={{
          px: {
            xs: 0,
            sm: 4,
            md: 5
          },
          py: 4
        }}
      >
        <Grid container spacing={3}>
          {listTypes.valueSeq().map(renderType)}
        </Grid>
      </Box> : <Stack
        spacing={4}
        sx={{
          px: {
            xs: 0,
            sm: 4,
            md: 5
          },
          pt: 2
        }}
      >
        <Stack direction="row" spacing={3} alignItems="center">
          <Paper
            elevation={0}
            sx={{
              overflow: 'hidden',
              width: {
                xs: '80px',
                sm: '160px'
              },
              flexShrink: 0
            }}
          >
            <img
              src={require('@resources/img/account/branding-options/' + selectedType.get('slug') + '.jpg')}
              style={{
                display: 'block',
                width: '100%',
                height: 'auto'
              }}
            />
          </Paper>
          <Stack spacing={1} alignItems="flex-start">
            <div>
              <Typography variant="h4">{selectedType.get('name')}</Typography>
              <Typography variant="body1">{selectedType.get('description')}</Typography>
            </div>
            <div>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '600',
                  color: 'accent.main'
                }}
              >{t('{{price}} per {{type}}', { price: selectedType.get('price').toDinero().toFormat(), type: selectedType.get('name') })}</Typography>
              { selectedType.get('slug') === 'label' ? <Typography
                variant="body1"
                sx={{
                  fontWeight: '600',
                  color: 'accent.main'
                }}
              >{t('FREE for wholesale')}</Typography> : null }
              {selectedType.get('slug') === 'sticker' ? <Typography variant="caption">{t('Limited to 1 per order')}</Typography> : null }
            </div>
          </Stack>
        </Stack>

        <div>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} md>
              <Stack spacing={2}>
                <TextField
                  label={t('Name my branding option')}
                  value={name}
                  onChange={onChangeName}
                  disabled={loader}
                  fullWidth
                />

                <div>
                  <FormControlLabel
                    label={<Stack spacing={0.5}>
                      <Typography variant="body2">{t('Automatically add to my orders')}</Typography>
                      <Typography variant="caption">{t('It will be automatically added to all orders placed on the website, or from your store(s). This setting can only be applied to one {{name}}.', { name: selectedType.get('name') })}</Typography>
                    </Stack>}
                    control={<Checkbox />}
                    checked={isDefault}
                    onChange={onChangeDefault}
                    disabled={loader}
                  />
                </div>

                <Alert
                  severity="info"
                >
                  <AlertTitle>{t('Design guidelines')}</AlertTitle>
                  {t('Dimensions: {{width}}px x {{height}}px', { width: selectedType.get('width'), height: selectedType.get('height') })}
                </Alert>
              </Stack>
            </Grid>
            <Grid item xs={12} md="auto">
              <Stack spacing={2}>
                <Box component="div" sx={{
                  width: 300,
                  height: 300,
                  background: '#EBEBEB',
                  position: 'relative',
                }}>
                  {selectedImage ? <>
                    <ReactCrop
                      crop={crop}
                      onChange={(crop, percentCrop) => {
                        setPixelCrop(crop)
                        setCrop(percentCrop)
                      }}
                      minWidth={20}
                      aspect={aspect}
                      keepSelection
                      disabled={loader}
                    >
                      <img
                        ref={imageRef}
                        src={'/api/user/images/image/' + selectedImage.get('fileid')}
                        onLoad={onImageLoad}
                        style={{
                          maxWidth: 300,
                          maxHeight: 300
                        }}
                      />
                    </ReactCrop>
                    {selectedType.get('slug') === 'label' && pixelCrop !== undefined ? <img
                      src={require('@resources/img/account/branding-options/label-template-'+locale+'.png')}
                      style={{
                        position: 'absolute',
                        top: pixelCrop.y,
                        left: pixelCrop.x,
                        width: (rotated ? pixelCrop.height : pixelCrop.width)+'px',
                        height: (rotated ? pixelCrop.width : pixelCrop.height)+'px',
                        transform: rotated ? 'rotate(90deg)' : '',
                        transformOrigin:  rotated ? ((pixelCrop.width/2)+'px '+(pixelCrop.width/2)+'px') : ((pixelCrop.height/2)+'px '+(pixelCrop.height/2)+'px'),
                        pointerEvents: 'none',
                      }}
                    /> : null }
                  </> : <Box
                    component="div"
                    sx={{
                      width: '100%',
                      height: '100%',
                      border: 'dashed 2px #707070',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onLibraryOpen}
                      disabled={loader}
                    >{t('Add Image')}</Button>
                  </Box>}
                </Box>

                {selectedImage ? <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    color="gray"
                    onClick={onLibraryOpen}
                    disabled={loader}
                  >{t('Change Image')}</Button>

                  <Button
                    variant="contained"
                    color="gray"
                    onClick={onRotate}
                    disabled={loader}
                    startIcon={<Rotate90DegreesCwRoundedIcon />}
                  >{t('Rotate')}</Button>
                </Stack> : null}
              </Stack>
            </Grid>
          </Grid>
        </div>
      </Stack> }
    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
        disabled={loader}
      >{t('Cancel')}</DialogButton>
      <DialogButton
        color="primary"
        onClick={onConfirm}
        autoFocus
        disabled={!!loader || !valid}
      >{t('Save')}</DialogButton>
    </DialogActions>

    <ImageLibraryBrowser
      opened={libraryOpened}
      onSelect={onSelectImage}
      onClose={onLibraryClose}
    />
  </Dialog>
}
