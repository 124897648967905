import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import { useCallback, useEffect, useMemo } from 'react'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import type Immutable from 'immutable'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import SkeletonLoader from '../../Common/Loader/SkeletonLoader'
import { type AjaxResponse } from '../../Common/_actions/AjaxAction'
import { type CreateOrderData, type CreateOrderActions, type OnApproveData, type OnApproveActions } from '@paypal/paypal-js'
import { type ButtonProps } from '@mui/material'
import { type UserCartPayload } from '../../UserData/_stores/UserCartStore'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { EditManyOrders } from '../../UserData/_actions/OrderStoreActions'

type Props = ButtonProps & {
  orders: Immutable.Map<string, UserCartStore>
  confirmationUrl: string
  onErrorText: (error: string) => void
  onDisable: (disable: boolean) => void
}

export default function PayPalPaymentButton(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')
  const navigate = useNavigate()
  const [{ isPending: paypalIsPending, options: paypalOptions }, paypalDispatch] = usePayPalScriptReducer()

  const createOrder = useCallback(async (data: CreateOrderData, actions: CreateOrderActions) => {
    props.onDisable(true)
    props.onErrorText('')

    // Provide id order if we're paying an 'ordered' order
    let idOrder: number | undefined
    if (props.orders.count() === 1 && props.orders.first()?.get('status') === 'ordered') {
      idOrder = props.orders.first()?.get('id')
    }

    const fd = new FormData()
    if (idOrder !== undefined) {
      fd.append('id_order', String(idOrder))
    }

    const controller = new AbortController()

    return await fetch('ajax/checkout/paypal-v2/create-order', {
      method: 'POST',
      body: fd,
      signal: controller.signal
    })
      .then(async response => {
        if (!response.ok) {
          if (response.status === 400) {
            const data = await response.json() as { data?: { error: string | undefined } } | undefined
            if (data?.data?.error) {
              throw new Error(data.data.error)
            }
          }

          throw new Error('An error occurred')
        }
        return response
      })
      .then(async response => await response.json())
      .then((response: AjaxResponse<{ id: string }>) => response.data.id)
  }, [t, props.onDisable, props.onErrorText, props.orders])

  const onApprove = useCallback(async (data: OnApproveData, actions: OnApproveActions) => {
    props.onDisable(true)
    const fd = new FormData()
    fd.append('id', data.orderID)

    const controller = new AbortController()

    await fetch('ajax/checkout/paypal-v2/on-approve', {
      method: 'POST',
      signal: controller.signal,
      body: fd
    })
      .then(response => {
        if (!response.ok) throw new Error('An error occurred')
        return response
      })
      .then(async response => await response.json())
      .then((response: AjaxResponse<{ payment_id: string, orders: Record<string, UserCartPayload> }>) => {
        dispatch(EditManyOrders(response.data.orders))
        navigate(props.confirmationUrl + '?payment_id=' + encodeURIComponent(response.data.payment_id))
      })
      .catch(() => {
        props.onDisable(false)
        props.onErrorText(t('An error occured processing your order. Please try again later.'))
      })
  }, [t, props.confirmationUrl, props.onDisable, props.onErrorText])

  const onCancel = useCallback(() => {
    props.onDisable(false)
    props.onErrorText('')
  }, [props.onDisable, props.onErrorText])

  const onError = useCallback((error: Record<string, unknown>) => {
    props.onDisable(false)

    if (error?.message === 'invalid-shipping-address') {
      props.onErrorText(t('Your shipping address is invalid. Please edit and reconfirm your address.'))
    } else if (error?.message === 'invalid-billing-address') {
      props.onErrorText(t('Your billing address is invalid. Please edit reconfirm your address.'))
    } else {
      props.onErrorText(t('An error occured processing your order with PayPal. Please try again later.'))
    }

    console.log('PayPal onError', error)
  }, [t, props.onDisable, props.onErrorText])

  const firstOrder = useMemo(() => {
    return props.orders.first()
  }, [props.orders])

  const currency = useMemo(() => {
    if (!firstOrder) return 'USD'

    return firstOrder.get('total').get('currency')
  }, [firstOrder])

  useEffect(() => {
    // Trigger paypal update on currency change
    paypalDispatch({
      type: 'resetOptions',
      value: {
        ...paypalOptions,
        currency
      }
    })
  }, [currency])

  return <SkeletonLoader
    loading={paypalIsPending}
    variant="rounded"
    height={42}
    width={150}
  >
    <PayPalButtons
      createOrder={createOrder}
      onApprove={onApprove}
      onCancel={onCancel}
      onError={onError}
      disabled={props.disabled}
      style={{
        shape: 'pill',
        color: 'gold',
        label: 'paypal',
        tagline: false,
        layout: 'vertical',
        height: 42
      }}
    />
  </SkeletonLoader>
}
