import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import Dialog from '../Common/Dialog/Dialog';
import DialogTitle from '../Common/Dialog/DialogTitle';
import DialogContent from '../Common/Dialog/DialogContent';
import DialogActions from '../Common/Dialog/DialogActions';
import DialogButton from '../Common/Buttons/DialogButton';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from '../WebsiteStore';
import { Currency, Locale } from '../UserData/_stores/UserPrefsStore';
import { SaveUserPrefs } from '../UserData/_actions/UserDataActions';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../Common/_hooks/useAppDispatch';
import { useAppSelector } from '../Common/_hooks/useAppSelector';

const FormFlexWrap = styled('div')({
  display: 'flex',
  '.MuiFormControl-root': {
    flex: 1,
  },
  '.MuiFormLabel-root': {
    fontSize: 18,
    color: '#000',
    fontWeight: 600,
  },
  '.MuiFormControlLabel-root span': {
    fontSize: 16,
    color: '#000',
  }
});


const LanguageText = styled('div')({
  marginBottom: '15px',
  fontSize: 14,
});

type Props = {
  opened: boolean
  onClose: () => void
}

export default function PreferencesDialog(props: Props) {
  const [t] = useTranslation('header');
  const dispatch = useAppDispatch();

  const userLocale = useAppSelector(state => state.get('userData').get('prefs').get('lang'));
  const userCurrency = useAppSelector(state => state.get('userData').get('prefs').get('currency'));

  const [locale, setLocale] = useState<Locale>('en_CA');
  const [currency, setCurrency] = useState<Currency>('USD');

  useEffect(() => {
    setLocale(userLocale);
    setCurrency(userCurrency)
  }, [userLocale, userCurrency])

  const onChangeLocale = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setLocale(e.target.value as Locale), []);
  const onChangeCurrency = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setCurrency(e.target.value as Currency), []);

  const onConfirm = useCallback(() => {
    const fd = new FormData();
    fd.append('locale', locale);
    fd.append('currency', currency);

    dispatch(SaveUserPrefs(fd, true, () => {
      window.location.reload();
    }));

    props.onClose();
  }, [props.onClose, locale, currency]);

  return <Dialog
    open={props.opened}
    onClose={props.onClose}
    fullWidth
  >
    <DialogTitle>
      {t('Preferences')}
    </DialogTitle>
    <DialogContent>
      <LanguageText>{t('Switching currency alters displayed prices on the site. When you order, currency and prices adjust to your billing country.')}</LanguageText>
      <FormFlexWrap>
        <FormControl>
          <FormLabel>{t('Language')}</FormLabel>
          <RadioGroup
            value={locale}
            onChange={onChangeLocale}
          >
            <FormControlLabel value="en_CA" control={<Radio />} label="English" />
            <FormControlLabel value="fr_CA" control={<Radio />} label="Français" />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>{t('Currency')}</FormLabel>
          <RadioGroup
            value={currency}
            onChange={onChangeCurrency}
          >
            <FormControlLabel value="USD" control={<Radio />} label="USD" />
            <FormControlLabel value="CAD" control={<Radio />} label="CAD" />
          </RadioGroup>
        </FormControl>
      </FormFlexWrap>
    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
      >{t('Cancel')}</DialogButton>
      <DialogButton
        color="primary"
        onClick={onConfirm}
      >{t('Confirm')}</DialogButton>
    </DialogActions>
  </Dialog>
}