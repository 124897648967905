import { Grid, styled } from '@mui/material';
import * as Immutable from 'immutable';
import ViewProductStore from '../AppData/_stores/ViewProductStore';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WebsiteStore from '../WebsiteStore';
import Header from './Header';
import ProductCard from './ProductCard';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from '../Common/_hooks/useAppSelector';

export type Props = {}

const ProductGrid = styled(Grid)({
    '@media(max-width:1280px) and (min-width:940px)': {
        maxWidth: '50%',
        flexBasis: '50%',
    }
});

export default function ProductsList(props:Props) {
    const params = useParams<{category1?: string, category2?: string}>()
    const [searchParams] = useSearchParams();
    const viewCategories = useAppSelector(state => state.get('appData').get('viewCategories'));
    const viewProducts = useAppSelector(state => state.get('appData').get('viewProducts'));
    const viewProductAttributeTypes = useAppSelector(state => state.get('appData').get('viewProductAttributeTypes'));

    const getTypeFilters = useCallback((type: string) => {
        const type_slug = viewProductAttributeTypes.get(type)?.get('slug')
        const filterParam = type_slug && searchParams.has(type_slug) ? searchParams.get(type_slug) : null
        return filterParam !== null ? filterParam.split(',') : []
    }, [viewProductAttributeTypes, searchParams])

    const category1 = useMemo(() => {
        return params.category1 !== undefined ? viewCategories.find(category => category.get('url_slug') === params.category1) : undefined
    }, [viewCategories, params.category1])

    const category2 = useMemo(() => {
        return params.category2 !== undefined ? viewCategories.find(category => category.get('url_slug') === params.category2) : undefined
    }, [viewCategories, params.category2])

    const sortedViewProducts = useMemo(() => {
        return viewProducts
            .sort((a,b) => {
                if(a.get('is_new')) {
                    if(b.get('is_new')) {
                        return a.get('id') > b.get('id') ? -1 : 1
                    } else {
                        return -1
                    }
                } else {
                    if(b.get('is_new')) {
                        return 1
                    } else {
                        return a.get('id') > b.get('id') ? -1 : 1
                    }
                }
            })
    }, [viewProducts])

    const visibleProducts = useMemo(() => {
        //Categories
        let tmpVisibleProducts = viewProducts.filter(product => {
            if(!category1) {
                return true
            }

            let isVisible = false;

            //Check for direct category
            if(category2) {
                isVisible = product.get('categories').contains(category2.get('id'))
            } else {
                //Check for direct child of category1
                if(product.get('categories').contains(category1.get('id'))) {
                    isVisible = true
                } else {
                    //Check for parent category
                    product.get('categories').forEach(categoryId => {
                        const category = viewCategories.get(String(categoryId));
                        if(!category) return
    
                        const parentId = category.get('id_parent');
                        if(parentId !== null && parentId === category1?.get('id')) {
                            isVisible = true
                            return false
                        }
                    })
                }
            }

            return isVisible
        });

        //Filters
        tmpVisibleProducts = tmpVisibleProducts.filter(p => {
            let keep = true;
            viewProductAttributeTypes.forEach((type, type_id) => {
                const filters = getTypeFilters(type_id)
                if(!filters.length) return

                if(!p.get('listAttributes').has(type_id) 
                    || p.get('listAttributes').has(type_id) && !filters.includes(String(p.get('listAttributes').get(type_id)))
                ) {
                    return keep = false
                }
            });
            return keep;
        })

        return tmpVisibleProducts
    }, [viewProducts, viewCategories, category1, category2, viewProductAttributeTypes, getTypeFilters]);
    
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Header />
        </Grid>
        {sortedViewProducts.entrySeq().map(([id, product]) => {
            let hidden = !visibleProducts.has(id);
            return <ProductGrid className="products-item" key={product.get('id')} style={{display: hidden ? 'none' : 'block'}} item xs={6} sm={6} md={4} xl={3}>
                <ProductCard product={product} />
            </ProductGrid>
        })}
    </Grid>
}