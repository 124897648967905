import { useCallback, useState } from 'react'
import * as React from 'react'
import Dialog from '../../Common/Dialog/Dialog'
import { type UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, TextField } from '@mui/material'
import { AddRounded as AddRoundedIcon, CloseRounded as CloseRoundedIcon, CheckRounded as CheckRoundedIcon } from '@mui/icons-material'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import { AddToCollection, CreateCollection } from '../../UserData/_actions/CollectionActions'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { type CollectionStore } from '../../UserData/_stores/CollectionStore'

interface Props {
  productDesign: UserProductDesignStore
  opened: boolean
  onClose: () => void
}

export default function AddToCollectionDialog(props: Props) {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()

  const collections = useAppSelector(state => state.get('userData').get('collections'))

  const [newCollectionOpened, setNewCollectionOpened] = useState(false)
  const [name, setName] = useState('')

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }, [])

  const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(CreateCollection(name).set({
      onSuccess: (response) => {
        setNewCollectionOpened(false)
        setName('')
        dispatch(AddToCollection(
          response.data.item.id,
          props.productDesign.get('id_product_design')
        ))
      }
    }))
  }, [props.onClose, name])

  const onNewCollectionOpen = useCallback(() => {
    setNewCollectionOpened(true)
  }, [])

  const onNewCollectionClose = useCallback(() => {
    setNewCollectionOpened(false)
  }, [])

  const renderCollection = useCallback((collection: CollectionStore) => {
    // Disable this collection if product design is already part of it
    let isInCollection = false
    collection.get('listItems').forEach(item => {
      if (item.get('id_product_design') === props.productDesign.get('id_product_design')) {
        isInCollection = true
        return false
      }
    })

    return <ListItemButton
      key={collection.get('id')}
      disabled={isInCollection}
      onClick={() => {
        dispatch(AddToCollection(
          collection.get('id'),
          props.productDesign.get('id_product_design')
        ))
      }}
    >
      <ListItemText>{collection.get('name')}</ListItemText>
      { isInCollection ? <CheckRoundedIcon/> : null }
    </ListItemButton>
  }, [props.productDesign])

  return <>
    <Dialog
      open={props.opened}
      maxWidth="xs"
      fullWidth
      onClose={props.onClose}
      showCloseButton
    >
      <DialogTitle>{t('Add to collection')}</DialogTitle>
      <List>
        {collections.valueSeq().map(renderCollection)}

        {!newCollectionOpened ? <ListItemButton onClick={onNewCollectionOpen}>
          <ListItemIcon><AddRoundedIcon color="primary" /></ListItemIcon>
          <ListItemText sx={{ color: 'primary.main' }}>{t('New collection')}</ListItemText>
        </ListItemButton> : null}

        {newCollectionOpened ? <ListItem>
          <form onSubmit={onSubmit}>
            <Stack direction="row" spacing={1} alignItems="center">
              <TextField
                autoFocus
                variant="filled"
                error={name === ''}
                value={name}
                onChange={onChange}
                label={t('Name')}
                InputProps={{
                  endAdornment: <InputAdornment
                    position="end"
                  >
                    <IconButton
                      onClick={onNewCollectionClose}
                      edge="end"
                    ><CloseRoundedIcon /></IconButton>
                  </InputAdornment>
                }}
              />
              <IconButton
                color="primary"
                size="large"
                type="submit"
                disabled={name === ''}
              ><CheckRoundedIcon /></IconButton>
            </Stack>
          </form>
        </ListItem> : null}
      </List>
    </Dialog>
  </>
}
