import { useCallback } from "react";
import useLabData from "./useLabData";
import useSceneData from "./useSceneData";

export default function useCalcFitToSide() {
    const labData = useLabData();
    const sceneData = useSceneData();

    return useCallback((layerWidth:number, layerHeight:number, side:'left'|'right'|'top'|'bottom') => {
        const listParts = Object.keys(sceneData.parts);
        const part = listParts.length > 1 ? side : listParts[0];
        const outputType = 'lab';
        let rotation = 0;

        if(labData.fit_rotate){
            if(side == "left") rotation = -90;
            else rotation = 90;
        }
    
        //Set values from product (and add a bit of padding)
        let x = sceneData.parts[part][outputType].x,
            y = sceneData.parts[part][outputType].y,
            width = sceneData.parts[part][outputType].width,
            height = sceneData.parts[part][outputType].height;
    
        if(!labData.fit_rotate && !labData.fit_accurate){
            width += 2;
            height += 2;
            x -= 1;
            y -= 1;
        }
        
        //Keep ratio of image
        //Gotta inverse calculation if image is rotated
        let scale;

        if(Math.abs(rotation) === 90) {
            scale = width / layerHeight;

            if(layerWidth*scale < height) {
                scale = height / layerWidth;
            }
        } else {
            scale = height / layerHeight;

            if(layerWidth*scale < width) {
                scale = width / layerWidth;
            }
        }
    
        return {
            x: x + width / 2,
            y: y + height / 2,
            scaleX: scale,
            scaleY: scale,
            rotation: rotation,
        }
    }, [labData, sceneData]);
}