import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { StrictMode, Suspense, useEffect } from "react";
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Outlet, RouterProvider, createBrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import { Action, Store, applyMiddleware, compose, createStore } from "redux";
import AjaxMiddleware from "./Common/_middlewares/AjaxMiddleware";
import RenderMap from "./RenderMap";
import Theme from './Theme';
import { GetAccountData, GetUserPrefs } from "./UserData/_actions/UserDataActions";
import WebsiteReducer from "./WebsiteReducer";
import WebsiteStore, { store } from "./WebsiteStore";
import createEmotionCache from './createEmotionCache';
import { CacheProvider } from '@emotion/react';
import ApplicationDataLayer from './AppData/_components/ApplicationDataLayer';
import { HelmetProvider } from 'react-helmet-async';
import { initReactI18next, useSSR, useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { EnhancedStore, configureStore } from '@reduxjs/toolkit';
import { AjaxAction } from './Common/_actions/AjaxAction';
import I18NextXhrBackend from 'i18next-xhr-backend';
import i18next from 'i18next';
import DesignLab from './DesignLab/_components/DesignLab';
import FullPageLabLoader from './DesignLab/_components/FullPageLabLoader';
import Account from './Account/Account';
import WebsiteDataRouter, { WebsiteRoutes } from './Website';
import { DateTime, Settings as DateTimeSettings } from 'luxon';
import PageLoader from './DesignLab/_components/PageLoader';
import config from '../../config/config.json';
import '@stripe/stripe-js';

window.i18next = i18next;

// init sentry
if (['prod','staging','dev'].includes(config.ENVIRONMENT)) {
	Sentry.init({
		dsn: config.sentry.dsn,
		release: config.sentry.release,
		environment: config.ENVIRONMENT,
		integrations: [
			//Sentry.browserTracingIntegration(),
			/*Sentry.httpClientIntegration({
				failedRequestTargets: window.SENTRY_INCLUDE_PATHS,
			}),*/
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			}),
			Sentry.replayIntegration(),
			//Sentry.replayCanvasIntegration(),
		],
		tracePropagationTargets: [window.SITE_URL],
		sampleRate: 1,
		tracesSampleRate: config.ENVIRONMENT == "prod" ? 0.3 : 1.0,
		replaysSessionSampleRate: config.ENVIRONMENT == "prod" ? 0.1 : 1.0,
		replaysOnErrorSampleRate: 1.0,
		normalizeDepth: 10,
		ignoreErrors: [
			// Random plugins/extensions
		    'top.GLOBALS',
		    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
		    'originalCreateNotification',
		    'canvas.contentDocument',
		    'MyApp_RemoveAllHighlights',
		    'http://tt.epicplay.com',
		    'Can\'t find variable: ZiteReader',
		    'jigsaw is not defined',
		    'ComboSearch is not defined',
		    'http://loading.retry.widdit.com/',
		    'atomicFindClose',
		    // Facebook borked
		    'fb_xd_fragment',
		    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
		    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
		    'bmi_SafeAddOnload',
		    'EBCallBackMessageReceived',
		    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
		    'conduitPage',
		    'NetworkError when attempting to fetch resource.',
		    'Object Not Found Matching Id:'
		],
		denyUrls: [
			// Facebook flakiness
		    /graph\.facebook\.com/i,
		    // Facebook blocked
		    /connect\.facebook\.net\/en_US\/all\.js/i,
		    // Woopra flakiness
		    /eatdifferent\.com\.woopra-ns\.com/i,
		    /static\.woopra\.com\/js\/woopra\.js/i,
		    // Chrome extensions
		    /extensions\//i,
		    /^chrome:\/\//i,
		    // Other plugins
		    /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
		    /webappstoolbarba\.texthelp\.com\//i,
		    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
		]
	});

	Sentry.setUser({
		id: window.APP_USER_ID,
		email: window.APP_USER_EMAIL,
		geo: window.APP_GEO_COUNTRYCODE ? {
			country_code: window.APP_GEO_COUNTRYCODE
		} : undefined,
	});

	Sentry.setTag('logger', 'react.client-app');
	Sentry.setTag("page_locale", window.LOCALE);
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export default class ReactManager {
	public render = async () => {
		if(!window.PRELOADED_STORE) {
			store.dispatch(GetAccountData());
			store.dispatch(GetUserPrefs());
		}

		//Init translator
		const i18n = await i18next
			.use(I18NextXhrBackend)
			.use(initReactI18next)
			.init({
				initImmediate: true,
				supportedLngs: ['en-CA','fr-CA'],
				lng: window.LOCALE.replace('_', '-'),
				fallbackLng: 'en-CA',
				ns: ['master', 'account', 'create'],
				defaultNS: 'master',
				interpolation: {
					escapeValue: false,
				},
				load: 'currentOnly',
				backend: {
					loadPath: window.SITE_URL + 'locale/get/{{lng}}/{{ns}}',
					addPath: window.SITE_URL + 'locale/set/{{lng}}/{{ns}}',
					crossDomain: true,
				},
				keySeparator: '_key_',
				nsSeparator: '_ns_',
				pluralSeparator: '_plural_',
				contextSeparator: '_ctx_',
				saveMissing: false
			}, function (err, t) {
				//Initialized I guess
			});

		DateTimeSettings.defaultLocale = window.LOCALE.replace('_', '-')

		
		const emotionCache = createEmotionCache();
		let root;

		const reactPageContainer = document.getElementById('main');
		if(reactPageContainer?.className === "react-page") {
			function App() {
				useSSR(window.PRELOADED_I18NEXT_STORE || {}, window.LOCALE.replace('_', '-'))
				const [tR] = useTranslation('routes');

				const strictMode = process.env.NODE_ENV !== 'production';

				const app = <Sentry.ErrorBoundary fallback={<>An error occured</>}>
					<HelmetProvider>
						<Provider store={store}>
							<CacheProvider value={emotionCache}>
								<ThemeProvider theme={Theme}>
									<Suspense>
										<Suspense fallback={<PageLoader/>}>
											<RouterProvider router={sentryCreateBrowserRouter(WebsiteRoutes(tR))} />
										</Suspense>
									</Suspense>
								</ThemeProvider>
							</CacheProvider>
						</Provider>
					</HelmetProvider>
				</Sentry.ErrorBoundary>;
				
				return strictMode ? <StrictMode>{ app }</StrictMode> : app;
			} 

			
			if(reactPageContainer.hasChildNodes()) {
				root = hydrateRoot(reactPageContainer, <App/>)
			} else {
				root = createRoot(reactPageContainer)
				root.render(<App/>)
			}
		}


		//TODO: Legacy RenderMap for all other elements that are non-react pages
		RenderMap.forEach((e) => {
			//Get container
			if(!e.container) {
				return;
			}

			let root;

			function App() {
				useSSR(window.PRELOADED_I18NEXT_STORE || {}, window.LOCALE.replace('_', '-'))
				const strictMode = process.env.NODE_ENV !== 'production';

				const app = <Sentry.ErrorBoundary fallback={<></>}>
					<HelmetProvider>
						<Provider store={store}>
							<CacheProvider value={emotionCache}>
								<ThemeProvider theme={Theme}>
									<ApplicationDataLayer>
										<Suspense>
											<RouterProvider router={
												sentryCreateBrowserRouter([
													{ path: "*", Component: e.element, errorElement: process.env.NODE_ENV === 'production' ? <></> : undefined }
												])
											}/>
										</Suspense>
									</ApplicationDataLayer>
								</ThemeProvider>
							</CacheProvider>
						</Provider>
					</HelmetProvider>
				</Sentry.ErrorBoundary>;
				
				return strictMode ? <StrictMode>{ app }</StrictMode> : app;
			} 

			if(e.container.hasChildNodes()) {
				root = hydrateRoot(e.container, <App/>)
			} else {
				root = createRoot(e.container)
				root.render(<App/>)
			}
		})
	}
}