import * as Immutable from 'immutable';


export interface IUserStoreStore {
	id: number,
	slug: string,
	name: string,
}

const defaultStore = Immutable.Record<IUserStoreStore>({
	id: 0,
	slug: "",
	name: "",
});

export default class UserStoreStore extends defaultStore implements IUserStoreStore {}

