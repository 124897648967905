import { useCallback, useMemo, useState } from 'react'
import { type UserAddressStore } from '../../../UserData/_stores/UserAddressStore'
import { useTranslation } from 'react-i18next'
import { Card, CardActions, CardContent, Chip, Grid, Stack, Typography } from '@mui/material'
import EditAddressDialog from './EditAddressDialog'
import DeleteAddressDialog from './DeleteAddressDialog'
import { useAppSelector } from '../../../Common/_hooks/useAppSelector'
import CardButton from '../../../Common/Buttons/CardButton'

interface Props {
  address: UserAddressStore
}

export default function Address(props: Props) {
  const [t] = useTranslation('account-v2')
  const countries = useAppSelector(state => state.get('appData').get('countries'))

  const [editDialogOpened, setEditDialogOpened] = useState(false)
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)

  const onEditOpen = useCallback(() => {
    setEditDialogOpened(true)
  }, [])

  const onEditClose = useCallback(() => {
    setEditDialogOpened(false)
  }, [])

  const onDeleteOpen = useCallback(() => {
    setDeleteDialogOpened(true)
  }, [])

  const onDeleteClose = useCallback(() => {
    setDeleteDialogOpened(false)
  }, [])

  const addressLine1 = useMemo(() => {
    return [
      props.address.get('address'),
      props.address.get('address2')
    ].filter(value => value !== '').join(', ')
  }, [props.address])

  const addressLine2 = useMemo(() => {
    const country = countries.get(String(props.address.get('country')))
    return [
      props.address.get('city'),
      props.address.get('postal_code'),
      props.address.get('province'),
      country?.get('name')
    ].filter(value => value !== '').join(', ')
  }, [props.address, countries])

  return <Grid item xs={12} md={6} lg={4}>
    <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Stack spacing={1} alignItems="flex-start">
          <Typography variant="h3">
            {props.address.get('name')}
          </Typography>
          {props.address.get('default_shipping') || props.address.get('default_billing') ? <Stack direction="row" spacing={1}>
            {props.address.get('default_shipping') ? <Chip
              variant="square-filled"
              size="small"
              color="accent"
              label={t('Default Shipping')}
            /> : null}
            {props.address.get('default_billing') ? <Chip
              variant="square-filled"
              size="small"
              color="primary"
              label={t('Default Billing')}
            /> : null}
          </Stack> : null }
          <Typography variant="caption">
            {addressLine1}<br/>
            {addressLine2}<br/>
            {props.address.get('phone')}
          </Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <CardButton
          color="secondary"
          onClick={onDeleteOpen}
        >{t('Delete')}</CardButton>
        <CardButton
          color="primary"
          onClick={onEditOpen}
        >{t('Edit')}</CardButton>
      </CardActions>
    </Card>

    <EditAddressDialog
      address={props.address}
      opened={editDialogOpened}
      onClose={onEditClose}
    />

    <DeleteAddressDialog
      address={props.address}
      opened={deleteDialogOpened}
      onClose={onDeleteClose}
    />
  </Grid>
}
