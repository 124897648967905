import * as Immutable from 'immutable';

export interface IProductSubCategoryStore {
	id: number
    id_category: number
	slug: string
	name: string
	theorder: number
}

const defaultProductSubCategoryStore = Immutable.Record<IProductSubCategoryStore>({
	id: 0,
    id_category: 0,
	slug: '',
	name: '',
	theorder: 0,
});

export default class ProductSubCategoryStore extends defaultProductSubCategoryStore implements IProductSubCategoryStore {}