import { Grid, styled } from '@mui/material'

export const CartHeader = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '60px',
  alignItems: 'center',
  padding: '40px 0',

  '@media (max-width: 768px)': {
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'stretch'
  }
})

export const CartHeaderH1 = styled('h1')(({ theme }) => ({
  fontSize: '1.8rem',
  fontWeight: 400,
  margin: 0,
  lineHeight: 'normal',
  fontFamily: "'Poppins', 'Roboto', sans-serif",
  color: '#999',

  '> a': {
    color: '#999'
  },

  '> .title': {
    color: theme.palette.secondary.main
  }
}))
