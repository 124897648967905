import { combineReducers } from 'redux-immutable';

import AuthReducer from './Auth/_reducers/AuthReducer';
import DashboardReducer from './Dashboard/_reducers/DashboardReducer';
import AppDataReducer from './AppData/_reducers/AppDataReducer';
import { DATA_VERSION } from './config';
import UIDataReducer from './UIData/_reducers/UIDataReducer';
import UserDataReducer from './UserData/_reducers/UserDataReducer';
import WebsiteStore, { IWebsiteStore, WebsiteStoreFactory } from './WebsiteStore';
import { Action } from 'redux';

const WebsiteReducer = (
	store:WebsiteStore = new WebsiteStore(),
	action: Action
): WebsiteStore => {
	return new WebsiteStore(combineReducers<IWebsiteStore, IWebsiteStore>({
		userData: UserDataReducer,
		UIData: UIDataReducer,
		Auth: AuthReducer,
		Dashboard: DashboardReducer,
		appData: AppDataReducer,
		version: () => DATA_VERSION
	}, WebsiteStoreFactory)(store, action));
}

export default WebsiteReducer;