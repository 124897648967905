import { isBrowser, isNode } from 'browser-or-node';
import { Image } from 'skia-canvas';
import { ImageConfig, Image as KonvaImageRef } from 'konva/lib/shapes/Image';
import { ForwardedRef, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Image as ImageNode } from 'react-konva';
import { ImageLibraryContext } from '../_components/ImageLibrary';

export type Props = {
    src: string
    onLoad?: () => void
    imageDensity?: number
    disableCache?: boolean
} & Omit<ImageConfig, 'image'>;


const KonvaImage = forwardRef(function KonvaImage(props:Props, ref:ForwardedRef<KonvaImageRef|null>) {
    const innerRef = useRef<KonvaImageRef|null>(null);
    const imageRef = useRef<Image|null>();
    const [imageElem,setImageElem] = useState<Image|null>(null);
    const imageLibrary = useContext(ImageLibraryContext)

    const handleLoad = useCallback(() => {
        if(isBrowser) {
            setImageElem(imageRef.current ?? null);
        }
    }, []);

    useEffect(() => {
        if(isBrowser) {      
            let tmpImg = new window.Image();

            //Bypassing ts checks here as I can't figure out a way to map this node-canvas' Image object for the types to work
            //@ts-ignore
            imageRef.current = tmpImg;

            tmpImg.crossOrigin = 'anonymous';
            tmpImg.addEventListener('load', handleLoad);
            tmpImg.src = props.src;

            return () => {
                tmpImg.removeEventListener('load', handleLoad);
                imageRef.current = null;
            }
        }
    }, [props.src, handleLoad]);

    if(isNode) {
        //@ts-ignore
        imageRef.current = imageLibrary.retrieveImage(props.src);
    }
    
    useEffect(() => {
        props.onLoad && props.onLoad();
        
        if(!props.disableCache && innerRef.current !== null) {
            innerRef.current.cache({
                pixelRatio: props.imageDensity
            });
        }
    }, [imageElem, props.imageDensity, props.disableCache])

    useImperativeHandle(ref, () => innerRef.current, [])

    const {src, image, ...otherProps} = props;

    return <ImageNode ref={innerRef}
        image={isNode ? imageRef.current : imageElem}
        {...otherProps}
    />
});

export default KonvaImage;