import * as Immutable from 'immutable';
import ProductOptionStore from './ProductOptionStore';

export interface IProductOptionTypeStore {
	id: number,
	type: 'enum'|'bool',
	slug: string,
	name: string,
	theorder: number,
	visible: boolean,
	options: Immutable.OrderedMap<string, ProductOptionStore>,
}

const defaultProductOptionTypeStore = Immutable.Record<IProductOptionTypeStore>({
	id: 0,
	type: 'enum',
	slug: "",
	name: "",
	theorder: 0,
	visible: true,
	options: Immutable.OrderedMap<string, ProductOptionStore>(),
});

export default class ProductOptionTypeStore extends defaultProductOptionTypeStore implements IProductOptionTypeStore {}