import { data } from "jquery";
import { createAjaxAction } from "../../Common/_actions/AjaxAction";
import { BrandingOptionPayload } from "../_stores/BrandingOptionStore";
import { EditBrandingOption, EditManyBrandingOptions, RemoveBrandingOption } from "./BrandingOptionStoreActions";

export enum BrandingOptionActionType {
  FetchBrandingOptions = 'BRANDING_OPTION_FETCH_BRANDING_OPTIONS',
  CreateBrandingOption = 'BRANDING_OPTION_CREATE_BRANDING_OPTION',
  UpdateBrandingOption = 'BRANDING_OPTION_UPDATE_BRANDING_OPTION',
  DeleteBrandingOption = 'BRANDING_OPTION_DELETE_BRANDING_OPTION',
}

export const FetchBrandingOptions = () => createAjaxAction<{items: Record<string, BrandingOptionPayload>}>(BrandingOptionActionType.FetchBrandingOptions, {
	url: 'ajax/api/user/branding-options',
	method: 'GET',
	preventDuplicates: true,
	onSuccess: (response, dispatch) => {
		dispatch(EditManyBrandingOptions(response.data.items));
	},
});

export const CreateBrandingOption = (
  idType: number,
  imageFileid: string,
  name: string,
  isDefault: boolean,
  cropData: {
    x: number,
    y: number,
    width: number,
    height: number,
    rotation: number,
  }
) => {
  const data = new FormData()
  data.append('id_type', String(idType))
  data.append('image_fileid', imageFileid)
  data.append('name', name)
  data.append('is_default', isDefault ? '1':'0')
  data.append('crop_data', JSON.stringify(cropData))

  return createAjaxAction<{item: BrandingOptionPayload}>(BrandingOptionActionType.CreateBrandingOption, {
    url: 'ajax/api/user/branding-options',
    method: 'POST',
    data: data,
    onSuccess: (response, dispatch) => {
      dispatch(EditBrandingOption(response.data.item));

      //Refetch everything
      //TODO: Replace this with some smarter logic maybe
      dispatch(FetchBrandingOptions())
    },
  });
}

export const UpdateBrandingOption = (
  brandingOptionId: number,
  name: string,
  isDefault: boolean,
) => {
  const data = new FormData()
  data.append('name', name)
  data.append('is_default', isDefault ? '1':'0')

  return createAjaxAction<{item: BrandingOptionPayload}>(BrandingOptionActionType.UpdateBrandingOption, {
    url: 'ajax/api/user/branding-options/'+brandingOptionId+'/update',
    method: 'POST',
    data: data,
    onSuccess: (response, dispatch) => {
      dispatch(EditBrandingOption(response.data.item));

      //Refetch everything
      //TODO: Replace this with some smarter logic maybe
      dispatch(FetchBrandingOptions())
    },
  });
}

export const DeleteBrandingOption = (
  brandingOptionId: number,
) => {
  return createAjaxAction<{}>(BrandingOptionActionType.DeleteBrandingOption, {
    url: 'ajax/api/user/branding-options/'+brandingOptionId+'/delete',
    method: 'POST',
    onSuccess: (response, dispatch) => {
      dispatch(RemoveBrandingOption(brandingOptionId));
    },
  });
}