import * as Immutable from 'immutable';
import PatternCategoryStore, { IPatternCategoryStore } from './PatternCategoryStore';
import PatternStore, { IPatternStore } from './PatternStore';

export const PatternsPerPage:number = 30;
export const MaxPage:number = 99;
export const MaxOffset = PatternsPerPage * MaxPage;

export interface IPatternDesignServiceStore {
	categories: Immutable.OrderedMap<number, PatternCategoryStore>,
	patterns: Immutable.OrderedMap<string, PatternStore>,
	selectedCategoryId: number|null,
	offset: number
	count: number
}

const defaultStore = Immutable.Record<IPatternDesignServiceStore>({
	categories: Immutable.OrderedMap<number, PatternCategoryStore>(),
	patterns: Immutable.OrderedMap<string, PatternStore>(),
	selectedCategoryId: null,
	offset: 0,
	count: 0,
});

export default class PatternDesignServiceStore extends defaultStore implements IPatternDesignServiceStore {}