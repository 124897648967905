import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AjaxCallback } from '../../Common/_actions/AjaxAction';
import { PublishProductListings, SelectProductListing } from '../../Dashboard/_actions/DashboardActions';
import EditSelectedProducts, { Props } from '../_components/EditSelectedProducts';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	listings: store.getIn(['Dashboard','addProduct_listings']),
	storePages: store.getIn(['userData','storePages']),
	loader: store.getIn(['UIData','loaders','dashboard_addProducts']),
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	selectProductListing: (id: string) => dispatch(SelectProductListing(id)),
	publishProductListings: (store:string, jsonData:string, successCallback:AjaxCallback) => dispatch(PublishProductListings(store, jsonData, successCallback)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(EditSelectedProducts);