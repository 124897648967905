import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import { memo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SectionBrandingOption from './SectionBrandingOption';

type Props = {
  hasLabel?: boolean
  title: React.ReactNode
  text: React.ReactNode
}


const BrandingOptionsSection = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  backgroundImage: 'url(' + require('@resources/img/theme/page/ornaments-3.svg?url') + ')',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: '120px 0 180px',
}))

const BrandingOptionsList = styled(Container)(({ theme }) => ({
  marginTop: '60px',

  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
  'h3': {
    margin: 0,
    fontFamily: 'Poppins',
    fontSize: 22,
  },
  'p': {
    lineHeight: 1.4,
  },
  'img': {
    borderRadius: 20,
  }
}))

export default memo(function SectionBranding(props: Props) {
  const [t] = useTranslation('page/products/_sections');

  return <BrandingOptionsSection>
    <Container maxWidth="md">
      <Stack spacing={2} alignItems="center" textAlign="center">
        <Stack spacing={-2}>
          <Typography
            variant="h1-accent"
            component="h4"
          >{t('Make it yours')}</Typography>
          <Typography
            variant="h1"
            component="h3"
            color="secondary.main"
          >{props.title}</Typography>
        </Stack>
        <Typography variant="body1">{props.text}</Typography>
      </Stack>
    </Container>

    <BrandingOptionsList maxWidth='lg'>
      <Grid container spacing={4} justifyContent={{
        xs: 'center',
        lg: 'flex-start',
      }}>
        {props.hasLabel ? <SectionBrandingOption
          img={require('@resources/img/pages/products/_sections/branding/label.jpg')}
          title={t('Labels')}
          text={t('Enhance your products with custom labels to strengthen your brand\'s identity effortlessly!')}
        /> : null }
        <SectionBrandingOption
          img={require('@resources/img/pages/products/_sections/branding/sticker.jpg')}
          title={t('Packaging Stickers')}
          text={t('Customize packages that we ship to your customers by adding a custom sticker that we\'ll apply on the outside of the package.')}
        />
        <SectionBrandingOption
          img={require('@resources/img/pages/products/_sections/branding/artistcard.jpg')}
          title={t('Artist Cards')}
          text={t('Like business cards, but bigger! Use them as a thank you message, offer discounts for your own store or anything else you want.')}
        />
      </Grid>
    </BrandingOptionsList>
  </BrandingOptionsSection>
})