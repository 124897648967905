import { Paper, styled } from '@mui/material'

const StyledPaper = styled(Paper)({
  borderRadius: '5px',
  overflow: 'hidden'
})

const StyledImg = styled('img')({
  display: 'block'
})

interface Props {
  brand: string
}

export default function CreditCardIcon(props: Props) {
  return <StyledPaper elevation={2}>
    <StyledImg
      src={require('@resources/img/account/payment-brand-icons/' + props.brand + '.svg?url')}
      alt={props.brand}
      width={36}
      height={24}
    />
  </StyledPaper>
}
