import { InputBase, Select as MuiSelect, SelectProps as MuiSelectProps, styled } from '@mui/material';

const StyledSelect = styled(MuiSelect)({
    
});

const InputSelect = styled(InputBase)({
    height: '100%',

    '.MuiInputBase-input:focus': {
        background: 'transparent',
    },

    '.MuiSelect-select': {
        height: '100%',
        display: 'flex',
        gap: '15px',
        alignItems: 'center',
        padding: '0 24px 0 0',
    },
});

export type SelectBaseV2Props = MuiSelectProps & {}

export default function SelectBaseV2(props: SelectBaseV2Props) {
    return <StyledSelect 
        variant="standard"
        input={<InputSelect/>}
        {...props} 
    />
}