import { useMemo } from 'react';
import useWindowSize from '../../Common/_hooks/useWindowSize';
import { LAB_TEMPLATE_WIDTH } from '../../config';
import { useTheme } from '@mui/material';

const MIN_PREVIEW_WIDTH = 380;

//TODO: Should figure out a better way to handle that
const TOP_BAR_HEIGHT = 64 + 10;
const SAVE_BAR_HEIGHT = 270 + 30 + 35 + 40;
const MOBILE_SAVE_BAR_HEIGHT = 316 + 15;
const MIN_SAVE_BAR_WIDTH = 340;

export default function usePreviewSize() {
    const windowSize = useWindowSize();
    const theme = useTheme();

    const previewSize = useMemo(() => {
        let viewerSize = windowSize.width*0.4;
        viewerSize = Math.min(windowSize.height - SAVE_BAR_HEIGHT - TOP_BAR_HEIGHT, viewerSize);
        viewerSize = Math.min(windowSize.width - LAB_TEMPLATE_WIDTH, viewerSize);
        viewerSize = Math.max(MIN_PREVIEW_WIDTH, viewerSize);

        if(windowSize.width <= theme.breakpoints.values.lab_mobile || windowSize.height < theme.height_breakpoints.values.lab_mobile) {
            viewerSize = Math.min(windowSize.height - MOBILE_SAVE_BAR_HEIGHT - TOP_BAR_HEIGHT, windowSize.width);

            if(windowSize.height < theme.height_breakpoints.values.lab_mobile && windowSize.width >= 700) {
                viewerSize = Math.min(windowSize.height - TOP_BAR_HEIGHT - 15, windowSize.width - MIN_SAVE_BAR_WIDTH);
            }
        }

        viewerSize = Math.round(viewerSize);

        return {
            width: viewerSize,
            height: viewerSize,
        }
    }, [windowSize, theme])

    return previewSize;
}