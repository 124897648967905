import i18next from 'i18next';
import { Dispatch } from 'redux';

import { createAction } from '../../Common/_actions/Action';
import { AjaxCallback, AjaxResponse, createAjaxAction } from '../../Common/_actions/AjaxAction';
import { Toast } from '../../UIData/_actions/UIDataActions';
import { batch } from 'react-redux';
import { UserAddressPayload } from '../_stores/UserAddressStore';
import { UserImagePayload } from '../_stores/UserImageStore';
import { UserProductDesignPayload } from '../_stores/UserProductDesignStore';
import { UserCreditCardPayload } from '../_stores/UserCreditCardStore';
import { PaginationDataType, PaginationItemPayload, PaginationPayload } from '../_stores/PaginationStore';
import { UserPrefsPayload } from '../_stores/UserPrefsStore';
import { UserDataPayload } from '../_stores/UserDataStore';
import ApiErrorHandler from '../../Common/Error/ApiErrorHandler';

export enum UserDataActionType {
	EditUser					= "EDIT_USER",
	EditUserPrefs				= "EDIT_USER_PREFS",
	EditUserFetched				= "EDIT_USER_FETCHED",
	EditUserLoggedIn			= "EDIT_USER_LOGGEDIN",
	EditUserValidated			= "EDIT_USER_VALIDATED",
	InvalidatePaginationData	= "PAGINATION_INVALIDATE_DATA",
	EditPaginationLoader		= "EDIT_PAGINATION_LOADER",
	RemoveAccountData			= "REMOVE_ACCOUNT_DATA",
	SetPaginationData			= "PAGINATION_SET_DATA",
	EditItemData				= "EDIT_ITEM_DATA",
	EditStores					= "EDIT_STORES",
	EditUserEmail				= "EDIT_USER_EMAIL",

	SaveUserPrefs = 'USER_SAVE_USER_PREFS',
	GetUserPrefs = 'USER_GET_USER_PREFS',
	GetAccountData = 'USER_GET_ACCOUNT_DATA',
	SaveContactDetails = 'USER_SAVE_CONTACT_DETAILS',
	SaveUserNotifications = 'USER_SAVE_USER_NOTIFICATIONS',
	SavePassword = 'USER_SAVE_PASSWORD',
	DeleteAccount = 'USER_DELETE_ACCOUNT',
	FetchPaginationItems = 'USER_FETCH_PAGINATION_ITEMS',
	FetchOneItem = 'USER_FETCH_ONE_ITEM',
	RenamePaginationItem = 'USER_RENAME_PAGINATION_ITEM',
	DeletePaginationItem = 'USER_DELETE_PAGINATION_ITEM',
}

export const EditUser = (user: UserDataPayload) =>
	createAction(UserDataActionType.EditUser, {
	  user: user
	});

///////////
// Prefs //
///////////

export const EditUserPrefs = (updates: object) => createAction(UserDataActionType.EditUserPrefs, {
	updates: updates
});

export const SaveUserPrefs = (data:FormData, silent: boolean = false, onSuccess?:() => void) => createAjaxAction<{
	userPrefs: UserPrefsPayload
}>(UserDataActionType.SaveUserPrefs, {
	url: "ajax/api/user/save-user-preferences",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(EditUserPrefs(response.data.userPrefs));
		if(!silent) dispatch(Toast(i18next.t('Details changed successfully.')));

		onSuccess();
	},
	onFailure: (response, dispatch) => {
		if(response && response.data && response.data.error) 
			if(!silent) dispatch(Toast(response.data.error));
		else
			if(!silent) dispatch(Toast(i18next.t('Details could not be changed.')));
	}
});

export const GetUserPrefs = () => createAjaxAction<{
	userPrefs: UserPrefsPayload
}>(UserDataActionType.GetUserPrefs, {
	url: "ajax/api/user/get-user-preferences",
	method: "GET",
	onSuccess: (response, dispatch) => {
		dispatch(EditUserPrefs(response.data.userPrefs));
	},
	onFailure: (response, dispatch) => {
		if(response.data?.error) 
			dispatch(Toast(response.data.error));
		else
			dispatch(Toast(i18next.t('Something went wrong!')));
	}
});

//////////
// Data //
//////////

export const EditUserLoggedIn = (status:boolean) => createAction(UserDataActionType.EditUserLoggedIn, {
	status: status
});

export const EditUserValidated = (valid:boolean) => createAction(UserDataActionType.EditUserValidated, {
	valid: valid
});

export const EditUserFetched = (fetched:boolean) => createAction(UserDataActionType.EditUserFetched, {
	fetched: fetched
});

export const GetAccountData = () => createAjaxAction<{
	email: string,
	loggedIn: boolean,
	validated?: boolean
	stores?: {
		id: number,
		slug: string,
		name: string,
	}[],
}>(UserDataActionType.GetAccountData, {
	url: "ajax/api/user/get-account-data",
	method: "GET",
	onSuccess: (response, dispatch) => {
		if(response.data.loggedIn) {
			dispatch(EditUserLoggedIn(true));
		} else {
			dispatch(EditUserLoggedIn(false));
		}

		if(response.data.email) {
			dispatch(EditUserEmail(response.data.email));
		}

		if(response.data.validated)
			dispatch(EditUserValidated(response.data.validated));

		if(response.data.stores)
			dispatch(EditStores(response.data.stores));

		dispatch(EditUserFetched(true));
	},
	onFailure: (response, dispatch) => {
		if(response && response.data && response.data.error) 
			dispatch(Toast(response.data.error));
		else
			dispatch(Toast(i18next.t('Something went wrong!')));
	}
});

export const RemoveAccountData = () => createAction(UserDataActionType.RemoveAccountData);

export const EditStores = (stores: object) => createAction(UserDataActionType.EditStores, {
	stores: stores
});

export const SaveContactDetails = (data: FormData) => createAjaxAction<{
	user: UserDataPayload
}>(UserDataActionType.SaveContactDetails, {
	url: "ajax/api/user/save-contact-details",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(EditUser(response.data.user))
	},
	onFailure: ApiErrorHandler(UserDataActionType.SaveContactDetails),
});

export const SaveUserNotifications = (
	newsletterNews: boolean,
	newsletterOffers: boolean
) => {
	const data = new FormData;
	data.append('newsletter_news', newsletterNews ? '1':'0')
	data.append('newsletter_offers', newsletterOffers ? '1':'0')

	return createAjaxAction<{
		user: UserDataPayload
	}>(UserDataActionType.SaveUserNotifications, {
		url: "ajax/api/user/save-user-notifications",
		method: "POST",
		data: data,
		onSuccess: (response, dispatch) => {
			dispatch(EditUser(response.data.user))
			dispatch(Toast(i18next.t("Preferences saved!")));
		},
		onFailure: ApiErrorHandler(UserDataActionType.SaveUserNotifications),
	})
}

export const SavePassword = (oldPassword: string, newPassword: string) => {
	const data = new FormData()
	data.append('old-password', oldPassword)
	data.append('new-password', newPassword)

	return createAjaxAction<{}>(UserDataActionType.SavePassword, {
		url: "ajax/api/user/save-password",
		method: "POST",
		data: data,
		onSuccess: (response, dispatch) => {
			dispatch(Toast(i18next.t("Password changed")));
		},
		onFailure: ApiErrorHandler(UserDataActionType.SavePassword),
	});
}

export const DeleteAccount = (password: string) => {
	const data = new FormData()
	data.append('password', password)

	return createAjaxAction<{}>(UserDataActionType.DeleteAccount, {
		url: "ajax/api/user/delete-account",
		method: "POST",
		data: data,
		onSuccess: (response, dispatch) => {
		},
		onFailure: ApiErrorHandler(UserDataActionType.DeleteAccount),
	});
}

export const EditUserEmail = (email: string) => createAction(UserDataActionType.EditUserEmail, {
	email: email
});

////////////////
// Pagination //
////////////////

export const SetPaginationData = (listId: string, data:PaginationPayload) => createAction(UserDataActionType.SetPaginationData, {
	listId: listId,
	data: data
})

export const EditItemData = (data:PaginationItemPayload) => createAction(UserDataActionType.EditItemData, {
	data: data
})

export const InvalidatePaginationData = (dataType:PaginationDataType, listId?: string) => createAction(UserDataActionType.InvalidatePaginationData, {
	dataType: dataType,
	listId: listId,
});

export const EditPaginationLoader = (dataType:PaginationDataType, listId: string, loader: boolean) => createAction(UserDataActionType.EditPaginationLoader, {
	dataType: dataType,
	listId: listId,
	loader: loader,
});

export const FetchPaginationItems = (listId: string, data:FormData, dataType:PaginationDataType) => createAjaxAction<PaginationPayload>(UserDataActionType.FetchPaginationItems, {
	url: "ajax/api/user/" + dataType + "?listId="+listId,
	method: "GET",
	data: data,
	limit: 150,
	abortPrevious: true,
	onSuccess: (response, dispatch) => {
		batch(() => {
			dispatch(SetPaginationData(listId, response.data));
		})
	},
	onFailure: (response, dispatch) => {
		dispatch(Toast(i18next.t('Something went wrong!')));
	}
});

export const FetchOneItem = (id:string, dataType:PaginationDataType, fields?: string[]) => {
	const data:Record<string, any> = {};

	if(fields) {
		data.fields = fields;
	}

	return createAjaxAction<PaginationItemPayload>(UserDataActionType.FetchOneItem, {
		url: "ajax/api/user/" + dataType + "/"+id,
		data: data,
		method: "GET",
		limit: 150,
		abortPrevious: true,
		onSuccess: (response, dispatch) => {
			dispatch(EditItemData(response.data));
		},
		onFailure: (response, dispatch) => {
			dispatch(Toast(i18next.t('Something went wrong!')));
		}
	});
}

export const RenamePaginationItem = (data:FormData, dataType:PaginationDataType) => createAjaxAction(UserDataActionType.RenamePaginationItem, {
	url: "ajax/api/user/" + dataType + "/rename",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(InvalidatePaginationData(dataType));
	},
	onFailure: (response, dispatch) => {
		dispatch(Toast(i18next.t('Something went wrong!')));
	}
});

export const DeletePaginationItem = (data:FormData, dataType:PaginationDataType) => createAjaxAction(UserDataActionType.DeletePaginationItem, {
	url: "ajax/api/user/" + dataType + "/delete",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(InvalidatePaginationData(dataType));
	},
	onFailure: (response, dispatch) => {
		dispatch(Toast(i18next.t('Something went wrong!')));
	}
});

export type UserDataAction =
	ReturnType<typeof EditUser>					|
	ReturnType<typeof EditUserPrefs>			|
	ReturnType<typeof EditUserFetched>			|
	ReturnType<typeof EditUserLoggedIn>			|
	ReturnType<typeof EditUserValidated>		|
	ReturnType<typeof InvalidatePaginationData>	|
	ReturnType<typeof EditPaginationLoader>		|
	ReturnType<typeof RemoveAccountData>		|
	ReturnType<typeof EditStores>				|
	ReturnType<typeof SetPaginationData>		|
	ReturnType<typeof EditItemData>				|
	ReturnType<typeof EditUserEmail>