import { useEffect, useState } from "react";

type Size = {
    width: number;
    height: number;
}  

export default function useWindowSize(): Size {
    const [windowSize, setWindowSize] = useState<Size>({
      width: 1920,
      height: 1080,
    });

    useEffect(() => {
      function resize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", resize);
      resize();

      return () => window.removeEventListener("resize", resize);
    }, []);

    return windowSize;
  }