import { Box, IconButton, Dialog as MuiDialog, DialogProps as MuiDialogProps, Paper as MuiPaper, styled } from '@mui/material';
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import DialogPaper, { DialogPaperOrnaments } from './DialogPaper';
import { Suspense } from 'react';
import LabLoader from '../../DesignLab/_components/LabLoader';


const StyledDialog = styled(MuiDialog)({

});

type Props = MuiDialogProps & {
    showCloseButton?: boolean
    onClose?: (event: {}, reason: 'backdropClick'|'escapeKeyDown'|'closeButton') => void
	ornaments?: DialogPaperOrnaments
}

export default function Dialog(props: Props) {
    const { children, showCloseButton, ornaments, ...otherProps } = props;
    return <StyledDialog 
		PaperComponent={DialogPaper}
		PaperProps={{
			ornaments: ornaments
		}}
		{...otherProps}
	>
        { showCloseButton ? <IconButton
				color="secondary"
				onClick={() => props.onClose && props.onClose({}, 'closeButton')}
				sx={{
					position: 'absolute',
					right: 12,
					top: 12,
				}}
			>
				<CloseRoundedIcon fontSize="large" />
			</IconButton> : null }
			<Suspense fallback={
				<Box
					component="div"
					sx={{
						py: 4,
						px: 8,
						minHeight: '200px',
						minWidth: '300px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<LabLoader/>
				</Box>
			}>
        { children }
			</Suspense>
    </StyledDialog>
}