import Immutable from "immutable"
import { useContext, useEffect } from "react"
import { MeshPhysicalMaterial, Vector3 } from "three"
import ModelElements from "../ModelElements"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"
import { ProductModelProps } from "../ModelViewerProduct"

export default function PermanentSticker(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/permanent-sticker.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture, fxTexture] = useContext(ProductTextureContext)

    useEffect(() => {
        if(!elements || !elements[0]?.material || !elements[2]?.material || !elements[3]?.material) return

        elements[0].receiveShadow = false
        elements[1].receiveShadow = false
        elements[2].receiveShadow = false
        elements[3].receiveShadow = false

        // Replace materials with a manually created one
        var sourcemat = elements[0].material

        // Find Stage/Env
        var p = elements[0].parent
        while (p && p.type != "Scene") {
            p = p.parent
        }

        elements[2].material = new MeshPhysicalMaterial({
            roughnessMap: sourcemat.roughnessMap,
            metalnessMap: sourcemat.metalnessMap,
            envMapIntensity: 2.0,
            transparent: true,
            metalness: 0.25,
            specularIntensity: 1.5,
        })
        
        elements[0].material = new MeshPhysicalMaterial({
            transparent: true,
            alphaToCoverage: true,
            side: 1
        })

        elements[2].material.roughness = 0.25

        elements[3].material = elements[2].material.clone()
        elements[3].material.roughness = 0.5
        elements[3].material.opacity = 0.4
        elements[3].material.alphaToCoverage = true
    }, [elements])

    // Apply lab texture
    useEffect(() => {
        if(!elements || !elements[0]?.material || !elements[2]?.material) return

        elements[2].material.map = productTexture
        elements[2].material.map.flipY = false
        elements[2].material.map.needsUpdate = true

        elements[0].visible = !!fxTexture
        if(fxTexture) {
            elements[0].material.map = fxTexture
            elements[0].material.map.flipY = false
            elements[0].material.map.needsUpdate = true
        }
    }, [productTexture, fxTexture])
    
    return <ModelElements elements={elements}/>
}