import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../Common/_hooks/useAppSelector'
import { useParams, Link, useSearchParams, Outlet, useMatch } from 'react-router-dom'
import { Alert, Button, Chip, Container, Divider, Grid, Paper, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import CartListItems from '../Cart/CartItem/CartListItems'
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from '../Common/_hooks/useAppDispatch'
import { FetchOneItem, FetchPaginationItems } from '../UserData/_actions/UserDataActions'
import { CartStack } from '../Cart/CartContent/CartStack'
import useOrderStatusProps from '../Common/_hooks/Order/useOrderStatusProps'
import { DateTime } from 'luxon'
import { FileDownloadOutlined as FileDownloadOutlinedIcon, OpenInNewRounded as OpenInNewRoundedIcon } from '@mui/icons-material'
import CartListStack from '../Cart/CartContent/CartListStack'
import CancelOrderDialog from '../Account/Orders/CancelOrderDialog'
import { PutOnHoldOrder, ReleaseHoldOrder, UnignoreOrder } from '../UserData/_actions/OrderActions'
import IgnoreOrderDialog from '../Account/Orders/IgnoreOrderDialog'
import UserCartStore from '../UserData/_stores/UserCartStore'
import Immutable from 'immutable'
import OrderPageContent from '../Account/Orders/OrderPageContent'
import CheckoutOverview from '../Checkout/Overview/CheckoutOverview'
import CheckoutShipping from '../Checkout/Shipping/CheckoutShipping'
import CheckoutPayment from '../Checkout/Payment/CheckoutPayment'
import AccountHeader from '../Account/AccountHeader'
import LabLoader from '../DesignLab/_components/LabLoader'

export default function DashboardOrderPage() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')
  const [tR] = useTranslation('routes')
  const params = useParams<{ storeSlug: string, refId: string, step?: string }>()
  const [searchParams] = useSearchParams()
  const shippingPageMatch = useMatch(tR('/d/:storeId/orders/:refId/shipping'))
  const paymentPageMatch = useMatch(tR('/d/:storeId/orders/:refId/payment'))

  const order = useAppSelector(state => params.refId ? state.get('userData').get('orders').get('data').find(value => value.get('ref_id') === params.refId) : undefined)
  const storeIntegration = useAppSelector(state => order ? state.get('appData').get('storeIntegrations').get(order.get('storeIntegration') ?? '') : undefined)
  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))

  const [cancelDialogOpened, setCancelDialogOpened] = useState(false)
  const [ignoreDialogOpened, setIgnoreDialogOpened] = useState(false)

  useEffect(() => {
    if (!params.refId) return
    dispatch(FetchOneItem(params.refId, 'orders'))
  }, [params.refId])

  const {
    text: statusText,
    color: statusColor
  } = useOrderStatusProps(order?.get('status') ?? 'paid')

  const onCancelDialogOpen = useCallback(() => {
    setCancelDialogOpened(true)
  }, [])

  const onCancelDialogClose = useCallback(() => {
    setCancelDialogOpened(false)
  }, [])

  const onIgnoreDialogOpen = useCallback(() => {
    setIgnoreDialogOpened(true)
  }, [])

  const onIgnoreDialogClose = useCallback(() => {
    setIgnoreDialogOpened(false)
  }, [])

  const onPutOnHold = useCallback(() => {
    if (!order) return
    dispatch(PutOnHoldOrder(order.get('id')))
  }, [order])

  const onReleaseHold = useCallback(() => {
    if (!order) return
    dispatch(ReleaseHoldOrder(order.get('id')))
  }, [order])

  const onUnignore = useCallback(() => {
    if (!order) return
    dispatch(UnignoreOrder(order.get('id')))
  }, [order])
  
  const storeOrder = useMemo(() => {
    return order?.get('storeOrder')
  }, [order])

  const storeUrl = useMemo(() => {
    return storeOrder?.get('store_url')
  }, [storeOrder])

  const orders = useMemo(() => {
    return order ? Immutable.OrderedMap({[order.get('id')]: order}) : Immutable.OrderedMap<string, UserCartStore>()
  }, [order])

  const isPaid = useMemo(() => {
    return ['paid','in-production','ready','shipped'].includes(order?.get('status') ?? '')
  }, [order])

  const [title, breadcrumbs] = useMemo(() => {
    if(!order) return ['', []]

    const orderNumber = t('Order #{{number}}', { number: order.get('ref_id').toUpperCase() });
    let tmpTitle = orderNumber;
    const tmpBreadcrumbs = [
      {
        title: t('Orders'),
        url: tR('/d/{{store}}/orders', {store: params.storeSlug})
      }
    ]

    if(order.get('status') === 'ordered') {
      if(shippingPageMatch !== null || paymentPageMatch !== null) {
        tmpBreadcrumbs.push({
          title: orderNumber,
          url: tR('/d/{{store}}/orders/{{ref_id}}', {store: params.storeSlug, ref_id: order.get('ref_id')})
        })
      }

      if(paymentPageMatch !== null) {
        tmpBreadcrumbs.push({
          title: t('Shipping'),
          url: tR('/d/{{store}}/orders/{{ref_id}}/shipping', {store: params.storeSlug, ref_id: order.get('ref_id')})
        })

        tmpTitle = t('Payment')
      } else if(shippingPageMatch !== null) {
        tmpTitle = t('Shipping')
      }
    }

    return [
      tmpTitle,
      tmpBreadcrumbs
    ]
  }, [order, params, t, tR])

  const isConfirmation = useMemo(() => {
    return searchParams.has('payment_id')
  }, [searchParams])

  if (!order) return null

  return <Container style={{padding: '0 0.75rem'}}>
    <AccountHeader
      title={title}
      breadcrumbs={breadcrumbs}
      titleActions={<Chip
        label={statusText}
        color={statusColor}
        variant="square-filled"
        size="small"
      />}
      actions={isPaid ? <Button
        variant="dashed"
        color="secondary"
        startIcon={<FileDownloadOutlinedIcon/>}
        component={Link}
        to={tR('/invoice/download-receipt') + '?ref_id=' + order.get('ref_id')}
        target="_blank"
      >{t('Invoice (PDF)')}</Button> : null}
    />

    { storeIntegration ? <Typography
      variant="body1"
      sx={{color: '#515151', mt: -2, mb: 2}}
    >{ t('Received on ')}<strong style={{fontWeight: 600}}>{DateTime.fromISO(order.get('date_created')).toLocaleString(DateTime.DATETIME_FULL)}</strong></Typography> : null }

    <CartListStack>
      <Grid container>
        <Grid item xs={12} sm={6} display="flex" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            { storeIntegration ? <Chip
              avatar={<img src={require('@resources/img/account/' + storeIntegration.get('slug') + '-icon.svg?url')} />}
              label={storeIntegration.get('name')}
            /> : null }

            { storeUrl && storeIntegration ? <Button
              variant="text"
              color="secondary"
              component={Link}
              to={storeUrl}
              target="_blank"
              endIcon={<OpenInNewRoundedIcon/>}
            >{ t('View order on {{store}}', {store: storeIntegration.get('name')})}</Button> : null}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="center" justifyContent="flex-end">
          { order.get('order_from') !== 'invoice' ? <Stack direction="row" spacing={2}>
            { order.get('status') === 'on-hold' ? <Button
              color="secondary"
              variant="outlined"
              onClick={onReleaseHold}
            >{t('Release hold')}</Button> : null }

            { ['ordered'].includes(order.get('status')) ? <Button
              color="error"
              variant="outlined"
              onClick={onIgnoreDialogOpen}
              disabled={cartLoader}
            >{t('Ignore order')}</Button> : null }

            { ['ignored'].includes(order.get('status')) ? <Button
              color="secondary"
              variant="outlined"
              onClick={onUnignore}
              disabled={cartLoader}
            >{t('Unignore order')}</Button> : null }

            { order.get('isCancelable') ? <>
              { order.get('status') !== 'on-hold' ? <Button
                color="warning"
                variant="outlined"
                onClick={onPutOnHold}
              >{t('Put on hold')}</Button> : null }
              <Button
                color="error"
                variant="outlined"
                onClick={onCancelDialogOpen}
              >{t('Cancel order')}</Button>
            </> : null }
          </Stack> : null }
        </Grid>
      </Grid>

      { isConfirmation ? <Alert
        severity="success"
        variant="outlined"
      >{ t('Thank you for your payment.') }</Alert> : null}

      <Suspense fallback={<LabLoader/>}>
        { order.get('status') === 'ordered' ? <div>
          <Outlet/>
        </div> : <OrderPageContent
          order={order}
        /> }
      </Suspense>
    </CartListStack>


    <CancelOrderDialog
      order={order}
      opened={cancelDialogOpened}
      onClose={onCancelDialogClose}
    />

    <IgnoreOrderDialog
      order={order}
      opened={ignoreDialogOpened}
      onClose={onIgnoreDialogClose}
    />
  </Container>
}
