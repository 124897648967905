import { Checkbox, Grid, styled } from '@mui/material';
import * as Immutable from 'immutable';
import * as React from 'react';
import { Trans, Translation } from 'react-i18next';
import ProductStore from '../../AppData/_stores/ProductStore';
import { ButtonWhiteHeight30 } from '../../Common/_components/Buttons';
import { CircleGreenCheck, PinkCheckBox, PinkCheckedIcon, UncheckedWhiteBackgroundIcon } from '../../Common/_components/CheckBoxes';
import Hidden from '../../Common/_components/Hidden';
import { UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore';
import UserStorePresetStore from '../../UserData/_stores/UserStorePresetStore';
import VariantPriceHelper from '../VariantPriceHelper';

export type Props = {
	hasEditButton ?: boolean
	className ?: string
	handleEditDetails ?: (id: string) => void
	item?: UserProductDesignStore
	product?: ProductStore
	storePreset?: UserStorePresetStore
	isSelected?: boolean
	isModified?: boolean
	title?: string
	editProductListing?: Function
	removeProductListing?: Function
}

type State = {
}

const CardTitle = styled('p')({
	fontWeight: 'normal',
	fontSize: '14px',
	letterSpacing: '0.02em',
	color: '#000000',
	margin: '0',
	marginBottom: '5px',
});
const CardSubtitle = styled('p')({
	fontWeight: 'normal',
	fontSize: '12px',
	letterSpacing: '0.01em',
	color: '#807F7F',
	margin: '0',
});
const CardContainer = styled('div')({
	position: 'relative',
	background: '#FFFFFF',
	boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.25)',
	borderRadius: '2px',

	'img': {
		width: '100%',
	},

	'&.MuiGrid-item': {
		padding: '0',
	},

	'&.pink-border': {
		outline: '1px solid #DA3192',
	}
});

const CardInfoCotainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	padding: '0 28px 10px 22px',

	'@media (max-width:1200px)': {
		padding: '10px 28px',
	},
	
	'@media (max-width:600px)': {
		padding: '5px 14px',
	}
});

const CheckboxDiv = styled('div')({
	position: 'absolute',
	top: '15px',
	left: '27px',
});
const EditButtonContainer = styled('div')({
	margin: '10px 0',

	'@media (max-width:600px)': {
		margin: '5px 0',
	}
});
const RemoveProductButtonDiv = styled('div')({
	position: 'absolute',
	top: '10px',
	right: '10px',
	cursor: 'pointer',
});
const PriceStatus = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	textAlign: 'center',
	alignItems: 'center',
	color: '#000000',
	fontSize: '10px',
	gap: '5.68px',
});
const PinkWrapper = styled(Grid)({
	backgroundColor: 'transparent',
	position: 'relative',

	'&:hover': {
		backgroundColor: '#FCEFF7',
	},

	'&.has-edit-button': {
		'&:hover': {
			backgroundColor: 'transparent',
		}
	},
});

const T = ({children, ...otherProps}:any) => <Trans ns="dashboard" {...otherProps}>{children}</Trans>

export default class ProductCard extends React.Component<Props, State> {

	state: State = {
    }

	getCheckBox = () => <>
		<CheckboxDiv>
			<PinkCheckBox
				control={
					<Checkbox
						color="secondary"
						checked={this.props.isSelected}
						name="savePaymentMethod"
						icon={<UncheckedWhiteBackgroundIcon />}
						checkedIcon={<PinkCheckedIcon />}
					/>
				}
				label=""
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleSelectCard()}
			/>
		</CheckboxDiv>
	</>

	getEditButton = () => <>
		<Hidden lgDown>
			<ButtonWhiteHeight30 onClick={() => this.props.handleEditDetails(this.props.item.get('id'))}><T>Edit Details</T> &amp; <T>pricing</T></ButtonWhiteHeight30>
		</Hidden>
		<Hidden lgUp>
			<ButtonWhiteHeight30 onClick={() => this.props.handleEditDetails(this.props.item.get('id'))}><T>Edit</T></ButtonWhiteHeight30>
		</Hidden>
	</>

	getRemoveProductButton = () =><>
		<RemoveProductButtonDiv onClick={() => this.props.removeProductListing(this.props.item.get('id'))}>
			<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="14.0805" cy="14.4399" r="13.475" fill="#333333" />
				<path d="M18.6761 17.9741L15.1406 14.4386L18.6761 10.903C18.7645 10.8146 18.7645 10.6379 18.6761 10.5495L17.969 9.84236C17.8585 9.73188 17.7038 9.75397 17.6155 9.84236L14.0799 13.3779L10.5444 9.84236C10.4339 9.73188 10.2792 9.75397 10.1908 9.84236L9.48373 10.5495C9.37325 10.66 9.37325 10.7925 9.48373 10.903L13.0193 14.4386L9.48373 17.9741C9.37325 18.0846 9.37325 18.2172 9.48373 18.3276L10.1908 19.0348C10.2792 19.1231 10.4339 19.1452 10.5444 19.0348L14.0799 15.4992L17.6155 19.0348C17.7038 19.1231 17.8585 19.1452 17.969 19.0348L18.6761 18.3276C18.7645 18.2393 18.7645 18.0625 18.6761 17.9741Z" fill="white" />
			</svg>
		</RemoveProductButtonDiv>
	</>

	handleSelectCard = () => {
		if (!this.props.hasEditButton) {
			if(this.props.isSelected) {
				this.props.removeProductListing(this.props.item.get('id'));
			} else {
				//Setup the default required variant prices
				let helper = new VariantPriceHelper(this.props.product, this.props.item.get('availableOptionTypes'));
				let variant_prices = helper.buildVariantPrices();
				let presetVariantPrices = this.props.storePreset.get('variant_prices');

				//Replace prices with presets
				variant_prices.forEach((variantPrice, variantId) => {
					if(!presetVariantPrices.has(variantId)) {
						return;
					}

					let newVariantPrice = variantPrice.set('price', presetVariantPrices.get(variantId).get('price'));

					variant_prices.set(variantId, newVariantPrice);
					presetVariantPrices = presetVariantPrices.remove(variantId);
				});

				this.props.editProductListing(
					this.props.item.get('id_product_design'), 
					this.props.item.get('id_product'),
					this.props.item.get('name'),
					this.props.storePreset.get('description'), //description
					this.props.storePreset.get('price'), //price
					false, //update_preset
					this.props.storePreset.get('disabled_preview_ids'), //disabled_preview_ids
					this.props.storePreset.get('disabled_option_ids'), //disabled_option_ids
					Immutable.OrderedMap(variant_prices),
					//Immutable.Map<string, VariantPriceStore>(variant_prices), //variant_prices
					undefined, //squarespace_store_page_id

				);
			}
		}
	}

	render = () =>{
		if(!this.props.item || !this.props.product) return null;

		let hasEditButton = this.props.hasEditButton;

		return <>
			<PinkWrapper className={hasEditButton && "has-edit-button"} xs={6} md={4} item>
				<CardContainer onClick={() => this.handleSelectCard()} className={!this.props.hasEditButton && this.props.isSelected && "pink-border"}>
					<img src={this.props.item.get('thumbnail')} alt="" />
					<CardInfoCotainer>
						<div>
							<CardTitle>{ this.props.title }</CardTitle>
						</div>
						<CardSubtitle>{ this.props.product.get('name') }</CardSubtitle>
						{hasEditButton ? <>
							<EditButtonContainer>
								{this.getEditButton()}
							</EditButtonContainer>
							<Translation ns='dashboard'>{ t => 
								<PriceStatus>
									{this.props.isModified ? <>
										{CircleGreenCheck()}
										<div>
											<T>Changes Saved</T>
										</div>
									</> : (this.props.storePreset && !this.props.storePreset.get('default') ? <>
										{CircleGreenCheck()}
										<div>
											<T>Using preset</T>
										</div>
									</> : t("Details & Prices created by Art of Where")
									)}
								</PriceStatus>
								}
							</Translation>
						</> : <></>}
					</CardInfoCotainer>
				
					{hasEditButton && this.getRemoveProductButton()}
					
				</CardContainer>
				{!hasEditButton && this.getCheckBox()}
			</PinkWrapper>
		</> 
	}
}