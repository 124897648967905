import { Dispatch } from "redux";
import { AppDispatch } from "../../WebsiteStore";
import { ErrorResponseData } from "../_actions/AjaxAction";
import { Action } from '@reduxjs/toolkit';
import { ApiError } from "../../UIData/_actions/UIDataActions";

export default function ApiErrorHandler(key:string) {
  return (response:ErrorResponseData, dispatch:Dispatch<Action>) => {
    if(!response.data?.error) {
      return
    }

    dispatch(ApiError(key, {
      error: response.data.error
    }))
  }
}