import { AjaxActionEnd, AjaxActionStart } from '../../Common/_actions/AjaxAction'
import { ModelViewerAction, ModelViewerActionType } from '../_actions/ModelViewerStoreActions'
import ModelViewerStore from '../_stores/ModelViewerStore'

const ModelViewerReducer = (store: ModelViewerStore = new ModelViewerStore(), action: ModelViewerAction | AjaxActionStart | AjaxActionEnd): ModelViewerStore => {
    switch (action.type) {
        case ModelViewerActionType.SetFixedView:
            store = store.set('fixedView', action.view)
            break

        case ModelViewerActionType.SetFixedViewTime:
            store = store.set('fixedViewTime', action.time)
            break
	}

	return store
}

export default ModelViewerReducer