import * as Immutable from 'immutable';
import { CollectionPayload, CollectionStore } from '../_stores/CollectionStore';
import { CollectionStoreAction, CollectionStoreActionType } from '../_actions/CollectionStoreActions';
import { CollectionItemStore } from '../_stores/CollectionItemStore';

const editCollection = (store: Immutable.OrderedMap<string, CollectionStore>, action: CollectionPayload) => {
  return store.withMutations(store => {
    const oldCollection = store.get(String(action.id));

    const { listItems, ...otherProps } = action;

    let newCollection = new CollectionStore(otherProps);

    if (oldCollection) {
      newCollection = oldCollection.mergeDeep(newCollection);
    }

    if (listItems) {
      let tmpListItems = Immutable.OrderedMap<string, CollectionItemStore>();
      Object.values(listItems).forEach(item => {
        const oldItem = oldCollection
          ?.get('listItems')
          .get(String(item.id))

        let newItem = new CollectionItemStore(item)

        if (oldItem) {
          newItem = oldItem.mergeDeep(newItem)
        }

        tmpListItems = tmpListItems.set(String(item.id), newItem)
      })

      newCollection = newCollection.set('listItems', tmpListItems)
    }

    store.set(String(action.id), newCollection);
  })
}

const CollectionReducer = (
  store: Immutable.OrderedMap<string, CollectionStore> = Immutable.OrderedMap<string, CollectionStore>(),
  action: CollectionStoreAction
): Immutable.OrderedMap<string, CollectionStore> => {
  switch (action.type) {
    case CollectionStoreActionType.EditCollection:
      store = editCollection(store, action.collection);
      break;

    case CollectionStoreActionType.EditManyCollections:
      Object.values(action.collections).forEach(collection => {
        store = editCollection(store, collection);
      })
      break;

    case CollectionStoreActionType.RemoveCollection:
      store = store.remove(String(action.id));
      break;
  }
  return store;
}

export default CollectionReducer