import Immutable from "immutable";
import { IPaginationStore } from "./PaginationStore";
import { UserCreditCardStore } from "./UserCreditCardStore";
import PaginationItemListStore from "./PaginationItemListStore";

export interface IUserCreditCardListStore extends IPaginationStore {
	data: Immutable.Map<string, UserCreditCardStore>,
}

const defaultUserCreditCardListStore = Immutable.Record<IUserCreditCardListStore>({
	itemLists: Immutable.Map<string, PaginationItemListStore>(),
	data: Immutable.Map<string, UserCreditCardStore>(),
});

export default class UserCreditCardListStore extends defaultUserCreditCardListStore implements IUserCreditCardListStore {}