import { West as WestIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { Button, CssBaseline, FormControl, GlobalStyles, InputBase, MenuItem, Paper, Select, styled } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
//import WebFont from 'webfontloader';
import { isBrowser } from 'browser-or-node';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { GetProductLabData } from '../../UIData/_actions/DesignLabActions';
import { ClearLab, EditActiveDesign, EditActiveOption, EditActiveProduct, EditActiveVariant, EditAutoDesignSubproduct, EditDesignName, EditLayer, EditMirrorMode, ResetActiveOptions, SelectLayer } from '../../UIData/_actions/DesignLabStoreActions';
import { EditLoader } from '../../UIData/_actions/UIDataActions';
import { FetchOneItem } from '../../UserData/_actions/UserDataActions';
import WebsiteStore from '../../WebsiteStore';
import { LAB_FONTS } from '../../config';
import FabricLab from '../FabricLab/_components/FabricLab';
import FabricViewer from '../FabricLab/_components/FabricViewer';
import AutoDesignOverlay from './AutoDesignOverlay';
import AutoDesignTool from './AutoDesignTool';
import ExitLab from './ExitLab';
import FullPageLabLoader from './FullPageLabLoader';
import LabStage from './LabStage';
import LayerList from './LayerList';
import MirrorTool from './MirrorTool';
import PreviewViewer from './PreviewViewer';
import ProductSelector from './ProductSelector';
import SaveBar from './SaveBar';
import SceneSelector from './SceneSelector';
import SubproductSelector from './SubproductSelector';
import ZoomControls from './ZoomControls';
import IconButton from '../../Common/Buttons/IconButton';

const DEV_MODE = false;

const DesignLabAppContainer = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    background: '#F3F3F3',
    display: 'flex',
    flexDirection: 'column',
})

const LabInterface = styled('div')({
    display: 'flex',
    flex: '1',
    transition: 'opacity 300ms',

    '&.hidden': {
        opacity: 0,
    },

    '*': {
        userSelect: 'none',
        userDrag: 'none',
    }
})

export const StageContainer = styled('div')(({theme}) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    transition: 'transform 300ms, opacity 300ms',
    position: 'relative',
    opacity: 1,

    '&.hidden': {
        transform: 'translateY(100px)',
        opacity: 0,
    },

    '@media (max-width: 1510px)': {
        paddingLeft: '15px',
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        paddingRight: '15px',
        transform: 'translateY(100px)',
        opacity: 0,

        '&.active': {
            opacity: 1,
            transform: 'none',
        }
    },
}));

export const LabWrapper = styled('div')({
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    '&:before': {
		content: '""',
		height: '150px',
		width: '150px', 
		borderRadius: '50%',
		border: '20px solid #7FD3F3',
		display: 'block',
		position: 'absolute',
		zIndex: -1,
		right: '-70px',
		top: '-30px',
	},

	'&:after': {
		content: '""',
		height: '150px',
		width: '150px', 
		border: '20px solid #FCF57F',
		display: 'block',
		position: 'absolute',
		zIndex: -1,
		left: '-80px',
		top: 0,
		bottom: 0,
		margin: 'auto',
		transform: 'rotate(-20deg)',
	}
})

const PreviewScreen = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    transition: 'transform 300ms, opacity 300ms',

    '&.hidden': {
        transform: 'translateX(100px)',
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        position: 'absolute',
        top: '74px',
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        pointerEvents: 'none',

        '&.active': {
            opacity: 1,
            pointerEvents: 'auto',
        },
        
        ['@media (max-height: '+theme.height_breakpoints.values.lab_mobile+'px) and (min-width: 700px)']: {
            flexDirection: 'row',
            alignItems: 'center',
        }
    }
}));

export const LeftContainer = styled('div')(({theme}) => ({
    display: 'flex',
    flex: 1,

    ['@media (max-width: 1510px) and (min-height: '+theme.height_breakpoints.values.lab_mobile+'px), (max-height: 1070px) and (max-width: 953px)']: {
        flexDirection: 'column',
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        pointerEvents: 'none',

        '&.active': {
            pointerEvents: 'auto',
        },
    }
}));

const VariantSelectorFormControl = styled(FormControl)({
    lineHeight: '64px',
    height: '64px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px 0px 15px',
    backgroundColor: 'transparent',
    transition: 'background-color 150ms',

    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },

    '&.active': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
})

const TemplateTools = styled('div')({
    position: 'absolute',
    top: '20px',
    right: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexEnd',
    gap: '10px',
})

export const InputSelect = styled(InputBase)({
    height: '100%',
    color: '#fff',

    '.MuiInputBase-input:focus': {
        background: 'transparent',
    },

    '.MuiSelect-select': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0 34px 0 0',
    },

    '.MuiSelect-icon': {
        color: '#fff',
    }
})

const YellowDecoration = styled('div')({
    overflow: 'hidden',
    maxWidth: '80px',
    maxHeight: '120px',
    position: 'absolute',
    zIndex: -1,
    right: 0,
    bottom: 0,

    '&:before': {
        content: '""',
        height: '150px',
        width: '150px', 
        border: '20px solid #FCF57F',
        display: 'block',
        transform: 'rotate(-20deg)',
        marginLeft: '30px',
    }
})

const PinkDecoration = styled('div')({
    overflow: 'hidden',
    maxWidth: '80px',
    maxHeight: '160px',
    position: 'absolute',
    zIndex: -1,

    '&:before': {
        content: '""',
        height: '150px',
        width: '60px',
        backgroundColor: '#F396D6',
        display: 'block',
        transform: 'rotate(25deg)',
        marginLeft: 0,
    },

    '&.right': {
        right: 0,
        bottom: 0,
        top: '25vh',

        '&:before': {
            margin: '0 -40px 30px 30px',
        },
    },

    '&.left': {
        left: 0,
        bottom: 0,
        
        '&:before': {
            margin: '30px 30px 30px -40px',
        }
    }
})

export const SeePreviewButton = styled(Button)(({theme}) => ({
    position: 'absolute',
    top: 0,
    right: '15px',
    zIndex: 30,
    padding: '6px 20px',
    display: 'none',
    background: 'linear-gradient(0deg, #DA3192 0%, #F600BE 100%)',
    color: '#fff',
    borderRadius: '30px',
    fontWeight: 400,

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        display: 'flex',
        alignItems: 'center',
    },

    '@media (max-width: 953px)': {
        bottom: '15px',
        top: 'auto',
        margin: 'auto',
        left: 0,
        right: 0,
        width: 'fit-content',
    },
}));

const BackToTemplateButton = styled(Button)(({theme}) => ({
    position: 'absolute',
    top: '10px',
    left: '20px',
    zIndex: 30,
    display: 'none',
    backgroundColor: '#05aced',
    color: '#fff',
    borderRadius: '30px',
    fontWeight: 400,
    alignItems: 'center',
    gap: '10px',
    padding: '10px 20px 10px 15px',

    '&:hover': {
        backgroundColor: '#05aced',
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        display: 'flex',
    },
}));

export const ArrowWhite = styled('img')({
    marginLeft: '15px',

    '@media (max-width: 1200px), (max-height: 900px)': {
        width: '30px',
    },
})


type Props = {}

export type SaveState = 'none'|'generating'|'create_more'|'done';

export default function DesignLab(props:Props) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [t] = useTranslation('design-lab');
    const [tR] = useTranslation('routes');

    const params = useParams<{product?: string}>();
    const [searchParams, setSearchParams] = useSearchParams();

    const activeDesignId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeDesignId'));
    const activeProductId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
    const activeVariant = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeVariant'));
    const listProducts = useSelector((state:WebsiteStore) => state.get('appData').get('products'));
    const productData = useSelector((state:WebsiteStore) => state.get('appData').get('products').get(String(activeProductId)));
    const designLoader = useSelector((state:WebsiteStore) => state.get('UIData').get('loaders').get('design-lab_design'));
    const productDesign = useSelector((state:WebsiteStore) => state.get('userData').get('productDesigns').get('data').get(searchParams.get('design')));

    const isEditing = useMemo(() => {
        return searchParams.has('design');
    }, [searchParams]);

    const [hide, setHide] = useState(!params.product && !isEditing);
    const [screenState, setScreenState] = useState<'lab'|'preview'>('lab');

    const isFabricLab = useMemo(() => activeProductId === 50, [activeProductId]);

    const isLoadingDesign = useMemo(() => {
        return isEditing && (
            !activeDesignId ||
            !productDesign ||
            designLoader
        )
    }, [isEditing, activeDesignId, productDesign, designLoader])

    //Load design from url
    useEffect(() => {
        if(!searchParams.has('design') || searchParams.get('design') === activeDesignId) return;
        
        dispatch(EditLoader("design-lab_design", true));
        dispatch(FetchOneItem(searchParams.get('design'), 'productDesigns', ['designData']));
    }, []);

    useEffect(() => {
        if(!productDesign || productDesign.get('id') === activeDesignId) return;

        if(productDesign.get('id_product') != productData?.get('id')) {
            navigate('/'+tR('create')+'/'+listProducts.get(String(productDesign.get('id_product'))).get('url_slug')+'?design='+productDesign.get('id'), {
                replace: true
            });
            return;
        }

        batch(() => {
            dispatch(EditActiveDesign(searchParams.get('design')));

            if(productDesign.get('id_product_variant')) {
                dispatch(EditActiveVariant(listProducts.get(String(productDesign.get('id_product'))).get('variants').get(String(productDesign.get('id_product_variant'))).get('slug')));
            }
            dispatch(EditDesignName(productDesign.get('name')));

            //Load layers
            let selectedLayer:string|null = null;
            Object.keys(productDesign.get('designData').layers).forEach(layerId => {
                //Select first layer
                if(!selectedLayer) {
                    selectedLayer = layerId;
                }
                dispatch(EditLayer(layerId, productDesign.get('designData').layers[layerId]));
            });
            if(selectedLayer) {
                dispatch(SelectLayer(selectedLayer));
            }
            
            //Load mirrorModes
            if(productDesign.get('designData').mirrorModes) {
                Object.keys(productDesign.get('designData').mirrorModes).forEach(key => {
                    dispatch(EditMirrorMode(key, productDesign.get('designData').mirrorModes[key]));
                });
            }

            //Load autoDesign
            if(productDesign.get('designData').autoDesignSubproducts) {
                Object.keys(productDesign.get('designData').autoDesignSubproducts).forEach(key => {
                    dispatch(EditAutoDesignSubproduct(key, productDesign.get('designData').autoDesignSubproducts[key]));
                });
            }

            //Load options
            if(productDesign.get('designData').options) {
                Object.keys(productDesign.get('designData').options).forEach(key => {
                    dispatch(EditActiveOption(key, productDesign.get('designData').options[key]));
                });
            }
            
            dispatch(EditLoader("design-lab_design", false));
        });
        
    }, [productData, productDesign]);

    //Change product based on url
    useEffect(() => {
        //Do nothing if it's the same as currently selected product
        if(params.product === productData?.get('url_slug')) return;

        if(!params.product) {
            dispatch(EditActiveProduct(null))
            return
        }

        const newProduct = listProducts.find(product => {
            return product.get('url_slug') === params.product;
        });

        if(!newProduct) return;

        batch(() => {
            dispatch(EditActiveProduct(newProduct.get('id')));
            dispatch(GetProductLabData(newProduct.get('slug')));

            //Always clear the lab when going in/out of fabric
            //TODO: Might implement this later for the whole lab, but with additional code to keep some data like layers
            if(productData?.get('slug') === 'fabric' || newProduct.get('slug') === 'fabric') {
                dispatch(ClearLab());
            }

            if(newProduct.get('slug') === 'fabric') {
                //FabricLab will handle its own default variant
                return;
            }

            //Don't reset anything if it's the same product or if we're loading a design
            if(newProduct.get('id') === productData?.get('id') || activeDesignId) {
                return;
            }

            //Reset variant
            let variant = 'default';

            if(newProduct.get('variants').count() > 0) {
                variant = newProduct.get('variants').first()?.get('slug') ?? variant;
            }   

            dispatch(EditActiveVariant(variant));

            //Reset options
            dispatch(ResetActiveOptions());
            const ENABLED_OPTION_TYPES = ['color', 'zipper-color', 'zipper-pull','fabric','loop-color'];
            newProduct.get('availableOptionTypes').forEach(optionType => {
                if(!ENABLED_OPTION_TYPES.includes(optionType.get('slug'))) {
                    return;
                }

                let option = optionType.get('options').first();

                //Try to get from searchparams
                const paramOption = searchParams.get('option_'+optionType.get('slug'))

                if(!!paramOption) {
                    option = optionType.get('options').find(option => option.get('slug') === paramOption) ?? option
                }

                if(!option) return

                dispatch(EditActiveOption(optionType.get('slug'), option.get('slug')));
            });
        })
    }, [params, listProducts]);

    useEffect(() => {
        if(!isBrowser) return;
        
        import('webfontloader').then(WebFont => WebFont.load({
			google: {
				families: LAB_FONTS
			},
			active: () => {
                //On load
            },
			classes: false
		}));
    }, []);
     
    return (
        <CssBaseline>
            <GlobalStyles
                styles={{
                    html: {
                        overscrollBehavior: 'none',
                        overflow: 'hidden',
                    }
                }}
            />

            <DesignLabAppContainer>
                <YellowDecoration/>
                <PinkDecoration className="left"/>
                <PinkDecoration className="right"/>
                <div style={{
                    height: '64px',
                    background: '#000',
                    color: '#fff',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    zIndex: 5,
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <h1 style={{
                            margin: '0 15px 0 15px',
                            height: '40px',
                        }}><img src={require('@resources/img/create/design-lab-v4/logo-design-lab.svg?url')} alt="Design Lab" style={{width: 'auto', height: '100%'}}/></h1>
                        <div style={{
                            width: '1px',
                            height: '36px',
                            background: '#fff',
                            marginLeft: '10px',
                            marginRight: '10px',
                        }}></div>
                        { !isEditing ? <>
                            <ProductSelector
                                onOpen={() => {
                                    setHide(true);
                                }}
                                onClose={() => {
                                    setHide(false);
                                }}
                            />                        
                            { productData?.get('variants').count() > 0 ? <> 
                                <div style={{
                                    width: '1px',
                                    height: '36px',
                                    background: '#fff',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}></div>
                                <VariantSelectorFormControl variant="standard">
                                    <Select
                                        variant="standard"
                                        input={<InputSelect/>}
                                        value={activeVariant}
                                        onChange={(e) => {
                                            dispatch(EditActiveVariant(String(e.target.value)));
                                        }}>
                                        { productData.get('variants').valueSeq().map(variant => {
                                            return <MenuItem key={variant.get('id')} value={variant.get('slug')}>{ variant.get('name') }</MenuItem>
                                        }) }
                                    </Select>
                                </VariantSelectorFormControl>
                            </> : null }
                        </> : null }
                    </div>

                    <div style={{
                        display: 'flex',
                        alignSelf: 'stretch',
                    }}>
                        { /** DEV: This is just a useful button for dev to force refresh the data */}
                        { DEV_MODE ? <IconButton
                            style={{
                                color: '#fff'
                            }}
                            onClick={() => {
                                dispatch(GetProductLabData(productData.get('slug')));
                            }}
                            title={t('Refresh Lab Data')}
                        >
                            <RefreshIcon />
                        </IconButton> : null }
                        <ExitLab/>
                    </div>
                </div>
                
                <HotkeysProvider initiallyActiveScopes={['design-lab']}>
                    { productData?.get('labData') ? <LabInterface className={hide || isLoadingDesign ? 'hidden':''}>
                        { !isFabricLab ? <LeftContainer className={screenState === 'lab' ? 'active':''}>
                            <LayerList 
                                hidden={hide} 
                                active={screenState === 'lab'}
                            />

                            <StageContainer className={hide ? 'hidden':(screenState === 'lab' ? 'active':'')}>
                                <SubproductSelector />
                                <SceneSelector />

                                <SeePreviewButton
                                    onClick={() => {
                                        setScreenState('preview');
                                    }}
                                >{ t('Next') }<ArrowWhite src={require('@resources/img/create/design-lab-v4/icons/white-arrow.svg?url')}/></SeePreviewButton>

                                <LabWrapper>
                                    <Paper 
                                        elevation={3}
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '20px 20px 0 0',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <LabStage />
                                        <AutoDesignOverlay />
                                    </Paper>
                                    <TemplateTools>
                                        <AutoDesignTool/>
                                        <MirrorTool/>
                                    </TemplateTools>
                                    <ZoomControls/>
                                </LabWrapper>
                            </StageContainer>
                        </LeftContainer> : <FabricLab 
                            hide={hide} 
                            active={screenState === 'lab'}
                            onNext={() => setScreenState('preview')}
                        /> }

                        <PreviewScreen 
                            className={hide ? 'hidden':(screenState === 'preview' ? 'active':'')}
                            style={{
                                flexGrow: 0,
                            }}
                        >
                            <BackToTemplateButton
                                onClick={() => {
                                    setScreenState('lab');
                                }}
                            ><WestIcon/>{ t('Back to Template') }</BackToTemplateButton>

                            { !isFabricLab ? <PreviewViewer /> : <FabricViewer labData={productData.get('labData').originalData} variant={activeVariant} inLab={true} /> }
                            <SaveBar/>
                        </PreviewScreen>
                    </LabInterface> : null }

                    <FullPageLabLoader hide={(productData?.get('labData') && !isLoadingDesign) || hide} />
                </HotkeysProvider>
            </DesignLabAppContainer>
        </CssBaseline>
    );
}