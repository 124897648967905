import * as Immutable from 'immutable';

export interface IProductVariantStore {
	id: number
	slug: string
    name: string
}

const defaultProductVariantStore = Immutable.Record<IProductVariantStore>({
	id: 0,
	slug: "",
    name: "",
});

export default class ProductVariantStore extends defaultProductVariantStore implements IProductVariantStore {}