import { type MouseEvent, useCallback, useState } from 'react'
import { type CollectionStore } from '../../UserData/_stores/CollectionStore'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { Settings as SettingsIcon, DriveFileRenameOutline as DriveFileRenameOutlineIcon } from '@mui/icons-material'
import DeleteIcon from '../../Common/Icons/DeleteIcon'
import { useTranslation } from 'react-i18next'
import DeleteCollectionDialog from './DeleteCollectionDialog'
import EditCollectionDialog from './EditCollectionDialog'

interface Props {
  collection: CollectionStore
}

export default function CollectionSettingsButton(props: Props) {
  const [t] = useTranslation('account-v2')

  const [menuOpened, setMenuOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)
  const [editDialogOpened, setEditDialogOpened] = useState(false)

  const onEditClose = useCallback(() => {
    setEditDialogOpened(false)
  }, [])

  const onEditOpen = useCallback(() => {
    setMenuOpened(false)
    setEditDialogOpened(true)
  }, [])

  const onDeleteClose = useCallback(() => {
    setDeleteDialogOpened(false)
  }, [])

  const onDeleteOpen = useCallback(() => {
    setMenuOpened(false)
    setDeleteDialogOpened(true)
  }, [])

  const onClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setMenuOpened(true)
  }, [])

  const onMenuClose = useCallback(() => {
    setMenuOpened(false)
  }, [])

  return <>
    <IconButton
      onClick={onClick}
    ><SettingsIcon /></IconButton>
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      open={menuOpened}
      onClose={onMenuClose}
    >
      <MenuItem onClick={onEditOpen}>
        <ListItemIcon><DriveFileRenameOutlineIcon /></ListItemIcon>
        <ListItemText>{t('Edit')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={onDeleteOpen}>
        <ListItemIcon><DeleteIcon /></ListItemIcon>
        <ListItemText>{t('Delete')}</ListItemText>
      </MenuItem>
    </Menu>

    <EditCollectionDialog
      collection={props.collection}
      opened={editDialogOpened}
      onClose={onEditClose}
    />

    <DeleteCollectionDialog
      collection={props.collection}
      opened={deleteDialogOpened}
      onClose={onDeleteClose}
    />
  </>
}
