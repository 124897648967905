import * as Immutable from 'immutable';
import { combineReducers } from 'redux-immutable';
import { CanvasToLab } from '../../Common/Utils/ZoomUtils';
import { AjaxActionEnd, AjaxActionStart } from '../../Common/_actions/AjaxAction';
import PatternDesignServiceReducer from '../../DesignLab/_reducers/PatternDesignServiceReducer';
import { DesignLabAction, DesignLabActionType } from '../_actions/DesignLabStoreActions';
import DesignLabLayerStore from '../_stores/DesignLab/DesignLabLayerStore';
import DesignLabStore, { IDesignLabStore } from '../_stores/DesignLab/DesignLabStore';
import ModelViewerReducer from '../../ModelViewer/_reducers/ModelViewerReducer';
import { v4 as uuidv4 } from 'uuid';

const DesignLabReducer = (store:DesignLabStore = new DesignLabStore(), action: DesignLabAction | AjaxActionStart | AjaxActionEnd):DesignLabStore => {
    let id, layer;

    switch (action.type) {
        case DesignLabActionType.AddImgLayer:
            id = uuidv4();
            layer = new DesignLabLayerStore({
                ...action.data,
                type: 'img',
                id: id,
            });
            store = store.set('layers', store.get('layers').set(id, layer));
            store = store.set('selectedLayer', id);
            break;

        case DesignLabActionType.AddPatternLayer:
            id = uuidv4();
            layer = new DesignLabLayerStore({
                ...action.data,
                type: 'pattern',
                patternMode: 'repeat',
                id: id,
            });
            store = store.set('layers', store.get('layers').set(id, layer));
            store = store.set('selectedLayer', id);
            break;

        case DesignLabActionType.AddTextLayer:
            id = uuidv4();
            layer = new DesignLabLayerStore({
                ...action.data,
                type: 'text',
                id: id,
            });
            store = store.set('layers', store.get('layers').set(id, layer));
            store = store.set('selectedLayer', id);
            break;

        case DesignLabActionType.EditLayer:
            let oldLayer = store.get('layers').get(action.id);
            if(!oldLayer) {
                oldLayer = new DesignLabLayerStore({
                    id: action.id,
                })
            }

            store = store.set('layers', store.get('layers').set(action.id, oldLayer.withMutations(layer => {
                action.data.type !== undefined && layer.set('type', action.data.type);
                action.data.scene !== undefined && layer.set('scene', action.data.scene);
                action.data.subproduct !== undefined && layer.set('subproduct', action.data.subproduct);
                action.data.width !== undefined && layer.set('width', action.data.width);
                action.data.height !== undefined && layer.set('height', action.data.height);
                action.data.originalWidth !== undefined && layer.set('originalWidth', action.data.originalWidth);
                action.data.originalHeight !== undefined && layer.set('originalHeight', action.data.originalHeight);
                action.data.x !== undefined && layer.set('x', action.data.x);
                action.data.y !== undefined && layer.set('y', action.data.y);
                action.data.scaleX !== undefined && layer.set('scaleX', action.data.scaleX);
                action.data.scaleY !== undefined && layer.set('scaleY', action.data.scaleY);
                action.data.rotation !== undefined && layer.set('rotation', action.data.rotation);
                action.data.text !== undefined && layer.set('text', action.data.text);
                action.data.font !== undefined && layer.set('font', action.data.font);
                action.data.color !== undefined && layer.set('color', action.data.color);
                action.data.src !== undefined && layer.set('src', action.data.src);
                action.data.fileid !== undefined && layer.set('fileid', action.data.fileid);
                action.data.patternMode !== undefined && layer.set('patternMode', action.data.patternMode);
                action.data.alignment !== undefined && layer.set('alignment', action.data.alignment);
            })));
            break;

        case DesignLabActionType.RemoveLayer:
            store = store.set('layers', store.get('layers').remove(action.id));
            break;

        case DesignLabActionType.SelectLayer:
            store = store.set('selectedLayer', action.id);
            break;

        case DesignLabActionType.EditActiveDesign:
            store = store.set('activeDesignId', action.id);
            break;

        case DesignLabActionType.EditActiveProduct:
            store = store.withMutations(store => {
                //Do nothing if we're not changing
                if(store.get('activeProductId') === action.id) {
                    return;
                }

                store.set('activeProductId', action.id);
                store.set('activeSubproduct', null);
                store.set('activeVariant', 'default');
                store.set('activeScene', null);
                store.set('mirrorModes', Immutable.OrderedMap<string, number>());
            });
            break;

        case DesignLabActionType.EditActiveScene:
            store = store.set('activeScene', action.scene);
            break;

        case DesignLabActionType.EditActiveVariant:
            store = store.set('activeVariant', action.variant);
            break;

        case DesignLabActionType.EditActiveSubproduct:
            store = store.set('activeSubproduct', action.subproduct);
            break;

        case DesignLabActionType.EditActiveOption:
            store = store.set('activeOptions', store.get('activeOptions').set(action.typeSlug, action.valueSlug));
            break;

        case DesignLabActionType.ResetActiveOptions:
            store = store.set('activeOptions', Immutable.OrderedMap<string, string>());
            break;

        case DesignLabActionType.EditZoom:
            // Apply Zoom limits
            let current_zoom = store.get('zoomScale')
            let new_zoom = Math.min(Math.max(current_zoom + action.zoom, 0.5), 4)
            
            let current_offset = {x: store.get('zoomOffsetX'), y: store.get('zoomOffsetY')}

            // Compensate for offset
            let position = {
                x: current_offset.x + action.position.x / current_zoom,
                y: current_offset.y + action.position.y / current_zoom
            }

            // Convert position
            position = CanvasToLab(position, current_offset, current_zoom)

            // Compensate for new zoom
            position = {
                x: position.x - position.x / new_zoom,
                y: position.y - position.y / new_zoom,
            }

            // Clamp
            let labMinX = Math.min(0, (action.stageDimensions.x - action.stageDimensions.x/new_zoom)/2);
            let labMinY = Math.min(0, (action.stageDimensions.y - action.stageDimensions.y/new_zoom)/2);

            let labMaxX = Math.max((action.stageDimensions.x - action.stageDimensions.x/new_zoom)/2, action.stageDimensions.x - action.stageDimensions.x/new_zoom);
            let labMaxY = Math.max((action.stageDimensions.y - action.stageDimensions.y/new_zoom)/2, action.stageDimensions.y - action.stageDimensions.y/new_zoom);

            position = {
                x: Math.min(Math.max(position.x, labMinX), labMaxX),
                y: Math.min(Math.max(position.y, labMinY), labMaxY),
            }

            store = store.withMutations(store => {
                store.set('zoomScale', new_zoom)
                store.set('zoomOffsetX', position.x)
                store.set('zoomOffsetY', position.y)
            })
            break

        case DesignLabActionType.EditIsPanning:
            store = store.set('isPanning', action.isPanning);
            break;
        
        case DesignLabActionType.EditMirrorMode:
            store = store.set('mirrorModes', store.get('mirrorModes').set(action.scene, action.mirrorMode));
            break;

        case DesignLabActionType.EditBackgroundColor:
            store = store.set('backgroundColor', action.color);
            break;
    
        case DesignLabActionType.EditAutoDesignSubproduct:
            store = store.set('autoDesignSubproducts', store.get('autoDesignSubproducts').set(action.subproduct, action.value));
            break;

        case DesignLabActionType.SetLayerOrder:
            store = store.withMutations(store => {
                store.set('layers', store.get('layers').sort((a, b) => {
                    const aIndex = action.listIds.findIndex((item) => {
                        return item === a.get('id');
                    })

                    const bIndex = action.listIds.findIndex((item) => {
                        return item === b.get('id');
                    })

                    if(aIndex !== -1 && bIndex !== -1) {
                        return bIndex - aIndex;
                    }

                    return 0;
                }))
            });
            break;

        case DesignLabActionType.UpdateTexture:
            store = store.set('textureUpdate', Date.now());
            break;

        case DesignLabActionType.EditWarning:
            store = store.set(
                'warnings', 
                store.get('warnings').set(
                    action.warning, 
                    store.get('warnings').get(action.warning).set(action.scene, action.value)
                )
            );
            break;

        case DesignLabActionType.EditWarnings:
            let warnings = Immutable.Record({
                resolution: Immutable.OrderedMap<string, boolean>(),
                templates_covered: Immutable.OrderedMap<string, boolean>(),
            })();

            const warningKeys = Object.keys(action.warnings);
            warningKeys.forEach(scene => {
                const warningScenes = Object.keys(action.warnings[scene]);
                warningScenes.forEach((warning:'resolution'|'templates_covered') => {
                    warnings = warnings.set(warning, warnings.get(warning).set(scene, action.warnings[scene][warning]));
                });
            });

            store = store.set('warnings', warnings);
            break;

        case DesignLabActionType.ResetWarnings:
            store = store.set(
                'warnings', 
                Immutable.Record({
                    resolution: Immutable.OrderedMap<string, boolean>(),
                    templates_covered: Immutable.OrderedMap<string, boolean>(),
                })(),
            );
            break;

        case DesignLabActionType.EditDesignName:
            store = store.set('designName', action.name);
            break;

        case DesignLabActionType.ClearLab:
            store = store.withMutations(store => {
                store.set('layers', Immutable.OrderedMap<string, DesignLabLayerStore>());
                store.set('selectedLayer', null);
                store.set('activeOptions', Immutable.OrderedMap<string, string>());
                store.set('mirrorModes', Immutable.OrderedMap<string, number>());
                store.set('autoDesignSubproducts', Immutable.OrderedMap<string, boolean>());
                store.set('backgroundColor', null);
                store.set('warnings', Immutable.Record<{
                    resolution: Immutable.OrderedMap<string, boolean>
                    templates_covered: Immutable.OrderedMap<string, boolean>
                }>({
                    resolution: Immutable.OrderedMap<string, boolean>(),
                    templates_covered: Immutable.OrderedMap<string, boolean>(),
                })());
            });
            break;
	}

	return combineReducers<IDesignLabStore>({
		layers: (store) => store,
        selectedLayer: (store) => store,
        activeScene: (store) => store,
        activeDesignId: (store) => store,
        activeProductId: (store) => store,
        activeVariant: (store) => store,
        activeSubproduct: (store) => store,
        activeOptions: (store) => store,
        zoomScale: (store) => store,
        zoomOffsetX: (store) => store,
        zoomOffsetY: (store) => store,
        isPanning: (store) => store,
        mirrorModes: (store) => store,
        autoDesignSubproducts: (store) => store,
        backgroundColor: (store) => store,
        patternDesignService: PatternDesignServiceReducer,
        textureUpdate: (store) => store,
        warnings: (store) => store,
        designName: (store) => store,
        modelViewer: ModelViewerReducer,
	}) (store, action) as DesignLabStore;
}

export default DesignLabReducer