import { createAction } from "../../Common/_actions/Action";
import { CollectionPayload } from "../_stores/CollectionStore";

export enum CollectionStoreActionType {
  EditCollection = 'COLLECTION_EDIT_COLLECTION',
  EditManyCollections = 'COLLECTION_EDIT_MANY_COLLECTIONS',
  RemoveCollection = 'COLLECTION_REMOVE_COLLECTION',
}

export const EditCollection = (collection: CollectionPayload) => createAction(CollectionStoreActionType.EditCollection, {
	collection: collection
});

export const EditManyCollections = (collections: Record<string, CollectionPayload>) => createAction(CollectionStoreActionType.EditManyCollections, {
	collections: collections
});

export const RemoveCollection = (id: number) => createAction(CollectionStoreActionType.RemoveCollection, {
	id: id
});

export type CollectionStoreAction =
    ReturnType<typeof EditCollection> |
    ReturnType<typeof EditManyCollections> |
    ReturnType<typeof RemoveCollection>