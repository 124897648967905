import i18next from 'i18next';
import { Dispatch } from 'redux';

import { createAction } from '../../Common/_actions/Action';
import { AjaxCallback, AjaxResponse, createAjaxAction } from '../../Common/_actions/AjaxAction';
import { Toast } from '../../UIData/_actions/UIDataActions';
import { UserStorePagePayload } from '../_stores/UserStorePageStore';

export enum UserStorePageActionType {
	EditUserStorePages  = "EDIT_USER_STORE_PAGES",
	RemoveUserStorePage = "REMOVE_USER_STORE_PAGE",

	FetchUserStorePages = 'USER_STORE_PAGE_FETCH_USER_STORE_PAGES',
}

export const RemoveUserStorePage = (id: string) => createAction(UserStorePageActionType.RemoveUserStorePage, {
	id: id
});

export const EditUserStorePages = (storePages: object) => createAction(UserStorePageActionType.EditUserStorePages, {
	storePages: storePages
});

export const FetchUserStorePages = (store: string) => createAjaxAction<{
	storePages: UserStorePagePayload[]
}>(UserStorePageActionType.FetchUserStorePages, {
	url: "ajax/d/"+store+"/store-pages",
	method: "GET",
	preventDuplicates: true,
	onSuccess: (response, dispatch) => {
		dispatch(EditUserStorePages(response.data.storePages));
	},
	onFailure: (response, dispatch) => {
		if(response && response.data && response.data.error)
			dispatch(Toast(response.data.error));
		else
			dispatch(Toast(i18next.t('Something went wrong!')));
	}
});

export type UserStorePageAction =
	ReturnType<typeof EditUserStorePages>		|
	ReturnType<typeof RemoveUserStorePage>