import Immutable from "immutable";
import { IPaginationStore } from "./PaginationStore";
import { UserImageStore } from "./UserImageStore";
import PaginationItemListStore from "./PaginationItemListStore";

export interface IUserImageListStore extends IPaginationStore {
	data: Immutable.Map<string, UserImageStore>,
  isUploading: boolean
}

const defaultUserImageListStore = Immutable.Record<IUserImageListStore>({
	itemLists: Immutable.Map<string, PaginationItemListStore>(),
	data: Immutable.Map<string, UserImageStore>(),
  isUploading: false,
});

export default class UserImageListStore extends defaultUserImageListStore implements IUserImageListStore {}