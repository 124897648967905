import Immutable from "immutable";

export interface UserRewardPayload {
	id: number
	slug: string
	name: string
	code: string
	used: boolean
}

export interface IUserRewardStore {
	id: number
	slug: string
	name: string
	code: string
	used: boolean
}

const defaultUserRewardStore = Immutable.Record<IUserRewardStore>({
	id: 0,
	slug: "",
	name: "",
	code: "",
	used: false,
});

export default class UserRewardStore extends defaultUserRewardStore implements IUserRewardStore {}