import Immutable from "immutable";
import { IPaginationStore } from "./PaginationStore";
import { UserAddressStore } from "./UserAddressStore";
import PaginationItemListStore from "./PaginationItemListStore";

export interface IUserAddressListStore extends IPaginationStore {
	data: Immutable.Map<string, UserAddressStore>,
}

const defaultUserAddressListStore = Immutable.Record<IUserAddressListStore>({
	itemLists: Immutable.Map<string, PaginationItemListStore>(),
	data: Immutable.Map<string, UserAddressStore>(),
});

export default class UserAddressListStore extends defaultUserAddressListStore implements IUserAddressListStore {}