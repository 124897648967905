import * as Immutable from 'immutable';

export interface IViewProductAttributeValueStore {
    id: number,
    type: number,
	slug: string
    name: string
}

const defaultViewProductAttributeValueStore = Immutable.Record<IViewProductAttributeValueStore>({
    id: 0,
    type: 0,
	slug: '',
    name: '',
});

export default class ViewProductAttributeValueStore extends defaultViewProductAttributeValueStore implements IViewProductAttributeValueStore {}