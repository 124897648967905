import { useContext, useEffect } from "react";
import { Vector2 } from "three";
import ModelElements from "../ModelElements";
import { ProductModelProps } from "../ModelViewerProduct";
import ProductTextureContext from "../_contexts/ProductTextureContext";
import useGLB from "../_hooks/useGLB";

export default function YogaShorts(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/yoga-shorts.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    
    const [productTexture] = useContext(ProductTextureContext)
    const option_fabric = props.options.get('fabric')
    const shiny_val = option_fabric == "glitter-performance-knit" ? new Vector2(2, 2) : new Vector2(0, 0)

    useEffect(() => {
        if(!elements) return

        for(const element of elements) {
            // Scale the effect maps used for shiny fabric
            if (!!element.material?.metalnessMap
                && !!element.material?.normalMap
                && !!element.material?.roughnessMap
            ) {
                element.material.metalnessMap.repeat = shiny_val
                element.material.normalMap.repeat = shiny_val
                element.material.roughnessMap.repeat = shiny_val
            }

            // Tune down aoMap
            if (!!element.material?.aoMap) {
                element.material.aoMapIntensity = 0.5
            }
        }
    }, [elements, option_fabric])

    // Apply lab texture
    useEffect(() => {
        if(!elements || !elements[0]?.material) return 

        elements[0].material.map = productTexture
        elements[0].material.map.flipY = false
        elements[0].material.map.needsUpdate = true
    }, [productTexture])
    
    return <ModelElements elements={elements}/>
}