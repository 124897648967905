import { type ChangeEvent, type FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import DialogButton from '../../../Common/Buttons/DialogButton'
import Dialog from '../../../Common/Dialog/Dialog'
import DialogActions from '../../../Common/Dialog/DialogActions'
import DialogContent from '../../../Common/Dialog/DialogContent'
import DialogTitle from '../../../Common/Dialog/DialogTitle'
import { FetchPaginationItems } from '../../../UserData/_actions/UserDataActions'
import { useTranslation } from 'react-i18next'
import { MenuItem, Stack, TextField, Typography } from '@mui/material'
import SelectV2 from '../../../Common/Form/SelectV2'
import { CreateUserTaxNumber } from '../../../UserData/_actions/UserTaxNumberActions'
import { useAppDispatch } from '../../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../../Common/_hooks/useAppSelector'
import { type UserAddressStore } from '../../../UserData/_stores/UserAddressStore'

interface Props {
  opened: boolean
  onClose: () => void
}

export default function AddTaxNumberDialog(props: Props) {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()

  const addresses = useAppSelector(state => state.get('userData').get('addresses').get('data'))

  const [type, setType] = useState('')
  const [address, setAddress] = useState(0)
  const [taxNumber, setTaxNumber] = useState('')

  useEffect(() => {
    const formData = new FormData()
    formData.append('offset', String(0))
    formData.append('limit', String(200))

    dispatch(FetchPaginationItems('tax-numbers', formData, 'addresses'))
  }, [])

  const onChangeType = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setType(e.target.value)
  }, [])

  const onChangeAddress = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAddress(Number(e.target.value))
  }, [])

  const onChangeTaxNumber = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTaxNumber(e.target.value)
  }, [])

  const onSubmit = useCallback((e: FormEvent) => {
    e.preventDefault()

    const fd = new FormData()
    fd.append('type', type)
    fd.append('id_address', String(address))
    fd.append('tax_number', taxNumber)

    dispatch(CreateUserTaxNumber(fd).set({
      onSuccess: () => {
        props.onClose()
      }
    }))
    props.onClose()
  }, [type, address, taxNumber])

  const valid = useMemo(() => {
    return type !== '' && address > 0 && taxNumber !== ''
  }, [type, address, taxNumber])

  const renderAddress = useCallback((address: UserAddressStore) => {
    return <MenuItem
      key={address.get('id')}
      value={address.get('id')}
      style={{
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
    >
      {address.get('name')}<br />
      <Typography variant="caption">{address.get('address')}</Typography>
    </MenuItem>
  }, [])

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <form onSubmit={onSubmit}>
      <DialogTitle>{t('Add Tax number')}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body1" component="p">{t('You\'ll need to add your business address to your account first to be able to link it to your new tax number.')}</Typography>

          <SelectV2
            label={t('Type')}
            value={type}
            onChange={onChangeType}
          >
            <MenuItem value="uk-vat">{t('UK VAT')}</MenuItem>
            <MenuItem value="eu-vat">{t('EU VAT')}</MenuItem>
            <MenuItem value="canada-sk-pst">{t('Saskatchewan PST')}</MenuItem>
            <MenuItem value="canada-bc-pst">{t('British Columbia PST')}</MenuItem>
          </SelectV2>

          <SelectV2
            label={t('Address')}
            value={address}
            onChange={onChangeAddress}
          >
            {addresses.valueSeq().map(renderAddress)}
          </SelectV2>

          <TextField
            value={taxNumber}
            onChange={onChangeTaxNumber}
            label={t('Tax number')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <DialogButton
          color="secondary"
          onClick={props.onClose}
        >{t('Cancel')}</DialogButton>
        <DialogButton
          color="primary"
          type="submit"
          disabled={!valid}
        >{t('Save')}</DialogButton>
      </DialogActions>
    </form>
  </Dialog>
}
