import { Dispatch } from 'redux';
import { ActionType } from './Action';

type HTTPMethod = "GET" | "POST";

export type AjaxResponse<ResponseData> = { data: ResponseData };
export type AjaxCallback<ResponseData> = (response: AjaxResponse<ResponseData>, dispatch: Dispatch) => void;

export type AjaxActionType = "AJAX_ACTION";
export const AjaxActionType = "AJAX_ACTION";
export type AjaxActionStartType = "AJAX_ACTION_START";
export const AjaxActionStartType = "AJAX_ACTION_START";
export type AjaxActionEndType = "AJAX_ACTION_END";
export const AjaxActionEndType = "AJAX_ACTION_END";

export type ErrorResponseData = {
	data?: {
		error?: string
	}
}

export type AjaxErrorCallback = (response: ErrorResponseData, dispatch: Dispatch) => void;

export type AjaxActionPayload<ResponseData> = {
	url: string
	headers?: Record<string, unknown>;
	limit?: number
	abortPrevious?: boolean
	preventDuplicates?: boolean
	credentials?: 'include' | 'omit' | 'same-origin'
	method?: HTTPMethod
	onSuccess?: AjaxCallback<ResponseData>;
	onFailure?: AjaxErrorCallback;
	onAbort?: AjaxCallback<unknown>;
	data?: { [x: string]: any }
	raw?: boolean
}

export interface AjaxAction<ResponseData> extends AjaxActionPayload<ResponseData> {
	type: AjaxActionType;
	actionType: ActionType;
	set: (params: {
		onSuccess?: AjaxCallback<ResponseData>
		onFailure?: AjaxErrorCallback
		onAbort?: AjaxCallback<unknown>
	}) => AjaxAction<ResponseData>
}

export interface AjaxActionStart<ResponseData> extends AjaxActionPayload<ResponseData> {
	type: AjaxActionStartType;
	actionType: ActionType;
}

export interface AjaxActionEnd<ResponseData> extends AjaxActionPayload<ResponseData> {
	type: AjaxActionEndType;
	actionType: ActionType;
}

export function createAjaxAction<ResponseData>(actionType: ActionType, payload: AjaxActionPayload<ResponseData>): AjaxAction<ResponseData> {
	return {
		type: AjaxActionType,
		actionType: actionType,
		...payload,
		set: function (params: {
			onSuccess?: AjaxCallback<ResponseData>
			onFailure?: AjaxErrorCallback
			onAbort?: AjaxCallback<unknown>
		}) {
			if (params.onSuccess) {
				const onSuccess = this.onSuccess
				this.onSuccess = (response, dispatch) => {
					onSuccess && onSuccess(response, dispatch);
					params.onSuccess && params.onSuccess(response, dispatch);
				}
			}

			if (params.onFailure) {
				const onFailure = this.onFailure;
				this.onFailure = (response, dispatch) => {
					onFailure && onFailure(response, dispatch);
					params.onFailure && params.onFailure(response, dispatch);
				}
			}

			if (params.onAbort) {
				const onAbort = this.onAbort;
				this.onAbort = (response, dispatch) => {
					onAbort && onAbort(response, dispatch);
					params.onAbort && params.onAbort(response, dispatch);
				}
			}

			return this;
		}
	};
}

export function createAjaxActionStart<ResponseData>(
	actionType: ActionType,
	payload: AjaxActionPayload<ResponseData>
): AjaxActionStart<ResponseData> {
	return { type: AjaxActionStartType, actionType: actionType, ...payload };
}

export function createAjaxActionEnd<ResponseData>(actionType: ActionType, payload: AjaxActionPayload<ResponseData>): AjaxActionEnd<ResponseData> {
	return { type: AjaxActionEndType, actionType: actionType, ...payload };
}