import { useTranslation } from "react-i18next";

interface Props {
  slug: string
  name: string
}

export default function ProductCardImage(props:Props) {
  const [t] = useTranslation('products');

  return <img 
    src={require('@resources/img/all-products/products/'+props.slug+'.jpg')}
    alt={t('Picture of custom printed {{product}}', { product: props.name})}
  />
}