import * as Immutable from 'immutable';
import UserRewardStore, { UserRewardPayload } from '../_stores/UserRewardStore';
import { UserRewardStoreAction, UserRewardStoreActionType } from '../_actions/UserRewardStoreActions';

const editUserReward = (store: Immutable.OrderedMap<string, UserRewardStore>, action: UserRewardPayload) => {
  return store.withMutations(store => {
    const oldUserReward = store.get(String(action.id));

    const { ...otherProps } = action;

    let newUserReward = new UserRewardStore(otherProps);

    if (oldUserReward) {
      newUserReward = oldUserReward.mergeDeep(newUserReward);
    }

    store.set(String(action.id), newUserReward);
  })
}

const UserRewardReducer = (
  store: Immutable.OrderedMap<string, UserRewardStore> = Immutable.OrderedMap<string, UserRewardStore>(),
  action: UserRewardStoreAction
): Immutable.OrderedMap<string, UserRewardStore> => {
  switch (action.type) {
    case UserRewardStoreActionType.EditUserReward:
      store = editUserReward(store, action.userReward);
      break;

    case UserRewardStoreActionType.EditManyUserRewards:
      Object.values(action.userRewards).forEach(userReward => {
        store = editUserReward(store, userReward);
      })
      break;

    case UserRewardStoreActionType.RemoveUserReward:
      store = store.remove(String(action.id));
      break;
  }
  return store;
}

export default UserRewardReducer