import Skeleton from '@mui/material/Skeleton';
import { FormControl, Grid, InputLabel, MenuItem, Select, styled } from '@mui/material';
import * as Immutable from 'immutable';
import * as React from 'react';
import { Trans, Translation } from 'react-i18next';
import { AjaxCallback } from '../../Common/_actions/AjaxAction';
import { PreviousButton } from '../../Common/_components/Buttons';
import { ModalSubtitle, ModalTitle } from '../../Common/_components/Typography';
import UserStorePageStore from '../../UserData/_stores/UserStorePageStore';
import ProductCardContainer from '../_containers/ProductCardContainer';
import ListingStore from '../_stores/ListingStore';
import ItemList from '../../Common/ItemList/ItemList';

export type Props = {
	compactVersion?: boolean
	maxItemsCount?: number
	index ?: number
	handleStep ?: (step:number) => void
	listings?: Immutable.Map<string,ListingStore>
	selectProductListing?: (id: string) => void
	publishProductListings?: (store:string, jsonData:string, successCallback:AjaxCallback) => void
	store?: string
	storePages?: Immutable.OrderedMap<string,UserStorePageStore>
	loader?: boolean
}
type State = {
	search: string
	squarespace_store_page_id: string
}

const MassSendingMainButtonContainer = styled('div')({
	position: 'fixed',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	background: '#FFFFFF',
	boxShadow: '2px 0px 4px rgba(0, 0, 0, 0.25)',
	borderRadius: '0px 0px 30px 30px',
	width: '100%',
	bottom: '0',
	left: '0',
	textAlign: 'center',
	padding: '12px',

	'@media (max-width: 959px)': {
		backgroundColor: 'transparent',
		boxShadow: 'none',
	}
});
const PinkButton = styled('button')({
	height: '45px',
	backgroundColor: '#F600BE',
	background: 'linear-gradient(0deg, #DA3192 0%, #F600BE 100%)',
	borderRadius: '22.5px',
	border: 'none',
	color: '#ffffff',
	width: '90%',

	'&:hover': {
		background: '#e83cae',
	},

	'&:focus': {
		background: 'linear-gradient(-180deg, #DA3192 0%, #F600BE 100%)',
	},

	'&[disabled]': {
		background: '#AAAFB4',
	}
});
const PaginationGridSection = styled(Grid)({
	marginTop: '25px',
	
	'.pagination-search': {
		margin: '30px 0',
		border: '1px solid #AFAFAF',
		boxSizing: 'border-box',
		boxShadow: 'none',
	},

	'.input-field input[type=search]:focus': {
		backgroundColor: 'unset',
	},
});
const PreviousButtonContainer = styled(Grid)({
	marginTop: '-22px',

	'@media(max-width:960px)': {
		marginTop: '-2px',
	},

	flexBasis: '100%',
});

const ScrollableContent = styled('div')({
	padding: '50px 60px 0px',
	width: '100%',
	height: 'calc(100% - 69px)',
	overflow: 'auto',

	'@media (max-width: 1200px)': {
		padding: '44px 40px 0px',
	},

	'@media (max-width: 959px)': {
		height: 'auto',
		padding: '0',
		overflow: 'visible',
		paddingBottom: '60px',
	}
});

const StyledInputLabel = styled(InputLabel)({
	fontFamily: 'Poppins',
	fontWeight: 'bold',
	fontSize: '10px',
	letterSpacing: '0.09em',
	textTransform: 'uppercase',
	transform: 'none',
	color: '#333333',
	position: 'initial',
});
const FullWidthFormControl = styled(FormControl)({
	width: '90%',
	textAlign: 'left',
});
const StyledSelect = styled(Select)({
	border: '1px solid #AFAFAF',
	boxSizing: 'border-box',
	minHeight: '45px',
	backgroundColor: '#ffffff',

	'.MuiSelect-select.MuiSelect-select': {
		paddingLeft: '12px',
		fontFamily: 'Poppins',
	}
});

const T = ({children, ...otherProps}:any) => <Trans ns="dashboard" {...otherProps}>{children}</Trans>

export default class EditSelectedProducts extends React.Component<Props, State> {
	
	public state = {
		search: '',
		squarespace_store_page_id: '',
	} as State

	componentDidUpdate() {
		//If we had 0 listings, go back to Select screen
		if(this.props.listings.count() === 0) {
			this.handleGoPreviousState();
		}
	}

	private handleGoPreviousState = () => {
		this.props.handleStep(this.props.index - 1)
	}
	private handlePublishProducts = () => {
		let listings = this.props.listings;

		//Apply squarespace_store_page
		if(this.props.store === 'squarespace') {
			listings = listings.withMutations((store) => {
				store.forEach((listing, key) => {
					store.setIn([key, 'squarespace_store_page_id'], this.state.squarespace_store_page_id);
				});
			});
		}

		let jsonData = JSON.stringify({
			products: listings.toJS()
		});

		this.props.publishProductListings(this.props.store, jsonData, (response, dispatch) => {
			this.props.handleStep(this.props.index + 2);
		});
	}
	private handleEditDetails = (id: string) => {
		this.props.selectProductListing(id);
		this.props.handleStep(this.props.index + 1)
	}
	render() {
		return <>
			<ScrollableContent>
				<Grid container>
					<PreviousButtonContainer item className="position-relative" xs={12}>
						<PreviousButton onClick={this.handleGoPreviousState}>
							{"< "}
							<T>Back</T>
						</PreviousButton>
					</PreviousButtonContainer>
					<Grid item>
						<ModalTitle><T>Edit Selected Products</T></ModalTitle>
						<ModalSubtitle><T>Edit details and pricing of your listings before publishing them to your store</T></ModalSubtitle>
					</Grid>
					

					<Translation>{t=>
						<PaginationGridSection item xs={12}>
							<ItemList
								controlled
								dataType={'productDesigns'}
								ids={this.props.listings ? this.props.listings.keySeq().toArray() : undefined}
								limit={9999}
								item={(item) => <ProductCardContainer item={item} hasEditButton handleEditDetails={this.handleEditDetails}/>}
								render={(items: JSX.Element, links: JSX.Element, loading: boolean) =>
									<Grid item xs={12}>
										{loading ?
											<Grid container spacing={2}>
												<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
												<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
												<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
												<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
												<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
												<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
												<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
												<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
												<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
											</Grid> :
											(items ? <>
												<Grid container spacing={2}>
													{items}
												</Grid>
											</> : <span className="pagination-noitems"><T>No Results</T></span>
										)}
									</Grid>
								}
							/>
						</PaginationGridSection>
						}
					</Translation>
				</Grid>
			</ScrollableContent>
				
			
			<MassSendingMainButtonContainer>
				<Grid container spacing={2}>
					{ this.props.store === 'squarespace' &&
						<Grid item xs={12}>
							<FullWidthFormControl variant="standard">
								<StyledInputLabel id="squarespace_store_page_id"><T>Store page</T></StyledInputLabel>
								<StyledSelect
									variant="standard"
									value={this.state.squarespace_store_page_id}
									labelId={"squarespace_store_page_id"}
									onChange={(e) => {
										this.setState({
											squarespace_store_page_id: String(e.target.value)
										});
									}}
								>
									<MenuItem key={"none"} value={""} disabled><T>Select an option...</T></MenuItem>
									{ this.props.storePages.entrySeq().map(([pageId, page]) => {
										return <MenuItem key={pageId} value={page.get('id')}>{page.get('title')}</MenuItem>
									})}
								</StyledSelect>
							</FullWidthFormControl>
						</Grid>
					}
					<Grid item xs={12}>
						<PinkButton 
							disabled={this.props.listings.count() === 0 
								|| (this.props.store === 'squarespace' && this.state.squarespace_store_page_id === "")
								|| this.props.loader
							} 
							onClick={this.handlePublishProducts}> <T>Publish</T> { this.props.listings.count() } <T>Products</T></PinkButton>
					</Grid>
				</Grid>
			</MassSendingMainButtonContainer>
		</>;
	}
}
