import * as Immutable from 'immutable';
import { PaginationItem, IPaginationStore} from './PaginationStore'
import ProductOptionTypeStore from '../../AppData/_stores/ProductOptionTypeStore';
import PreviewStore from '../../AppData/_stores/PreviewStore';
import semver from 'semver';
import { PricePayload } from '../../UIData/_stores/PriceStore';

export type UserProductDesignStatus = 'available'|'deleted'|'permadeleted'

export type UserProductDesignPayload = {
	id: string
	id_product_design: number
	id_product: number
	id_user: number
	id_product_variant: number|null
	status: UserProductDesignStatus
	name: string
	thumbnail: string
	preview: string
	date_created: string
	labVersion: string
	availableOptionTypes: {
		id: number,
		type: 'enum'|'bool',
		slug: string,
		name: string,
		theorder: number,
		visible: boolean,
		options: {
			id: number,
			id_product_variant: number,
			id_product_option_type: number,
			listDependencies: number[]
			slug: string,
			name: string,
			theorder: number,
			price_retail: PricePayload
			price_wholesale: PricePayload
			price_dropship: PricePayload
		}[],
	}[],
	defaultOptions: {
		id: number,
		id_product_option_type: number,
	}[],
	previews: {
		id: number,
		url: string,
	},
	textures: {
		id: number,
		slug: string,
		url: string,
	}[],
	details: {
		slug: string,
		valueSlug: string,
	}[],
}

interface IUserProductDesignStore extends PaginationItem {
	id: string
	id_product_design: number
	id_product: number
	id_product_variant: number
	variant: string
	name: string
	thumbnail: string
	preview: string
	labVersion: string
	availableOptionTypes: Immutable.Map<string, ProductOptionTypeStore>
	defaultOptions: Immutable.Map<number, number>
	previews: Immutable.Map<number, PreviewStore>
	textures: Immutable.Map<number, PreviewStore>
	details: Immutable.Map<string, string>
	designData: Record<string, any>
}

const defaultUserProductDesignStore = Immutable.Record<IUserProductDesignStore>({
	id: undefined,
	id_product_design: undefined,
	id_product: undefined,
	id_product_variant: undefined,
	variant: undefined,
	name: undefined,
	thumbnail: undefined,
	preview: undefined,
	labVersion: undefined,
	availableOptionTypes: Immutable.Map<string, ProductOptionTypeStore>(),
	defaultOptions: Immutable.Map<number, number>(),
	previews: Immutable.Map<number, PreviewStore>(),
	textures: Immutable.Map<number, PreviewStore>(),
	details: Immutable.Map<string, string>(),
	designData: undefined,
});

export class UserProductDesignStore extends defaultUserProductDesignStore implements IUserProductDesignStore {
	public isEditable():boolean {
		return this.get('labVersion') && semver.gte(this.get('labVersion'), '4.0.0');
	}
}