import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../_Sections/SectionDetails';
import SectionGallery from '../_Sections/SectionGallery';
import SectionDropship from '../_Sections/SectionDropship';
import SectionPairings from '../_Sections/SectionPairings';
import SectionBranding from '../_Sections/SectionBranding';
import SectionSizes from '../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { Container, Grid, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../_Sections/SectionPricing';
import tKey from '../../Common/Utils/tKey';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import ProductSEO from '../_Sections/ProductSEO';

const SectionHowTo = styled('div')(({ theme }) => ({
  backgroundColor: 'black',
  padding: '100px 0',
  'h2': {
    color: 'white',
    textAlign: 'center',
    fontSize: '4rem',
    lineHeight: 1,
    marginBottom: '40px',
  },
  'h3': {
    color: 'white',
    textAlign: 'center',
    fontSize: '3rem',
    marginTop: '80px',
    marginBottom: '40px',
  },
  'h4': {
    color: 'white',
    fontSize: '1.2rem',
    fontFamily: 'Poppins, sans-serif',
  },
  'img': {
    maxWidth: '100%',
  }
}))

const CautionText = styled('div')(({ theme }) => ({
  fontSize: '1.2rem',
  color: '#e33250',
  maxWidth: '700px',
  textAlign: 'center',
  fontFamily: 'Poppins, sans-serif',
  margin: '60px auto 0',
  'strong': {
    display: 'block',
    textTransform: 'uppercase',
  }
}))

const PRODUCT_ID = 122;
const VIEW_PRODUCT_ID = 143;

export default function PermanentStickerPage() {
  const [t] = useTranslation('page/products/permanent-stickers');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom Permanent Stickers | Products')}
      description={t('Design and create your own custom printed Permanent Stickers featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#edbbbb"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '48%',
      }}
      tag={t('The all-over print')}
      title={t('Permanent Stickers')}
      text={t('Enhance any surface with custom permanent stickers printed on demand for you! These vibrant, glossy stickers adhere to wood, ceramic, glass, and metal. Made with UV technology, they\'re waterproof and durable, ensuring long-lasting brilliance. Decorate your projects or anything else with these eye-catching stickers!')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/permanent-stickers/banner-1-' + locale + '.png')}
      alt={t('Picture of Permanent Stickers')}
      details={[
        {
          img: require('@resources/img/pages/products/permanent-stickers/icon-1.svg?url'),
          alt: t('Resistant icon'),
          text: t('Highly permanent and stick to most surfaces'),
        },
        {
          img: require('@resources/img/pages/products/permanent-stickers/icon-2.svg?url'),
          alt: t('Glossy icon'),
          text: t('Glossy finish, raised and textured surface'),
        },
        {
          img: require('@resources/img/pages/products/permanent-stickers/icon-3.svg?url'),
          alt: t('Waterproof icon'),
          text: t('Waterproof and microwave safe'),
        },
        {
          img: require('@resources/img/pages/products/permanent-stickers/icon-4.svg?url'),
          alt: t('Printing icon'),
          text: t('Printed with UV technology'),
        },
        {
          img: require('@resources/img/pages/products/permanent-stickers/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/permanent-stickers/gallery_0.jpg'),
      require('@resources/img/pages/products/permanent-stickers/gallery_1.jpg'),
      require('@resources/img/pages/products/permanent-stickers/gallery_2.jpg'),
      require('@resources/img/pages/products/permanent-stickers/gallery_3.jpg'),
      require('@resources/img/pages/products/permanent-stickers/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your permanent <br/>stickers <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Permanent Stickers! At prices designed for reselling, yoga shorts made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
      variants={['9x9']}
      listOptions
    />

    <SectionSizes
      productId={PRODUCT_ID}
      title={t('Permanent Stickers Sizing')}
      text={t('Permanent stickers are available in pre-determined sizes. Your permanent stickers will be laser cut outside your design. Permanent stickers require a minimum 300 DPI and should be submitted as png (with transparent background), or jpeg (white background will be printed). Available sizes include: ')}
    >
      <img
        src={require('@resources/img/pages/products/permanent-stickers/measurement.svg?url')}
        style={{
          marginRight: 0,
        }}
      />
    </SectionSizes>

    <SectionHowTo>
      <Container maxWidth="md">
        <h2><Trans
          t={t}
          i18nKey={tKey('<yellow>How</yellow> to apply <br/> <blue>permanent</blue> <pink>stickers</pink>?')}
          components={{
            yellow: <span className="tyy" />,
            pink: <span className="tp" />,
            blue: <span className="tb" />,
          }}
        /></h2>

        <video width="100%" controls>
          <source src={require('@resources/img/pages/products/permanent-stickers/how-to-videos/how-to-stickers-' + locale + '.webm')} type="video/webm" />
          <source src={require('@resources/img/pages/products/permanent-stickers/how-to-videos/how-to-stickers-' + locale + '.mov')} type="video/mov" />
          <source src={require('@resources/img/pages/products/permanent-stickers/how-to-videos/how-to-stickers-' + locale + '.mp4')} type="video/mp4" />
        </video>

        <h3>{t('Follow our step-by-step:')}</h3>
        <Grid container spacing={4}>
          <Grid item sm={6}>
            <img src={require('@resources/img/pages/products/permanent-stickers/how-to-step-1.jpg')} alt={t('Person cleaning a pencil case')} />
            <h4>{t('1. Clean/dry the intended surface for the sticker.')}</h4>
          </Grid>
          <Grid item sm={6}>
            <img src={require('@resources/img/pages/products/permanent-stickers/how-to-step-2.jpg')} alt={t('Person applying pressure on a permanent sticker')} />
            <h4>{t('2. Apply pressure on top of the printed design.')}</h4>
          </Grid>
          <Grid item sm={6}>
            <img src={require('@resources/img/pages/products/permanent-stickers/how-to-step-3.jpg')} alt={t('Person peeling transparent film from white backing')} />
            <h4>{t('3. Peel the transparent film carrier from the white backing, making sure the print comes with it.')}</h4>
          </Grid>
          <Grid item sm={6}>
            <img src={require('@resources/img/pages/products/permanent-stickers/how-to-step-4.jpg')} alt={t('Person applying sticker to pencil case')} />
            <h4>{t('4. Apply the sticker to the intended surface.')}</h4>
          </Grid>
          <Grid item sm={6}>
            <img src={require('@resources/img/pages/products/permanent-stickers/how-to-step-5.jpg')} alt={t('Person applying pressure to sticker')} />
            <h4>{t('5. Apply pressure to the printed design.')}</h4>
          </Grid>
          <Grid item sm={6}>
            <img src={require('@resources/img/pages/products/permanent-stickers/how-to-step-6.jpg')} alt={t('Persone peeling transparent film from sticker')} />
            <h4>{t('6. Peel the carrier film to leave the design on the surface.')}</h4>
          </Grid>
        </Grid>

        <CautionText className="center"><strong>{t('Caution!')}</strong> {t('Only apply permanent stickers to surfaces that you want them permanently affixed to. They cannot be easily removed.')}</CautionText>
      </Container>
    </SectionHowTo>

    <SectionBranding
      title={t('Brand your Permanent Stickers')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Permanent Stickers with these great products to create your own unique styles.')}
      productSlugs={['spiral-notebooks', 'carry-alls', 'digital-prints']}
    />
  </div>
}