import { type SyntheticEvent, useCallback, useState } from 'react'
import DialogButton from '../../Common/Buttons/DialogButton'
import Dialog from '../../Common/Dialog/Dialog'
import DialogActions from '../../Common/Dialog/DialogActions'
import DialogContent from '../../Common/Dialog/DialogContent'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { IgnoreStoreItem } from '../../UserData/_actions/OrderActions'
import { type StoreItemStore } from '../../UserData/_stores/StoreItemStore'
import { Checkbox, Collapse, FormControlLabel, Stack, Typography } from '@mui/material'

interface Props {
  idCart: number
  storeItem: StoreItemStore
  opened: boolean
  onClose: () => void
}

export default function IgnoreItemDialog(props: Props) {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()

  const [ignoreVariant, setIgnoreVariant] = useState(true)
  const [ignoreProduct, setIgnoreProduct] = useState(false)

  const onConfirm = useCallback(() => {
    dispatch(IgnoreStoreItem(
      props.idCart,
      props.storeItem.get('id'),
      ignoreVariant,
      ignoreProduct
    ).set({
      onSuccess: props.onClose
    }))
  }, [props.idCart, props.storeItem, props.onClose, ignoreVariant, ignoreProduct])

  const onIgnoreVariantChange = useCallback((e: SyntheticEvent<Element, Event>, checked: boolean) => {
    setIgnoreVariant(checked)

    if (!checked) {
      setIgnoreProduct(false)
    }
  }, [])

  const onIgnoreProductChange = useCallback((e: SyntheticEvent<Element, Event>, checked: boolean) => {
    setIgnoreProduct(checked)
  }, [])

  return <Dialog
    open={props.opened}
    maxWidth="sm"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <DialogTitle>{t('Ignore item "{{name}}"', { name: props.storeItem.get('product_name') })}</DialogTitle>
    <DialogContent>
      <Stack spacing={2}>
        <p>{t('Are you sure you want to ignore this item?')}</p>

        <div>
          <FormControlLabel
            control={<Checkbox/>}
            checked={ignoreVariant}
            onChange={onIgnoreVariantChange}
            label={<Stack>
              <Typography variant="body2">{t('Always ignore this variant')}</Typography>
              <Typography variant="caption">{t('All future orders of this product in this specific variant (size, color, etc.) will be ignored.')}</Typography>
            </Stack>}
          />
        </div>

        <Collapse in={ignoreVariant}>
          <FormControlLabel
            control={<Checkbox/>}
            checked={ignoreProduct}
            onChange={onIgnoreProductChange}
            label={<Stack>
              <Typography variant="body2">{t('Always ignore this product')}</Typography>
              <Typography variant="caption">{t('All future orders of this product (regardless of size, color, etc.) will be ignored.')}</Typography>
            </Stack>}
          />
        </Collapse>
      </Stack>

    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
      >{t('Cancel')}</DialogButton>
      <DialogButton
        color="error"
        onClick={onConfirm}
        autoFocus
      >{t('Confirm')}</DialogButton>
    </DialogActions>
  </Dialog>
}
