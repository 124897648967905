import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { type FeeItem, type ProductItemOption, type BaseItem, type ProductItem } from '../../UserData/_stores/UserCartStore'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { Box, Button, Divider, Grid, Stack, Typography, styled } from '@mui/material'
import { CloseRounded as CloseRoundedIcon, AddShoppingCartRounded as AddShoppingCartRoundedIcon } from '@mui/icons-material'
import NumberFieldV2 from '../../Common/Form/NumberFieldV2'
import { useTranslation } from 'react-i18next'
import { DeleteCartItem, UpdateCartItem } from '../../UserData/_actions/UserCartActions'
import Dinero from 'dinero.js'
import DeleteItemDialog from './DeleteItemDialog'
import OrderDialog from '../../Account/Products/OrderDialog'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import DeleteIcon from '../../Common/Icons/DeleteIcon'
import ItemBrandingOption from './ItemBrandingOption'
import { type BrandingOptionStore } from '../../UserData/_stores/BrandingOptionStore'
import type Immutable from 'immutable'
import { selectBrandingOptionsForItem } from '../../UserData/_selectors/BrandingOptionSelectors'

const ThumbnailWrapper = styled('div')(({ theme }) => ({
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,

  [theme.breakpoints.down('sm')]: {
    width: '64px',
    height: '64px'
  }
}))

const BrandingOptionList = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '8px',
  bottom: '-8px'
}))

const BrandingOptionImg = styled('img')(({ theme }) => ({
  width: '32px',
  height: 'auto',
  display: 'block',
  border: '2px solid #fff',
  borderRadius: '4px',
  position: 'absolute',
  left: 0,
  bottom: 0,

  [theme.breakpoints.down('sm')]: {
    width: '20px'
  }
}))

const CartItemContainer = styled(Grid)(({ theme }) => ({
  '&.small': {
    [`${ThumbnailWrapper}`]: {
      width: '64px',
      height: '64px',
      margin: '0 18px',

      [theme.breakpoints.down('sm')]: {
        margin: 0
      }
    },

    [`${BrandingOptionList}`]: {
      left: '4px',
      bottom: '-4px'
    },

    [`${BrandingOptionImg}`]: {
      width: '20px'
    }
  },

  '&.short': {
    [`${ThumbnailWrapper}`]: {
      width: '64px',
      height: '64px'
    },

    [`${BrandingOptionList}`]: {
      left: '4px',
      bottom: '-4px'
    },

    [`${BrandingOptionImg}`]: {
      width: '20px'
    }
  }
}))

const ButtonStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(1)
}))

const QuantityStack = styled(Stack)(({ theme }) => ({
  justifyContent: 'flex-start'
}))

interface Props {
  cart: UserCartStore
  item: BaseItem
  short?: boolean
  hidePrice?: boolean
  showReorder?: boolean
}

export default function CartItem(props: Props) {
  const [t] = useTranslation('cart')
  const dispatch = useAppDispatch()

  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))
  const quickDeletePref = useAppSelector(state => state.get('userData').get('prefs').get('quickDeleteFromCart'))
  const productBrandingOptions = useAppSelector(state => selectBrandingOptionsForItem(state, props.item))

  const [quantity, setQuantity] = useState(props.item.get('quantity'))
  const quantityTimeout = useRef<NodeJS.Timeout>()

  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)
  const [editDialogOpened, setEditDialogOpened] = useState(false)
  const [reorderDialogOpened, setReorderDialogOpened] = useState(false)

  useEffect(() => {
    setQuantity(props.item.get('quantity'))
  }, [props.item])

  const onQuantityChange = useCallback((value: number) => {
    setQuantity(value)

    clearTimeout(quantityTimeout.current)
    quantityTimeout.current = setTimeout(() => {
      const fd = new FormData()
      fd.append('id', String(props.item.get('id')))
      fd.append('fields[]', 'quantity')
      fd.append('quantity', String(value))

      dispatch(UpdateCartItem(fd))
    }, 500)
  }, [props.item])

  const onDeleteClose = useCallback(() => {
    setDeleteDialogOpened(false)
  }, [])

  const onDeleteOpen = useCallback(() => {
    if (quickDeletePref) {
      const fd = new FormData()
      fd.append('id', String(props.item.get('id')))

      dispatch(DeleteCartItem(fd))
    } else {
      setDeleteDialogOpened(true)
    }
  }, [quickDeletePref, props.item])

  const onEditClose = useCallback(() => {
    setEditDialogOpened(false)
  }, [])

  const onEditOpen = useCallback(() => {
    setEditDialogOpened(true)
  }, [])

  const onReorderClose = useCallback(() => {
    setReorderDialogOpened(false)
  }, [])

  const onReorderOpen = useCallback(() => {
    setReorderDialogOpened(true)
  }, [])

  const isSmallItem = useMemo(() => {
    return [
      'sticker',
      'artist_card',
      'branding_option',
      'sample_pack_preprinted_synthetic',
      'sample_pack_preprinted_natural',
      'sample_pack_preprinted_paper',
      'face_mask_filter'
    ].includes(props.item.get('type'))
  }, [props.item])

  const price = useMemo(() => {
    // Return the saved price if this is already paid
    if (!['cart', 'ordered'].includes(props.cart.get('status'))) {
      return props.item.get('price').toDinero()
    }

    return quantity >= 6
      ? props.item.get('price_wholesale').toDinero()
      : props.item.get('price_dropship').toDinero()
  }, [props.item, props.cart, quantity])

  const discount = useMemo(() => {
    // Return no discount if this is already paid
    if (!['cart', 'ordered'].includes(props.cart.get('status'))) {
      return Dinero({
        amount: 0,
        currency: props.item.get('price').get('currency')
      })
    }

    return quantity >= 6
      ? props.item.get('price_dropship').toDinero().subtract(props.item.get('price_wholesale').toDinero())
      : Dinero({
        amount: 0,
        currency: props.item.get('price').get('currency')
      })
  }, [props.item, props.cart, quantity])

  const totalDiscount = useMemo(() => {
    return discount.multiply(quantity)
  }, [discount, quantity])

  const totalPrice = useMemo(() => {
    return price.multiply(quantity)
  }, [price, quantity])

  const selectedOptions: Array<[number, number]> = useMemo(() => {
    if (props.item.get('type') !== 'product') return []

    const tmpOptions: Array<[number, number]> = [];
    (props.item as ProductItem).get('options').forEach(option => {
      tmpOptions.push([option.get('type_id'), option.get('id')])
    })

    return tmpOptions
  }, [props.item])

  const renderOption = useCallback((option: ProductItemOption) => {
    // Skip some options
    if (['printing-sides'].includes(option.get('type_slug'))) {
      return null
    }

    // Skip branding options unless it's short version
    if (!props.short && ['include-label'].includes(option.get('type_slug'))) {
      return null
    }

    const typeName = option.get('type_name')
    const optionName = option.get('name')

    return <div
      key={option.get('id')}
      style={{
        color: '#999',
        fontSize: 12
      }}
    >{typeName === optionName ? typeName : typeName + ': ' + optionName}</div>
  }, [props.short])

  const renderFee = useCallback((fee: FeeItem) => {
    const name = fee.get('type') === 'pattern_design' ? t('Pattern fee') : ''
    return <Typography
      key={fee.get('id')}
      variant="subtitle2"
      style={{
        lineHeight: 1
      }}
    >{name + ': ' + fee.get('price').toDinero().toFormat()}</Typography>
  }, [t])

  const productName = useMemo(() => {
    return [
      props.item.get('category'),
      props.item.get('product_name')
    ].filter(value => value !== '').join(' | ')
  }, [props.item])

  const renderBrandingOption = useCallback((brandingOption: BrandingOptionStore, key: number) => {
    return <BrandingOptionImg
      key={brandingOption.get('id')}
      src={brandingOption.get('file_url')}
      style={{
        left: (key * 16) + 'px'
      }}
    />
  }, [])

  const onEditConfirm = useCallback((listOptions: Immutable.Map<number, number>, listBrandingOptions: Immutable.Map<number, number>) => {
    const fd = new FormData()
    fd.append('id', String(props.item.get('id')))
    fd.append('fields[]', 'options')

    listOptions.forEach((optionId, optionTypeId) => {
      fd.append('options[]', String(optionId))
    })

    listBrandingOptions.forEach((brandingOptionId, optionId) => {
      fd.append('brandingOptions[' + optionId + ']', String(brandingOptionId))
    })

    dispatch(UpdateCartItem(fd).set({
      onSuccess: () => setEditDialogOpened(false)
    }))
  }, [props.item])

  return <CartItemContainer
    container
    spacing={2}
    className={props.short ? 'short' : (isSmallItem ? 'small' : '')}
    sx={{
      px: {
        xs: 2,
        lg: 3
      },
      pt: {
        xs: 2,
        lg: isSmallItem || props.short ? 1.5 : 3
      },
      pb: props.short ? 1.5 : 0.5,
      alignItems: 'center'
    }}
  >
    <Grid item xs={props.hidePrice ? 9 : 12} lg={6}>
      <Stack
        direction="row"
        spacing={2}
        style={{
          flexGrow: 1
        }}
      >
        <div style={{
          position: 'relative',
          alignSelf: 'flex-start'
        }}>
          <ThumbnailWrapper>
            {props.item.get('thumbnail') ? <img
              src={props.item.get('thumbnail')}
              style={{
                height: '100%',
                display: 'block'
              }}
            /> : null}
          </ThumbnailWrapper>

          {productBrandingOptions.count() > 0 ? <BrandingOptionList>
            { productBrandingOptions.valueSeq().map(renderBrandingOption) }
          </BrandingOptionList> : null }
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          <Typography variant="h4">{props.item.get('name')}</Typography>
          { productName !== '' ? <div style={{
            textTransform: 'uppercase',
            fontSize: '12px',
            fontWeight: '500'
          }}>{productName}</div> : null }

          {props.item.get('type') === 'product' ? <>
            <Stack
              direction={props.short ? 'row' : 'column'}
              divider={props.short ? <Divider orientation="vertical" flexItem style={{ marginTop: '4px', marginBottom: '4px' }} /> : undefined}
              spacing={props.short ? 0.5 : 0}
              flexWrap="wrap"
              useFlexGap
            >
              {(props.item as ProductItem).get('options').valueSeq().map(renderOption)}

              {(props.item as ProductItem).get('dynamicOptions').entrySeq().map(([name, dynamicOption]) => {
                return <div
                  key={name}
                  style={{
                    color: '#999',
                    fontSize: 12
                  }}
                >{t('Text: ')}{dynamicOption.get('data')}</div>
              })}
            </Stack>

            {!props.short ? <ButtonStack
              direction="row"
              spacing={1}
              useFlexGap
              flexWrap="wrap"
            >
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                disabled={cartLoader}
                onClick={onEditOpen}
              >{t('Edit options')}</Button>
              {(props.item as ProductItem).get('brandingOptions').has('include-label') ? <>
                <ItemBrandingOption
                  item={props.item}
                  typeId={1}
                />
              </> : null}
            </ButtonStack> : null}

          </> : null}
        </div>
      </Stack>
    </Grid>

    { !props.hidePrice ? <Grid item xs={5} lg>
      <QuantityStack
        direction={props.item.get('fixedQuantity') || props.short ? 'row' : {
          xs: 'column',
          sm: 'row'
        }}
        spacing={props.item.get('fixedQuantity') || props.short ? 0 : {
          xs: 1,
          sm: 0
        }}
        sx={{
          alignItems: props.item.get('fixedQuantity') || props.short ? 'center' : {
            xs: 'flex-start',
            sm: 'center'
          }
        }}
      >
        {props.item.get('fixedQuantity') || props.short ? <div style={{ fontSize: '14px' }}>
          {props.item.get('quantity')}
        </div> : <NumberFieldV2
          value={quantity}
          onChange={onQuantityChange}
          min={1}
          max={99999}
          disabled={cartLoader}
        />}
        <div>
          <Stack direction="row" alignItems="center" style={{ marginTop: !discount.isZero() ? '8px' : 0 }}>
            <Box
              component="div"
              sx={{
                marginRight: '5px',
                marginLeft: {
                  xs: '5px',
                  md: '15px'
                }
              }}
            ><CloseRoundedIcon style={{ display: 'block', fontSize: '12px' }} /></Box>
            <div style={{
              position: 'relative'
            }}>
              {!discount.isZero() ? <div style={{
                fontSize: '10px',
                fontWeight: '500',
                textDecoration: 'line-through',
                color: '#999',
                position: 'absolute',
                top: '-10px',
                left: '0px'
              }}>{price.add(discount).toFormat()} </div> : null}
              <Box
                component="div"
                sx={{
                  fontSize: '14px',
                  fontWeight: '600'
                }}
                color={!discount.isZero() ? 'accent.main' : 'secondary'}
              >{price.toFormat()} </Box>
            </div>
          </Stack>
        </div>
      </QuantityStack>
    </Grid> : null }

    <Grid item xs={props.hidePrice ? 3 : 7} lg>
      <div style={{
        flexBasis: '150px',
        textAlign: 'right',
        flexShrink: 0
      }}>
        { props.hidePrice ? <>
          <Box
            component="div"
            sx={{
              fontSize: '18px',
              fontWeight: '600'
            }}
            color="secondary"
          >{ props.item.get('quantity') }</Box>
        </> : <>
          <div style={{
            position: 'relative',
            marginTop: (props.item.get('fees').count() * 10) + 'px'
          }}>
            {!totalDiscount.isZero() ? <div style={{
              fontSize: '12px',
              fontWeight: '500',
              textDecoration: 'line-through',
              color: '#999',
              lineHeight: 1,
              position: 'absolute',
              top: '-10px',
              right: '0px'
            }}>{totalPrice.add(totalDiscount).toFormat()} </div> : null}
            <Box
              component="div"
              sx={{
                fontSize: '18px',
                fontWeight: '600'
              }}
              color={!totalDiscount.isZero() ? 'accent.main' : 'secondary'}
            >{totalPrice.toFormat()} </Box>
          </div>

          {props.item.get('fees').valueSeq().map(renderFee)}
        </> }
      </div>
    </Grid>

    {!props.short ? <Grid
      item
      xs={12}
      style={{
        textAlign: 'right',
        paddingTop: 0
      }}
    >
      <Button
        variant="text"
        color="secondary"
        size="small"
        startIcon={<DeleteIcon/>}
        onClick={onDeleteOpen}
        disabled={cartLoader}
        style={{
          marginRight: '-15px'
        }}
      >{t('Remove')}</Button>
    </Grid> : null}

    { props.item.get('type') === 'product' && props.showReorder ? <Grid
      item
      xs={12}
      style={{
        textAlign: 'right',
        paddingTop: 0
      }}
    >
      <Button
        variant="text"
        color="secondary"
        size="small"
        startIcon={<AddShoppingCartRoundedIcon/>}
        onClick={onReorderOpen}
        disabled={cartLoader}
        style={{
          marginRight: '-15px'
        }}
      >{t('Reorder')}</Button>
    </Grid> : null }

    <DeleteItemDialog
      item={props.item}
      opened={deleteDialogOpened}
      onClose={onDeleteClose}
    />

    {props.item.get('type') === 'product' && !props.short ? <OrderDialog
      opened={editDialogOpened}
      item={props.item as ProductItem}
      onConfirm={onEditConfirm}
      productDesignId={String((props.item as ProductItem).get('id_product_design') + '|' + (props.item as ProductItem).get('product_id'))}
      onClose={onEditClose}
    /> : null}

    {props.item.get('type') === 'product' && props.showReorder ? <OrderDialog
      opened={reorderDialogOpened}
      productDesignId={String((props.item as ProductItem).get('id_product_design') + '|' + (props.item as ProductItem).get('product_id'))}
      selectedOptions={selectedOptions}
      onClose={onReorderClose}
    /> : null}
  </CartItemContainer>
}
