import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CanvasTexture } from 'three';
import BetterAnimFrame from '../Common/Utils/BetterAnimFrame';
import WebsiteStore from '../WebsiteStore';
import ModelViewerCanvas from './ModelViewerCanvas';
import ProductTextureContext from './_contexts/ProductTextureContext';
import useCustomProductTexture from './_hooks/useCustomProductTexture';

interface Props {
    renderTexture: () => HTMLCanvasElement | OffscreenCanvas | HTMLImageElement | undefined
    viewerId: string,
    productId: number,
    variant: string,
    labData: Record<string, any>,
    options:Immutable.Map<string,string>
}

export default function ModelViewer(props:Props) {
    const activeSubproduct = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeSubproduct'))
    const activeVariant = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeVariant'))
    const autoDesign = useSelector((state:WebsiteStore) => activeSubproduct ? state.get('UIData').get('designLab').get('autoDesignSubproducts').get(activeSubproduct) : undefined)
    const layers = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('layers'))
    const mirrorModes = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('mirrorModes'))
    const textureUpdate = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('textureUpdate'))

    const customProductTexture = useCustomProductTexture(props.productId)
    const productTextureCanvas = useRef<OffscreenCanvas>()
    const [productTexture,setProductTexture] = useState<CanvasTexture|null>(null)
    const fxTextureCanvas = useRef<OffscreenCanvas|null>(null)
    const fxTexture = useRef<CanvasTexture>()

    const updateTexture = useCallback(() => {
        if(!props.renderTexture || !productTextureCanvas.current || !props.labData || !props.labData.variants[props.variant]) {
            return;
        }
        
        let context = productTextureCanvas.current.getContext("2d")
        if(context === null) return;

        const textureResolution = 1;

        const texture = props.renderTexture();
        if(!texture) return;

        context.clearRect(0, 0, texture.width, texture.height);

        if(!props.labData.transparent_texture) {
			context.fillStyle = props.labData?.backgroundColor || "#ffffff";
			context.fillRect(0, 0, texture.width, texture.height);
		}

        context.globalCompositeOperation = "source-over"

        const optionObject = props.options.toJS()
        const scenes = Object.keys(props.labData.variants[props.variant].scenes)
        
        for(let scene of scenes) {
            customProductTexture.pre(productTextureCanvas.current, fxTextureCanvas.current, scene, textureResolution, optionObject)
        }

        context.drawImage(texture, 0, 0)

        for(let scene of scenes) {
            context.globalCompositeOperation = "destination-over"
            customProductTexture.post(productTextureCanvas.current, fxTextureCanvas.current, scene, textureResolution, optionObject)
            context.globalCompositeOperation = "source-over"
        }

        if(productTexture) productTexture.needsUpdate = true
        if(fxTexture.current) fxTexture.current.needsUpdate = true
    }, [productTexture, props.renderTexture, props.labData, props.options, props.variant])

    useEffect(() => {
        if(!props.labData) return;
        
        productTextureCanvas.current = new OffscreenCanvas(
            props.labData.variants[props.variant].dimensions.texture.width,
            props.labData.variants[props.variant].dimensions.texture.height
        )

        let context = productTextureCanvas.current.getContext('2d');
        if(context === null) return;
        
        if(props.labData.transparent_texture){
			context.clearRect(0, 0, productTextureCanvas.current.width, productTextureCanvas.current.height);
		} else {
			context.fillStyle = "#ffffff";
			context.fillRect(0, 0, productTextureCanvas.current.width, productTextureCanvas.current.height);
		}

        const newProductTexture = new CanvasTexture(productTextureCanvas.current)
        newProductTexture.colorSpace = "srgb"

        if(props.labData.useFxCanvas) {
            fxTextureCanvas.current = new OffscreenCanvas(
                props.labData.variants[props.variant].dimensions.texture.width,
                props.labData.variants[props.variant].dimensions.texture.height
            )

            fxTexture.current = new CanvasTexture(fxTextureCanvas.current)
            fxTexture.current.colorSpace = "srgb"
        }

        setProductTexture(newProductTexture)
    }, [props.labData?.slug, props.variant])

    useEffect(() => {
        BetterAnimFrame(60)(updateTexture)
    }, [textureUpdate, updateTexture, layers, mirrorModes, activeVariant, activeSubproduct, autoDesign])

    if(!props.labData || productTexture === null) {
        return null;
    }

    return <ProductTextureContext.Provider value={[productTexture, fxTexture.current]}>
        <ModelViewerCanvas
            viewerId={props.viewerId}
            productId={props.productId}
            labData={props.labData}
            options={props.options}
        />
    </ProductTextureContext.Provider>
}