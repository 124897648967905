import { useContext, useEffect } from "react"
import { Vector2 } from "three"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function BodyconDress(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/bodycon-dress.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    
    useEffect(() => {
        if(!elements) return

        // Scale the effect maps used for shiny fabric
        for(const element of elements) {
            if (!!element.material?.metalnessMap
                && !!element.material?.normalMap
                && !!element.material?.roughnessMap
            ) {
                element.material.metalnessMap.repeat = new Vector2(0, 0)
                element.material.normalMap.repeat = new Vector2(0, 0)
                element.material.roughnessMap.repeat = new Vector2(0, 0)
            }

            // Tune down aoMap
            if (!!element.material?.aoMap) {
                element.material.aoMapIntensity = 0.5
            }
        }

        // Enable see-through for inner material
        if(elements[0]?.material) {
            elements[0].material.side = 2
        }
        if(elements[1]?.material) {
            elements[1].material.transparent = true
            elements[1].material.opacity = 0.8
        }
    }, [elements])

    // Apply lab texture
    useEffect(() => {
        if(!elements || !elements[0]?.material) return
        
        elements[0].material.map = productTexture
        elements[0].material.map.flipY = false
        elements[0].material.map.needsUpdate = true
    }, [productTexture])
    
    return <ModelElements elements={elements}/>
}