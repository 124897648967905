import { Alert, Button, Stack } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import CheckoutSummary from '../Summary/CheckoutSummary'
import CheckoutOverviewContent from './CheckoutOverviewContent'
import { Link } from 'react-router-dom'
import CartListStack from '../../Cart/CartContent/CartListStack'
import CartListGrid from '../../Cart/CartContent/CartListGrid'
import CartGridContainer from '../../Cart/CartContent/CartGridContainer'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import CartSignupPrompt from '../../Cart/CartSignupPrompt'
import type Immutable from 'immutable'

interface Props {
  orders: Immutable.OrderedMap<string, UserCartStore>
  shippingUrl: string
  paymentUrl: string
}

export default function CheckoutOverview(props: Props) {
  const [t] = useTranslation('cart')

  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))

  const renderCart = useCallback((cart: UserCartStore) => {
    return <CheckoutOverviewContent
      key={cart.get('id')}
      cart={cart}
    />
  }, [])

  const validSync = useMemo(() => {
    let tmpValid = true

    // Check that all store items
    props.orders.forEach(order => {
      order.get('storeOrder')?.get('listItems').forEach(item => {
        if (item.get('id_cart_item') === null && !item.get('ignored')) {
          tmpValid = false
          return false
        }
      })

      // Stop looping if it's invalid
      if (!tmpValid) {
        return false
      }
    })

    return tmpValid
  }, [props.orders])

  const firstOrder = useMemo(() => {
    return props.orders.first()
  }, [props.orders])

  if (!firstOrder) {
    return null
  }

  return <CartGridContainer>
    <CartListGrid>
      <CartListStack>
        {firstOrder.get('status') === 'cart' ? <CartSignupPrompt /> : null}
        {props.orders.valueSeq().map(renderCart)}
      </CartListStack>
    </CartListGrid>

    <CheckoutSummary
      carts={props.orders}
      alert={!validSync
        ? <Alert
          variant="outlined"
          severity="info"
        >{t('Make sure all items are synced (or ignored) to continue.')}</Alert>
        : undefined}
      action={<Stack spacing={2} alignItems="center">
        {firstOrder.get('order_from') !== 'invoice'
          ? <Button
            color="accent"
            size="large"
            variant="contained"
            component={Link}
            to={props.shippingUrl}
            disabled={!!cartLoader || !validSync}
          >{t('Proceed to checkout')}</Button>
          : <Button
            color="accent"
            size="large"
            variant="contained"
            component={Link}
            to={props.paymentUrl}
            disabled={cartLoader}
          >{t('Proceed to payment')}</Button>
        }
      </Stack>}
    />
  </CartGridContainer>
}
