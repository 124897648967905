import * as Immutable from 'immutable';
import PriceStore from '../../UIData/_stores/PriceStore';

export interface IViewProductStore {
	id: number
    description: string
    categories: Immutable.OrderedSet<number>
    listAttributes: Immutable.OrderedMap<string, number>
    name: string
    price: PriceStore
    slug: string
    url_slug: string
    is_new: boolean
    is_react_page: boolean
}

const defaultViewProductStore = Immutable.Record<IViewProductStore>({
	id: 0,
    description: '',
    categories: Immutable.OrderedSet<number>(),
    listAttributes: Immutable.OrderedMap<string, number>(),
	name: '',
	price: new PriceStore(),
	slug: '',
    url_slug: '',
    is_new: false,
    is_react_page: false,
});

export default class ViewProductStore extends defaultViewProductStore implements IViewProductStore {}