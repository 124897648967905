import { Button, Card, CardContent, CardMedia, Checkbox, Grid, Typography, styled } from "@mui/material";
import Immutable from "immutable";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PreviewStore from "../../../AppData/_stores/PreviewStore";
import { PinkHeight45FullWidthButton } from '../../../Common/_components/Buttons';
import { UserProductDesignStore } from "../../../UserData/_stores/UserProductDesignStore";
import WebsiteStore from "../../../WebsiteStore";
import useExitLab from "../../_hooks/useExitLab";
import OrderDialog from "../../../Account/Products/OrderDialog";
import DialogContent from "../../../Common/Dialog/DialogContent";
import DialogActions from "../../../Common/Dialog/DialogActions";

const PreviewImg = styled('img')({
    maxWidth: '100%',
    borderRadius: '20px',
    display: 'block',
    border: '2px solid transparent',
    boxSizing: 'border-box',
    
    '&.active': {
        borderColor: '#999',
    }
})

const FooterButton = styled(Button)({
    color: '#000',
    padding: '10px 20px',
    borderRadius: 0,
})

const PreviewWrapper = styled(Grid)(({theme}) => ({
    marginBottom: '40px',
    aspectRatio: '1120 / 500',
    flexDirection: 'row',
    gap: '16px',

    ['@media (max-width: '+theme.breakpoints.values.dialog_mobile+'px)']: {
        aspectRatio: '1120 / 750',
        gap: '8px',
    }
}));

const PreviewContainer = styled(Grid)(({theme}) => ({
    maxWidth: 'calc(50% - 8px)',

    ['@media (max-width: '+theme.breakpoints.values.dialog_mobile+'px)']: {
        maxWidth: 'calc(75% - 4px)',
    }
}));

const ThumbnailContainer = styled(Grid)(({theme}) => ({
    overflow: 'auto',
    gap: '16px',
    height: 'calc(100% - 8px)',
    maxWidth: 'calc(50% - 8px)',
    paddingRight: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',

    ['@media (max-width: '+theme.breakpoints.values.dialog_mobile+'px)']: {
        maxWidth: 'calc(25% - 4px)',
        height: 'calc(100% - 4px)',
        gap: '8px',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },

    '&::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
    },

    '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
    },

    '&::-webkit-scrollbar-thumb:hover': {
        background: 'rgba(0, 0, 0, 0.3)',
    },
}));

const ThumbnailItem = styled('div')(({theme}) => ({
    maxWidth: 'calc(50% - 8px)',

    ['@media (max-width: '+theme.breakpoints.values.dialog_mobile+'px)']: {
        maxWidth: 'none',
        width: '100%',
    },
}));

const SimilarProductPreviewImg = styled('img')({
    maxWidth: '100%',
})

const StyledDialogContent = styled(DialogContent)(({theme}) => ({
    padding: '60px 80px',
    textAlign: 'center',

    ['@media (max-width: '+theme.breakpoints.values.dialog_mobile+'px)']: {
        padding: '20px 30px',
    }
}));

type Props = {
    productDesign: UserProductDesignStore
    similarProducts: Record<string,any>[]
    onClose?: () => void
}

export default function SaveActions(props:Props) {
    const [t] = useTranslation('design-lab');
    const listProducts = useSelector((state:WebsiteStore) => state.get('appData').get('products'));
    const activeDesignId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeDesignId'));

    const [activePreview, setActivePreview] = useState<PreviewStore|undefined>(undefined);
    const [similarProductsEnabled, setSimilarProductsEnabled] = useState<Immutable.Map<string, number>>(Immutable.Map<string, number>())
    const [hideSimilarProducts, setHideSimilarProducts] = useState(true);
    const [orderDialogOpened, setOrderDialogOpened] = useState(false);
    const exitLab = useExitLab();

    useEffect(() => {
        setActivePreview(props.productDesign.get('previews').first());
    }, [props.productDesign])

    return <>
        <StyledDialogContent>
            <Typography
                variant="h2" 
                style={{
                    marginBottom: '10px',
                }}
            >{ t('It\'s done and it\'s amazing!') }</Typography>
            <Typography
                variant="body1"
                style={{
                    marginBottom: '30px',
                }}
            >{t('Your design ') }<strong style={{color: '#f600bc'}}>{ props.productDesign.get('name') }</strong>{ t(' has been saved in your account.')}</Typography>

            <PreviewWrapper container>
                <PreviewContainer item>
                    { activePreview ? <PreviewImg src={activePreview.get('url')}/> : null }
                </PreviewContainer>
                <ThumbnailContainer item>
                    { props.productDesign.get('previews').valueSeq().map(preview => {
                        return <ThumbnailItem 
                            key={preview.get('id')}
                        >
                            <PreviewImg
                                src={ preview.get('url') }
                                onClick={() => {
                                    setActivePreview(preview);
                                }}
                                className={activePreview && activePreview.get('id') === preview.get('id') ? 'active':''}
                            />
                        </ThumbnailItem>
                    }) }
                </ThumbnailContainer>
            </PreviewWrapper>
                    
            { !hideSimilarProducts && props.similarProducts.length > 0 ? <div>
                <Typography
                    variant="h3" 
                    style={{
                        marginBottom: '10px',
                        fontFamily: 'Bebas Neue',
                        fontSize: '30px',
                        fontWeight: '400',
                    }}
                >{ t('Do you want to create those similar products as well?') }</Typography>

                
                <Grid container columnSpacing={2} style={{
                    marginBottom: '20px',
                }}>
                    { props.similarProducts.map((similarProduct,index) => {
                        return <Grid 
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            key={similarProduct.designData.designId}
                            style={{
                                paddingBottom: '16px'
                            }}
                        >
                            <Card
                                elevation={2}
                                style={{
                                    position: 'relative',
                                    borderRadius: '20px',
                                }}
                            >
                                <Checkbox
                                    value={true}
                                    checked={similarProductsEnabled.has(similarProduct.designData.designId)}
                                    onChange={(e) => {
                                        if(e.target.checked) {
                                            if(!similarProductsEnabled.has(similarProduct.designData.designId)) {
                                                setSimilarProductsEnabled(value => value.set(similarProduct.designData.designId, index))
                                            }
                                        } else {
                                            setSimilarProductsEnabled(value => value.remove(similarProduct.designData.designId));
                                        }
                                        
                                    }}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                                <CardMedia
                                    component="img"
                                    image={similarProduct.previews[0]}
                                />
                                <CardContent style={{
                                    padding: '10px',
                                }}>
                                    <Typography
                                        variant="h5"
                                    >{ listProducts.get(similarProduct.designData.productId).get('name') }</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    }) }
                </Grid>

                <PinkHeight45FullWidthButton
                    style={{
                        background: 'linear-gradient(0deg, #00ACED 0%, #1CC1FF 100%)',
                        textTransform: 'uppercase',
                        fontFamily: "Bebas Neue",
                        fontSize: '26px',
                        width: '200px',
                        padding: '0 40px',
                        height: '60px',
                        borderRadius: '60px',
                        cursor: 'pointer',
                        marginRight: '15px',
                    }}
                    onClick={() => {
                        
                    }}
                >{ t('Edit') }</PinkHeight45FullWidthButton>
                <PinkHeight45FullWidthButton
                    style={{
                        textTransform: 'uppercase',
                        fontFamily: "Bebas Neue",
                        fontSize: '26px',
                        width: '200px',
                        padding: '0 40px',
                        height: '60px',
                        borderRadius: '60px',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        
                    }}
                >{ t('Create') }</PinkHeight45FullWidthButton>
                <br />
                <Button
                    style={{
                        marginTop: '15px',
                        borderRadius: "30px",
                        color: '#000',
                    }}
                    onClick={() => {
                        setHideSimilarProducts(true);
                    }}
                >{ t('No') }</Button>
            </div> : <>
                { !activeDesignId ? <PinkHeight45FullWidthButton
                    style={{
                        textTransform: 'uppercase',
                        fontFamily: "Bebas Neue",
                        fontSize: '26px',
                        width: 'fit-content',
                        padding: '0 40px',
                        height: '60px',
                        borderRadius: '60px',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setOrderDialogOpened(true);
                    }}
                >{ t('Order now') }</PinkHeight45FullWidthButton> : null }
            </> }
        </StyledDialogContent>
        <DialogActions>
            <FooterButton
                onClick={props.onClose}
            >{ t('Keep designing') }</FooterButton>
            <FooterButton
                onClick={exitLab}
            >{ t('Exit Design Lab') }</FooterButton>
        </DialogActions>

        <OrderDialog
            productDesignId={props.productDesign.get('id')}
            opened={orderDialogOpened}
            onClose={() => setOrderDialogOpened(false)}
        />
    </>
}