import { styled } from "@mui/material";

export const MarginWrapper = styled('div')<{margin ?: string , mTop ?: string , mBottom ?: string, mRight ?: string, mLeft ?: string}>`
    margin: ${props => props.margin && props.margin};
    margin-top: ${props => props.mTop && props.mTop};
    margin-right: ${props => props.mRight && props.mRight};
    margin-left: ${props => props.mLeft && props.mLeft};
    margin-bottom: ${props => props.mBottom && props.mBottom};
`;

export const PaddingWrapper = styled('div')<{padding ?: string , pTop ?: string , pBottom ?: string, pRight ?: string, pLeft ?: string}>`
    padding: ${props => props.padding && props.padding};
    padding-top: ${props => props.pTop && props.pTop};
    padding-right: ${props => props.pRight && props.pRight};
    padding-left: ${props => props.pLeft && props.pLeft};
    padding-bottom: ${props => props.pBottom && props.pBottom};
`;

export const FlexWrapper = styled('div')<{display ?: string , justifyContent ?: string , alignItems ?: string , gap?: string, flexGrow ?: string , flexDirection ?: string, flexWrap ?: string }>`
    display : flex;
    justify-content : ${props => props.justifyContent && props.justifyContent}; 
    align-items : ${props => props.alignItems && props.alignItems};
    gap : ${props => props.gap && props.gap};
    flex-grow : ${props => props.flexGrow && props.flexGrow};
    flex-direction : ${props => props.flexDirection && props.flexDirection};
    flex-wrap: ${props => props.flexWrap && props.flexWrap};
`;