import { useCallback, useState } from 'react'
import * as React from 'react'
import { type UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore'
import { useTranslation } from 'react-i18next'
import { Button, Card, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, styled } from '@mui/material'
import { MoreVert as MoreVertIcon, LibraryAddRounded as LibraryAddRoundedIcon, PhotoFilterRounded as PhotoFilterRoundedIcon, DriveFileRenameOutlineRounded as DriveFileRenameOutlineRoundedIcon } from '@mui/icons-material'
import DeleteDesignDialog from './DeleteDesignDialog'
import RenameDesignDialog from './RenameDesignDialog'
import DeleteIcon from '../../Common/Icons/DeleteIcon'
import AddToCollectionDialog from '../Collections/AddToCollectionDialog'
import OrderDialog from './OrderDialog'
import ProductDesign from './ProductDesign'
import tKey from '../../Common/Utils/tKey'

const ProductCard = styled(Card)({
  borderRadius: '20px',
  position: 'relative',
  overflow: 'hidden',
  background: '#fff',
  height: '100%',
  transition: 'none',
  display: 'flex',
  flexDirection: 'column'

  /* '&:before': {
      border: '2px solid transparent',
      borderRadius: '20px',
      bottom: 0,
      content: '""',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      transition: 'border 250ms',
  },

  '&.selected:before': {
      borderColor: '#000',
  } */
})

const ProductThumbnail = styled('img')({
  width: '100%',
  height: 'auto',
  aspectRatio: '1120 / 1000',
  display: 'block'
})

export const ProductCardWrapper = styled(Grid)(({ theme }) => ({
  width: '20%',

  ['@media (max-width: ' + theme.breakpoints.values.xl + 'px)']: {
    width: '25%'
  },

  ['@media (max-width: ' + theme.breakpoints.values.lg + 'px)']: {
    width: '33.3%'
  },

  ['@media (max-width: ' + theme.breakpoints.values.md + 'px)']: {
    width: '50%'
  },

  ['@media (max-width: ' + theme.breakpoints.values.sm + 'px)']: {
    width: '50%'
  },

  '&.list-view': {
    width: '100%',
    paddingTop: 0,

    '&:last-child': {
      [`${ProductCard}`]: {
        borderBottom: '0'
      }
    },

    [`${ProductCard}`]: {
      borderRadius: '0px',
      boxShadow: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '10px 0',
      alignItems: 'center',
      background: 'transparent',
      flexDirection: 'row'
    },

    [`${ProductThumbnail}`]: {
      width: '80px',
      borderRadius: '15px'
    },

    [`${ProductTitle}`]: {
      padding: '5px 15px'
    },

    [`${ButtonsWrapper}`]: {
      padding: '0 0 0 15px'
    }
  }
}))

const ProductTitle = styled('div')({
  color: 'black',
  fontSize: '16px',
  fontWeight: 500,
  padding: '10px 15px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  flexGrow: 1
})

const ButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 0 10px 15px',
  gap: '10px'
})

tKey('Order_ctx_verb')

interface Props {
  view: 'grid' | 'list'
  item: UserProductDesignStore
}

export default function ProductDesignMainActions(props: Props) {
  const [t] = useTranslation('account-v2')
  const [tR] = useTranslation('routes')

  const [menuOpened, setMenuOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [orderDialogOpened, setOrderDialogOpened] = useState(false)
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)
  const [renameDialogOpened, setRenameDialogOpened] = useState(false)
  const [addToCollectionDialogOpened, setAddToCollectionDialogOpened] = useState(false)

  const onEdit = useCallback(() => {
    window.location.href = tR('create') + '?design=' + props.item.get('id')
  }, [])

  const onPhotoLabClick = useCallback(() => {
    setMenuOpened(false)

    // @ts-expect-error Legacy AOW UI dialog
    AOW.UI.dialog({
      url: 'account/social-app',
      data: {
        id: props.item.get('id_product_design'),
        product_id: props.item.get('id_product')
      },
      success: () => {
        AOW.SocialApp.init()
      }
    })
  }, [props.item])

  const onOrderClose = useCallback(() => {
    setOrderDialogOpened(false)
  }, [])

  const onOrderOpen = useCallback(() => {
    setMenuOpened(false)
    setOrderDialogOpened(true)
  }, [])

  const onRenameClose = useCallback(() => {
    setRenameDialogOpened(false)
  }, [])

  const onRenameOpen = useCallback(() => {
    setMenuOpened(false)
    setRenameDialogOpened(true)
  }, [])

  const onDeleteClose = useCallback(() => {
    setDeleteDialogOpened(false)
  }, [])

  const onDeleteOpen = useCallback(() => {
    setMenuOpened(false)
    setDeleteDialogOpened(true)
  }, [])

  const onAddToCollectionClose = useCallback(() => {
    setAddToCollectionDialogOpened(false)
  }, [])

  const onAddToCollectionOpen = useCallback(() => {
    setMenuOpened(false)
    setAddToCollectionDialogOpened(true)
  }, [])

  const onMenuOpen = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setMenuOpened(true)
  }, [])

  const onMenuClose = useCallback(() => {
    setMenuOpened(false)
  }, [])

  return <>
    <ProductDesign
      view={props.view}
      item={props.item}
      actions={<>
        <Button
          onClick={onOrderOpen}
          variant="contained"
          color="accent"
        >{t('Order', { context: 'verb' })}</Button>
        <IconButton
          onClick={onMenuOpen}
          size="large">
          <MoreVertIcon />
        </IconButton>
      </>}
    />
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={menuOpened}
      onClose={onMenuClose}
    >
      { /* props.item.isEditable() ? <MenuItem onClick={onEdit}>
      <ListItemIcon><DesignServicesIcon /></ListItemIcon>
      <ListItemText>{t('Edit')}</ListItemText>
      </MenuItem> : null */ }
      <MenuItem onClick={onRenameOpen}>
        <ListItemIcon><DriveFileRenameOutlineRoundedIcon /></ListItemIcon>
        <ListItemText>{t('Rename')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={onAddToCollectionOpen}>
        <ListItemIcon><LibraryAddRoundedIcon /></ListItemIcon>
        <ListItemText>{t('Add to collection')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={onPhotoLabClick}>
        <ListItemIcon><PhotoFilterRoundedIcon /></ListItemIcon>
        <ListItemText>{t('Photo Lab')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={onDeleteOpen}>
        <ListItemIcon><DeleteIcon /></ListItemIcon>
        <ListItemText>{t('Delete')}</ListItemText>
      </MenuItem>
    </Menu>

    <OrderDialog
      opened={orderDialogOpened}
      productDesignId={props.item.get('id')}
      onClose={onOrderClose}
    />

    <RenameDesignDialog
      productDesign={props.item}
      opened={renameDialogOpened}
      onClose={onRenameClose}
    />

    <DeleteDesignDialog
      productDesign={props.item}
      opened={deleteDialogOpened}
      onClose={onDeleteClose}
    />

    <AddToCollectionDialog
      productDesign={props.item}
      opened={addToCollectionDialogOpened}
      onClose={onAddToCollectionClose}
    />
  </>
}
