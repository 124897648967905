import * as Immutable from 'immutable';
import PriceStore, { PricePayload } from '../../UIData/_stores/PriceStore';
import { DateTime } from 'luxon';

export type RefundPayload = {
	id: number
  id_cart: number
  amount: PricePayload
  date_refunded: string
}

interface IRefundStore {
	id: number
  id_cart: number
  amount: PriceStore
  date_refunded: string
}

const defaultRefundStore = Immutable.Record<IRefundStore>({
	id: 0,
  id_cart: 0,
  amount: new PriceStore(),
  date_refunded: DateTime.now().toISO(),
});

export class RefundStore extends defaultRefundStore implements IRefundStore {}