import * as Immutable from 'immutable';
import ProductDetailValueStore from './ProductDetailValueStore';

export interface IProductDetailStore {
	id: number,
    slug: string,
    name: string,
    listValues: Immutable.OrderedMap<string, ProductDetailValueStore>,
}

const defaultProductDetailStore = Immutable.Record<IProductDetailStore>({
	id: 0,
    slug: '',
    name: '',
    listValues: Immutable.OrderedMap<string, ProductDetailValueStore>()
});

export default class ProductDetailStore extends defaultProductDetailStore implements IProductDetailStore {}