import { useTranslation } from 'react-i18next'
import CheckoutOverview from '../../Checkout/Overview/CheckoutOverview'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { useMemo } from 'react'
import Immutable from 'immutable'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'

export default function OrderPageOverview() {
  const [tR] = useTranslation('routes')
  const params = useParams<{ refId: string }>()

  const order = useAppSelector(state => params.refId ? state.get('userData').get('orders').get('data').find(value => value.get('ref_id') === params.refId) : undefined)

  const orders = useMemo(() => {
    return order ? Immutable.OrderedMap({ [order.get('id')]: order }) : Immutable.OrderedMap<string, UserCartStore>()
  }, [order])

  if (!order) return null

  return <CheckoutOverview
    orders={orders}
    shippingUrl={tR('/account/orders/{{ref_id}}/shipping', { ref_id: order.get('ref_id') })}
    paymentUrl={tR('/account/orders/{{ref_id}}/payment', { ref_id: order.get('ref_id') })}
  />
}
