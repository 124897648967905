import Immutable from "immutable";
import { EditProductLabData } from "../../AppData/_actions/AppDataActions";
import { createAjaxAction } from "../../Common/_actions/AjaxAction";
import { UserProductDesignStore } from "../../UserData/_stores/UserProductDesignStore";
import PreviewStore from "../../AppData/_stores/PreviewStore";
import { UserImagePayload } from "../../UserData/_stores/UserImageStore";

export enum DesignLabActionType {
	GetProductLabData = 'DESIGN_LAB_GET_PRODUCT_LAB_DATA',
	BuildProductPreview = 'DESIGN_LAB_BUILD_PRODUCT_PREVIEW',
	SaveDesign = 'DESIGN_LAB_SAVE_DESIGN',
	UpscaleImage = 'DESIGN_LAB_UPSCALE_IMAGE',
	ConfirmUpscaledImage = 'DESIGN_LAB_CONFIRM_UPSCALED_IMAGE',
}

export type UpscaledImagePayload = {
	chunkFilenames: {
		old: string,
		new: string,
	}[]
}

export const GetProductLabData = (slug: string) =>  createAjaxAction<{ labData: Record<string,any> }>(DesignLabActionType.GetProductLabData, {
	url: "ajax/create/product-data",
	method: "GET",
	data: {
        product: slug
    },
	onSuccess: (response, dispatch) => {
        dispatch(EditProductLabData(response.data.labData.id, response.data.labData));
	},
	onFailure: (response, dispatch) => {
		// TODO: Handle request failure, probably with a toast.
	}
});

export const BuildProductPreview = (designData: string, onSuccess?:() => void, onFailure?:() => void) =>  {
	const formData = new FormData();
	formData.append('designData', designData);

	return createAjaxAction<{}>(DesignLabActionType.BuildProductPreview, {
		url: "ajax/create/build-preview-v4",
		method: "POST",
		data: formData,
		onSuccess: (response, dispatch) => {
			onSuccess && onSuccess();
		},
		onFailure: (response, dispatch) => {
			onFailure && onFailure();	
		}
	});
}

export const SaveDesign = (designData: string, onSuccess?:(productDesign: UserProductDesignStore, similarProducts?: { designData:Record<string, any>, previews: string[] }[]) => void, onFailure?:() => void) =>  {
	const formData = new FormData();
	formData.append('designData', designData);

	return createAjaxAction<{
		productDesign: {
			id: string,
			id_product_design: number,
			id_product: number,
			name: string,
			previews: {
				id: number,
				url: string,
			}[] 
		},
		similarProducts: {
			previews: string[],
			designData: Record<string,any>
		}[],
	}>(DesignLabActionType.SaveDesign, {
		url: "ajax/create/save-design-v4",
		method: "POST",
		data: formData,
		onSuccess: (response, dispatch) => {
			let listPreviews = Immutable.OrderedMap<number, PreviewStore>();

			response.data.productDesign.previews.forEach((previewData:any) => {
				let preview = new PreviewStore({
					id: previewData.id,
					url: previewData.url,
				});

				listPreviews = listPreviews.set(previewData.id, preview);
			});

			onSuccess && onSuccess(new UserProductDesignStore({
				id: response.data.productDesign.id,
				id_product_design: response.data.productDesign.id_product_design,
				id_product: response.data.productDesign.id_product,
				name: response.data.productDesign.name,
				previews: listPreviews
			}), response.data.similarProducts);
		},
		onFailure: (response, dispatch) => {
			onFailure && onFailure();	
		}
	});
}

export const UpscaleImage = (fileid:string, attemptNb:number, onSuccess?:(data:UpscaledImagePayload) => void, onFailure?:() => void) =>  {
	const formData = new FormData();
	formData.append('fileid', fileid);
	formData.append('attemptNb', String(attemptNb));

	return createAjaxAction<UpscaledImagePayload>(DesignLabActionType.UpscaleImage, {
		url: "ajax/create/imagery/upscale",
		method: "POST",
		data: formData,
		onSuccess: (response, dispatch) => {
			onSuccess && onSuccess(response.data);
		},
		onFailure: (response, dispatch) => {
			onFailure && onFailure();	
		}
	});
}

export const ConfirmUpscaledImage = (fileid:string, onSuccess?:(image:UserImagePayload) => void, onFailure?:() => void) => {
	const formData = new FormData();
	formData.append('fileid', fileid);

	return createAjaxAction<{image: UserImagePayload}>(DesignLabActionType.ConfirmUpscaledImage, {
		url: "ajax/create/imagery/confirm-upscale",
		method: "POST",
		data: formData,
		onSuccess: (response, dispatch) => {
			onSuccess && onSuccess(response.data.image);
		},
		onFailure: (response, dispatch) => {
			onFailure && onFailure();	
		}
	});
}