import { IconButton, Snackbar, styled } from '@mui/material';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import { useCallback } from 'react';
import { ConsumeToast } from '../_actions/UIDataActions';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

const CloseBtnText= styled('p')({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 'bold',
	fontSize: '0.95rem',
	lineHeight: '15px',
	letterSpacing: '0.11em',
	textTransform: 'uppercase',
	color: '#FFFFFF',
	margin: '0',
});

export default function Toast() {
	const dispatch = useAppDispatch();
	const message = useAppSelector(state => state.get('UIData').get('toast').get('message'));

	const onClose = useCallback(() => {
		dispatch(ConsumeToast())
	}, [])

	return <Snackbar
		className="snackbar-style"
		open={!!message}
		key={Date.now()}
		anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
		message={message}
		autoHideDuration={3000}
		onClose={onClose}
		action={
			<IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
				<CloseBtnText>Ok</CloseBtnText>
			</IconButton>
		}
	/>
}