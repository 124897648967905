import { data } from "jquery";
import { createAjaxAction } from "../../Common/_actions/AjaxAction";
import { EditOrder, EditManyOrders, RemoveOrder } from "./OrderStoreActions";
import { UserCartPayload } from "../_stores/UserCartStore";
import { EditItemData } from "./UserDataActions";
import { PaginationItemPayload } from "../_stores/PaginationStore";

export enum OrderActionType {
  FetchOrders = 'ORDER_FETCH_ORDERS',
	CancelOrder = 'ORDER_CANCEL_ORDER',
	PutOnHoldOrder = 'ORDER_PUT_ON_HOLD_ORDER',
	ReleaseHoldOrder = 'ORDER_RELEASE_HOLD_ORDER',
	IgnoreOrder = 'ORDER_IGNORE_ORDER',
	UnignoreOrder = 'ORDER_UNIGNORE_ORDER',
	IgnoreStoreItem = 'ORDER_IGNORE_STORE_ITEM',
	UnignoreStoreItem = 'ORDER_UNIGNORE_STORE_ITEM',
	FetchOneInvoice = 'ORDER_UNIGNORE_STORE_ITEM_FETCH_ONE_INVOICE',
}

export const FetchOrders = () => createAjaxAction<{orders: Record<string, UserCartPayload>}>(OrderActionType.FetchOrders, {
	url: 'ajax/api/user/orders',
	method: 'GET',
	preventDuplicates: true,
	onSuccess: (response, dispatch) => {
		dispatch(EditManyOrders(response.data.orders));
	},
});

export const CancelOrder = (id:number) => createAjaxAction<{order: UserCartPayload}>(OrderActionType.CancelOrder, {
	url: 'ajax/api/user/orders/'+id+'/cancel',
	method: 'POST',
	onSuccess: (response, dispatch) => {
		dispatch(EditOrder(response.data.order));
	},
});

export const PutOnHoldOrder = (id:number) => createAjaxAction<{order: UserCartPayload}>(OrderActionType.PutOnHoldOrder, {
	url: 'ajax/api/user/orders/'+id+'/put-on-hold',
	method: 'POST',
	onSuccess: (response, dispatch) => {
		dispatch(EditOrder(response.data.order));
	},
});

export const ReleaseHoldOrder = (id:number) => createAjaxAction<{order: UserCartPayload}>(OrderActionType.ReleaseHoldOrder, {
	url: 'ajax/api/user/orders/'+id+'/release-hold',
	method: 'POST',
	onSuccess: (response, dispatch) => {
		dispatch(EditOrder(response.data.order));
	},
});

export const IgnoreOrder = (id:number) => createAjaxAction<{order: UserCartPayload}>(OrderActionType.IgnoreOrder, {
	url: 'ajax/api/user/orders/'+id+'/ignore',
	method: 'POST',
	onSuccess: (response, dispatch) => {
		dispatch(EditOrder(response.data.order));
	},
});

export const UnignoreOrder = (id:number) => createAjaxAction<{order: UserCartPayload}>(OrderActionType.UnignoreOrder, {
	url: 'ajax/api/user/orders/'+id+'/unignore',
	method: 'POST',
	onSuccess: (response, dispatch) => {
		dispatch(EditOrder(response.data.order));
	},
});

export const IgnoreStoreItem = (
	idOrder:number, 
	idStoreItem:number,
	ignoreVariant?: boolean,
	ignoreProduct?: boolean,
) => {
	const data = new FormData
	data.append('id_store_item', String(idStoreItem))

	if(ignoreVariant) {
		data.append('ignore_variant', '1')
	}

	if(ignoreProduct) {
		data.append('ignore_product', '1')
	}

	return createAjaxAction<{order: UserCartPayload}>(OrderActionType.IgnoreStoreItem, {
		url: 'ajax/api/user/orders/'+idOrder+'/ignore-store-item',
		method: 'POST',
		data: data,
		onSuccess: (response, dispatch) => {
			dispatch(EditOrder(response.data.order));
		},
	})
}

export const UnignoreStoreItem = (idOrder:number, idStoreItem:number) => {
	const data = new FormData
	data.append('id_store_item', String(idStoreItem))

	return createAjaxAction<{order: UserCartPayload}>(OrderActionType.UnignoreStoreItem, {
		url: 'ajax/api/user/orders/'+idOrder+'/unignore-store-item',
		method: 'POST',
		data: data,
		onSuccess: (response, dispatch) => {
			dispatch(EditOrder(response.data.order));
		},
	})
}

export const FetchOneInvoice = (invoiceId:string) => {
	return createAjaxAction<PaginationItemPayload>(OrderActionType.FetchOneInvoice, {
		url: "ajax/api/user/orders/fetch-invoice/"+invoiceId,
		method: "GET",
		limit: 150,
		abortPrevious: true,
		onSuccess: (response, dispatch) => {
			dispatch(EditItemData(response.data));
		},
	});
}