import Immutable from "immutable";
import { IPaginationStore } from "./PaginationStore";
import PaginationItemListStore from "./PaginationItemListStore";
import UserCartStore from "./UserCartStore";

export interface IOrderListStore extends IPaginationStore {
	data: Immutable.Map<string, UserCartStore>,
}

const defaultOrderListStore = Immutable.Record<IOrderListStore>({
	itemLists: Immutable.Map<string, PaginationItemListStore>(),
	data: Immutable.Map<string, UserCartStore>(),
});

export default class OrderListStore extends defaultOrderListStore implements IOrderListStore {}