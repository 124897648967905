import * as Immutable from 'immutable';

export interface IModelViewerStore {
    fixedView: 'front'|'back'|'left'|'right'|'custom',
    fixedViewTime: number,
}

const defaultModelViewerStore = Immutable.Record<IModelViewerStore>({
    fixedView: "custom",
    fixedViewTime: 0,
})

export default class DesignLabStore extends defaultModelViewerStore implements IModelViewerStore {}