import Immutable from "immutable";
import Dinero from 'dinero.js';

export type PricePayload = {
    amount: number,
    currency: Dinero.Currency,
}

interface IPriceStore {
	amount: number
	currency: Dinero.Currency
}

const defaultPriceStore = Immutable.Record<IPriceStore>({
	amount: 0,
	currency: 'USD'
});

export default class PriceStore extends defaultPriceStore implements IPriceStore {
	constructor(params?:IPriceStore) {
		if(params) {
			super({
				amount: Number(params.amount),
				currency: String(params.currency) as Dinero.Currency
			});
		} else super();
	}

	public static fromDinero(price:Dinero.Dinero) {
		return new PriceStore({
			amount: price.getAmount(),
			currency: price.getCurrency(),
		});
	}

	public toDinero():Dinero.Dinero {
		return Dinero({
			amount: this.get('amount'),
			currency: this.get('currency'),
		});
	}
}

export const FormatPrice = (price: PriceStore, quantity: number = 1) => price.toDinero().multiply(quantity).toFormat();