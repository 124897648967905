import { useCallback } from 'react';
import Point from '../../Common/Utils/Math/Point';
import { useDispatch, useSelector } from 'react-redux';
import { EditZoom } from '../../UIData/_actions/DesignLabStoreActions';
import WebsiteStore from '../../WebsiteStore';
import useStageDimensions from './useStageDimensions';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

export default function useZoom() {
    const dispatch = useAppDispatch();
    const { stageWidth, stageHeight } = useStageDimensions();

    const zoomScale = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('zoomScale'));
    const zoomOffsetX = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('zoomOffsetX'));
    const zoomOffsetY = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('zoomOffsetY'));

    return useCallback((zoom: number, position: Point) =>
        dispatch(EditZoom(zoom, position, {x: stageWidth, y: stageHeight})), 
        [zoomScale, zoomOffsetX, zoomOffsetY, stageWidth, stageHeight]
    );
}