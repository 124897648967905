import MassProductSendingButtonContainer from './MassProductSending/_containers/MassProductSendingButtonContainer';
import MassPresetsContainer from './MassProductSending/_containers/MassPresetsContainer';
import MobileNav from "./Header/MobileNav";

import { ComponentType } from 'react';
import UserNav from './Header/UserNav';
import Header from './Header/Header';

type RenderEntry = {
	element: ComponentType,
	container: Element,
}

export default [
	{
		element: Header,
		container: document.getElementById('react-header')
	},
	{
		element: MassProductSendingButtonContainer,
		container: document.getElementById("react-mass-product-sending")
	},
	{
		element: MassPresetsContainer,
		container: document.getElementById("mass-presets-section")
	},
] as RenderEntry[];