import { Button } from '@mui/material'
import { FileDownloadOutlined as FileDownloadOutlinedIcon } from '@mui/icons-material'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import CartContent from '../CartContent/CartContent'
import CartListItems from '../CartItem/CartListItems'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface Props {
  cart: UserCartStore
}

export default function CartConfirmationContent(props: Props) {
  const [t] = useTranslation('cart')
  const [tR] = useTranslation('routes')

  return <CartContent
    cart={props.cart}
    headerActions={<Button
      variant="dashed"
      color="secondary"
      startIcon={<FileDownloadOutlinedIcon/>}
      component={Link}
      to={tR('/invoice/download-receipt') + '?ref_id=' + props.cart.get('ref_id')}
      target="_blank"
    >{t('Invoice (PDF)')}</Button>}
  >
    <CartListItems cart={props.cart} short={true} />
  </CartContent>
}
