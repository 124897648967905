import { Button, Grid } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddRounded as AddRoundedIcon } from '@mui/icons-material'
import AccountHeader from '../AccountHeader'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { FetchBrandingOptions } from '../../UserData/_actions/BrandingOptionActions'
import { type BrandingOptionStore } from '../../UserData/_stores/BrandingOptionStore'
import BrandingOption from './BrandingOption'
import AddBrandingOptionDialog from './AddBrandingOptionDialog'

export default function BrandingOptionList() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')

  const brandingOptions = useAppSelector(state => state.get('userData').get('brandingOptions'))

  const [addDialogOpened, setAddDialogOpened] = useState(false)

  useEffect(() => {
    dispatch(FetchBrandingOptions())
  }, [])

  const onAddOpen = useCallback(() => {
    setAddDialogOpened(true)
  }, [])

  const onAddClose = useCallback(() => {
    setAddDialogOpened(false)
  }, [])

  const renderBrandingOption = useCallback((brandingOption: BrandingOptionStore) => {
    return <BrandingOption
      key={brandingOption.get('id')}
      brandingOption={brandingOption}
    />
  }, [])

  return <>
    <AccountHeader
      title={t('Branding options')}
      actions={<Button
        variant="contained"
        color="accent"
        startIcon={<AddRoundedIcon />}
        onClick={onAddOpen}
      >{t('Create Branding option')}</Button>}
    />

    <Grid container spacing={2}>
      { brandingOptions.valueSeq().map(renderBrandingOption)}
    </Grid>

    <AddBrandingOptionDialog
      opened={addDialogOpened}
      onClose={onAddClose}
    />
  </>
}
