import { Button, Paper, Stack, Typography } from '@mui/material'
import { CartStack } from './CartContent/CartStack'
import { useAppSelector } from '../Common/_hooks/useAppSelector'
import { useCallback } from 'react'
import { OpenAuthModal } from '../Auth/_actions/AuthActions'
import { useAppDispatch } from '../Common/_hooks/useAppDispatch'
import { useTranslation } from 'react-i18next'

export default function CartSignupPrompt() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const loggedIn = useAppSelector(state => state.get('userData').get('loggedIn'))

  const onOpenLogin = useCallback(() => {
    dispatch(OpenAuthModal(false))
  }, [])

  const onOpenSignup = useCallback(() => {
    dispatch(OpenAuthModal(true))
  }, [])

  // Hidden if we're logged in
  if (loggedIn) return null

  return <Paper
    elevation={0}
    style={{
      backgroundImage: 'url(' + require('@resources/img/theme/dialog/ornaments-shapes-2.svg?url') + ')',
      backgroundPosition: 'right top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '50%'
    }}
  >
    <CartStack spacing={2}>
      <Typography
        variant="h2"
        style={{
          fontSize: '2.5rem'
        }}
      >{t('Checkout faster with an account')}</Typography>
      <Typography variant="body1">{t('Benefit from our Goals & Rewards program, brand your products and packaging for reselling, integrate your own Etsy, Shopify or Squarespace store.')}</Typography>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={onOpenLogin}
        >{t('Login')}</Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onOpenSignup}
        >{t('Sign up')}</Button>
      </Stack>
    </CartStack>
  </Paper>
}
