import * as Immutable from 'immutable';

export interface IViewerVersionStore {
	id: number
    version: number
    description: string
}

const defaultViewerVersionStore = Immutable.Record<IViewerVersionStore>({
	id: 0,
	version: 0,
    description: "",
});

export default class ViewerVersionStore extends defaultViewerVersionStore implements IViewerVersionStore {}