import { Alert, Box, Button, Collapse, Container, Paper, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import tKey from "../Common/Utils/tKey";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../Common/_hooks/useAppDispatch";
import { ResendValidationLink } from "./_actions/AuthActions";
import AuthForm from "./AuthForm";
import { useAppSelector } from "../Common/_hooks/useAppSelector";

export default function SignedUpPage() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('auth')
  const [tR] = useTranslation('routes')
  const [searchParams,setSearchParams] = useSearchParams()

  const name = useAppSelector(state => state.get('userData').get('name'))


  // Trigger Ad tracking
  useEffect(() => {
    if(!!searchParams.get('logged')) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    window.fbq && window.fbq('track', 'CompleteRegistration', {
      status: true
    });
  
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    window.gtag && window.gtag('event', 'sign_up', {
      
    });
  
    // @ts-expect-error No types definitions for pinterest ad tracking
    window.pintrk && window.pintrk('track', 'signup');

    // Add 'logged' to url so we don't log this twice in case of a refresh
    searchParams.set('logged', '1')
    setSearchParams(searchParams, {
      replace: true
    })
  }, [searchParams])

  return <Box component="div" sx={{
    backgroundImage: 'url(' + require('@resources/img/theme/page/ornaments-1.svg?url') + ')',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    pt: 6,
    pb: 15,
    flex: '1 0 auto',
  }}>
    <Container
      maxWidth="md"
    >
      <Helmet>
        <title>{t('Sign up')}</title>
      </Helmet>

      <Stack spacing={3}>
        <Typography variant="h1" textAlign="center">{t('Sign up')}</Typography>
        <Paper
          elevation={0}
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Stack
            spacing={2}
            sx={{
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              px: {
                xs: 6,
                sm: 8,
                md: 10,
              },
              pt: 6,
              pb: 13,
            }}
          >
            <img 
              src={require('@resources/img/signup/confettis.svg?url')}
              width={63}
              height={68}
            />
            <Typography
              variant="h3"
              style={{
                fontSize: '2rem',
                textAlign: 'center',
              }}
            >{t('{{name}}, thanks for signing up!', {name: name})}</Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: 'center',
              }}
            >{t('You will receive a validation email shortly. Make sure to click the button in the email to confirm your account.')}</Typography>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  </Box>
}