import { Button, FormControl, Grid, styled } from '@mui/material';
import * as Immutable from 'immutable';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { TableBoldHeaderTextEnd, TableSimpleHeader, TableSimpleHeaderTextCenter } from '../../Common/_components/TableElements';
import { InlineParagraph500, InlineParagraph600, ModalSubtitle, ModalTitle } from '../../Common/_components/Typography';
import Dinero from 'dinero.js';
import ProductOptionStore from '../../AppData/_stores/ProductOptionStore';
import ProductStore from '../../AppData/_stores/ProductStore';
import { PinkHeight45FullWidthButton } from '../../Common/_components/Buttons';
import VariantPriceStore from '../../Dashboard/_stores/VariantPriceStore';
import { UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore';
import VariantPriceHelper from '../VariantPriceHelper';
import ListingStore from '../_stores/ListingStore';
import PriceRow from './PriceRow';
import PriceStore from '../../UIData/_stores/PriceStore';

export type Props = {
	price: PriceStore,
	variant_prices: Immutable.Map<string, VariantPriceStore>,

	index ?: number
	handleStep ?: (step:number) => void
	disableButtons ?: () => void
	enableButtons ?: () => void
	labExit ?: boolean
	listing: ListingStore
	product?: ProductStore
	productDesign?: UserProductDesignStore
	onFormChange: Function
	massPresets?: boolean
}
type State = {
	ProfitType: string
	fixAmount: number
	fixAmount_changed: boolean
	percentageAmount: number
	percentageAmount_changed: boolean
	enterSpecialPrice: boolean
	withoutFringe: boolean
}

const ApplyNetProfitTitle = styled('p')`
	margin: 0;
	font-size: 10px;
	letter-spacing: 0.09;
	font-weight: 700;
	text-transform: uppercase;
`;
const MarginButtom30 = styled('div')`
	margin-bottom: 30px;
	@media (max-width: 600px){
		margin-bottom: 10px;
	}
`;
const ProfitOptionContainer = styled('div')`
	display: flex;
	gap: 60px;
	@media( max-width: 600px){
		flex-direction: column;
		gap: 0;
	}
`;
const AmountInput = styled(FormControl)`
	input{
		display: inline;
		width: 82px;
		margin: 0;
		border: 1px solid #AFAFAF;
		border-radius: 2px;
		padding-left: 5px;
	}	
`;
const SpanMarginRight10 = styled('span')`
	margin-right: 10px;
`;
const PricesTableContainer = styled('div')`
	margin-top: 30px;
	margin-bottom: 60px;
`;
const SpecialPriceDesc = styled('p')`
	font-weight: normal;
	font-size: 10px;
	text-align: center;
	color: #4D4D4D;
	margin: 10px 0 0 0;
`;
const MarginTopPublishButton = styled('div')`
	margin-top: 60px;
`;
const FlexGap1rem = styled('div')`
	display: flex;
`;
const SampleSpecialParentDiv = styled('div')`
	border: 1px solid #AFAFAF;
	box-sizing: border-box;
	padding : 20px;
`;
const SpecialOptionLabel = styled('p')`
	font-weight: 700;
	font-size: 10px;
	letter-spacing: 0.09em;
	text-transform: uppercase;
	color: #333333;
	margin: 0;
`;
const SpecialOptionData = styled('p')`
	margin: 0;
	font-weight: normal;
	font-size: 12px;
	line-height: 21px;
	letter-spacing: 0.01em;
	color: #000000;
`;
const MarginBottom10 = styled('div')`
	margin-bottom: 10px;
`;
const MarginBottom20 = styled('div')`
	margin-bottom: 20px;
`;
const ActionButton = styled(Button)`
	color: #DA3192;
	padding: 0 0.5rem !important;
	&:focus{
		background-color: unset;
	}
`;
const ActionIcon = styled('i')`
	font-size: 21px;
`;

const T = ({children, ...otherProps}:any) => <Trans ns="dashboard" {...otherProps}>{children}</Trans>

export default class EditProductPrices extends React.Component<Props> {

	public state = {
		ProfitType: "fix",
		fixAmount: null,
		percentageAmount: null,
		fixAmount_changed: false,
		percentageAmount_changed: false,
		enterSpecialPrice: false,
		withoutFringe: false,
	} as State

	private updateProfitType= (newType: string) => {
		this.setState({ProfitType: newType})
	}
	private onAmountChange = (key:keyof State, value: any) => {
		let newState:{[key:string]: any} = {};
		
		newState[key] = value;
		newState[key+'_changed'] = true;
		
		this.setState(newState);
	}
	handleDisplaySpecialPriceSection = () => {
		this.setState({enterSpecialPrice: true})
		this.props.disableButtons()
	}
	handleCloseSpecialPriceSection = () => {
		this.setState({enterSpecialPrice : false})
		this.props.enableButtons()
	} 

	render = () =>{
		if(!this.props.product) {
			return null;
		}

		let labExit = (this.props.labExit ? true : false);

		let availableOptionTypes = this.props.massPresets ? 
			this.props.product.get('availableOptionTypes') 
			: this.props.productDesign.get('availableOptionTypes');

		let optionTypesWithNoEmptyPrice = [];
		let optionTypesWithOnlyOneEmptyPrice:number[] = [];
		let variantsWithNoPrices:number[] = [];

		availableOptionTypes.forEach((type) => {
			//Skip branding options
			if(['include-label'].includes(type.get('slug'))) {
				return;
			}

			let hasEmptyPrice = false;
			let hasPrices = new Set();

			let optionsByPVariants = new Map<number, Map<string, ProductOptionStore>>();

			type.get('options').forEach((option) => {
				if(option.get('price_dropship').get('amount') > 0) {
					hasPrices.add(option.get('slug'));
				} else {
					hasEmptyPrice = true;
				}

				if(!optionsByPVariants.has(option.get('id_product_variant'))) {
					optionsByPVariants.set(option.get('id_product_variant'), new Map<string, ProductOptionStore>());
				}

				optionsByPVariants.get(option.get('id_product_variant')).set(option.get('slug'), option);
			});

			//Compare options by variants
			optionsByPVariants.forEach((listOptions, pVariantId) => {
				let hasSamePrice = true;
				listOptions.forEach((option) => {
					optionsByPVariants.forEach((listOptions2) => {
						if(listOptions2.has(option.get('slug')) 
							&& listOptions2.get(option.get('slug')).get('price_dropship').get('amount') != option.get('price_dropship').get('amount')) {
							hasSamePrice = false;
						}
					});
				});

				if(hasSamePrice) {
					variantsWithNoPrices.push(pVariantId);
				}
			});

			if(hasPrices.size === type.get('options').count()) {
				optionTypesWithNoEmptyPrice.push(type.get('id'));
			}

			if(type.get('options').count() > 1 && hasPrices.size === type.get('options').map((option) => option.get('slug')).toSet().size - 1) {
				optionTypesWithOnlyOneEmptyPrice.push(type.get('id'));
			}
		});

		//let defaultVariantPrices = new Map<string, VariantPriceStore>();
		//this.buildVariantPrices([], availableOptionTypes, defaultVariantPrices, optionTypesWithOnlyOneEmptyPrice, variantsWithNoPrices);

		let helper = new VariantPriceHelper(this.props.product, availableOptionTypes);
		let defaultVariantPrices = helper.buildVariantPrices();

		let customVariantPrices = this.props.variant_prices;
		defaultVariantPrices.forEach((variantPrice, variantId) => {
			if(!customVariantPrices.has(variantId)) {
				return;
			}

			let newVariantPrice = variantPrice.set('price', customVariantPrices.get(variantId).get('price'));

			defaultVariantPrices.set(variantId, newVariantPrice);
			customVariantPrices = customVariantPrices.remove(variantId);
		});

		//Group variants with the same slug together
		let variantPriceGroups = new Map<string,{ name: string, listVariantPrices: Array<VariantPriceStore> }>();
		defaultVariantPrices.forEach((variantPrice, variantId) => {
			if(variantPriceGroups.has(variantPrice.get('slug'))) {
				//Add to existing array of this variantPrice slug
				variantPriceGroups.get(variantPrice.get('slug')).listVariantPrices.push(variantPrice);
				return;
			}

			//Init array for this option slug
			variantPriceGroups.set(variantPrice.get('slug'), {
				name: variantPrice.get('name'),
				listVariantPrices: [variantPrice],
			});
		});

		return <>
			<Grid>
				<MarginButtom30>
					<ModalTitle><T>Edit Pricing</T></ModalTitle>
					<ModalSubtitle><T>Edit pricing for the various sizes, colors and options</T></ModalSubtitle>
				</MarginButtom30>
			</Grid>
			{ /*<Grid>
				<ApplyNetProfitTitle>
					<T>Apply a net profit of</T>
				</ApplyNetProfitTitle>
				<ProfitOptionContainer>
					<div>
						<FormControlLabel
							id="fix"
							control={<Radio
								icon={<UncheckedRadioIcon />}
								checkedIcon={<CheckedRadioIcon />}
							/>}
							checked={this.state.ProfitType === "fix"}
							label={<RadioButtonLabel><T>Fixed Amount</T></RadioButtonLabel>}
							value="fix"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateProfitType(e.target.value)}
						/>
						{this.state.ProfitType === "fix" && <>
							<SpanMarginRight10>$</SpanMarginRight10>
								<AmountInput variant="standard">
									<input type="number" name="fixAmountInput" id="fixAmountInput" onChange={(e) => this.onAmountChange('fixAmount', e.target.value)} />
								</AmountInput>
							
						</>}
					</div>

					<div>
						<FormControlLabel
							id="percentage"
							control={<Radio
								icon={<UncheckedRadioIcon />}
								checkedIcon={<CheckedRadioIcon />}
							/>}
							checked={this.state.ProfitType === "percentage"}
							label={<RadioButtonLabel><T>Percentage</T></RadioButtonLabel>}
							value="percentage"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateProfitType(e.target.value)}
						/>
						{this.state.ProfitType === "percentage" && <>
							<SpanMarginRight10>%</SpanMarginRight10>
							<AmountInput>
								<input type="number" name="percentageInput" id="percentageInput" onChange={(e) => this.onAmountChange('percentageAmount', e.target.value)} />
							</AmountInput>
							{
								//<AmountInput type="number" name="percentageInput" id="percentageInput" /> 
							}

						</>}
					</div>
				</ProfitOptionContainer>
			</Grid> */ }
			<Grid>
				<PricesTableContainer>
					<table>
						<thead>
							<tr>
								<TableSimpleHeader><T>Variant</T></TableSimpleHeader>
								<TableSimpleHeader><T>Dropship Price</T></TableSimpleHeader>
								<TableSimpleHeaderTextCenter><T>Your Price</T></TableSimpleHeaderTextCenter>
								<TableBoldHeaderTextEnd><T>Net Profit</T></TableBoldHeaderTextEnd>
							</tr>
						</thead>
						<tbody>
							{ this.props.product.get('price_dropship').get('amount') > 0 
								&& optionTypesWithNoEmptyPrice.length === 0 
								&& optionTypesWithOnlyOneEmptyPrice.length === 0 
								&& <PriceRow
									name="Base Product"
									price={this.props.price.toDinero()}
									costPrice={this.props.product.get('price_dropship').toDinero()}
									onPriceChange={(price:Dinero.Dinero) => {
										this.props.onFormChange('price', PriceStore.fromDinero(price));
									}}
								/>
							}
							{ Array.from(variantPriceGroups).map(([variantSlug, variantGroup]) => {
								//Take first one, doesn't matter anyways
								let variantPrice = variantGroup.listVariantPrices[0];

								return <PriceRow
									key={variantSlug}
									name={variantGroup.name}
									price={variantPrice.get('price').toDinero()}
									costPrice={variantPrice.get('costPrice').toDinero()}
									onPriceChange={(price:Dinero.Dinero) => {
										let variant_prices = this.props.variant_prices;

										variantGroup.listVariantPrices.forEach((variantPrice) => {
											let id = variantPrice.get('option_ids').sort().join('|');
											variant_prices = variant_prices.set(id, variantPrice.set('price', PriceStore.fromDinero(price)));	
										});
										
										this.props.onFormChange('variant_prices', variant_prices);
									}}
								/>
							}) }
						</tbody>
					</table>
				</PricesTableContainer>
			</Grid>
			

			<Grid>
				{/* if there is any special price*/}
				<MarginBottom20>
					<Grid container direction='column' spacing={2}>
						{ customVariantPrices.entrySeq().map(([variantId, variantPrice]) => {
							return <Grid key={variantId} item>
								<SampleSpecialParentDiv>
									<MarginBottom10>
										<Grid container direction='row' justifyContent='space-between'>
											<Grid item>
												<InlineParagraph500><T>Custom Price: </T></InlineParagraph500>
												<InlineParagraph600>{ variantPrice.get('price').toDinero().toFormat() }</InlineParagraph600>
											</Grid>
											<Grid item>
												<FlexGap1rem>
													<ActionButton onClick={() => {
														let variant_prices = this.props.variant_prices.delete(variantId);
														this.props.onFormChange('variant_prices', variant_prices);
													}}><ActionIcon className='material-icons'>delete_outline</ActionIcon><T>Remove</T></ActionButton>
													{ /* <ActionButton><ActionIcon className='material-icons'>edit</ActionIcon><T>Edit</T></ActionButton> */ }
												</FlexGap1rem>
											</Grid>
										</Grid>
									</MarginBottom10>
									<Grid container direction='row' spacing={4}>
										{ variantPrice.get('option_ids').map((optionId) => {
											return availableOptionTypes.valueSeq().map(type => {
												if(!type.get('options').has(String(optionId))) return;

												return <Grid key={optionId} item>
													<SpecialOptionLabel>{ type.get('name') }</SpecialOptionLabel>
													<SpecialOptionData>{ type.get('options').get(String(optionId)).get('name') }</SpecialOptionData>
												</Grid>
											})
										})}
									</Grid>
								</SampleSpecialParentDiv>
							</Grid>
						}) }
					</Grid>
				</MarginBottom20>
			</Grid>
			{ /* <Grid>
				{!this.state.enterSpecialPrice ? <>
					<ButtonWhiteHeight45Weight500Larger onClick={(e) => this.handleDisplaySpecialPriceSection()}><T>+ Create a Custom Price</T></ButtonWhiteHeight45Weight500Larger>
					<SpecialPriceDesc><T>Give an item a certain price that overrides the table based on size, color, material or options</T></SpecialPriceDesc>
				</>
					: <CustomPriceForm 
						massPresets={this.props.massPresets}
						product={this.props.product}
						productDesign={this.props.productDesign}
						basePrice={this.props.product.get('price_retail').toDinero()}
						onCancel={() => {
							this.handleCloseSpecialPriceSection();
						}}
						onSave={(price, option_ids) => {
							this.handleCloseSpecialPriceSection();

							let id = option_ids.sort().join('|');
							let variant_prices = this.props.variant_prices.set(id, new VariantPriceStore({
								price: PriceStore.fromDinero(price),
								option_ids: option_ids
							}));

							this.props.onFormChange('variant_prices', variant_prices);
						}}
					/>
				}
			</Grid> */ }

			{labExit && <>
				<MarginTopPublishButton>
					<PinkHeight45FullWidthButton onClick={() => this.props.handleStep(this.props.index + 1)}>
						<T>Publish</T>
					</PinkHeight45FullWidthButton>
				</MarginTopPublishButton>
			</>}

		</>
		}
}