import { Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import LabLoader from "../../_components/LabLoader";
import DialogContent from "../../../Common/Dialog/DialogContent";

const StyledDialogContent = styled(DialogContent)(({theme}) => ({
    padding: '60px 80px',
    textAlign: 'center',

    ['@media (max-width: '+theme.breakpoints.values.dialog_mobile+'px)']: {
        padding: '30px 40px',
    }
}));

type Props = {
}

export default function SaveGenerating(props:Props) {
    const [t] = useTranslation('design-lab');

    return <>
        <StyledDialogContent>
            <Typography
                variant="h2" 
                style={{
                    marginBottom: '10px',
                }}
            >{ t('We are generating your design!') }</Typography>
            <Typography
                variant="body1"
                style={{
                    marginBottom: '30px',
                }}
            >{ t('It won\'t be long. We\'re creating unique previews for your design.') }</Typography>

            <LabLoader />

            <img 
                src={require('@resources/img/create/design-lab-v4/product-illustration.svg?url')}
                style={{
                    maxWidth: '100%',
                }}
            />
        </StyledDialogContent>
    </>
}