import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { IconButton, Menu, MenuItem, Tooltip, styled } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { EditActiveOption } from '../../UIData/_actions/DesignLabStoreActions';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import LabOptionTool, { LabOptionValue } from './LabOptionTool';
import Immutable from 'immutable';

type Props = {
    option: string,
}

export default function DetailTool(props:Props) {
    const activeProductId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
    const detail = useSelector((state:WebsiteStore) => state.get('appData').get('products').get(String(activeProductId))?.get('details').get(props.option));

    const listValues = useMemo(() => {
        let tmpListValues = Immutable.OrderedMap<string, LabOptionValue>()
        
        detail?.get('listValues').forEach(value => {
            tmpListValues = tmpListValues.set(value.get('slug'), new LabOptionValue({
                name: value.get('name'),
                slug: value.get('slug'),
                color: value.get('data')?.get('color'),
            }))
        })

        return tmpListValues
    }, [detail]);

    if(!detail) return null;

    return <LabOptionTool
        type="detail"
        layout={props.option === 'finish' ? 'icon' : 'color'}
        name={detail.get('name')}
        option={props.option}
        listValues={listValues}
    />
}