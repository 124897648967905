import { FormControl, styled } from '@mui/material';
import { TdTextCenter, TdTextEnd } from '../../Common/_components/TableElements';
import Dinero from 'dinero.js';
import { ChangeEvent, FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';

export type Props = {
	name: string
	price: Dinero.Dinero,
	costPrice: Dinero.Dinero,
	onPriceChange: (price:Dinero.Dinero) => void
}

const AmountInput = styled(FormControl)({
	'input': {
		display: 'inline',
		width: '82px',
		margin: '0',
		border: '1px solid #AFAFAF',
		borderRadius: '2px',
		paddingLeft: '5px',
	}	
});
const SpanMarginRight10 = styled('span')({
	marginRight: '10px',
});
const InlineFlexAlignCenter = styled('div')({
	display: 'inline-flex',
	alignItems: 'center',
});

export default function PriceRow(props:Props) {
	const [priceInput, setPriceInput] = useState(props.price.toRoundedUnit(2).toFixed(2));
	const [profitInput, setProfitInput] = useState(props.price.subtract(props.costPrice).toRoundedUnit(2).toFixed(2));

	useEffect(() => {
		setPriceInput(props.price.toRoundedUnit(2).toFixed(2))
		setProfitInput(props.price.subtract(props.costPrice).toRoundedUnit(2).toFixed(2))
	}, [props.price,props.costPrice])

	const price = useMemo(() => {
		let parsedInput = parseFloat(priceInput);
		parsedInput = isNaN(parsedInput) ? 0 : Math.round(parsedInput*100);

		return Dinero({ amount: parsedInput, currency: props.price.getCurrency()})
	}, [priceInput, props.price]);

	const profit = useMemo(() => {
		let parsedInput = parseFloat(profitInput);
		parsedInput = isNaN(parsedInput) ? 0 : Math.round(parsedInput*100);

		return Dinero({ amount: parsedInput, currency: props.price.getCurrency()})
	}, [profitInput, props.price]);

	const onPriceInputChange = useCallback((e:ChangeEvent<HTMLInputElement>) => {
		setPriceInput(e.target.value)
	}, [])

	const onPriceInputBlur = useCallback((e:FocusEvent<HTMLInputElement>) => {
		setPriceInput(price.toRoundedUnit(2).toFixed(2))

		props.onPriceChange(price)
	}, [price, props.onPriceChange])

	const onProfitInputChange = useCallback((e:ChangeEvent<HTMLInputElement>) => {
		setProfitInput(e.target.value)
	}, [])

	const onProfitInputBlur = useCallback((e:FocusEvent<HTMLInputElement>) => {
		setProfitInput(profit.toRoundedUnit(2).toFixed(2))

		props.onPriceChange(props.costPrice.add(profit))
	}, [profit, props.costPrice])
	
	return <tr>
		<td>{ props.name }</td>
		<td>{ props.costPrice.toFormat() }</td>
		<TdTextCenter>
			<InlineFlexAlignCenter>
				<SpanMarginRight10>$</SpanMarginRight10>
				<AmountInput variant="standard">
					<input 
						type="number"
						name="yourPrice"
						id="yourPrice"
						value={priceInput} 
						onChange={onPriceInputChange}
            onBlur={onPriceInputBlur}
					/>
				</AmountInput>
			</InlineFlexAlignCenter>
		</TdTextCenter>
		<TdTextEnd>
			<InlineFlexAlignCenter>
				<SpanMarginRight10>$</SpanMarginRight10>
				<AmountInput variant="standard">
					<input
						type="number"
						name="yourPrice"
						id="yourPrice"
						value={profitInput} 
						onChange={onProfitInputChange}
            onBlur={onProfitInputBlur}
					/>
				</AmountInput>
			</InlineFlexAlignCenter>
		</TdTextEnd>
	</tr>
}