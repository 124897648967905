import Point from "./Point";
import triangleArea from "./triangleArea";

export default function isInsideRect(a:Point, b:Point, c:Point, d:Point, point:Point) {
	if(triangleArea(a, b, point) > 0 || 
		triangleArea(b, c, point) > 0 || 
		triangleArea(c, d, point) > 0 || 
		triangleArea(d, a, point) > 0)
		return false;

	return true;
};