import { Paper, Divider, Grid, Stack, Typography, IconButton } from '@mui/material'
import { useMemo, type ReactNode, useState, useCallback } from 'react'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import DeleteIcon from '../../Common/Icons/DeleteIcon'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import DeleteCartDialog from '../DeleteCartDialog'

export interface Props {
  cart: UserCartStore
  short?: boolean
  children?: ReactNode
  headerActions?: ReactNode
  showDeleteButton?: boolean
}

export default function CartContent(props: Props) {
  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))

  const title = useMemo(() => {
    return props.cart.get('status') !== 'cart' ? '#' + props.cart.get('ref_id') : props.cart.get('cart_name')
  }, [props.cart])

  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)

  const onDeleteOpen = useCallback(() => {
    setDeleteDialogOpened(true)
  }, [])

  const onDeleteClose = useCallback(() => {
    setDeleteDialogOpened(false)
  }, [])

  return <Paper
    elevation={0}
  >
    <Stack divider={<Divider variant="bold" />}>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: {
            xs: 2,
            lg: 3
          },
          py: 2
        }}
      >
        <Grid item xs={12} md>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h3" style={{
              fontSize: '1.85rem'
            }}>{title}</Typography>

            { props.showDeleteButton ? <IconButton
              onClick={onDeleteOpen}
              disabled={cartLoader}
              color="secondary"
              sx={{
                marginRight: '-12px',
                display: {
                  xs: 'flex',
                  md: 'none'
                }
              }}
            ><DeleteIcon/></IconButton> : null }
          </Stack>
        </Grid>

        {props.headerActions != null ? <Grid item xs={12} md="auto">
          <Stack
            direction="row"
            spacing={{ xs: 1, sm: 2 }}
            alignItems="center"
            useFlexGap
            flexWrap="wrap"
          >
            {props.headerActions}

            { props.showDeleteButton ? <IconButton
              onClick={onDeleteOpen}
              disabled={cartLoader}
              color="secondary"
              sx={{
                marginRight: '-12px',
                display: {
                  xs: 'none',
                  md: 'flex'
                }
              }}
            ><DeleteIcon/></IconButton> : null }
          </Stack>
        </Grid> : null}
      </Grid>

      {props.children}
    </Stack>

    { props.showDeleteButton ? <DeleteCartDialog
      cart={props.cart}
      opened={deleteDialogOpened}
      onClose={onDeleteClose}
    /> : null }
  </Paper>
}
