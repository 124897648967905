import { useTranslation } from 'react-i18next'
import { type ChangeEvent, type FormEvent, useCallback, useEffect, useState } from 'react'
import Dialog from '../../Common/Dialog/Dialog'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import DialogContent from '../../Common/Dialog/DialogContent'
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import DialogActions from '../../Common/Dialog/DialogActions'
import DialogButton from '../../Common/Buttons/DialogButton'
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon } from '@mui/icons-material'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import useErrorText from '../../Common/Error/useErrorText'

interface Props {
  opened: boolean
  onConfirm: (password: string) => void
  onClose: () => void
  loaderKey?: string
  errorKey?: string
}

export default function AuthCheckDialog(props: Props) {
  const [t] = useTranslation('account-v2')

  const loader = useAppSelector(state => props.loaderKey ? state.get('UIData').get('loaders').get(props.loaderKey) : undefined)
  const error = useAppSelector(state => props.errorKey ? state.get('UIData').get('apiErrors').get(props.errorKey) : undefined)

  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    // Reset fields on close
    if (!props.opened) {
      setPassword('')
      setShowPassword(false)
    }
  }, [props.opened])

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }, [])

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    props.onConfirm(password)
  }, [props.onConfirm, password])

  const onClickShowPassword = useCallback(() => {
    setShowPassword(value => !value)
  }, [])

  const errorText = useErrorText(props.errorKey)

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <form onSubmit={onSubmit}>
      <DialogTitle>{t('Verify your identity')}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body1">{t('Please enter your current password so we can verify that it\'s you.')}</Typography>
          <TextField
            autoFocus
            variant="filled"
            type={showPassword ? 'text' : 'password'}
            label={t('Password')}
            value={password}
            onChange={onChange}
            error={!!error}
            helperText={errorText}
            disabled={loader}
            autoComplete="current-password"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  onClick={onClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <DialogButton
          color="secondary"
          onClick={props.onClose}
        >{t('Cancel')}</DialogButton>
        <DialogButton
          color="primary"
          type="submit"
          disabled={password === '' || loader}
        >{t('Confirm')}</DialogButton>
      </DialogActions>
    </form>
  </Dialog>
}
