import { useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import config from '../../../../config/config.json'
import { type StripeElementLocale, loadStripe } from '@stripe/stripe-js'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { useTranslation } from 'react-i18next'
import CartGridContainer from '../../Cart/CartContent/CartGridContainer'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { EditLoader } from '../../UIData/_actions/UIDataActions'
import { CartCheckoutStripeGetPaymentIntent } from '../../UserData/_actions/UserCartActions'
import type Immutable from 'immutable'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import CheckoutPaymentContent from './CheckoutPaymentContent'

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY)

interface Props {
  orders: Immutable.OrderedMap<string, UserCartStore>
  confirmationUrl: string
}

export default function CheckoutPayment(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const loggedIn = useAppSelector(state => state.get('userData').get('loggedIn'))

  const [stripeClientSecret, setStripeClientSecret] = useState<string | undefined>(undefined)
  const [savePaymentMethod, setSavePaymentMethod] = useState(loggedIn)
  const [stripeError, setStripeError] = useState('')
  const [stripeUpdate, setStripeUpdate] = useState(Date.now())

  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  const isFree = useMemo(() => {
    // Check if total is 0$
    let tmpIsFree = true

    props.orders.forEach((cart) => {
      if (cart.get('total').get('amount') > 0) {
        tmpIsFree = false
      }
    })

    return tmpIsFree
  }, [props.orders])

  // Re-trigger payment intent on every change to orders
  useEffect(() => {
    if (isFree) {
      return
    }

    dispatch(EditLoader('payment', true))

    // Provide id order if we're paying an 'ordered' order
    let idOrder: number | undefined
    if (props.orders.count() === 1 && props.orders.first()?.get('status') === 'ordered') {
      idOrder = props.orders.first()?.get('id')
    }

    dispatch(CartCheckoutStripeGetPaymentIntent(savePaymentMethod, idOrder).set({
      onSuccess: (response) => {
        setStripeUpdate(Date.now())
        setStripeClientSecret(response.data.secret)
      },
      onFailure: (response) => {
        // Reset loader payment loader here as it won't be reenabled downstream
        dispatch(EditLoader('payment', false))

        if (response.data?.error === 'invalid-shipping-address') {
          setStripeError(t('Your shipping address is invalid. Please edit and reconfirm your address.'))
        } else if (response.data?.error === 'invalid-billing-address') {
          setStripeError(t('Your billing address is invalid. Please edit reconfirm your address.'))
        } else {
          setStripeError(t('An error occured'))
        }
      }
    }))
  }, [t, savePaymentMethod, props.orders, isFree])

  const firstCart = useMemo(() => {
    return props.orders.first()
  }, [props.orders])

  const currency = useMemo(() => {
    if (!firstCart) return 'USD'

    return firstCart.get('total').get('currency')
  }, [firstCart])

  return <CartGridContainer>
    {isFree ? <>
      <CheckoutPaymentContent
        orders={props.orders}
        paymentOption={isFree ? 'free' : 'card'}
        savePaymentMethod={savePaymentMethod}
        setSavePaymentMethod={setSavePaymentMethod}
        confirmationUrl={props.confirmationUrl}
      />
    </> : (stripeClientSecret ? <Elements
      stripe={stripePromise}
      options={{
        clientSecret: stripeClientSecret,
        loader: 'auto',
        locale: window.LOCALE.replace('_', '-') as StripeElementLocale,
        appearance: {
          theme: 'flat',
          variables: {
            fontFamily: 'Poppins, sans-serif',
            borderRadius: '0px',
            colorPrimaryText: '#000000',
            colorBackground: '#ffffff',
            iconCheckmarkColor: '#ffffff'
          },
          rules: {
            '.Input': {
              backgroundColor: 'rgba(0, 0, 0, 0.06)',
              borderRadius: '25px',
              fontSize: '14px',
              padding: '10px 20px',
              border: '0',
              outline: '0'
            },
            '.Input:focus': {
              boxShadow: 'none',
              outline: '0'
            },
            '.Input--invalid': {
              boxShadow: 'none',
              outline: '0',
              border: '0'
            },
            '.Label': {
              color: '#000',
              fontSize: '14px'
            },
            '.Label--invalid': {
              color: '#df1b41'
            },
            '.CheckboxInput': {
              border: '1px solid #afafaf',
              borderRadius: '3px'
            },
            '.CheckboxInput--checked': {
              backgroundColor: '#000',
              borderColor: '#000'
            },
            '.Error': {
              fontSize: '.8rem'
            },
            '.Tab': {
              padding: '10px 12px 8px 12px',
              border: '1px solid #afafaf',
              borderRadius: '8px'
            },
            '.Tab:hover, .Tab:focus': {
              border: '1px solid #000',
              outline: 'none',
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
              border: '1px solid transparent',
              backgroundColor: '#fff',
              boxShadow: '0 0 0 1.5px #000, 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
            }
          }
        }
      }}
    >
      <PayPalScriptProvider
        options={{
          clientId: config.PAYPAL_CLIENT_ID,
          locale,
          currency,
          disableFunding: ['card', 'paylater'],
          intent: 'capture',
          dataPageType: 'checkout'
        }}
      >
        <CheckoutPaymentContent
          orders={props.orders}
          stripeReady
          stripeUpdate={stripeUpdate}
          savePaymentMethod={savePaymentMethod}
          setSavePaymentMethod={setSavePaymentMethod}
          confirmationUrl={props.confirmationUrl}
        />
      </PayPalScriptProvider>
    </Elements> : <PayPalScriptProvider
      options={{
        clientId: config.PAYPAL_CLIENT_ID,
        locale,
        currency,
        disableFunding: ['card', 'paylater'],
        intent: 'capture',
        dataPageType: 'checkout'
      }}
    >
      <CheckoutPaymentContent
        orders={props.orders}
        stripeError={stripeError}
        savePaymentMethod={savePaymentMethod}
        setSavePaymentMethod={setSavePaymentMethod}
        confirmationUrl={props.confirmationUrl}
      />
    </PayPalScriptProvider>)}
      { /* <>
        <CartListGrid>
          <Skeleton
            variant="rounded"
            height={800}
          />
        </CartListGrid>
        <Grid item xs={12} md={4} lg={3}>
          <Skeleton
            variant="rounded"
            height={500}
          />
        </Grid>
    </> */ }
  </CartGridContainer>
}
