import { isBrowser } from 'browser-or-node'

export default (fps: number) => (() => {
    if(!isBrowser) {
        return (callback:() => void) => {
            callback();
        }
    }

    //@ts-ignore
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame ||
    function(callback:() => void){
        window.setTimeout(callback, 1000 / fps);
    }
})()