import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import WebsiteStore from "../../WebsiteStore";
import { Layer as LayerNode, Rect, Stage as StageNode, Text, Image as ImageNode, Group as GroupNode } from "react-konva";
import KonvaPatternImage from "../KonvaNode/KonvaPatternImage";
import KonvaImage from "../KonvaNode/KonvaImage";
import { isBrowser, isNode } from "browser-or-node";
import PartRenderer from "./PartRenderer";

type Props = {
  subproduct: string | null,
  subproductRef?: string,
  scene: string,
  sceneData: Record<string, any>,
  subproductRef_sceneData?: Record<string, any>,
  inputType: 'lab',
  outputType: 'texture' | 'render',
  labData: Record<string, any>,
}

export default function SceneRenderer(props: Props) {
  const renderPart = useCallback((part: string) => {
    return <PartRenderer
      key={part}
      subproduct={props.subproduct}
      subproductRef={props.subproductRef}
      scene={props.scene}
      part={part}
      partData={props.sceneData.parts[part]}
      subproductRef_partData={props.subproductRef_sceneData?.parts[part]}
      inputType={props.inputType}
      outputType={props.outputType}
      labData={props.labData}
    />
  }, [props.labData, props.subproduct, props.subproductRef, props.subproductRef_sceneData, props.sceneData, props.inputType, props.outputType])

  const parts = useMemo(() => {
    return Object.keys(props.sceneData.parts);
  }, [props.sceneData])

  return <>
    {parts.map(renderPart)}
  </>
}