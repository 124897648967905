import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';

const PRODUCT_ID = 61;
const VIEW_PRODUCT_ID = 141;

export default function GlitterAthleticCropTopPage() {
  const [t] = useTranslation('page/products/glitter-athletic-crop-top');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Glitter Athletic Crop Tops | Products')}
      description={t('Design and create your own custom printed Glitter Athletic Crop Top. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#edc4d2"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '47%',
      }}
      tag={t('The all-over print')}
      title={t('Glitter Crop Top')}
      text={t('Introducing glitter athletic crop tops, crafted from high-performance glitter fabrics, glitter athletic crop tops offer a stylish twist to your workout attire. Designed for both flair and function, these form-fitting tops provide unrestricted movement during any activity. Shine bright and confident as you conquer your fitness goals in style.')}
      createUrl={productData.get('url_slug') + '?option_fabric=glitter-performance-knit'}
      img={require('@resources/img/pages/products/glitter-athletic-crop-top/banner-1-' + locale + '.png')}
      alt={t('Picture of Glitter Crop Top')}
      details={[
        {
          img: require('@resources/img/pages/products/glitter-athletic-crop-top/icon-1.svg?url'),
          alt: t('Fabric icon'),
          text: t('Cropped body-hugging fit'),
        },
        {
          img: require('@resources/img/pages/products/glitter-athletic-crop-top/icon-2.svg?url'),
          alt: t('Worldwide icon'),
          text: t('Racerback cut. Reversible front and back.'),
        },
        {
          img: require('@resources/img/pages/products/glitter-athletic-crop-top/icon-3.svg?url'),
          alt: t('UPF icon'),
          text: t('Made from 88% polyester/12 % spandex performance glitter fabric'),
        },
        {
          img: require('@resources/img/pages/products/glitter-athletic-crop-top/icon-4.svg?url'),
          alt: t('Ink icon'),
          text: t('Printed with vibrant and permanent sublimation inks'),
        },
        {
          img: require('@resources/img/pages/products/glitter-athletic-crop-top/icon-5.svg?url'),
          alt: t('Yoga icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/glitter-athletic-crop-top/gallery_0.jpg'),
      require('@resources/img/pages/products/glitter-athletic-crop-top/gallery_1.jpg'),
      require('@resources/img/pages/products/glitter-athletic-crop-top/gallery_2.jpg'),
      require('@resources/img/pages/products/glitter-athletic-crop-top/gallery_3.jpg'),
      require('@resources/img/pages/products/glitter-athletic-crop-top/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your glitter <br/>crop top <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love glitter athletic crop tops! At prices designed for reselling, glitter athletic crop tops made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin.')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
      options={['glitter-performance-knit']}
    />

    <SectionSizes
      chart="tops"
      productId={PRODUCT_ID}
      title={t('Glitter Crop Top Sizing')}
      text={t('All measurements are approximate. Since our products are made by hand, there may be variations in the finished size of our products between orders or even within an order.')}
    />

    <SectionBranding
      hasLabel
      title={t('Brand your Glitter Crop Top')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your glitter crop top with these great products to create your own unique styles.')}
      productSlugs={['glitter-flare-skirt', 'glitter-yoga-shorts', 'vegan-leather-tote-bag']}
    />
  </div>
}