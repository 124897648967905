import { Button, Tooltip, styled } from '@mui/material';
import { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import { EditIsPanning, EditZoom } from '../../UIData/_actions/DesignLabStoreActions';
import WebsiteStore from '../../WebsiteStore';
import useStageDimensions from '../_hooks/useStageDimensions';
import useZoom from '../_hooks/useZoom';
import { LAB_TEMPLATE_HEIGHT, LAB_TEMPLATE_WIDTH } from '../../config';
import { useTranslation } from 'react-i18next';
import { CanvasToLab } from '../../Common/Utils/ZoomUtils';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

const ZoomControlsContainer = styled('div')(({theme}) => ({
    background: '#eee',
    borderRadius: '30px',
    overflow: 'hidden',
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    display: 'flex',
    alignItems: 'center',

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        display: 'none',
    }
}));

const ZoomButton = styled(Button)({
    height: '40px',
    borderRadius: 0,
    color: 'inherit',

    '&:focus': {
        background: 'rgba(0, 0, 0, 0.1)',
    }
})

type Props = {}

export default function ZoomControls(props:Props) {
    const [t] = useTranslation('design-lab');
    const dispatch = useAppDispatch();
    const { stageWidth, stageHeight } = useStageDimensions();
    const zoomScale = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('zoomScale'));
    const zoomOffset = {
        x: useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('zoomOffsetX')),
        y: useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('zoomOffsetY')),
    }
    const isPanning = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('isPanning'));
    const activeProductId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
    const zoom = useZoom();

    useHotkeys('ctrl+0', (e) => {
        e.preventDefault();
        zoom(1 - zoomScale, {
            x: stageWidth/2,
            y: stageHeight/2,
        });
    }, {
        scopes: ['design-lab']
    });

    useHotkeys(['ctrl-+', 'ctrl-='], (e) => {
        e.preventDefault();
        zoom(0.25, CanvasToLab({
            x: stageWidth / 2,
            y: stageHeight / 2,
        }, zoomOffset, zoomScale));
    }, {
        combinationKey: '-',
        scopes: ['design-lab'],
        keydown: true,
    });

    useHotkeys('ctrl+-', (e) => {
        e.preventDefault();
        zoom(-0.25, CanvasToLab({
            x: stageWidth / 2,
            y: stageHeight / 2,
        }, zoomOffset, zoomScale));
    }, {
        scopes: ['design-lab']
    });

    useHotkeys('space', (e) => {
        e.preventDefault();
        dispatch(EditIsPanning(true));
    }, {
        scopes: ['design-lab'],
        keydown: true,
    });

    useHotkeys('space', (e) => {
        e.preventDefault();
        dispatch(EditIsPanning(false));
    }, {
        scopes: ['design-lab'],
        keyup: true,
    });

    //Reset positioning when product changes
    useEffect(() => {
        const bestZoomScale = Math.max(0.5, Math.min(
            2, 
            Math.floor((stageWidth / LAB_TEMPLATE_WIDTH)/0.25)*0.25,
            Math.floor((stageHeight / LAB_TEMPLATE_HEIGHT)/0.25)*0.25
        ));

        zoom(bestZoomScale - zoomScale, {
            x: stageWidth/2,
            y: stageHeight/2,
        });
    }, [activeProductId, stageWidth, stageHeight]);

    return <ZoomControlsContainer>
        <Tooltip title={t('Pan')} placement="top">
            <ZoomButton
                style={{
                    border: 0,
                    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                    backgroundColor: isPanning ? 'rgba(0, 0, 0, 0.15)' : 'transparent',
                }}
                onClick={() => {
                    dispatch(EditIsPanning(!isPanning));
                }}
            ><i className="material-icons">pan_tool</i></ZoomButton>
        </Tooltip>
        
        <Tooltip title={t('Zoom out')} placement="top">
            <ZoomButton
                style={{
                    border: 0,
                    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                    zoom(-0.25, {
                        x: stageWidth/2,
                        y: stageHeight/2,
                    });
                }}
            ><i className="material-icons">zoom_out</i></ZoomButton>
        </Tooltip>

        <div
            style={{
                padding: '0 15px',
                width: '70px',
                textAlign: 'center',
            }}
        >{ Math.round(zoomScale*100) }%</div>

        <Tooltip title={t('Zoom in')} placement="top">
            <ZoomButton
                style={{
                    border: 0,
                    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                    zoom(0.25, {
                        x: stageWidth/2,
                        y: stageHeight/2,
                    });
                }}
            ><i className="material-icons">zoom_in</i></ZoomButton>
        </Tooltip>
    </ZoomControlsContainer>
}