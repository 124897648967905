import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../_Sections/SectionDetails';
import SectionGallery from '../_Sections/SectionGallery';
import SectionDropship from '../_Sections/SectionDropship';
import SectionPairings from '../_Sections/SectionPairings';
import SectionBranding from '../_Sections/SectionBranding';
import SectionSizes from '../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../_Sections/SectionPricing';
import tKey from '../../Common/Utils/tKey';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import ProductSEO from '../_Sections/ProductSEO';
import { BlackCheckedIcon } from '../../Common/_components/CheckBoxes';

const PRODUCT_ID = 80;
const VIEW_PRODUCT_ID = 46;

export default function BoxerBriefsPage() {
  const [t] = useTranslation('page/products/boxer-briefs');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Boxer Briefs | Products')}
      description={t('Design and create your own custom printed Boxer Briefs featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#8ed4f2"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '48%',
      }}
      tag={t('The all-over print')}
      title={t('Boxer Briefs')}
      text={t('Custom printed, super comfy men’s boxer briefs! Unique online, Art of Where gives you the power to create, sell, and share your own branded artwork printed underwear. What else is awesome? Boxer briefs is our first product for men! So get creative with your line of branded men’s boxer briefs.')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/boxer-briefs/banner-1-' + locale + '.png')}
      alt={t('Picture of Boxer Briefs')}
      details={[
        {
          img: require('@resources/img/pages/products/boxer-briefs/icon-1.svg?url'),
          alt: t('Peachskin fabric icon'),
          text: t('Made from our super soft peachskin jersey'),
        },
        {
          img: require('@resources/img/pages/products/boxer-briefs/icon-2.svg?url'),
          alt: t('Sewing icon'),
          text: t('Activeseam stitching to move with you'),
        },
        {
          img: require('@resources/img/pages/products/boxer-briefs/icon-3.svg?url'),
          alt: t('Cotton icon'),
          text: t('Full lined with jersey cotton lining'),
        },
        {
          img: require('@resources/img/pages/products/boxer-briefs/icon-4.svg?url'),
          alt: t('Waistband icon'),
          text: t('Fully printed 1.25" wide elastic waistband.'),
        },
        {
          img: require('@resources/img/pages/products/boxer-briefs/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/boxer-briefs/gallery_0.jpg'),
      require('@resources/img/pages/products/boxer-briefs/gallery_1.jpg'),
      require('@resources/img/pages/products/boxer-briefs/gallery_2.jpg'),
      require('@resources/img/pages/products/boxer-briefs/gallery_3.jpg'),
      require('@resources/img/pages/products/boxer-briefs/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Boxer <br/>Brief <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Boxer Briefs! At prices designed for reselling, Boxer Briefs made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
    />

    <SectionSizes
      chart="bottoms-men" 
      productId={PRODUCT_ID}
      title={t('Boxer Briefs Sizing')}
      text={t('Our underwear tends to run small so if you\'d like a bit of extra wiggle room, we suggest ordering a size larger than you\'re used to.')}
    >
    </SectionSizes>

    <SectionBranding
      hasLabel
      title={t('Brand your Boxer Briefs')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Boxer Briefs with these great products to create your own unique styles.')}
      productSlugs={['cheeky-briefs', 'lounge-pants', 'peignoir-peachskin']}
    />
  </div>
}