import { createAction } from "../../Common/_actions/Action";
import { UserRewardPayload } from "../_stores/UserRewardStore";

export enum UserRewardStoreActionType {
  EditUserReward = 'USER_REWARD_EDIT_USER_REWARD',
  EditManyUserRewards = 'USER_REWARD_EDIT_MANY_USER_REWARDS',
  RemoveUserReward = 'USER_REWARD_REMOVE_USER_REWARD',
}

export const EditUserReward = (userReward: UserRewardPayload) => createAction(UserRewardStoreActionType.EditUserReward, {
	userReward: userReward
});

export const EditManyUserRewards = (userRewards: Record<string, UserRewardPayload>) => createAction(UserRewardStoreActionType.EditManyUserRewards, {
	userRewards: userRewards
});

export const RemoveUserReward = (id: number) => createAction(UserRewardStoreActionType.RemoveUserReward, {
	id: id
});

export type UserRewardStoreAction =
    ReturnType<typeof EditUserReward> |
    ReturnType<typeof EditManyUserRewards> |
    ReturnType<typeof RemoveUserReward>