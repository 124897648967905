import { createSelector } from "reselect";
import { selectUserData } from "./UserDataSelectors";
import Immutable from "immutable";
import UserCartStore from "../_stores/UserCartStore";

export const selectCartIds = createSelector(
  [selectUserData],
  userData => userData.get('orders').get('itemLists').get('cart')?.get('items') ?? Immutable.OrderedSet<string>(),
  {
    memoizeOptions: {
      resultEqualityCheck: (a: Immutable.OrderedSet<string>, b: Immutable.OrderedSet<string>) => {
        return a === b || (
          a instanceof Immutable.OrderedMap
          && b instanceof Immutable.OrderedMap
          && a.count() === b.count()
          && a.every((id, key) => id === b.get(key))
        )
      }
    }
  }
)
export const selectCartCount = createSelector(
  [selectUserData],
  userData => userData.get('orders').get('itemLists').get('cart')?.get('total') ?? 0
)
export const selectCarts = createSelector(
  [selectUserData, selectCartIds],
  (userData, cartIds) => {
    let list = Immutable.OrderedMap<string, UserCartStore>()
    cartIds.forEach(id => {
      const order = userData.get('orders').get('data').get(id)
      if (!order) return

      list = list.set(id, order)
    })
    return list
  }, {
  memoizeOptions: {
    resultEqualityCheck: (a: Immutable.OrderedMap<string, UserCartStore>, b: Immutable.OrderedMap<string, UserCartStore>) => {
      return a === b || (
        a instanceof Immutable.OrderedMap
        && b instanceof Immutable.OrderedMap
        && a.count() === b.count()
        && a.every((order, key) => order.equals(b.get(key)))
      )
    }
  }
})

export const selectCartConfirmationIds = createSelector(
  [selectUserData],
  userData => userData.get('orders').get('itemLists').get('cartConfirmation')?.get('items') ?? Immutable.OrderedSet<string>(),
  {
    memoizeOptions: {
      resultEqualityCheck: (a: Immutable.OrderedSet<string>, b: Immutable.OrderedSet<string>) => {
        return a === b || (
          a instanceof Immutable.OrderedMap
          && b instanceof Immutable.OrderedMap
          && a.count() === b.count()
          && a.every((id, key) => id === b.get(key))
        )
      }
    }
  }
)

export const selectCartConfirmations = createSelector(
  [selectUserData, selectCartConfirmationIds],
  (userData, cartIds) => {
    let list = Immutable.OrderedMap<string, UserCartStore>()
    cartIds.forEach(id => {
      const order = userData.get('orders').get('data').get(id)
      if (!order) return

      list = list.set(id, order)
    })
    return list
  }, {
  memoizeOptions: {
    resultEqualityCheck: (a: Immutable.OrderedMap<string, UserCartStore>, b: Immutable.OrderedMap<string, UserCartStore>) => {
      return a === b || (
        a instanceof Immutable.OrderedMap
        && b instanceof Immutable.OrderedMap
        && a.count() === b.count()
        && a.every((order, key) => order.equals(b.get(key)))
      )
    }
  }
})