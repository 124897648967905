import { Grid } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  img: string
  imgAlt?: string
  title: ReactNode
  text: ReactNode
}

export default function SectionBrandingOption(props:Props) {
  return <Grid item xs={12} lg={6} md={8} container spacing={4} alignItems="center" justifyContent="center">
    <Grid item xs={12} sm={6}>
      <img
        src={props.img}
        alt={props.imgAlt}
        style={{
          marginTop: 0,
          width: '100%',
          maxWidth: '360px',
        }}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <h3>{props.title}</h3>
      <p>{props.text}</p>
    </Grid>
  </Grid>
}