import * as Immutable from 'immutable';

export type Locale = 'en_CA'|'fr_CA';
export type Currency = 'USD'|'CAD';

export type UserPrefsPayload = {
	currency: Currency,
	lang: Locale,
	addressAtCheckout?: boolean
	quickDeleteFromCart?: boolean
	massDesignLabSaveDismissed?: boolean
	accountProductsLayout?: 'grid'|'list'
	accountPageLimit?: number
}
export interface IUserPrefsStore {
	currency: Currency,
	lang: Locale,
	addressAtCheckout: boolean,
	quickDeleteFromCart: boolean,
	massDesignLabSaveDismissed: boolean,
	accountProductsLayout: 'grid'|'list',
	accountPageLimit: number,
}

const defaultStore = Immutable.Record<IUserPrefsStore>({
	currency: 'USD',
	lang: 'en_CA',
	addressAtCheckout: false,
	quickDeleteFromCart: false,
	massDesignLabSaveDismissed: false,
	accountProductsLayout: 'grid',
	accountPageLimit: 20,
});

export default class UserPrefsStore extends defaultStore implements IUserPrefsStore {}