import { Color } from "three";
import Point from "../../Common/Utils/Math/Point"
import { createAction } from "../../Common/_actions/Action"
import { LayerAlignment, LayerPatternMode, LayerType } from "../_stores/DesignLab/DesignLabLayerStore"

export enum DesignLabActionType {
	AddImgLayer = "DESIGN_LAB_ADD_IMG_LAYER",
    AddPatternLayer = "DESIGN_LAB_ADD_PATTERN_LAYER",
    AddTextLayer = "DESIGN_LAB_ADD_TEXT_LAYER",
    EditLayer = "DESIGN_LAB_EDIT_LAYER",
    RemoveLayer = "DESIGN_LAB_REMOVE_LAYER",
    SelectLayer = "DESIGN_LAB_SELECT_LAYER",
    EditActiveDesign = 'DESIGN_LAB_EDIT_ACTIVE_DESIGN',
    EditActiveProduct = 'DESIGN_LAB_EDIT_ACTIVE_PRODUCT',
    EditActiveScene = "DESIGN_LAB_EDIT_ACTIVE_SCENE",
    EditActiveVariant = "DESIGN_LAB_EDIT_ACTIVE_VARIANT",
    EditActiveSubproduct = "DESIGN_LAB_EDIT_ACTIVE_SUBPRODUCT",
    EditZoom = "DESIGN_LAB_EDIT_ZOOM",
    EditIsPanning = "DESIGN_LAB_EDIT_IS_PANNING",
    EditMirrorMode = "DESIGN_LAB_EDIT_MIRROR_MODE",
    EditBackgroundColor = "DESIGN_LAB_EDIT_BACKGROUND_COLOR",
    EditAutoDesignSubproduct = "DESIGN_LAB_EDIT_AUTO_DESIGN_SUBPRODUCT",
    SetLayerOrder = "DESIGN_LAB_SET_LAYER_ORDER",
    UpdateTexture = "DESIGN_LAB_EDIT_TEXTURE_UPDATE",
    EditActiveOption = 'DESIGN_LAB_EDIT_ACTIVE_OPTION',
    ResetActiveOptions = 'DESIGN_LAB_RESET_ACTIVE_OPTIONS',
    EditWarning = 'DESIGN_LAB_EDIT_WARNING',
    EditWarnings = 'DESIGN_LAB_EDIT_WARNINGS',
    ResetWarnings = 'DESIGN_LAB_RESET_WARNINGS',
    EditDesignName = 'DESIGN_LAB_EDIT_DESIGN_NAME',
    ClearLab = 'DESIGN_LAB_CLEAR_LAB',
}

export const AddImgLayer = (data: {
    fileid: string,
    src: string,
    originalWidth: number,
    originalHeight: number,
    scene: string,
    subproduct: string|null,
    width: number,
    height: number,
    x: number,
    y: number,
    scaleX: number,
    scaleY: number,
    rotation: number,
    patternMode?: LayerPatternMode,
    alignment?: LayerAlignment | null,
}) => createAction(DesignLabActionType.AddImgLayer, {
    data: data
});

export const AddPatternLayer = (data: {
    fileid: string,
    src: string,
    originalWidth: number,
    originalHeight: number,
    scene: string,
    subproduct: string|null,
    width: number,
    height: number,
    x: number,
    y: number,
    scaleX: number,
    scaleY: number,
    rotation: number,
}) => createAction(DesignLabActionType.AddPatternLayer, {
    data: data
});

export const AddTextLayer = (data: {
    text: string,
    color: string,
    font: string,
    scene: string,
    subproduct: string|null,
    width: number,
    height: number,
    x: number,
    y: number,
    scaleX: number,
    scaleY: number,
    rotation: number,
}) => createAction(DesignLabActionType.AddTextLayer, {
    data: data
});

export const EditLayer = (id: string, data: {
    type?: LayerType
    scene?: string,
    subproduct?: string,
    width?: number,
    height?: number,
    originalWidth?: number,
    originalHeight?: number,
    x?: number,
    y?: number,
    scaleX?: number,
    scaleY?: number,
    rotation?: number,
    text?: string,
    color?: string,
    font?: string,
    src?: string,
    fileid?: string,
    patternMode?: LayerPatternMode
    alignment?: LayerAlignment | null,
}) => createAction(DesignLabActionType.EditLayer, {
    id: id,
    data: data
});

export const RemoveLayer = (id: string) => createAction(DesignLabActionType.RemoveLayer, {
    id: id
});

export const SelectLayer = (id?: string) => createAction(DesignLabActionType.SelectLayer, {
    id: id
});

export const EditActiveDesign = (id: string) => createAction(DesignLabActionType.EditActiveDesign, {
    id: id
});

export const EditActiveProduct = (id: number|null) => createAction(DesignLabActionType.EditActiveProduct, {
    id: id
});

export const EditActiveScene = (scene?: string) => createAction(DesignLabActionType.EditActiveScene, {
    scene: scene
});

export const EditActiveVariant = (variant: string) => createAction(DesignLabActionType.EditActiveVariant, {
    variant: variant
});

export const EditActiveSubproduct = (subproduct?: string) => createAction(DesignLabActionType.EditActiveSubproduct, {
    subproduct: subproduct
});

export const ResetActiveOptions = () => createAction(DesignLabActionType.ResetActiveOptions, {});

export const EditActiveOption = (typeSlug: string, valueSlug: string) => createAction(DesignLabActionType.EditActiveOption, {
    typeSlug: typeSlug,
    valueSlug: valueSlug,
});

export const EditZoom = (zoom: number, position: Point, stageDimensions: Point) => createAction(DesignLabActionType.EditZoom, {
    zoom: zoom,
    position: position,
    stageDimensions: stageDimensions,
});

export const EditIsPanning = (isPanning: boolean) => createAction(DesignLabActionType.EditIsPanning, {
    isPanning: isPanning
});

export const EditMirrorMode = (scene: string, mirrorMode?: number) => createAction(DesignLabActionType.EditMirrorMode, {
    scene: scene,
    mirrorMode: mirrorMode
});

export const EditAutoDesignSubproduct = (subproduct: string, value: boolean) => createAction(DesignLabActionType.EditAutoDesignSubproduct, {
    subproduct: subproduct,
    value: value,
});

export const EditBackgroundColor = (color: string) => createAction(DesignLabActionType.EditBackgroundColor, {
    color: color
})

export const SetLayerOrder = (listIds:string[]) => createAction(DesignLabActionType.SetLayerOrder, {
    listIds: listIds,
});

export const UpdateTexture = () => createAction(DesignLabActionType.UpdateTexture, {});

export const EditWarning = (warning: 'resolution'|'templates_covered', scene: string, value: boolean) => createAction(DesignLabActionType.EditWarning, {
    warning: warning,
    scene: scene,
    value: value,
});

export const EditWarnings = (warnings: Record<string,Record<string,boolean>>) => createAction(DesignLabActionType.EditWarnings, {
    warnings: warnings,
});

export const ResetWarnings = () => createAction(DesignLabActionType.ResetWarnings, {});
export const EditDesignName = (name:string) => createAction(DesignLabActionType.EditDesignName, {
    name: name
});

export const ClearLab = () => createAction(DesignLabActionType.ClearLab, {
});

export type DesignLabAction = 
	ReturnType<typeof AddImgLayer> |
    ReturnType<typeof AddPatternLayer> |
    ReturnType<typeof AddTextLayer> |
    ReturnType<typeof EditLayer> |
    ReturnType<typeof RemoveLayer> |
    ReturnType<typeof SelectLayer> |
    ReturnType<typeof EditActiveDesign> |
    ReturnType<typeof EditActiveProduct> |
    ReturnType<typeof EditActiveScene> |
    ReturnType<typeof EditActiveVariant> |
    ReturnType<typeof EditActiveSubproduct> |
    ReturnType<typeof EditActiveOption> |
    ReturnType<typeof ResetActiveOptions> |
    ReturnType<typeof EditZoom> |
    ReturnType<typeof EditIsPanning> |
    ReturnType<typeof EditMirrorMode> | 
    ReturnType<typeof EditBackgroundColor> |
    ReturnType<typeof EditAutoDesignSubproduct> | 
    ReturnType<typeof EditWarning> | 
    ReturnType<typeof EditWarnings> | 
    ReturnType<typeof ResetWarnings> | 
    ReturnType<typeof SetLayerOrder> |
    ReturnType<typeof UpdateTexture> |
    ReturnType<typeof EditDesignName> |
    ReturnType<typeof ClearLab>