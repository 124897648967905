import { memo, useCallback } from 'react';
import WebsiteStore from '../../WebsiteStore';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import ViewProductStore from '../../AppData/_stores/ViewProductStore';

type Props = {
  text: React.ReactNode
  productSlugs: string[]
}

export default memo(function SectionPairings(props: Props) {
  const [tR] = useTranslation('routes');
  const [t] = useTranslation('page/products/_sections');

  const listProducts = useAppSelector(state => state.get('appData').get('viewProducts').filter(viewProduct => props.productSlugs.includes(viewProduct.get('slug'))), {
    equalityFn: (a, b) => a.equals(b)
  });

  const renderProduct = useCallback((viewProduct: ViewProductStore) => {
    return <Grid key={viewProduct.get('id')} item xs={6} sm={6} md={4}>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          height: '100%',
          textAlign: 'center',
        }}
      >
        <img
          src={require('@resources/img/pages/products/_sections/pairings/' + viewProduct.get('slug') + '-pairing-1.png')}
          alt={viewProduct.get('name') + ' photo'}
          style={{
            maxWidth: '100%',
          }}
        />
        <Typography
          variant="h4"
          sx={{
            fontSize: '1.4rem',
            fontWeight: '500',
          }}
        >{viewProduct.get('name')}</Typography>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          component={Link}
          to={tR('/products') + '/' + viewProduct.get('url_slug')}
          reloadDocument={!viewProduct.get('is_react_page')}
        >{t('Learn more')}</Button>
      </Stack>
    </Grid>
  }, [t, tR])

  return <Box component="div" sx={{
    pb: {
      xs: 8,
      md: 16,
    },
    pt: {
      xs: 6,
      md: 12,
    },
    backgroundColor: '#f0f0f0',
  }}>
    <Container maxWidth="lg">
      <Stack spacing={8} alignItems="center">
        <Container maxWidth="md">
          <Stack spacing={2} alignItems="center" textAlign="center">
            <Stack spacing={-4}>
              <Typography
                variant="h1-accent"
                component="h3"
              >{t('Pairings')}</Typography>
              <Typography
                variant="h1"
                component="h3"
                color="secondary.main"
              >{t('Complete the look')}</Typography>
            </Stack>
            <Typography
              variant="body1"
            >{props.text}</Typography>
          </Stack>
        </Container>
        <div>
          <Grid
            container
            columnSpacing={{
              xs: 2,
              sm: 4,
              md: 6,
              lg: 8
            }}
            rowSpacing={6}
            justifyContent="center"
          >
            {listProducts.valueSeq().map(renderProduct)}
          </Grid>
        </div>
      </Stack>
    </Container>
  </Box>
})