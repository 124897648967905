import * as React from 'react';
import * as Immutable from 'immutable';
import { combineReducers } from 'redux-immutable';
import { AjaxActionEnd, AjaxActionEndType, AjaxActionStart, AjaxActionStartType } from '../../Common/_actions/AjaxAction';
import { UIDataAction, UIDataActionType } from '../_actions/UIDataActions';
import UIDataStore, { IUIDataStore, UIDataStoreFactory } from '../_stores/UIDataStore';
import DesignLabReducer from './DesignLabReducer';
import { UserDataActionType } from '../../UserData/_actions/UserDataActions';
import { UserCartActionType } from '../../UserData/_actions/UserCartActions';
import { UserAddressActionType } from '../../UserData/_actions/UserAddressActions';
import { BrandingOptionActionType } from '../../UserData/_actions/BrandingOptionActions';
import { AuthActionType } from '../../Auth/_actions/AuthActions';

const UIDataReducer = (
	store:UIDataStore = new UIDataStore(),
	action: UIDataAction 
		| AjaxActionStart<unknown> 
		| AjaxActionEnd<unknown>
	):UIDataStore => {
	switch (action.type) {
		case UIDataActionType.Toast:
			return store.withMutations(store => {
				store.setIn(['toast', 'new'], true);
				store.setIn(['toast', 'message'], action.message);
			});

		case UIDataActionType.ConsumeToast:
			return store.withMutations(store => {
				store.setIn(['toast', 'new'], false);
				store.setIn(['toast', 'message'], "");
			});

		case UIDataActionType.EditLoader:
			return store.setIn(['loaders', action.loader], action.value);

		case UIDataActionType.EditValidData:
			return store.setIn(['validData', action.dataKey], action.value);

		case UIDataActionType.RegisterScrollBlock:
			if(!store.get('scrollBlockers').includes(action.blockerID)){
				store = store.set('scrollBlockers', store.get('scrollBlockers').push(action.blockerID));
			}
			handleScrollBlockers(store.get('scrollBlockers'));
			return store;
		
		case UIDataActionType.UnregisterScrollBlock:
			store = store.set('scrollBlockers', store.get('scrollBlockers').filterNot(blockerID => blockerID == action.blockerID));
			handleScrollBlockers(store.get('scrollBlockers'));
			return store;

		case UIDataActionType.StartInterval:
			clearInterval(store.get('intervals').get(action.name));
			return store.setIn(['intervals', action.name], setInterval(action.call, action.milliseconds))

		case UIDataActionType.StopInterval:
			clearInterval(store.get('intervals').get(action.name));
			return store.deleteIn(['intervals', action.name]);

		case UIDataActionType.ApiError:
			store = store.set('apiErrors', store.get('apiErrors').set(action.key, action.error))
			break;

		case UIDataActionType.ResetApiErrors:
			store = store.set('apiErrors', Immutable.Map<string, Record<string,any>>())
			break;

		case UIDataActionType.EditCreateUrl:
			return store.set('createUrl', action.createUrl);

		case AjaxActionStartType:
		case AjaxActionEndType:
			let loading = action.type == AjaxActionStartType;
			if(action.url == "ajax/api/get-app-data")						store = store.setIn(["loaders", "appData"], loading);
			if(action.url == "ajax/api/user/upload-file")					store = store.setIn(["loaders", "images"], loading);
			if(action.url == "ajax/api/cart/delete-cart")					store = store.setIn(["loaders", "carts"], loading);
			if(action.url == "ajax/api/cart/save-cart-address")				store = store.setIn(["loaders", "carts"], loading);
			if(action.url == "ajax/api/cart/update-cart-shipping-type")		store = store.setIn(["loaders", "carts"], loading);
			if(action.url == "ajax/api/cart/apply-promo-code")				store = store.setIn(["loaders", "carts"], loading);
			if(action.url == "ajax/api/cart/remove-promo-code")				store = store.setIn(["loaders", "carts"], loading);
			if(action.url == "ajax/api/cart/delete-cart-item")				store = store.setIn(["loaders", "carts"], loading);
			if(action.url == "ajax/api/cart/remove-cart-item-label")		store = store.setIn(["loaders", "carts"], loading);
			if(action.url == "ajax/api/cart/add-cart-item-label")			store = store.setIn(["loaders", "carts"], loading);
			if(/ajax\/d\/[a-z0-9\-]+\/add-products/.test(action.url))		store = store.setIn(["loaders", "dashboard_addProducts"], loading);

			switch(action.actionType) {
				case AuthActionType.SubmitSignup:
				case AuthActionType.Authenticate:
				case AuthActionType.SubmitFbLogin:
				case AuthActionType.SubmitGoogleLogin:
				case AuthActionType.SubmitAccountLink:
				case AuthActionType.ResetPasswordRequest:
				case AuthActionType.NewPassword:
				case AuthActionType.ResendValidationLink:
					store = store.set('loaders', store.get('loaders').set('auth', loading))
					break;

				case UserDataActionType.SaveContactDetails:
					store = store.set('loaders', store.get('loaders').set('carts', loading));
					store = store.set('loaders', store.get('loaders').set('userData', loading));
					break;

				case UserDataActionType.SavePassword:
				case UserDataActionType.DeleteAccount:
					store = store.set('loaders', store.get('loaders').set('userData', loading));
					break;

				case UserCartActionType.UpdateCartItem:
					store = store.set('loaders', store.get('loaders').set('carts', loading));
					break;

				case UserAddressActionType.CreateUserAddress:
				case UserAddressActionType.UpdateUserAddress:
					store = store.set('loaders', store.get('loaders').set('edit_address', loading));
					break;

				case BrandingOptionActionType.CreateBrandingOption:
				case BrandingOptionActionType.UpdateBrandingOption:
				case BrandingOptionActionType.DeleteBrandingOption:
					store = store.set('loaders', store.get('loaders').set('edit_branding_option', loading));
					break;

			}
	}

	const defaultUIData = new UIDataStore
	return new UIDataStore(combineReducers<IUIDataStore, IUIDataStore>({
		toast: (store) => store ?? defaultUIData.get('toast'),
		loaders: (store) => store ?? defaultUIData.get('loaders'),
		validData: (store) => store ?? defaultUIData.get('validData'),
		apiErrors: (store) => store ?? defaultUIData.get('apiErrors'),
		scrollBlockers: (store) => store ?? defaultUIData.get('scrollBlockers'),
		intervals: (store) => store ?? defaultUIData.get('intervals'),
		designLab: DesignLabReducer,
		createUrl: (store) => store ?? defaultUIData.get('createUrl'),
	}, UIDataStoreFactory) (store, action));

}

const handleScrollBlockers = (list: Immutable.List<React.ReactNode>) => {
	// WARNING - Side Effect : Since AOW isn't a SPA I don't see another way to do this.
	// Using the Scrollblock list at least make the side-effects trackable/manageable.

	// TODO : Track all other side-effects on document.body.classList and use this instead
	document.body.classList.toggle('hide-overflow', list.count() > 0);
}

export default UIDataReducer