import { Button, Tooltip, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ConfirmUpscaledImage, UpscaleImage, UpscaledImagePayload } from '../../../UIData/_actions/DesignLabActions';
import { EditLayer } from '../../../UIData/_actions/DesignLabStoreActions';
import DesignLabLayerStore from '../../../UIData/_stores/DesignLab/DesignLabLayerStore';
import { PropertyPanel } from '../../_components/Layer';
import UpscaleDialog, { UpscaleState } from './UpscaleDialog';
import { useAppDispatch } from '../../../Common/_hooks/useAppDispatch';

const WarningTab = styled('div')(({theme}) => ({
    position: 'absolute',
    width: '54px',
    height: '54px',
    top: '11px',
    left: '11px',
    background: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    transition: 'opacity 250ms',
    overflow: 'hidden',
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
}));

const WarningIcon = styled('div')({
    background: '#e33250',
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    color: '#fff',
    fontSize: '24px',
    fontWeight: 600,
    textAlign: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 250ms',
    animationName: 'warning_pulse',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    '@keyframes warning_pulse': {
        from: {
            boxShadow: '0 0 0 0 rgba(227, 50, 80, .75)',
        },
        to: {
            boxShadow: '0 0 0 15px transparent',
        }
    },
});

type Props = {
    active?: boolean
    opened?: boolean
    onOpen?: () => void
    onClose?: () => void
    layer: DesignLabLayerStore
}

export default function UpscaleTool(props:Props) {
    const [t] = useTranslation('design-lab');
    const dispatch = useAppDispatch();
    const [upscaleState, setUpscaleState] = useState<UpscaleState>('none');
    const [upscaledImage, setUpscaledImage] = useState<UpscaledImagePayload|undefined>(undefined);
    const [nbAttempts, setNbAttempts] = useState(1);

    return <>
        { props.active ? <Tooltip
            title={t('Upscale image')}
        >
            <WarningTab 
                onClick={props.onOpen}
            >
                <WarningIcon>!</WarningIcon>
            </WarningTab>
        </Tooltip> : null }

        <PropertyPanel elevation={4} className={props.opened ? 'opened':''}>
            <h4 style={{
                margin: '0 0 10px 0',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                marginBottom: '5px',
                whiteSpace: 'nowrap',
            }}>
                <div 
                    style={{
                        background: '#f44336',
                        width: '18px',
                        height: '18px',
                        borderRadius: '18px',
                        color: '#fff',
                        lineHeight: '18px',
                        fontSize: '14px',
                        fontWeight: 600,
                        textAlign: 'center',
                        flexShrink: 0,
                        cursor: 'pointer',
                    }}
                >!</div>
                { t('Image too small') }
            </h4>
            <p style={{
                margin: '0 0 5px',
                fontSize: '14px',
            }}>{ t('Do you want to upscale it using our AI tool?') }</p>
            <div style={{
                display: 'flex'
            }}>
                <Button
                    size="small"
                    style={{
                        color: '#fff',
                        padding: '10px 20px',
                        minWidth: '0',
                        borderRadius: '40px'
                    }}
                    onClick={props.onClose}
                >{ t('No') }</Button>
                <Button 
                    style={{
                        color: '#fff',
                        padding: '10px 20px',
                        minWidth: '0',
                        borderRadius: '20px'
                    }}
                    onClick={() => {
                        props.onClose();
                        setUpscaleState('generating');
                        dispatch(UpscaleImage(
                            props.layer.get('fileid'),
                            1,
                            (data) => {
                                setUpscaleState('done');
                                setUpscaledImage(data);
                            },
                            () => {
                                setUpscaleState('none');
                            }
                        ));
                        setNbAttempts(1);
                    }}
                >{ t('Yes') }</Button>
            </div>
        </PropertyPanel>

        <UpscaleDialog
            upscaleState={upscaleState}
            upscaledImage={upscaledImage}
            onConfirm={() => {
                setUpscaleState('none');
                dispatch(ConfirmUpscaledImage(props.layer.get('fileid'), (image) => {
                    dispatch(EditLayer(props.layer.get('id'), {
                        fileid: image.fileid,
                        src: '/api/user/images/image/'+image.fileid,
                        originalWidth: image.width,
                        originalHeight: image.height,
                    }));
                }))
            }}
            onTryAgain={nbAttempts < 2 ? () => {
                setUpscaleState('generating');
                dispatch(UpscaleImage(
                    props.layer.get('fileid'),
                    2,
                    (data) => {
                        setUpscaleState('done');
                        setUpscaledImage(data);
                    },
                    () => {
                        setUpscaleState('none');
                    }
                ));

                setNbAttempts(2);
            } : undefined}
            onClose={() => setUpscaleState('none')}
        />
    </>
}