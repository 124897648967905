import Immutable from "immutable"

export interface IPaginationItemListStore {
	items: Immutable.OrderedSet<string>
	total: number
	valid: boolean
	loading: boolean
}

const defaultStore = Immutable.Record<IPaginationItemListStore>({
	items: Immutable.OrderedSet<string>(),
	total: 0,
	valid: false,
	loading: true,
});

export default class PaginationItemListStore extends defaultStore implements IPaginationItemListStore {} 