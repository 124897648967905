import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Drawer from '@mui/material/Drawer';
import * as Immutable from 'immutable';
import { useState } from 'react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import ViewCategoryStore from '../AppData/_stores/ViewCategoryStore';
import ViewProductStore from '../AppData/_stores/ViewProductStore';
import { FlexWrapper, PaddingWrapper } from '../Common/_components/Divs';
import Hidden from '../Common/_components/Hidden';
import { StyledLink } from '../Common/_components/Links';
import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import WebsiteStore from '../WebsiteStore';

type UrlParams =  { category1 ?: string , category2 ?: string };

export type Props = {}

const StyledAccordion = styled(Accordion)`
	background-color: unset;
	box-shadow: unset;
	border-bottom: 0.7px solid #afafaf;
	border-radius: 0;
	
	:last-child{
		border: none;
	}
	.MuiAccordionSummary-content{
		margin: 0;
	}
	.MuiAccordionSummary-root.Mui-expanded{
		min-height: 48px;
	}
	&.MuiAccordion-root:before {
		display: none;
	}
	&.MuiAccordion-root.Mui-expanded {
		margin: 0;
	}
	&.MuiAccordion-root.Mui-expanded:before{
		display: none;
	}
	.MuiAccordionSummary-root{
		padding: 0;
	}
`;

const EmptyAccordionElement = styled('div')({
	minHeight: '48px',
	lineHeight: '48px',
});

const StyledAccordionDetails = styled(AccordionDetails)({
	display: 'block',
	paddingLeft: '4px',

	'a:not(:last-child)': {
		'li': {
			marginBottom: '1rem',
		} 
	}
});

const StyledDrawer = styled(Drawer)({
	'.MuiDrawer-paperAnchorBottom': {
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
		margin: '0 auto',
		width: '95%',
		maxHeight: '85%',
	},

	'.drawer-content': {
		padding: '22px 20px',
	}
});

const ParentCategoryItem = styled('p')({
	margin: 0,
	fontWeight: 500,

	'&.selected': {
		color: '#F600BE',
		fontWeight: 600,
	}
});

const SubcategoryItem = styled('li')({
	listStyle: 'none',
	fontWeight: 500,

	'&.selected': {
		color: '#F600BE',
	}
});

const SectionTitle = styled('div')({
	'h4': {
		textTransform: 'uppercase',
		fontWeight: 600,
	}
});

const AllProductLink = styled('div')({
	backgroundColor: 'unset',
	boxShadow: 'unset',
	borderBottom: '0.7px solid #afafaf',
	padding: '12px 0',
	'a': {
		color: '#000',
	},
	'.MuiAccordionSummary-content': {
		margin: 0,
	},
	'.MuiAccordionSummary-root.Mui-expanded': {
		minHeight: '48px',
	},
	'&.MuiAccordion-root:before': {
		display: 'none',
	},
	'&.MuiAccordion-root.Mui-expanded': {
		margin: 0,
	},
	'&.MuiAccordion-root.Mui-expanded:before': {
		display: 'none',
	},
	'.MuiAccordionSummary-root': {
		padding: 0,
	}
});

export default function SelectCategory(props:Props) {
	const [displayDrawer, setDisplayDrawer] = useState(false);
	const [t] = useTranslation('products')
	const [tR] = useTranslation('routes');
    const params = useParams()
	const viewCategories = useSelector((state:WebsiteStore) => state.get('appData').get('viewCategories'));

	const renderLink = (category: ViewCategoryStore, selected: boolean, shouldCloseDrawer: boolean = false) => {
		let id_parent = category.get('id_parent');
		let has_parent = viewCategories.has(String(id_parent));
		let Element = (has_parent ? SubcategoryItem : ParentCategoryItem) as React.ElementType;
		return <Element className={selected ? "selected" : ""} onClick={() => shouldCloseDrawer && setDisplayDrawer(false)}>{category.get('name')}</Element>
	}

	const getContent = () => {
		const ct1 = params.category1;
		const ct2 = params.category2;

		return viewCategories.filter(category => category.get('id_parent') === null).entrySeq().map(([categoryId, parentCategory]) => {
			let has_children = !!viewCategories.filter(c => String(c.get('id_parent')) == categoryId).count();
			let selected = ct2 ? (!!viewCategories.filter(c => c.get('url_slug') == ct2 && String(c.get('id_parent')) == categoryId).count()) : ct1 == parentCategory.get('url_slug')

			let link_content = renderLink(parentCategory, ct1 === parentCategory.get('url_slug') && ct2 === undefined, !has_children)
			let element = has_children ? <AccordionSummary expandIcon={<ExpandMoreIcon />}>{link_content}</AccordionSummary> : <EmptyAccordionElement>{link_content}</EmptyAccordionElement>;

			return <StyledAccordion key={categoryId} expanded={selected}>
				<StyledLink to={tR('/products')+'/'+parentCategory.get('url_slug')}>{element}</StyledLink>
				{has_children ?
					<StyledAccordionDetails>{
						viewCategories.filter(category => String(category.get('id_parent')) === categoryId).entrySeq().map(([secondLayerId, secondLayer]) => {
							return <StyledLink key={secondLayerId} to={tR('/products')+'/'+parentCategory.get('url_slug')+'/'+secondLayer.get('url_slug')}>{renderLink(secondLayer, ct2 === secondLayer.get('url_slug'), true)}</StyledLink>
						})
					}</StyledAccordionDetails>
				:
					<></>
				}
			</StyledAccordion>
		})
	}

	return <>
			<Hidden only={['xs']}>
				<PaddingWrapper padding='0 1vw'>
					<PaddingWrapper pTop='2vh' pBottom='1vh'>
						<SectionTitle>
							<h4 style={{ margin: 0 }}>{t('Select Product')}</h4>
						</SectionTitle>
					</PaddingWrapper>
					<AllProductLink>
						<h4 style={{ margin: 0 }}>
							<Link to={tR('/products')}>{t('All Products')}</Link>
						</h4>
					</AllProductLink>
					{getContent()}
				</PaddingWrapper>
			</Hidden>
			<Hidden only={['sm', 'md', 'lg', 'xl']}>
				<div style={{padding: '15px'}}>
					<FlexWrapper justifyContent='space-between' alignItems='center' onClick={() => setDisplayDrawer(true)} >
						<SectionTitle>
							<h4 style={{ margin: 0 }}>{t('Select Product')}</h4>
						</SectionTitle>
						<i className='material-icons'>arrow_forward</i>
					</FlexWrapper>
				</div>
				<StyledDrawer anchor="bottom" open={displayDrawer} onClose={() => setDisplayDrawer(false)}>
					<div className="drawer-content">
						<SectionTitle>
							<h4 style={{ margin: 0 }}>{t('Select Product')}</h4>
						</SectionTitle>
						<AllProductLink>
							<h4 style={{ margin: 0 }}>
								<Link to={tR('/products')} onClick={() => setDisplayDrawer(false)}>{t('All Products')}</Link>
							</h4>
						</AllProductLink>
						{getContent()}
					</div>
				</StyledDrawer>
			</Hidden>
	</> 
}