// Couldn't for the life of me figure out how to actually replace the styled export correctly...
import { memo } from "react";
import { styled as muiStyled, useTheme } from "@mui/material"

const styled = (StyledComponent: any, ...args: any[]) => {
  const Component: any = muiStyled(StyledComponent, ...args)
  Component.attrs = (defaultProps: Function|Record<string, any>) =>
    muiStyled(
      memo((props) => {
        const theme = useTheme()

        const addedProps = typeof defaultProps === 'function' ? defaultProps({ theme, ...props }) : defaultProps;

        return <StyledComponent {...addedProps} {...props} />
      }),
      ...args
    )
  return Component
}

export * from "@mui/material/styles"
export default styled