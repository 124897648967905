import * as Immutable from 'immutable';

export interface IPreviewStore {
	id: number
	slug: string
	theorder: number
	url: string
}

const defaultPreviewStore = Immutable.Record<IPreviewStore>({
	id: 0,
	slug: "",
	theorder: 0,
	url: "",
});

export default class PreviewStore extends defaultPreviewStore implements IPreviewStore {}