import * as Immutable from 'immutable';

export interface IViewCategoryStore {
	id: number
	id_parent: number|null
	name: string
	slug: string
	url_slug: string
	description: string
	theorder: number
}

const defaultViewCategoryStore = Immutable.Record<IViewCategoryStore>({
	id: 0,
	id_parent: null,
	name: '',
	slug: '',
	url_slug: '',
	description: '',
	theorder: 0,
});

export default class ViewCategoryStore extends defaultViewCategoryStore implements IViewCategoryStore {}