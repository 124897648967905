import { useState } from 'react';
import * as React from 'react';
import { FormControl, InputLabel as MuiInputLabel, styled } from '@mui/material';
import LabelV2, { LabelV2Props } from './LabelV2';
import InputV2, { InputV2Props } from './InputV2';
import SelectBaseV2, { SelectBaseV2Props } from './SelectBaseV2';
import useId from '@mui/utils/useId';

const StyledSelect = styled('div')({
});

const SelectWrapper = styled('div')({
  height: '40px',
  width: '100%',
  borderRadius: '30px',
  backgroundColor: 'rgba(0, 0, 0, 0.06)',
  padding: '0 16px',
  display: 'flex',
  flexDirection: 'column',

  '.MuiSelect-icon': {
  }
});

let nextIdSuffix = 1

export type SelectV2Props = React.PropsWithChildren<{
  id?: string
  label?: React.ReactNode
  value: unknown
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean,
  error?: boolean
  InputLabelProps?: Omit<LabelV2Props, "children" | "ref">
  SelectBaseProps?: Omit<SelectBaseV2Props, "children" | "ref">
}>

export default function SelectV2(props: SelectV2Props) {
  const id = useId(props.id)

  const { SelectBaseProps, InputLabelProps, children, ...rest } = props;

  return <StyledSelect>
    {props.label ? <LabelV2
      htmlFor={id}
      error={props.error}
      {...InputLabelProps}
    >{props.label}</LabelV2> : <></>}
    <SelectWrapper>
      <SelectBaseV2
        error={props.error}
        id={id}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        {...SelectBaseProps}
      >{children}</SelectBaseV2>
    </SelectWrapper>
  </StyledSelect>
}