import * as Immutable from 'immutable';

import ListingStore from '../../MassProductSending/_stores/ListingStore';

export interface IDashboardStore {
	addProduct_listings: Immutable.Map<string, ListingStore>
	addProduct_selectedListing: string
}

const defaultStore = Immutable.Record<IDashboardStore>({
	addProduct_listings: Immutable.Map<string, ListingStore>(),
	addProduct_selectedListing: ""
});

export default class DashboardStore extends defaultStore implements IDashboardStore {}