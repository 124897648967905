import { data } from "jquery";
import { createAjaxAction } from "../../Common/_actions/AjaxAction";
import { UserRewardPayload } from "../_stores/UserRewardStore";
import { EditUserReward, EditManyUserRewards, RemoveUserReward } from "./UserRewardStoreActions";

export enum UserRewardActionType {
  FetchUserRewards = 'USER_REWARD_FETCH_USER_REWARDS',
}

export const FetchUserRewards = () => createAjaxAction<{userRewards: Record<string, UserRewardPayload>}>(UserRewardActionType.FetchUserRewards, {
	url: 'ajax/api/user/rewards',
	method: 'GET',
	preventDuplicates: true,
	onSuccess: (response, dispatch) => {
		dispatch(EditManyUserRewards(response.data.userRewards));
	},
});