import { ButtonTypeMap, ExtendButton, ExtendButtonTypeMap, Button as MuiButton, ButtonProps as MuiButtonProps, styled } from '@mui/material';
import { forwardRef } from 'react';


const StyledButton = styled(MuiButton)(({ theme }) => ({
  minWidth: 0,

  '&.MuiButton-sizeMedium': {
    padding: '8px',
  },

  '&.MuiButton-sizeLarge': {
    padding: '8px',
  }
}));

type Props = MuiButtonProps & {}

//TODO: Can't figure out how to make the types work here
export default forwardRef(function IconButton(props: Props, ref: React.Ref<any>) {
  return <StyledButton
    ref={ref}
    {...props}
  />
})