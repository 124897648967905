import * as Immutable from 'immutable';
import { PaginationItem, IPaginationStore} from './PaginationStore'

export type UserImagePayload = {
	id: number
	id_user: number
	id_user_image_status: number
	name: string
	fileid: string
	filename: string
	original_filename: string
	filesize: number
	width: number
	height: number
	date_added: string
}

interface IUserImageStore extends PaginationItem {
	id: string
	name: string
	filename: string
	original_filename: string
	fileid: string
	width: number
	height: number
}

const defaultUserImageStore = Immutable.Record<IUserImageStore>({
	id: undefined,
	name: undefined,
	filename: undefined,
	original_filename: undefined,
	fileid: undefined,
	width: 0,
	height: 0,
});

export class UserImageStore extends defaultUserImageStore implements IUserImageStore {}