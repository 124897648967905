import type UserRewardStore from '../../UserData/_stores/UserRewardStore'
import Coupon, { type CouponProps } from './Coupon'

type Props = Omit<CouponProps, 'img' | 'htmlText'> & {
  userReward: UserRewardStore
}

export default function UserRewardCoupon(props: Props) {
  const { userReward, ...otherProps } = props

  return <Coupon
    img={require('@resources/img/account/rewards/rewards/' + props.userReward.get('slug') + '.svg?url')}
    htmlText={props.userReward.get('name')}
    fullWidth={props.fullWidth}
    small={props.small}
    onClick={props.onClick}
    selected={props.selected}
    {...otherProps}
  />
}
