import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';
import { BlackCheckedIcon } from '../../../Common/_components/CheckBoxes';

const PRODUCT_ID = 125;
const VIEW_PRODUCT_ID = 146;

export default function StainlessSteelTumblerPage() {
  const [t] = useTranslation('page/products/stainless-steel-tumblers');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Stainless Steel Tumblers | Products')}
      description={t('Design and create your own custom printed Stainless Steel Tumblers featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#8fffff"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '48%',
      }}
      tag={t('The all-over print')}
      title={t('Stainless Steel Tumbler')}
      text={t('Meet your new favorite water bottle! The all-over print stainless steel tumbler combines practical design with personal flair. Printed on-demand and constructed from durable double-walled stainless steel with slide top opening and built-in straw, it’s perfect for toting any beverage you need throughout the day. ')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/stainless-steel-tumblers/banner-1-' + locale + '.png')}
      alt={t('Picture of Stainless Steel Tumbler')}
      details={[
        {
          img: require('@resources/img/pages/products/stainless-steel-tumblers/icon-3.svg?url'),
          alt: t('20oz icon'),
          text: t('Holds 20 oz of liquid.'),
        },
        {
          img: require('@resources/img/pages/products/stainless-steel-tumblers/icon-1.svg?url'),
          alt: t('Stainless steel icon'),
          text: t('Crafted from premium stainless steel, the sleek finish highly resists dents and scratches.'),
        },
        {
          img: require('@resources/img/pages/products/stainless-steel-tumblers/icon-2.svg?url'),
          alt: t('Straw icon'),
          text: t('Includes a plastic slide top cover and 2 straws (metal and plastic)'),
        },
        {
          img: require('@resources/img/pages/products/stainless-steel-tumblers/icon-4.svg?url'),
          alt: t('Dishwasher icon'),
          text: t('Durable and vibrant UV print won’t fade with use or wash. The stainless steel body is dishwasher safe.'),
        },
        {
          img: require('@resources/img/pages/products/stainless-steel-tumblers/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/stainless-steel-tumblers/gallery_0.jpg'),
      require('@resources/img/pages/products/stainless-steel-tumblers/gallery_1.jpg'),
      require('@resources/img/pages/products/stainless-steel-tumblers/gallery_2.jpg'),
      require('@resources/img/pages/products/stainless-steel-tumblers/gallery_3.jpg'),
      require('@resources/img/pages/products/stainless-steel-tumblers/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Stainless Steel <br/>Tumbler <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Stainless Steel Tumbler! At prices designed for reselling, Stainless Steel Tumbler made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
    />

    <SectionSizes
      productId={PRODUCT_ID}
      title={t('Stainless Steel Tumbler Sizing')}
      text={t('Water bottle measurements may vary to a small degree. Holds 20 oz of liquid. The base color of the tumbler is cool white. ')}
    >
      <img
        src={require('@resources/img/pages/products/stainless-steel-tumblers/measurement.svg?url')}
        style={{
          marginRight: 0,
        }}
      />
    </SectionSizes>

    <SectionBranding
      title={t('Brand your Stainless Steel Tumbler')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Stainless Steel Tumbler with these great products to create your own unique styles.')}
      productSlugs={['athletic-crop-top', 'yoga-shorts', 'headband']}
    />
  </div>
}
