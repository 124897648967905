import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardActions, CardContent, Chip, Grid, Stack, Typography } from '@mui/material'
import { type UserCreditCardStore } from '../../../UserData/_stores/UserCreditCardStore'
import DeleteCreditCardDialog from './DeleteCreditCardDialog'
import CardButton from '../../../Common/Buttons/CardButton'
import { DateTime } from 'luxon'
import CreditCardIcon from './CreditCardIcon'

interface Props {
  creditCard: UserCreditCardStore
}

export default function CreditCard(props: Props) {
  const [t] = useTranslation('account-v2')

  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)

  const onDeleteOpen = useCallback(() => {
    setDeleteDialogOpened(true)
  }, [])

  const onDeleteClose = useCallback(() => {
    setDeleteDialogOpened(false)
  }, [])

  // Cards expire at the end of the month, but expiration is saved as 1st of the month
  const expiration = useMemo(() => {
    return DateTime.fromISO(props.creditCard.get('expiration')).endOf('month')
  }, [props.creditCard])

  return <Grid item xs={12} md={6} lg={4}>
    <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <CreditCardIcon brand={props.creditCard.get('brand')}/>
            <Typography variant="h3">&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {props.creditCard.get('last4')}</Typography>
          </Stack>
          <Stack spacing={1} alignItems="flex-start">
            <Typography variant="body1">{ t('Expiration: {{date}}', { date: expiration.toLocaleString({ month: '2-digit', year: '2-digit' }) }) }</Typography>
            { expiration.diffNow().as('months') < 0
              ? <Chip
                  variant="square-filled"
                  size="small"
                  color="error"
                  label={t('Expired')}
                />
              : (expiration.diffNow().as('months') < 2
                  ? <Chip
                    variant="square-filled"
                    size="small"
                    color="warning"
                    label={t('Expires soon')}
                  />
                  : null)}
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <CardButton
          color="secondary"
          onClick={onDeleteOpen}
        >{t('Delete')}</CardButton>
      </CardActions>
    </Card>

    <DeleteCreditCardDialog
      creditCard={props.creditCard}
      opened={deleteDialogOpened}
      onClose={onDeleteClose}
    />
  </Grid>
}
