import { useCallback, useState } from 'react'
import * as React from 'react'
import DialogButton from '../../Common/Buttons/DialogButton'
import Dialog from '../../Common/Dialog/Dialog'
import DialogActions from '../../Common/Dialog/DialogActions'
import DialogContent from '../../Common/Dialog/DialogContent'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { TextField } from '@mui/material'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import { CreateCollection } from '../../UserData/_actions/CollectionActions'

interface Props {
  opened: boolean
  onClose: () => void
  onAdd: (collectionId: number) => void
}

export default function NewCollectionDialog(props: Props) {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()

  const [name, setName] = useState('')

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }, [])

  const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(CreateCollection(name).set({
      onSuccess: (response) => {
        props.onAdd(response.data.item.id)
        props.onClose()
      }
    }))
  }, [props.onClose, name])

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <form onSubmit={onSubmit}>
      <DialogTitle>{t('New collection')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          variant="filled"
          label={t('Name')}
          value={name}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <DialogButton
          color="secondary"
          onClick={props.onClose}
        >{t('Cancel')}</DialogButton>
        <DialogButton
          color="primary"
          type="submit"
          disabled={name === ''}
        >{t('Confirm')}</DialogButton>
      </DialogActions>
    </form>
  </Dialog>
}
