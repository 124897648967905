import { Button, styled } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import * as React from 'react';
import { Trans } from 'react-i18next';
import Hidden from '../../Common/_components/Hidden';
import ModalContainer from '../../Common/_containers/ModalContainer';
import PromptContainer from '../../Common/_containers/PromptContainer';
import EditProductContainer from '../_containers/EditProductContainer';
import EditSelectedProductsContainer from '../_containers/EditSelectedProductsContainer';
import PublishingContainer from '../_containers/PublishingContainer';
import SelectProductContainer from '../_containers/SelectProductContainer';
import SuccessContainer from '../_containers/SuccessContainer';

export type Props = {
	resetProductListings: () => void
	fetchUserStorePages: (store:string) => void
}

export type State = {
	dialogOpened: boolean
	currentStep: number
	unlistenCallback: Function
	closeModalPrompt : boolean
	store: string
}

const MassModalContainer = styled(ModalContainer)({
	bottom: '0',
	margin: '0 auto',
	transformOrigin: '50% 50%',
    animationName: 'grow',
	maxHeight: 'calc(93% - 45px)',
	top: '5% !important',
	width: '960px',
	maxWidth: '960px',
	borderRadius: '30px',

	'@media (max-width: 1200px)': {
		width: '90%',
	},

	'.modal-content': {
		position: 'static',
	    margin: '0',
	    alignSelf: 'flex-start',
		width: '100%',
		height: '100%',
		padding: '0',
	},

	'&.height-fit-content': {
		//height: 'fit-content',
        bottom: 'unset',
        maxHeight: 'unset',

		'.modal-content': {
			position: 'initial',
            maxHeight: 'calc(93% - 45px)',
            height: 'fit-content',
		} 
	}
});

const MassDrawer = styled(Drawer)({
	'.MuiDrawer-paperAnchorBottom': {
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
		margin: '0 auto',
		width: '95%',
		maxHeight: '85%',
		overflow: 'hidden',
	},

	'.drawer-content': {
		padding: '22px 20px',
		overflow: 'auto',
	}
});
const AddProductButton = styled('button')({
	background: '#E41EA1',
	borderRadius: '100px',
	minHeight: '40px',
	color: '#ffffff',
	border: 'none',
	minWidth: '184px',
	width: '100%',

	'&:hover': {
		background: '#e83cae',
	},

	'&:focus': {
		background: '#e62da7',
	}
});

const CloseModalDiv = styled('div')({
	position: 'absolute',
	top: '20px',
	right: '20px',
});

const CloseModalButton = styled(Button)({
	'&:focus': {
		backgroundColor: 'unset',
	}
});

const T = ({children, ...otherProps}:any) => <Trans ns="dashboard" {...otherProps}>{children}</Trans>

export default class MassProductButton extends React.Component<Props, State>{

	private steps = ['SelectProduct', 'EditSelectedProducts', 'EditDetails', 'Publishing', 'Success'];
	private routes = ['selectproduct', 'editselectedproducts', 'editdetails', 'publishing', 'success'];

	public state = {
		currentStep: 0,
		dialogOpened: false,
		closeModalPrompt: false,
		store: "",
	} as State

	componentDidMount() {
		//Grab store slug from url
		let path = window.PAGE_ROUTE.split("/");
		this.setState({
			store: path[1]
		});
	}

	componentDidUpdate(prevProps:Props, prevState:State) {
		if(this.state.store !== prevState.store && this.state.store === 'squarespace') {
			this.props.fetchUserStorePages(this.state.store);
		}
	}

	private setStep = (step: number) => {
		this.setState({currentStep: step})
	} 
	private handleCloseModal = () => {
		this.setState({closeModalPrompt : false})
		this.setStep(0)
		this.setState({dialogOpened : false})
		document.body.classList.remove('hide-overflow');
		// let massModal = document.body.querySelector('.mass-modal')
		// if(massModal.classList.contains('height-fit-content')) massModal.classList.remove('height-fit-content') 

		this.props.resetProductListings();
	}
	private displayPrompt = () => {
		this.setState({closeModalPrompt : true})
	}

	render() {
		
		const Content = [
			SelectProductContainer,
			EditSelectedProductsContainer,
			EditProductContainer,
			PublishingContainer,
			SuccessContainer,
		][this.state.currentStep] as React.ComponentType<any>


		const body = (
			<>
				<Content 
					massSending 
					store={this.state.store} 
					index={this.state.currentStep} 
					handleStep={this.setStep} 
					handleCloseModal={this.handleCloseModal}
				/>
				<CloseModalDiv >
					<CloseModalButton onClick={() => (this.state.currentStep < this.steps.length - 2 ? this.displayPrompt() : this.handleCloseModal())}>
						<i className="material-icons">close</i>
					</CloseModalButton>
				</CloseModalDiv>
			</>

		)
		return <>
			<AddProductButton onClick={() => {
				this.setState({
					dialogOpened: true
				});
			}}><i className="material-icons" style={{verticalAlign: "top"}}>add</i><T> Add product</T></AddProductButton>


			<div >
				<Hidden mdDown>
					<MassModalContainer className={"modal react-modal  light-mode mass-modal" + (this.state.currentStep > 2 ? " height-fit-content" : "" )} darken opened={this.state.dialogOpened} animate persist
						content={body}
					/>
				</Hidden>
				<Hidden mdUp>
					<MassDrawer anchor="bottom" open={this.state.dialogOpened} className="drawer-root">
						<div className="drawer-content">
							{body}
						</div>
					</MassDrawer>
				</Hidden>
			</div>
			
			<PromptContainer
				opened={this.state.closeModalPrompt}
				title={<T>Are you sure?</T>}
				message={<T>You will lose your changes if you close this dialog</T>}
				onClose={() => this.setState({closeModalPrompt : false})}
				actions={{
					"Close": this.handleCloseModal
				}}
				dismissible
			/>
		</>;
	}
}