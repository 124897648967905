import * as Immutable from 'immutable';

export interface IToast {
	message: string
	new: boolean
}

const defaultStore = Immutable.Record<IToast>({
	message: "",
	new: false
});

export default class UIToastStore extends defaultStore implements IToast {}