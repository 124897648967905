import { DialogContent as MuiDialogContent, DialogContentProps as MuiDialogContentProps, styled } from '@mui/material';


const StyledDialogContent = styled(MuiDialogContent)({
    marginTop: 10,
});

type Props = MuiDialogContentProps & {}

export default function DialogContent(props: Props) {
    return <StyledDialogContent {...props} />
}