import { Avatar, Button, ListItemAvatar, ListItemText, Menu, MenuItem } from '@mui/material'
import { AddRounded as AddRoundedIcon, CheckRounded as CheckRoundedIcon } from '@mui/icons-material'
import { type MouseEvent, useCallback, useState } from 'react'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { useTranslation } from 'react-i18next'
import { type BrandingOptionStore } from '../../UserData/_stores/BrandingOptionStore'

interface Props {
  typeId: number
  onChange: (idBrandingOption?: number) => void
  value?: number
}

export default function ProductBrandingOption(props: Props) {
  const [t] = useTranslation('account-v2')

  const type = useAppSelector(state => state.get('appData').get('brandingOptionTypes').get('1'))
  const brandingOptions = useAppSelector(state => state.get('userData').get('brandingOptions')
    .filter(brandingOption => brandingOption.get('id_type') === type?.get('id')), {
    equalityFn: (a, b) => a.equals(b)
  })
  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))

  const [opened, setOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const onRemove = useCallback(() => {
    props.onChange(undefined)
  }, [props.onChange])

  const onMenuOpen = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setOpened(true)
  }, [])

  const onMenuClose = useCallback(() => {
    setOpened(false)
  }, [])

  const renderOption = useCallback((brandingOption: BrandingOptionStore) => {
    return <MenuItem
      key={brandingOption.get('id')}
      onClick={() => {
        props.onChange(brandingOption.get('id'))
        setOpened(false)
      }}
    >
      <ListItemAvatar>
        <Avatar src={brandingOption.get('file_url')} />
      </ListItemAvatar>
      <ListItemText>{brandingOption.get('name')}</ListItemText>
    </MenuItem>
  }, [props.onChange])

  // Don't display if user doesn't have any
  if (!type || brandingOptions.count() === 0) return null

  return <>
    <Button
      variant={props.value ? 'contained' : 'dashed'}
      color={props.value ? 'primary' : 'secondary'}
      size="medium"
      startIcon={props.value ? <CheckRoundedIcon /> : <AddRoundedIcon />}
      onClick={props.value ? onRemove : onMenuOpen}
      disabled={cartLoader}
    >{props.value ? t('{{name}} added', { name: type.get('name') }) : t('Add {{name}}', { name: type.get('name') })}</Button>

    <Menu
      open={opened}
      onClose={onMenuClose}
      anchorEl={anchorEl}
    >
      { brandingOptions.valueSeq().map(renderOption)}
    </Menu>
  </>
}
