import { type ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ItemList from '../Common/ItemList/ItemList'
import { Button, Container, Divider, Grid, MenuItem, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { Circle as CircleIcon, RefreshRounded as RefreshRoundedIcon } from '@mui/icons-material'
import { type CartStatus } from '../UserData/_stores/UserCartStore'
import type UserCartStore from '../UserData/_stores/UserCartStore'
import { LIST_CART_STATUSES } from '../UserData/_stores/UserCartStore'
import SearchBar from '../Common/ItemList/SearchBar'
import { useParams, useSearchParams } from 'react-router-dom'
import SelectV2 from '../Common/Form/SelectV2'
import useOrderStatusProps from '../Common/_hooks/Order/useOrderStatusProps'
import OrderRow from '../Account/Orders/OrderRow'
import AccountHeader from '../Account/AccountHeader'
import { Link } from 'react-router-dom'

const LIMIT = 20
const SEARCH_FIELDS = ['search', 'statuses','stores']
const LIST_STATUS_FILTERS = LIST_CART_STATUSES.filter(value => !['cart'].includes(value))

export default function DashboardOrderList() {
  const [t] = useTranslation('account-v2')
  const [tR] = useTranslation('routes')
  const [searchParams] = useSearchParams()
  const params = useParams<{storeSlug: string}>();

  const paramSearch = searchParams.get('search')
  const paramStatuses = searchParams.get('statuses')
  const [search, setSearch] = useState({
    search: paramSearch ? paramSearch.split(',') : [],
    statuses: paramStatuses ? paramStatuses.split(',') : [],
    stores: params.storeSlug ? [params.storeSlug] : [],
  })
  const [selectedStatus, setSelectedStatus] = useState<CartStatus | 'all'>(paramStatuses ? paramStatuses as CartStatus : 'all')

  useEffect(() => {
    setSearch(search => ({
      ...search,
      stores: params.storeSlug ? [params.storeSlug] : []
    }))
  }, [params.storeSlug])

  const searchText = useMemo(() => {
    return search.search.join(',')
  }, [search.search])

  const loadingItems = useMemo(() => {
    const listSkeletons = []

    for (let i = 0; i < LIMIT; i++) {
      listSkeletons.push(<Skeleton key={i} variant="text" height={64} />)
    }

    return listSkeletons
  }, [])

  const renderOrder = useCallback((item: UserCartStore, index: number) => {
    return <OrderRow order={item} index={index} />
  }, [])

  const onChangeStatus = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedStatus(e.target.value === 'all' ? 'all' : e.target.value as CartStatus)
    setSearch(search => ({
      ...search,
      statuses: e.target.value === 'all' ? [] : [e.target.value]
    }))
  }, [])

  const renderStatus = useCallback((status: CartStatus) => {
    const { text, color } = useOrderStatusProps(status)
    return <MenuItem key={status} value={status}>
      <Stack direction="row" spacing={1}>
        <CircleIcon color={color}/>
        <span>{text}</span>
      </Stack>
    </MenuItem>
  }, [])

  const onChangeSearchText = useCallback((value: string) => {
    setSearch(search => ({
      ...search,
      search: value.split(',')
    }))
  }, [])

  const renderList = useCallback((items: JSX.Element | null, links: JSX.Element | null, loading: boolean) => {
    return <>
      <Stack>
        <div>
          <Stack
            direction="row"
            spacing={4}
            sx={{
              py: 2,
              px: 2
            }}
          >
            <div style={{ width: '100px' }}>
              <Typography variant="h4">{t('Order')}</Typography>
            </div>
            <div style={{ width: '220px' }}>
              <Typography variant="h4">{t('Date')}</Typography>
            </div>
            <div style={{ flexGrow: 1 }}>
              <Typography variant="h4">{t('Ship to')}</Typography>
            </div>
            <div style={{ width: '130px' }}>
              <Typography variant="h4">{t('Total')}</Typography>
            </div>
            <div style={{ width: '150px' }}>
              <Typography variant="h4">{t('Status')}</Typography>
            </div>
          </Stack>
        </div>
        <Divider variant="bold" />
        {loading
          ? <Stack sx={{ px: 2 }}>{ loadingItems }</Stack>
          : (items ?? <Grid item xs={12}>
            <Stack sx={{ p: 2 }}>{t('No Results')}</Stack>
          </Grid>
            )
        }
      </Stack>

      {links}
    </>
  }, [t, loadingItems])

  return <Container style={{padding: '0 0.75rem'}}>
    <AccountHeader
      title={t('Orders')}
      actions={<Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={tR('/d/{{store}}/refresh-orders', {store: params.storeSlug})}
          startIcon={<RefreshRoundedIcon/>}
          reloadDocument
        >{t('Refresh orders')}</Button>
        <SelectV2
          value={selectedStatus}
          onChange={onChangeStatus}
        >
          <MenuItem value="all">{t('All statuses')}</MenuItem>
          { LIST_STATUS_FILTERS.map(renderStatus)}
        </SelectV2>
      </Stack>}
    />
    <SearchBar
      placeholder={t('Search by order number or name...')}
      value={searchText}
      onChange={onChangeSearchText}
    />
    <Paper elevation={0}>
      <ItemList
        dataType="orders"
        item={renderOrder}
        searchQuery={search}
        searchFields={SEARCH_FIELDS}
        limit={LIMIT}
        render={renderList}
      />
    </Paper>
  </Container>
}
