import DineroFactory, { Dinero } from "dinero.js"
import ProductOptionStore from "../../AppData/_stores/ProductOptionStore"
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react"
import Immutable from "immutable"
import { InputAdornment, TableCell, TableRow, TextField, styled } from "@mui/material"
import { PricingType } from "./SectionPricing"

const StyledCell = styled(TableCell)(({theme}) => ({
  backgroundColor: '#fff',
  borderLeft: '1px solid #cbecf8',
  borderRight: '1px solid #cbecf8',
  borderBottom: '1px solid #cbecf8',
  fontSize: '1rem',

  '&:first-of-type': {
    borderLeft: 0,
  },
  '&:last-of-type': {
    borderRight: 0,
  }
}))

const StyledInputCell = styled(StyledCell)(({theme}) => ({
  padding: theme.spacing(1),

  'p': {
    margin: 0,
  }
}))

const StyledRow = styled(TableRow)(({theme}) => ({
  '&:last-of-type': {
    [`${StyledCell}`]: {
      borderBottom: 0,
    },
    [`${StyledInputCell}`]: {
      borderBottom: 0,
    }
  }
}))

interface Props {
  basePriceDropship: Dinero.Dinero
  basePriceWholesale: Dinero.Dinero
  basePriceRetail: Dinero.Dinero
  options: Immutable.Set<ProductOptionStore>
  name?: string
  showOptionNames?: boolean
}

export default function SectionPricingOption(props: Props) {
  const pricingType = useContext(PricingType);

  const [priceDropship, priceWholesale, priceRetail] = useMemo(() => {
    //Base product prices
    let tmpPriceDropship = props.basePriceDropship,
        tmpPriceWholesale = props.basePriceWholesale,
        tmpPriceRetail = props.basePriceRetail;

    //Add options
    props.options.forEach(option => {
      tmpPriceDropship = tmpPriceDropship.add(option.get('price_dropship').toDinero())
      tmpPriceWholesale = tmpPriceWholesale.add(option.get('price_wholesale').toDinero())
      tmpPriceRetail = tmpPriceRetail.add(option.get('price_retail').toDinero())
    })

    return [
        tmpPriceDropship,
        tmpPriceWholesale,
        tmpPriceRetail,
    ];
  }, [props.options, props.basePriceDropship, props.basePriceWholesale, props.basePriceRetail]);

  const [priceInput, setPriceInput] = useState(priceRetail.toRoundedUnit(2).toFixed(2) ?? '0.00');

  const price = useMemo(() => {
    let parsedInput = parseFloat(priceInput);
    parsedInput = isNaN(parsedInput) ? 0 : Math.round(parsedInput * 100);

    return DineroFactory({ amount: parsedInput, currency: priceDropship.getCurrency() })
  }, [priceInput, priceDropship]);

  useEffect(() => {
    setPriceInput(priceRetail.toRoundedUnit(2).toFixed(2));
  }, [priceRetail]);

  const profitDropship = useMemo(() => {
    return price.subtract(priceDropship);
  }, [price, priceDropship]);

  const profitWholesale = useMemo(() => {
    return price.subtract(priceWholesale);
  }, [price, priceWholesale]);

  const onChangePrice = useCallback((e:ChangeEvent<HTMLInputElement>) => {
    setPriceInput(e.target.value)
  }, [])

  const onBlurPrice = useCallback(() => {
    setPriceInput(price.toRoundedUnit(2).toFixed(2))
  }, [price])

  const optionNames = useMemo(() => {
    return props.options.valueSeq().map(option => option.get('name')).join(' / ')
  }, [props.options])

  return <StyledRow>
    { props.name ? <StyledCell sx={{minWidth: 100}}>{props.name}</StyledCell>  
      : props.showOptionNames ? <StyledCell sx={{minWidth: 100}}>{optionNames}</StyledCell> : null 
    }
    {pricingType === 'reseller' ? <StyledInputCell width={140}>
      <TextField
        type="number"
        inputProps={{
          min: 0,
          step: 0.01,
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
        value={priceInput}
        onChange={onChangePrice}
        onBlur={onBlurPrice}
      />
    </StyledInputCell> : null }
    <StyledCell width={130} align="center">{priceDropship.toFormat()}</StyledCell>
    {pricingType === 'reseller' ? <StyledCell width={120} align="center"><strong>{profitDropship.toFormat()}</strong></StyledCell> : null }
    <StyledCell width={130} align="center">{priceWholesale.toFormat()}</StyledCell>
    {pricingType === 'reseller' ? <StyledCell width={120} align="center"><strong>{profitWholesale.toFormat()}</strong></StyledCell> : null }
  
  </StyledRow>
}