import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react';
import Dialog from '../Common/Dialog/Dialog';
import DialogContent from '../Common/Dialog/DialogContent';
import { Alert, Button, Collapse, Grid, Stack, TextField, Typography } from '@mui/material';
import DialogTitle from '../Common/Dialog/DialogTitle';
import { useTranslation } from 'react-i18next';
import SendFD from '../Common/Utils/SendFD';
import { useAppDispatch } from '../Common/_hooks/useAppDispatch';
import { ResetPasswordRequest } from './_actions/AuthActions';
import { useAppSelector } from '../Common/_hooks/useAppSelector';


export default function ForgotPasswordForm() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('auth');

  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('auth'))

  const [email, setEmail] = useState('');
  const [sent,setSent] = useState(false)

  const validEmail = useMemo(() => email !== '', [email]);

  const onChangeEmail = useCallback((e:ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim())
  }, [])

  const resetFormSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();

    dispatch(ResetPasswordRequest(email).set({
      onSuccess: () => {
        setSent(true)
      }
    }))
  }, [email]);

  return <>
    <Collapse in={!sent}>
      <form onSubmit={resetFormSubmit}>
        <Stack spacing={2} alignItems="flex-start">
          <Typography variant="body1">{t('Follow the instructions in the email we\'ll send you to reset your password.')}</Typography>

          <TextField
            label={t('Email')}
            value={email}
            error={!validEmail}
            onChange={onChangeEmail}
            disabled={!!loader}
          />
          <Button
            color="accent"
            variant="contained"
            size="large"
            disabled={!validEmail || !!loader}
            type="submit"
          >{t('Send Reset Link')}</Button>
        </Stack>
      </form>
    </Collapse>

    <Collapse in={sent}>
      <Alert
        severity="success"
      >{t('We sent an email to {{email}} with the instructions on how to create a new password.', {email: email})}</Alert>
    </Collapse>
  </>
}