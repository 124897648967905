import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import WebsiteStore from "../../WebsiteStore";
import { Layer as LayerNode, Rect, Stage as StageNode, Text, Image as ImageNode, Group as GroupNode } from "react-konva";
import KonvaPatternImage from "../KonvaNode/KonvaPatternImage";
import KonvaImage from "../KonvaNode/KonvaImage";
import { isBrowser, isNode } from "browser-or-node";
import LayerRenderer from "./LayerRenderer";
import { Layer } from 'konva/lib/Layer';
import rotatePoint from "../../Common/Utils/Math/rotatePoint";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";

type Props = {
  subproduct: string | null,
  subproductRef?: string,
  scene: string,
  part: string,
  partData: Record<string, any>,
  subproductRef_partData?: Record<string, any>,
  inputType: 'lab',
  outputType: 'texture' | 'render',
  labData: Record<string, any>,
}

export default function PartRenderer(props: Props) {
  const visibleLayers = useAppSelector(state => state.get('UIData').get('designLab').get('layers'));
  const backgroundColor = useAppSelector(state => state.get('UIData').get('designLab').get('backgroundColor'));
  const partRef = useRef<Layer|null>(null);

  const partData = props.partData[props.outputType];

  let clipX = 0,
    clipY = 0,
    clipWidth = partData.width,
    clipHeight = partData.height,
    rotation = partData.rotation ? partData.rotation : 0;

  //Crop the part so it doesn't overflow into other parts
  if (partData.crop) {
    clipX = partData.crop.x;
    clipY = partData.crop.y;
    clipWidth = partData.crop.width;
    clipHeight = partData.crop.height;
  }

  //Calculate bounding box based on rotation
  const origin = { x: 0, y: 0 };
  const a = rotatePoint(origin, { x: 0, y: 0 }, rotation);
  const b = rotatePoint(origin, { x: 0 + clipWidth, y: 0 }, rotation);
  const c = rotatePoint(origin, { x: 0 + clipWidth, y: 0 + clipHeight }, rotation);
  const d = rotatePoint(origin, { x: 0, y: 0 + clipHeight }, rotation);
  const boundingBox = {
    left: Math.min(a.x, b.x, c.x, d.x),
    top: Math.min(a.y, b.y, c.y, d.y),
  };

  //Add bounding box offset to our position
  let x = partData.x - boundingBox.left;
  let y = partData.y - boundingBox.top;

  //I/O scaling
  let scaleX = props.partData[props.outputType].width / props.partData[props.inputType].width;
  let scaleY = props.partData[props.outputType].height / props.partData[props.inputType].height;

  const renderLayer = useCallback((layerId: string) => {
    return <LayerRenderer
      key={layerId}
      layerId={layerId}
      subproduct={props.subproduct}
      subproductRef={props.subproductRef}
      scene={props.scene}
      part={props.part}
      partData={props.partData}
      subproductRef_partData={props.subproductRef_partData}
      inputType={props.inputType}
      outputType={props.outputType}
      labData={props.labData}
    />
  }, [props.labData, props.subproduct, props.subproductRef, props.subproductRef_partData, props.partData, props.scene, props.part, props.inputType, props.outputType])

  return <LayerNode
    ref={partRef}
    x={x}
    y={y}
    offsetX={clipX}
    offsetY={clipY}
    clipX={clipX}
    clipY={clipY}
    clipWidth={clipWidth}
    clipHeight={clipHeight}
    rotation={rotation}
    listening={false}
  >
    {!props.labData.transparent_texture ? <Rect
      width={partData.width}
      height={partData.height}
      x={0}
      y={0}
      fill={backgroundColor ? backgroundColor : "#fff"}
      listening={false}
    /> : null}
    <GroupNode
      scaleX={scaleX}
      scaleY={scaleY}
      listening={false}
    >
      {visibleLayers.keySeq().map(renderLayer)}
    </GroupNode>
  </LayerNode>
}