import { Box, Button, Chip, Container, Grid, Stack, Typography, styled } from "@mui/material";
import { PublicRounded as PublicRoundedIcon } from "@mui/icons-material"
import PreferencesDialog from "./PreferencesDialog";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../Common/_hooks/useAppSelector";
import { Link } from "react-router-dom";
import classNames from "classnames";
import ViewCategoryStore from "../AppData/_stores/ViewCategoryStore";
import ProductNavImage from "./ProductNavImage";
import ErrorBoundary from "../Common/_components/ErrorBoundary";

const NavText = styled('span')(({ theme }) => ({
  borderBottom: '4px solid transparent',
  paddingTop: '4px',
  color: '#444',
  lineHeight: 1,
  textDecoration: 'none',
}))

const Subnav = styled('div')(({theme}) => ({
  boxShadow: '0 -4px 50px rgba(0, 0, 0, 0.1)',
  clipPath: 'inset(0 -100px -100px -100px)',
  position: 'absolute',
  top: '52px',
  left: 0,
  zIndex: 1000,
  display: 'none',
  cursor: 'default',
  width: '100vw',
  maxWidth: '1280px',
}))

const SubnavBody = styled(Stack)(({theme}) => ({
  padding: theme.spacing(3),
  backgroundColor: '#f4f4f4',
}))

const SubnavSection = styled('div')(({theme}) => ({
  
}))

const SubnavUl = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
}))

const SubnavLi = styled('li')(({ theme }) => ({
  '> a': {
    display: 'block',
    fontSize: '.9rem',
    color: '#444',
    textDecoration: 'none',
    padding: theme.spacing(1, 0),

    '&:hover': {
      textDecoration: 'underline',
    }
  }
}))

const SubnavHeaderText = styled('span')(({theme}) => ({
  borderBottom: '2px solid transparent',
  paddingTop: '2px',
  lineHeight: 1,
}))

const SubnavHeaderLi = styled('div')(({theme}) => ({
  color: '#fff',
  padding: theme.spacing(2),
  fontSize: '.9rem',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',

  '&:after': {
    content: '""',
    borderBottom: '10px solid #f4f4f4',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    bottom: 0,
    left: '50%',
    marginLeft: '-10px',
    position: 'absolute',
    display: 'block',
    opacity: 0,
  },

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 1),
  },

  '&.active': {
    [`${SubnavHeaderText}`]: {
      borderColor: '#fff',
    },

    '&:after': {
      opacity: 1,
    }
  }
}))

const NavLink = styled(Link)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'none',

    [`${NavText}`]: {
      borderColor: '#ffd0ec',
    }
  }
}))

const NavItem = styled('span')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  [`${NavLink}`]: {
    padding: 0,
  },

  '&:hover': {
    [`${NavText}`]: {
      borderColor: '#ffd0ec',
    },
    [`${Subnav}`]: {
      display: 'block',
    }
  }
}))

const ProductImgWrapper = styled('div')(({theme}) => ({
  marginBottom: theme.spacing(1),
  borderRadius: '50%',
  overflow: 'hidden',
  width: '100px',
  height: '100px',
}))

const ProductImg = styled(ProductNavImage)(({theme}) => ({
  transform: 'scale(1)',
  transition: 'transform 150ms',
}))

const ProductText = styled('span')(({theme}) => ({
  fontSize: '.9rem',
  textAlign: 'center',
}))

const ProductLink = styled(Link)(({theme}) => ({
  width: '148px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  color: '#444',
  textDecoration: 'none',
  position: 'relative',

  '&:hover': {
    textDecoration: 'none',

    [`${ProductImg}`]: {
      transform: 'scale(1.2)',
    }
  }
}))

const NewTag = styled('div')(({theme}) => ({
  position: 'absolute',
  top: 0,
  left: '65%',
  padding: theme.spacing(0, 0.5),
  backgroundColor: theme.palette.accent.main,
  color: theme.palette.accent.contrastText,
  fontSize: '.7rem',
  fontWeight: '500',
  textTransform: 'uppercase',
  lineHeight: '1.5',
}))


type NavProduct = {
  name: string,
  slug: string,
  urlSlug: string,
  isNew: boolean
}

export default function DesktopNav() {
  const [t] = useTranslation('header')
  const [tR] = useTranslation('routes')

  const currency = useAppSelector(state => state.get('userData').get('prefs').get('currency'));
  const lang = useAppSelector(state => state.get('userData').get('prefs').get('lang'));
  const viewCategories = useAppSelector(state => state.get('appData').get('viewCategories'))
  const viewProducts = useAppSelector(state => state.get('appData').get('viewProducts'))

  const [selectedCategoryId,setSelectedCategoryId] = useState(viewCategories.first()?.get('id'))
  const [prefDialogOpened, setPrefDialogOpened] = useState(false);

  useEffect(() => {
    if(selectedCategoryId !== undefined) return

    setSelectedCategoryId(viewCategories.first()?.get('id'))
  }, [viewCategories])

  const onPrefDialogOpen = useCallback(() => {
    setPrefDialogOpened(true)
  }, [])

  const onPrefDialogClose = useCallback(() => {
    setPrefDialogOpened(false)
  }, [])

  const infoNav = useMemo(() => {
    return [
      {
        'slug': 'general',
        'name': t('General'),
        'items': [
          {
            'url': tR('drop-shipping'),
            'name': t('What is Drop Shipping'),
          },
          {
            'url': tR('wholesale'),
            'name': t('Wholesale'),
          },
          {
            'url': tR('custom-labels-and-packaging'),
            'name': t('Custom Labels & Packaging'),
          },
          {
            'url': tR('info/sample-packs'),
            'name': t('Order sample packs'),
          },
        ],
      },
      {
        'slug': 'general2',
        'name': ' ',
        'items': [
          {
            'url': tR('info/production-and-shipping'),
            'name': t('Production and Shipping'),
          },
          {
            'url': tR('info/pricing'),
            'name': t('Pricing'),
          },
          {
            'url': tR('info/design-guidelines'),
            'name': t('Design Guidelines'),
          },
        ],
      },
      {
        'slug': 'integrations',
        'name': t('Integrations'),
        'items': [
          {
            'url': tR('drop-shipping/etsy'),
            'name': t('Etsy'),
          },
          {
            'url': tR('drop-shipping/shopify'),
            'name': t('Shopify'),
          },
          {
            'url': tR('drop-shipping/squarespace'),
            'name': t('Squarespace'),
          },
          {
            'url': tR('drop-shipping/bigcartel'),
            'name': t('Big Cartel'),
          },
        ],
      },
      {
        'slug': 'policies',
        'name': t('Policies'),
        'items': [
          {
            'url': tR('info/return-policy'),
            'name': t('Refund/Return'),
          },
          {
            'url': tR('info/privacy-policy'),
            'name': t('Privacy'),
          },
          {
            'url': tR('info/terms-of-service'),
            'name': t('Terms of Service'),
          },
        ],
      },
    ];
  }, [t, tR])

  const renderSubnavItem = useCallback((item:{url: string, name: string}) => {
    return <SubnavLi key={item.name}>
      <Link to={item.url} reloadDocument>{item.name}</Link>
    </SubnavLi>
  }, [])

  const renderSubnavSection = useCallback((section:{ name: string, items: {url: string, name: string}[]}) => {
    return <SubnavSection key={section.name}>
      <Typography variant="h4" sx={{height: '25px'}}>{section.name}</Typography>
      <SubnavUl>
        { section.items.map(renderSubnavItem) }
      </SubnavUl>
    </SubnavSection>
  }, [renderSubnavItem])

  const renderCategory = useCallback((category:ViewCategoryStore) => {
    return <SubnavHeaderLi 
      key={category.get('id')}
      onMouseOver={() => setSelectedCategoryId(category.get('id'))}
      className={classNames({
        active: category.get('id') === selectedCategoryId
      })}
    >
      <SubnavHeaderText>{category.get('name')}</SubnavHeaderText>
    </SubnavHeaderLi>
  }, [selectedCategoryId])

  const renderProduct = useCallback((product:NavProduct) => {
    return <Grid item key={product.slug}>
      <ProductLink
        to={tR('/products')+'/'+product.urlSlug}
        reloadDocument
      >
        <ProductImgWrapper>
          <ErrorBoundary fallback={<></>}>
            <ProductImg
              slug={product.slug}
              name={product.name}
            />
          </ErrorBoundary>
        </ProductImgWrapper>
        { product.isNew ? <NewTag>{t('New')}</NewTag> : null}

        <ProductText>{product.name}</ProductText>
      </ProductLink>
    </Grid>
  }, [tR])

  const topCategories = useMemo(() => {
    return viewCategories.filter(category => category.get('id_parent') === null)
  }, [viewCategories])

  const products = useMemo(() => {
    const selectedCategory = viewCategories.get(String(selectedCategoryId));
    if(selectedCategory === undefined) return [];

    const tmpProducts:NavProduct[] = []

    viewCategories.forEach(category => {
      if(category.get('id_parent') !== selectedCategoryId) return;

      tmpProducts.push({
        name: category.get('name'),
        slug: category.get('slug'),
        urlSlug: selectedCategory.get('url_slug')+'/'+category.get('url_slug'),
        isNew: false,
      })
    })

    viewProducts.forEach(product => {
      if(selectedCategoryId === undefined || !product.get('categories').contains(selectedCategoryId)) return;

      tmpProducts.push({
        name: product.get('name'),
        slug: product.get('slug'),
        urlSlug: product.get('url_slug'),
        isNew: product.get('is_new'),
      })
    })

    return tmpProducts
  }, [viewProducts, viewCategories, selectedCategoryId])

  return <Box
    component="div"
    sx={{
      backgroundColor: '#fff',
      boxShadow: '0 -4px 50px rgba(0, 0, 0, 0.1)',
      display: {
        xs: 'none',
        md: 'block',
      },
    }}
  >
    <Container
      maxWidth="xl"
      sx={{
        position: 'relative',
        pl: {
          md: 1,
          lg: 2
        },
        pr: {
          md: 1,
          lg: 2
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          height: '52px',
        }}
      >
        <Stack direction="row" alignItems="stretch">
          <NavItem>
            <NavLink to={tR('/products')} reloadDocument>
              <NavText>{t('Products')}</NavText>
            </NavLink>
            <Subnav>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: '#2d2d2d',
                  px: 1,
                }}
              >
                {topCategories.valueSeq().map(renderCategory)}
              </Stack>
              <SubnavBody sx={{overflow: 'hidden'}}>
                <Grid 
                  container
                  columnSpacing={{
                    md: 2,
                    lg: 4
                  }}
                  rowSpacing={{
                    md: 2,
                    lg: 4
                  }}
                >
                  {products.map(renderProduct)}
                </Grid>
              </SubnavBody>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: '#e0e0e0',
                  p: 2,
                }}
                justifyContent="flex-end"
                spacing={2}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={tR('/info/sample-packs')}
                  reloadDocument
                >{t('Order sample packs')}</Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  component={Link}
                  to={tR('/products')}
                  reloadDocument
                  sx={{
                    backgroundColor: '#fff',
                  }}
                >{t('View all products')}</Button>
              </Stack>
            </Subnav>
          </NavItem>
          <NavItem>
            <NavText>{t('Infos')}</NavText>
            <Subnav>
              <SubnavBody direction="row" spacing={6}>
                {infoNav.map(renderSubnavSection)}
              </SubnavBody>
            </Subnav>
          </NavItem>
          <NavLink to={tR('/help')} reloadDocument>
            <NavText>{t('Help')}</NavText>
          </NavLink>
          <NavLink to="https://blog.artofwhere.com" target="_blank" rel="noopener">
            <NavText>{t('Blog')}</NavText>
          </NavLink>
        </Stack>
        <Button
          variant="text"
          color="secondary"
          onClick={onPrefDialogOpen}
          startIcon={<PublicRoundedIcon />}
          sx={{
            display: 'flex',
            height: '100%',
            fontSize: '.9rem',
            fontWeight: 400,
            borderRadius: '0',
            alignItems: 'center',
          }}
        >{t('{{lang}} (${{currency}})', {
          lang: lang === 'fr_CA' ? 'FR' : 'EN',
          currency: currency
        })}</Button>
      </Stack>
    </Container>

    <PreferencesDialog
      opened={prefDialogOpened}
      onClose={onPrefDialogClose}
    />
  </Box>
}