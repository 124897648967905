import { useCallback } from 'react'
import DialogButton from '../../Common/Buttons/DialogButton'
import Dialog from '../../Common/Dialog/Dialog'
import DialogActions from '../../Common/Dialog/DialogActions'
import DialogContent from '../../Common/Dialog/DialogContent'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { type CollectionStore } from '../../UserData/_stores/CollectionStore'
import { DeleteCollection } from '../../UserData/_actions/CollectionActions'

interface Props {
  collection: CollectionStore
  opened: boolean
  onClose: () => void
}

export default function DeleteCollectionDialog(props: Props) {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()

  const onConfirm = useCallback(() => {
    dispatch(DeleteCollection(props.collection.get('id')).set({
      onSuccess: props.onClose
    }))
    props.onClose()
  }, [props.collection, props.onClose])

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <DialogTitle>{t('Delete {{name}}', { name: props.collection.get('name') })}</DialogTitle>
    <DialogContent>
      <p>{t('Are you sure that you want to delete this collection?')}</p>
    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
      >{t('Cancel')}</DialogButton>
      <DialogButton
        color="error"
        onClick={onConfirm}
        autoFocus
      >{t('Delete')}</DialogButton>
    </DialogActions>
  </Dialog>
}
