import ApiErrorHandler from '../../Common/Error/ApiErrorHandler';
import { createAction } from '../../Common/_actions/Action';
import { createAjaxAction } from '../../Common/_actions/AjaxAction';
import { GetAccountData, GetUserPrefs } from '../../UserData/_actions/UserDataActions';

export enum AuthActionType {
	OpenAuthModal = "OPEN_AUTH_MODAL",
  CloseAuthModal = "CLOSE_AUTH_MODAL",
	
	ResetPasswordRequest = 'AUTH_SEND_RESET_PASSWORD_REQUEST',
	ResendValidationLink = 'AUTH_RESEND_VALIDATION_LINK',
	NewPassword = 'AUTH_NEW_PASSWORD',

	SubmitSignup = 'AUTH_SUBMIT_SIGNUP',
	Authenticate = 'AUTH_AUTHENTICATE',
	SubmitFbLogin = 'AUTH_SUBMIT_FB_LOGIN',
	SubmitGoogleLogin = 'AUTH_SUBMIT_GOOGLE_LOGIN',
	SubmitAccountLink = 'AUTH_SUBMIT_AccountLink',
}

export const OpenAuthModal = (signup?: boolean) => createAction(AuthActionType.OpenAuthModal, {
	signup: signup
});

export const CloseAuthModal = () => createAction(AuthActionType.CloseAuthModal);

export const ResetPasswordRequest = (email:string) => {
	const data = new FormData
	data.append('email', email)

	return createAjaxAction<{}>(AuthActionType.ResetPasswordRequest, {
		url: 'ajax/auth/reset-password-request',
		method: 'POST',
		data: data,
	})
}

export const ResendValidationLink = () => {
	return createAjaxAction<{}>(AuthActionType.ResendValidationLink, {
		url: 'ajax/auth/resend-validation-link',
		method: 'POST',
	})
}

export const NewPassword = (
	token:string,
	password: string,
) => {
	const data = new FormData
	data.append('token', token)
	data.append('password', password)

	return createAjaxAction<{}>(AuthActionType.NewPassword, {
		url: 'ajax/auth/new-password',
		method: 'POST',
		data: data,
		onSuccess: (response, dispatch) => {
			//Reload user
			dispatch(GetAccountData());
			dispatch(GetUserPrefs());
		},
		onFailure: ApiErrorHandler(AuthActionType.NewPassword)
	})
}

export const SubmitSignup = (
	name: string,
	email: string,
	password: string,
	newsletter: boolean,
) => {
	const data = new FormData
	data.append('signup_name', name)
	data.append('signup_email', email)
	data.append('signup_password', password)
	data.append('signup_newsletter', newsletter ? '1':'0')

	return createAjaxAction<{}>(AuthActionType.SubmitSignup, {
		url: 'ajax/auth/submit-signup',
		method: 'POST',
		data: data,
		onFailure: ApiErrorHandler(AuthActionType.SubmitSignup)
	})
}

export const SubmitFbLogin = (newsletter?:boolean) => {
	const data = new FormData

	data.append('newsletter', !!newsletter ? '1':'0');

	return createAjaxAction<{login_type: 'login'|'signup'|'account-link'}>(AuthActionType.SubmitFbLogin, {
		url: 'ajax/auth/submit-fb-login',
		method: 'POST',
		data: data,
		onFailure: ApiErrorHandler(AuthActionType.SubmitFbLogin)
	})
}

export const SubmitGoogleLogin = (id_token:string, newsletter?:boolean) => {
	const data = new FormData
	data.append('id_token', id_token);
	data.append('newsletter', !!newsletter ? '1':'0');

	return createAjaxAction<{login_type: 'login'|'signup'|'account-link'}>(AuthActionType.SubmitGoogleLogin, {
		url: 'ajax/auth/submit-google-login',
		method: 'POST',
		data: data,
		onFailure: ApiErrorHandler(AuthActionType.SubmitGoogleLogin)
	})
}

export const SubmitAccountLink = (password:string) => {
	const data = new FormData
	data.append('password', password);

	return createAjaxAction<{}>(AuthActionType.SubmitAccountLink, {
		url: 'ajax/auth/submit-account-link',
		method: 'POST',
		data: data,
		onFailure: ApiErrorHandler(AuthActionType.SubmitAccountLink)
	})
}

export type AuthAction = 
	ReturnType<typeof OpenAuthModal>    |
    ReturnType<typeof CloseAuthModal>