import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { DownloadRounded as DownloadRoundedIcon } from '@mui/icons-material'
import { Grid, ListItemIcon, ListItemText, Card, CardActions, Typography, IconButton, Menu, MenuItem } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeletePhotoLabCreationDialog from './DeletePhotoLabCreationDialog'
import { type PhotoLabCreationStore } from '../../UserData/_stores/PhotoLabCreationStore'

export interface Props {
  item: PhotoLabCreationStore
}

export default function PhotoLabCreation(props: Props) {
  const [t] = useTranslation('account-v2')
  const [tR] = useTranslation('routes')

  const [menuOpen, setMenuOpen] = useState(false)
  const [menuDeleteOpen, setMenuDeleteOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const onMenuOpen = useCallback(() => {
    setMenuOpen(true)
  }, [])
  const onMenuClose = useCallback(() => {
    setMenuOpen(false)
  }, [])

  const onMenuDeleteOpen = useCallback(() => {
    setMenuOpen(false)
    setMenuDeleteOpen(true)
  }, [])

  const onMenuDeleteClose = useCallback(() => {
    setMenuDeleteOpen(false)
  }, [])

  const imageElem = useMemo(() => {
    return <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src={props.item.get('file_url')} style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        pointerEvents: 'none'
      }} />
    </div>
  }, [props.item])

  return <Grid item key={props.item.get('id')} xs={6} sm={4} lg={3} xl={2}>
    <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{
        paddingBottom: '90%',
        height: 0,
        position: 'relative'
      }}>{ imageElem }</div>

      <CardActions style={{
        paddingLeft: '16px',
        flexGrow: 1
      }}>
        <Typography
          variant="h4"
          style={{
            flexGrow: 1,
            padding: '8px 0',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        ></Typography>
        <IconButton
          onClick={onMenuOpen}
          ref={setAnchorEl}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
      </CardActions>
      <Menu
        onClose={onMenuClose}
        open={menuOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          component="a"
          href={tR('/download-image') + '?url=' + encodeURIComponent(props.item.get('file_url'))}
          target="_blank"
        >
          <ListItemIcon><DownloadRoundedIcon /></ListItemIcon>
          <ListItemText>{t('Download')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={onMenuDeleteOpen}>
          <ListItemIcon><DeleteIcon /></ListItemIcon>
          <ListItemText>{t('Delete')}</ListItemText>
        </MenuItem>
      </Menu>
    </Card>

    <DeletePhotoLabCreationDialog
      creation={props.item}
      opened={menuDeleteOpen}
      onClose={onMenuDeleteClose}
    />
  </Grid>
}
