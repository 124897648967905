import * as Immutable from 'immutable';
import PatternDesignServiceStore, { IPatternDesignServiceStore } from '../_stores/PatternDesignServiceStore';
import PatternStore, { IPatternStore } from '../_stores/PatternStore';
import PatternCategoryStore, { IPatternCategoryStore } from '../_stores/PatternCategoryStore';
import { PatternDesignAction, PatternDesignActionType } from '../_actions/PatternDesignActions';
import { MaxOffset, PatternsPerPage } from '../_stores/PatternDesignServiceStore';

const PatternDesignServiceReducer = (store:PatternDesignServiceStore = new PatternDesignServiceStore(), action: PatternDesignAction):PatternDesignServiceStore => {
	switch (action.type) {
		case PatternDesignActionType.PatternDesignServiceImageAdd:
			//if(action.categoryId !== store.get('selectedCategoryId')) return store;
			let imageStore = new PatternStore(action.image);

			return store.setIn(['patterns', action.image.id], imageStore);

		case PatternDesignActionType.PatternDesignServiceCategoryAdd:
			let categoryStore = new PatternCategoryStore();
			categoryStore = categoryStore.withMutations(categoryStore => {
				categoryStore.set('id', action.id);
				categoryStore.set('title', action.title);
				categoryStore.set('thumbnail', action.thumbnail);

				//Subcategories
				for (var i = 0; i < action.categories.length; i++) {
					let subCategoryStore = new PatternCategoryStore();
					subCategoryStore = subCategoryStore.withMutations(subCategoryStore => {
						subCategoryStore.set('id', action.categories[i].id);
						subCategoryStore.set('title', action.categories[i].title);
						subCategoryStore.set('thumbnail', action.categories[i].thumbnail);
					});

					categoryStore.setIn(['categories', action.categories[i].id], subCategoryStore);
				}
			});

			return store.setIn(['categories', action.id], categoryStore);

		case PatternDesignActionType.PatternDesignServiceSetColors:
			return store.setIn(['patterns', action.id, 'colors'], Immutable.List<string>(action.colors));

		case PatternDesignActionType.SelectCategory:
			return store.withMutations(store => {
				store.set('selectedCategoryId', action.id);
				store.set('offset', 0);
			});

		case PatternDesignActionType.ResetPatterns:
			return store.set('patterns', Immutable.OrderedMap<string, PatternStore>());

		case PatternDesignActionType.PatternDesignServiceOffsetChange:
			return store.set('offset', Math.min(action.offset, MaxOffset));

		case PatternDesignActionType.EditCount:
			return store.set('count', action.count);
	}

	return store;
}

export default PatternDesignServiceReducer