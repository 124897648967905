import * as React from 'react'
import { Breakpoint, Theme, useMediaQuery } from '@mui/material'

export type Props = {
    children?: React.ReactNode
    lgDown?: boolean
    lgUp?: boolean
    mdDown?: boolean
    mdUp?: boolean
    smDown?: boolean
    smUp?: boolean
    xlDown?: boolean
    xlUp?: boolean
    xsDown?: boolean
    xsUp?: boolean
    only?: Breakpoint[]
}

export default (props: Props) => {
    // I'm so sorry for this
    let isHidden = false

    let onlyBreakpoints:any = {};

    const lg_down = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
    const lg_up = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
    onlyBreakpoints.lg = useMediaQuery((theme: Theme) => theme.breakpoints.only('lg'))
    const md_down = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const md_up = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
    onlyBreakpoints.md = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'))
    const sm_down = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const sm_up = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
    onlyBreakpoints.sm = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'))
    const xl_down = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'))
    const xl_up = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))
    onlyBreakpoints.xl = useMediaQuery((theme: Theme) => theme.breakpoints.only('xl'))
    const xs_down = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
    const xs_up = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'))
    onlyBreakpoints.xs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))

    if (props.only && Array.isArray(props.only) && props.only.length) {
        isHidden = props.only.some(value => onlyBreakpoints[value]);
    } else {
        isHidden = 
            props.lgDown ? lg_down :
            props.lgUp ? lg_up :
            props.mdDown ? md_down :
            props.mdUp ? md_up :
            props.smDown ? sm_down :
            props.smUp ? sm_up :
            props.xlDown ? xl_down :
            props.xlUp ? xl_up :
            props.xsDown ? xs_down :
            props.xsUp ? xs_up :
        false
    }

    return isHidden ? <></> : <>{props.children}</>
}