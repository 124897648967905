import { useTranslation } from 'react-i18next'
import AccountHeader from '../AccountHeader'
import { Button, Card, CardContent, Container, Divider, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { type ChangeEvent, type FormEvent, useCallback, useMemo, useState } from 'react'
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon } from '@mui/icons-material'
import AuthCheckDialog from './AuthCheckDialog'
import { DeleteAccount, SavePassword, UserDataActionType } from '../../UserData/_actions/UserDataActions'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { useNavigate } from 'react-router-dom'

export default function Security() {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const [passCheckDialogOpened, setPassCheckDialogOpened] = useState(false)
  const [passCheckDialog2Opened, setPassCheckDialog2Opened] = useState(false)

  const onChangeNewPassword = useCallback((e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value), [])
  const onChangeConfirmNewPassword = useCallback((e: ChangeEvent<HTMLInputElement>) => setConfirmNewPassword(e.target.value), [])
  const onClickShowPassword = useCallback(() => setShowPassword(value => !value), [])

  const onPassCheckDialogClose = useCallback(() => {
    setPassCheckDialogOpened(false)
  }, [])

  const onPassCheckDialog2Close = useCallback(() => {
    setPassCheckDialog2Opened(false)
  }, [])

  const onSubmitPassword = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPassCheckDialogOpened(true)
  }, [])

  const onAuthCheck = useCallback((password: string) => {
    dispatch(SavePassword(password, newPassword).set({
      onSuccess: () => {
        setPassCheckDialogOpened(false)
        setNewPassword('')
        setConfirmNewPassword('')
        setShowPassword(false)
      }
    }))
  }, [newPassword])

  const onDeleteAccount = useCallback(() => {
    setPassCheckDialog2Opened(true)
  }, [])

  const onAuthCheck2 = useCallback((password: string) => {
    dispatch(DeleteAccount(password).set({
      onSuccess: () => {
        navigate(0)
      }
    }))
  }, [newPassword])

  const formModified = useMemo(() => {
    return newPassword !== '' || confirmNewPassword !== ''
  }, [newPassword, confirmNewPassword])

  const validPasswords = useMemo(() => {
    return newPassword !== '' &&
      confirmNewPassword !== '' &&
      newPassword === confirmNewPassword
  }, [newPassword, confirmNewPassword])

  return <div>
    <AccountHeader
      title={t('Security')}
    />

    <Container
      maxWidth="sm"
      disableGutters
      style={{ margin: 0 }}
    >
      <Stack spacing={4}>
        <Card elevation={0}>
          <form onSubmit={onSubmitPassword}>
            <CardContent sx={{ py: 2 }}>
              <Stack spacing={1}>
                <Typography
                  variant="h3"
                >{t('Password')}</Typography>
                <Typography
                  variant="body1"
                >{t('Choose a strong password and don\'t reuse it for other accounts.')}</Typography>
              </Stack>
            </CardContent>
            <Divider />
            <CardContent>
              <Stack spacing={2}>
                <TextField
                  label={t('New password')}
                  type={showPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={onChangeNewPassword}
                  error={formModified && newPassword === ''}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        onClick={onClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
                <TextField
                  label={t('Confirm new password')}
                  type={showPassword ? 'text' : 'password'}
                  value={confirmNewPassword}
                  onChange={onChangeConfirmNewPassword}
                  error={formModified && (confirmNewPassword === '' || confirmNewPassword !== newPassword)}
                  helperText={formModified && confirmNewPassword !== newPassword ? t('Passwords don\'t match') : ''}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        onClick={onClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!validPasswords}
                  style={{
                    alignSelf: 'flex-start'
                  }}
                >{t('Change password')}</Button>
              </Stack>
            </CardContent>
          </form>
        </Card>

        <Card elevation={0}>
          <CardContent sx={{ py: 2 }}>
            <Stack spacing={1}>
              <Typography
                variant="h3"
              >{t('Delete account')}</Typography>
              <Typography
                variant="body1"
              >{t('Permanently delete my Art of Where account and all my data. This action is irreversible.')}</Typography>
            </Stack>
          </CardContent>
          <Divider />
          <CardContent>
            <Button
              variant="outlined"
              color="error"
              onClick={onDeleteAccount}
            >{t('Delete my account')}</Button>
          </CardContent>
        </Card>
      </Stack>
    </Container>

    <AuthCheckDialog
      opened={passCheckDialogOpened}
      onConfirm={onAuthCheck}
      onClose={onPassCheckDialogClose}
      loaderKey="userData"
      errorKey={UserDataActionType.SavePassword}
    />
    <AuthCheckDialog
      opened={passCheckDialog2Opened}
      onConfirm={onAuthCheck2}
      onClose={onPassCheckDialog2Close}
      loaderKey="userData"
      errorKey={UserDataActionType.DeleteAccount}
    />
  </div>
}
