import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';

const PRODUCT_ID = 127;
const VIEW_PRODUCT_ID = 148;


const ShirtColorSection = styled('div')(({theme}) => ({
  backgroundColor: "#333",
  paddingBottom: '4rem',
  textAlign: 'center',
  'h2': {
    margin: '0 0 30px 0',
    paddingTop: 15,
    color: '#fff',
    fontSize: '50px',
    lineHeight: 1,
  },
  'h5': {
    fontSize: '26px',
  },
}))

export default function FittedPoloShirtPage() {
  const [t] = useTranslation('page/products/fitted-polo-shirts');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom digitally printed Fitted Polo Shirts | Products')}
      description={t('Design and create your own custom printed Fitted Polo Shirts. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#edc4d2"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '47%',
      }}
      tag={t('The digitally printed')}
      title={t('Fitted Polo Shirt')}
      text={t('Experience the perfect blend of comfort and style with tailored premium cotton polo shirts, customized with your designs using Direct-to-Film (DTF) print technology. Whether you’re looking to create custom unisex polos for work or leisure, our print-on-demand service allows you to personalize each shirt with any artwork and images.')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/fitted-polo-shirts/banner-1-' + locale + '.png')}
      alt={t('Picture of Fitted Polo Shirt')}
      details={[
        {
          img: require('@resources/img/pages/products/fitted-polo-shirts/icon-1.svg?url'),
          alt: t('Cotton icon'),
          text: t('Made from 11.3 oz 100% preshrunk pique ringspun cotton'),
        },
        {
          img: require('@resources/img/pages/products/fitted-polo-shirts/icon-2.svg?url'),
          alt: t('Shoulder icon'),
          text: t('Taped shoulder seam'),
        },
        {
          img: require('@resources/img/pages/products/fitted-polo-shirts/icon-3.svg?url'),
          alt: t('Button icon'),
          text: t('Button placket'),
        },
        {
          img: require('@resources/img/pages/products/fitted-polo-shirts/icon-4.svg?url'),
          alt: t('Print icon'),
          text: t('Durable and vibrant direct-to-film (DTF) print won’t fade with use or wash'),
        },
        {
          img: require('@resources/img/pages/products/fitted-polo-shirts/icon-5.svg?url'),
          alt: t('Yoga icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/fitted-polo-shirts/gallery_0.jpg'),
      require('@resources/img/pages/products/fitted-polo-shirts/gallery_1.jpg'),
      require('@resources/img/pages/products/fitted-polo-shirts/gallery_2.jpg'),
      require('@resources/img/pages/products/fitted-polo-shirts/gallery_3.jpg'),
      require('@resources/img/pages/products/fitted-polo-shirts/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Fitted <br/>Polo Shirts <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Fitted Polo Shirts! At prices designed for reselling, Fitted Polo Shirts made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin.')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
    />

    <SectionSizes
      chart="polos-women"
      productId={PRODUCT_ID}
      title={t('Fitted Polo Shirt Sizing')}
      text={t('Fitted polo shirts are a premium premade shirt from the M&O brand. Custom placement print can be printed on the shirt front or back.')}
    />

    <ShirtColorSection id="colors" className="white-text section-colors">
      <div className="container">
        <h2>{t('Colors available')}</h2>
        <div className="row no-margin">
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/polo-shirts/black.png')} alt={t('Black')}/>
            <h5>{t('Black')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/polo-shirts/white.png')} alt={t('White')}/>
            <h5>{t('White')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/polo-shirts/navy.png')} alt={t('Navy')}/>
            <h5>{t('Navy')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/polo-shirts/sand.png')} alt={t('Sand')}/>
            <h5>{t('Sand')}</h5>
          </div>
        </div>
      </div>
    </ShirtColorSection>

    <SectionBranding
      title={t('Brand your Fitted Polo Shirt')}
      text={t('Branding enhances your products. Add artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Fitted Polo Shirt with these great products to create your own unique styles.')}
      productSlugs={['lounge-pants', 'glitter-fitted-skirt', 'glitter-flare-skirt']}
    />
  </div>
}