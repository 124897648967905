import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ItemList from '../../Common/ItemList/ItemList'
import { Grid, Skeleton, Typography } from '@mui/material'
import AccountHeader from '../AccountHeader'
import { type PhotoLabCreationStore } from '../../UserData/_stores/PhotoLabCreationStore'
import PhotoLabCreation from './PhotoLabCreation'

const LIMIT = 20

export default function PhotoLabCreationList() {
  const [t] = useTranslation('account-v2')

  const loadingGrid = useMemo(() => {
    const listSkeletons = []

    for (let i = 0; i < LIMIT; i++) {
      listSkeletons.push(<Grid key={i} item xs={12} md={6} lg={4}>
        <Skeleton variant="rounded" height={198} />
      </Grid>)
    }

    return listSkeletons
  }, [])

  const renderCreation = useCallback((item: PhotoLabCreationStore) => {
    return <PhotoLabCreation item={item} />
  }, [])

  const renderList = useCallback((items: JSX.Element | null, links: JSX.Element | null, loading: boolean) => {
    return <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {loading
          ? loadingGrid
          : (items ?? <Grid item xs={12}>
            <span></span>
          </Grid>)
        }
      </Grid>

      {links}
    </>
  }, [loadingGrid])

  return <div>
    <AccountHeader
      title={t('Photo Lab')}
    />
    <Typography variant="body1" sx={{ mb: 2 }}>{t('All your creations from the Photo Lab are saved here.')}</Typography>
    <ItemList
      dataType="photoLabCreations"
      item={renderCreation}
      limit={LIMIT}
      render={renderList}
    />
  </div>
}
