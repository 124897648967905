import fs from 'fs';
import { createContext } from 'react';
import { Image } from 'skia-canvas';

export default class ImageLibrary {
    public images:Map<string, Image> = new Map<string, Image>();

    public retrieveImage(filename:string) {
        //Grab from cache
        if(this.images.has(filename)) {
            return this.images.get(filename);
        }

        //Trigger error
        throw new Error('Image '+filename+' not found');
    }
}

export const ImageLibraryContext = createContext(new ImageLibrary)