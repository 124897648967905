import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material';
import * as React from 'react';

const ValidIndicatorDiv = styled('div')(({theme}) => ({
    backgroundColor: '#E33250',
    borderRadius: '24px',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: '0',

    '&.valid': {
        backgroundColor: '#88D232',
    },

    '&.small': {
        width: '24px',
        height: '24px',

        ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
            width: '18px',
            height: '18px',
        },
    },

    '&.medium': {
        width: '36px',
        height: '36px',

        '@media (max-width: 1510px), (max-height: 1070px)': {
            width: '24px',
            height: '24px',
        }
    }
}));

const ValidIndicatorContainer = styled('div')({
    display: 'flex',
    alignItems: 'center', 
    fontSize: '16px', 
    color: '#E33250',

    '&.valid': {
        color: '#88D232',
    },

    '@media (max-width: 1510px), (max-height: 1070px)': {
        fontSize: '14px',
    },
})

const ResizedCheckIcon = styled(CheckIcon)({
    color: '#fff',
    '&.small': {
        fontSize: '20px',
        '@media (max-width: 1053px), (max-height: 708px)': {
            fontSize: '14px'
        }
    },
    '&.medium': {
        fontSize: '24px',
        '@media (max-width: 1510px), (max-height: 1070px)': {
            fontSize: '18px'
        }
    },
});

const ResizedCloseIcon = styled(CloseIcon)({
    color: '#fff',
    '&.small': {
        fontSize: '20px',
        '@media (max-width: 1053px), (max-height: 708px)': {
            fontSize: '14px'
        }
    },
    '&.medium': {
        fontSize: '24px',
        '@media (max-width: 1510px), (max-height: 1070px)': {
            fontSize: '18px'
        }
    },
})

type Props = React.HTMLAttributes<HTMLDivElement> & {
    valid: boolean
    validText?: string
    invalidText?: string
    iconSize: 'small'|'medium'
}

export default function ValidIndicator(props:Props) {
    const { valid, validText, invalidText, iconSize, className, ...otherProps } = props;

    return <ValidIndicatorContainer 
        className={valid ? 'valid'+(className ? ' '+className:''):className}
        { ...otherProps }
    >
        <ValidIndicatorDiv 
            { ...otherProps }
            className={iconSize+(valid ? ' valid' : '')}
        >
            { valid ? <ResizedCheckIcon className={iconSize} /> : <ResizedCloseIcon className={iconSize} /> }
        </ValidIndicatorDiv>
        { valid && validText ? validText 
            : !valid && invalidText ? invalidText 
            : null 
        }
        
    </ValidIndicatorContainer>
}