import { useDispatch, useSelector } from 'react-redux'
import { SetFixedView } from '../_actions/ModelViewerStoreActions';
import WebsiteStore from '../../WebsiteStore';
import ModelViewerStore from '../_stores/ModelViewerStore';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

export default function useFixedView(): [ModelViewerStore['fixedView'], (view: ModelViewerStore['fixedView']) => void] {
    const dispatch = useAppDispatch();
    const fixedView = useSelector((state: WebsiteStore) => state.get('UIData').get('designLab').get('modelViewer').get('fixedView'))
    return [
        fixedView,
        (view: ModelViewerStore['fixedView']) => dispatch(SetFixedView(view)),
    ]
}