import { useCallback, useState } from 'react';
import { UserImageStore } from '../../../UserData/_stores/UserImageStore';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import useLabData from '../../_hooks/useLabData';
import { AddImgLayer } from '../../../UIData/_actions/DesignLabStoreActions';
import useCalcFitTemplate from '../../_hooks/useCalcFitTemplate';
import useCalcFitToSide from '../../_hooks/useCalcFitToSide';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../Common/_hooks/useAppDispatch';
import IconButton from '../../../Common/Buttons/IconButton';
import ImageLibraryBrowser from '../../ImageLibraryBrowser/_components/ImageLibraryBrowser';

export default function AddImageTool() {
  const dispatch = useAppDispatch();
  const [t] = useTranslation('design-lab');
  const activeScene = useSelector((state: WebsiteStore) => state.get('UIData').get('designLab').get('activeScene'));
  const activeSubproduct = useSelector((state: WebsiteStore) => state.get('UIData').get('designLab').get('activeSubproduct'));
  const autoDesign = useSelector((state: WebsiteStore) => activeSubproduct !== null ? state.get('UIData').get('designLab').get('autoDesignSubproducts').get(activeSubproduct) : undefined);
  const labData = useLabData();
  const calcFitTemplate = useCalcFitTemplate();
  const calcFitToSide = useCalcFitToSide();

  const [libraryOpened,setLibraryOpened] = useState(false)

  const onSelectImage = useCallback((item: UserImageStore) => {
    if(!activeScene || !labData) return

    const maxWidth = 1024;
    const maxHeight = 1024;

    let originalWidth = item.get('width');
    let originalHeight = item.get('height');
    let width, height;
    if (originalWidth / originalHeight > 1) {
      width = maxWidth;
      height = maxHeight * originalHeight / originalWidth;
    } else {
      height = maxHeight;
      width = maxWidth * originalWidth / originalHeight;
    }

    const data = labData.fit_all ? calcFitTemplate(width, height) : calcFitToSide(width, height, 'left');

    dispatch(AddImgLayer({
      fileid: item.get('fileid'),
      src: '/api/user/images/image/' + item.get('fileid'),
      originalWidth: originalWidth,
      originalHeight: originalHeight,
      scene: activeScene,
      subproduct: activeSubproduct,
      width: width,
      height: height,
      ...data
    }))
  }, [labData, activeScene, activeSubproduct, calcFitTemplate, calcFitToSide]);

  const onLibraryOpen = useCallback(() => {
    setLibraryOpened(true)
  }, [])

  const onLibraryClose = useCallback(() => {
    setLibraryOpened(false)
  }, [])

  return <>
    <Tooltip title={t('Add Image')}>
      <IconButton
        variant="contained"
        color="primary"
        size="large"
        disabled={!!autoDesign}
        onClick={onLibraryOpen}
      ><img src={require('@resources/img/create/design-lab-v4/icons/add-image.svg?url')} /></IconButton>
    </Tooltip>

    <ImageLibraryBrowser
      opened={libraryOpened}
      onSelect={onSelectImage}
      onClose={onLibraryClose}
    />
  </>
}