import { useCallback } from 'react'
import DialogButton from '../Common/Buttons/DialogButton'
import Dialog from '../Common/Dialog/Dialog'
import DialogActions from '../Common/Dialog/DialogActions'
import DialogContent from '../Common/Dialog/DialogContent'
import DialogTitle from '../Common/Dialog/DialogTitle'
import { useTranslation } from 'react-i18next'
import { DeleteCart } from '../UserData/_actions/UserCartActions'
import type UserCartStore from '../UserData/_stores/UserCartStore'
import { useAppDispatch } from '../Common/_hooks/useAppDispatch'

interface Props {
  cart: UserCartStore
  opened: boolean
  onClose: () => void
}

export default function DeleteCartDialog(props: Props) {
  const [t] = useTranslation('cart')
  const dispatch = useAppDispatch()

  const onConfirm = useCallback(() => {
    const fd = new FormData()
    fd.append('id', String(props.cart.get('id')))

    dispatch(DeleteCart(fd))

    props.onClose()
  }, [props.cart, props.onClose])

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <DialogTitle>{t('Remove {{name}}', { name: props.cart.get('cart_name') })}</DialogTitle>
    <DialogContent>
      <p>{t('Are you sure that you want to remove this cart?')}</p>
    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
      >{t('Cancel')}</DialogButton>
      <DialogButton
        color="error"
        onClick={onConfirm}
      >{t('Remove')}</DialogButton>
    </DialogActions>
  </Dialog>
}
