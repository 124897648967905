import { Alert, Box, Button, Collapse, Container, Paper, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import tKey from "../Common/Utils/tKey";
import { useCallback, useState } from "react";
import { useAppDispatch } from "../Common/_hooks/useAppDispatch";
import { ResendValidationLink } from "./_actions/AuthActions";
import AuthForm from "./AuthForm";

export default function LoginPage() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('auth')
  const [tR] = useTranslation('routes')
  const [searchParams] = useSearchParams()


  return <Box component="div" sx={{
    backgroundImage: 'url(' + require('@resources/img/theme/page/ornaments-1.svg?url') + ')',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    pt: 6,
    pb: 15,
    flex: '1 0 auto',
  }}>
    <Container
      maxWidth="md"
    >
      <Helmet>
        <title>{t('Login')}</title>
      </Helmet>

      <Stack spacing={3}>
        <Typography variant="h1" textAlign="center">{t('Login')}</Typography>
        <Paper
          elevation={0}
          sx={{
            p: 4,
          }}
        >
          <AuthForm
            redirect={searchParams.get('r') ?? undefined}
          />
        </Paper>
      </Stack>
    </Container>
  </Box>
}