import { Button, styled } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Point from '../../Common/Utils/Math/Point';
import { EditActiveScene, EditWarnings, RemoveLayer, SelectLayer } from '../../UIData/_actions/DesignLabStoreActions';
import WebsiteStore from '../../WebsiteStore';
import useCheckDpi from '../_hooks/useCheckDpi';
import useCheckWarningPoint from '../_hooks/useCheckWarningPoint';
import useLabData from '../_hooks/useLabData';
import ValidIndicator from './ValidIndicator';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

export const SceneTabsContainer = styled('div')({
    padding: '10px 20px 0',
    position: 'relative',
    zIndex: 20,
    paddingTop: '10px',
    marginTop: '-10px',
    overflow: 'hidden',
    display: 'flex',
})

export const SceneTab = styled(Button)({
    color: '#333',
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'Poppins',
    borderRadius: '20px 20px 0 0',
    background: '#f7f7f7',
    boxShadow:  '',
    padding: '10px 20px',
    marginRight: '15px',
    height: '45px',
    position: 'relative',
    lineHeight: '15px',
    '&.active': {
        background: '#fff',
        boxShadow:  '0 0 10px rgba(0, 0, 0, 0.25)',
        zIndex: 2,
    },
    '&:hover': {
        background: '#ddd',
    }, 
    '@media (max-width: 953px)': {
        fontSize: '12px',
        padding: '5px 10px',
        marginRight: '-5px',
    }
})

type Props = {}

export default function SceneSelector(props:Props) {
    const dispatch = useAppDispatch();

    const activeProductId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
    const activeVariant = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeVariant'));
    const activeScene = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeScene'));
    const productData = useSelector((state:WebsiteStore) => state.get('appData').get('products').get(String(activeProductId)));
    const layers = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('layers'));
    const warnings = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('warnings'));
    const labData = useLabData();
    const checkWarningPoint = useCheckWarningPoint();
    const checkDpi = useCheckDpi();

    //Cleanup layers for scenes that don't exist when switching to a new product
    useEffect(() => {
        if(!labData?.variants[activeVariant]) return;

        const tmpScenes = Object.keys(labData.variants[activeVariant].scenes);

        layers.forEach(layer => {
            if(!tmpScenes.includes(layer.scene)) {
                dispatch(RemoveLayer(layer.get('id')));
            }
        });
    }, [labData]);

    const scenes = useMemo(() => {
        if(!labData || !labData.variants[activeVariant]) return [];

        const tmpScenes = Object.keys(labData.variants[activeVariant].scenes);

        if(tmpScenes.length === 1) {
            return [{
                slug: 'main',
                name: productData.get('name'),
            }];
        } else {
            let productObj = window.Products.getProduct(labData.id);
            //productObj.static.preview_data = labData.preview_data;
            const sceneInfo = productObj.getSceneInfo(tmpScenes);

            return tmpScenes.map((scene, index) => {
                return {
                    slug: scene,
                    name: sceneInfo && sceneInfo[0][index] ? sceneInfo[0][index] : scene,
                }
            });
        }
    }, [labData]);

    useEffect(() => {
        if(scenes.length < 1) {
            dispatch(EditActiveScene(undefined));
            return;
        }

        dispatch(EditActiveScene(scenes[0].slug));
    }, [scenes]);

    const renderScene = useCallback((scene:{slug: string, name: string}) => {
        const resolutionOk = warnings.get('resolution').get(scene.slug);
        const templatesCoveredOk = warnings.get('templates_covered').get(scene.slug);

        return <SceneTab
            key={scene.slug}
            className={activeScene === scene.slug ? 'active':''}
            onClick={() => {
                dispatch(EditActiveScene(scene.slug))
                dispatch(SelectLayer(null));
            }}
        >
            <ValidIndicator valid={templatesCoveredOk && resolutionOk} iconSize="small" />
            { scene.name }
        </SceneTab>
    }, [activeScene, warnings])

    return !productData.get('labData').has_subproducts ? <SceneTabsContainer>
        { scenes.map(renderScene) }
    </SceneTabsContainer> : null
}