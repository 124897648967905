import { Button } from '@mui/material';
import { memo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    title: React.ReactNode
    text: React.ReactNode
}

export default memo(function SectionDropship(props: Props) {
    const [t] = useTranslation('page/products/_sections');
    const [tR] = useTranslation('routes');

    return <div className="section-dropship">
        <div className="section-simple-cta">
            <div className="full-page-panel center-align section-simple section-simple-dark section-simple-full">
                <div className="container">
                    <div className="row no-margin">
                        <div className="col s12">
                            <h3>{ props.title }</h3>
                            <p className="bounded">{ props.text }</p>
                        </div>
                    </div>
                    <div>
                        <div className="dropship-steps">
                            <div className="steps-row row-1">
                                <div className="step step-1">
                                    <div className="icon"><img src={require('@resources/img/pages/products/design-2021/dropship_1.svg?url')} alt="Dropship 1" /></div>
                                    <div className="label">{t('You Design')}</div>
                                    <div className="check"><i className="material-icons">done</i></div>
                                </div>
                                <div className="step-line line-1"></div>
                                <div className="step step-2">
                                    <div className="icon"><img src={require('@resources/img/pages/products/design-2021/dropship_2.svg?url')} alt="Dropship 2" /></div>
                                    <div className="label">{t('You Sell')}</div>
                                    <div className="check">
                                        <span className="layer-1"></span>
                                        <span className="layer-2"></span>
                                        <i className="material-icons">done</i>
                                    </div>
                                </div>
                            </div>

                            <div className="step-line line-2"></div>
                            <div className="step-line line-3"></div>

                            <div className="steps-row row-2">
                                <div className="step step-3">
                                    <div className="icon"><img src={require('@resources/img/pages/products/design-2021/dropship_3.svg?url')} alt="Dropship 3" /></div>
                                    <div className="label">{t('We Make')}</div>
                                    <div className="check"></div>
                                </div>
                                <div className="step-line line-4"></div>
                                <div className="step step-4">
                                    <div className="icon"><img src={require('@resources/img/pages/products/design-2021/dropship_4.svg?url')} alt="Dropship 4" /></div>
                                    <div className="label">{t('And Deliver!')}</div>
                                    <div className="check"></div>
                                </div>
                            </div>
                        </div>
                        <a href={tR('/drop-shipping')} className="btn-2020-flat">{ t('Explore Dropshipping') }</a>
                    </div>       
                </div>
            </div>
        </div>
    </div>
})