import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import isInsideRect from '../../Common/Utils/Math/isInsideRect';
import Point from '../../Common/Utils/Math/Point';
import rotatePoint from '../../Common/Utils/Math/rotatePoint';
import WebsiteStore from '../../WebsiteStore';
import { LAB_TEMPLATE_HEIGHT, LAB_TEMPLATE_WIDTH } from '../../config';

export default function useCheckWarningPoint() {
    const mirrorModes = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('mirrorModes'));
    const layers = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('layers'));

    return useCallback((point:Point, scene:string, subproduct?: string) => {
        let pointCovered = false;
        const mirrorModePrefix = subproduct ? subproduct+'-':'';
        let mirrorMode = mirrorModes.get(mirrorModePrefix+scene);

        if(scene === 'main' && [6,8].includes(mirrorModes.get(mirrorModePrefix+'back'))) {
            return true;
        }

        if(scene === 'back' && [5,7].includes(mirrorModes.get(mirrorModePrefix+'main'))) {
            return true;
        }

        //Check for mirrors
        if(mirrorMode > 0) {
            //Skip points of mirror sides
            switch(mirrorMode) {
                //Mirror Left
                //Clone left
                case 1:
                case 3:
                    if(point.x >= LAB_TEMPLATE_WIDTH/2)
                        pointCovered = true;
                    break;
    
                //Mirror Right
                //Clone Right
                case 2:
                case 4:
                    if(point.x < LAB_TEMPLATE_WIDTH/2)
                        pointCovered = true;
                    break;
    
            }
    
            if(pointCovered)
                return true;
        }

        const sceneLayers = layers.filter((layer) => layer.get('scene') === scene && (!subproduct || layer.get('subproduct') === subproduct));

        sceneLayers.forEach((layer) => {
            if(pointCovered) return;

            let data_x = layer.get('x');
            let data_y = layer.get('y');
            let data_w = layer.get('width')*layer.get('scaleX');
            let data_h = layer.get('height')*layer.get('scaleY');
            let data_r = layer.get('rotation');

            if(['img','pattern'].includes(layer.get('type'))) {
                //Patterns that cover everything
                if(['repeat','repeat-mirror','repeat-mirror-x','repeat-mirror-y','half-drop','half-brick'].includes(layer.get('patternMode'))) {
                    pointCovered = true;
                    return;
                }

                const maxLabSide = Math.max(LAB_TEMPLATE_WIDTH, LAB_TEMPLATE_HEIGHT);

                //Horizontal pattern
                if(['repeat-x','repeat-x-mirror-x'].includes(layer.get('patternMode'))) {
                    data_w = maxLabSide*2;
                }

                //Vertical pattern
                if(['repeat-y','repeat-y-mirror-y'].includes(layer.get('patternMode'))) {
                    data_h = maxLabSide*2;
                }
            }

            //Initial X is middle point so we substract half of width/height to get top-left corner
            let x = data_x - data_w /2;
            let y = data_y - data_h /2;

            let width = data_w;
            let height = data_h;
            let angle = data_r;

            //Find rotation origin
            let origin = {
                x: x + width/2,
                y: y + height/2
            };

            //Define corner points
            let a = { x: x, y: y };
            let b = { x: x+width, y: y };
            let c = { x: x+width, y: y+height };
            let d = { x: x, y: y+height };

            //Calculate image corner points after rotation
            a = rotatePoint(origin, a, angle);
            b = rotatePoint(origin, b, angle);
            c = rotatePoint(origin, c, angle);
            d = rotatePoint(origin, d, angle);

            //FINALLY
            //Check if point is inside image
            if(isInsideRect(a, b, c, d, point)) {
                pointCovered = true;
            }
        });
    
        //Stop right here and raise warning if point wasn't covered
        if(!pointCovered) {
            return false;
        }
    
        return true;
    }, [layers, mirrorModes]);
}