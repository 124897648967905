import { useEffect, useState } from "react";

type Size = {
    width: number;
    height: number;
}  

export default function useDocumentSize(): Size {
    const [documentSize, setDocumentSize] = useState<Size>({
      width: 1920,
      height: 1080,
    });

    useEffect(() => {
      function resize() {
        setDocumentSize({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        });
      }

      window.addEventListener("resize", resize);
      resize();

      return () => window.removeEventListener("resize", resize);
    }, []);

    return documentSize;
  }