import { Box, Divider, styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Drawer from '@mui/material/Drawer';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexWrapper, MarginWrapper, PaddingWrapper } from '../Common/_components/Divs';
import Hidden from '../Common/_components/Hidden';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FilterCard from './FilterCard';
import { useAppSelector } from '../Common/_hooks/useAppSelector';

const FilterInput = styled('p')({
    fontWeight: '500',
    fontSize: '14px',
    color: '#000000',
});

const StyledAccordion = styled(Accordion)({
    backgroundColor: 'unset',
    boxShadow: 'unset',

    '.MuiAccordionSummary-content': {
        margin: 0,
    },

    '.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '48px',
    },
});

const StyledDrawer = styled(Drawer)({
	'.MuiDrawer-paperAnchorBottom': {
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
		margin: '0 auto',
		width: '95%',
		maxHeight: '85%',
	},

	'.drawer-content': {
		padding: '22px 20px',
	}
});

const SectionTitle = styled('h4')({
	textTransform: 'uppercase',
	fontWeight: '600',
    margin: 0,
});

const CardWrapper = styled('div')({
	flexGrow: 1,
});

export default function Filters() {
    const [t] = useTranslation('products')
    const viewProductAttributeTypes = useAppSelector(state => state.get('appData').get('viewProductAttributeTypes'));
    const viewProductAttributeValues = useAppSelector(state => state.get('appData').get('viewProductAttributeValues'));

    const [displayDrawer, setDisplayDrawer] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    
    const getTypeFilters = (type: string) => {
        const type_slug = viewProductAttributeTypes.get(type).get('slug')
        let filters = searchParams.has(type_slug) ? searchParams.get(type_slug).split(',') : [];
        return Array.isArray(filters) ? filters : [filters]
    }

    const onFilterClick = (type: string, value: string) => {
        let filters = getTypeFilters(type)

        if(!filters.includes(value)) {
            filters.push(value)
        } else {
            filters.splice(filters.indexOf(value), 1)
        }

        if(filters.length === 0) {
            searchParams.delete(viewProductAttributeTypes.get(type).get('slug'));
        } else {
            searchParams.set(viewProductAttributeTypes.get(type).get('slug'), filters.join(','))
        }
        
        setSearchParams(searchParams)
    }

    const filtersBody = <>
        {viewProductAttributeTypes.entrySeq().map(([type_id, type]) => <React.Fragment key={'type_'+type_id}>
            <FlexWrapper><FilterInput>{type.get('name')}</FilterInput></FlexWrapper>
            <div>
                {viewProductAttributeValues.filter(v => String(v.get('type')) == type_id).entrySeq().map(([value_id, value]) => {
                    const filters = getTypeFilters(type_id);
                    const selected = filters.includes(String(value_id));
                    return <React.Fragment key={'value_'+value_id}>
                        <CardWrapper onClick={(e:React.MouseEvent) => onFilterClick(type_id, value_id)}>
                            <FilterCard name={value.get('name')} image={value.get('slug')} selected={selected} />
                        </CardWrapper>
                    </React.Fragment>
                })}
            </div>
            <MarginWrapper margin='20px 0 0'><Divider /></MarginWrapper>
        </React.Fragment>)}
    </>

    return <div className="filters-opacity">
        <Box component="div" sx={{p: 2}}>
            <Hidden only={['xs', 'sm', 'md']}>
                <PaddingWrapper pTop='2vh' pBottom='1vh'>
                    <SectionTitle>{t('Filter by')}</SectionTitle>
                </PaddingWrapper>
                {filtersBody}
            </Hidden>
            <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
                <StyledAccordion>
                    <AccordionSummary
                        expandIcon={<img style={{ width: '90%' }} src={require('@resources/img/all-products/double-arrow.svg?url')}></img>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                    <SectionTitle>{t('Filter by')}</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        {filtersBody}
                    </AccordionDetails>
                </StyledAccordion>
            </Hidden>
            <Hidden only={['lg', 'xl']}>
                <div>
                    <FlexWrapper justifyContent='space-between' alignItems='center' onClick={() => setDisplayDrawer(true)}>
                    <SectionTitle>{t('Filter by')}</SectionTitle>
                        <i className='material-icons'>arrow_forward</i>
                    </FlexWrapper>
                </div>
                <StyledDrawer anchor="bottom" open={displayDrawer} onClose={() => setDisplayDrawer(false)}>
                    <div className="drawer-content">
                        <h4 style={{ margin: 0 }}>{t('Select Product')}</h4>
                        {filtersBody}
                    </div>
                </StyledDrawer>
            </Hidden>
        </Box>
    </div>
}