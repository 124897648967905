import * as Immutable from 'immutable';
import PriceStore from '../../UIData/_stores/PriceStore';

export interface IProductOptionStore {
	id: number,
	id_product_variant: number,
	id_product_option_type: number,
	listDependencies: Immutable.List<number>,
	slug: string,
	name: string,
	theorder: number,
	is_default: boolean,
	price_retail: PriceStore,
	price_wholesale: PriceStore,
	price_dropship: PriceStore,
}

const defaultProductOptionStore = Immutable.Record<IProductOptionStore>({
	id: 0,
	id_product_variant: 0,
	id_product_option_type: 0,
	listDependencies: Immutable.List<number>(),
	slug: "",
	name: "",
	theorder: 0,
	is_default: false,
	price_retail: new PriceStore(),
	price_wholesale: new PriceStore(),
	price_dropship: new PriceStore(),
});

export default class ProductOptionStore extends defaultProductOptionStore implements IProductOptionStore {}