import * as Immutable from 'immutable';
import { PaginationItem } from './PaginationStore'
import { DateTime } from 'luxon';

export type BrandingOptionPayload = {
	id: number
  id_type: number
  name: string
  file_url: string
  is_default: boolean
  date_created: string
}

interface IBrandingOptionStore extends PaginationItem {
	id: number
  id_type: number
  name: string
  file_url: string
  is_default: boolean
  date_created: string
}

const defaultBrandingOptionStore = Immutable.Record<IBrandingOptionStore>({
	id: 0,
  id_type: 0,
  name: '',
  file_url: '',
  is_default: false,
  date_created: DateTime.now().toISO(),
});

export class BrandingOptionStore extends defaultBrandingOptionStore implements IBrandingOptionStore {}