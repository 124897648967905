import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../_Sections/SectionDetails';
import SectionGallery from '../_Sections/SectionGallery';
import SectionDropship from '../_Sections/SectionDropship';
import SectionPairings from '../_Sections/SectionPairings';
import SectionBranding from '../_Sections/SectionBranding';
import SectionSizes from '../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../_Sections/SectionPricing';
import tKey from '../../Common/Utils/tKey';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import ProductSEO from '../_Sections/ProductSEO';
import { BlackCheckedIcon } from '../../Common/_components/CheckBoxes';

const PRODUCT_ID = 79;
const VIEW_PRODUCT_ID = 47;

export default function BoyshortsPage() {
  const [t] = useTranslation('page/products/boyshorts');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Boyshorts | Products')}
      description={t('Get cozy with our stylish boyshorts! Soft fabric, flattering fit – the perfect blend of comfort and fashion. Try them now!')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#ff98d3"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '48%',
      }}
      tag={t('The all-over print')}
      title={t('Boyshorts')}
      text={t('Upgrade your e-commerce platform with our vibrant and comfortable boyshorts! These boyshorts offer the perfect blend of style, comfort, and convenience. Made from soft, breathable fabric, they provide a snug fit that feels like a second skin, ensuring all-day comfort. Whether you or your customers are lounging at home or out on the town, our boyshorts promise to keep them feeling confident and stylish.')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/boyshorts/banner-1-' + locale + '.png')}
      alt={t('Picture of Boyshorts')}
      details={[
        {
          img: require('@resources/img/pages/products/boyshorts/icon-1.svg?url'),
          alt: t('Fabric icon'),
          text: t('Made from our super soft peachskin jersey in 92% polyester, 8% spandex'),
        },
        {
          img: require('@resources/img/pages/products/boyshorts/icon-2.svg?url'),
          alt: t('Sewing icon'),
          text: t('Activeseam stitching to move with you and keep seams smooth and bulk free.'),
        },
        {
          img: require('@resources/img/pages/products/boyshorts/icon-3.svg?url'),
          alt: t('Cotton icon'),
          text: t('Lined with cotton spandex for comfort.'),
        },
        {
          img: require('@resources/img/pages/products/boyshorts/icon-4.svg?url'),
          alt: t('Elastic Waistband icon'),
          text: t('Fully printed 1.25" wide elastic waistband.'),
        },
        {
          img: require('@resources/img/pages/products/boyshorts/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/boyshorts/gallery_0.jpg'),
      require('@resources/img/pages/products/boyshorts/gallery_1.jpg'),
      require('@resources/img/pages/products/boyshorts/gallery_2.jpg'),
      require('@resources/img/pages/products/boyshorts/gallery_3.jpg'),
      require('@resources/img/pages/products/boyshorts/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your<br/>Boyshorts <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Boyshorts! At prices designed for reselling, Boyshorts made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
    />

    <SectionSizes
      chart="bottoms"
      productId={PRODUCT_ID}
      title={t('Boyshorts Sizing')}
      text={t('Discover our range of sizes for the perfect fit, our Boyshorts are made with stretchy material, ensuring comfort in every size. For in-between sizes, we recommend sizing up for the perfect fit and feel. Experience ultimate comfort now!')}
    >
    </SectionSizes>

    <SectionBranding
      hasLabel
      title={t('Brand your Boyshorts')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Boyshorts with these great products to create your own unique styles.')}
      productSlugs={['scoop-bralette', 'lounge-pants', 'peignoir-peachskin']}
    />
  </div>
}