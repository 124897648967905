import { createAjaxAction } from "../../Common/_actions/AjaxAction";
import { InvalidatePaginationData } from "./UserDataActions";

export enum UserCreditCardActionType {
    SetupIntentCreditCard = 'CREDIT_CARD_SETUP_INTENT',
    CreateCreditCard = 'CREDIT_CARD_CREATE_CREDIT_CARD',
}


export const SetupIntentCreditCard = () => createAjaxAction<{client_secret:string}>(UserCreditCardActionType.SetupIntentCreditCard, {
	url: "ajax/api/user/creditCards/setup-intent",
	method: "POST",
	onSuccess: (response, dispatch) => {
        
	},
	onFailure: (response, dispatch) => {
		
	}
});

export const CreateCreditCard = (paymentMethod: string) => {
    const data = new FormData();
    data.append('paymentMethod', paymentMethod);

    return createAjaxAction<{}>(UserCreditCardActionType.CreateCreditCard, {
        url: "ajax/api/user/creditCards/create",
        method: "POST",
        data: data,
        onSuccess: (response, dispatch) => {
            dispatch(InvalidatePaginationData('creditCards'));
        },
        onFailure: (response, dispatch) => {
            
        }
    })
}