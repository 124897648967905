import { createAction } from "../../Common/_actions/Action";
import { UserCartPayload } from "../_stores/UserCartStore";

export enum OrderStoreActionType {
  EditOrder = 'ORDER_EDIT_ORDER',
  EditManyOrders = 'ORDER_EDIT_MANY_ORDERS',
  RemoveOrder = 'ORDER_REMOVE_ORDER',
}

export const EditOrder = (order: UserCartPayload) => createAction(OrderStoreActionType.EditOrder, {
	order: order
});

export const EditManyOrders = (orders: Record<string, UserCartPayload>) => createAction(OrderStoreActionType.EditManyOrders, {
	orders: orders
});

export const RemoveOrder = (id: number) => createAction(OrderStoreActionType.RemoveOrder, {
	id: id
});

export type OrderStoreAction =
    ReturnType<typeof EditOrder> |
    ReturnType<typeof EditManyOrders> |
    ReturnType<typeof RemoveOrder>