import { Alert, Button, Checkbox, Divider, FormControlLabel, Grid, Paper, Radio, Stack, Typography } from '@mui/material'
import { VerifiedUserOutlined as VerifiedUserOutlinedIcon, ShoppingCartCheckoutRounded as ShoppingCartCheckoutRoundedIcon } from '@mui/icons-material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckoutSummary from '../Summary/CheckoutSummary'
import CartListItems from '../../Cart/CartItem/CartListItems'
import CheckoutAddressSelector from '../CheckoutAddressSelector'
import { PaymentElement } from '@stripe/react-stripe-js'
import StripePaymentButton from './StripePaymentButton'
import PayPalPaymentButton from './PayPalPaymentButton'
import FreePaymentButton from './FreePaymentButton'
import { CartStack } from '../../Cart/CartContent/CartStack'
import CartListGrid from '../../Cart/CartContent/CartListGrid'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import ItemList from '../../Common/ItemList/ItemList'
import { type UserCreditCardStore } from '../../UserData/_stores/UserCreditCardStore'
import LabLoader from '../../DesignLab/_components/LabLoader'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { DateTime } from 'luxon'
import CreditCardIcon from '../../Account/Settings/CreditCards/CreditCardIcon'

interface Props {
  orders: Immutable.OrderedMap<string, UserCartStore>
  confirmationUrl: string
  stripeReady?: boolean
  stripeUpdate?: number
  paymentOption?: 'card' | 'paypal' | 'free'
  savePaymentMethod: boolean
  setSavePaymentMethod: (value: boolean) => void
  stripeError?: string
}

export default function CheckoutPaymentContent(props: Props) {
  const [t] = useTranslation('cart')

  const countries = useAppSelector(state => state.get('appData').get('countries'))
  const creditCards = useAppSelector(state => state.get('userData').get('creditCards').get('data'))
  const email = useAppSelector(state => state.get('userData').get('email'))
  const loggedIn = useAppSelector(state => state.get('userData').get('loggedIn'))
  const cartLoader = useAppSelector(state => !!state.get('UIData').get('loaders').get('carts') || !!state.get('UIData').get('loaders').get('payment'))

  const [paymentOption, setPaymentOption] = useState<'card' | 'paypal' | 'free'>(props.paymentOption ?? 'card')
  const [selectedCreditCard, setSelectedCreditCard] = useState(0)
  const [disabled, setDisabled] = useState(false)
  const [cardComplete, setCardComplete] = useState(false)
  const [error, setError] = useState('')

  const isFree = useMemo(() => {
    // Check if total is 0$
    let tmpIsFree = true

    props.orders.forEach((cart) => {
      if (cart.get('total').get('amount') > 0) {
        tmpIsFree = false
      }
    })

    return tmpIsFree
  }, [props.orders])

  useEffect(() => {
    // Switch back to card if order isn't free anymore
    if (paymentOption === 'free' && !isFree) {
      setPaymentOption('card')

      // Autoselect credit card
      const firstCreditCard = creditCards.first()
      if (firstCreditCard) {
        setSelectedCreditCard(firstCreditCard.get('id'))
      } else {
        setSelectedCreditCard(0)
      }
      return
    }

    // Switch payment option to free if order is free
    if (paymentOption !== 'free' && isFree) {
      setPaymentOption('free')
    }
  }, [paymentOption, isFree, creditCards])

  useEffect(() => {
    // Reset error on payment option change
    setError('')
  }, [paymentOption, selectedCreditCard])

  useEffect(() => {
    // Autoselect credit card once loaded
    const firstCreditCard = creditCards.first()
    if (firstCreditCard) {
      setSelectedCreditCard(firstCreditCard.get('id'))
    } else {
      setSelectedCreditCard(0)
    }
  }, [creditCards])

  const firstCart = useMemo(() => {
    return props.orders.first()
  }, [props.orders])

  const renderCart = useCallback((cart: UserCartStore) => {
    return <div key={cart.get('id')}>
      <CartStack style={{ paddingBottom: 0 }}>
        <Typography variant="h3" color="primary">{cart.get('cart_name')}</Typography>
      </CartStack>
      <CartListItems cart={cart} short={true} />
    </div>
  }, [])

  const renderCreditCard = useCallback((creditCard: UserCreditCardStore) => {
    // Cards expire at the end of the month, but expiration is saved as 1st of the month
    const expiration = DateTime.fromISO(creditCard.get('expiration')).endOf('month')

    return <Grid key={creditCard.get('id')} item xs={12} sm={6} lg={4}>
      <FormControlLabel
        key={creditCard.get('id')}
        value={creditCard.get('id')}
        label={<div>
          <Stack direction="row" spacing={1} alignItems="center">
            <CreditCardIcon brand={creditCard.get('brand')} />
            <Typography variant="body1">&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {creditCard.get('last4')}</Typography>
          </Stack>
          <Typography variant="caption">{t('Expiration: {{date}}', { date: expiration.toLocaleString({ month: '2-digit', year: '2-digit' }) })}</Typography>
          {expiration.diffNow().as('months') < 0
            ? <Typography variant="subtitle1" color="error.main">{t('Expired')}</Typography>
            : (expiration.diffNow().as('months') < 2
                ? <Typography variant="subtitle1" color="warning.main">{t('Expires soon')}</Typography>
                : null)}
        </div>}
        control={<Radio
          name="payment-option"
          checked={paymentOption === 'card' && selectedCreditCard === creditCard.get('id')}
          onChange={(e) => {
            if (e.target.checked) {
              setPaymentOption('card')
              setSelectedCreditCard(creditCard.get('id'))
            }
          }}
        />}
        disabled={disabled || !!cartLoader}
        style={{
          alignItems: 'flex-start'
        }}
      />
    </Grid>
  }, [disabled, cartLoader, paymentOption, selectedCreditCard])

  const validBillingAddress = useMemo(() => {
    let valid = true
    props.orders.forEach(cart => {
      valid = valid &&
        cart.get('billing_address') !== '' &&
        cart.get('billing_country') !== ''
    })
    return valid
  }, [props.orders])

  const paymentButtonText = useMemo(() => {
    return firstCart?.get('order_from') === 'invoice' ? t('Pay') : t('Place my order')
  }, [firstCart, t])

  if (!firstCart) {
    // How did we get here without a cart?
    return null
  }

  return <>
    <CartListGrid>
      <Paper
        elevation={0}
      >
        <Stack divider={<Divider variant="bold" />}>
          <CheckoutAddressSelector
            cart={firstCart}
            hasMultipleCarts={props.orders.count() > 1}
            type="billing"
            disabled={disabled || !!cartLoader}
          />

          {paymentOption === 'free' ? <CartStack>
            <Typography variant="h3" color="primary">{t('Free – No payment required')}</Typography>
          </CartStack> : <div>
            <CartStack style={{ paddingBottom: 0 }}>
              <Typography variant="h3" color="primary">{t('Payment')}</Typography>
            </CartStack>
            <CartStack spacing={2}>
              <Alert
                severity="success"
                icon={<VerifiedUserOutlinedIcon />}
              >{t('Your payment information is encrypted and is never stored on our servers.')}</Alert>

              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4">{t('Pay by Credit Card')}</Typography>
                  </Grid>
                  <ItemList
                    dataType="creditCards"
                    controlled
                    limit={9999}
                    item={renderCreditCard}
                    render={(items, links, loading) => {
                      return loading ? <>
                        <Grid item xs={12}>
                          <LabLoader />
                        </Grid>
                      </> : <>
                        {items}

                        <Grid item xs={12} sm={6} lg={4}>
                          <FormControlLabel
                            value={0}
                            label={<div>
                              <Typography variant="body1" component="p">{t('Add new card')}</Typography>
                            </div>}
                            control={<Radio
                              name="payment-option"
                              checked={paymentOption === 'card' && selectedCreditCard === 0}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setPaymentOption('card')
                                  setSelectedCreditCard(0)
                                }
                              }}
                            />}
                            disabled={disabled || !!cartLoader}
                            style={{
                              alignItems: 'flex-start'
                            }}
                          />
                        </Grid>

                        {props.stripeReady && paymentOption === 'card' && selectedCreditCard === 0 ? <Grid item xs={12}>
                          <PaymentElement
                            options={{
                              business: {
                                name: 'Art of Where'
                              },
                              defaultValues: {
                                billingDetails: {
                                  name: firstCart.get('billing_name'),
                                  phone: firstCart.get('billing_phone'),
                                  email,
                                  address: {
                                    line1: firstCart.get('address'),
                                    line2: firstCart.get('address2'),
                                    city: firstCart.get('billing_city'),
                                    postal_code: firstCart.get('billing_postalCode'),
                                    state: firstCart.get('billing_province'),
                                    country: countries.get(firstCart.get('billing_country'))?.get('name')

                                  }
                                }
                              }
                            }}
                            onChange={(e) => {
                              setCardComplete(e.complete)
                            }}
                          />
                          {loggedIn ? <FormControlLabel
                            control={<Checkbox
                              checked={props.savePaymentMethod}
                              disabled={disabled || !!cartLoader}
                              onChange={(e) => {
                                props.setSavePaymentMethod(e.target.checked)
                              }}
                            />}
                            label={t('Save this payment method in my account')}
                          /> : null}
                        </Grid> : null}
                      </>
                    }}
                  />
                </Grid>
              </div>
            </CartStack>

            <Divider />

            <CartStack>
              <Typography variant="h4">{t('Pay with PayPal')}</Typography>
              <FormControlLabel
                value={0}
                label={<div>
                  <img
                    src={require('@resources/img/cart/paypal-logo.svg?url')} alt="PayPal"
                    width={90}
                    height={24}
                  />
                </div>}
                control={<Radio
                  name="payment-option"
                  checked={paymentOption === 'paypal'}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setPaymentOption('paypal')
                    }
                  }}
                />}
                disabled={disabled || !!cartLoader}
                style={{
                  alignItems: 'flex-start'
                }}
              />
            </CartStack>
          </div>}

          {props.orders.valueSeq().map(renderCart)}
        </Stack>
      </Paper>
    </CartListGrid>

    <CheckoutSummary
      carts={props.orders}
      disabled={disabled}
      alert={!validBillingAddress
        ? <Alert
          severity="info"
          variant="outlined"
        >{t('Fill out your billing address to proceed.')}</Alert>
        : (paymentOption === 'card' ? (
            props.stripeError
              ? <Alert
              severity="error"
              variant="outlined"
            >{props.stripeError}</Alert>
              : (selectedCreditCard === 0 && !cardComplete
                  ? <Alert
                severity="info"
                variant="outlined"
              >{t('Enter your credit card or select PayPal to proceed.')}</Alert>
                  : (error
                      ? <Alert
                  severity="error"
                  variant="outlined"
                >{error}</Alert>
                      : null
                    ))
          ) : (error
            ? <Alert
            severity="error"
            variant="outlined"
          >{error}</Alert>
            : null
          )
          )}
      action={<>
        {paymentOption === 'free' ? <FreePaymentButton
          orders={props.orders}
          confirmationUrl={props.confirmationUrl}
          onErrorText={setError}
          onDisable={setDisabled}
          disabled={disabled || !!cartLoader}
          variant="contained"
          color="accent"
          size="large"
          startIcon={<ShoppingCartCheckoutRoundedIcon />}
        >{paymentButtonText}</FreePaymentButton> : (paymentOption === 'paypal' ? <PayPalPaymentButton
          orders={props.orders}
          confirmationUrl={props.confirmationUrl}
          onErrorText={setError}
          onDisable={setDisabled}
          disabled={disabled || !!cartLoader}
        /> : (props.stripeReady ? <StripePaymentButton
          orders={props.orders}
          confirmationUrl={props.confirmationUrl}
          selectedCreditCard={selectedCreditCard}
          savePaymentMethod={props.savePaymentMethod}
          stripeUpdate={props.stripeUpdate}
          onErrorText={setError}
          onDisable={setDisabled}
          disabled={disabled || (selectedCreditCard === 0 && !cardComplete) || !!cartLoader}
          variant="contained"
          color="accent"
          size="large"
          startIcon={<ShoppingCartCheckoutRoundedIcon />}
        >{paymentButtonText}</StripePaymentButton> : <Button
          disabled={true}
          variant="contained"
          color="accent"
          size="large"
          startIcon={<ShoppingCartCheckoutRoundedIcon />}
        >{paymentButtonText}</Button>)
        )}
      </>}
    />
  </>
}
