import { useContext, useEffect } from "react";
import { Color } from "three";
import ModelElements from "../ModelElements";
import { ProductModelProps } from "../ModelViewerProduct";
import ProductTextureContext from "../_contexts/ProductTextureContext";
import useGLB from "../_hooks/useGLB";

export default function VeganLeatherMakeupBag(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/vegan-leather-makeup-bag.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children

    const [productTexture] = useContext(ProductTextureContext)
    const option_color = props.options.get('color') ?? 'black'
    const color = new Color({
        "black": 0x1a1919,
        "white": 0xffffff,
    }[option_color])

    useEffect(() => {
        if(!elements || !elements[1]?.material) return

        elements[1].material.color = color
        elements[2].material.color = color
        elements[4].material.color = color
        elements[3].material.color = option_color == "black" ? new Color(0x999999) : new Color(0xD5B27C)
    }, [option_color])

    // Easier to fine-tune metal values here
    useEffect(() => {
        if(!elements || !elements[3]?.material) return

        elements[3].material.roughness = 0.10 
    }, [elements])

    // Apply lab texture
    useEffect(() => {
        if(!elements || !elements[0]?.material) return

        elements[0].material.map = productTexture
        elements[0].material.map.needsUpdate = true
    }, [productTexture])
    
    return <ModelElements elements={elements}/>
}