import * as Immutable from 'immutable';
import { DateTime } from 'luxon';
import { PackageItemPayload, PackageItemStore } from './PackageItemStore';

export type PackagePayload = {
	id: number
  id_status: number
  date_shipped: string
  tracking_number: string|null
  tracking_url: string|null
  listItems: Record<string, PackageItemPayload>
}

interface IPackageStore {
	id: number
  id_status: number
  date_shipped: string
  tracking_number: string|null
  tracking_url: string|null
  listItems: Immutable.OrderedMap<string, PackageItemStore>
}

const defaultPackageStore = Immutable.Record<IPackageStore>({
	id: 0,
  id_status: 0,
  date_shipped: DateTime.now().toISO(),
  tracking_number: null,
  tracking_url: null,
  listItems: Immutable.OrderedMap<string, PackageItemStore>(),
});

export class PackageStore extends defaultPackageStore implements IPackageStore {}