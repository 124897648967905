import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react';
import Dialog from '../Common/Dialog/Dialog';
import DialogContent from '../Common/Dialog/DialogContent';
import { Alert, Button, Collapse, Grid, Stack, TextField, Typography } from '@mui/material';
import DialogTitle from '../Common/Dialog/DialogTitle';
import { useTranslation } from 'react-i18next';
import SendFD from '../Common/Utils/SendFD';
import { useAppDispatch } from '../Common/_hooks/useAppDispatch';
import { ResetPasswordRequest } from './_actions/AuthActions';
import ForgotPasswordForm from './ForgotPasswordForm';

type Props = {
  opened: boolean
  onClose: () => void
}

export default function ForgotPasswordDialog(props: Props) {
  const [t] = useTranslation('auth');

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    showCloseButton
    onClose={props.onClose}
  >
    <DialogTitle>{t('Forgot your password?')}</DialogTitle>

    <DialogContent>
      <ForgotPasswordForm/>
    </DialogContent>
  </Dialog>
}