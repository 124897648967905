import i18next from "i18next";
import { createAjaxAction } from "../../Common/_actions/AjaxAction";
import { Toast } from "../../UIData/_actions/UIDataActions";
import { InvalidatePaginationData } from "./UserDataActions";
import ApiErrorHandler from "../../Common/Error/ApiErrorHandler";
import { UserCartPayload } from "../_stores/UserCartStore";
import { EditManyOrders } from "./OrderStoreActions";

export enum UserAddressActionType {
	CreateUserAddress = 'USER_CREATE_USER_ADDRESS',
  UpdateUserAddress = 'USER_UPDATE_USER_ADDRESS',
}

export const CreateUserAddress = (data: FormData) => createAjaxAction(UserAddressActionType.CreateUserAddress, {
	url: "ajax/api/user/addresses/create",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(InvalidatePaginationData('addresses'));
		dispatch(Toast(i18next.t('Address updated successfully!')));
	},
	onFailure: ApiErrorHandler(UserAddressActionType.CreateUserAddress)
});

export const UpdateUserAddress = (data: FormData) => createAjaxAction<{orders: Record<string,UserCartPayload>}>(UserAddressActionType.UpdateUserAddress, {
	url: "ajax/api/user/addresses/update",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(InvalidatePaginationData('addresses'));
		dispatch(Toast(i18next.t('Address updated successfully!')));

		dispatch(EditManyOrders(response.data.orders))
	},
	onFailure: ApiErrorHandler(UserAddressActionType.UpdateUserAddress)
});