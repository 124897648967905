import * as Immutable from 'immutable';
import { PaginationItem } from './PaginationStore'
import { DateTime } from 'luxon';
import { StoreItemPayload, StoreItemStore } from './StoreItemStore';

export type StoreOrderPayload = {
	id: number
  store_id: string
  id_cart: number
  store_url: string|null
  listItems: Record<string,StoreItemPayload>
}

interface IStoreOrderStore {
	id: number
  store_id: string
  id_cart: number
  store_url: string|null
  listItems: Immutable.OrderedMap<string,StoreItemStore>
}

const defaultStoreOrderStore = Immutable.Record<IStoreOrderStore>({
	id: 0,
  store_id: '',
  id_cart: 0,
  store_url: null,
  listItems: Immutable.OrderedMap<string,StoreItemStore>(),
});

export class StoreOrderStore extends defaultStoreOrderStore implements IStoreOrderStore {}