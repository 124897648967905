import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import useLabData from './useLabData';

export default function useSceneData():Record<string,any>|undefined {
    const activeVariant = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeVariant'));
    const activeScene = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeScene'));

    const labData = useLabData();

    return labData?.variants[activeVariant]?.scenes[activeScene];
}