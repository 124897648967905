import { Alert, Box, Button, Collapse, Container, Paper, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import tKey from "../Common/Utils/tKey";
import { useCallback, useState } from "react";
import { useAppDispatch } from "../Common/_hooks/useAppDispatch";
import { ResendValidationLink } from "./_actions/AuthActions";
import { useAppSelector } from "../Common/_hooks/useAppSelector";

export default function AccountNotValidated() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('auth')
  const [tR] = useTranslation('routes')

  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('auth'))

  const email = useAppSelector(state => state.get('userData').get('email'))

  const [sent,setSent] = useState(false)

  const onClick = useCallback(() => {
    dispatch(ResendValidationLink().set({
      onSuccess: () => setSent(true)
    }))
  }, [])

  return <Box component="div" sx={{
    backgroundImage: 'url(' + require('@resources/img/theme/page/ornaments-1.svg?url') + ')',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    pt: 6,
    pb: 15,
    flex: '1 0 auto',
  }}>
    <Container
      maxWidth="sm"
    >
      <Helmet>
        <title>{t('Account not validated')}</title>
      </Helmet>

      <Stack spacing={3}>
        <Typography variant="h1" textAlign="center">{t('Account not validated')}</Typography>
        <Paper
          elevation={0}
          sx={{
            p: 4,
          }}
        >
          <Stack spacing={2} alignItems="flex-start">
            <Typography variant="body1">
              <Trans
                t={t}
                i18nKey={tKey('You need to validate your email address before you can access your account. You should have received an email at <strong>{{email}}</strong> when signing up.')}
                values={{
                  email: email
                }}
                components={{
                  strong: <strong/>
                }}
              />
            </Typography>
            <Typography variant="body1">{t('If you can\'t find the email, click on the button below and we\'ll resend the email with the validation link.')}</Typography>

            <div>
              <Collapse in={!sent}>
                <Button
                  variant="contained"
                  color="accent"
                  size="large"
                  onClick={onClick}
                  disabled={!!loader}
                >{t('Resend validation link')}</Button>
              </Collapse>
              
              <Collapse in={sent}>
                <Alert
                  severity="success"
                >{t('A validation link was resent to your email address.')}</Alert>
              </Collapse>
            </div>

            <Typography variant="body1">
              <Trans
                t={t}
                i18nKey={tKey('If the problem persists, feel free to <contactLink>contact us</contactLink>.')}
                components={{
                  contactLink: <Link to={tR('/contact-us')} reloadDocument/>,
                }}
              />
            </Typography>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  </Box>
}