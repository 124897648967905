import { Grid, TextField, Typography, Link as MuiLink, Stack, Button } from '@mui/material'
import { CartStack } from '../../Cart/CartContent/CartStack'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { type FormEvent, useCallback, useState, useMemo, type ChangeEvent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { SaveContactDetails, UserDataActionType } from '../../UserData/_actions/UserDataActions'
import { OpenAuthModal } from '../../Auth/_actions/AuthActions'
import { CheckCircleOutlineRounded as CheckCircleOutlineRoundedIcon } from '@mui/icons-material'
import tKey from '../../Common/Utils/tKey'

export default function CheckoutContactInfo() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const userEmail = useAppSelector(state => state.get('userData').get('email'))
  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))
  const error = useAppSelector(state => state.get('UIData').get('apiErrors').get(UserDataActionType.SaveContactDetails))

  const [contactEmail, setContactEmail] = useState(userEmail)

  const onChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setContactEmail(e.target.value.trim())
  }, [])

  const onOpenSignup = useCallback(() => {
    dispatch(OpenAuthModal(true))
  }, [])

  const onOpenLogin = useCallback(() => {
    dispatch(OpenAuthModal(false))
  }, [])

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const fd = new FormData()
    fd.append('email', contactEmail)
    dispatch(SaveContactDetails(fd))
  }, [contactEmail])

  const isComplete = useMemo(() => {
    return userEmail !== '' && contactEmail === userEmail
  }, [userEmail, contactEmail])

  return <CartStack>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" color="primary">{t('Contact information')}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <form onSubmit={onSubmit}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <TextField
              label={t('Email address')}
              value={contactEmail}
              onChange={onChangeEmail}
              helperText={!!error
                ? (error.error === 'email-exists'
                  ? t('An account with this email already exists. Please login or use a different email.')
                  : t('Please enter a valid email.')
                ) : t('A confirmation email will be sent after checkout.')}
              type="email"
              error={!!error}
              disabled={cartLoader}
              InputProps={{
                endAdornment: isComplete ? <CheckCircleOutlineRoundedIcon
                  fontSize="medium"
                  color="success"
                /> : <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  disabled={cartLoader}
                  style={{
                    margin: '-10px -16px -10px 0'
                  }}
                >{t('Confirm')}</Button>
              }}
            />
          </Stack>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="p">
          <Trans
            t={t}
            i18nKey={tKey('Would you like to create an account? <signupLink>Sign up</signupLink> or <loginLink>login</loginLink>')}
            components={{
              signupLink: <MuiLink component="button" onClick={onOpenSignup} />,
              loginLink: <MuiLink component="button" onClick={onOpenLogin} />,
            }}
          />
        </Typography>
      </Grid>
    </Grid>
  </CartStack>
}
