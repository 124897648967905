import { Checkbox, FormControlLabel, Grid, TextField, styled } from '@mui/material';
import * as React from 'react';
import { Trans, Translation } from 'react-i18next';
import ProductOptionStore from '../../AppData/_stores/ProductOptionStore';
import ProductStore from '../../AppData/_stores/ProductStore';
import { PinkHeight45FullWidthButton } from '../../Common/_components/Buttons';
import { BlackCheckedIcon, PinkCheckedIcon, UncheckedWhiteBackgroundIcon } from '../../Common/_components/CheckBoxes';
import Hidden from '../../Common/_components/Hidden';
import { ModalSubtitle, ModalTitle, OptionLabel, OptionTitle } from '../../Common/_components/Typography';
import { UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore';
import ListingStore from '../_stores/ListingStore';

export type Props = {
	title: string,
	description: string,
	update_preset: boolean,
	disabled_preview_ids: Array<number>,
	disabled_option_ids: Array<number>,
	onFormChange: Function,

	index ?: number
	handleStep ?: (step:number) => void
	labExit ?: boolean
	massPresets ?: boolean
	listing: ListingStore
	productDesign?: UserProductDesignStore
	product: ProductStore
}
type State = {
	applyChangesMassPublishing: boolean
}

const GridTitle = styled(Grid)`
	margin-bottom: 32px;
`;
const PreviewImage = styled('img')`
	width: 100%;
	height: auto;
`;
const RightContainer = styled(Grid)`
	gap: 30px;
	padding-left: 40px;
	@media(max-width: 1280px){
		padding-left: 20px;
	}
	@media(max-width: 600px){
		padding-left: 0;
		gap: 20px;
	}
`;
const OptionsContainer = styled(Grid)`
	gap: 30px;
	@media(max-width: 600px){
		gap: 20px;
	}
`;
const MockupsContainer = styled('div')`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(122px, 1fr));
	gap: 5px;
`;
const MockupParentDiv = styled('div')`
	padding: 4.5px;
	border: none;
	position: relative;
	&.checked{
		outline: 1px solid #DA3192; 
	}
	&:hover{
		background-color: #FCEFF7;
	}
`;
const MockupDiv = styled('div')`
	display: flex;
	justify-content:center;
	background-color: #EDEDED;
	height: 100%;
	align-items: center;
	cursor: pointer;
	img{
		width: 100%;
		height: auto;
	}
	&:hover{
		background-color: #FCEFF7;
	}
`;
const PreviewGrid = styled(Grid)`
	@media (min-width: 1280px){
		max-width: 291px;
	}
`;
const OptionsGrid = styled(Grid)`
	margin-top: 30px;
	@media (min-width: 1280px){
		flex-grow: 1;
		max-width: 100%;
	}
`;
const MarginTop10Left5 = styled('div')`
	margin-top: 10px;
	margin-left: -4.5px;
`;
const UpdatePresetGrid = styled(Grid)`
	background: #F2F2F2;
	border-radius: 2px;
	padding: 20px 19.5px;
	@media(max-width:600px){
		padding: 20px 14px;
	}
`;
const MockupCheckBoxDiv = styled('div')`
	position: absolute;
	top: 4px;
	left: 16px;
`;
const DescriptionGrid = styled(Grid)`
	@media(max-width:600px){
		margin-top: 30px;		
	}
`;
const MarginTopSaveButton = styled('div')`
	margin-top: 60px;
`;
const FullWidthDiv = styled('div')`
	width: 100%;
`;

const T = ({children, ...otherProps}:any) => <Trans ns="dashboard" {...otherProps}>{children}</Trans>

export default class EditProductDetails extends React.Component<Props> {

	public state = {
		applyChangesMassPublishing: false,
	} as State

	onChangeOption = (listIds:number[]) => {
		let disabled_option_ids = [...this.props.disabled_option_ids];

		listIds.forEach((id) => {
			let index = disabled_option_ids.indexOf(id);

			if(index !== -1) {
				disabled_option_ids.splice(index, 1);
			} else {
				disabled_option_ids.push(id);
			}
		});

		this.props.onFormChange('disabled_option_ids', disabled_option_ids);
	}

	onChangeMockup = (id:number) => {
		let disabled_preview_ids = [...this.props.disabled_preview_ids];
		let index = this.props.disabled_preview_ids.indexOf(id);

		if(index !== -1) {
			disabled_preview_ids.splice(index, 1);
		} else {
			disabled_preview_ids.push(id);
		}

		this.props.onFormChange('disabled_preview_ids', disabled_preview_ids);
	}

	getMockups = () => {
		let previews = this.props.massPresets ? this.props.product.get('previews') : this.props.productDesign.get('previews');
		return <MockupsContainer>
			{ previews.entrySeq().map(([previewId, preview]) => {
				let checked = this.props.disabled_preview_ids.indexOf(preview.get('id')) === -1;

				return <MockupParentDiv key={previewId} className={checked && "checked"} >
					<MockupDiv onClick={() => this.onChangeMockup(preview.get('id'))}>
						<img src={preview.get('url')} alt="" />
					</MockupDiv>
					<MockupCheckBoxDiv>
						<FormControlLabel
							control={
								<Checkbox
									color="secondary"
									checked={checked}
									name="mock-check"
									icon={<UncheckedWhiteBackgroundIcon />}
									checkedIcon={<PinkCheckedIcon />}
								/>
							}
							label=""
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeMockup(preview.get('id'))}
						/>
					</MockupCheckBoxDiv>
				</MockupParentDiv>
			}) }
		</MockupsContainer>
	}

	render = () =>{
		if(!this.props.product || 
			(!this.props.massPresets && !this.props.productDesign)
		) return null;

		let labExit = (this.props.labExit ? true : false) 

		let thumbnail = this.props.massPresets ? this.props.product.get('previews').first().get('url') : this.props.productDesign.get('thumbnail');
		let nbPreviews = this.props.massPresets ? this.props.product.get('previews').count() : this.props.productDesign.get('previews').count();
		let availableOptionTypes = this.props.massPresets ? 
			this.props.product.get('availableOptionTypes') 
			: this.props.productDesign.get('availableOptionTypes');

		return <>
			<GridTitle>
				{this.props.massPresets ? 
					<ModalTitle><T>Edit Preset Details</T></ModalTitle>
					:
					<ModalTitle><T>Edit Product Details</T></ModalTitle>
				}
				
				<ModalSubtitle><T>Edit product details for this listing</T></ModalSubtitle>
			</GridTitle>
			<Grid container direction="row">
				<PreviewGrid item xs={12} sm={4}>
					<PreviewImage src={ thumbnail } alt=""></PreviewImage>
				</PreviewGrid>
				<DescriptionGrid item xs ={12} sm={8}>
					<Translation>
						{ t => 
						<RightContainer container direction='column'>
							{!this.props.massPresets &&
								<Grid item>
									<TextField
										label={t("Product title")}
										value={this.props.title}
										onChange={(e) => this.props.onFormChange('title', e.target.value)}
									/>
								</Grid>
							}
							<Grid item>
								<Hidden smDown>
									<TextField
										multiline={true}
										rows={7}
										label={t("Product Description")}
										value={this.props.description}
										onChange={(e) => this.props.onFormChange('description', e.target.value)}
									/>
								</Hidden>
								<Hidden smUp>
									<TextField
										multiline={true}
										rows={3}
										label={t("Product Description")}
										value={this.props.description}
										onChange={(e) => this.props.onFormChange('description', e.target.value)}
									/>
								</Hidden>
							</Grid>
						</RightContainer>
						}
					</Translation>
				</DescriptionGrid>
				<OptionsGrid item xs={12}>
					<OptionsContainer container direction="column">
						{ availableOptionTypes.valueSeq().map(type => {
							//Skip if hidden option type or it has only 0 or 1 options
							if(!type.get('visible') || type.get('options').count() <= 1) {
								return null;
							}

							//Group options with the same slug together
							let counter = 0;
							let options = new Map<string,{ name: string, listOptions: Array<ProductOptionStore> }>();
							type.get('options').forEach((option) => {
								if(options.has(option.get('slug'))) {
									//Add to existing array of this option slug
									options.get(option.get('slug')).listOptions.push(option);
									return;
								}

								//Init array for this option slug
								options.set(option.get('slug'), {
									name: option.get('name'),
									listOptions: [option],
								});

								//Update counter
								if(this.props.disabled_option_ids.indexOf(option.get('id')) === -1) {
									counter++
								}
							});

							return <Grid key={type.get('id')} item>
								<OptionTitle>{ type.get('name') } ({ counter+'/'+options.size })</OptionTitle>
								<Grid container direction="row">
									{ Array.from(options).map(([optionSlug, optionGroup]) => {
										let checked = true;

										optionGroup.listOptions.forEach((option) => {
											if(this.props.disabled_option_ids.indexOf(option.get('id')) !== -1) {
												checked = false;
											}
										});

										return <Grid key={optionSlug} item>
											<FormControlLabel
												control={
													<Checkbox
														color="secondary"
														checked={checked}
														name={type.get('slug')}
														icon={<UncheckedWhiteBackgroundIcon />}
														checkedIcon={<BlackCheckedIcon />}
													/>
												}
												label={<OptionLabel>{ optionGroup.name }</OptionLabel>}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													this.onChangeOption(optionGroup.listOptions.map((option) => option.get('id')));
												}}
											/>
										</Grid>
									})}
								</Grid>
							</Grid>
						})}
						
						<FullWidthDiv>
							<OptionTitle><T>Mockups</T> (
								{(nbPreviews - this.props.disabled_preview_ids.length)
								+'/'
								+nbPreviews
							})</OptionTitle>
							<MarginTop10Left5>
								{this.getMockups()}
							</MarginTop10Left5>
						</FullWidthDiv>
						{ !this.props.massPresets && <UpdatePresetGrid item>
							<div>	
								<h5 style={{margin:0}}><T>Would you like to update the preset for this product?</T></h5>
								<FormControlLabel
									control={
										<Checkbox
											color="secondary"
											checked={this.props.update_preset}
											name="ask-update-preset"
											icon={<UncheckedWhiteBackgroundIcon />}
											checkedIcon={<BlackCheckedIcon />}
										/>
									}
									label={<OptionLabel><T>Update current product preset with the following details</T></OptionLabel>}
								    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.onFormChange('update_preset', e.target.checked)}
								/>
							</div>
						</UpdatePresetGrid> }
					</OptionsContainer>
				</OptionsGrid>
			</Grid>

			{labExit && <>
				<MarginTopSaveButton>
					<PinkHeight45FullWidthButton onClick={() => this.props.handleStep(this.props.index + 1)}>
						<T>Save</T> &amp; <T>continue</T>
					</PinkHeight45FullWidthButton>
				</MarginTopSaveButton>
			</>}
			
		</>;
		}
}