import { type MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import Dialog from '../../Common/Dialog/Dialog'
import DialogContent from '../../Common/Dialog/DialogContent'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import ItemList from '../../Common/ItemList/ItemList'
import ProductDesign, { ProductCardWrapper } from '../Products/ProductDesign'
import ProductSearchBar, { type ProductSearchParams } from '../Products/ProductSearchBar'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { useSearchParams } from 'react-router-dom'
import { Button, Grid, Skeleton, ToggleButton, ToggleButtonGroup, styled } from '@mui/material'
import { type UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore'
import { ViewList as ViewListIcon, ViewModule as ViewModuleIcon, KeyboardBackspaceRounded as KeyboardBackspaceRoundedIcon } from '@mui/icons-material'
import { SaveUserPrefs } from '../../UserData/_actions/UserDataActions'
import { ProductItem } from '../../UserData/_stores/UserCartStore'
import { type StoreItemStore } from '../../UserData/_stores/StoreItemStore'
import { UpdateCartItem } from '../../UserData/_actions/UserCartActions'
import OrderDialogContent from '../Products/OrderDialogContent'

const ProductSkeleton = styled(Skeleton)({
  backgroundColor: 'rgba(0,0,0,0.1)',
  width: '100%',
  height: 'auto',
  boxSizing: 'content-box',
  aspectRatio: '1120 / 1000',
  paddingBottom: '100px',
  borderRadius: '20px'
})

const ProductCardSkeleton = styled(ProductCardWrapper)({
})

const StyledToggleButton = styled(ToggleButton)({
  background: 'none',
  border: 0,
  borderRadius: 0
})

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  alignSelf: 'stretch',

  [`${StyledToggleButton}`]: {
    '&:not(:first-of-type)': {
      border: 0,
      margin: 0
    }
  }
})

interface Props {
  idCart: number
  storeItem: StoreItemStore
  item?: ProductItem
  opened: boolean
  onClose: () => void
}

const SEARCH_FIELDS = ['search', 'categories', 'collections']

export default function SyncProductDialog(props: Props) {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const userLayout = useAppSelector(state => state.get('userData').get('prefs').get('accountProductsLayout'))
  const userLimit = useAppSelector(state => state.get('userData').get('prefs').get('accountPageLimit'))

  const [selectedProductDesignId, setSelectedProductDesignId] = useState<string | undefined>(props.item ? props.item.get('id_product_design') + '|' + props.item.get('product_id') : undefined)

  const [layout, setLayout] = useState<'grid' | 'list'>(userLayout)
  const categories = searchParams.get('categories')
  const searchQuery = searchParams.get('search')
  const [search, setSearch] = useState<ProductSearchParams>({
    categories: categories ? categories.split(',') : [],
    search: searchQuery ? searchQuery.split(',') : [],
    collections: []
  })

  useEffect(() => {
    if (props.item) {
      setSelectedProductDesignId(props.item.get('id_product_design') + '|' + props.item.get('product_id'))
    } else {
      setSelectedProductDesignId(undefined)
    }
  }, [props.item])

  const loadingGrid = useMemo(() => {
    const listSkeletons = []

    for (let i = 0; i < userLimit; i++) {
      listSkeletons.push(<ProductCardSkeleton key={i} item><ProductSkeleton variant="rectangular" /></ProductCardSkeleton>)
    }

    return listSkeletons
  }, [userLimit])

  useEffect(() => {
    // Save preferences if any have changed
    if (layout === userLayout) {
      return
    }

    const fd = new FormData()
    fd.append('accountProductsLayout', layout)

    dispatch(SaveUserPrefs(fd, true))
  }, [layout])

  const onLayoutChange = useCallback((e: MouseEvent<HTMLElement>, value?: 'grid' | 'list') => {
    if (!value) return
    setLayout(value)
  }, [])

  const renderItem = useCallback((item: UserProductDesignStore) => {
    return <ProductDesign
      item={item}
      view={layout}
      actions={<Button
        variant="contained"
        color="accent"
        onClick={() => {
          setSelectedProductDesignId(item.get('id'))
        }}
      >{t('Select')}</Button>}
    />
  }, [layout, props.idCart, props.item, props.storeItem])

  const renderList = useCallback((items: JSX.Element | null, links: JSX.Element | null, loading: boolean) => {
    return <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {loading
          ? loadingGrid
          : (items ?? <Grid item xs={12}>
            <span>{t('No Results')}</span>
          </Grid>
            )
        }
      </Grid>

      {links}
    </>
  }, [loadingGrid, t])

  const cartItem = useMemo(() => {
    if (!selectedProductDesignId) {
      return new ProductItem({
        id: props.item?.get('id') ?? 0,
        quantity: props.storeItem.get('quantity')
      })
    }

    return props.item?.get('id_product_design') === Number(selectedProductDesignId.split('|')[0]) &&
      props.item?.get('product_id') === Number(selectedProductDesignId.split('|')[1])
      ? props.item : new ProductItem({
        id: props.item?.get('id') ?? 0,
        quantity: props.storeItem.get('quantity')
      })
  }, [props.item, props.storeItem, selectedProductDesignId])

  const onConfirm = useCallback((listOptions: Immutable.Map<number, number>, listBrandingOptions: Immutable.Map<number, number>) => {
    if (!selectedProductDesignId) return

    const fd = new FormData()

    fd.append('cart_id', String(props.idCart))

    if (cartItem.get('id') > 0) {
      fd.append('id', String(cartItem.get('id')))
    }

    fd.append('id_store_item', String(props.storeItem.get('id')))

    fd.append('fields[]', 'item_id')
    fd.append('fields[]', 'product_id')
    fd.append('fields[]', 'options')
    fd.append('fields[]', 'quantity')

    fd.append('type', 'product')
    fd.append('item_id', selectedProductDesignId.split('|')[0])
    fd.append('product_id', selectedProductDesignId.split('|')[1])
    fd.append('quantity', String(cartItem.get('quantity')))

    listOptions.forEach((optionId, optionTypeId) => {
      fd.append('options[]', String(optionId))
    })

    listBrandingOptions.forEach((brandingOptionId, optionId) => {
      fd.append('brandingOptions[' + optionId + ']', String(brandingOptionId))
    })

    dispatch(UpdateCartItem(fd).set({
      onSuccess: () => {
        props.onClose()
      }
    }))
  }, [selectedProductDesignId, cartItem, props.idCart])

  return <Dialog
    open={props.opened}
    maxWidth={selectedProductDesignId === undefined ? 'lg' : 'md'}
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    { selectedProductDesignId === undefined ? <DialogTitle>{t('Sync product')}</DialogTitle> : null }
    { selectedProductDesignId === undefined ? <DialogContent>
      <ProductSearchBar
        placeholder={t('Search your products...')}
        value={search}
        onChange={setSearch}
      >
        <StyledToggleButtonGroup
          value={layout}
          exclusive
          onChange={onLayoutChange}
        >
          <StyledToggleButton value="grid">
            <ViewModuleIcon />
          </StyledToggleButton>
          <StyledToggleButton value="list">
            <ViewListIcon />
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </ProductSearchBar>
      <ItemList
        dataType={'productDesigns'}
        controlled={true}
        item={renderItem}
        searchQuery={search}
        searchFields={SEARCH_FIELDS}
        limit={userLimit}
        render={renderList}
      />
    </DialogContent> : <>
      <div style={{
        padding: '10px 25px 0',
        marginBottom: '-15px'
      }}>
        <Button
          variant="text"
          color="secondary"
          startIcon={<KeyboardBackspaceRoundedIcon/>}
          onClick={() => setSelectedProductDesignId(undefined) }
        >{t('Back to products')}</Button>
      </div>
      <OrderDialogContent
        opened={selectedProductDesignId !== undefined}
        productDesignId={selectedProductDesignId}
        item={cartItem}
        onConfirm={onConfirm}
        onClose={() => setSelectedProductDesignId(undefined)}
      />
    </> }
  </Dialog>
}
