import { Grid, styled } from '@mui/material';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { ButtonWhiteHeight45Weight500Larger, PreviousButton } from '../../Common/_components/Buttons';
import { SuccessMsgSubTitle, SuccessMsgTitle } from '../../Common/_components/Typography';
export type Props = {
	index ?: number
	handleStep ?: (step:number) => void
}

const MsgContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	gap: '10px',
	marginTop: '30px',
});
const FullWidthGrid = styled(Grid)({
	width: '100%',
});
const PreviousButtonContainer = styled(Grid)({
	marginTop: '20px',

	'@media (max-width: 960px)': {

	}
});

const ButtonsContainer = styled('div')({
	marginTop: '60px',
	display: 'flex',
	gap: '10px',

	'@media(max-width: 960px)': {
		marginTop: '20px',
	},
});

const T = ({children, ...otherProps}:any) => <Trans ns="dashboard" {...otherProps}>{children}</Trans>

export default class Success extends React.Component<Props> {

	componentDidMount(){
		// if(window.innerWidth > 960){
		// 	let massModal =  document.body.querySelector('.mass-modal')
		// 	massModal.classList.add('height-fit-content')
		// }
	}
	private handleGoPreviousState = () => {
		this.props.handleStep(this.props.index - 2)
	}
	private handleShowResult = () => {
		this.props.handleStep(this.props.index + 1)
	}

	render = () => 
	{

		return <>
			<Grid container direction='column'  alignItems='center'>
				<Grid item xs={12}>
					<div>
						<img src={require('@resources/img/create/lab/big-check.svg?url')} alt="success-check" />
					</div>
				</Grid>
				<Grid item xs={12}>
					<MsgContainer>
						<SuccessMsgTitle>
							<T>Products Successfully Published!</T>
						</SuccessMsgTitle>
						<SuccessMsgSubTitle>
						<T>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</T>
						</SuccessMsgSubTitle>
					</MsgContainer>
				</Grid>
				<FullWidthGrid item xs={12}>
					<ButtonsContainer>
						<ButtonWhiteHeight45Weight500Larger><T>View in</T> Shopify</ButtonWhiteHeight45Weight500Larger>
					</ButtonsContainer>
				</FullWidthGrid>

				<PreviousButtonContainer item>
					<PreviousButton onClick={this.handleGoPreviousState}>
						{"< "}
						<T>Back to Products</T>
					</PreviousButton>
				</PreviousButtonContainer>

			</Grid>

		</>
	}
}