import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../_Sections/SectionDetails';
import SectionGallery from '../_Sections/SectionGallery';
import SectionDropship from '../_Sections/SectionDropship';
import SectionPairings from '../_Sections/SectionPairings';
import SectionBranding from '../_Sections/SectionBranding';
import SectionSizes from '../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../_Sections/SectionPricing';
import tKey from '../../Common/Utils/tKey';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import ProductSEO from '../_Sections/ProductSEO';
import { BlackCheckedIcon } from '../../Common/_components/CheckBoxes';

const PRODUCT_ID = 98;
const VIEW_PRODUCT_ID = 49;

export default function SchoopBralettePage() {
  const [t] = useTranslation('page/products/scoop-bralette');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Scoop Bralette | Products')}
      description={t('Design and create your own custom printed Scoop Bralette featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#fd8181"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '48%',
      }}
      tag={t('The all-over print')}
      title={t('Scoop Bralette')}
      text={t('For anyone who hates to wear a bra, meet your new favourite undergarment! This oh-so-soft bra offers essential support without sacrificing comfort.')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/scoop-bralette/banner-1-' + locale + '.png')}
      alt={t('Picture of Scoop Bralette')}
      details={[
        {
          img: require('@resources/img/pages/products/scoop-bralette/icon-1.svg?url'),
          alt: t('No buckle icon'),
          text: t('Pullover style bralette with deep scoop back'),
        },
        {
          img: require('@resources/img/pages/products/scoop-bralette/icon-2.svg?url'),
          alt: t('Underband icon'),
          text: t('Custom printed elastic 1.25” underband'),
        },
        {
          img: require('@resources/img/pages/products/scoop-bralette/icon-3.svg?url'),
          alt: t('Stretchy Fabric Icon'),
          text: t('Made from ultrasoft peachskin fabric'),
        },
        {
          img: require('@resources/img/pages/products/scoop-bralette/icon-4.svg?url'),
          alt: t('Cotton Liner Icon'),
          text: t('Full lined with jersey cotton lining'),
        },
        {
          img: require('@resources/img/pages/products/scoop-bralette/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/scoop-bralette/gallery_0.jpg'),
      require('@resources/img/pages/products/scoop-bralette/gallery_1.jpg'),
      require('@resources/img/pages/products/scoop-bralette/gallery_2.jpg'),
      require('@resources/img/pages/products/scoop-bralette/gallery_3.jpg'),
      require('@resources/img/pages/products/scoop-bralette/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Scoop <br/>Bralette <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Scoop Bralette! At prices designed for reselling, scoop bralettes made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
    />

    <SectionSizes
      chart="tops"
      productId={PRODUCT_ID}
      title={t('Scoop Bralette Sizing')}
      text={t('Scoop bralettes are available in a variety of sizes made on-demand, ranging from xs through to 2xl.')}
    >
    </SectionSizes>

    <SectionBranding
      hasLabel
      title={t('Brand your Scoop Bralette')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Scoop Bralette with these great products to create your own unique styles.')}
      productSlugs={['cheeky-briefs', 'lounge-pants', 'peignoir-peachskin']}
    />
  </div>
}