import * as Immutable from 'immutable';
import { AjaxActionEnd, AjaxActionStart } from '../../Common/_actions/AjaxAction';
import { DashboardAction, DashboardActionType } from '../_actions/DashboardActions';
import DashboardStore from '../_stores/DashboardStore';
import ListingStore from '../../MassProductSending/_stores/ListingStore';
import VariantPriceStore from '../../Dashboard/_stores/VariantPriceStore';

const getListingId = (id_product_design: number, id_product:number) => String(id_product_design)+'|'+String(id_product);

const DashboardReducer = (store:DashboardStore = new DashboardStore(), action: DashboardAction):DashboardStore => {
	switch (action.type) {
		case DashboardActionType.EditProductListing:
			return store.withMutations(store => {
				let listing = new ListingStore({
					id: getListingId(action.id_product_design, action.id_product),
					id_product_design: action.id_product_design,
					id_product: action.id_product,
					title: action.title,
					description: action.description,
					price: action.price,
					update_preset: action.update_preset || false,
					disabled_preview_ids: Immutable.List<number>(action.disabled_preview_ids || []),
					disabled_option_ids: Immutable.List<number>(action.disabled_option_ids || []),
					variant_prices: action.variant_prices || Immutable.Map<string, VariantPriceStore>(),
					squarespace_store_page_id: action.squarespace_store_page_id,
					is_modified: action.is_modified,
				});

				/*if(action.variant_prices) {
					action.variant_prices.forEach((price:VariantPriceStore) => listing.variant_prices.push(price));
				}*/

				store.setIn(['addProduct_listings', listing.get('id')], listing);
			});

		case DashboardActionType.RemoveProductListing:
			return store.removeIn(['addProduct_listings', action.id]);

		case DashboardActionType.SelectProductListing:
			return store.set('addProduct_selectedListing', action.id);

		case DashboardActionType.ResetProductListings:
			return store.withMutations(store => {
				store.set('addProduct_listings', Immutable.Map<string, ListingStore>());
				store.set('addProduct_selectedListing', "");
			});
	}

	return store;
}

export default DashboardReducer