import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { AjaxAction, AjaxActionType, createAjaxActionStart, createAjaxActionEnd, AjaxActionPayload, AjaxResponse } from '../_actions/AjaxAction';
import { createAction } from '../_actions/Action';
import { MiddlewareAPI, Dispatch, Middleware, AnyAction, Store } from "redux";
import RateLimiter from '../Utils/RateLimiter';
import WebsiteStore, { IWebsiteStore } from '../../WebsiteStore';
import { ResetApiErrors } from '../../UIData/_actions/UIDataActions';

// Rate Limiters & Abort Controllers
let tracker:string[] = [];
let rateLimiters:{[key:string]:RateLimiter} = {};
let abortControllers:{[key:string]:AbortController} = {};

const AjaxMiddleware: Middleware<Dispatch, WebsiteStore> = ({dispatch, getState}: MiddlewareAPI) => next => (action: AjaxAction<unknown>) => {
	let { url, method = "GET", credentials = 'include', data, limit, abortPrevious, preventDuplicates, onSuccess, onFailure, onAbort, raw } = action;
	const {type, actionType, ...payload} = action;

	if (type != AjaxActionType) return next(action);

	let controller = new AbortController();

	const store = getState() as IWebsiteStore;
	const locale = store.userData.get('prefs').get('lang').substring(0,2);

	//Prepend locale
	if(url.indexOf('ajax/') === 0 && locale !== 'en') {
		//console.log(url, store.userData.get('prefs').get('lang').substring(0,2), 'ajax/'+store.userData.get('prefs').get('lang').substring(0,2)+url.substring(4));
		url = 'ajax/'+store.userData.get('prefs').get('lang').substring(0,2)+url.substring(4)
	}

	const baseUrl = url;

	let callable = async () => {
		dispatch(ResetApiErrors())
		dispatch(createAjaxActionStart(actionType, payload as AjaxActionPayload));

		let options:RequestInit = {
			method: method, 
			signal: controller.signal,
			credentials: credentials
		};

		if(data){
			if(method == "GET"){
				let values:string[][] = [];

				if(data instanceof FormData) {
					data.forEach((v:string, k:string) => values.push([k, v]));
				} else {
					Object.entries(data).forEach(([k, v]) => values.push([k, String(v)]));
				}

				url += (url.includes('?') ? '&' : '?') + new URLSearchParams(values);
			} else if (method == "POST") {
				options.body = data;
			}
		}

		let end = () => dispatch(createAjaxActionEnd(actionType, payload as AjaxActionPayload));

		return fetch(url, options)
			.then(response => { 
				if(response.type != "basic") raw = true;
				return response.json()
			})
			.then(response => {
				if(!raw && response.code !== 200) throw response;
				onSuccess && onSuccess(response as AjaxResponse, dispatch)
				end();
			})
			.catch(response => {
				if(response.name === 'AbortError') return onAbort && onAbort(response as AjaxResponse, dispatch);
				let out = onFailure && onFailure(response as AjaxResponse, dispatch);
				end();
				return out;
			})
			.finally(() => {
				if(preventDuplicates && (tracker.indexOf(baseUrl) !== -1)){
					tracker.splice(tracker.indexOf(baseUrl), 1);
				}
			})
	}

	if(preventDuplicates){
		if(tracker.indexOf(baseUrl) !== -1) return
		tracker.push(baseUrl);
	}

	if(abortPrevious) {
		if(abortControllers[baseUrl]) abortControllers[baseUrl].abort();
		abortControllers[baseUrl] = controller;
	}

	if(limit) {
		if(!rateLimiters[baseUrl]) rateLimiters[baseUrl] = new RateLimiter(limit);
		rateLimiters[baseUrl].throttle(callable);
	} else return callable();
};

export default AjaxMiddleware;