import { styled } from '@mui/material';
import { memo, useMemo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

const SizeChart = styled('table')(({theme}) => ({
    fontFamily: 'Popins, sans-serif',
	textTransform: 'uppercase',
	fontWeight: '500',
    fontSize: '1.25rem',
    borderCollapse: 'collapse',
    
	'tr': {
		'th': {
			color: '#F600BE',
            fontWeight: '600',
		},
		'th,td': {
			padding: '10px',
            textAlign: 'center',
			border: '1px solid rgba(255,255,255,0.8)',
            
            [theme.breakpoints.down('sm')]: {
                padding: '5px 2px',
            },
		},
		'&:first-of-type th': {
			borderTop: '0',
            '&:first-of-type': {
                maxWidth: '120px',
            },
		},
		'&:last-child td': {
			borderBottom: '0',
		},
		'td:first-of-type,th:first-of-type': {
			borderLeft: '0',
		},
		'td:last-child, th:last-child': {
			borderRight: '0',
		},
		'&.chart-head': {
			'th': {
			borderBottom: '1px solid white',
			},
		},
	},
}))

const SizeChartWrapper = styled('div')(({theme}) => ({
    margin: '40px auto 0',
   
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '1000px',
    },

    'img': {
        marginTop: '0',
        
        [theme.breakpoints.up('md')]: {
            marginRight: '60px',
        },
        
        [theme.breakpoints.down('md')]: {
            marginBottom: '30px',
            marginLeft: '50px',
        },
        
        [theme.breakpoints.down('sm')]: {
            maxWidth: '210px',
        },
    }
}))

type ClothingSize = 'xsmall'|'small'|'medium'|'large'|'xlarge'|'xxlarge'

type Props = React.PropsWithChildren<{
    title: React.ReactNode
    text: React.ReactNode
    chart?: 'tops' | 'bottoms' | 'full' | 'bottoms-men' | 'polos-men' | 'polos-women' | 'unisex-joggers'
    productId: number
}>

export default memo(function SectionSizes(props: Props) {
    const [t] = useTranslation('page/products/_sections');

    const productData = useAppSelector(state => state.get('appData').get('products').get(String(props.productId)));
    const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

    const sizes = useMemo(() => {
        return productData?.get('availableOptionTypes').get('1')?.get('options').valueSeq().map(option => option.get('slug')).toArray() ?? []
    }, [productData])

    const [chartTitle, chartImg] = useMemo(():[string|undefined,string|undefined] => {
        switch(props.chart) {
            case 'tops':
                return [
                    t('Size'),
                    require('@resources/img/pages/products/_sections/sizes/body-measure-illustration-0-' + locale + '.svg?url')
                ];
            case 'bottoms':
                return [
                    t('Women\'s US Size'),
                    require('@resources/img/pages/products/_sections/sizes/body-measure-illustration-1-' + locale + '.svg?url')
                ];
            case 'full':
                return [
                    t('Women\'s US Size'),
                    require('@resources/img/pages/products/_sections/sizes/body-measure-illustration-2-' + locale + '.svg?url')
                ];
            case 'bottoms-men':
                return [
                    t('Size'),
                    require('@resources/img/pages/products/_sections/sizes/body-measure-illustration-3-' + locale + '.svg?url')
                ];
            case 'polos-men':
                return [
                    t('Size'),
                    require('@resources/img/pages/products/_sections/sizes/body-measure-illustration-4-' + locale + '.svg?url')
                ];
            case 'polos-women':
                return [
                    t('Size'),
                    require('@resources/img/pages/products/_sections/sizes/body-measure-illustration-4-' + locale + '.svg?url')
                ];
            case 'unisex-joggers':
                return [
                    t('Size'),
                    require('@resources/img/pages/products/_sections/sizes/body-measure-illustration-5-' + locale + '.svg?url')
                ];
        }
        return [t('Size'),undefined];
    }, [props.chart, locale, t])

    return <div className="full-page-panel white-text section-sizes">
        <div className="container">
            <div className="row no-margin">
                <div className="col s12 l12 center-align">
                    <h1>{props.title}</h1>
                    <p>{props.text}</p>

                    { chartImg && props.chart ? <div className="measurement">
                        <SizeChartWrapper>
                            <img className="size-chart-image" src={chartImg} alt="measurement-image"/>
                            <div>
                                { ['full','bottoms','tops','bottoms-men'].includes(props.chart) ? <SizeChart className="size-chart">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>{chartTitle}</th>
                                            { sizes.includes('xsmall') ? <th>XS</th> : null }
                                            { sizes.includes('small') ? <th>S</th> : null }
                                            { sizes.includes('medium') ? <th>M</th> : null }
                                            { sizes.includes('large') ? <th>L</th> : null }
                                            { sizes.includes('xlarge') ? <th>XL</th> : null }
                                            { sizes.includes('xxlarge') ? <th>XXL</th> : null }
                                        </tr>
                                        { ['full', 'bottoms'].includes(props.chart) ? <tr className="chart-head">
                                            { sizes.includes('xsmall') ? <th>0-2</th> : null }
                                            { sizes.includes('small') ? <th>4-6</th> : null }
                                            { sizes.includes('medium') ? <th>8-10</th> : null }
                                            { sizes.includes('large') ? <th>12-14</th> : null }
                                            { sizes.includes('xlarge') ? <th>16</th> : null }
                                            { sizes.includes('xxlarge') ? <th>20-22</th> : null }
                                        </tr> : null }
                                    </thead>
                                    <tbody>
                                        { ['full', 'tops'].includes(props.chart) ? <tr>
                                            <td>{t('Bust')}</td>
                                            { sizes.includes('xsmall') ? <td>32-34"</td> : null }
                                            { sizes.includes('small') ? <td>34-37"</td> : null }
                                            { sizes.includes('medium') ? <td>37-40"</td> : null }
                                            { sizes.includes('large') ? <td>40-41"</td> : null }
                                            { sizes.includes('xlarge') ? <td>41-42"</td> : null }
                                            { sizes.includes('xxlarge') ? <td>42-43"</td> : null }
                                        </tr> : null }
                                        <tr>
                                            <td>{t('Waist')}</td>
                                            { sizes.includes('xsmall') ? <td>24-26"</td> : null }
                                            { sizes.includes('small') ? <td>26-29"</td> : null }
                                            { sizes.includes('medium') ? <td>29-32"</td> : null }
                                            { sizes.includes('large') ? <td>33-37"</td> : null }
                                            { sizes.includes('xlarge') ? <td>37-42"</td> : null }
                                            { sizes.includes('xxlarge') ? <td>42-44"</td> : null }
                                        </tr>
                                        { ['full', 'bottoms'].includes(props.chart) ? <tr>
                                            <td>{t('Hips')}</td>
                                            { sizes.includes('xsmall') ? <td>33-35"</td> : null }
                                            { sizes.includes('small') ? <td>35-37"</td> : null }
                                            { sizes.includes('medium') ? <td>38-40"</td> : null }
                                            { sizes.includes('large') ? <td>41-43"</td> : null }
                                            { sizes.includes('xlarge') ? <td>43-46"</td> : null }
                                            { sizes.includes('xxlarge') ? <td>46-50"</td> : null }
                                        </tr> : null }
                                    </tbody>
                                </SizeChart> : null }
                                
                                { ['polos-men','polos-women'].includes(props.chart) ? <SizeChart className="size-chart">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>{chartTitle}</th>
                                            { sizes.includes('small') ? <th>SM</th> : null }
                                            { sizes.includes('medium') ? <th>MD</th> : null }
                                            { sizes.includes('large') ? <th>L</th> : null }
                                            { sizes.includes('xlarge') ? <th>XL</th> : null }
                                            { sizes.includes('2xlarge') ? <th>2XL</th> : null }
                                            { sizes.includes('3xlarge') ? <th>3XL</th> : null }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { ['polos-men'].includes(props.chart) ? <tr>
                                            <td>{t('Body Length')}</td>
                                            { sizes.includes('small') ? <td>28"</td> : null }
                                            { sizes.includes('medium') ? <td>29"</td> : null }
                                            { sizes.includes('large') ? <td>30"</td> : null }
                                            { sizes.includes('xlarge') ? <td>31"</td> : null }
                                            { sizes.includes('2xlarge') ? <td>32"</td> : null }
                                            { sizes.includes('3xlarge') ? <td>33"</td> : null }
                                        </tr> : null }
                                        { ['polos-men'].includes(props.chart) ? <tr>
                                            <td>{t('Chest Width')}</td>
                                            { sizes.includes('small') ? <td>19"</td> : null }
                                            { sizes.includes('medium') ? <td>21"</td> : null }
                                            { sizes.includes('large') ? <td>23"</td> : null }
                                            { sizes.includes('xlarge') ? <td>25"</td> : null }
                                            { sizes.includes('2xlarge') ? <td>27"</td> : null }
                                            { sizes.includes('3xlarge') ? <td>29"</td> : null }
                                        </tr> : null }

                                        { ['polos-women'].includes(props.chart) ? <tr>
                                            <td>{t('Body Length')}</td>
                                            { sizes.includes('small') ? <td>24 ¾"</td> : null }
                                            { sizes.includes('medium') ? <td>25 ½"</td> : null }
                                            { sizes.includes('large') ? <td>26 ¼"</td> : null }
                                            { sizes.includes('xlarge') ? <td>27"</td> : null }
                                            { sizes.includes('2xlarge') ? <td></td> : null }
                                        </tr> : null }
                                        { ['polos-women'].includes(props.chart) ? <tr>
                                            <td>{t('Chest Width')}</td>
                                            { sizes.includes('small') ? <td>18"</td> : null }
                                            { sizes.includes('medium') ? <td>19"</td> : null }
                                            { sizes.includes('large') ? <td>20"</td> : null }
                                            { sizes.includes('xlarge') ? <td>21"</td> : null }
                                            { sizes.includes('2xlarge') ? <td></td> : null }
                                        </tr> : null }
                                    </tbody>
                                </SizeChart> : null }

                                { ['unisex-joggers'].includes(props.chart) ? <SizeChart className="size-chart">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>{chartTitle}</th>
                                            { sizes.includes('small') ? <th>SM</th> : null }
                                            { sizes.includes('medium') ? <th>MD</th> : null }
                                            { sizes.includes('large') ? <th>L</th> : null }
                                            { sizes.includes('xlarge') ? <th>XL</th> : null }
                                            { sizes.includes('2xlarge') ? <th>2XL</th> : null }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t('Width')}</td>
                                            { sizes.includes('small') ? <td>13.5"</td> : null }
                                            { sizes.includes('medium') ? <td>14.5"</td> : null }
                                            { sizes.includes('large') ? <td>15.5"</td> : null }
                                            { sizes.includes('xlarge') ? <td>18"</td> : null }
                                            { sizes.includes('2xlarge') ? <td>18"</td> : null }
                                        </tr>
                                        <tr>
                                            <td>{t('Length')}</td>
                                            { sizes.includes('small') ? <td>39"</td> : null }
                                            { sizes.includes('medium') ? <td>40"</td> : null }
                                            { sizes.includes('large') ? <td>40.5"</td> : null }
                                            { sizes.includes('xlarge') ? <td>41"</td> : null }
                                            { sizes.includes('2xlarge') ? <td>41.5"</td> : null }
                                        </tr>
                                        <tr>
                                            <td>{t('Hip')}</td>
                                            { sizes.includes('small') ? <td>20"</td> : null }
                                            { sizes.includes('medium') ? <td>21"</td> : null }
                                            { sizes.includes('large') ? <td>22"</td> : null }
                                            { sizes.includes('xlarge') ? <td>23"</td> : null }
                                            { sizes.includes('2xlarge') ? <td>24"</td> : null }
                                        </tr>
                                        <tr>
                                            <td>{t('Knee Width')}</td>
                                            { sizes.includes('small') ? <td>8.3"</td> : null }
                                            { sizes.includes('medium') ? <td>8.7"</td> : null }
                                            { sizes.includes('large') ? <td>9.1"</td> : null }
                                            { sizes.includes('xlarge') ? <td>9.4"</td> : null }
                                            { sizes.includes('2xlarge') ? <td>9.8"</td> : null }
                                        </tr>
                                    </tbody>
                                </SizeChart> : null }
                            </div>
                        </SizeChartWrapper>
                    </div> : null }

                    {/*<div className="download-button">
                        <a href="<?=$downloadButton?>" className="btn-2020-flat">Download measurement charts</a>
                    </div> */ }

                    { props.children }
                </div>
            </div>
        </div>
    </div>
})