import { Canvas } from '@react-three/fiber';
import Immutable from 'immutable';
import { memo } from 'react';
import { PCFShadowMap } from 'three';
import ModelViewerComponents from './ModelViewerComponents';
import ModelViewerProduct from './ModelViewerProduct';

type Props = {
    viewerId: string,
    productId: number,
    labData: Record<string, any>,
    options:Immutable.Map<string,string>
}

const ModelViewerCanvas = memo((props:Props) => {
    return <Canvas 
        shadows={{ type: PCFShadowMap }} 
        //Necessary or else it breaks the lab currently
        style={{pointerEvents: 'inherit'}}
    >
        <ModelViewerComponents labData={props.labData} />
        <ModelViewerProduct 
            viewerId={props.viewerId}
            productId={props.productId}
            options={props.options}
        />
    </Canvas>
})

export default ModelViewerCanvas;