import { memo } from 'react';

type Props = {
    images: string[]
}

export default memo(function SectionGallery(props: Props) {
    return <div className="full-page-panel section-gallery">
        <div className="row no-margin">
            { props.images.map((image,index) => {
                return <div
                    key={image}
                    className="col"
                    style={{backgroundImage: 'url("'+image+'")'}}
                ></div>
            })}
        </div>
    </div>
})