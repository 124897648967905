import { useCallback, useEffect } from 'react'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import CartContent from '../../Cart/CartContent/CartContent'
import CartListItems from '../../Cart/CartItem/CartListItems'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import AddStandaloneBrandingOption from './AddStandaloneBrandingOption'
import type BrandingOptionTypeStore from '../../AppData/_stores/BrandingOptionTypeStore'
import { FetchBrandingOptions } from '../../UserData/_actions/BrandingOptionActions'

interface Props {
  cart: UserCartStore
}

export default function CheckoutOverviewContent(props: Props) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(FetchBrandingOptions())
  }, [])

  const standaloneBrandingOptionTypes = useAppSelector(state => state.get('appData').get('brandingOptionTypes')
    .filter(bOptionType => {
      return bOptionType.get('is_standalone') && state.get('userData').get('brandingOptions')
        .filter(brandingOption => brandingOption.get('id_type') === bOptionType.get('id'))
        .count() > 0
    }), {
    equalityFn: (a, b) => a.equals(b)
  })

  const renderBOptionType = useCallback((type: BrandingOptionTypeStore) => {
    return <AddStandaloneBrandingOption
      key={type.get('id')}
      cart={props.cart}
      type={type}
    />
  }, [props.cart])

  return <>
    <CartContent
      cart={props.cart}
      showDeleteButton={props.cart.get('status') === 'cart'}
      headerActions={props.cart.get('order_from') !== 'invoice' && standaloneBrandingOptionTypes.count() > 0
        ? standaloneBrandingOptionTypes.valueSeq().map(renderBOptionType)
        : null}
    >
      <CartListItems
        cart={props.cart}
        short={props.cart.get('order_from') === 'invoice'}
      />
    </CartContent>
  </>
}
