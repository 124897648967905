import Immutable from "immutable";
import { IPaginationStore } from "./PaginationStore";
import { PhotoLabCreationStore } from "./PhotoLabCreationStore";
import PaginationItemListStore from "./PaginationItemListStore";

export interface IPhotoLabCreationListStore extends IPaginationStore {
	data: Immutable.Map<string, PhotoLabCreationStore>,
}

const defaultPhotoLabCreationListStore = Immutable.Record<IPhotoLabCreationListStore>({
	itemLists: Immutable.Map<string, PaginationItemListStore>(),
	data: Immutable.Map<string, PhotoLabCreationStore>(),
});

export default class PhotoLabCreationListStore extends defaultPhotoLabCreationListStore implements IPhotoLabCreationListStore {}