import { CircularProgress } from '@mui/material';
import * as React from 'react';

export type Props = {
	noModal?: boolean
	circular?: boolean
}

export default class Loader extends React.Component<Props> {
	render() {
		const style = this.props.noModal ? {
			position: "absolute" as "absolute",
			top: "50%",
			marginTop: "-64px",
			left: "50%",
			marginLeft: "-32px",
			zIndex: 1000
		} : {};

		return <div style={style} className="modal_saving">
			<div className="modal_saving-anim">
				{!this.props.circular ?
					<div className="modal_saving-anim--drop" />
				:
					<CircularProgress />
				}
			</div>
		</div>
	}
}