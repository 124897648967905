import { useContext, useEffect } from "react"
import { Color } from "three"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function UnisexJoggers(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/unisex-joggers.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture, fxTexture] = useContext(ProductTextureContext)

    const option_color = props.options.get('color') ?? 'white'
    const color = new Color({
        "white": 0xffffff,
        "black": 0x171616,
        "navy": 0x2e2f41,
        "sport_grey": 0xecebf2,
        "maroon": 0x701b2f,
        "lavender": 0xd3ccea,
    }[option_color])

    useEffect(() => {
        if(!elements || !elements[0]?.material || !elements[1]?.material || !elements[2]?.material || !elements[3]?.material) return

        elements[0].material.color = color
        elements[1].material.color = color
        elements[2].material.color = color
        //elements[3].material.color = color

        let ao_str = option_color == "sport_grey" ? 3 : 0.5
        elements[0].material.aoMapIntensity = ao_str
        elements[1].material.aoMapIntensity = ao_str
        elements[2].material.aoMapIntensity = ao_str
        elements[3].material.aoMapIntensity = ao_str
    }, [elements, color])

    // Apply lab texture
    useEffect(() => {
        if(!fxTexture || !elements || !elements[0]?.material) return
        elements[3].material.map = fxTexture
        elements[3].material.map.flipY = false
        elements[3].material.map.needsUpdate = true
    }, [fxTexture])
    
    return <ModelElements elements={elements}/>
}