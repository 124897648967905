import * as Immutable from 'immutable';
import { BrandingOptionPayload, BrandingOptionStore } from '../_stores/BrandingOptionStore';
import { BrandingOptionStoreAction, BrandingOptionStoreActionType } from '../_actions/BrandingOptionStoreActions';

const editBrandingOption = (store: Immutable.OrderedMap<string, BrandingOptionStore>, action: BrandingOptionPayload) => {
  return store.withMutations(store => {
    const oldBrandingOption = store.get(String(action.id));

    const { ...otherProps } = action;

    let newBrandingOption = new BrandingOptionStore(otherProps);

    if (oldBrandingOption) {
      newBrandingOption = oldBrandingOption.mergeDeep(newBrandingOption);
    }

    store.set(String(action.id), newBrandingOption);
  })
}

const BrandingOptionReducer = (
  store: Immutable.OrderedMap<string, BrandingOptionStore> = Immutable.OrderedMap<string, BrandingOptionStore>(),
  action: BrandingOptionStoreAction
): Immutable.OrderedMap<string, BrandingOptionStore> => {
  switch (action.type) {
    case BrandingOptionStoreActionType.EditBrandingOption:
      store = editBrandingOption(store, action.brandingOption);
      break;

    case BrandingOptionStoreActionType.EditManyBrandingOptions:
      Object.values(action.brandingOptions).forEach(brandingOption => {
        store = editBrandingOption(store, brandingOption);
      })
      break;

    case BrandingOptionStoreActionType.RemoveBrandingOption:
      store = store.remove(String(action.id));
      break;
  }
  return store;
}

export default BrandingOptionReducer