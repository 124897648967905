import * as React from 'react';
import { IAwareComponent } from './IAwareComponent';

export default class AwareComponent<P = any, S = any> extends React.Component<P, S> implements IAwareComponent<P, S> {
	public state:S;

	propChanged(prev:P, ...keys:(keyof P)[]){
		for(let key in keys)
			if(prev[keys[key]] !== this.props[keys[key]]) return true;
		return false;
	}

	stateChanged(prev:S, ...keys:(keyof S)[]){
		for(let key in keys)
			if(prev[keys[key]] !== this.state[keys[key]]) return true;
		return false;
	}

	whichPropsChanged(props?:P) {
		let what = [];
		for(let pkey in props)
			if(props[pkey] !== this.props[pkey]) what.push(pkey);
		return what;
	}

	whichStatesChanged(state?:S) {
		let what = [];
		for(let skey in state)
			if(state[skey] !== this.state[skey]) what.push(skey);
		return what;
	}
}