import { Box, BoxProps, styled } from '@mui/material';
import { CSSProperties, memo, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import tKey from '../../Common/Utils/tKey';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { EditCreateUrl } from '../../UIData/_actions/UIDataActions';

const TransitionTop = styled('div')({
    width: '50%',

    '@media (max-width: 992px)': {
        width: '100%',
        marginTop: '-10rem',
        paddingTop: '10rem',
        position: 'relative',

        '&::before': {
            background: 'inherit',
            content: '""',
            height: '10rem',
            left: '0',
            position: 'absolute',
            right: '0',
            top: '0',
            width: '100%',
        }
    }
})


const PriceTag = styled('div')({
    fontFamily: 'Bebas Neue, sans-serif',
	backgroundColor: '#fff',
	borderRadius: '50px 0 0 50px',
	display: 'flex',
	alignItems: 'center',
	padding: '15px 20px 12px 30px',
	width: 'fit-content',
	position: 'absolute',
	bottom: '80px',
	right: '0',

    '@media (max-width: 992px)': {
        top: '410px',
	    bottom: 'auto',
        zIndex: '9',
        padding: '10px 15px 7px 20px',
    },
	
	'h5': {
		fontSize: '18.5px',
		lineHeight: '1',
		margin: '0 5px 0 0',
		'span': {
			fontSize: '22px',
			display: 'block',
            '@media (max-width: 992px)': {
                fontSize: '18px',
            },
		}
	},
	'h4': {
		fontSize: '50px',
		color: '#F600BE',
		lineHeight: '1',
		margin: '0',
		
        '@media (max-width: 992px)': {
            fontSize: '40px',
        },
	}
})

type Props = {
    viewProductId?: number
    color: string
    img: string
    bgStyle: CSSProperties
    alt: string
    tag: string
    title: string
    text: string
    details: {
        img: string
        alt: string
        text: string
    }[],
    createUrl: string
}

export default memo(function SectionDetails(props: Props) {
    const dispatch = useAppDispatch()
    const [t] = useTranslation('page/products/_sections');
    const [tR] = useTranslation('routes')

    const viewProductData = useAppSelector(state => state.get('appData').get('viewProducts').get(String(props.viewProductId)));

    //TODO: This changes the header's create button url. Should move this to somewhere that makes more sense eventually.
    useEffect(() => {
        dispatch(EditCreateUrl(props.createUrl))
    }, [props.createUrl, tR])

    return <div
        className="section-header-details"
        style={{
            backgroundColor: props.color,
            position: 'relative',
        }}
    >
        { viewProductData ? <PriceTag>
            <h5><Trans
                t={t}
                i18nKey={tKey('Starting <line2>at only</line2>')} 
                components={{
                    line2: <span/>,
                }}
            /></h5>
	        <h4>{ viewProductData.get('price').toDinero().toFormat() }</h4>
        </PriceTag> : null }
        <Box
            component="div"
            className="container"
            sx={{
                '@media only screen and (min-width: 993px)': {
                    backgroundImage: 'url('+props.img+')',
                }
            }}
            style={props.bgStyle}
        >
            <div className="row no-margin">
                <div className="col s12 hide-on-large-only">
                    <img className="features-img" src={props.img} alt={props.alt} />
                </div>
            </div>
            <TransitionTop
                style={{
                    backgroundImage: 'linear-gradient(to bottom, rgba(253, 129, 129, 0), '+props.color+')',
                }}
            >
                <span className="tag">{ props.tag }</span>
                <h1 className="title">{ props.title }</h1>
                <p>{ props.text }</p>
            </TransitionTop>
            <div className="row no-margin">
                <div className="col s12 l6">
                    <ul className="staggered-list">
                        { props.details.map((detail, index) => {
                            return <li key={index} className="detail-block vertical-block">
                                <div className="detail-img-wrapper">
                                    <img src={detail.img} alt={ detail.alt } width="60" height="60" />
                                </div>
                                <p>{ detail.text }</p>
                            </li>
                        })}
                    </ul>
                    <Link className="btn-create" to={tR('/create')+'/'+props.createUrl} reloadDocument
                    ><i className="material-icons">edit</i>{t('Create your own')}</Link>
                </div>
            </div>
        </Box>
    </div>
})