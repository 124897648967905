import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import ListingStore from '../../MassProductSending/_stores/ListingStore';
import Publishing, { Props } from '../_components/Publishing';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	total: (store.getIn(['Dashboard','addProduct_listings']) as Immutable.Map<string, ListingStore>).count()
});

export default connect(
	mapStateToProps,
	null
)(Publishing);