import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = React.HTMLProps<HTMLImageElement> & {
  slug: string
  name: string
}

export default function ProductNavImage(props:Props) {
  const [t] = useTranslation('header');

  const { slug, name, ...imgProps } = props;

  return <picture>
    <source type="image/webp" srcSet={require('@resources/img/all-products/nav/'+props.slug+'.webp')+' 1x, '+require('@resources/img/all-products/nav/'+props.slug+'@2x.webp')+' 2x'}/>
    <source type="image/jpeg" srcSet={require('@resources/img/all-products/nav/'+props.slug+'.jpg')+' 1x, '+require('@resources/img/all-products/nav/'+props.slug+'@2x.jpg')+' 2x'}/>
    <img
      src={require('@resources/img/all-products/nav/'+props.slug+'.svg?url')}
      width={100}
      height={100}
      alt={t('Picture of custom printed {{name}}', {name: props.name})}
      {...imgProps}
    />
  </picture>
}