import * as Immutable from 'immutable';
import UserStorePresetStore, { IUserStorePresetStore } from '../_stores/UserStorePresetStore';
import { UserStorePresetAction, UserStorePresetActionType } from '../_actions/UserStorePresetActions';
import { AjaxActionStart, AjaxActionEnd, AjaxActionStartType, AjaxActionEndType } from '../../Common/_actions/AjaxAction';
import VariantPriceStore from '../../Dashboard/_stores/VariantPriceStore';
import PriceStore from '../../UIData/_stores/PriceStore';

const UserStorePresetsReducer = (
	store:Immutable.OrderedMap<number, UserStorePresetStore> = Immutable.OrderedMap<number, UserStorePresetStore>(), 
	action: UserStorePresetAction
):Immutable.OrderedMap<number, UserStorePresetStore> => {
	let loading = false;
	switch (action.type) {
		case UserStorePresetActionType.AddUserStorePresets:
		case UserStorePresetActionType.EditUserStorePresets:
			store = store.withMutations(store => {
				for(let storePreset in action.storePresets) {
					if(!action.storePresets.hasOwnProperty(storePreset)) continue;
					let a = action.storePresets[storePreset];
					a.loading = a.loading || false;

					let {price, variant_prices, ...rest} = a as IUserStorePresetStore;

					let preset = new UserStorePresetStore();
					preset = preset.withMutations(preset => {
						preset.merge(Immutable.fromJS(rest));
						preset.set('price', new PriceStore(price));

						let parsed_variant_prices = Immutable.Map<string, VariantPriceStore>();
						variant_prices.forEach((variantPrice) => {
							let {price, option_ids, ...variantProps} = variantPrice;
							let id = option_ids.sort().join('|');
							parsed_variant_prices = parsed_variant_prices.set(id, new VariantPriceStore({
								price: new PriceStore(price),
								option_ids: option_ids,
								...variantProps
							}));
						});
						preset.set('variant_prices', parsed_variant_prices);
					});

					store.set(a.id_product, preset);
				}
			})
		break;

		case UserStorePresetActionType.RemoveUserStorePreset:
			store = store.delete(Number(action.id));
			break;
	}
	return store;
}

export default UserStorePresetsReducer