import * as Immutable from 'immutable';
import PriceStore from '../../UIData/_stores/PriceStore';

export interface IVariantPriceStore {
	name: string,
	slug: string,
	price: PriceStore,
	costPrice: PriceStore,
	option_ids: Array<number>
}

const defaultVariantPriceStore = Immutable.Record<IVariantPriceStore>({
	name: "",
	slug: "",
	price: new PriceStore(),
	costPrice: new PriceStore(),
	option_ids: []
});

export default class VariantPriceStore extends defaultVariantPriceStore implements IVariantPriceStore {}