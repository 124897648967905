import { AjaxActionEnd, AjaxActionStart } from '../../Common/_actions/AjaxAction';
import { AuthAction, AuthActionType } from '../_actions/AuthActions';
import AuthStore from '../_stores/AuthStore';

const AuthReducer = (
	store:AuthStore = new AuthStore(),
	action: AuthAction 
		| AjaxActionStart<unknown>
		| AjaxActionEnd<unknown>
):AuthStore => {
	switch (action.type) {
		case AuthActionType.OpenAuthModal:
			return store.withMutations(store => {
				store.set('modalOpened', true);
				store.set('isSignup', !!action.signup);
			});

		case AuthActionType.CloseAuthModal:
			return store.set('modalOpened', false);
	}

	return store;
}

export default AuthReducer