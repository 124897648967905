import { useCallback, useState } from 'react'
import * as React from 'react'
import DialogButton from '../../Common/Buttons/DialogButton'
import Dialog from '../../Common/Dialog/Dialog'
import DialogActions from '../../Common/Dialog/DialogActions'
import DialogContent from '../../Common/Dialog/DialogContent'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import { SaveUserPrefs } from '../../UserData/_actions/UserDataActions'
import { useTranslation } from 'react-i18next'
import { type BaseItem } from '../../UserData/_stores/UserCartStore'
import { DeleteCartItem } from '../../UserData/_actions/UserCartActions'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'

interface Props {
  item: BaseItem
  opened: boolean
  onClose: () => void
}

export default function DeleteItemDialog(props: Props) {
  const [t] = useTranslation('cart')
  const dispatch = useAppDispatch()

  const [quickDelete, setQuickDelete] = useState(false)

  const onConfirm = useCallback(() => {
    const fd = new FormData()
    fd.append('id', String(props.item.get('id')))
    dispatch(DeleteCartItem(fd))

    const fd2 = new FormData()
    fd2.append('quickDeleteFromCart', quickDelete ? '1' : '0')
    dispatch(SaveUserPrefs(fd2))

    props.onClose()
  }, [props.item, props.onClose, quickDelete])

  const onQuickDeleteChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuickDelete(e.target.checked)
  }, [])

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <DialogTitle>{t('Remove {{name}}', { name: props.item.get('name') })}</DialogTitle>
    <DialogContent>
      <p>{t('Are you sure that you want to remove this item?')}</p>
      <FormControlLabel
        control={<Checkbox
          checked={quickDelete}
          onChange={onQuickDeleteChange}
        />}
        label={t('Don\'t ask me again')}
      />
    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
      >{t('Cancel')}</DialogButton>
      <DialogButton
        color="error"
        onClick={onConfirm}
        autoFocus
      >{t('Remove')}</DialogButton>
    </DialogActions>
  </Dialog>
}
