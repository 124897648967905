import { FormControlLabel, styled } from '@mui/material';

const PinkCheckBox = styled(FormControlLabel)`
.MuiCheckbox-colorSecondary.Mui-checked{
    color: #DA3192;
}
.MuiSvgIcon-root {
    font-size: 27px;
}
`;

const UncheckedWhiteBackgroundIcon = () => <> <svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.730469" y="0.921875" width="19" height="19" fill="white" stroke="#AFAFAF" />
</svg></>

const PinkCheckedIcon = () => <>
    <svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.746094" y="0.421875" width="20" height="20" fill="#DA3192" />
        <path d="M16.1367 5.46582L8.94531 12.6572L6.32031 10.0049C6.18359 9.89551 5.96484 9.89551 5.85547 10.0049L5.0625 10.7979C4.95312 10.9072 4.95312 11.126 5.0625 11.2627L8.72656 14.8994C8.86328 15.0361 9.05469 15.0361 9.19141 14.8994L17.3945 6.69629C17.5039 6.58691 17.5039 6.36816 17.3945 6.23145L16.6016 5.46582C16.4922 5.3291 16.2734 5.3291 16.1367 5.46582Z" fill="white" />
    </svg>
</>

const BlackCheckedIcon = () => <>
    <svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.400391" y="0.015625" width="20" height="20" fill="#333333" />
        <path d="M14.793 5.42871L7.60156 12.6201L4.97656 9.96777C4.83984 9.8584 4.62109 9.8584 4.51172 9.96777L3.71875 10.7607C3.60938 10.8701 3.60938 11.0889 3.71875 11.2256L7.38281 14.8623C7.51953 14.999 7.71094 14.999 7.84766 14.8623L16.0508 6.65918C16.1602 6.5498 16.1602 6.33105 16.0508 6.19434L15.2578 5.42871C15.1484 5.29199 14.9297 5.29199 14.793 5.42871Z" fill="white" />
    </svg>
</>

const CircleGreenCheck = () => <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1562 5.10547C10.1562 2.44922 7.96875 0.261719 5.3125 0.261719C2.63672 0.261719 0.46875 2.44922 0.46875 5.10547C0.46875 7.78125 2.63672 9.94922 5.3125 9.94922C7.96875 9.94922 10.1562 7.78125 10.1562 5.10547ZM4.74609 7.68359C4.62891 7.80078 4.41406 7.80078 4.29688 7.68359L2.26562 5.65234C2.14844 5.53516 2.14844 5.32031 2.26562 5.20312L2.71484 4.77344C2.83203 4.63672 3.02734 4.63672 3.14453 4.77344L4.53125 6.14062L7.46094 3.21094C7.57812 3.07422 7.77344 3.07422 7.89062 3.21094L8.33984 3.64062C8.45703 3.75781 8.45703 3.97266 8.33984 4.08984L4.74609 7.68359Z" fill="#60B258" />
</svg>

export { BlackCheckedIcon, CircleGreenCheck, PinkCheckBox, PinkCheckedIcon, UncheckedWhiteBackgroundIcon };
