import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import WebsiteStore from "../../WebsiteStore";
import { Layer as LayerNode, Rect as RectNode, Stage as StageNode, Text, Image as ImageNode, Group as GroupNode } from "react-konva";
import KonvaPatternImage from "../KonvaNode/KonvaPatternImage";
import KonvaImage from "../KonvaNode/KonvaImage";
import { isBrowser, isNode } from "browser-or-node";
import { UpdateTexture } from "../../UIData/_actions/DesignLabStoreActions";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import createAlphaThresholdFilter from "../KonvaFilters/AlphaThresholdFilter";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { LAB_TEMPLATE_HEIGHT, LAB_TEMPLATE_WIDTH } from "../../config";

type Props = {
  layerId: string,
  subproduct: string | null,
  subproductRef?: string,
  scene: string,
  part: string,
  partData: Record<string, any>,
  subproductRef_partData?: Record<string, any>,
  inputType: 'lab',
  outputType: 'texture' | 'render',
  labData: Record<string, any>,
}

export default function LayerRenderer(props: Props) {
  const dispatch = useAppDispatch();

  const autoDesign = useAppSelector(state => props.subproduct !== null ? state.get('UIData').get('designLab').get('autoDesignSubproducts').get(props.subproduct) : undefined);
  const layer = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId));

  const updateTexture = useCallback(() => {
    dispatch(UpdateTexture());
  }, []);

  //If this is set to autodesign, use layers from first subproduct
  let subproductLayer = props.subproduct;
  if (autoDesign && props.subproductRef) {
    subproductLayer = props.subproductRef;
  }

  const filters = useMemo(() => {
    let tmpFilters: ((imageData: ImageData) => void)[] = [];
    if (props.labData.imageFilters?.alphaThreshold) {
      tmpFilters.push(createAlphaThresholdFilter(props.labData.imageFilters?.alphaThreshold));
    }
    return tmpFilters
  }, [props.labData])

  //Skip if scene or subproduct doesn't match
  if (!layer || layer.get('scene') !== props.scene || (subproductLayer && layer.get('subproduct') !== subproductLayer) || layer.get('width') === 0 || layer.get('height') === 0) return null;

  let x = (layer.get('x') - props.partData[props.inputType].x);
  let y = (layer.get('y') - props.partData[props.inputType].y);

  if (layer.get('alignment')) {
    switch (layer.get('alignment')) {
      case 'center':
        x = props.partData[props.inputType].width / 2;
        y = props.partData[props.inputType].height / 2;
        break;

      case 'top-left':
        x = (layer.get('width') * layer.get('scaleX')) / 2;
        y = (layer.get('height') * layer.get('scaleY')) / 2;
        break;
    }
  }

  let newScaleX = layer.get('scaleX');
  let newScaleY = layer.get('scaleY');

  //Adapt scale and position for autoDesign
  if (autoDesign && props.subproductRef_partData) {
    const refScaleX = props.partData[props.inputType].width
      / props.subproductRef_partData[props.inputType].width;

    const refScaleY = props.partData[props.inputType].height
      / props.subproductRef_partData[props.inputType].height;


    //Positions need to scale independently
    x = (layer.get('x') - props.subproductRef_partData[props.inputType].x) * refScaleX;
    y = (layer.get('y') - props.subproductRef_partData[props.inputType].y) * refScaleY;

    //scaleX/scaleY needs to scale the same both ways to avoid warping
    const autoDesignScale = Math.max(refScaleX, refScaleY);
    newScaleX = newScaleX * autoDesignScale;
    newScaleY = newScaleY * autoDesignScale;
  }

  let src = layer.get('src');

  if (isNode) {
    if (layer.get('type') === 'pattern') {
      let extension = layer.get('src').split('.').pop();
      let format = props.outputType === 'texture' ? 'small' : 'full';
      let filename = layer.get('fileid') + '_' + format + '.' + extension;

      src = filename;
    }
  }

  //All regular products should fall within the LAB_TEMPLATE_WIDTH/HEIGHT, but fabric works different and will have bigger values for partData and layers
  const maxSide = Math.max(LAB_TEMPLATE_WIDTH, LAB_TEMPLATE_HEIGHT, props.partData[props.inputType].width, props.partData[props.inputType].height, layer.get('width'), layer.get('height'));

  //This is used to scale the width/height from the lab prior to drawing, to have proper image density

  //Browser just uses base width/height to avoid recreating pattern on every update
  let imageWidth = layer.get('width');
  let imageHeight = layer.get('height');
  let imageDensityX = 1;
  let imageDensityY = 1;

  //RenderbotApp modifies image width/height to have optimal image density
  let adjustmentScale = 1;
  if (isNode) {
    const ioScaleX = props.partData[props.outputType].width / props.partData[props.inputType].width;
    const ioScaleY = props.partData[props.outputType].height / props.partData[props.inputType].height;
    imageDensityX = newScaleX * ioScaleX;
    imageDensityY = newScaleY * ioScaleY;
    imageWidth = Math.round(layer.get('width') * imageDensityX);
    imageHeight = Math.round(layer.get('height') * imageDensityY);

    //This needs to be enabled manually and allows for downscaling image to 16000, which is the current limit for canvases that will be repeated
    if (layer.get('patternMode') !== 'none' && global.allow_downscale) {
      let ratio = imageWidth / imageHeight
      if (imageWidth > 16000) {
        imageWidth = 16000;
        imageHeight = Math.ceil(imageWidth / ratio);
      }

      if (imageHeight > 16000) {
        imageHeight = 16000;
        imageWidth = Math.ceil(imageHeight * ratio);
      }

      adjustmentScale = Math.round(layer.get('width') * imageDensityX) / imageWidth;
    }
  }

  return <>
    {layer.get('type') === 'img' || layer.get('type') === 'pattern' ? <>
      {layer.get('patternMode') !== 'none'
        ? <KonvaPatternImage
          key={layer.get('id') + '-pattern'}
          x={x}
          y={y}
          width={maxSide * 2}
          height={maxSide * 2}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          fillPatternX={maxSide}
          fillPatternY={maxSide}
          fillPatternOffsetX={imageWidth / 2}
          fillPatternOffsetY={imageHeight / 2}
          fillPatternScaleX={newScaleX / imageDensityX * adjustmentScale}
          fillPatternScaleY={newScaleY / imageDensityY * adjustmentScale}
          offsetX={maxSide}
          offsetY={maxSide}
          rotation={layer.get('rotation')}
          fileid={layer.get('fileid')}
          src={src}
          patternMode={layer.get('patternMode')}
          onLoad={updateTexture}
          filters={filters}
          listening={false}
        />
        : <KonvaImage
          key={layer.get('id')}
          src={src}
          rotation={layer.get('rotation')}
          width={layer.get('width')}
          height={layer.get('height')}
          offsetX={layer.get('width') / 2}
          offsetY={layer.get('height') / 2}
          x={x}
          y={y}
          scaleX={newScaleX}
          scaleY={newScaleY}
          imageDensity={Math.max(imageDensityX, imageDensityY)}
          onLoad={updateTexture}
          filters={filters}
          listening={false}
          disableCache={isNode && filters.length === 0} //Don't enable cache if we don't have filters as it slows down the rendering a lot
        />
      }
    </> : layer.get('type') === 'text' ? <Text
      key={layer.get('id')}
      text={layer.get('text')}
      fill={layer.get('color')}
      fontSize={50}
      fontFamily={layer.get('font')}
      fillEnabled={true}
      rotation={layer.get('rotation')}
      //TODO: Possible fix for text that disappears, but if our width/height is off, that means offsetX/Y might be off
      //width={layer.get('width')}
      //height={layer.get('height')}
      offsetX={layer.get('width') / 2}
      offsetY={layer.get('height') / 2}
      x={x}
      y={y}
      scaleX={newScaleX}
      scaleY={newScaleY}
      listening={false}
    /> : null}
  </>;
}