import * as Immutable from 'immutable';
import { combineReducers } from 'redux-immutable';

import { AjaxActionEnd, AjaxActionEndType, AjaxActionStart, AjaxActionStartType } from '../../Common/_actions/AjaxAction';
import { UserCartAction, UserCartActionType } from '../_actions/UserCartActions';
import { UserDataAction, UserDataActionType } from '../_actions/UserDataActions';
import UserDataStore, { IUserDataStore } from '../_stores/UserDataStore';
import UserStoreStore from '../_stores/UserStoreStore';
import { UserImageStore } from '../_stores/UserImageStore';
import { UserProductDesignStore } from '../_stores/UserProductDesignStore';
import PreviewStore from '../../AppData/_stores/PreviewStore';
import ProductOptionTypeStore, { IProductOptionTypeStore } from '../../AppData/_stores/ProductOptionTypeStore';
import ProductOptionStore, { IProductOptionStore } from '../../AppData/_stores/ProductOptionStore';
import UserPrefsReducer from './UserPrefsReducer';
import UserTaxNumbersReducer from './UserTaxNumbersReducer';
import UserStorePresetsReducer from './UserStorePresetsReducer';
import UserStorePagesReducer from './UserStorePagesReducer';
import { UserAddressStore } from '../_stores/UserAddressStore';
import PaginationStore, { PaginationDataType, PaginationItem } from '../_stores/PaginationStore';
import PaginationItemListStore from '../_stores/PaginationItemListStore';
import UserRewardStore from '../_stores/UserRewardStore';
import { UserCreditCardStore } from '../_stores/UserCreditCardStore';
import PriceStore from '../../UIData/_stores/PriceStore';
import { UserImageActionType } from '../_actions/UserImageActions';
import CollectionReducer from './CollectionReducer';
import UserCartStore from '../_stores/UserCartStore';
import { OrderStoreAction, OrderStoreActionType } from '../_actions/OrderStoreActions';
import BrandingOptionReducer from './BrandingOptionReducer';
import UserRewardReducer from './UserRewardReducer';
import PhotoLabCreationReducer from './PhotoLabCreationReducer';
import { PhotoLabCreationStore } from '../_stores/PhotoLabCreationStore';

const editItem = (dataType:PaginationDataType, item:any) => {
	let itemStore:Immutable.Record<any> = null;

	itemStore = getItem(dataType, item);
	Object.keys(item).forEach((itemKey) => {
		//TODO: Have to find a better way of handling that instead of hardcoding it here
		if(dataType === 'productDesigns') {
			if(itemKey === 'availableOptionTypes') {
				let listTypes = Immutable.OrderedMap<string, ProductOptionTypeStore>();

				for (const [key, typeData] of Object.entries(item[itemKey])) {
					let type = new ProductOptionTypeStore({
						id: typeData.id,
						type: typeData.type,
						slug: typeData.slug,
						name: typeData.name,
						theorder: typeData.theorder,
						visible: typeData.visible,
					});

					for (const [key2, optionData] of Object.entries(typeData.options)) {
						let {price_retail, price_wholesale, price_dropship, ...rest} = optionData as IProductOptionStore;
						let option = new ProductOptionStore();
						option = option.withMutations(option => {
							option.merge(Immutable.fromJS(rest));
							option.set('price_retail', new PriceStore(price_retail));
							option.set('price_wholesale', new PriceStore(price_wholesale));
							option.set('price_dropship', new PriceStore(price_dropship));
						});

						type = type.setIn(['options',String(optionData.id)], option);
					}

					type = type.set('options', type.get('options').sort((a, b) => a.get('theorder') - b.get('theorder')));

					listTypes = listTypes.set(String(typeData.id), type);
				}

				listTypes = listTypes.sort((a, b) => a.get('theorder') - b.get('theorder'));

				item[itemKey] = listTypes;
			} else if(itemKey === 'previews' || itemKey === 'textures') {
				let listPreviews = Immutable.OrderedMap<number, PreviewStore>();

				item[itemKey].forEach((previewData:any) => {
					let preview = new PreviewStore({
						id: previewData.id,
						slug: previewData.slug,
						url: previewData.url,
					});

					listPreviews = listPreviews.set(previewData.id, preview);
				});

				item[itemKey] = listPreviews;
			} else if(itemKey === 'defaultOptions') {
				let defaultOptions = Immutable.Map<number, number>();

				item[itemKey].forEach((defaultOption:any) => {
					defaultOptions = defaultOptions.set(defaultOption.id_product_option_type, defaultOption.id);
				});

				item[itemKey] = defaultOptions;
			} else if(itemKey === 'details') {
				let details = Immutable.Map<string, string>();

				item[itemKey].forEach((detail:any) => {
					details = details.set(detail.slug, detail.valueSlug);
				});

				item[itemKey] = details;
			}
		}

		if(dataType !== 'orders') {
			itemStore = itemStore.set(itemKey, item[itemKey]);
		}
	});

	return itemStore;
}

const UserDataReducer = (
	store:UserDataStore = new UserDataStore(),
	action: UserDataAction
		| UserCartAction
		| OrderStoreAction
		| AjaxActionStart<unknown>
		| AjaxActionEnd<unknown>
): UserDataStore => {
	switch (action.type) {
		case UserDataActionType.SetPaginationData:
			let list = store.get(action.data.dataType).get('data') as Immutable.OrderedMap<string, PaginationItem>;
			let items = Immutable.OrderedSet<string>();

			action.data.items.forEach(item => {
				let itemStore = editItem(action.data.dataType, item);

				let id = String(item.id);
				list = list.set(id, itemStore);
				items = items.add(id);
			});

			store = store.set(action.data.dataType, (store.get(action.data.dataType) as PaginationStore).withMutations(store => {
				store.set('data', list);
				store.set('itemLists', store.get('itemLists').set(action.listId, new PaginationItemListStore({
					items: items,
					total: action.data.totalItems,
					valid: true,
				})));
			}));
		break;

		case OrderStoreActionType.EditOrder:
			let orderStore = editItem('orders', action.order);
			store = store.setIn(['orders', 'data', String(action.order.id)], orderStore);
			break;

		case OrderStoreActionType.EditManyOrders:
			store = store.set('orders', 
				store.get('orders').set('data',
					store.get('orders').get('data').withMutations(store => {
						Object.values(action.orders).forEach(order => {
							store.set(String(order.id), editItem('orders', order))
						})
					})
				)
			)
			break;

		case UserDataActionType.EditItemData:
			let itemStore = editItem(action.data.dataType, action.data.item);
			store = store.setIn([action.data.dataType, 'data', String(action.data.item.id)], itemStore);
		break;

		case UserDataActionType.InvalidatePaginationData:
			if(action.listId) {
				store = store.setIn([action.dataType, 'itemLists', action.listId, 'valid'], false);
			} else {
				store = store.withMutations(store => {
					store.get(action.dataType).get('itemLists').forEach((itemList, key) => {
						store.set(action.dataType, store.get(action.dataType)
							.set('itemLists', store.get(action.dataType).get('itemLists')
								.set(key, store.get(action.dataType).get('itemLists').get(key)
									.set('valid', false))));
					});
				});
				
				store = store.setIn([action.dataType, 'itemLists', action.listId, 'valid'], false);
			}
		break;

		case UserDataActionType.EditPaginationLoader:
			const oldItemList = store.get(action.dataType).get('itemLists').get(action.listId);

			let newItemList = new PaginationItemListStore({
				loading: action.loader,
			});

			if(oldItemList) {
				newItemList = oldItemList.set('loading', action.loader);
			}

			store = store.setIn([action.dataType, 'itemLists', action.listId], newItemList);
			break;

		case UserDataActionType.EditUserLoggedIn:
			store = store.set('loggedIn', action.status);
		break;

		case UserDataActionType.EditUserValidated:
			store = store.set('validated', action.valid);
		break;

		case UserDataActionType.EditUserFetched:
			store = store.set('fetched', action.fetched);
		break;

		case UserDataActionType.RemoveAccountData:
			store = new UserDataStore();
		break;

		case UserDataActionType.EditStores:
			store = store.withMutations(store => {
				for(const [id, integrationStore] of Object.entries(action.stores)) {
					store = store.setIn(['stores', id], new UserStoreStore(integrationStore));
				}
			});
		break;

		case UserDataActionType.EditUserEmail:
			store = store.set('email', action.email);
		break;

		case UserDataActionType.EditUser:
			store = store.withMutations(store => {
				//Keep certain fields that may be overwritten by empty data
				store.set('email', action.user.email !== undefined ? action.user.email : store.get('email') ?? '');
				store.set('name', action.user.name !== undefined ? action.user.name : store.get('name') ?? '');
				store.set('phone', action.user.phone !== undefined ? action.user.phone : store.get('phone') ?? '');
				store.set('loggedIn', action.user.loggedIn !== undefined ? action.user.loggedIn : store.get('loggedIn') ?? false);
				store.set('validated', action.user.validated !== undefined ? action.user.validated : store.get('validated') ?? false);
				store.set('newsletter_news', action.user.newsletter_news !== undefined ? action.user.newsletter_news : store.get('newsletter_news') ?? false);
				store.set('newsletter_offers', action.user.newsletter_offers !== undefined ? action.user.newsletter_offers : store.get('newsletter_offers') ?? false);
			})
			break;

		case AjaxActionStartType:
		case AjaxActionEndType:
			const loading = action.type === AjaxActionStartType;

			switch(action.actionType) {
				case UserImageActionType.UploadUserImage:
					store = store.setIn(['images', 'isUploading'], loading);
					break;
			}
	}

	const defaultStore = new UserDataStore
	return new UserDataStore(combineReducers<IUserDataStore>({
		fetched: (store) => store ?? defaultStore.get('fetched'),
		id: (store) => store ?? defaultStore.get('id'),
		loggedIn: (store) => store ?? defaultStore.get('loggedIn'),
		email: (store) => store ?? defaultStore.get('email'),
		name: (store) => store ?? defaultStore.get('name'),
		phone: (store) => store ?? defaultStore.get('phone'),
		validated: (store) => store ?? defaultStore.get('validated'),
		newsletter_news: (store) => store ?? defaultStore.get('newsletter_news'),
		newsletter_offers: (store) => store ?? defaultStore.get('newsletter_offers'),
		addresses: (store) => store ?? defaultStore.get('addresses'),
		creditCards: (store) => store ?? defaultStore.get('creditCards'),
		orders: (store) => store ?? defaultStore.get('orders'),
		rewards: UserRewardReducer,
		images: (store) => store ?? defaultStore.get('images'),
		productDesigns: (store) => store ?? defaultStore.get('productDesigns'),
		collections: CollectionReducer,
		brandingOptions: BrandingOptionReducer,
		photoLabCreations: PhotoLabCreationReducer,
		prefs: UserPrefsReducer,
		taxNumbers: UserTaxNumbersReducer,
		storePresets: UserStorePresetsReducer,
		storePages: UserStorePagesReducer,
		stores: (store) => store ?? defaultStore.get('stores'),
	})(store, action));
}

function getItem(key:string, data:any):Immutable.Record<any> {
	switch(key) {
		case 'images': return new UserImageStore()
		case 'productDesigns': return new UserProductDesignStore()
		case 'addresses': return new UserAddressStore()
		case 'creditCards': return new UserCreditCardStore()
		case 'orders': return new UserCartStore(data)
		case 'photoLabCreations': return new PhotoLabCreationStore()
	}
}

export default UserDataReducer;