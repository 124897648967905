import { useCallback, useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import Point from '../../Common/Utils/Math/Point';
import { EditActiveSubproduct, EditAutoDesignSubproduct, EditWarnings, RemoveLayer, SelectLayer } from '../../UIData/_actions/DesignLabStoreActions';
import WebsiteStore from '../../WebsiteStore';
import useCheckDpi from '../_hooks/useCheckDpi';
import useCheckWarningPoint from '../_hooks/useCheckWarningPoint';
import useLabData from '../_hooks/useLabData';
import { SceneTab, SceneTabsContainer } from './SceneSelector';
import ValidIndicator from './ValidIndicator';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

type Props = {}

export default function SubproductSelector(props:Props) {
    const dispatch = useAppDispatch();

    const activeProductId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
    const activeSubproduct = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeSubproduct'));
    const activeVariant = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeVariant'));
    const productData = useSelector((state:WebsiteStore) => state.get('appData').get('products').get(String(activeProductId)));
    const layers = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('layers'));
    const warnings = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('warnings'));
    const autoDesignSubproducts = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('autoDesignSubproducts'));
    const labData = useLabData();
    const checkWarningPoint = useCheckWarningPoint();
    const checkDpi = useCheckDpi();

    //Cleanup layers for scenes that don't exist when switching to a new product
    useEffect(() => {
        if(!productData.get('labData')) return;

        if(!productData.get('labData').has_subproducts) {
            layers.forEach(layer => {
                if(layer.subproduct) {
                    dispatch(RemoveLayer(layer.get('id')));
                }
            });
            return;
        }

        const tmpSubproducts = Object.keys(productData.get('labData').subproducts);

        layers.forEach(layer => {
            if(!tmpSubproducts.includes(layer.subproduct)) {
                dispatch(RemoveLayer(layer.get('id')));
            }
        });
    }, [productData]);

    const subproducts = useMemo(() => {
        if(!productData.get('labData')?.has_subproducts) return [];

        const tmpSubproducts = Object.keys(productData.get('labData').subproducts);

        return tmpSubproducts.map((subproduct, index) => {
            let productObj = window.Products.getProduct(productData.get('labData').subproducts[subproduct].id);

            return {
                slug: subproduct,
                name: productObj.static.tabName,
            }
        });
    }, [productData]);

    useEffect(() => {
        if(subproducts.length < 1) return;

        batch(() => {
            dispatch(EditActiveSubproduct(subproducts[0].slug));

            subproducts.forEach((subproduct,index) => {
                dispatch(EditAutoDesignSubproduct(subproduct.slug, !(index === 0)));
            })
        })
    }, [subproducts]);

    useEffect(() => {
        let tmpWarnings:Record<string,Record<string,boolean>> = {};
        subproducts.forEach(subproduct => {
            tmpWarnings[subproduct.slug] = {
                'templates_covered': false,
                'resolution': true,
            };
            let templatesCoveredOk = true;
            let resolutionOk = true;

            //Ignore checks if autoDesign is on for that subproduct
            if(!autoDesignSubproducts.get(subproduct.slug)) {
                //Check templates_covered
                if(productData.get('labData')) {
                    productData.get('labData').subproducts[subproduct.slug].originalData.variants[activeVariant].scenes['main'].warnings.forEach((warning:Point) => {
                        templatesCoveredOk = templatesCoveredOk && checkWarningPoint(warning, 'main', subproduct.slug);
                    });
                }

                //Check resolution
                layers.forEach(layer => {
                    if(layer.get('subproduct') !== subproduct.slug) {
                        return;
                    }

                    const { dpiIndicator } = checkDpi(layer);
                    
                    resolutionOk = resolutionOk && dpiIndicator !== 'bad';
                })
            }

            tmpWarnings[subproduct.slug].templates_covered = templatesCoveredOk;
            tmpWarnings[subproduct.slug].resolution = resolutionOk;
        })

        dispatch(EditWarnings(tmpWarnings));
    }, [subproducts, productData, layers, activeVariant, autoDesignSubproducts, checkWarningPoint, checkDpi]);

    const renderSubproduct = useCallback((subproduct:{slug: string, name: string}) => {
        const resolutionOk = warnings.get('resolution').get(subproduct.slug);
        const templatesCoveredOk = warnings.get('templates_covered').get(subproduct.slug);

        return <SceneTab
            key={subproduct.slug}
            onClick={() => {
                dispatch(EditActiveSubproduct(subproduct.slug))
                dispatch(SelectLayer(null));
            }}
            className={activeSubproduct === subproduct.slug ? 'active':''}
        >
            <ValidIndicator valid={templatesCoveredOk && resolutionOk} iconSize="small" />
            { subproduct.name }
        </SceneTab>
    }, [activeSubproduct, warnings])

    return productData.get('labData').has_subproducts ? <SceneTabsContainer>
        { subproducts.map(renderSubproduct) }
    </SceneTabsContainer> : null;
}