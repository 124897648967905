import { Button, Collapse, List, ListItem, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, ListProps, SwipeableDrawer, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AutoFixHigh, ExpandLess, ExpandMore, Public as PublicIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import WebsiteStore from '../WebsiteStore';
import PreferencesDialog from './PreferencesDialog';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../Common/_hooks/useAppSelector';

const CustomSwipeableDrawer = styled(SwipeableDrawer)({
  top: '52px',
  '.MuiDrawer-paper': {
    backgroundColor: 'rgba(0, 0, 0, .95)',
    width: '100%',
    top: '52px',
    overflow: 'hidden',
  }
});

const ScrollableWrapper = styled('div')({
  paddingBottom: 200,
  overflow: 'auto',
});

const MainMenuList = styled(List)({

});

const AccountList = styled(List)({
  borderTop: '1px solid #3A3A3A',
});

const CollapseMobileMenu = styled(Collapse)({
  color: '#fff',
  borderBottom: '1px solid #3A3A3A',
  paddingTop: 0,
  transition: 'border-top 300ms, height 300ms',

  'span': {
    fontSize: 16,
  },

  '.MuiListItemText-root': {
    margin: 0,
  }
});

const CollapseListStyle = styled(List)({
  paddingBottom: 30
});

function CollapseList<C extends React.ElementType>(
  props: ListProps<C, { component?: C }>,
) {
  return <CollapseListStyle
    {...props}
  />
}

const ListItemMobileMenu = styled(ListItem)({
  color: '#fff',
  paddingTop: 0,
  paddingBottom: 0,
  borderTop: '1px solid transparent',
  transition: 'border-bottom 300ms',
  paddingLeft: 0,
  paddingRight: 0,

  'span': {
    fontSize: 22,
    paddingLeft: 15,
    paddingRight: 15,
  },

  '&.active': {
    color: '#F600BE',
    ':not(.account)': {
      borderTop: '1px solid #3A3A3A',
    },
    'span': {
      fontWeight: 500,
    }
  },
  '&.loginoutup': {
    'span': {
      color: '#fcee23',
    },
  },
});

const ListItemSiteSettingsBtn = styled(ListItem)({
  color: '#fff',
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  borderTop: '1px solid #3A3A3A',
  marginTop: 15,
  'span': {
    fontSize: 18,
    paddingLeft: 15,
    paddingRight: 15,
  },

  '.MuiListItemIcon-root': {
    color: '#fff',
    minWidth: 20,
    marginLeft: 10,

    'svg': {
      fontSize: '2rem',
    }
  },
});


const ButtonsWrapper = styled('div')({
  position: 'absolute',
  bottom: 50,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'space-around',
  paddingBottom: 30,
  background: 'linear-gradient(to top, #000, transparent)',
});

const ButtonCreate = styled(Button)({
  display: 'block',
  borderRadius: 50,
  fontSize: 32,
  lineHeight: 1,
  fontFamily: 'Bebas Neue,sans-serif',
  textTransform: 'uppercase',
  backgroundColor: '#F600BC',
  backgroundImage: 'linear-gradient(to top, #F600BE, #EA008A)',
  color: '#fff',
  width: '50%',
  maxWidth: 300,
  height: 'auto',
  padding: '15px 20px 13px',
  margin: '0 7.5px 0 15px',
  textAlign: 'center',
  transition: 'background 250ms',
  '&:hover': {
    backgroundImage: 'none',
    backgroundColor: '#F600BC',
  }
});

const AccountTitle = styled('h5')({
  paddingLeft: 30,
  textTransform: 'uppercase',
  fontWeight: 700,
  marginBottom: 5,
  marginTop: 20,
});

const SubListItemButtonStyle = styled(ListItemButton)({
  paddingTop: 7,
  paddingBottom: 7,

  '&:hover': {
    color: '#FFF000',
  }
})

function SubListItemButton<C extends React.ElementType>(
  props: ListItemButtonProps<C, { component?: C }>,
) {
  return <SubListItemButtonStyle
    {...props}
  />
}

const Hamburger = styled(Button)(({ theme }) => ({
  display: 'block',
  minWidth: 25,
  alignSelf: 'stretch',
  borderRadius: 0,

  '.bar': {
    background: '#fff',
    display: 'block',
    height: 2,
    width: 25,
    borderRadius: 15,
    marginBottom: 5,

    '&:first-of-type': {
      marginTop: 5,
    }
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  }
}));

export default function MobileNav() {
  const [t] = useTranslation('header');
  const [tR] = useTranslation('routes');

  const currency = useAppSelector(state => state.get('userData').get('prefs').get('currency'));
  const lang = useAppSelector(state => state.get('userData').get('prefs').get('lang'));
  const isLoggedIn = useAppSelector(state => state.get('userData').get('loggedIn'));
  const stores = useAppSelector(state => state.get('userData').get('stores'));
  const createUrl = useAppSelector(state => state.get('UIData').get('createUrl'))

  const [opened, setOpened] = useState(false);
  const [openedSubnav, setOpenedSubnav] = useState<'none' | 'info' | 'dropship' | 'policies' | 'account'>('none');
  const [prefDialogOpened, setPrefDialogOpened] = useState(false);

  const onToggle = useCallback(() => setOpened(value => !value), []);

  const onOpen = useCallback(() => setOpened(true), []);
  const onClose = useCallback(() => setOpened(false), []);

  const onPrefDialogOpen = useCallback(() => {
    setPrefDialogOpened(true)
  }, [])

  const onPrefDialogClose = useCallback(() => {
    setPrefDialogOpened(false)
  }, [])

  return <>
    <Hamburger
      variant="contained"
      color="secondary"
      onClick={onToggle}
    >
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </Hamburger>

    <CustomSwipeableDrawer
      open={opened}
      onOpen={onOpen}
      onClose={onClose}
      hideBackdrop={true}
      sx={{
        display: {
          xs: 'block',
          md: 'none'
        }
      }}
    >
      <ScrollableWrapper>
        <MainMenuList>
          <ListItemMobileMenu>
            <ListItemButton component="a" href={tR('/products')}>
              <ListItemText primary={t('Products')} />
            </ListItemButton>
          </ListItemMobileMenu>
          <ListItemMobileMenu onClick={() => openedSubnav === 'info' ? setOpenedSubnav('none') : setOpenedSubnav('info')} className={openedSubnav === 'info' ? 'active' : ''}>
            <ListItemButton>
              <ListItemText primary={t('Infos')} />
              {openedSubnav === 'info' ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItemMobileMenu>
          <CollapseMobileMenu in={openedSubnav === 'info'} timeout="auto">
            <CollapseList component="div" disablePadding>
              <SubListItemButton component="a" href={tR('/drop-shipping')} sx={{ pl: 4 }}>
                <ListItemText primary={t('What is Drop Shipping')} />
              </SubListItemButton>
              <SubListItemButton component="a" href={tR('/wholesale')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Wholesale')} />
              </SubListItemButton>
              <SubListItemButton component="a" href={tR('/info/production-and-shipping')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Production and Shipping')} />
              </SubListItemButton>
              <SubListItemButton component="a" href={tR('/info/pricing')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Pricing')} />
              </SubListItemButton>
              <SubListItemButton component="a" href={tR('/info/design-guidelines')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Design Guidelines')} />
              </SubListItemButton>
              <SubListItemButton component="a" href={tR('/custom-labels-and-packaging')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Custom Labels & Packaging')} />
              </SubListItemButton>
              <SubListItemButton component="a" href={tR('/info/sample-packs')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Order Sample Packs')} />
              </SubListItemButton>
            </CollapseList>
          </CollapseMobileMenu>
          <ListItemMobileMenu onClick={() => openedSubnav === 'dropship' ? setOpenedSubnav('none') : setOpenedSubnav('dropship')} className={openedSubnav === 'dropship' ? 'active' : ''}>
            <ListItemButton>
              <ListItemText primary={t('Sell with Art of Where')} />
              {openedSubnav === 'dropship' ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItemMobileMenu>
          <CollapseMobileMenu in={openedSubnav === 'dropship'} timeout="auto">
            <CollapseList component="div" disablePadding>
              <SubListItemButton component="a" href={tR('/drop-shipping/etsy')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Sell with Etsy')} />
              </SubListItemButton>
              <SubListItemButton component="a" href={tR('/drop-shipping/shopify')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Sell with Shopify')} />
              </SubListItemButton>
              <SubListItemButton component="a" href={tR('/drop-shipping/squarespace')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Sell with Squarespace')} />
              </SubListItemButton>
              <SubListItemButton component="a" href={tR('/drop-shipping/bigcartel')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Sell with Big Cartel')} />
              </SubListItemButton>
            </CollapseList>
          </CollapseMobileMenu>
          <ListItemMobileMenu onClick={() => openedSubnav === 'policies' ? setOpenedSubnav('none') : setOpenedSubnav('policies')} className={openedSubnav === 'policies' ? 'active' : ''}>
            <ListItemButton>
              <ListItemText primary={t('Policies')} />
              {openedSubnav === 'policies' ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItemMobileMenu>
          <CollapseMobileMenu in={openedSubnav === 'policies'} timeout="auto">
            <CollapseList component="div" disablePadding>
              <SubListItemButton component="a" href={tR('/info/return-policy')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Refund/Return')} />
              </SubListItemButton>
              <SubListItemButton component="a" href={tR('/info/privacy-policy')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Privacy')} />
              </SubListItemButton>
              <SubListItemButton component="a" href={tR('/info/terms-of-service')} sx={{ pl: 4 }}>
                <ListItemText primary={t('Terms of Service')} />
              </SubListItemButton>
            </CollapseList>
          </CollapseMobileMenu>
          <ListItemMobileMenu>
            <ListItemButton component="a" href={tR('/help')}>
              <ListItemText primary={t('Help')} />
            </ListItemButton>
          </ListItemMobileMenu>
          <ListItemMobileMenu>
            <ListItemButton component="a" href="https://blog.artofwhere.com" target="_blank">
              <ListItemText primary={t('Blog')} />
            </ListItemButton>
          </ListItemMobileMenu>
        </MainMenuList>
        <AccountList>
          {isLoggedIn ? <>
            <ListItemMobileMenu onClick={() => openedSubnav === 'account' ? setOpenedSubnav('none') : setOpenedSubnav('account')} className={openedSubnav === 'account' ? 'active account' : ''}>
              <ListItemButton>
                <ListItemText primary={t('My account')} />
                {openedSubnav === 'account' ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItemMobileMenu>
            <CollapseMobileMenu in={openedSubnav === 'account'} timeout="auto">
              <CollapseList component="div" disablePadding>
                <AccountTitle>{t('Account')}</AccountTitle>
                <SubListItemButton component="a" href={tR('/account/products')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Products')} />
                </SubListItemButton>
                <SubListItemButton component="a" href={tR('/account/orders')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Orders')} />
                </SubListItemButton>
                <SubListItemButton component="a" href={tR('/account/images')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Image Library')} />
                </SubListItemButton>
                <SubListItemButton component="a" href={tR('/account/branding-options')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Branding options')} />
                </SubListItemButton>
                { /* <SubListItemButton component="a" href="" sx={{ pl: 4 }}>
                                    <ListItemText primary={t('Goals & Rewards')} />
                                </SubListItemButton> */ }
                <SubListItemButton component="a" href={tR('/account/photo-lab')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Photo Lab')} />
                </SubListItemButton>

                <AccountTitle>{t('Settings')}</AccountTitle>
                <SubListItemButton component="a" href={tR('/account/settings/info')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Personal info')} />
                </SubListItemButton>
                <SubListItemButton component="a" href={tR('/account/settings/notifications')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Notifications')} />
                </SubListItemButton>
                <SubListItemButton component="a" href={tR('/account/settings/security')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Security')} />
                </SubListItemButton>
                <SubListItemButton component="a" href={tR('/account/settings/addresses')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Addresses')} />
                </SubListItemButton>
                <SubListItemButton component="a" href={tR('/account/settings/credit-cards')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Credit cards')} />
                </SubListItemButton>
                <SubListItemButton component="a" href={tR('/account/settings/tax-numbers')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Tax numbers')} />
                </SubListItemButton>

                <AccountTitle>{t('Stores')}</AccountTitle>
                {stores.valueSeq().map(store => {
                  return <SubListItemButton key={store.get('id')} component="a" href={tR('/d') + '/' + store.get('slug')} sx={{ pl: 4 }}>
                    <ListItemText primary={store.get('name')} />
                  </SubListItemButton>
                })}
                <SubListItemButton component="a" href={tR('/account/integrations')} sx={{ pl: 4 }}>
                  <ListItemText primary={t('Add Store')} />
                </SubListItemButton>
              </CollapseList>
            </CollapseMobileMenu>
          </> : null}
          {isLoggedIn && stores.count() > 0 ? <ListItemMobileMenu>
            <ListItemButton component="a" href="/d">
              <ListItemText primary={t('Dashboard')} />
            </ListItemButton>
          </ListItemMobileMenu> : null}
          {isLoggedIn ? <>
            <ListItemMobileMenu className="loginoutup">
              <ListItemButton component="a" href={tR('/auth/logout')}>
                <ListItemText primary={t('Logout')} />
              </ListItemButton>
            </ListItemMobileMenu>
          </> : <>
            <ListItemMobileMenu className="loginoutup">
              <ListItemButton component="a" href={tR('/auth/login')}>
                <ListItemText primary={t('Login')} />
              </ListItemButton>
            </ListItemMobileMenu>
            <ListItemMobileMenu className="loginoutup">
              <ListItemButton component="a" href={tR('/auth/signup')}>
                <ListItemText primary={t('Sign up')} />
              </ListItemButton>
            </ListItemMobileMenu>
          </>}
          <ListItemSiteSettingsBtn>
            <ListItemButton onClick={onPrefDialogOpen}>
              <ListItemIcon><PublicIcon /></ListItemIcon>
              <ListItemText primary={t('{{lang}} (${{currency}})', {
                lang: lang === 'fr_CA' ? 'FR' : 'EN',
                currency: currency
              })} />
            </ListItemButton>
          </ListItemSiteSettingsBtn>
        </AccountList>
      </ScrollableWrapper>
      <ButtonsWrapper>
        <ButtonCreate href={tR('/create')+(createUrl !== '' ? '/'+createUrl : '')}>{t('Create')}</ButtonCreate>
      </ButtonsWrapper>
    </CustomSwipeableDrawer>

    <PreferencesDialog
      opened={prefDialogOpened}
      onClose={onPrefDialogClose}
    />
  </>
}