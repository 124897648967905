import { useState, useEffect } from 'react';

function useAnimFrame(rerender: boolean, cb ?: () => void, deps ?: any[]) {
    const [, setTick] = useState(0)
    useEffect(() => {
        let id: number
        const frame = () => {
            setTick(prev => prev + 1)
            cb && cb()
            if (rerender) id = requestAnimationFrame(frame)
        }
        frame()
        return () => cancelAnimationFrame(id)
    }, [rerender, ...deps])
}

export default useAnimFrame