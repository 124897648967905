import { Typography, styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import PatternStore from '../_stores/PatternStore';
import { useCallback } from 'react';

const ItemImage = styled('img')({
	width: '100%',
	height: '100%',
	objectFit: 'cover',
	pointerEvents: 'none',
})

export type Props = {
	item: PatternStore
	selected?: boolean
	onSelect: (item: PatternStore) => void
	onClose: () => void
}

export default function PatternBrowserItem(props: Props) {
	const onClick = useCallback(() => {
		props.onSelect(props.item);
		props.onClose()
	}, [props.onSelect, props.onClose])

	return <Grid item key={props.item.get('id')} xs={6} sm={4} lg={3} xl={2}>
		<Card elevation={3}>
			<CardActionArea
				onClick={onClick}
				style={{
					paddingBottom: '90%',
					height: 0,
				}}
			>
				<div style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
					<ItemImage src={props.item.get('thumbnail_src')} crossOrigin="anonymous" />
				</div>
			</CardActionArea>
			<CardActions
				sx={{
					px: 2,
					flexGrow: 1,
				}}
			>
				<Typography
					variant="h4"
					style={{
						flexGrow: 1,
						padding: '8px 0',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}
				>{props.item.get('name')}</Typography>
			</CardActions>
		</Card>
	</Grid>
}