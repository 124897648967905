import { type ChangeEvent, type FormEvent, type SyntheticEvent, useCallback, useState } from 'react'
import DialogButton from '../../Common/Buttons/DialogButton'
import Dialog from '../../Common/Dialog/Dialog'
import DialogActions from '../../Common/Dialog/DialogActions'
import DialogContent from '../../Common/Dialog/DialogContent'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import { type BrandingOptionStore } from '../../UserData/_stores/BrandingOptionStore'
import { UpdateBrandingOption } from '../../UserData/_actions/BrandingOptionActions'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'

interface Props {
  brandingOption: BrandingOptionStore
  opened: boolean
  onClose: () => void
}

export default function EditBrandingOptionDialog(props: Props) {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()

  const type = useAppSelector(state => state.get('appData').get('brandingOptionTypes').get(String(props.brandingOption.get('id_type'))))
  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('edit_branding_option'))

  const [name, setName] = useState(props.brandingOption.get('name'))
  const [isDefault, setIsDefault] = useState(props.brandingOption?.get('is_default') ?? false)

  const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }, [])

  const onChangeDefault = useCallback((e: SyntheticEvent<Element>, checked: boolean) => {
    setIsDefault(checked)
  }, [])

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(UpdateBrandingOption(
      props.brandingOption.get('id'),
      name,
      isDefault
    ).set({
      onSuccess: props.onClose
    }))
  }, [props.brandingOption, props.onClose, name, isDefault])

  if (!type) return null

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <form onSubmit={onSubmit}>
      <DialogTitle>{t('Edit Branding option')}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            autoFocus
            variant="filled"
            label={t('Name')}
            value={name}
            onChange={onChangeName}
            disabled={loader}
          />
          <FormControlLabel
            label={<Stack spacing={1}>
              <Typography variant="body2">{t('Automatically add to my orders')}</Typography>
              <Typography variant="caption">{t('It will be automatically added to all orders placed on the website, or from your store(s). This setting can only be applied to one {{name}}.', { name: type.get('name') })}</Typography>
            </Stack>}
            control={<Checkbox />}
            checked={isDefault}
            onChange={onChangeDefault}
            disabled={loader}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <DialogButton
          color="secondary"
          onClick={props.onClose}
        >{t('Cancel')}</DialogButton>
        <DialogButton
          color="primary"
          type="submit"
          disabled={name === '' || loader}
        >{t('Confirm')}</DialogButton>
      </DialogActions>
    </form>
  </Dialog>
}
