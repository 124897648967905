import * as Immutable from 'immutable';

export type PatternCategoryPayload = {
	id: number
	title: string
	thumbnail: string
	categories: PatternCategoryPayload[]
}
export interface IPatternCategoryStore {
	id: number,
	title: string,
	thumbnail: string
	categories: Immutable.OrderedMap<number, PatternCategoryStore>
}

const defaultStore = Immutable.Record<IPatternCategoryStore>({
	id: undefined,
	title: undefined,
	thumbnail: undefined,
	categories: Immutable.OrderedMap<number, PatternCategoryStore>()
});

export default class PatternCategoryStore extends defaultStore implements IPatternCategoryStore {}