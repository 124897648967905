import { CartHeaderH1 } from '../CartContent/CartHeader'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { selectCarts } from '../../UserData/_selectors/OrderSelectors'
import CheckoutPayment from '../../Checkout/Payment/CheckoutPayment'
import { Stack } from '@mui/material'

export default function CartPayment() {
  const [t] = useTranslation('cart')
  const [tR] = useTranslation('routes')

  const carts = useAppSelector(selectCarts)

  return <Stack spacing={2}>
    <CartHeaderH1>
      <Link to={tR('/cart')}>{t('Cart')}</Link>{' > '}
      <Link to={tR('/cart/shipping')}>{t('Shipping')}</Link>{' > '}
      <span className="title">{t('Payment')}</span>
    </CartHeaderH1>
    <div>
      <CheckoutPayment
        orders={carts}
        confirmationUrl={tR('/cart/confirmation')}
      />
    </div>
  </Stack>
}
