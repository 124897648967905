import { styled } from '@mui/material';

const Loader = styled('div')({
    paddingTop: '30px',
    textAlign: 'center',
})

const Circle = styled('div')({
    position: 'relative',
    zIndex: 999,
    display: 'inline-block',
    margin: '0 2px',
    height: '20px',
    width: '20px',
    border: '3px solid',
    borderRadius: '60px',
    boxSizing: 'content-box',

    '&.loader-blue': {
        borderColor: '#00ACED',
        animation: 'jump .5s ease 0s infinite alternate',
    },

    '&.loader-pink': {
        borderColor: '#F600BC',
        animation: 'jump .5s ease .15s infinite alternate',
    },

    '&.loader-yellow': {
        borderColor: '#FFF000',
        animation: 'jump .5s ease .25s infinite alternate',
    },

    '@keyframes jump': {
        '0%': {
            transform: 'scaleY(.9)',
        },
        '100%': {
            transform: 'translateY(-30px)',
        }
    }
})

export default function LabLoader() {
    return <Loader>
        <Circle className="loader-blue"/>
        <Circle className="loader-pink"/>
        <Circle className="loader-yellow"/>
    </Loader>
}