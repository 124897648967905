import { type FormEvent, useCallback, useEffect, useState } from 'react'
import Dialog from '../../../Common/Dialog/Dialog'
import DialogTitle from '../../../Common/Dialog/DialogTitle'
import DialogContent from '../../../Common/Dialog/DialogContent'
import DialogActions from '../../../Common/Dialog/DialogActions'
import DialogButton from '../../../Common/Buttons/DialogButton'
import { useTranslation } from 'react-i18next'
import { UserAddressStore } from '../../../UserData/_stores/UserAddressStore'
import { CreateUserAddress, UpdateUserAddress, UserAddressActionType } from '../../../UserData/_actions/UserAddressActions'
import { useAppDispatch } from '../../../Common/_hooks/useAppDispatch'
import AddressForm from './AddressForm'
import { useAppSelector } from '../../../Common/_hooks/useAppSelector'
import ErrorAlert from '../../../Common/Error/ErrorAlert'

interface Props {
  address?: UserAddressStore
  opened: boolean
  onClose: () => void
}

export default function EditAddressDialog(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')

  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('edit_address'))

  const [address, setAddress] = useState(props.address ?? new UserAddressStore())
  const [valid, setValid] = useState(false)

  useEffect(() => {
    setAddress(props.address ?? new UserAddressStore())
  }, [props.address])

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const fd = new FormData()

    if (props.address) {
      fd.append('id', String(props.address.get('id')))
    }

    fd.append('name', address.get('name'))
    fd.append('address', address.get('address'))
    fd.append('address2', address.get('address2'))
    fd.append('postal_code', address.get('postal_code'))
    fd.append('city', address.get('city'))
    fd.append('province', address.get('province'))
    fd.append('country', String(address.get('country')))
    fd.append('phone', address.get('phone'))
    fd.append('default_billing', address.get('default_billing') ? '1' : '0')
    fd.append('default_shipping', address.get('default_shipping') ? '1' : '0')

    if (props.address) {
      dispatch(UpdateUserAddress(fd).set({
        onSuccess: () => {
          props.onClose()
        }
      }))
    } else {
      dispatch(CreateUserAddress(fd).set({
        onSuccess: () => {
          props.onClose()
          setAddress(new UserAddressStore())
        }
      }))
    }
  }, [address, props.address])

  return <Dialog
    open={props.opened}
    maxWidth="md"
    fullWidth
    onClose={props.onClose}
    showCloseButton
    ornaments="shapes-2"
  >
    <form onSubmit={onSubmit}>
      <DialogTitle>{ props.address ? t('Edit address') : t('Add address')}</DialogTitle>
      <DialogContent>
        <AddressForm
          address={address}
          onChange={setAddress}
          onValidCheck={setValid}
          save={true}
          disabled={!!loader}
        />

        <ErrorAlert
          errorKey={props.address ? UserAddressActionType.UpdateUserAddress : UserAddressActionType.CreateUserAddress}
        />
      </DialogContent>
      <DialogActions>
        <DialogButton
          color="secondary"
          onClick={props.onClose}
          disabled={!!loader}
        >{t('Cancel')}</DialogButton>
        <DialogButton
          color="primary"
          type="submit"
          disabled={!valid || !!loader}
        >{t('Confirm')}</DialogButton>
      </DialogActions>
    </form>
  </Dialog>
}
