import { Alert, Box, Button, Collapse, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import tKey from "../Common/Utils/tKey";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../Common/_hooks/useAppDispatch";
import { ResendValidationLink } from "./_actions/AuthActions";
import AuthForm from "./AuthForm";
import { useAppSelector } from "../Common/_hooks/useAppSelector";
import StoreIntegrationStore from "../AppData/_stores/StoreIntegration";

export default function AccountValidated() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('auth')
  const [tR] = useTranslation('routes')
  const [searchParams,setSearchParams] = useSearchParams()

  const name = useAppSelector(state => state.get('userData').get('name'))
  const storeIntegrations = useAppSelector(state => state.get('appData').get('storeIntegrations')
    .filter(storeIntegration => !storeIntegration.get('disabled')), {
      equalityFn: (a, b) => a.equals(b)
    })

  // Trigger tracking
  useEffect(() => {
    if(!!searchParams.get('logged')) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    window.gtag && window.gtag('event', 'Account validated', {
      event_category: 'Sign up',
      event_action: 'Account validated'
    });
  }, [searchParams])

  const renderIntegration = useCallback((storeIntegration:StoreIntegrationStore) => {
    return <Grid
      key={storeIntegration.get('slug')}
      item
      xs={12}
      sm={6}
    >
      <Button
        variant="contained"
        color="gray"
        fullWidth
        startIcon={<img 
          src={require('@resources/img/account/' + storeIntegration.get('slug') + '-icon.svg?url')}
          width={24}
          height={24}
        />}
        size="large"
        sx={{
          textAlign: 'left',
          justifyContent: 'flex-start',
        }}
        component={Link}
        to={tR('/d/{{store}}/connect', {store: storeIntegration.get('slug')})}
        reloadDocument
      >{storeIntegration.get('name')}</Button>
    </Grid>
  }, [])

  return <Box component="div" sx={{
    backgroundImage: 'url(' + require('@resources/img/theme/page/ornaments-1.svg?url') + ')',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    pt: 6,
    pb: 15,
    flex: '1 0 auto',
  }}>
    <Container
      maxWidth="md"
    >
      <Helmet>
        <title>{t('Account validated')}</title>
      </Helmet>

      <Stack spacing={3}>
        <Paper
          elevation={0}
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Stack
            spacing={4}
            sx={{
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              px: {
                xs: 4,
                sm: 8,
                md: 10,
              },
              pt: 6,
              pb: 13,
            }}
          >
            <img 
              src={require('@resources/img/signup/email-confettis.svg?url')}
              width={172}
              height={118}
            />
            <Stack spacing={2} alignItems="center">
              <Typography
                variant="h1"
                style={{
                  textAlign: 'center',
                }}
              >{t('Your email was confirmed!')}</Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: 'center',
                }}
              >{t('Welcome to our print-on-demand platform! Get ready to unleash your creativity. Design unique products for yourself, or add them to your store with our simple and easy to use e-commerce integrations.')}</Typography>

              <Typography
                variant="body1"
                style={{
                  textAlign: 'center',
                }}
              >{t('Let\'s turn your ideas into must-have items!')}</Typography>

              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                spacing={2}
              >
                <Button
                  variant="contained"
                  color="accent"
                  size="large"
                  component={Link}
                  to={tR('/create')}
                  reloadDocument
                >{t('Create a product')}</Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  component={Link}
                  to={tR('/account')}
                >{t('Go to Account')}</Button>
              </Stack>
            </Stack>

            <Stack spacing={2} alignItems="center">
              <Typography 
                variant="h3"
              >{t('Or')}</Typography>

              <Typography
                variant="body1"
                style={{
                  textAlign: 'center',
                }}
              >{t('Link your Art of Where account to your chosen e-commerce platform:')}</Typography>

              <div>
                <Grid
                  container 
                  spacing={2}
                  sx={{
                    maxWidth: 400,
                  }}
                >
                  { storeIntegrations.valueSeq().map(renderIntegration) }
                </Grid>
              </div>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  </Box>
}