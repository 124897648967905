import { Button, DialogContent, Grid, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PinkHeight45FullWidthButton } from '../../../Common/_components/Buttons';
import { UpscaledImagePayload } from "../../../UIData/_actions/DesignLabActions";

const PreviewImg = styled('img')({
    maxWidth: '100%',
    borderRadius: '20px',
    display: 'block',
    border: '2px solid transparent',
    boxSizing: 'border-box',
    
    '&.active': {
        borderColor: '#999',
    }
})

const FooterButton = styled(Button)({
    color: '#000',
    padding: '10px 20px',
    borderRadius: 0,
})

const ThumbnailContainer = styled(Grid)({
    height: '328px',
    overflow: 'auto',
    paddingRight: '16px',

    '&::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
    },

    '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
    },

    '&::-webkit-scrollbar-thumb:hover': {
        background: 'rgba(0, 0, 0, 0.3)',
    }
})

export const ChunkImg = styled('img')({
    display: 'block',
    width: '100%',
})

export const OldChunkImg = styled(ChunkImg)({
    borderRadius: '10px 10px 0 0',
    border: '2px solid rgba(0, 0, 0, 0.15)',
    borderBottom: 0,
})

export const NewChunkImg = styled(ChunkImg)({
    borderRadius: '0 0 10px 10px',
    border: '2px solid rgba(0, 0, 0, 0.15)',
})

type Props = {
    upscaledImage: UpscaledImagePayload
    onConfirm?: () => void
    onTryAgain?: () => void
    onClose?: () => void
}

export default function UpscaleConfirm(props:Props) {
    const [t] = useTranslation('design-lab');

    return <>
        <DialogContent
            style={{
                padding: '60px 80px',
                textAlign: 'center',
            }}
        >
            <Typography
                variant="h2" 
                style={{
                    marginBottom: '10px',
                }}
            >{ t('Scaling up your image with AI!') }</Typography>
            <Typography
                variant="body1"
                style={{
                    marginBottom: '30px',
                }}
            >{ t('It\'s ready! Here\'s a few before / after of your image.') }</Typography>
            { props.onTryAgain ? <Typography
                variant="body1"
                style={{
                    marginBottom: '30px',
                }}
            >{ t('If you don\'t like the result, you can hit Try again and our little robots will try to make it better.') }</Typography> : null }

            <Grid container columnSpacing={1} style={{
                marginBottom: '40px'
            }}>
                { props.upscaledImage.chunkFilenames.map(chunk => {
                    return <Grid key={chunk.old} item xs={6} sm={4} md={3}>
                        <OldChunkImg 
                            src={'https://storage.bhs.cloud.ovh.net/v1/AUTH_9f134bb533c64667ba7cd96ffdeaa2e7/aow-tmp/'+chunk.old}
                        />
                        <NewChunkImg 
                            src={'https://storage.bhs.cloud.ovh.net/v1/AUTH_9f134bb533c64667ba7cd96ffdeaa2e7/aow-tmp/'+chunk.new}
                        />
                    </Grid>
                })}
            </Grid>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px',
            }}>
                <PinkHeight45FullWidthButton
                    style={{
                        textTransform: 'uppercase',
                        fontFamily: "Bebas Neue",
                        fontSize: '26px',
                        width: 'auto',
                        padding: '0 80px',
                        height: '60px',
                        borderRadius: '60px',
                    }}
                    onClick={props.onConfirm}
                >{ t('I\'m happy with it') }</PinkHeight45FullWidthButton>

                { props.onTryAgain ? <Button
                    onClick={props.onTryAgain}
                >{ t('Try again') }</Button> : <Button
                    onClick={props.onClose}
                >{ t('Cancel') }</Button> }
            </div>
        </DialogContent>
    </>
}