import { createAction } from "../../Common/_actions/Action";
import { BrandingOptionPayload } from "../_stores/BrandingOptionStore";

export enum BrandingOptionStoreActionType {
  EditBrandingOption = 'BRANDING_OPTION_EDIT_BRANDING_OPTION',
  EditManyBrandingOptions = 'BRANDING_OPTION_EDIT_MANY_BRANDING_OPTIONS',
  RemoveBrandingOption = 'BRANDING_OPTION_REMOVE_BRANDING_OPTION',
}

export const EditBrandingOption = (brandingOption: BrandingOptionPayload) => createAction(BrandingOptionStoreActionType.EditBrandingOption, {
	brandingOption: brandingOption
});

export const EditManyBrandingOptions = (brandingOptions: Record<string, BrandingOptionPayload>) => createAction(BrandingOptionStoreActionType.EditManyBrandingOptions, {
	brandingOptions: brandingOptions
});

export const RemoveBrandingOption = (id: number) => createAction(BrandingOptionStoreActionType.RemoveBrandingOption, {
	id: id
});

export type BrandingOptionStoreAction =
    ReturnType<typeof EditBrandingOption> |
    ReturnType<typeof EditManyBrandingOptions> |
    ReturnType<typeof RemoveBrandingOption>