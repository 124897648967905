import { useEffect, useMemo } from "react"
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

interface Props {
  viewProductId: number
  title: string
  description: string
}

export default function ProductSEO(props: Props) {
  const [tR] = useTranslation('routes')
  const viewProductData = useAppSelector(state => state.get('appData').get('viewProducts').get(String(props.viewProductId)));

  const schemaJson = useMemo(() => {
    if (!viewProductData) return ''

    return JSON.stringify({
      '@context': 'http://schema.org/',
      '@type': 'Product',
      'name': viewProductData.get('name'),
      'image': require('@resources/img/all-products/products/' + viewProductData.get('slug') + '.jpg'),
      'description': viewProductData.get('description'),
      'sku': 'product-' + viewProductData.get('id'),
      'mpn': 'AOW-product-' + viewProductData.get('id'),
      'brand': {
        '@type': 'Brand',
        'name': 'Art of Where'
      },
      'offers': {
        '@type': 'Offer',
        'price': viewProductData.get('price').toDinero().toRoundedUnit(2).toFixed(2),
        'priceCurrency': viewProductData.get('price').get('currency'),
        'availability': 'https://schema.org/InStock',
        'itemCondition': 'https://schema.org/NewCondition',
        'url': tR('/products')+'/'+viewProductData.get('url_slug'),
        //Price is set to be valid until middle of next year
        'priceValidUntil': DateTime.now().plus({ year: 1 }).setZone('utc').set({
          month: 6,
          day: 1,
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
      }
    })
  }, [viewProductData])

  if (schemaJson === '' || !viewProductData) return null

  return <>
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />

      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={require('@resources/img/all-products/products/' + viewProductData.get('slug') + '.jpg')} />
      <meta property="og:type" content="product" />
      <meta property="product:price.amount" content={viewProductData.get('price').toDinero().toRoundedUnit(2).toFixed(2)} />
      <meta property="product:price.currency" content={viewProductData.get('price').get('currency')} />

      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={require('@resources/img/all-products/products/' + viewProductData.get('slug') + '.jpg')} />
    </Helmet>

    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schemaJson }} />
  </>
}