import * as Immutable from 'immutable';

export type UserTaxNumberPayload = {
	id: number,
	type: string,
	id_address: number,
	tax_number: string,
}

export interface IUserTaxNumberStore {
	id: number,
	type: string,
	id_address: number,
	tax_number: string,
	deleted: boolean,
}

const defaultUserTaxNumberStore = Immutable.Record<IUserTaxNumberStore>({
	id: 0,
	type: '',
	id_address: 0,
	tax_number: '',
	deleted: false,
});

export class UserTaxNumberStore extends defaultUserTaxNumberStore implements IUserTaxNumberStore {}