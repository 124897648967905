import { styled } from '@mui/material';
import Tabs from '@mui/material/Tabs';

const TabsPinkFullWidth = styled(Tabs)`
    &.MuiTabs-root{
        border-bottom: 0.912638px solid #C4C4C4;
    }

    .MuiTab-root{
        min-height: 43px;
        padding: 0;
        margin-right: 29.08px;
        font-family: Poppins;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #333333;

    }
    @media(min-width:600px){
        .MuiTab-root{
            min-width: unset;
        }
    }

    .MuiTab-textColorInherit.Mui-selected{
        color: #DA3192;
        font-weight: 600;
    }
    .MuiTabs-indicator{
        background-color: #DA3192;
    }

    button:focus{
        background-color: unset;
    }
`;
export { TabsPinkFullWidth };
