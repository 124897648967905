import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FetchUserStorePresets } from '../../UserData/_actions/UserStorePresetActions';
import MassPresets, { Props } from '../_components/MassPresets';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	productCategories: store.get('appData').get('productCategories'),
	products: store.get('appData').get('products').filter(product => product.get('visible')),
	storePresets: store.getIn(['userData', 'storePresets']),
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	fetchStorePresets: () => {dispatch(FetchUserStorePresets())},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(MassPresets);