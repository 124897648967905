import { useMemo } from 'react';
import useWindowSize from '../../Common/_hooks/useWindowSize';
import usePreviewSize from './usePreviewSize';
import { useTheme } from '@mui/material';

const HEADER_HEIGHT = 64;
const SCENE_SELECTOR_HEIGHT = 55;
const LAYER_LIST_WIDTH = 275;
const LAYER_LIST_HEIGHT = 246;

export default function useStageDimensions() {
    const windowSize = useWindowSize();
    const previewSize = usePreviewSize();
    const theme = useTheme();

    const stageSize = useMemo(() => {
        let stageWidth = windowSize.width - previewSize.width - LAYER_LIST_WIDTH;
        let stageHeight = windowSize.height - HEADER_HEIGHT - SCENE_SELECTOR_HEIGHT; //windowHeight - headerHeight - sceneSelectorHeight

        if(windowSize.width <= theme.breakpoints.values.lab_mobile && windowSize.height >= theme.height_breakpoints.values.lab_mobile ||
            windowSize.width <= 953 && windowSize.height <= 1070
        ) {
            stageWidth = windowSize.width - 30;
            stageHeight = windowSize.height - HEADER_HEIGHT - LAYER_LIST_HEIGHT - SCENE_SELECTOR_HEIGHT;
        }

        else if(windowSize.width <= 1510 && windowSize.height >= theme.height_breakpoints.values.lab_mobile) {
            stageWidth = windowSize.width - previewSize.width - 15;
            stageHeight = windowSize.height - HEADER_HEIGHT - LAYER_LIST_HEIGHT - SCENE_SELECTOR_HEIGHT;
        }

        else if(windowSize.width <= 1510) {
            stageWidth = windowSize.width - LAYER_LIST_WIDTH - 30;

            /*if(windowSize.height < theme.height_breakpoints.values.lab_mobile) {
                stageWidth = windowSize.width - 30;
                stageHeight = windowSize.height - HEADER_HEIGHT - LAYER_LIST_HEIGHT - SCENE_SELECTOR_HEIGHT;
            }*/
        } else if(windowSize.height <= theme.height_breakpoints.values.lab_mobile) {
            stageWidth = windowSize.width - LAYER_LIST_WIDTH - 15;
        } else if(windowSize.height <= 1070) {
            let leftOverSpace = windowSize.width - LAYER_LIST_WIDTH;
            stageWidth = Math.min(leftOverSpace*0.6, stageWidth);
        }

        return {
            stageWidth, stageHeight
        }
    }, [windowSize, previewSize])

    return stageSize;
}