import { useCallback } from 'react'
import DialogButton from '../../Common/Buttons/DialogButton'
import Dialog from '../../Common/Dialog/Dialog'
import DialogActions from '../../Common/Dialog/DialogActions'
import DialogContent from '../../Common/Dialog/DialogContent'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { CancelOrder } from '../../UserData/_actions/OrderActions'

interface Props {
  order: UserCartStore
  opened: boolean
  onClose: () => void
}

export default function CancelOrderDialog(props: Props) {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()

  const onConfirm = useCallback(() => {
    dispatch(CancelOrder(props.order.get('id')).set({
      onSuccess: props.onClose
    }))
  }, [props.order, props.onClose])

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <DialogTitle>{t('Cancel order #{{number}}', { number: props.order.get('ref_id').toUpperCase() })}</DialogTitle>
    <DialogContent>
      <p>{t('Are you sure that you want to cancel this order?')}</p>
    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
      >{t('No')}</DialogButton>
      <DialogButton
        color="error"
        onClick={onConfirm}
        autoFocus
      >{t('Confirm')}</DialogButton>
    </DialogActions>
  </Dialog>
}
