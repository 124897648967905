import { ArrowDropDown as ArrowDropDownIcon, ExpandLessRounded as ExpandLessRoundedIcon, CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import { Button, Fade, Grid, Grow, IconButton, Paper, Stack, Typography, styled } from '@mui/material';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import WebsiteStore from '../../WebsiteStore';
import ProductStore from '../../AppData/_stores/ProductStore';
import { TransitionGroup } from 'react-transition-group';
import ViewCategoryStore from '../../AppData/_stores/ViewCategoryStore';
import ErrorBoundary from '../../Common/_components/ErrorBoundary';
import ProductImage from '../ProductSelector/ProductImage';
import classNames from 'classnames';

const ProductCard = styled(Paper)({
  cursor: 'pointer',
  borderRadius: '20px',
  position: 'relative',
  overflow: 'hidden',
  userSelect: 'none',
  //boxShadow: '0 0 6px 2px rgba(0,0,0,.1)',
  height: '100%',

  '&:before': {
    border: '2px solid transparent',
    borderRadius: '20px',
    bottom: 0,
    content: '""',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    transition: 'border 250ms',
  },

  '&.selected:before': {
    borderColor: '#000',
  }
})

const SidebarContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  transition: 'transform 300ms, opacity 300ms',

  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    bottom: '0px',
    zIndex: '10',
    left: '20px',
    right: '20px',
    display: 'none',

    '&.has-selected-product': {
      display: 'block', 
    }
  },

  ['@media (max-width: 600px)']: {
    left: '10px',
    right: '10px',
  },

  '&.hidden': {
    transform: 'translateX(-100px)',
    opacity: '0',
  },

  '&:before': {
    content: '""',
    height: '130px',
    width: '130px',
    borderRadius: '50%',
    border: '20px solid #7FD3F3',
    display: 'block',
    position: 'absolute',
    zIndex: '-1',
    right: '-40px',
    top: '40px',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },

  '&:after': {
    content: '""',
    height: '150px',
    width: '150px',
    border: '20px solid #FCF57F',
    display: 'block',
    position: 'absolute',
    zIndex: '-1',
    right: '-60px',
    bottom: '120px',
    margin: 'auto',
    transform: 'rotate(-20deg)',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
}));

const Sidebar = styled(Paper)(({ theme }) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  background: '#fff',
  marginRight: '30px',
  width: '350px',
  flexShrink: '0',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  position: 'relative',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',

  ['@media (max-width: 1510px), (max-height: 1070px)']: {
    marginRight: '15px',
  },

  [theme.breakpoints.down('md')]: {
    width: 'auto',
    flex: 1,
    margin: '0',
    paddingTop: '0',
    paddingBottom: theme.spacing(3),
    minHeight: '0',
    transition: 'min-height 250ms',
    overflow: 'hidden',

    '&.opened': {
      minHeight: '75vh',
      maxHeight: '75vh',
      overflow: 'auto',
    }
  },

  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(2),
  }
}));

const SidebarMobileButton = styled(Button)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));

const SidebarHeader = styled('div')(({ theme }) => ({
  h2: {
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
    },
  }
}));

const SidebarContent = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '600px',
    display: 'none',
  },

  '&.opened': {
    display: 'block',
  }
}));


const StartButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: 'Bebas Neue',
  fontSize: '26px',
  padding: '0 40px',
  height: '55px',
  borderRadius: '55px',
  flexShrink: '0',
}));

const ProductListWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 300ms, opacity 300ms',

  '&.hidden': {
    transform: 'translateX(100px)',
    opacity: 0,
  }
})

const ProductList = styled(Grid)(({ theme }) => ({
  overflow: 'auto',
  marginBottom: '0',
  marginTop: '0',
  paddingBottom: '20px',
  paddingTop: '15px',

  [theme.breakpoints.down('md')]: {
    paddingBottom: '200px'
  },
}));

const ProductSelectorButton = styled('div')({
  height: '64px',
  lineHeight: '18px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: '0px 10px 0px 15px',
  backgroundColor: 'transparent',
  transition: 'background-color 150ms',

  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },

  '&.active': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
})

const ProductSelectorButtonText = styled('div')({
  marginRight: '10px',

  '@media (max-width: 599px)': {
    display: 'none',
  }
})

const SubCategoryTitle = styled('div')({
  color: '#111',
  fontFamily: "'Bebas Neue', sans-serif",
  fontSize: '30px',
  margin: '20px 0 5px',
})

const OptionUl = styled('ul')({
  paddingLeft: '25px',
  margin: '5px 0',
  lineHeight: '30px',

  '&.col2': {
    display: 'flex',
    flexWrap: 'wrap',

    li: {
      flexBasis: '50%',
      paddingRight: '30px',
    }
  }
})

const OptionLi = styled('li')``

const ProductLink = styled('a')({
  color: '#00aced',
})

const StartingPrice = styled('div')({
  fontSize: '12px',
  textAlign: 'right',
  marginTop: '-15px',

  '& > div': {
    fontFamily: 'Bebas Neue',
    fontSize: '40px',
    lineHeight: 1,
  }
})

const ProductSelectorContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '64px',
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  display: 'none',
  padding: '30px 30px 0',
  '&.opened': {
    display: 'flex'
  },
  ['@media (max-width: 1510px), (max-height: 1070px)']: {
    padding: '15px 15px 0'
  },
}));

const CategoryButton = styled(Button)(({theme}) => ({
  backgroundColor: '#d6d6d6',
  fontSize: '16px',
  lineHeight: 1.5,
  minWidth: 0,

  '&.selected': {
    backgroundColor: theme.palette.accent.main,
  }
}))

type Props = {
  onOpen?: () => void
  onClose?: () => void
};

export default function ProductSelector(props: Props) {
  const [t] = useTranslation('design-lab');
  const [tR] = useTranslation('routes');

  const activeProductId = useSelector((state: WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
  const productData = useSelector((state: WebsiteStore) => state.get('appData').get('products').get(String(activeProductId)));
  const listCategories = useSelector((state: WebsiteStore) => state.get('appData').get('viewCategories'));
  const listProducts = useSelector((state: WebsiteStore) => state.get('appData').get('products').filter(product => product.get('visible')), {
    equalityFn: (a, b) => a.equals(b)
  });
  const params = useParams<{ product?: string }>();

  const [opened, setOpened] = useState(!params.product);
  const [hide, setHide] = useState(!!params.product);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [sidebarOpened, setSidebarOpened] = useState(false);

  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        setOpened(false);
        props.onClose && props.onClose();
      }, 300);
    }
  }, [hide])

  useEffect(() => {
    if (opened) {
      props.onOpen && props.onOpen();
      setTimeout(() => {
        setHide(false);
      }, 300);
    }
  }, [opened]);

  const topLevelCategoryIds = useMemo(() => {
    let tmpList: number[] = [];
    listProducts.forEach(product => {
      product.get('viewCategories').forEach(viewCategory => {
        if (!tmpList.includes(viewCategory)) {
          tmpList.push(viewCategory);
        }
      });
    });
    return tmpList;
  }, [listProducts]);

  const listFilteredSubCategories = useMemo(() => {
    let tmpList = listCategories;

    //Filter by category
    if (selectedCategory) {
      tmpList = tmpList.filter(category => category.get('id_parent') === selectedCategory);
    } else {
      tmpList = tmpList.filter(category => topLevelCategoryIds.includes(category.get('id')));
    }

    return tmpList.sort((a, b) => {
      if (a.get('id') === b.get('id_parent')) {
        return 1;
      } else if (a.get('id_parent') === b.get('id')) {
        return -1;
      }

      return a.get('theorder') - b.get('theorder');
    });
  }, [listCategories, selectedCategory, topLevelCategoryIds])

  const listFilteredProducts = useMemo(() => {
    let tmpList = listProducts;

    //Filter by category
    if (selectedCategory) {
      //tmpList = tmpList.filter(product => product.get('viewCategories').includes(selectedCategory));
    }

    //Filter by search
    if (search) {
      const listSearch = search.toLowerCase().replace('-', '').split(' ');
      tmpList = tmpList.filter(product => {
        const productName = product.get('name').toLowerCase().replace('-', '');
        let found = false;
        let hasSearchTerm = false;
        listSearch.forEach(searchTerm => {
          if (searchTerm === "" || searchTerm.length < 3) return;

          found = found || productName.indexOf(searchTerm) !== -1;
          hasSearchTerm = true;
        });

        return !hasSearchTerm || found;
      });
    }

    return tmpList.sort((a, b) => a.get('theorder') - b.get('theorder'));
  }, [listProducts, selectedCategory, search]);

  const listSizes = useMemo(() => {
    const options = productData?.get('availableOptionTypes').get('1')?.get('options');
    if (!options) return undefined;

    let list: string[] = [];
    options.forEach(option => {
      if (list.includes(option.get('name'))) return;
      list.push(option.get('name'));
    });
    return list;
  }, [productData]);

  const listFabrics = useMemo(() => {
    const options = productData?.get('availableOptionTypes').get('2')?.get('options');
    if (!options) return undefined;

    let list: string[] = [];
    options.forEach(option => {
      if (list.includes(option.get('name'))) return;
      list.push(option.get('name'));
    });
    return list;
  }, [productData]);

  const renderProductCard = useCallback((product: ProductStore) => {
    const isSelected = product.get('id') === activeProductId;
    return <Fade key={product.get('id')}>
      <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
        <Link to={tR('/create') + '/' + product.get('url_slug')} replace style={{ textDecoration: 'none', display: 'block', height: '100%', }}>
          <ProductCard
            elevation={3}
            className={isSelected ? 'selected' : ''}
            onDoubleClick={() => {
              setHide(true);
            }}
            onClick={() => {
              if (isSelected) {
                setHide(true);
              }
            }}
          >
            <ErrorBoundary fallback={<></>}>
              <ProductImage
                slug={product.get('slug')}
                width={292}
                height={292}
              />
            </ErrorBoundary>
            <div
              style={{
                color: 'black',
                fontSize: '16px',
                fontWeight: 500,
                padding: '5px 10px 10px',
              }}
            >{product.get('name')}</div>
          </ProductCard>
        </Link>
      </Grid>
    </Fade>
  }, [activeProductId]);

  const renderCategory = useCallback((category: ViewCategoryStore) => {
    const isSelected = category.get('id') === selectedCategory;
    return <CategoryButton
      key={category.get('id')}
      variant="contained"
      color={isSelected ? 'accent':'gray'}
      onClick={() => setSelectedCategory(category.get('id'))}
      className={isSelected ? 'selected':''}
    >{ category.get('name') }</CategoryButton>
  }, [selectedCategory])

  const renderSubcategory = useCallback((subCategory: ViewCategoryStore) => {
    const tmpListProducts = listFilteredProducts.filter(product => product.get('viewCategories').includes(subCategory.get('id')));
    const nbProducts = tmpListProducts.count();
    if (nbProducts === 0) return null;

    return <Grid item xs={12} key={subCategory.get('id')}>
      <SubCategoryTitle>{subCategory.get('name')}</SubCategoryTitle>

      <Grid container spacing={2} style={{ flexWrap: 'wrap' }}>
        <TransitionGroup component={null}>
          {tmpListProducts.valueSeq().map(renderProductCard)}
        </TransitionGroup>
      </Grid>
    </Grid>
  }, [listFilteredProducts, renderProductCard])

  const onOpenButtonClick = useCallback(() => {
    if (opened) {
      setHide(true);
    } else {
      setOpened(true);
    }
  }, [opened])

  const onSidebarToggle = useCallback(() => {
    setSidebarOpened(value => !value)
  }, [])

  const onStart = useCallback(() => {
    setHide(true)
  }, [])

  const onSearchSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return false
  }, [])

  const onChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }, [])

  const onAllClick = useCallback(() => {
    setSelectedCategory(null)
  }, [])

  return <div>
    {productData ? <ProductSelectorButton
      className={opened ? 'active' : ''}
      onClick={onOpenButtonClick}
    >
      <div
        style={{
          width: '36px',
          height: '36px',
          borderRadius: '24px',
          overflow: 'hidden',
          marginRight: '15px',
          flexShrink: 0,
        }}
      >
        <ErrorBoundary fallback={<></>}>
          <ProductImage
            slug={productData.get('slug')}
            width={48}
            height={48}
          />
        </ErrorBoundary>
      </div>
      <ProductSelectorButtonText>{productData.get('name')}</ProductSelectorButtonText>
      <ArrowDropDownIcon
        style={{
          transform: opened ? 'rotate(180deg)' : '',
          transition: 'transform 250ms',
        }}
      />
    </ProductSelectorButton> : null}
    <ProductSelectorContainer className={opened ? 'opened' : ''}>
      <SidebarContainer className={classNames({
        hidden: hide,
        'has-selected-product': productData !== undefined
      })}>
        <Sidebar
          elevation={16}
          className={(hide ? 'hidden' : '') + (sidebarOpened ? ' opened' : '')}
        >
          {productData ? <>
            <SidebarMobileButton
              color="secondary"
              fullWidth
              onClick={onSidebarToggle}
              sx={{
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }}
            ><ExpandLessRoundedIcon
                fontSize="large"
                sx={{
                  transform: sidebarOpened ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 250ms',
                }}
              /></SidebarMobileButton>
            <Stack
              direction={{
                xs: 'column',
                sm: sidebarOpened ? 'column' : 'row',
                md: 'column',
              }}
              spacing={2}
              sx={{
                px: {
                  xs: 2,
                  sm: 3,
                },
                alignItems: {
                  xs: 'flex-start',
                  sm: sidebarOpened ? 'flex-start' : 'center',
                  md: 'flex-start',
                },
                justifyContent: {
                  xs: 'flex-start',
                  sm: sidebarOpened ? 'flex-start' : 'space-between',
                  md: 'flex-start',
                }
              }}
            >
              <SidebarHeader>
                <Typography variant="h2">{productData.get('name')}</Typography>
                { /*<StartingPrice>
                                    { t('Starting at') }
                                    <div>{ t('30.00$') }</div>
                                </StartingPrice>*/ }
              </SidebarHeader>
              <StartButton
                color="accent"
                variant="contained"
                disableElevation
                className={sidebarOpened ? 'opened' : ''}
                onClick={onStart}
              >{t('Create new')}</StartButton>
              <SidebarContent className={sidebarOpened ? 'opened' : ''}>
                <p
                  style={{
                    fontSize: '15px',
                    lineHeight: '1.5em',
                    marginTop: '5px',
                  }}
                >
                  {productData.get('shop_description')}
                  <br />
                  { /* false && <ProductLink href={'/products/'} target="_blank">{ t('Learn more about {{name}}', { name: productData.get('name') }) }</ProductLink> */}
                </p>

                {listFabrics ? <div style={{ marginBottom: '20px' }}>
                  <Typography
                    variant="h4"
                  >{t('Fabrics available')}</Typography>
                  <OptionUl>
                    {listFabrics.map(option => {
                      return <OptionLi key={option}>{option}</OptionLi>
                    })}
                  </OptionUl>
                </div> : null}

                {listSizes ? <div style={{ marginBottom: '20px' }}>
                  <Typography
                    variant="h4"
                  >{t('Sizes available')}</Typography>
                  <OptionUl className={listSizes.length > 10 ? 'col2' : ''}>
                    {listSizes.map(option => {
                      return <OptionLi key={option}>{option}</OptionLi>
                    })}
                  </OptionUl>
                </div> : null}
              </SidebarContent>
            </Stack>
          </> : null}

        </Sidebar>

        <IconButton
          color="secondary"
          component={Link}
          to={tR('/create')}
          replace
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            display: {
              xs: 'inline-flex',
              md: 'none',
            }
          }}
        >
          <CloseRoundedIcon fontSize="large" color="inherit" />
        </IconButton>
      </SidebarContainer>
      <ProductListWrap
        className={hide ? 'hidden' : ''}
      >
        <form method="post" action="" onSubmit={onSearchSubmit}
          style={{
            background: '#fff',
            borderRadius: 30,
            padding: '0 20px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
            position: 'relative',
            zIndex: 10,
          }}
        >
          <input
            type="text"
            value={search}
            onChange={onChangeSearch}
            placeholder={t('Search product...')}
            style={{
              color: '#000',
              border: 0,
              padding: 0,
              height: '48px',
              margin: 0,
              boxShadow: 'none',
              width: '100%',
              outline: 0,
              fontFamily: 'Poppins, Roboto, sans-serif',
              fontSize: '18px',
            }}
          />
        </form>

        <ProductList
          container
          spacing={2}
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{
                mb: 1,
                flexWrap: 'wrap',
              }}
            >
              <CategoryButton
                variant="contained"
                color={selectedCategory === null ? 'accent':'gray'}
                onClick={onAllClick}
                className={selectedCategory === null ? 'selected':''}
              >{ t('All') }</CategoryButton>
              {listCategories.filter(category => category.get('id_parent') === null).valueSeq().map(renderCategory)}
            </Stack>
          </Grid>
          {listFilteredSubCategories.valueSeq().map(renderSubcategory)}

          <TransitionGroup component={null}>
            {listFilteredProducts.filter(product => selectedCategory !== null ? product.get('viewCategories').includes(selectedCategory) : true).valueSeq().map(renderProductCard)}
          </TransitionGroup>
        </ProductList>
      </ProductListWrap>
    </ProductSelectorContainer>
  </div>
}