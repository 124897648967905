import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';

export default function AutoDesignOverlay() {
    const activeSubproduct = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeSubproduct'));
    const autoDesign = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('autoDesignSubproducts').get(activeSubproduct));

    return autoDesign ? <div style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(220, 220, 220, 0.8)',
        borderRadius: '20px 20px 0 0',
    }} /> : null
}