import { createAjaxAction, AjaxResponse, AjaxCallback } from '../../Common/_actions/AjaxAction';
import { createAction } from '../../Common/_actions/Action';
import { EditValidData, Toast } from '../../UIData/_actions/UIDataActions';
import { Dispatch } from "redux";
import * as Immutable from 'immutable';
import UserCartStore, { IUserCartStore, UserCartPayload } from '../_stores/UserCartStore';
import i18next from 'i18next';
import { EditItemData, InvalidatePaginationData, UserDataActionType } from './UserDataActions';
import { EditManyOrders, EditOrder } from './OrderStoreActions';
import ApiErrorHandler from '../../Common/Error/ApiErrorHandler';
import { PaginationItemPayload } from '../_stores/PaginationStore';

export enum UserCartActionType {
	DeleteCart = 'CART_DELETE_CART',
	SaveCartAddress = 'CART_SAVE_CART_ADDRESS',
	UpdateCartShippingType = 'CART_UPDATE_CART_SHIPPING_TYPE',
	ApplyPromoCode = 'CART_APPLY_PROMOCODE',
	DeleteCartItem = 'CART_DELETE_CART_ITEM',
	UpdateCartItem = 'CART_UPDATE_CART_ITEM',

	CartCheckoutFree = 'CART_CHECKOUT_FREE',
	CartCheckoutStripeGetPaymentIntent = 'CART_CHECKOUT_STRIPE_GET_PAYMENT_INTENT',
	CartCheckoutStripeWithCard = 'CART_CHECKOUT_STRIPE_WITH_CARD',
};

export const DeleteCart = (
	data: FormData
) => createAjaxAction<{}>(UserCartActionType.DeleteCart, {
	url: "ajax/api/cart/delete-cart",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(InvalidatePaginationData('orders', 'cart'))
	},
	onFailure: ApiErrorHandler(UserCartActionType.DeleteCart),
});

export const SaveCartAddress = (
	data: FormData
) => createAjaxAction<{orders: Record<string,UserCartPayload>}>(UserCartActionType.SaveCartAddress, {
	url: "ajax/api/cart/save-cart-address",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(EditManyOrders(response.data.orders))
		dispatch(InvalidatePaginationData('addresses'));
	},
	onFailure: ApiErrorHandler(UserCartActionType.SaveCartAddress),
});

export const UpdateCartShippingType = (
	data: FormData
) => createAjaxAction<{order:UserCartPayload}>(UserCartActionType.UpdateCartShippingType, {
	url: "ajax/api/cart/update-cart-shipping-type",
	method: "POST",
	limit: 300,
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(EditOrder(response.data.order))
	},
	onFailure: ApiErrorHandler(UserCartActionType.UpdateCartShippingType),
});

export const ApplyPromoCode = (
	cartIds: number[],
	code?: string,
) => {
	const data = new FormData

	for(let cartId of cartIds) {
		data.append('cart_ids[]', String(cartId))
	}

	if(code) {
		data.append('code', code)
	}

	return createAjaxAction<{listOrders:Record<string, UserCartPayload>}>(UserCartActionType.ApplyPromoCode, {
		url: "ajax/api/cart/apply-promo-code",
		method: "POST",
		data: data,
		onSuccess: (response,dispatch) => {
			dispatch(EditManyOrders(response.data.listOrders))
		},
		onFailure: ApiErrorHandler(UserCartActionType.ApplyPromoCode),
	})
}

export const DeleteCartItem = (
	data: FormData
) => createAjaxAction<{order:UserCartPayload}>(UserCartActionType.DeleteCartItem, {
	url: "ajax/api/cart/delete-cart-item",
	method: "POST",
	limit: 100,
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(EditOrder(response.data.order))
	},
	onFailure: ApiErrorHandler(UserCartActionType.DeleteCartItem),
});

export const UpdateCartItem = (
	data: FormData,
) => createAjaxAction<{order:UserCartPayload}>(UserCartActionType.UpdateCartItem, {
	url: 'ajax/api/cart/update-cart-item',
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(EditOrder(response.data.order))
	},
	onFailure: ApiErrorHandler(UserCartActionType.UpdateCartItem),
});

export const CartCheckoutFree = (
	id_order?: number
) => {
	const data = new FormData()

	if(id_order) {
		data.append('id_order', String(id_order));
	}

	return createAjaxAction<{payment_id: string, orders: Record<string,UserCartPayload>}>(UserCartActionType.CartCheckoutFree, {
		url: "ajax/checkout/free",
		method: "POST",
		data: data,
		onSuccess: (response, dispatch) => {
			dispatch(EditManyOrders(response.data.orders))
		}
	})
}

export const CartCheckoutStripeGetPaymentIntent = (savePaymentMethod: boolean, id_order?: number) => {
	const data = new FormData()
  data.append('saveCard', savePaymentMethod ? '1':'0');

	if(id_order) {
		data.append('id_order', String(id_order));
	}
		
	return createAjaxAction<{secret: string}>(UserCartActionType.CartCheckoutStripeGetPaymentIntent, {
		url: "ajax/checkout/creditcard-v2/get-payment-intent",
		method: "POST",
		data: data,
	})
}

export const CartCheckoutStripeWithCard = (id_card: number, id_order?: number) => {
	const data = new FormData()
	data.append('id_card', String(id_card));
	
	if(id_order) {
		data.append('id_order', String(id_order));
	}

	return createAjaxAction<{payment_id: string, orders: Record<string,UserCartPayload>}>(UserCartActionType.CartCheckoutStripeWithCard, {
		url: "ajax/checkout/creditcard-v2/pay-with-card",
		method: "POST",
		data: data,
		onSuccess: (response, dispatch) => {
			dispatch(EditManyOrders(response.data.orders))
		}
	})
}