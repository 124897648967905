import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { RegisterScrollBlock, UnregisterScrollBlock } from '../../UIData/_actions/UIDataActions';
import ScrollBlock from '../../Common/Utils/ScrollBlock';

type Props = { 
	preventClose?: boolean
	darken?: boolean
	element?:JSX.Element
	content:JSX.Element
	footer?: JSX.Element
	className?:string
	persist?:boolean
	align?:boolean
	animate?:boolean
	onOpen?:Function
	onClose?:Function
	opened?:boolean
	zIndex?: number
}

type State = { 
	top: number
	left: number
}

class ModalContainer extends React.Component<Props, State> {
	private contentRef:HTMLDivElement;
	private footerRef:HTMLDivElement;
	private modalRef:HTMLDivElement;
	private elementRef:HTMLSpanElement;
	private portal:HTMLDivElement;

	state = { top: 0, left: 0 }

	constructor(props:Props){
		super(props);
		this.getPortal();
	}

	componentDidUpdate(prevProps:Props){
		if(prevProps.opened != this.props.opened && this.props.opened){
			this.openSelf();
		}

		if(prevProps.content != this.props.content){
			this.registerEvents();
		}
	}

	getSnapshotBeforeUpdate(prevProps:Props):null {
		if(prevProps.content != this.props.content){
			this.unregisterEvents();
		}

		return null;
	}

	componentDidMount = () => this.registerEvents();
	componentWillUnmount = () => this.unregisterEvents();

	private registerEvents = () => {
		if(this.contentRef && !this.props.preventClose) this.contentRef.querySelectorAll('.modal-close').forEach((e) => e.addEventListener('click', this.closeSelf.bind(this)));
		if(this.footerRef && !this.props.preventClose) this.footerRef.querySelectorAll('.modal-close').forEach((e) => e.addEventListener('click', this.closeSelf.bind(this)));
	}

	private unregisterEvents = () => {
		if(this.contentRef) this.contentRef.querySelectorAll('.modal-close').forEach((e) => e.removeEventListener('click', this.closeSelf.bind(this)));
		if(this.footerRef) this.footerRef.querySelectorAll('.modal-close').forEach((e) => e.removeEventListener('click', this.closeSelf.bind(this)));
	}

	private getPortal(){
		if(typeof document === 'undefined') return;

		this.portal = document.getElementById('modal-portal') as HTMLDivElement || (() => {
			const portal = document.createElement('div');
			portal.setAttribute('id', 'modal-portal');
			document.body.appendChild(portal);
			return portal;
		})();
	}

	private openSelf(e?:MouseEvent){
		if(this.modalRef && this.elementRef && this.props.align){
			const rect = this.elementRef.children[0].getBoundingClientRect();
			this.modalRef.style.left = rect.left + rect.width/2 + 'px';
			this.modalRef.style.top = rect.top + rect.height/2 + 'px';
		}
		this.animate('open');
	}

	private closeSelf(){
		if(this.props.preventClose || !this.props.onClose) return;
		this.animate('close', () => {
			this.props.onClose();
		});
	}

	private animate(type:'open' | 'close', callback?:Function){
		if(this.props.animate){
			this.modalRef.style.transformOrigin = this.props.align ? '0 0' : '50% 50%';
			this.modalRef.style.animationName = type == 'open' ? 'grow' : 'shrink';
			setTimeout(callback, 150);
		} else callback();
	}

	private stopMouseEvent = (e:React.MouseEvent) => {
		e.stopPropagation();
	}

	render(){
		if(!this.portal) return <></>;

		const {element, content, footer, onOpen, className, ...otherProps} = this.props;
		
		const modalStyle:{[key: string]: any} = {
			display: this.props.opened ? 'flex' : 'none'
		};

		if(this.props.zIndex) modalStyle.zIndex = this.props.zIndex

		const visible = this.props.persist || this.props.opened
		return <> 
			{ element && onOpen && <span className='modal-element' onClick={(e) => onOpen()} ref={ref => this.elementRef = ref}>{element}</span> }
			{ ReactDOM.createPortal(visible ? <div style={modalStyle}>
				<div className={'modal-close-fullscreen modal-close' + (this.props.preventClose || this.props.darken ? ' darken' : '')} onClick={this.closeSelf.bind(this)}/>
				<div style={modalStyle} className={className || 'modal react-modal'} ref={ref => this.modalRef = ref} onMouseDown={(e:React.MouseEvent)=>this.stopMouseEvent(e)}>
					<div className='modal-content react-modal-content' style={{top: 0}} ref={ref => this.contentRef = ref}>{content}</div>
					{ footer && <div className='modal-footer' ref={ref => this.footerRef = ref}>{footer}</div> }
				</div>
			</div> : null, this.portal) }
		</>
	}
}

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	registerScrollBlock: (blockerID:string) => {dispatch(RegisterScrollBlock(blockerID))},
	unregisterScrollBlock: (blockerID:string) => {dispatch(UnregisterScrollBlock(blockerID))},
});

export default connect(
	null, mapDispatchToProps
)(ScrollBlock(ModalContainer));