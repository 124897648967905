import { Button, styled } from '@mui/material';

export const DefaultButton = styled('button')`
    color: #000000;
    background-color: #ffffff;
    border: 1.5px solid #000000;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    min-height: 40px;

    &:focus {
        outline:none;
        background-color: none;
    }

    &:active{
        outline:none;
        background-color:none;
    }

    @media (max-width: 960px) {
        width: 100%;
    }
`

export const DefaultButtonBlack = styled(DefaultButton)`
    color: #ffffff;
    background-color: #000000;
    border: none;

    &:focus {
        background-color:#60B258;
    }
`

export const DefaultButtonGreen = styled(DefaultButton)`
    color: white;
    border: none;
    background-color: #60B258;
`
const Height45Weight500 = styled('button')`
	height: 45px;
	font-weight: 500;
	text-align: center;
`;
const Height35Weight500 = styled('button')`
	height: 35px;
	font-weight: 500;
	text-align: center;
`;
const WhiteBgColorBlackTextFullWidthButton = styled('button')`
	border: ${props => props.disabled ? "none" : "1.5px solid #333333"} ;
	box-sizing: border-box;
	border-radius: 20px;
	background-color: ${props => props.disabled ? "#AAAFB4" : "#ffffff"};
	text-align: center;
	color: ${props => props.disabled ? "#ffffff" : "#333333"} ;
	width: 100%;
	letter-spacing: 0.02em;
	transition: all 0.25s;
	&:hover{
		background-color: ${props => props.disabled ? "#AAAFB4" : "rgba(0, 0, 0, 0.1)"};
	}
	&:focus{
		background-color: ${props => props.disabled ? "#AAAFB4" : "#ffffff"};
	}
`;
const BlackBgColorWhiteTextFullWidthButton = styled('button')`
	border: none;
	background-color: ${props => props.disabled ? "#AAAFB4" : "#333333"};
	color: #FFFFFF;
	box-sizing: border-box;
	border-radius: 20px;
	text-align: center;
	width: 100%;
	letter-spacing: 0.02em;
	transition: all 0.25s;
	&:hover{
		background-color: ${props => props.disabled ? "#AAAFB4" : "#5d5d5d"};
	}
	&:focus{
		background-color: ${props => props.disabled ? "#AAAFB4" : "#333333"};
	}
`;
const ButtonWhiteHeight30 = styled(WhiteBgColorBlackTextFullWidthButton)`
	font-size: 14px;
	font-weight: 400;
	height: 30px;
`;
const ButtonWhiteHeight45 = styled(WhiteBgColorBlackTextFullWidthButton)`
	font-size: 14px;
	font-weight: 400;
	height: 45px;
`;
const ButtonWhiteHeight45Weight500Larger = styled('button')`
	border: 1.5px solid #333333;
	box-sizing: border-box;
	border-radius: 22.5px;
	background-color: #ffffff;
	text-align: center;
	color: #333333;
	width: 100%;
	letter-spacing: 0.02em;
	font-size: 16px;
	font-weight: 500;
	height: 45px;
	transition: all 0.3s;
	&:hover{
		background-color: rgba(0, 0, 0, 0.1);
	}
	&:focus{
		background-color: #ffffff;
	}
`;
export const ButtonBlackHeight45Weight500Larger = styled('button')`
	border: none;
	box-sizing: border-box;
	border-radius: 22.5px;
	background-color: #333333;
	text-align: center;
	color: #ffffff;
	width: 100%;
	letter-spacing: 0.02em;
	font-size: 16px;
	font-weight: 500;
	height: 45px;
`;
const ButtonBlackHeight45 = styled(BlackBgColorWhiteTextFullWidthButton)`
	font-size: 14px;
	font-weight: 400;
	height: 45px;
`;

const PreviousButton = styled(Button)`
	&:not(#spec #ov){
		display: flex;
		gap: 8px;
		font-weight: 500;
		font-size: 16px;
		color: #333333;
		border: none;
		background-color: transparent;
		padding: 0 .5rem;
		&:hover{
			background-color: rgba(0, 0, 0, 0.04);
		}
	}
`;

const ButtonWhiteHeight40widthChangeable = styled('button')`
	border: 1.5px solid #333333;
	box-sizing: border-box;
	border-radius: 20px;
	background-color: #ffffff;
	text-align: center;
	color: #333333;
	width: unset;
	height: 40px;
	min-width: 90px;
	@media(max-width:1280px){
		width: 100%;
	}
	letter-spacing: 0.02em;
`;
const ButtonBlackHeight40widthChangeable = styled('button')`
	border: none;
	background-color: #333333;
	color: #FFFFFF;
	border-radius: 20px;
	box-sizing: border-box;
	text-align: center;
	width: unset;
	height: 40px;
	min-width: 90px;
	@media(max-width:1280px){
		width: 100%;
	}
	letter-spacing: 0.02em;
	&:hover{
		background-color: #5d5d5d;
	}
	&:focus{
		background-color: #333333;
	}
`;

export const PinkHeight45FullWidthButton = styled(Height45Weight500)`
	width: 100%;
	color: #ffffff;
	font-size: 16px;
	border: none;
	border-radius: 22.5px;
	background: linear-gradient(0deg, #DA3192 0%, #F600BE 100%);
	opacity: 1;
	transition: opacity 150ms;

	&:hover:not([disabled]) {
		background: linear-gradient(0deg, #DA3192 0%, #F600BE 0%);
	}
	&:focus:not([disabled]) {
		background: linear-gradient(-180deg, #DA3192 0%, #F600BE 100%);
	}

	&[disabled] {
		opacity: 0.4;
	}
`;
export const PinkHeight35FullWidthButton = styled(Height35Weight500)`
	width: 100%;
	color: #ffffff;
	font-size: 16px;
	border: none;
	border-radius: 22.5px;
	background: linear-gradient(0deg, #DA3192 0%, #F600BE 100%);
	&:hover{
		background: linear-gradient(0deg, #DA3192 0%, #F600BE 0%);
	}
	&:focus{
		background: linear-gradient(-180deg, #DA3192 0%, #F600BE 100%);
	}
`;

export const TrashIcon = () => <>
	<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.80469 11.2695H7.41406C7.56641 11.2695 7.71875 11.1426 7.71875 10.9648V5.48047C7.71875 5.32812 7.56641 5.17578 7.41406 5.17578H6.80469C6.62695 5.17578 6.5 5.32812 6.5 5.48047V10.9648C6.5 11.1426 6.62695 11.2695 6.80469 11.2695ZM10.9688 2.73828H8.86133L7.99805 1.31641C7.79492 0.960938 7.38867 0.707031 6.95703 0.707031H4.39258C3.96094 0.707031 3.55469 0.960938 3.35156 1.31641L2.48828 2.73828H0.40625C0.177734 2.73828 0 2.94141 0 3.14453V3.55078C0 3.7793 0.177734 3.95703 0.40625 3.95703H0.8125V12.4883C0.8125 13.1738 1.3457 13.707 2.03125 13.707H9.34375C10.0039 13.707 10.5625 13.1738 10.5625 12.4883V3.95703H10.9688C11.1719 3.95703 11.375 3.7793 11.375 3.55078V3.14453C11.375 2.94141 11.1719 2.73828 10.9688 2.73828ZM4.3418 2.00195C4.36719 1.97656 4.41797 1.92578 4.46875 1.92578C4.46875 1.92578 4.46875 1.92578 4.49414 1.92578H6.88086C6.93164 1.92578 6.98242 1.97656 7.00781 2.00195L7.43945 2.73828H3.91016L4.3418 2.00195ZM9.34375 12.4883H2.03125V3.95703H9.34375V12.4883ZM3.96094 11.2695H4.57031C4.72266 11.2695 4.875 11.1426 4.875 10.9648V5.48047C4.875 5.32812 4.72266 5.17578 4.57031 5.17578H3.96094C3.7832 5.17578 3.65625 5.32812 3.65625 5.48047V10.9648C3.65625 11.1426 3.7832 11.2695 3.96094 11.2695Z" fill="#DA3192" />
	</svg>
</>

export const CloseModalButton = styled(Button)<{padding ?: string}>`
	&:not(#xb #close){
		padding: ${props => props.padding && props.padding};
		&:focus{
			background-color: transparent;
		}
	}
`;

export const ConfirmDefaultButton = styled(DefaultButton)`
	font-size: 14px;
	letter-spacing: 0.02em;
	min-width: 209.22px;

	@media (max-width:600px){
		width: 100%;
	}
`;

export const ConfirmDefaultButtonBlack = styled(DefaultButtonBlack)`
	font-size: 14px;
	letter-spacing: 0.02em;
	min-width: 209.22px;

	@media (max-width:600px){
		width: 100%;
	}
`;

export { ButtonBlackHeight40widthChangeable, ButtonBlackHeight45, ButtonWhiteHeight30, ButtonWhiteHeight40widthChangeable, ButtonWhiteHeight45, ButtonWhiteHeight45Weight500Larger, PreviousButton };
export const GreyButton = styled(Button)<{color ?: string, height?: string, borderRadius ?: string , fullWidth?:boolean}>`
	&:not(#xb #close){
		background-color: #A6A6A6;
		width: ${props => props.fullWidth && "100%"};
		color: ${props => props.color ? props.color : "#ffffff"};
		height: ${props => props.height ? props.height : "45px"};
		border-radius: ${props => props.height ? props.height : "100px"};
	}
`;