import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';
import SectionPricingOption from '../../_Sections/SectionPricingOption';
import DineroFactory from 'dinero.js';
import Immutable from 'immutable';

const PRODUCT_ID = 128;
const VIEW_PRODUCT_ID = 149;

const ShirtColorSection = styled('div')(({theme}) => ({
  backgroundColor: "#333",
  paddingBottom: '4rem',
  textAlign: 'center',
  'h2': {
    margin: '0 0 30px 0',
    paddingTop: 15,
    color: '#fff',
    fontSize: '50px',
    lineHeight: 1,
  },
  'h5': {
    fontSize: '26px',
  },
}))

export default function UnisexPoloShirtPage() {
  const [t] = useTranslation('page/products/unisex-joggers');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))
  const currency = useAppSelector(state => state.get('userData').get('prefs').get('currency'))

  const [priceDropship, priceRetail, priceWholesale] = useMemo(() => {
    if (!productData) {
      return [
        DineroFactory({ amount: 0, currency: currency }),
        DineroFactory({ amount: 0, currency: currency }),
        DineroFactory({ amount: 0, currency: currency }),
      ]
    }

    //Base product prices
    let tmpPriceDropship = productData.get('price_dropship').toDinero(),
      tmpPriceRetail = productData.get('price_retail').toDinero(),
      tmpPriceWholesale = productData.get('price_wholesale').toDinero();

    return [
      tmpPriceDropship,
      tmpPriceRetail,
      tmpPriceWholesale,
    ];
  }, [productData, currency]);

  const twoSidesOption = useMemo(() => {
    return productData?.get('availableOptionTypes').get('33')?.get('options').get('1524');
  }, [productData])

  const threeSidesOption = useMemo(() => {
    return productData?.get('availableOptionTypes').get('33')?.get('options').get('1527');
  }, [productData])

  if (!productData || !twoSidesOption || !threeSidesOption) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom digitally printed Unisex Joggers | Products')}
      description={t('Design and create your own custom printed Unisex Joggers. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#96cfe9"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '47%',
      }}
      tag={t('The digitally printed')}
      title={t('Unisex Joggers')}
      text={t('Experience ultimate comfort and unparalleled customization with printed unisex jogging pants, made from premium tri blend fleece. These jogging pants feature roomy pockets and shaped side seams to give a flattering modern fit. Unisex joggers are printed using the latest Direct-to-Film (DTF) print technology for an incredibly long lasting print.')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/unisex-joggers/banner-1-' + locale + '.png')}
      alt={t('Picture of Unisex Joggers')}
      details={[
        {
          img: require('@resources/img/pages/products/unisex-joggers/icon-1.svg?url'),
          alt: t('Cotton icon'),
          text: t('Made from 14oz 60% cotton/40% polyester premium fleece'),
        },
        {
          img: require('@resources/img/pages/products/unisex-joggers/icon-2.svg?url'),
          alt: t('Pocket icon'),
          text: t('Shaped side seam for a modern fit and roomy pockets'),
        },
        {
          img: require('@resources/img/pages/products/unisex-joggers/icon-3.svg?url'),
          alt: t('Pill-Resistant icon'),
          text: t('Pill resistant fabric looks fresh longer'),
        },
        {
          img: require('@resources/img/pages/products/unisex-joggers/icon-4.svg?url'),
          alt: t('Print icon'),
          text: t('Durable and vibrant direct-to-film (DTF) print won’t fade with use or wash'),
        },
        {
          img: require('@resources/img/pages/products/unisex-joggers/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/unisex-joggers/gallery_0.jpg'),
      require('@resources/img/pages/products/unisex-joggers/gallery_1.jpg'),
      require('@resources/img/pages/products/unisex-joggers/gallery_2.jpg'),
      require('@resources/img/pages/products/unisex-joggers/gallery_3.jpg'),
      require('@resources/img/pages/products/unisex-joggers/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Unisex <br/>Joggers <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Unisex Joggers! At prices designed for reselling, Unisex Joggers made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin.')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
      optionHeader={t('Printed part')}
      baseOptionName={t('One part printed')}
    >
      <SectionPricingOption
        basePriceDropship={priceDropship}
        basePriceWholesale={priceWholesale}
        basePriceRetail={priceRetail}
        options={Immutable.Set([twoSidesOption])}
        name={t('Two parts printed')}
      />
      <SectionPricingOption
        basePriceDropship={priceDropship}
        basePriceWholesale={priceWholesale}
        basePriceRetail={priceRetail}
        options={Immutable.Set([threeSidesOption])}
        name={t('Three parts printed')}
      />
    </SectionPricing>

    <SectionSizes
      chart="unisex-joggers"
      productId={PRODUCT_ID}
      title={t('Unisex Joggers Sizing')}
      text={t('Unisex joggers are a premium made jogging pant from the Just Like Hero brand.  Custom placement print can be printed on the front of the legs and/or the backside.')}
    />

    
    <ShirtColorSection id="colors" className="white-text section-colors">
      <div className="container">
        <h2>{t('Colors available')}</h2>
        <div className="row no-margin">
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/unisex-joggers/black.png')} alt={t('Black')}/>
            <h5>{t('Black')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/unisex-joggers/white.png')} alt={t('White')}/>
            <h5>{t('White')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/unisex-joggers/navy.png')} alt={t('Navy')}/>
            <h5>{t('Navy')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/unisex-joggers/sport-grey.png')} alt={t('Sport Grey')}/>
            <h5>{t('Athletic Grey')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/unisex-joggers/maroon.png')} alt={t('Maroon')}/>
            <h5>{t('Sand')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/unisex-joggers/lavender.png')} alt={t('Lavender')}/>
            <h5>{t('Athletic Grey')}</h5>
          </div>
        </div>
      </div>
    </ShirtColorSection>

    <SectionBranding
      title={t('Brand your Unisex Joggers')}
      text={t('Branding enhances your products. Add artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Unisex Joggers with these great products to create your own unique styles.')}
      productSlugs={['premium-zipper-hoodie', 'premium-pullover-hoodie', 'premium-t-shirt']}
    />
  </div>
}