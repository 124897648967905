import { ElementRef, Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import LabLoader from "./LabLoader";
import ModelViewer from "../../ModelViewer/ModelViewer";
import useLabData from "../_hooks/useLabData";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import Renderer from "../Renderer/Renderer";

export default function LabModelViewer() {
  const rendererRef = useRef<ElementRef<typeof Renderer>>(null)
  const labData = useLabData()
  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'))
  const activeVariant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'))
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)))
  const activeOptions = useAppSelector(state => state.get('UIData').get('designLab').get('activeOptions'))

  const { subproductRef, subproductRef_labData } = useMemo(() => {
    let tmpSubproductRef = productData?.get('labData')?.has_subproducts ? Object.keys(productData?.get('labData')?.subproducts)[0] : undefined
    let tmpData = undefined

    if (tmpSubproductRef) {
      tmpData = productData?.get('labData')?.subproducts[tmpSubproductRef].originalData
    }

    return {
      subproductRef: tmpSubproductRef,
      subproductRef_labData: tmpData,
    }
  }, [productData]);

  const { textureCanvas, textureContext } = useMemo(() => {
    const textureCanvas = new OffscreenCanvas(1, 1);
    const textureContext = textureCanvas.getContext('2d');

    return {
      textureCanvas,
      textureContext,
    }
  }, [])

  const renderTexture = useCallback(() => {
    if (rendererRef.current === null) return

    const stage = rendererRef.current.getStage()
    if(stage === null || textureContext === null) return

    textureCanvas.width = stage.width()
    textureCanvas.height = stage.height()

    for(let layer of stage.children) {
      if (!layer.isVisible()) {
        return;
      }

      textureContext.drawImage(layer.canvas._canvas, 0, 0, layer.width(), layer.height());
    }
    return textureCanvas;
  }, [])

  if (!labData || activeProductId === null) return null

  return <>
    <Suspense
      fallback={<LabLoader />}
    >
      <ModelViewer
        renderTexture={renderTexture}
        viewerId="design-lab"
        productId={activeProductId}
        variant={activeVariant}
        labData={labData}
        options={activeOptions}
      />
    </Suspense>
    <Renderer
      ref={rendererRef}
      labData={labData}
      subproductRef={subproductRef}
      subproductRef_labData={subproductRef_labData}
      inputType="lab"
      outputType="texture"
    />
  </>
}