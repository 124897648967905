import { Skeleton, SkeletonProps } from '@mui/material';

export type SkeletonLoaderProps = SkeletonProps & {
    loading?: boolean
};

export default function SkeletonLoader(props:SkeletonLoaderProps) {
    const { children, loading, ...otherProps } = props;
    return loading ? <Skeleton {...otherProps}>
        <span>{ children }</span>
    </Skeleton> : children;
}