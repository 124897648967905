import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../Common/_hooks/useAppSelector'
import { useParams, Link, useSearchParams, Outlet, useMatch } from 'react-router-dom'
import { Alert, Button, Chip, Container, Divider, Grid, Paper, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import CartListItems from '../Cart/CartItem/CartListItems'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from '../Common/_hooks/useAppDispatch'
import { FetchOneItem, FetchPaginationItems } from '../UserData/_actions/UserDataActions'
import { CartStack } from '../Cart/CartContent/CartStack'
import useOrderStatusProps from '../Common/_hooks/Order/useOrderStatusProps'
import { DateTime } from 'luxon'
import { FileDownloadOutlined as FileDownloadOutlinedIcon, OpenInNewRounded as OpenInNewRoundedIcon } from '@mui/icons-material'
import CartListStack from '../Cart/CartContent/CartListStack'
import CancelOrderDialog from '../Account/Orders/CancelOrderDialog'
import { FetchOneInvoice, PutOnHoldOrder, ReleaseHoldOrder, UnignoreOrder } from '../UserData/_actions/OrderActions'
import IgnoreOrderDialog from '../Account/Orders/IgnoreOrderDialog'
import UserCartStore from '../UserData/_stores/UserCartStore'
import Immutable from 'immutable'
import OrderPageContent from '../Account/Orders/OrderPageContent'
import CheckoutOverview from '../Checkout/Overview/CheckoutOverview'
import CheckoutPayment from '../Checkout/Payment/CheckoutPayment'
import AccountHeader from '../Account/AccountHeader'

export default function InvoicePage() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')
  const [tR] = useTranslation('routes')
  const params = useParams<{ invoiceId: string }>()
  const [searchParams] = useSearchParams()
  const paymentPageMatch = useMatch(tR('/invoice/:invoiceId/payment'))

  const order = useAppSelector(state => params.invoiceId ? state.get('userData').get('orders').get('data').find(value => value.get('invoice_id') === params.invoiceId) : undefined)

  useEffect(() => {
    if (!params.invoiceId) return
    dispatch(FetchOneInvoice(params.invoiceId))
  }, [params.invoiceId])

  const {
    text: statusText,
    color: statusColor
  } = useOrderStatusProps(order?.get('status') ?? 'paid')

  const orders = useMemo(() => {
    return order ? Immutable.OrderedMap({[order.get('id')]: order}) : Immutable.OrderedMap<string, UserCartStore>()
  }, [order])

  const isPaid = useMemo(() => {
    return ['paid','in-production','ready','shipped'].includes(order?.get('status') ?? '')
  }, [order])

  const [title, breadcrumbs] = useMemo(() => {
    if(!order) return ['', []]

    const orderNumber = t('Invoice #{{number}}', { number: order.get('ref_id').toUpperCase() });
    let tmpTitle = orderNumber;
    const tmpBreadcrumbs:{
      title: string,
      url: string
    }[] = []

    if(order.get('status') === 'ordered') {
      if(paymentPageMatch !== null) {
        tmpBreadcrumbs.push({
          title: orderNumber,
          url: tR('/invoice/{{invoice_id}}', {invoice_id: order.get('invoice_id')})
        })
      }

      if(paymentPageMatch !== null) {
        tmpTitle = t('Payment')
      }
    }

    return [
      tmpTitle,
      tmpBreadcrumbs
    ]
  }, [order, params, t, tR])

  const isConfirmation = useMemo(() => {
    return searchParams.has('payment_id')
  }, [searchParams])

  if (!order) return null

  return <Container sx={{
    p: {
      xs: 2,
      md: 3,
      lg: 4
    }
  }}>
    <AccountHeader
      title={title}
      breadcrumbs={breadcrumbs}
      titleActions={<Chip
        label={statusText}
        color={statusColor}
        variant="square-filled"
        size="small"
      />}
      actions={isPaid ? <Button
        variant="dashed"
        color="secondary"
        startIcon={<FileDownloadOutlinedIcon/>}
        component={Link}
        to={tR('/invoice/download-receipt') + '?invoice_id=' + order.get('invoice_id')}
        target="_blank"
      >{t('Invoice (PDF)')}</Button> : null}
    />

    { order.get('order_from') === 'invoice' ? <Typography
      variant="body1"
      sx={{color: '#515151', mt: -2, mb: 2}}
    ><strong style={{fontWeight: 600}}>{DateTime.fromISO(order.get('date_created')).toLocaleString(DateTime.DATETIME_FULL)}</strong></Typography> : null }

    <CartListStack>
      { isConfirmation ? <Alert
        severity="success"
        variant="outlined"
      >{ t('Thank you for your payment.') }</Alert> : null}

      { order.get('status') === 'ordered' ? <div>
        <Outlet/>
      </div> : <OrderPageContent
        order={order}
      /> }
    </CartListStack>
  </Container>
}
