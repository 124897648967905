import { Button, type ButtonProps, IconButton, InputAdornment, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Stack, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AddRounded as AddRoundedIcon, ExpandMoreRounded as ExpandMoreRoundedIcon, CloseRounded as CloseRoundedIcon, CheckRounded as CheckRoundedIcon } from '@mui/icons-material'
import { type ChangeEvent, type FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../Common/_hooks/useAppSelector'
import type UserCartStore from '../UserData/_stores/UserCartStore'
import { useAppDispatch } from '../Common/_hooks/useAppDispatch'
import { selectCarts } from '../UserData/_selectors/OrderSelectors'
import { FetchPaginationItems } from '../UserData/_actions/UserDataActions'

type Props = ButtonProps & {
  onAdd: (id_cart?: number, newCartName?: string) => void
}

export default function AddToCartButton(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const loggedIn = useAppSelector(state => state.get('userData').get('loggedIn'))
  const carts = useAppSelector(selectCarts)
  const valid = useAppSelector(state => state.get('userData').get('orders').get('itemLists').get('cart')?.get('valid') ?? false)
  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))

  const [menuOpened, setMenuOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [newCartOpened, setNewCartOpened] = useState(false)
  const [cartName, setCartName] = useState('')

  useEffect(() => {
    const fd = new FormData()
    fd.append('statuses', 'cart')
    fd.append('limit', '9999')
    fd.append('fields', 'items')

    dispatch(FetchPaginationItems('cart', fd, 'orders'))
  }, [valid])

  useEffect(() => {
    // Update default cart name to next number if the new cart prompt isn't opened
    if (!newCartOpened) {
      setCartName(t('Cart #{{key}}', { key: carts.count() + 1 }))
    }
  }, [carts, t])

  const multipleCartsEnabled = useMemo(() => {
    return loggedIn && carts.count() > 0
  }, [loggedIn, carts])

  const onMenuClose = useCallback(() => {
    setMenuOpened(false)
    setNewCartOpened(false)
  }, [])

  const onClick = useCallback((e: React.MouseEvent) => {
    const firstCart = carts.first()

    // Auto-create a cart
    if (!firstCart) {
      props.onAdd(undefined, loggedIn ? t('Cart #{{key}}', { key: 1 }) : t('My cart'))
      return
    }

    // Open cart menu if we already have a cart and we're logged in
    if (loggedIn) {
      setAnchorEl(e.currentTarget)
      setMenuOpened(true)
    } else {
      // Simply add to regular cart when logged out
      props.onAdd(firstCart.get('id'))
    }
  }, [carts, loggedIn, props.onAdd, t])

  const onClickNewCart = useCallback(() => {
    setNewCartOpened(true)
  }, [])

  const onCloseNewCart = useCallback(() => {
    setNewCartOpened(false)
  }, [])

  const onChangeCartName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCartName(e.target.value)
  }, [])

  const onSubmitNewCart = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setNewCartOpened(false)

    props.onAdd(undefined, cartName)

    setMenuOpened(false)
  }, [cartName, props.onAdd])

  const renderCartOption = useCallback((cart: UserCartStore, key: number) => {
    return <MenuItem
      key={cart.get('id')}
      onClick={() => {
        props.onAdd(cart.get('id'))
        setMenuOpened(false)
      }}
    >
      <ListItemText>{cart.get('cart_name') ?? t('Cart #{{key}}', { key })}</ListItemText>
    </MenuItem>
  }, [t, props.onAdd])

  const { onAdd, ...otherProps } = props

  return <>
    <Button
      color="accent"
      variant="contained"
      size="large"
      startIcon={<AddRoundedIcon />}
      endIcon={multipleCartsEnabled ? <ExpandMoreRoundedIcon /> : undefined}
      onClick={onClick}
      disabled={cartLoader}
      {...otherProps}
    >{t('Add to Cart')}</Button>

    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      keepMounted
      open={menuOpened}
      onClose={onMenuClose}
    >
      { carts.valueSeq().map(renderCartOption) }

      { !newCartOpened ? <MenuItem onClick={onClickNewCart} sx={{ color: 'accent.main' }}>
        <ListItemIcon sx={{ color: 'accent.main' }}><AddRoundedIcon /></ListItemIcon>
        <ListItemText>{t('New cart')}</ListItemText>
      </MenuItem> : null }

      { newCartOpened ? <ListItem>
        <form onSubmit={onSubmitNewCart}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              autoFocus
              variant="filled"
              error={cartName === ''}
              value={cartName}
              onChange={onChangeCartName}
              label={t('Name your cart')}
              InputProps={{
                endAdornment: <InputAdornment
                  position="end"
                >
                  <IconButton
                    onClick={onCloseNewCart}
                    edge="end"
                  ><CloseRoundedIcon /></IconButton>
                </InputAdornment>
              }}
            />
            <IconButton
              color="accent"
              size="large"
              type="submit"
              disabled={cartName === ''}
            ><CheckRoundedIcon /></IconButton>
          </Stack>
        </form>
      </ListItem> : null }
    </Menu>
  </>
}
