import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Link, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Dialog from '../Common/Dialog/Dialog';
import DialogContent from '../Common/Dialog/DialogContent';
import DialogTitle from '../Common/Dialog/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { CloseAuthModal, OpenAuthModal } from './_actions/AuthActions';
import WebsiteStore from '../WebsiteStore';
import ResetPasswordDialog from './ForgotPasswordDialog';
import { useAppDispatch } from '../Common/_hooks/useAppDispatch';
import { useLocation } from 'react-router-dom';
import AuthForm from './AuthForm';

export type Props = {
}

export default function AuthDialog(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('auth')
  const location = useLocation()

  const opened = useSelector((state: WebsiteStore) => state.get('Auth').get('modalOpened'));
  const signup = useSelector((state: WebsiteStore) => state.get('Auth').get('isSignup'));

  const onClose = useCallback(() => {
    dispatch(CloseAuthModal())
  }, [])

  return <>
    <Dialog
      open={opened}
      showCloseButton
      maxWidth="md"
      fullWidth
      onClose={onClose}
      ornaments="shapes-3"
    >
      <DialogTitle
        sx={{
          textAlign: {
            xs: 'left',
            sm: 'center',
          },
          fontSize: '3.25rem'
        }}
      >{signup ? t('Create an account') : t('Login')}</DialogTitle>

      <DialogContent>
        <AuthForm
          signup={signup}
          redirect={location.pathname + location.search}
        />
      </DialogContent>
    </Dialog>
  </>;
}