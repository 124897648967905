export default function parseColor(color:string) {
    let cache:RegExpExecArray, listColors:number[];
    color = color.replace(/\s/g,''); // Remove all spaces
    
    // Checks for 6 digit hex and converts string to integer
    if (cache = /#?([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/.exec(color)) 
        listColors = [parseInt(cache[1], 16), parseInt(cache[2], 16), parseInt(cache[3], 16)];
        
    // Checks for 3 digit hex and converts string to integer
    else if (cache = /#?([\da-fA-F])([\da-fA-F])([\da-fA-F])/.exec(color))
        listColors = [parseInt(cache[1], 16) * 17, parseInt(cache[2], 16) * 17, parseInt(cache[3], 16) * 17];
        
    // Checks for rgba and converts string to
    // integer/float using unary + operator to save bytes
    else if (cache = /rgba\(([\d]+),([\d]+),([\d]+),([\d]+|[\d]*.[\d]+)\)/.exec(color))
        listColors = [+cache[1], +cache[2], +cache[3], +cache[4]];
        
    // Checks for rgb and converts string to
    // integer/float using unary + operator to save bytes
    else if (cache = /rgb\(([\d]+),([\d]+),([\d]+)\)/.exec(color))
        listColors = [+cache[1], +cache[2], +cache[3]];
        
    // Otherwise throw an exception
    else throw 'invalid color';
    
    // Performs RGBA conversion by default
    isNaN(listColors[3]) && (listColors[3] = 1);
    
    return listColors;
}