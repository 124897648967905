import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../_Sections/SectionDetails';
import SectionGallery from '../_Sections/SectionGallery';
import SectionDropship from '../_Sections/SectionDropship';
import SectionPairings from '../_Sections/SectionPairings';
import SectionBranding from '../_Sections/SectionBranding';
import SectionSizes from '../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../_Sections/SectionPricing';
import tKey from '../../Common/Utils/tKey';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import ProductSEO from '../_Sections/ProductSEO';
import { BlackCheckedIcon } from '../../Common/_components/CheckBoxes';

const PRODUCT_ID = 124;
const VIEW_PRODUCT_ID = 145;

export default function DrawstringBagPage() {
  const [t] = useTranslation('page/products/drawstring-bags');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Drawstring Bags | Products')}
      description={t('Design and create your own custom printed Drawstring Bags featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#E4D5F8"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '48%',
      }}
      tag={t('The all-over print')}
      title={t('Drawstring Bag')}
      text={t('The effortless print on-demand drawstring bag made from durable polycanvas is your go-to on-the-go! Featuring an all-over print front and back, each handmade drawstring bag is fully lined with adjustable rope straps. Coveted for their versatility, custom printed drawstring bags will make your day easier and more beautiful!')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/drawstring-bags/banner-1-' + locale + '.png')}
      alt={t('Picture of Drawstring Bag')}
      details={[
        {
          img: require('@resources/img/pages/products/drawstring-bags/icon-1.svg?url'),
          alt: t('Polyester Fabric icon'),
          text: t('Made from durable polycanvas fabric'),
        },
        {
          img: require('@resources/img/pages/products/drawstring-bags/icon-2.svg?url'),
          alt: t('Drawstring bag icon'),
          text: t('All-over print on the drawstring bag front and back so you can wear it either way'),
        },
        {
          img: require('@resources/img/pages/products/drawstring-bags/icon-3.svg?url'),
          alt: t('Metal hardware icon'),
          text: t('Key ring clip inside to keep essentials handy'),
        },
        {
          img: require('@resources/img/pages/products/drawstring-bags/icon-4.svg?url'),
          alt: t('Strap icon'),
          text: t('Adjustable rope straps up to 31" long. Tie them to the length that\'s right for you'),
        },
        {
          img: require('@resources/img/pages/products/drawstring-bags/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/drawstring-bags/gallery_0.jpg'),
      require('@resources/img/pages/products/drawstring-bags/gallery_1.jpg'),
      require('@resources/img/pages/products/drawstring-bags/gallery_2.jpg'),
      require('@resources/img/pages/products/drawstring-bags/gallery_3.jpg'),
      require('@resources/img/pages/products/drawstring-bags/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Drawstring <br/>Bag <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Drawstring Bag! At prices designed for reselling, Drawstring Bag made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
    />

    <SectionSizes
      productId={PRODUCT_ID}
      title={t('Drawstring Bag Sizing')}
      text={t('All measurements are approximate. Since our products are made by hand, there may be variations in the finished size of our products between orders or even within an order.')}
    >
      <img
        src={require('@resources/img/pages/products/drawstring-bags/measurement.svg?url')}
        style={{
          marginRight: 0,
        }}
      />
    </SectionSizes>

    <SectionBranding
      title={t('Brand your Drawstring Bag')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Drawstring Bag with these great products to create your own unique styles.')}
      productSlugs={['pencil-cases', 'carry-alls', 'makeup-bags']}
    />
  </div>
}
