import { Chip, ListItemButton, Stack, Typography } from '@mui/material'
import { CartStack } from '../../Cart/CartContent/CartStack'
import { DateTime } from 'luxon'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import useOrderStatusProps from '../../Common/_hooks/Order/useOrderStatusProps'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

interface Props {
  id: number
}

export default function ReprintRow(props: Props) {
  const [tR] = useTranslation('routes')
  const reprint = useAppSelector(state => state.get('userData').get('orders').get('data').get(String(props.id)))

  const {
    text: statusText,
    color: statusColor
  } = useOrderStatusProps(reprint?.get('status') ?? 'paid')

  const reprintDate = useMemo(() => {
    if (!reprint) return null

    return DateTime.fromISO(reprint.get('date_paid'))
  }, [reprint])

  if (!reprint) return null

  return <ListItemButton
    component={Link}
    to={tR('/account/orders') + '/' + reprint.get('ref_id')}
    sx={{
      width: '100%',
      padding: 0,
      textAlign: 'left',
      display: 'block'
    }}
  >
    <CartStack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
      >
        <Typography variant="h4">#{reprint.get('ref_id').toUpperCase()}</Typography>
        <Chip
          label={statusText}
          color={statusColor}
        />
      </Stack>
      <Typography variant="h4">{reprintDate?.toLocaleString(DateTime.DATETIME_MED)}</Typography>
    </CartStack>
  </ListItemButton>
}
