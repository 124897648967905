import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';

const PRODUCT_ID = 126;
const VIEW_PRODUCT_ID = 147;

const ShirtColorSection = styled('div')(({theme}) => ({
  backgroundColor: "#333",
  paddingBottom: '4rem',
  textAlign: 'center',
  'h2': {
    margin: '0 0 30px 0',
    paddingTop: 15,
    color: '#fff',
    fontSize: '50px',
    lineHeight: 1,
  },
  'h5': {
    fontSize: '26px',
  },
}))

export default function UnisexPoloShirtPage() {
  const [t] = useTranslation('page/products/unisex-polo-shirts');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom digitally printed Unisex Polo Shirts | Products')}
      description={t('Design and create your own custom printed Unisex Polo Shirt. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#96cfe9"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '47%',
      }}
      tag={t('The digitally printed')}
      title={t('Unisex Polo Shirt')}
      text={t('Premium unisex ringspun cotton polo shirts feature vibrant, durable Direct-to-Film (DTF) prints that bring your custom designs to life with exceptional detail. With print on-demand production, you can create custom unisex polos printed with any artwork and designs. Perfect for both casual and professional settings, unisex polos balance style and functionality.')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/unisex-polo-shirts/banner-1-' + locale + '.png')}
      alt={t('Picture of Unisex Polo Shirt')}
      details={[
        {
          img: require('@resources/img/pages/products/unisex-polo-shirts/icon-1.svg?url'),
          alt: t('Cotton icon'),
          text: t('Made from 11.3 oz 100% preshrunk pique ringspun cotton'),
        },
        {
          img: require('@resources/img/pages/products/unisex-polo-shirts/icon-2.svg?url'),
          alt: t('Shoulder icon'),
          text: t('Taped shoulder seam'),
        },
        {
          img: require('@resources/img/pages/products/unisex-polo-shirts/icon-3.svg?url'),
          alt: t('Button icon'),
          text: t('Button placket'),
        },
        {
          img: require('@resources/img/pages/products/unisex-polo-shirts/icon-4.svg?url'),
          alt: t('Print icon'),
          text: t('Durable and vibrant direct-to-film (DTF) print won’t fade with use or wash'),
        },
        {
          img: require('@resources/img/pages/products/unisex-polo-shirts/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/unisex-polo-shirts/gallery_0.jpg'),
      require('@resources/img/pages/products/unisex-polo-shirts/gallery_1.jpg'),
      require('@resources/img/pages/products/unisex-polo-shirts/gallery_2.jpg'),
      require('@resources/img/pages/products/unisex-polo-shirts/gallery_3.jpg'),
      require('@resources/img/pages/products/unisex-polo-shirts/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Unisex <br/>Polo Shirts <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Unisex Polo Shirts! At prices designed for reselling, Unisex Polo Shirts made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin.')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
    />

    <SectionSizes
      chart="polos-men"
      productId={PRODUCT_ID}
      title={t('Unisex Polo Shirt Sizing')}
      text={t('Unisex polo shirts are a premium premade shirt from the M&O brand. Custom placement print can be printed on the shirt front or back.')}
    />

    
    <ShirtColorSection id="colors" className="white-text section-colors">
      <div className="container">
        <h2>{t('Colors available')}</h2>
        <div className="row no-margin">
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/polo-shirts/black.png')} alt={t('Black')}/>
            <h5>{t('Black')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/polo-shirts/white.png')} alt={t('White')}/>
            <h5>{t('White')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/polo-shirts/navy.png')} alt={t('Navy')}/>
            <h5>{t('Navy')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/polo-shirts/sand.png')} alt={t('Sand')}/>
            <h5>{t('Sand')}</h5>
          </div>
          <div className="color-item">
            <img src={require('@resources/img/pages/products/_sections/colors/polo-shirts/athletic-grey.png')} alt={t('Athletic Grey')}/>
            <h5>{t('Athletic Grey')}</h5>
          </div>
        </div>
      </div>
    </ShirtColorSection>

    <SectionBranding
      title={t('Brand your Unisex Polo Shirt')}
      text={t('Branding enhances your products. Add artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Unisex Polo Shirt with these great products to create your own unique styles.')}
      productSlugs={['lounge-pants', 'glitter-fitted-skirt', 'glitter-flare-skirt']}
    />
  </div>
}