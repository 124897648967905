import { Button, type ButtonProps } from '@mui/material'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import type Immutable from 'immutable'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { CartCheckoutStripeWithCard } from '../../UserData/_actions/UserCartActions'
import { useNavigate } from 'react-router-dom'
import { EditLoader } from '../../UIData/_actions/UIDataActions'

type Props = ButtonProps & {
  orders: Immutable.Map<string, UserCartStore>
  confirmationUrl: string
  selectedCreditCard: number
  savePaymentMethod: boolean
  stripeUpdate?: number
  onErrorText: (error: string) => void
  onDisable: (disable: boolean) => void
}

export default function StripePaymentButton(props: Props) {
  const { orders, confirmationUrl, selectedCreditCard, savePaymentMethod, stripeUpdate, onErrorText, onDisable, ...otherProps } = props

  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()

  // Trigger PaymentIntent update on stripeUpdate
  useEffect(() => {
    dispatch(EditLoader('payment', true))

    elements?.fetchUpdates().then(response => {
      dispatch(EditLoader('payment', false))

      if (response.error?.message) {
        props.onErrorText(response.error?.message)
      }
    }).catch(reason => {
      dispatch(EditLoader('payment', false))
      console.log(reason)
    })
  }, [props.stripeUpdate, elements, props.onErrorText])

  const payWithIntent = useCallback(() => {
    if (!stripe || !elements) {
      console.log('Stripe has not loaded yet', stripe, elements)
      return
    }

    props.onDisable(true)
    props.onErrorText('')

    stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.SITE_URL + 'checkout/creditcard-v2/payment-intent-callback?saveCard=' + (props.savePaymentMethod ? '1' : '0') + '&redirect_confirm=' + props.confirmationUrl
      },
      redirect: 'always'
    }).then((result) => {
      props.onDisable(false)

      if (['card_error', 'validation_error'].includes(result.error.type) && result.error.message) {
        props.onErrorText(result.error.message)
      } else {
        props.onErrorText(t('An error occured processing your credit card'))
      }
    }).catch(() => {
      // This should never happen according to Stripe, but we still handle it
      props.onDisable(false)
      props.onErrorText(t('An error occured processing your credit card'))
    })
  }, [props.savePaymentMethod, props.confirmationUrl, stripe, elements, t, props.onDisable, props.onErrorText])

  const payWithCard = useCallback(() => {
    props.onDisable(true)
    props.onErrorText('')

    // Provide id order if we're paying an 'ordered' order
    let idOrder: number | undefined
    if (props.orders.count() === 1 && props.orders.first()?.get('status') === 'ordered') {
      idOrder = props.orders.first()?.get('id')
    }

    dispatch(CartCheckoutStripeWithCard(props.selectedCreditCard, idOrder).set({
      onSuccess: (response) => {
        navigate(props.confirmationUrl + '?payment_id=' + encodeURIComponent(response.data.payment_id))
      },
      onFailure: (response) => {
        props.onDisable(false)

        if (response.data?.error) {
          props.onErrorText(response.data.error)
        } else {
          props.onErrorText(t('An error occured processing your credit card. Please try again later.'))
        }
      }
    }))
  }, [props.orders, props.selectedCreditCard, props.savePaymentMethod, props.confirmationUrl, props.onErrorText, props.onDisable, t])

  return <Button
    onClick={props.selectedCreditCard > 0 ? payWithCard : payWithIntent}
    {...otherProps}
  />
}
