import * as Immutable from 'immutable';
import { UserTaxNumberPayload, UserTaxNumberStore } from '../_stores/UserTaxNumberStore';
import { UserTaxNumberAction, UserTaxNumberActionType } from '../_actions/UserTaxNumberActions';

const editTaxNumber = (store: Immutable.OrderedMap<number, UserTaxNumberStore>, action:UserTaxNumberPayload) => {
	return store.withMutations(store => {
		const oldData = store.get(action.id);
		let newData = new UserTaxNumberStore(action);

		if(oldData) {
			newData = oldData.mergeDeep(newData);
		}

		store.set(action.id, newData);
	})
}

const UserTaxNumbersReducer = (
	store:Immutable.OrderedMap<number, UserTaxNumberStore> = Immutable.OrderedMap<number, UserTaxNumberStore>(),
	action: UserTaxNumberAction
):Immutable.OrderedMap<number, UserTaxNumberStore> => {
	switch (action.type) {
		case UserTaxNumberActionType.EditUserTaxNumber:
			store = editTaxNumber(store, action.taxNumber);
			break;

		case UserTaxNumberActionType.EditManyUserTaxNumbers:
			action.taxNumbers.forEach(taxNumber => {
				store = editTaxNumber(store, taxNumber);
			})
			break;

		case UserTaxNumberActionType.RemoveUserTaxNumber:
			store = store.remove(action.id);
			break;
	}
	return store;
}

export default UserTaxNumbersReducer