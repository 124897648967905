import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import Success, { Props } from '../_components/Success';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({

});

export default connect(
	mapStateToProps,
	null
)(Success);