import * as Immutable from 'immutable';

export interface IAuthStore {
	modalOpened: boolean,
	isSignup: boolean,
	signup_email: string,
  signup_service: string,
}

const defaultStore = Immutable.Record<IAuthStore>({
	modalOpened: false,
	isSignup: false,
	signup_email: '',
  signup_service: '',
});

export default class AuthStore extends defaultStore implements IAuthStore {}