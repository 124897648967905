import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useExitLab() {
    const [tR] = useTranslation('routes');

    return useCallback(() => {
        //Only go back if coming from AOW, and make sure we don't stay in the lab
        let backUrl = document.referrer;
        if(backUrl.indexOf(window.SITE_URL) === -1 || backUrl.indexOf(tR('/create')) !== -1) {
            backUrl = window.SITE_URL;
        }

        window.location.href = backUrl;
    }, [tR]);
}