import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AjaxCallback } from '../../Common/_actions/AjaxAction';
import { EditProductListing, SaveStorePreset } from '../../Dashboard/_actions/DashboardActions';
import VariantPriceStore from '../../Dashboard/_stores/VariantPriceStore';
import EditProduct, { Props } from '../_components/EditProduct';
import ListingStore from '../_stores/ListingStore';
import PriceStore from '../../UIData/_stores/PriceStore';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => {
	let listing:ListingStore|undefined = props.listing ? props.listing : (store.getIn(['Dashboard','addProduct_listings',store.getIn(['Dashboard','addProduct_selectedListing'])]) as ListingStore);

	return {
		listing: listing,
		product: props.product ? props.product : (listing ? store.getIn(['appData','products', String(listing.get('id_product'))]) : undefined),
		productDesign: props.productDesign ? props.productDesign : (listing ? store.getIn(['userData', 'productDesigns', 'data', listing.get('id')]) : undefined),
		listings: store.getIn(['Dashboard','addProduct_listings']),
		loader: store.getIn(['UIData','loaders','dashboard_addProducts']),
	}
};

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	editProductListing: (
		id_product_design: number,
		id_product: number,
		title: string,
		description: string,
		price: PriceStore,
		update_preset: boolean,
		disabled_preview_ids?: Array<number>,
		disabled_option_ids?: Array<number>,
		variant_prices?: Immutable.Map<string, VariantPriceStore>,
		squarespace_store_page_id?: number,
	) => dispatch(EditProductListing(
		id_product_design,
		id_product,
		title,
		description,
		price,
		update_preset,
		true,
		disabled_preview_ids,
		disabled_option_ids,
		variant_prices,
		squarespace_store_page_id,
	)),
	saveStorePreset:(store:string, jsonData:string, successCallback: AjaxCallback) => dispatch(SaveStorePreset(store, jsonData, successCallback)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(EditProduct);