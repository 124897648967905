import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Grid, styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Drawer from '@mui/material/Drawer';
import * as Immutable from 'immutable';
import * as React from 'react';
import { Trans } from 'react-i18next';
import ProductCategoryStore from '../../AppData/_stores/ProductCategoryStore';
import ProductStore from '../../AppData/_stores/ProductStore';
import Hidden from '../../Common/_components/Hidden';
import { AccordionHeader, AccordionSubHeader, Paragraph400size14grey } from '../../Common/_components/Typography';
import ModalContainer from '../../Common/_containers/ModalContainer';
import PromptContainer from '../../Common/_containers/PromptContainer';
import UserStorePresetStore from '../../UserData/_stores/UserStorePresetStore';
import EditProductContainer from '../_containers/EditProductContainer';
import PublishingContainer from '../_containers/PublishingContainer';
import SuccessContainer from '../_containers/SuccessContainer';

export type Props = {
	productCategories?: Immutable.OrderedMap<string, ProductCategoryStore>
	products?: Immutable.OrderedMap<string, ProductStore>
	storePresets?: Immutable.OrderedMap<number, UserStorePresetStore>
	fetchStorePresets: Function
}

export type State = {
	dialogOpened: boolean
	currentStep: number
	unlistenCallback: Function
	searchQuery: string
	expanded?: number
	closeModalPrompt: boolean
	selectedProduct: number
	store: string
}

const MassModalContainer = styled(ModalContainer)({
	bottom: '0',
	margin: '0 auto',
	transformOrigin: '50% 50%',
    animationName: 'grow',
	maxHeight: 'calc(93% - 45px)',
	top: '5% !important',
	width: '960px',
	borderRadius: '30px',

	'@media (max-width: 1200px)': {
		width: '774.5px',
	},

	'.modal-content': {
		position: 'static',
	    margin: '0',
	    alignSelf: 'flex-start',
		padding: '0',
		width: '100%',
		height: '100%',
	},
	
	'&.height-fit-content': {
		//height: 'fit-content',
        bottom: 'unset',
        maxHeight: 'unset',

		'.modal-content': {
			position: 'initial',
            maxHeight: 'calc(93% - 45px)',
            height: 'fit-content',
		} 
	}
});
const CustomizedAccordion = styled(Accordion)({
	'&.MuiPaper-elevation1': {
		boxShadow: 'none',
		margin: 0,
	},

	'.MuiAccordionSummary-root': {
		padding: 'unset',
	},

	'&.MuiAccordion-root.Mui-expanded:before': {
		opacity: 1,
	}
});
const CustomizedAccordionSummary = styled(AccordionSummary)({
	'&.MuiAccordionSummary-root.Mui-expanded': {
		minHeight: '48px',
	},

	'.MuiAccordionSummary-content.Mui-expanded': {
		margin: '12px 0',
	}
});
const ParentCircle = styled('div')({
	width: '144px',
	height: '144px',
	margin: 'auto',
	padding: '10px',
	borderRadius: '50%',
	boxSizing: 'border-box',
	transition: 'background-color 150ms',
	cursor: 'pointer',

	'&:hover': {
		backgroundColor: '#FCEFF7',
	},
	
	'&:hover .eButton': {
		opacity: '1',
	},

	'&:focus': {
		border: '1px solid #DA3192',
	},

	'&.not-configured img': {
		opacity: '0.5',
	},

	'&.not-configured:hover img': {
		opacity: '1',
	}
});
const ChildCircle = styled('div')({
	borderRadius: '50%',
	backgroundColor: '#FFFFFF',
	border: '1px solid #DA3192',
	textAlign: 'center',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'relative',

	'.eButton': {
		display: 'flex',
		opacity: '0',
		transition: 'opacity 150ms',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '5px',
		position: 'absolute',
		top: '45px',
		left: '30px',
		minHeight: '30px',
		minWidth: '70px',
		color: '#333333',
		backgroundColor: '#ffffff',
		border: '1px solid #333333',
		borderRadius: '20px',
	}
});
const ProductImage = styled('img')({
	borderRadius: '50%',
	objectFit: 'fill',
	transition: 'opacity 150ms',
});

const AddButton = styled('div')({
	position: 'absolute',
    left: '50%',
    marginLeft: '-14px',
    width: '28px',
    height: '28px',
    top: '50%',
    marginTop: '-14px',
    lineHeight: '28px',
    backgroundColor: '#DA3192',
    borderRadius: '50%',

	'.material-icons': {
		color: '#fff',
		verticalAlign: 'middle',
		fontSize: '20px',
	}
});
const TextCenter = styled('div')({
	textAlign: 'center',
});
const MassDrawer = styled(Drawer)({
	'.MuiDrawer-paperAnchorBottom': {
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
		margin: '0 auto',
		width: '95%',
		maxHeight: '85%',
		overflow: 'hidden',
	},

	'.drawer-content': {
		padding: '22px 20px',
		overflow: 'auto',
	}
});
const CloseModalDiv = styled('div')({
	position: 'absolute',
	top: '20px',
	right: '20px',
});
const CloseModalButton = styled(Button)({
	'&:focus': {
		backgroundColor: 'unset',
	}
});

const T = ({children, ...otherProps}:any) => <Trans ns="dashboard" {...otherProps}>{children}</Trans>

export default class MassPresets extends React.Component<Props, State>{

	private steps = ['SelectProduct', 'EditSelectedProducts', 'EditDetails', 'Publishing', 'Success'];
	private routes = ['selectproduct', 'editselectedproducts', 'editdetails', 'publishing', 'success'];

	public state = {
		currentStep: 0,
		dialogOpened: false,
		searchQuery: '',
		expanded: undefined,
		closeModalPrompt: false,
		selectedProduct: 0,
		store: "",
	} as State

	componentDidMount() {
		//Grab store slug from url
		let path = window.PAGE_ROUTE.split("/");
		this.setState({
			store: path[1]
		});

		this.props.fetchStorePresets();
	}

	private setStep = (step: number) => {
		this.setState({currentStep: step})
	}
	
	private onSearch(searchQuery: string) {
		// this.props.history.replace({
		// 	pathname: this.props.match.path,
		// 	search: '?search=' + searchQuery.replace('%','') + '&page=1&limit=' + this.getLimit()
		// })
	}

	handleChangeAccordion = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
		document.getElementById(`topOfPresets`).scrollIntoView()
		this.setState({
			expanded: isExpanded ? panel : undefined
		})
	}
	private handleOpenModal = (id_product: number) => {
		this.setState({
			dialogOpened: true,
			selectedProduct: id_product
		})
	}
	private closeModal = () => {
		this.setState({closeModalPrompt : false})
		this.setStep(0)
		document.body.classList.remove('hide-overflow');
		this.setState({dialogOpened : false})
	}
	private displayPrompt = () => {
		this.setState({closeModalPrompt : true})
	}

	render() {
		
		const Content = [
			EditProductContainer,
			PublishingContainer,
			SuccessContainer,
		][this.state.currentStep] as React.ComponentType<any>

		const setPresetModal = (
			<>
				<Content 
					store={this.state.store}
					storePreset={this.props.storePresets.get(this.state.selectedProduct)} 
					product={this.props.products.get(String(this.state.selectedProduct))} 
					massPresets 
					index={this.state.currentStep} 
					handleStep={this.setStep} 
					handleCloseModal={this.closeModal}
				/>
				<CloseModalDiv>
					<CloseModalButton onClick={() => this.displayPrompt()}><i className="material-icons">close</i></CloseModalButton>
				</CloseModalDiv>
			</>
		)
		return <>
			<div>
				<h5><T>Product Presets</T></h5>
				<Paragraph400size14grey id='topOfPresets'>
					<T>Create default pricing and listing details for the various product types, to be used as default when you publish them to your stores</T>
				</Paragraph400size14grey>
				{ /* TODO: Will add this in later
					<div>
						<SearchInputContainer>
							<div className="pagination-search sortbar">
								<div className="row no-margin">
									<div className="input-field col s12">
										<input
											value={this.state.searchQuery}
											onChange={(e: React.FormEvent<HTMLInputElement>) => this.onSearch(e.currentTarget.value)}
											type="search"
											placeholder="Search"
										/>
										<label htmlFor="product-search" className="active">
											<i className="material-icons">search</i>
										</label>
									</div>
								</div>
							</div>
						</SearchInputContainer>
					</div> */ }
				<div>
					<div>
						{ this.props.productCategories.entrySeq().map(([categoryId, category]) => {
							let products = this.props.products.filter((product) => product.get('id_product_category') === category.get('id'));
							let nbProductsConfigured = products.filter((product) => {
								return this.props.storePresets.has(product.get('id')) && !this.props.storePresets.getIn([product.get('id'), 'default'])
							}).count();

							return <CustomizedAccordion 
								key={category.get('id')}
								id={category.get('id').toString()}
								square
								expanded={this.state.expanded === category.get('id')} onChange={this.handleChangeAccordion(category.get('id'))}
							>
								<CustomizedAccordionSummary expandIcon={<ExpandMoreIcon />}>
									<AccordionHeader>{ category.get('name') }</AccordionHeader>
									<AccordionSubHeader>({ nbProductsConfigured+'/'+products.count() } <T>configured</T>)</AccordionSubHeader>
								</CustomizedAccordionSummary>
								<AccordionDetails>
									<Grid container spacing={1} >
										{ this.props.products.filter((product) => String(product.get('id_product_category')) === categoryId)
										.entrySeq().map(([productId, product]) => {
											let isConfigured = this.props.storePresets.has(product.get('id')) && !this.props.storePresets.getIn([product.get('id'), 'default']);
											return <Grid key={productId} item xs={6} md={4} lg={3}>
												<div onClick={() => this.handleOpenModal(product.get('id'))}>
													<ParentCircle className={!isConfigured ? 'not-configured':''}>
														<ChildCircle>
															<ProductImage src={require('@resources/img/create/thumbnails/'+product.get('slug')+'.jpg')} alt="" />
															{ isConfigured ? <div className='eButton'>
																<div>
																	<svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M9.83594 8.2207C9.78906 8.26758 9.78906 8.31445 9.78906 8.36133V11.0098H1.53906V2.75977H7.1875C7.23438 2.75977 7.28125 2.75977 7.32812 2.71289L8.07812 1.96289C8.19531 1.8457 8.10156 1.63477 7.9375 1.63477H1.53906C0.90625 1.63477 0.414062 2.15039 0.414062 2.75977V11.0098C0.414062 11.6426 0.90625 12.1348 1.53906 12.1348H9.78906C10.3984 12.1348 10.9141 11.6426 10.9141 11.0098V7.61133C10.9141 7.44727 10.7031 7.35352 10.5859 7.4707L9.83594 8.2207ZM13.4922 3.50977C14.0312 2.9707 14.0312 2.10352 13.4922 1.56445L12.4844 0.556641C11.9453 0.0175781 11.0781 0.0175781 10.5391 0.556641L4.39844 6.69727L4.16406 8.83008C4.09375 9.43945 4.60938 9.95508 5.21875 9.88477L7.35156 9.65039L13.4922 3.50977ZM11.1953 4.21289L6.83594 8.57227L5.28906 8.75977L5.47656 7.21289L9.83594 2.85352L11.1953 4.21289ZM12.6953 2.36133C12.8125 2.45508 12.8125 2.5957 12.7188 2.71289L11.9922 3.43945L10.6328 2.05664L11.3359 1.35352C11.4297 1.23633 11.5938 1.23633 11.6875 1.35352L12.6953 2.36133Z" fill="#333333" />
																	</svg>
																</div>
																<div>Edit</div>
															</div> : <AddButton><i className="material-icons">add</i></AddButton> }
														</ChildCircle>
													</ParentCircle>
													<TextCenter>
														{ product.get('name') }
													</TextCenter>
												</div>
											</Grid>
										})}
									</Grid>
								</AccordionDetails>
							</CustomizedAccordion>
						})}
					</div>
				</div>

			</div>

			<div id="mass-presets-modal-parent-div">
				<Hidden mdDown>
					<MassModalContainer className={"modal react-modal  light-mode mass-modal " + (this.state.currentStep > 0 ? "height-fit-content" : "" )} darken opened={this.state.dialogOpened} animate persist
						content={setPresetModal}
					/>
				</Hidden>
				<Hidden mdUp>
					<MassDrawer anchor="bottom" open={this.state.dialogOpened} className="drawer-root">
						<div className="drawer-content">
							{setPresetModal}
						</div>
					</MassDrawer>
				</Hidden>
			</div>

			<PromptContainer
				opened={this.state.closeModalPrompt}
				title={<T>Are you sure?</T>}
				message={<T>You will lose your changes if you close this dialog</T>}
				onClose={() => this.setState({closeModalPrompt : false})}
				actions={{
					"Close": this.closeModal
				}}
				dismissible
			/>
			

		</>;
	}
}