import { useTranslation } from 'react-i18next'
import AccountHeader from '../AccountHeader'
import { Button, Card, CardContent, Checkbox, Container, Divider, FormControl, FormControlLabel, Stack, Typography } from '@mui/material'
import { type ChangeEvent, type FormEvent, useCallback, useState, useEffect } from 'react'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { SaveUserNotifications } from '../../UserData/_actions/UserDataActions'

export default function Notifications() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')

  const userNewsletterNews = useAppSelector(state => state.get('userData').get('newsletter_news'))
  const userNewsletterOffers = useAppSelector(state => state.get('userData').get('newsletter_offers'))

  const [newsletterNews, setNewsletterNews] = useState(userNewsletterNews)
  const [newsletterOffers, setNewsletterOffers] = useState(userNewsletterOffers)

  useEffect(() => {
    setNewsletterNews(userNewsletterNews)
  }, [userNewsletterNews])

  useEffect(() => {
    setNewsletterOffers(userNewsletterOffers)
  }, [userNewsletterOffers])

  const onChangeNewsletterOffers = useCallback((e: ChangeEvent<HTMLInputElement>) => setNewsletterOffers(e.target.checked), [])
  const onChangeNewsletterNews = useCallback((e: ChangeEvent<HTMLInputElement>) => setNewsletterNews(e.target.checked), [])

  const onSubmitNewsletters = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(SaveUserNotifications(newsletterNews, newsletterOffers))
  }, [newsletterNews, newsletterOffers])

  const onUnsubscribeAll = useCallback(() => {
    dispatch(SaveUserNotifications(false, false))
  }, [])

  return <div>
    <AccountHeader
      title={t('Notifications')}
    />

    <Container
      maxWidth="sm"
      disableGutters
      style={{ margin: 0 }}
    >
      <Stack spacing={4}>
        <Card elevation={0}>
          <form onSubmit={onSubmitNewsletters}>
            <CardContent sx={{ py: 2 }}>
              <Stack spacing={1}>
                <Typography
                  variant="h3"
                >{t('Newsletters')}</Typography>
              </Stack>
            </CardContent>
            <Divider />
            <CardContent>
              <Stack spacing={2}>
                <FormControl>
                  <FormControlLabel
                    control={<Checkbox
                      checked={newsletterOffers}
                      onChange={onChangeNewsletterOffers}
                    />}
                    label={t('Exclusive offers & promotions')}
                  />
                  <FormControlLabel
                    control={<Checkbox
                      checked={newsletterNews}
                      onChange={onChangeNewsletterNews}
                    />}
                    label={t('News & Product launches')}
                  />
                </FormControl>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >{t('Save')}</Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onUnsubscribeAll}
                  >{t('Unsubscribe from all')}</Button>
                </Stack>
              </Stack>
            </CardContent>
          </form>
        </Card>
      </Stack>
    </Container>
  </div>
}
