import { Checkbox, styled } from '@mui/material';
import * as React from 'react';
import { PinkCheckBox, PinkCheckedIcon, UncheckedWhiteBackgroundIcon } from '../Common/_components/CheckBoxes';

export type Props = {
    image: string
    name: string
    selected?: boolean
}

const CardContainer = styled('div')({
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    width: '100%',
    padding: '0 35px 0 0',
    cursor: 'pointer',
    marginBottom: '10px',

    '&.selected p': {
        color: '#F600BE',
    },

    'img': {
        maxWidth: '35px',
    }
});

const FilterLabel = styled('p')({
    fontSize: '14px',
    lineHeight: '1.2',
    letterSpacing: '0.02em',
    color: '#000',
    margin: '0',
    padding: '0 15px',
    textAlign: 'left',
});

const CheckBoxDiv = styled('div')({
    position: 'absolute',
    top: '-1px',
    right: '0px',
    marginRight: '-17px',
});

export default class FilterCard extends React.Component<Props> {
    state = {
        name: '',
        selected: false
    }

	render = () => <CardContainer className={(this.props.selected ? "selected" : "") + " position-relative"}>
        <img src={require('@resources/img/all-products/filters/'+this.props.image+'.svg?url')} alt={this.props.name} />
        <FilterLabel>{this.props.name}</FilterLabel>
        <CheckBoxDiv>
            <PinkCheckBox
                control={
                    <Checkbox
                        color="secondary"
                        checked={this.props.selected}
                        icon={<UncheckedWhiteBackgroundIcon />}
                        checkedIcon={<PinkCheckedIcon />}
                        onClick={(e) => {e.preventDefault()}}
                    />
                }
                label=""
            />
        </CheckBoxDiv>
    </CardContainer> 
}