import { type ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ItemList from '../../Common/ItemList/ItemList'
import { Box, Divider, MenuItem, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { Circle as CircleIcon } from '@mui/icons-material'
import AccountHeader from '../AccountHeader'
import { type CartStatus } from '../../UserData/_stores/UserCartStore'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { LIST_CART_STATUSES } from '../../UserData/_stores/UserCartStore'
import OrderRow from './OrderRow'
import SearchBar from '../../Common/ItemList/SearchBar'
import { useSearchParams } from 'react-router-dom'
import SelectV2 from '../../Common/Form/SelectV2'
import useOrderStatusProps from '../../Common/_hooks/Order/useOrderStatusProps'

const LIMIT = 20
const SEARCH_FIELDS = ['search', 'statuses']
const LIST_STATUS_FILTERS = LIST_CART_STATUSES.filter(value => !['cart'].includes(value))

export default function OrderList() {
  const [t] = useTranslation('account-v2')
  const [searchParams] = useSearchParams()

  const paramSearch = searchParams.get('search')
  const paramStatuses = searchParams.get('statuses')
  const [search, setSearch] = useState({
    search: paramSearch ? paramSearch.split(',') : [],
    statuses: paramStatuses ? paramStatuses.split(',') : []
  })
  const [selectedStatus, setSelectedStatus] = useState<CartStatus | 'all'>(paramStatuses ? paramStatuses as CartStatus : 'all')

  const searchText = useMemo(() => {
    return search.search.join(',')
  }, [search.search])

  const loadingItems = useMemo(() => {
    const listSkeletons = []

    for (let i = 0; i < LIMIT; i++) {
      listSkeletons.push(<Skeleton key={i} variant="text" height={64} />)
    }

    return listSkeletons
  }, [])

  const renderOrder = useCallback((item: UserCartStore, index: number) => {
    return <OrderRow order={item} index={index} />
  }, [])

  const onChangeStatus = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedStatus(e.target.value === 'all' ? 'all' : e.target.value as CartStatus)
    setSearch(search => ({
      ...search,
      statuses: e.target.value === 'all' ? [] : [e.target.value]
    }))
  }, [])

  const renderStatus = useCallback((status: CartStatus) => {
    const { text, color } = useOrderStatusProps(status)
    return <MenuItem key={status} value={status}>
      <Stack direction="row" spacing={1}>
        <CircleIcon color={color}/>
        <span>{text}</span>
      </Stack>
    </MenuItem>
  }, [])

  const onChangeSearchText = useCallback((value: string) => {
    setSearch(search => ({
      ...search,
      search: value.split(',')
    }))
  }, [])

  const renderList = useCallback((items: JSX.Element | null, links: JSX.Element | null, loading: boolean) => {
    return <>
      <Stack>
        <Box
          component="div"
          sx={{
            display: {
              xs: 'none',
              lg: 'block'
            }
          }}
        >
          <div>
            <Stack
              direction="row"
              spacing={4}
              sx={{
                py: 2,
                px: 2
              }}
            >
              <Box
                component="div"
                sx={{ width: '100px' }}
              >
                <Typography variant="h4">{t('Order')}</Typography>
              </Box>
              <Box
                component="div"
                sx={{ width: '170px' }}
              >
                <Typography variant="h4">{t('Date')}</Typography>
              </Box>
              <Box
                component="div"
                sx={{ flexGrow: 1 }}
              >
                <Typography variant="h4">{t('Ship to')}</Typography>
              </Box>
              <Box
                component="div"
                sx={{ width: '130px' }}
              >
                <Typography variant="h4">{t('Total')}</Typography>
              </Box>
              <Box
                component="div"
                sx={{
                  width: '150px',
                  display: {
                    md: 'none',
                    lg: 'block'
                  }
                }}
              >
                <Typography variant="h4">{t('Status')}</Typography>
              </Box>
            </Stack>
          </div>
          <Divider variant="bold" />
        </Box>
        {loading
          ? <Stack sx={{ px: 2 }}>{ loadingItems }</Stack>
          : <Stack
            spacing={{
              xs: 2,
              lg: 0
            }}
          >{items ?? <Stack sx={{ p: 2 }}>{t('No Results')}</Stack>}</Stack>
        }
      </Stack>

      {links}
    </>
  }, [t, loadingItems])

  return <div>
    <AccountHeader
      title={t('Orders')}
      actions={<>
        <SelectV2
          value={selectedStatus}
          onChange={onChangeStatus}
        >
          <MenuItem value="all">{t('All statuses')}</MenuItem>
          { LIST_STATUS_FILTERS.map(renderStatus)}
        </SelectV2>
      </>}
    />
    <SearchBar
      placeholder={t('Search by order number or name...')}
      value={searchText}
      onChange={onChangeSearchText}
    />
    <Paper
      elevation={0}
      sx={{
        backgroundColor: {
          xs: 'transparent',
          lg: '#fff'
        }
      }}
    >
      <ItemList
        dataType="orders"
        item={renderOrder}
        searchQuery={search}
        searchFields={SEARCH_FIELDS}
        limit={LIMIT}
        render={renderList}
      />
    </Paper>
  </div>
}
