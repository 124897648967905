//import { Canvas, Image, new Canvas } from 'canvas';
//import { Canvas, Image } from 'skia-canvas';

export default function createCanvas(width: number, height: number) {
  if(IS_BROWSER) {
    return Object.assign(document.createElement('canvas'), { width: width, height: height })
  } else {
    //return new Canvas(width, height)
    return require('@napi-rs/canvas').createCanvas(width > 0 ? width : 1, height > 0 ? height : 1)
  }
}