import { useTranslation } from "react-i18next"
import { useAppSelector } from "../_hooks/useAppSelector";
import useErrorText from "./useErrorText";
import { Alert } from "@mui/material";

interface ErrorTextProps {
    errorKey: string
}

export default function ErrorAlert(props:ErrorTextProps) {
    const errorText = useErrorText(props.errorKey)

    return errorText ? <Alert
      severity="error"
      sx={{mt: 2, display: 'inline-flex'}}
    >{errorText}</Alert> : null
}