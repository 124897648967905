import * as Immutable from 'immutable';
import UserImageListStore from './UserImageListStore';
import UserProductDesignListStore from './UserProductDesignListStore';
import { IUserAddressStore, UserAddressStore } from './UserAddressStore';
import { IUserCreditCardStore, UserCreditCardStore } from './UserCreditCardStore';
import UserCartStore, { IUserCartStore, CartStatus } from './UserCartStore';
import { IUserTaxNumberStore, UserTaxNumberStore } from './UserTaxNumberStore';
import UserPrefsStore from './UserPrefsStore';
import UserStorePresetStore from './UserStorePresetStore';
import UserStorePageStore from './UserStorePageStore';
import UserStoreStore from './UserStoreStore';
import UserAddressListStore from './UserAddressListStore';
import UserCreditCardListStore from './UserCreditCardListStore';
import { CollectionStore } from './CollectionStore';
import OrderListStore from './OrderListStore';
import { BrandingOptionStore } from './BrandingOptionStore';
import UserRewardStore from './UserRewardStore';
import PhotoLabCreationListStore from './PhotoLabCreationListStore';

export interface UserDataPayload {
	id: number
	loggedIn: boolean
	validated: boolean
	email: string
	name: string
	phone: string
	newsletter_news: boolean
	newsletter_offers: boolean
}

export interface IUserDataStore {
	fetched: boolean
	id: number
	loggedIn: boolean
	email: string
	name: string
	phone: string
	validated: boolean
	newsletter_news: boolean
	newsletter_offers: boolean
	addresses: UserAddressListStore
	creditCards: UserCreditCardListStore
	orders: OrderListStore,
	rewards: Immutable.OrderedMap<string, UserRewardStore>
	images: UserImageListStore
	productDesigns: UserProductDesignListStore
	collections: Immutable.OrderedMap<string, CollectionStore>
	brandingOptions: Immutable.OrderedMap<string, BrandingOptionStore>
	photoLabCreations: PhotoLabCreationListStore
	prefs: UserPrefsStore
	taxNumbers: Immutable.OrderedMap<number, UserTaxNumberStore>
	storePresets: Immutable.OrderedMap<number, UserStorePresetStore>
	storePages: Immutable.OrderedMap<string, UserStorePageStore>
	stores: Immutable.OrderedMap<number, UserStoreStore>
}

const defaultStore = Immutable.Record<IUserDataStore>({
	fetched: false,
	id: 0,
	loggedIn: false,
	email: "",
	name: '',
	phone: '',
	validated: false,
	newsletter_news: false,
	newsletter_offers: false,
	addresses: new UserAddressListStore(),
	creditCards: new UserCreditCardListStore(),
	orders: new OrderListStore(),
	rewards: Immutable.OrderedMap<string, UserRewardStore>(),
	images: new UserImageListStore(),
	productDesigns: new UserProductDesignListStore(),
	photoLabCreations: new PhotoLabCreationListStore(),
	collections: Immutable.OrderedMap<string, CollectionStore>(),
	brandingOptions: Immutable.OrderedMap<string, BrandingOptionStore>(),
	prefs: new UserPrefsStore(),
	taxNumbers: Immutable.OrderedMap<number, UserTaxNumberStore>(),
	storePresets: Immutable.OrderedMap<number, UserStorePresetStore>(),
	storePages: Immutable.OrderedMap<string, UserStorePageStore>(),
	stores: Immutable.OrderedMap<number, UserStoreStore>(),
});

export default class UserDataStore extends defaultStore implements IUserDataStore {}