import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Grid, ListItemIcon, ListItemText, Card, CardActions, Typography, IconButton, Menu, MenuItem, CardActionArea } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type UserImageStore } from '../../UserData/_stores/UserImageStore'
import RenameImageDialog from './RenameImageDialog'
import DeleteImageDialog from './DeleteImageDialog'

export interface Props {
  item: UserImageStore
  onSelect?: (item: UserImageStore) => void
}

export default function Image(props: Props) {
  const [t] = useTranslation('account-v2')

  const [menuOpen, setMenuOpen] = useState(false)
  const [menuRenameOpen, setMenuRenameOpen] = useState(false)
  const [menuDeleteOpen, setMenuDeleteOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const onMenuOpen = useCallback(() => {
    setMenuOpen(true)
  }, [])
  const onMenuClose = useCallback(() => {
    setMenuOpen(false)
  }, [])

  const onMenuRenameOpen = useCallback(() => {
    setMenuOpen(false)
    setMenuRenameOpen(true)
  }, [])

  const onMenuRenameClose = useCallback(() => {
    setMenuRenameOpen(false)
  }, [])

  const onMenuDeleteOpen = useCallback(() => {
    setMenuOpen(false)
    setMenuDeleteOpen(true)
  }, [])

  const onMenuDeleteClose = useCallback(() => {
    setMenuDeleteOpen(false)
  }, [])

  const onClick = useMemo(() => {
    if (!props.onSelect) return undefined

    return () => {
      props.onSelect && props.onSelect(props.item)
    }
  }, [props.onSelect, props.item])

  const imageElem = useMemo(() => {
    return <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src={props.item.get('filename')} style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        pointerEvents: 'none'
      }} />
    </div>
  }, [props.item])

  return <Grid item key={props.item.get('id')} xs={6} sm={4} lg={3} xl={2}>
    <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      { onClick ? <CardActionArea
        onClick={onClick}
        style={{
          paddingBottom: '90%',
          height: 0,
          position: 'relative'
        }}
      >{ imageElem }</CardActionArea> : <div style={{
        paddingBottom: '90%',
        height: 0,
        position: 'relative'
      }}>{ imageElem }</div> }

      <CardActions style={{
        paddingLeft: '16px',
        flexGrow: 1
      }}>
        <Typography
          variant="h4"
          style={{
            flexGrow: 1,
            padding: '8px 0',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >{props.item.get('name')}</Typography>
        <IconButton
          onClick={onMenuOpen}
          ref={setAnchorEl}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
      </CardActions>
      <Menu
        onClose={onMenuClose}
        open={menuOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={onMenuRenameOpen}>
          <ListItemIcon><EditIcon /></ListItemIcon>
          <ListItemText>{t('Rename')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={onMenuDeleteOpen}>
          <ListItemIcon><DeleteIcon /></ListItemIcon>
          <ListItemText>{t('Delete')}</ListItemText>
        </MenuItem>
      </Menu>
    </Card>

    <RenameImageDialog
      image={props.item}
      opened={menuRenameOpen}
      onClose={onMenuRenameClose}
    />

    <DeleteImageDialog
      image={props.item}
      opened={menuDeleteOpen}
      onClose={onMenuDeleteClose}
    />
  </Grid>
}
