import { Paper as MuiPaper, PaperProps as MuiPaperProps, styled } from '@mui/material';


const StyledPaper = styled(MuiPaper)({
	'&.ornaments-shapes-1': {
		backgroundImage: 'url('+require('@resources/img/theme/dialog/ornaments-shapes-1.svg?url')+')',
		backgroundSize: '100%',
		backgroundPosition: 'center top',
		backgroundRepeat: 'no-repeat',
	},

	'&.ornaments-shapes-2': {
		backgroundImage: 'url('+require('@resources/img/theme/dialog/ornaments-shapes-2.svg?url')+')',
		backgroundPosition: 'right top',
		backgroundRepeat: 'no-repeat',
	},

	'&.ornaments-shapes-3': {
		backgroundImage: 'url('+require('@resources/img/theme/dialog/ornaments-shapes-3.svg?url')+')',
		backgroundPosition: 'bottom',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},

	'&.ornaments-shapes-3-top': {
		backgroundImage: 'url('+require('@resources/img/theme/dialog/ornaments-shapes-3-top.svg?url')+')',
		backgroundPosition: 'top',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},
	
	['@media (max-width: 760px)']: {
		margin: '16px 16px 0',
		width: 'calc(100% - 32px)',
		alignSelf: 'flex-end',
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	}
});

export type DialogPaperOrnaments = 'shapes-1'|'shapes-2'|'shapes-3'|'shapes-3-top'

type Props = MuiPaperProps & {
	ornaments?: DialogPaperOrnaments
}

export default function DialogPaper(props: Props) {
	const { className, ...otherProps } = props;

    return <StyledPaper
		className={className + (props.ornaments ? ' ornaments-'+props.ornaments : '')}
		{...otherProps}
	/>
}

