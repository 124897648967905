import { data } from "jquery";
import { createAjaxAction } from "../../Common/_actions/AjaxAction";
import { CollectionPayload } from "../_stores/CollectionStore";
import { EditCollection, EditManyCollections, RemoveCollection } from "./CollectionStoreActions";

export enum CollectionActionType {
  FetchCollections = 'COLLECTION_FETCH_COLLECTIONS',
  CreateCollection = 'COLLECTION_CREATE_COLLECTION',
  UpdateCollection = 'COLLECTION_UPDATE_COLLECTION',
  DeleteCollection = 'COLLECTION_DELETE_COLLECTION',
  AddToCollection = 'COLLECTION_ADD_TO_COLLECTION',
}

export const FetchCollections = () => createAjaxAction<{items: Record<string, CollectionPayload>}>(CollectionActionType.FetchCollections, {
	url: 'ajax/api/user/collections',
	method: 'GET',
	preventDuplicates: true,
	onSuccess: (response, dispatch) => {
		dispatch(EditManyCollections(response.data.items));
	},
});

export const CreateCollection = (
  name: string
) => {
  const data = new FormData()
  data.append('name', name)

  return createAjaxAction<{item: CollectionPayload}>(CollectionActionType.CreateCollection, {
    url: 'ajax/api/user/collections',
    method: 'POST',
    data: data,
    onSuccess: (response, dispatch) => {
      dispatch(EditCollection(response.data.item));
    },
  });
}

export const UpdateCollection = (
  collectionId: number,
  name: string,
) => {
  const data = new FormData()
  data.append('name', name)

  return createAjaxAction<{item: CollectionPayload}>(CollectionActionType.UpdateCollection, {
    url: 'ajax/api/user/collections/'+collectionId+'/update',
    method: 'POST',
    data: data,
    onSuccess: (response, dispatch) => {
      dispatch(EditCollection(response.data.item));
    },
  });
}

export const DeleteCollection = (
  collectionId: number,
) => {
  return createAjaxAction<{}>(CollectionActionType.DeleteCollection, {
    url: 'ajax/api/user/collections/'+collectionId+'/delete',
    method: 'POST',
    onSuccess: (response, dispatch) => {
      dispatch(RemoveCollection(collectionId));
    },
  });
}

export const AddToCollection = (
  collectionId: number,
  productDesignId: number
) => {
  const data = new FormData()
  data.append('productDesignId', String(productDesignId))

  return createAjaxAction<{item: CollectionPayload}>(CollectionActionType.AddToCollection, {
    url: 'ajax/api/user/collections/'+collectionId+'/add-item',
    method: 'POST',
    data: data,
    onSuccess: (response, dispatch) => {
      dispatch(EditCollection(response.data.item));
    },
  });
}