import { Paper, TextField, Typography, styled } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Point from '../../Common/Utils/Math/Point';
import { EditActiveDesign, EditDesignName, EditWarnings } from '../../UIData/_actions/DesignLabStoreActions';
import { UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore';
import WebsiteStore from '../../WebsiteStore';
import SaveDialog from '../SaveDialog/_components/SaveDialog';
import useCheckDpi from '../_hooks/useCheckDpi';
import useCheckWarningPoint from '../_hooks/useCheckWarningPoint';
import useLabData from '../_hooks/useLabData';
import { SaveState } from './DesignLab';
import OptionSelectors from '../OptionSelector/OptionSelectors';
import SaveButton from './SaveButton';
import ValidIndicator from './ValidIndicator';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

const CustomizeContainer = styled('div')({
    flex: 1,
    padding: '20px',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',

    '@media (max-width: 1510px), (max-height: 1070px)': {
        padding: '15px',
    }
})

const ValidIndicatorsContainer = styled('div')({
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '20px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',

    '@media (max-width: 1510px), (max-height: 1070px)': {
        padding: '15px',
    },

    '@media (max-width: 1510px) and (max-height: 1070px)': {
        flexDirection: 'row',
    },

    '@media (max-width: 1053px), (max-height: 780px)': {
        flexDirection: 'column',
    },
})

const SaveBarContainer = styled(Paper)(({theme}) => ({
    margin: '0 30px',
    backgroundColor: '#fff',
    borderRadius: '20px 20px 0 0',
    display: 'flex',
    flexDirection: 'column',

    ['@media (max-width: 1510px), (max-height: 1070px)']: {
        margin: '0 15px',
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        ['@media (max-height: '+theme.height_breakpoints.values.lab_mobile+'px) and (min-width: 700px)']: {
            borderRadius: '20px 0 0 20px',
            marginRight: 0,
        }
    },
}))

const BottomSection = styled('div')(({theme}) => ({
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '@media (max-width: 1510px) and (max-height: 1070px)': {
        flexDirection: 'column',
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        ['@media (min-width: 500px)']: {
            flexDirection: 'row',    
        },
        ['@media (max-height: '+theme.height_breakpoints.values.lab_mobile+'px) and (min-width: 700px)']: {
            flexDirection: 'column',    
        }
    },
}));

const SaveButtonContainer = styled('div')(({theme}) => ({
    padding: '20px',
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '@media (max-width: 1510px) and (max-height: 1070px)': {
        border: 0,
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        ['@media (min-width: 500px)']: {
            borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        },
        ['@media (max-height: '+theme.height_breakpoints.values.lab_mobile+'px) and (min-width: 700px)']: {
            border: 0,
        }
    },
}));

export default function SaveBar() {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [t] = useTranslation('design-lab');
    const [tR] = useTranslation('routes');
    const activeDesignId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeDesignId'));
    const activeProductId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
    const activeVariant = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeVariant'));
    const activeOptions = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeOptions'));
    const warnings = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('warnings'));
    const designName = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('designName'));
    const productData = useSelector((state:WebsiteStore) => state.get('appData').get('products').get(String(activeProductId)));
    const layers = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('layers'));
    const labData = useLabData();
    const checkWarningPoint = useCheckWarningPoint();
    const checkDpi = useCheckDpi();

    const [saveState, setSaveState] = useState<SaveState>('none');
    const [similarProducts, setSimilarProducts] = useState([]);
    const [savedProductDesign, setSavedProductDesign] = useState<UserProductDesignStore|null>(null);

    //Update activeDesignId + url if editing a design
    useEffect(() => {
        if(!activeDesignId || !savedProductDesign) return;
        dispatch(EditActiveDesign(savedProductDesign.get('id')));
        setSearchParams({
            design: savedProductDesign.get('id')
        });
    }, [savedProductDesign])

    useEffect(() => {
        //Ignore checking scene if there are subproducts
        if(productData.get('labData').has_subproducts || !labData?.variants[activeVariant]) return;

        const scenes = Object.keys(labData.variants[activeVariant].scenes);
        let tmpWarnings:Record<string,Record<string,boolean>> = {}
        scenes.forEach(scene => {
            tmpWarnings[scene] = {
                'templates_covered': false,
                'resolution': true,
            };

            //Check templates_covered
            let templatesCoveredOk = true;
            
            if(labData) {
                labData.variants[activeVariant]?.scenes[scene]?.warnings.forEach((warning: Point) => {
                    templatesCoveredOk = templatesCoveredOk && checkWarningPoint(warning, scene);
                });
            }

            //Check resolution
            let resolutionOk = true;
            layers.forEach(layer => {
                if(layer.get('scene') !== scene) {
                    return;
                }

                const { dpiIndicator } = checkDpi(layer);
                
                resolutionOk = resolutionOk && dpiIndicator !== 'bad';
            })

            tmpWarnings[scene].templates_covered = templatesCoveredOk;
            tmpWarnings[scene].resolution = resolutionOk;
        })

        dispatch(EditWarnings(tmpWarnings));
    }, [productData, labData, layers, activeVariant, checkWarningPoint, checkDpi]);

    const resolutionOk = useMemo(() => {
        return Boolean(warnings.get('resolution').reduce((reduction, value) => value && reduction));
    }, [warnings]);

    const templatesCoveredOk = useMemo(() => {
        return Boolean(warnings.get('templates_covered').reduce((reduction, value) => value && reduction));
    }, [warnings]);

    const optionsOk = useMemo(() => {
        let ok = true;
        
        if(!labData?.options) {
            return ok;
        }
        
        Object.entries(labData.options).forEach(([option, type]:[string,string]) => {
            ok = ok && Boolean(activeOptions.get(option))
        });

        return ok;
    }, [activeOptions, labData]);

    return <>
        <SaveBarContainer
            elevation={3}
        >
            <CustomizeContainer>
                <Typography
                    variant="h4"
                    style={{
                        marginBottom: '10px',
                    }}
                >{ t('Customize your product') }</Typography>

                <div style={{
                    display: 'flex'
                }}>
                    <TextField
                        variant="filled"
                        label={t('Name your product')}
                        value={designName}
                        onChange={(e) => dispatch(EditDesignName(e.target.value))}
                    />
                    <OptionSelectors/>
                </div>
            </CustomizeContainer>
            <BottomSection>
                <ValidIndicatorsContainer>
                    <ValidIndicator
                        valid={resolutionOk} 
                        validText={t('Resolution: Good')}
                        invalidText={t('Resolution: Bad')}
                        iconSize="medium"
                    />
                    <ValidIndicator 
                        valid={templatesCoveredOk} 
                        validText={t('Templates: Covered')}
                        invalidText={t('Templates: Not Covered')}
                        iconSize="medium"
                    />
                </ValidIndicatorsContainer>
                <SaveButtonContainer>
                    <SaveButton
                        disabled={!resolutionOk || !templatesCoveredOk || !optionsOk}
                        onBuild={() => {
                            setSaveState('generating');
                        }}
                        onSave={(productDesign, similarProducts) => {
                            setSaveState('done');
                            setSavedProductDesign(productDesign);
                            setSimilarProducts(similarProducts)
                        }}
                        onError={() => {
                            setSaveState('none');
                        }}
                    />
                </SaveButtonContainer>
            </BottomSection>
        </SaveBarContainer>

        <SaveDialog
            saveState={saveState}
            productDesign={savedProductDesign}
            similarProducts={similarProducts}
            onClose={() => {
                setSaveState('none');
            }}
        />
    </>
}