export default function createAlphaThresholdFilter(threshold: number) {
  return (imageData: ImageData) => {
    const level = threshold * 255,
      data = imageData.data,
      length = data.length;

    for (let i = 0; i < length; i += 1) {
      //Skip rgb
      if (i % 4 !== 3) continue;

      data[i] = data[i] < level ? 0 : 255;
    }
  }
}