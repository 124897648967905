import { Box, FormHelperText, IconButton, InputBase, InputBaseProps } from '@mui/material';
import { RemoveRounded as RemoveRoundedIcon, AddRounded as AddRoundedIcon } from '@mui/icons-material';
import { ChangeEvent, FocusEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import LabelV2, { LabelV2Props } from './LabelV2';
import useId from '@mui/utils/useId';

export type NumberFieldV2Props = {
  id?: string
  label?: ReactNode
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
  disabled?: boolean,
  error?: boolean
  helperText?: ReactNode
  InputLabelProps?: Omit<LabelV2Props, "children" | "ref">
  InputProps?: Omit<InputBaseProps, "children" | "ref">
}

export default function NumberFieldV2(props: NumberFieldV2Props) {
  const id = useId(props.id)
  const [numberInput, setNumberInput] = useState(String(props.value));

  useEffect(() => {
    setNumberInput(String(props.value));
  }, [props.value]);

  const fixQuantity = useCallback((value: number) => {
    let tmpValue = isNaN(value) ? 0 : value;

    if (props.min !== undefined) {
      tmpValue = Math.max(props.min, tmpValue);
    }

    if (props.max !== undefined) {
      tmpValue = Math.min(props.max, tmpValue);
    }

    return tmpValue;
  }, [props.min, props.max]);

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNumberInput(e.target.value);
  }, []);

  const onInputBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
    //Only trigger change if the value changed
    const newValue = fixQuantity(parseInt(e?.target?.value, 0));
    if(newValue === props.value) return;

    props.onChange(newValue);
  }, [props.value, props.onChange, fixQuantity]);

  const onMinus = useCallback(() => {
    const newValue = fixQuantity(props.value - 1);
    if(newValue === props.value) return;

    props.onChange(newValue);
  }, [props.value, props.onChange, fixQuantity]);

  const onPlus = useCallback(() => {
    const newValue = fixQuantity(props.value + 1);
    if(newValue === props.value) return;
    
    props.onChange(newValue);
  }, [props.value, props.onChange, fixQuantity]);

  const { InputProps, InputLabelProps, ...rest } = props;

  return <div>
    {props.label ? <LabelV2
      htmlFor={id}
      error={props.error}
      disabled={props.disabled}
      {...InputLabelProps}
    >{props.label}</LabelV2> : <></>}

    <Box
      component="div"
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
        borderRadius: '50px',
        display: 'flex',
        alignItems: 'stretch',
        overflow: 'hidden',
        flexShrink: 0,
      }}
    >
      <IconButton
        onClick={onMinus}
        disabled={props.disabled}
        color="secondary"
        size="small"
        style={{
          borderRadius: 0,
          padding: '5px 10px',
        }}
      ><RemoveRoundedIcon fontSize="small" /></IconButton>
      <InputBase
        id={id}
        value={numberInput}
        onChange={onInputChange}
        onBlur={onInputBlur}
        disabled={props.disabled}
        error={props.error}
        style={{
          background: 'transparent',
          flex: 1,
          width: '3.25rem',
        }}
        inputProps={{
          style: {
            textAlign: 'center',
            padding: '10px 0',
          }
        }}
      />
      <IconButton
        onClick={onPlus}
        disabled={props.disabled}
        color="secondary"
        size="small"
        style={{
          borderRadius: 0,
          padding: '5px 10px',
        }}
      ><AddRoundedIcon fontSize="small" /></IconButton>
    </Box>
    {props.helperText ? <FormHelperText
      error={props.error}
    >{props.helperText}</FormHelperText> : null}
  </div>
}