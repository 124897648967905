import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';

const BackgroundLifestyle = styled('div')({
  backgroundImage: 'url(' + require('@resources/img/pages/products/yoga-shorts/yoga-friends-1.jpg') + ')',
  backgroundPosition: 'center top',
  backgroundSize: 'cover',
  height: '70vh',
  position: 'relative',

  '&::after': {
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent)',
    bottom: '0',
    content: '""',
    height: '20vh',
    left: '0',
    position: 'absolute',
    right: '0',
    zIndex: '1',
  }
})

const PRODUCT_ID = 121;
const VIEW_PRODUCT_ID = 137;

export default function YogaShortsPage() {
  const [t] = useTranslation('page/products/yoga-shorts');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Yoga Shorts | Products')}
      description={t('Design and create your own custom printed Yoga Shorts featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#ebabd5"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '43%',
      }}
      tag={t('The all-over print')}
      title={t('Yoga Shorts')}
      text={t('Uplift your yoga practice with eco-friendly, customizable and printed on-demand yoga shorts! Tailored for ultimate comfort and coverage with their high waistband and 6" inseam, they\'re perfect for any pose. Flaunt your unique designs with your artworks and designs during workouts or as trendy casual wear, embracing comfort and individuality in every movement.')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/yoga-shorts/banner-1.png')}
      alt={t('Picture of Yoga Shorts')}
      details={[
        {
          img: require('@resources/img/pages/products/yoga-shorts/icon-1.svg?url'),
          alt: t('Fabric icon'),
          text: t('Made from 88% ecopolyester / 12 % spandex fabric'),
        },
        {
          img: require('@resources/img/pages/products/yoga-shorts/icon-2.svg?url'),
          alt: t('Worldwide icon'),
          text: t('Made on demand in Canada and shipped worldwide.'),
        },
        {
          img: require('@resources/img/pages/products/yoga-shorts/icon-3.svg?url'),
          alt: t('UPF icon'),
          text: t('UPF 50 wet and dry'),
        },
        {
          img: require('@resources/img/pages/products/yoga-shorts/icon-4.svg?url'),
          alt: t('Ink icon'),
          text: t('Printed with vibrant and permanent sublimation inks'),
        },
        {
          img: require('@resources/img/pages/products/yoga-shorts/icon-5.svg?url'),
          alt: t('Yoga icon'),
          text: t('Classic yoga cut with high waistband and 6" short inseam'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/yoga-shorts/gallery_0.jpg'),
      require('@resources/img/pages/products/yoga-shorts/gallery_1.jpg'),
      require('@resources/img/pages/products/yoga-shorts/gallery_2.jpg'),
      require('@resources/img/pages/products/yoga-shorts/gallery_3.jpg'),
      require('@resources/img/pages/products/yoga-shorts/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Yoga <br/>Shorts <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love yoga shorts! At prices designed for reselling, yoga shorts made on demand meet the needs of sellers who prefer not to stock inventory.. Sell them in your eCommerce store and decide your profit margin')}
    />

    <BackgroundLifestyle>
      <h3 style={{
        bottom: '5vh',
        color: '#fff',
        fontFamily: 'Rottersand',
        fontSize: '6vw',
        left: '0',
        lineHeight: '1',
        margin: 'auto',
        position: 'absolute',
        right: '0',
        textAlign: 'center',
        textShadow: '0 0 20px rgba(0, 0, 0, 0.8)',
        zIndex: '2',
      }}>{t('Redefining lifestyle essentials')}</h3>
    </BackgroundLifestyle>

    <SectionPricing productId={PRODUCT_ID} />

    <SectionSizes
      chart="bottoms"
      productId={PRODUCT_ID}
      title={t('Yoga shorts Sizing')}
      text={t('All measurements are approximate. Since our products are made by hand, there may be variations in the finished size of our products between orders or even within an order.')}
    />

    <SectionBranding
      hasLabel
      title={t('Brand your Yoga Shorts')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your yoga shorts with these great products to create your own unique styles.')}
      productSlugs={['athletic-crop-top', 'cotton-market-tote', 'yoga-leggings']}
    />
  </div>
}