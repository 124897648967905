import { useCallback } from 'react'
import DialogButton from '../../../Common/Buttons/DialogButton'
import Dialog from '../../../Common/Dialog/Dialog'
import DialogActions from '../../../Common/Dialog/DialogActions'
import DialogContent from '../../../Common/Dialog/DialogContent'
import DialogTitle from '../../../Common/Dialog/DialogTitle'
import { DeletePaginationItem } from '../../../UserData/_actions/UserDataActions'
import { useTranslation } from 'react-i18next'
import { type UserAddressStore } from '../../../UserData/_stores/UserAddressStore'
import { useAppDispatch } from '../../../Common/_hooks/useAppDispatch'

interface Props {
  address: UserAddressStore
  opened: boolean
  onClose: () => void
}

export default function DeleteAddressDialog(props: Props) {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()

  const onConfirm = useCallback(() => {
    const fd = new FormData()
    fd.append('id', String(props.address.get('id')))

    dispatch(DeletePaginationItem(fd, 'addresses'))
    props.onClose()
  }, [props.address, props.onClose])

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <DialogTitle>{t('Delete address {{name}}', { name: props.address.get('name') })}</DialogTitle>
    <DialogContent>
      <p>{t('Are you sure that you want to delete this address?')}</p>
    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
      >{t('Cancel')}</DialogButton>
      <DialogButton
        color="error"
        onClick={onConfirm}
        autoFocus
      >{t('Delete')}</DialogButton>
    </DialogActions>
  </Dialog>
}
