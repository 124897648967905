import Immutable from "immutable";
import { IPaginationStore } from "./PaginationStore";
import { UserProductDesignStore } from "./UserProductDesignStore";
import PaginationItemListStore from "./PaginationItemListStore";

export interface IUserProductDesignListStore extends IPaginationStore {
	data: Immutable.Map<string, UserProductDesignStore>,
}

const defaultUserProductDesignListStore = Immutable.Record<IUserProductDesignListStore>({
	itemLists: Immutable.Map<string, PaginationItemListStore>(),
	data: Immutable.Map<string, UserProductDesignStore>(),
});

export default class UserProductDesignListStore extends defaultUserProductDesignListStore implements IUserProductDesignListStore {}