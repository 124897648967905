import i18next from 'i18next';
import { createAjaxAction } from '../../Common/_actions/AjaxAction';
import { Toast } from '../../UIData/_actions/UIDataActions';
import { InvalidatePaginationData } from './UserDataActions';

export enum UserImageActionType {
	UploadUserImage = 'USER_IMAGE_UPLOAD_USER_IMAGE'
}

export const UploadUserImage = (data:FormData) => createAjaxAction(UserImageActionType.UploadUserImage, {
	url: "ajax/api/user/images/upload-file",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => {
		dispatch(InvalidatePaginationData("images"));
	},
	onFailure: (response, dispatch) => {
		dispatch(Toast(i18next.t('Something went wrong!')));
	}
});

export type UserImageAction = 
	ReturnType<typeof UploadUserImage>