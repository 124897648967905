import * as Immutable from 'immutable';
import VariantPriceStore from '../../Dashboard/_stores/VariantPriceStore';
import PriceStore from '../../UIData/_stores/PriceStore';


export interface IListingStore {
	id: string,
	id_product_design: number
	id_product: number
	title: string
	description: string
	squarespace_store_page_id?: string
	price: PriceStore
	disabled_preview_ids: Immutable.List<number>
	disabled_option_ids: Immutable.List<number>
	variant_prices: Immutable.Map<string, VariantPriceStore>
	update_preset: boolean
	is_modified: boolean
}

const defaultStore = Immutable.Record<IListingStore>({
	id: "",
	id_product_design: 0,
	id_product: 0,
	title: "",
	description: "",
	squarespace_store_page_id: undefined,
	price: new PriceStore(),
	disabled_preview_ids: Immutable.List<number>(),
	disabled_option_ids: Immutable.List<number>(),
	variant_prices: Immutable.Map<string, VariantPriceStore>(),
	update_preset: false,
	is_modified: false,
});

export default class ListingStore extends defaultStore implements IListingStore {}

