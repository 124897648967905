import * as Immutable from 'immutable';
import { PaginationItem } from './PaginationStore'
import { DateTime } from 'luxon';

export type StoreItemPayload = {
	id: number
  store_id: string
  id_store_product: number
  id_store_variant: number
  id_cart_item: number
  product_name: string
  variant_name: string
  preview: string
  ignored: boolean
  quantity: number
}

interface IStoreItemStore {
	id: number
  store_id: string
  id_store_product: number
  id_store_variant: number
  id_cart_item: number
  product_name: string
  variant_name: string
  preview: string
  ignored: boolean
  quantity: number
}

const defaultStoreItemStore = Immutable.Record<IStoreItemStore>({
	id: 0,
  store_id: '',
  id_store_product: 0,
  id_store_variant: 0,
  id_cart_item: 0,
  product_name: '',
  variant_name: '',
  preview: '',
  ignored: false,
  quantity: 0,
});

export class StoreItemStore extends defaultStoreItemStore implements IStoreItemStore {}