import * as Immutable from 'immutable';

export interface IProductDetailValueStore {
	id: number,
    slug: string,
    name: string,
    data: Immutable.Map<string, string>,
}

const defaultProductDetailValueStore = Immutable.Record<IProductDetailValueStore>({
	id: 0,
    slug: '',
    name: '',
    data: Immutable.Map<string, string>(),
});

export default class ProductDetailValueStore extends defaultProductDetailValueStore implements IProductDetailValueStore {}