import { Button, Typography, Alert } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import CheckoutSummary from '../Summary/CheckoutSummary'
import CartContent from '../../Cart/CartContent/CartContent'
import CartListItems from '../../Cart/CartItem/CartListItems'
import CheckoutShippingOptions from './CheckoutShippingOptions'
import CheckoutAddressSelector from '../CheckoutAddressSelector'
import { Link } from 'react-router-dom'
import { CartStack } from '../../Cart/CartContent/CartStack'
import CartListStack from '../../Cart/CartContent/CartListStack'
import CartListGrid from '../../Cart/CartContent/CartListGrid'
import CartGridContainer from '../../Cart/CartContent/CartGridContainer'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import CheckoutContactInfo from './CheckoutContactInfo'
import type Immutable from 'immutable'

export interface Props {
  orders: Immutable.OrderedMap<string, UserCartStore>
  paymentUrl: string
}

export default function CheckoutShipping(props: Props) {
  const [t] = useTranslation('cart')

  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))
  const loggedIn = useAppSelector(state => state.get('userData').get('loggedIn'))
  const userEmail = useAppSelector(state => state.get('userData').get('email'))

  const countOrders = useMemo(() => {
    return props.orders.count()
  }, [props.orders])

  const renderCart = useCallback((cart: UserCartStore) => {
    return <CartContent
      key={cart.get('id')}
      cart={cart}
    >
      {!loggedIn ? <CheckoutContactInfo /> : null}
      <CheckoutAddressSelector
        cart={cart}
        hasMultipleCarts={countOrders > 1}
        type="shipping"
      />
      <CheckoutShippingOptions cart={cart} />
      <div>
        <CartStack style={{ paddingBottom: 0 }}>
          <Typography variant="h3" color="primary">{t('Overview')}</Typography>
        </CartStack>
        <CartListItems cart={cart} short={true} />
      </div>
    </CartContent>
  }, [t, loggedIn, countOrders])

  const validContactInfo = useMemo(() => {
    return loggedIn || userEmail !== ''
  }, [loggedIn, userEmail])

  const validShippingAddress = useMemo(() => {
    let valid = true
    props.orders.forEach(cart => {
      valid = valid && cart.get('id_shipping_type') > 0
    })
    return valid
  }, [props.orders])

  const valid = useMemo(() => {
    return validContactInfo && validShippingAddress
  }, [validContactInfo, validShippingAddress])

  return <CartGridContainer>
    <CartListGrid>
      <CartListStack>
        {props.orders.valueSeq().map(renderCart)}
      </CartListStack>
    </CartListGrid>

    <CheckoutSummary
      carts={props.orders}
      alert={!validContactInfo
        ? <Alert
          variant="outlined"
          severity="info"
        >{t('Confirm your contact information to continue.')}</Alert>
        : (!validShippingAddress
            ? <Alert
            variant="outlined"
            severity="info"
          >{t('Confirm your shipping address and select a shipping option to continue.')}</Alert>
            : null
          )
      }
      action={<Button
        color="accent"
        size="large"
        variant="contained"
        component={Link}
        to={props.paymentUrl}
        disabled={!!cartLoader || !valid}
      >{t('Continue to Payment')}</Button>}
    />
  </CartGridContainer>
}
