import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { EditProductListing, RemoveProductListing } from '../../Dashboard/_actions/DashboardActions';
import VariantPriceStore from '../../Dashboard/_stores/VariantPriceStore';
import ProductCard, { Props } from '../_components/ProductCard';
import PriceStore from '../../UIData/_stores/PriceStore';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => {
	let isSelected = props.item ? store.hasIn(['Dashboard','addProduct_listings',props.item.get('id')]) : false;

	return {
		product: props.item ? store.getIn(['appData','products', String(props.item.get('id_product'))]) : undefined,
		storePreset: props.item ? store.getIn(['userData', 'storePresets', props.item.get('id_product')]) : undefined,
		isSelected: isSelected,
		isModified: isSelected ? store.getIn(['Dashboard','addProduct_listings',props.item.get('id'),'is_modified']) : false,
		title: props.hasEditButton && isSelected ? store.getIn(['Dashboard','addProduct_listings',props.item.get('id'),'title']) : props.item.get('name')
	}
};

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	editProductListing: (
		id_product_design: number,
		id_product: number,
		title: string,
		description: string,
		price: PriceStore,
		update_preset: boolean,
		disabled_preview_ids?: Array<number>,
		disabled_option_ids?: Array<number>,
		variant_prices?: Immutable.Map<string, VariantPriceStore>,
		squarespace_store_page_id?: number,
	) => dispatch(EditProductListing(
		id_product_design,
		id_product,
		title,
		description,
		price,
		update_preset,
		false,
		disabled_preview_ids,
		disabled_option_ids,
		variant_prices,
		squarespace_store_page_id,
	)),
	removeProductListing: (id:string) => dispatch(RemoveProductListing(id)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProductCard);