const SendFD = (path: string, method: "post" | "get", fd: FormData) => {
    const form = document.createElement('form');
    form.method = method;
      form.action = path;

    fd.forEach((value, key) => {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = String(value);

        form.appendChild(hiddenField);
    });

    document.body.appendChild(form);
    form.submit();
}

export default SendFD;