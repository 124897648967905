import * as Immutable from 'immutable';
import PriceStore, { PricePayload } from '../../UIData/_stores/PriceStore';
import VariantPriceStore from '../../Dashboard/_stores/VariantPriceStore';

export type UserStorePresetPayload = {
	id: number
	id_product: number
	description: string
	price: PricePayload
	disabled_option_ids: number[]
	disabled_preview_ids: number[]
	variant_prices: {
		id: number
		price: PricePayload
		options_ids: number[]
	}[]
	default: boolean
}

export interface IUserStorePresetStore {
	id: number,
	id_product: number
	description: string
	price: PriceStore
	disabled_preview_ids: Immutable.List<number>
	disabled_option_ids: Immutable.List<number>
	variant_prices: Immutable.Map<string, VariantPriceStore>
	default: boolean
}

const defaultStore = Immutable.Record<IUserStorePresetStore>({
	id: 0,
	id_product: 0,
	description: "",
	price: new PriceStore(),
	disabled_preview_ids: Immutable.List<number>(),
	disabled_option_ids: Immutable.List<number>(),
	variant_prices: Immutable.Map<string, VariantPriceStore>(),
	default: false,
});

export default class UserStorePresetStore extends defaultStore implements IUserStorePresetStore {}

