import { Button as MuiButton, ButtonProps as MuiButtonProps, styled } from '@mui/material';


const StyledDialogButton = styled(MuiButton)({
    fontSize: 16,
    padding: '10px 30px',
    borderRadius: 0,
    height: 'auto',
});

type Props = MuiButtonProps & {}

export default function DialogButton(props: Props) {
    return <StyledDialogButton {...props} />
}