import { useCallback, useMemo, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { Avatar, IconButton, ListItemAvatar, ListItemText, Menu, MenuItem, Stack, Tooltip, styled } from '@mui/material';
import { ReactSVG } from 'react-svg';
import useLabData from '../_hooks/useLabData';
import { EditActiveOption } from '../../UIData/_actions/DesignLabStoreActions';
import { Color } from 'three';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import Immutable from 'immutable';
import LabOptionTool, { LabOptionValue } from './LabOptionTool';

const OPTION_IDS: Record<string, string> = {
  'color': '3',
  'fabric': '2',
  'zipper-color': '27',
  'zipper-pull': '28',
  'loop-color': '37',
};

type Props = {
  option: string,
}

export default function OptionTool(props: Props) {
  const activeProductId = useSelector((state: WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
  const optionType = useSelector((state: WebsiteStore) => state.get('appData').get('products').get(String(activeProductId))?.get('availableOptionTypes').get(OPTION_IDS[props.option]));
  const labData = useLabData();

  const listValues = useMemo(() => {
    let tmpListValues = Immutable.OrderedMap<string, LabOptionValue>()

    if (!labData || !optionType) return tmpListValues;

    const product = window.Products.getProduct(labData.id);

    optionType?.get('options').forEach(option => {
      const threeColor = product.getColor(option.get('slug')) || new Color(0x000000)

      tmpListValues = tmpListValues.set(option.get('slug'), new LabOptionValue({
        name: option.get('name'),
        slug: option.get('slug'),
        color: threeColor ? '#' + threeColor.getHexString() : undefined,
      }))
    })

    return tmpListValues
  }, [optionType, labData]);

  if (!optionType) return null

  return <LabOptionTool
    type="option"
    layout={optionType.get('slug') === 'fabric' ? 'img-badge' : 'color'}
    name={optionType.get('name')}
    option={props.option}
    listValues={listValues}
  />
}