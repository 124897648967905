import { Alert, Box, Button, Collapse, Container, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from "@mui/material";
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon } from '@mui/icons-material'
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import tKey from "../Common/Utils/tKey";
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { useAppDispatch } from "../Common/_hooks/useAppDispatch";
import { AuthActionType, NewPassword, ResendValidationLink } from "./_actions/AuthActions";
import AuthForm from "./AuthForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import useErrorText from "../Common/Error/useErrorText";
import { useAppSelector } from "../Common/_hooks/useAppSelector";

export default function ResetPasswordPage() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('auth')
  const [searchParams] = useSearchParams()

  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('auth'))

  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [done,setDone] = useState(false)

  const onChangeNewPassword = useCallback((e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value), [])
  const onChangeConfirmNewPassword = useCallback((e: ChangeEvent<HTMLInputElement>) => setConfirmNewPassword(e.target.value), [])
  const onClickShowPassword = useCallback(() => setShowPassword(value => !value), [])

  const formModified = useMemo(() => {
    return newPassword !== '' || confirmNewPassword !== ''
  }, [newPassword, confirmNewPassword])

  const validPasswords = useMemo(() => {
    return newPassword !== '' &&
      confirmNewPassword !== '' &&
      newPassword === confirmNewPassword
  }, [newPassword, confirmNewPassword])

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const token = searchParams.get('token') ?? ''

    dispatch(NewPassword(
      token,
      newPassword
    ).set({
      onSuccess: () => setDone(true)
    }))
  }, [newPassword, searchParams])

  const errorText = useErrorText(AuthActionType.NewPassword)

  return <Box component="div" sx={{
    backgroundImage: 'url(' + require('@resources/img/theme/page/ornaments-1.svg?url') + ')',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    pt: 6,
    pb: 15,
    flex: '1 0 auto',
  }}>
    <Container
      maxWidth="sm"
    >
      <Helmet>
        <title>{t('Reset your password')}</title>
      </Helmet>

      <Stack spacing={3}>
        <Typography variant="h1" textAlign="center">{t('Reset your password')}</Typography>
        <Paper
          elevation={0}
          sx={{
            p: 4,
          }}
        >
          <Collapse in={!done}>
            <form onSubmit={onSubmit}>
              <Stack spacing={2} alignItems="flex-start">
                <Typography variant="body1">{t('Please choose a new password for your account.')}</Typography>

                <TextField
                  label={t('New password')}
                  type={showPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={onChangeNewPassword}
                  error={formModified && newPassword === ''}
                  disabled={!!loader}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        onClick={onClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
                <TextField
                  label={t('Confirm new password')}
                  type={showPassword ? 'text' : 'password'}
                  value={confirmNewPassword}
                  onChange={onChangeConfirmNewPassword}
                  error={formModified && (confirmNewPassword === '' || confirmNewPassword !== newPassword)}
                  helperText={formModified && confirmNewPassword !== newPassword ? t('Passwords don\'t match') : ''}
                  disabled={!!loader}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        onClick={onClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />

                { !!errorText ? <Alert
                  severity="error"
                >{errorText}</Alert> : null}

                <Button
                  color="accent"
                  variant="contained"
                  size="large"
                  disabled={!validPasswords || !!loader}
                  type="submit"
                >{t('Confirm')}</Button>
              </Stack>
            </form>
          </Collapse>

          <Collapse in={done}>
            <Alert
              severity="success"
            >{t('Your password has been changed.')}</Alert>
          </Collapse>
        </Paper>
      </Stack>
    </Container>
  </Box>
}