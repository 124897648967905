import { useCallback, useEffect } from 'react';
import SelectBaseV2 from '../Form/SelectBaseV2';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { SaveUserPrefs } from '../../UserData/_actions/UserDataActions';
import { useAppDispatch } from '../_hooks/useAppDispatch';

type Props = {
    value?: number
    onChange?: (value:number) => void
}

export default function LimitSelect(props:Props) {
    const dispatch = useAppDispatch();
    const [t] = useTranslation();

    const user_limit = useSelector((state:WebsiteStore) => state.get('userData').get('prefs').get('accountPageLimit'));

    useEffect(() => {
        //Save preferences if it changed
        if(props.value === user_limit) {
            return;
        }

        const fd = new FormData();
        fd.append('accountPageLimit', String(props.value));

        dispatch(SaveUserPrefs(fd, true));
    }, [props.value]);

    const onChange = useCallback((e:SelectChangeEvent) => {
        props.onChange && props.onChange(Number(e.target.value));
    }, [props.onChange]);

    return <SelectBaseV2
        id="account-products-limit"
        value={props.value}
        onChange={onChange}
    >
        <MenuItem value={20}>{ t('{{count}} per page', { count: 20 }) }</MenuItem>
        <MenuItem value={50}>{ t('{{count}} per page', { count: 50 }) }</MenuItem>
        <MenuItem value={100}>{ t('{{count}} per page', { count: 100 }) }</MenuItem>
    </SelectBaseV2>
}