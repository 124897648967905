import { useContext, useEffect } from "react"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function PencilCase(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/pencil-case.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    
    // Apply lab texture
    useEffect(() => {
        if(!elements || !elements[0]?.material) return

        elements[0].material.map = productTexture
        elements[0].material.map.needsUpdate = true
    }, [productTexture])
    
    return <ModelElements elements={elements}/>
}