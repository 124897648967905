import { DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps, styled } from '@mui/material';


const StyledDialogTitle = styled(MuiDialogTitle)({
    paddingRight: '60px',
});

type Props = MuiDialogTitleProps & {}

export default function DialogTitle(props: Props) {
    return <StyledDialogTitle {...props} />
}