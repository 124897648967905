import Dinero from 'dinero.js';
import * as Immutable from 'immutable';
import * as React from 'react';
import DesignLabStore from './DesignLab/DesignLabStore';
import ProductsPageStore from './ProductsPageStore';
import UIToastStore, { IToast } from './UIToastStore';

export interface IUIDataStore {
	toast: UIToastStore,
	loaders: Immutable.Map<string, boolean>,
	validData: Immutable.Map<string, boolean>,
	apiErrors: Immutable.Map<string, Record<string,any>>
	scrollBlockers: Immutable.List<React.ReactNode>,
	intervals: Immutable.Map<string, ReturnType<typeof setInterval>>
	designLab : DesignLabStore,
	createUrl: string,
}

export const UIDataStoreFactory = Immutable.Record<IUIDataStore>({
	toast: new UIToastStore(),
	loaders: Immutable.Map<string, boolean>(),
	validData: Immutable.Map<string, boolean>(),
	apiErrors: Immutable.Map<string, Record<string,any>>(),
	scrollBlockers: Immutable.List<React.ReactNode>(),
	intervals: Immutable.Map<string, ReturnType<typeof setInterval>>(),
	designLab: new DesignLabStore(),
	createUrl: '',
});

export default class UIDataStore extends UIDataStoreFactory {}