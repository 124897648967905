import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Grid, IconButton, Menu, MenuItem, Paper, Tooltip, styled } from '@mui/material';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { HexColorPicker } from 'react-colorful';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import parseColor from '../../Common/Utils/Color/parseColor';
import rgbToHex from '../../Common/Utils/Color/rgbToHex';
import NumberInput from '../../Common/_components/NumberInput';
import ParsedTextInput from '../../Common/_components/ParsedTextInput';
import { AddImgLayer, AddPatternLayer, AddTextLayer, EditLayer, RemoveLayer, SelectLayer } from '../../UIData/_actions/DesignLabStoreActions';
import DesignLabLayerStore from '../../UIData/_stores/DesignLab/DesignLabLayerStore';
import WebsiteStore from '../../WebsiteStore';
import { LAB_FONTS, LAB_TEMPLATE_HEIGHT, LAB_TEMPLATE_WIDTH } from '../../config';
import RepeatTool from '../Tool/_components/RepeatTool';
import UpscaleTool from '../UpscaleTool/_components/UpscaleTool';
import useCalcFitTemplate from '../_hooks/useCalcFitTemplate';
import useCalcFitToSide from '../_hooks/useCalcFitToSide';
import useCheckDpi from '../_hooks/useCheckDpi';
import useSceneData from '../_hooks/useSceneData';
import PatternBrowser from './PatternBrowser';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import DeleteIcon from '../../Common/Icons/DeleteIcon';

const SideButton = styled(Button)({
    border: 0,
    outline: 0,
    width: '40px',
    minWidth: 0,
    flex: 1,
    borderRadius: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',

    '&:first-of-type': {
        borderTop: 0,
    }
})

const SideButtonsList = styled('div')({
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    height: '122px',
    overflow: 'hidden',
    borderRadius: '0 15px 15px 0',
})

const LayerContainer = styled('div')({
    position: 'relative',
    width: '230px',
    flexShrink: 0,

    '&.dragging': {
        zIndex: 20,
    },

    '&:focus': {
        outline: 0,
    }
})

const LayerCard = styled(Paper)(({theme}) => ({
    marginBottom: '10px',
    border: '1px solid transparent',
    borderRadius: '15px',
    flexShrink: '0',
    transition: 'border-color 150ms',

    '&.selected': {
        borderColor: '#ea008a',

        [`${SideButtonsList}`]: {
            borderRadius: '0 15px 0 0',
        }
    },

    ['@media (max-width: 1510px) and (min-height: '+theme.height_breakpoints.values.lab_mobile+'px), (max-height: 1070px) and (max-width: 953px)']: {
        marginBottom: 0,
    },
}));

const LayerCardContent = styled('div')({
    padding: '10px',
    marginRight: '40px',
    display: 'flex',
    flexDirection: 'column',
    height: '120px',
    justifyContent: 'space-between',

    '&.dragging': {
        cursor: 'grabbing',
    }
})

const LayerImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
})

const LayerTextInput = styled('input')({
    background: '#f4f4f4',
    borderRadius: '20px',
    height: '36px',
    fontSize: '14px',
    padding: '0 10px',
    color: '#111',
    width: '100%',
    boxSizing: 'border-box',
    border: 0,
    outline: 0,
    display: 'block',
})

const ToolsList = styled('div')({
    position: 'relative',
    background: '#f4f4f4',
    display: 'flex',
    flex: 1,
    width: '100%',
    overflow: 'hidden',
    borderRadius: '0px 0px 15px 15px',
    height: 0,
    opacity: 0,
    transition: 'height 150ms, opacity 150ms, padding 150ms',
    boxSizing: 'border-box',
    padding: 0,

    '&.opened': {
        height: '34px',
        opacity: 1,
    }
})

const TextToolsList = styled(ToolsList)({
    padding: '0 10px',
    gap: '10px',

    '&.opened': {
        padding: '5px 10px',
    },
})

const ToolButton = styled(Button)({
    border: 0,
    outline: 0,
    width: '25%',
    minWidth: 0,
    borderRadius: 0,
    flexShrink: 0,
})

const TextToolButton = styled(Button)({
    borderRadius: '20px',
    width: '50%',
    background: 'rgba(0,0,0,0.1)',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: 'inherit',
    textAlign: 'left',
    minWidth: 0,
    justifyContent: 'flex-start',
    padding: '0 5px 0 0',
})

const TextToolIcon = styled('div')({
    width: '22px',
    height: '22px',
    borderRadius: '20px',
    marginRight: '5px',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
})

const TextToolTitle = styled('div')({
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#000',
})

export const PropertyPanel = styled(Paper)({
    position: 'absolute',
    top: 0,
    left: 0,
    boxSizing: 'border-box',
    background: '#414141',
    color: '#fff',
    width: '100%',
    padding: '10px 35px 10px 15px',
    height: '100%',
    transition: 'transform 200ms, opacity 200ms',
    opacity: 0,
    transform: 'scaleY(0)',
    transformOrigin: 'bottom',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 20,
    borderRadius: '15px',

    '&.opened': {
        transform: 'scaleY(1)',
        opacity: 1,
    }
})

export const PropertyColumnWrap = styled('div')({
    height: '100%',

    '&.left': {
        borderRight: '1px solid rgba(255, 255, 255, 0.1)',
    }
})

export const PropertyH5 = styled('h5')({
    margin: 0,
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: '5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
})

const PropertyTextInput = styled(ParsedTextInput)({
    background: '#515151',
    borderRadius: '20px',
    height: '28px',
    fontSize: '12px',
    padding: '0 10px',
    color: '#fff',
    width: '70px',
    boxSizing: 'border-box',
    border: 0,
    outline: 0,
    display: 'block',
    marginBottom: '10px',
})

const PropertyNumberInput = styled(NumberInput)({
    background: '#515151',
    borderRadius: '20px',
    height: '28px',
    fontSize: '12px',
    padding: '0 10px',
    color: '#fff',
    width: '70px',
    boxSizing: 'border-box',
    border: 0,
    outline: 0,
    display: 'block',
    marginBottom: '10px',
})

const PropertyLabel = styled('label')({
    color: '#8e8e8e',
    fontSize: '12px',
    display: 'block',
})

const PanelCloseButton = styled(IconButton)({
    position: 'absolute',
    top: '-6px',
    right: '-6px',
    color: '#fff',
})

const FontContainer = styled('div')({
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
})

const FontButton = styled(Button)({
    textTransform: 'none',
    background: '#515151',
    borderRadius: '20px',
    fontSize: '12px',
    padding: '0 10px',
    height: '28px',
    color: '#fff',
    width: 'calc(50% - 3px)',
})

type Props = {
    layer: DesignLabLayerStore
}

export default function Layer(props:Props) {
    const [t] = useTranslation('design-lab');
    const dispatch = useAppDispatch();
    const isSelected = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('selectedLayer') === props.layer.get('id'));
    const sceneData = useSceneData();
    const checkDpi = useCheckDpi();
    const [openedPanel, setOpenedPanel] = useState<'none'|'warning'|'properties'|'font'|'color'|'repeat'>('none');
    
    const moveHotkey = useCallback((data: { x?: number, y?: number }) => {
        return (e:KeyboardEvent) => {
            if(!isSelected) return;
            e.preventDefault();

            dispatch(EditLayer(props.layer.get('id'), data));
        }
    }, [props.layer, isSelected])

    const duplicateLayer = useCallback(() => {
        if(props.layer.get('type') === 'img') {
            dispatch(AddImgLayer({
                ...props.layer.toObject(),
                x: props.layer.get('x') + 20,
                y: props.layer.get('y') + 20,
            }))
        } else if(props.layer.get('type') === 'pattern') {
            dispatch(AddPatternLayer({
                ...props.layer.toObject(),
                x: props.layer.get('x') + 20,
                y: props.layer.get('y') + 20,
            }))
        } else if(props.layer.get('type') === 'text') {
            dispatch(AddTextLayer({
                ...props.layer.toObject(),
                x: props.layer.get('x') + 20,
                y: props.layer.get('y') + 20,
            }))
        }
    }, [props.layer]);

    useHotkeys('up', moveHotkey({y: props.layer.get('y') - 1}), {
        scopes: ['design-lab']
    });
    useHotkeys('shift+up', moveHotkey({y: props.layer.get('y') - 10}), {
        scopes: ['design-lab']
    });
    useHotkeys('down', moveHotkey({y: props.layer.get('y') + 1}), {
        scopes: ['design-lab']
    });
    useHotkeys('shift+down', moveHotkey({y: props.layer.get('y') + 10}), {
        scopes: ['design-lab']
    });
    useHotkeys('left', moveHotkey({x: props.layer.get('x') - 1}), {
        scopes: ['design-lab']
    });
    useHotkeys('shift+left', moveHotkey({x: props.layer.get('x') - 10}), {
        scopes: ['design-lab']
    });
    useHotkeys('right', moveHotkey({x: props.layer.get('x') + 1}), {
        scopes: ['design-lab']
    });
    useHotkeys('shift+right', moveHotkey({x: props.layer.get('x') + 10}), {
        scopes: ['design-lab']
    });

    useHotkeys('delete', (e) => {
        if(!isSelected) return;
        e.preventDefault();

        dispatch(RemoveLayer(props.layer.get('id')));
        dispatch(SelectLayer(null));
    }, {
        scopes: ['design-lab']
    });

    useHotkeys('ctrl+j', (e) => {
        if(!isSelected) return;
        e.preventDefault();

        duplicateLayer();
    }, {
        scopes: ['design-lab']
    });

    const { dpi, minDpi, dpiIndicator } = useMemo(() => {
        return checkDpi(props.layer);
    }, [props.layer, checkDpi]);

    const calcFitTemplate = useCalcFitTemplate();
    const fitTemplate = useCallback((layer:DesignLabLayerStore) => {
        const data = calcFitTemplate(layer.get('width'), layer.get('height'));

        dispatch(EditLayer(layer.get('id'), data));
    }, [calcFitTemplate]);

    const calcFitToSide = useCalcFitToSide();
    const fitToSide = useCallback((layer:DesignLabLayerStore, side:'left'|'right'|'top'|'bottom') => {
        const data = calcFitToSide(layer.get('width'), layer.get('height'), side);

        dispatch(EditLayer(layer.get('id'), data));
    }, [calcFitToSide]);

    useEffect(() => {
        if(!isSelected) {
            setOpenedPanel('none');
        }
    }, [isSelected])

    const [patternBrowserOpened, setPatternBrowserOpened] = useState(false);
    const [fontAnchorEl, setFontAnchorEl] = React.useState<null | HTMLElement>(null);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
      } = useSortable({id: props.layer.get('id')});

    if(transform) {
        transform.scaleY = 1;
    }

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    } as CSSProperties;

    useEffect(() => {
        if(isDragging) {
            setOpenedPanel('none');
        }
    }, [isDragging])

    return <LayerContainer
        ref={setNodeRef}
        style={style}
        className={isDragging ? 'dragging':''}
        { ...attributes }
        onMouseDown={(e) => {
            if(e.target instanceof Element && e.target.closest('.btn-delete')) {
                return;
            }

            if(!isSelected) {
                dispatch(SelectLayer(props.layer.get('id')));
            }
        }}
    >
        <UpscaleTool
            active={dpiIndicator === 'bad'}
            opened={isSelected && openedPanel === 'warning'}
            onOpen={() => setOpenedPanel(openedPanel === 'warning' ? 'none' : 'warning')}
            onClose={() => setOpenedPanel('none')}
            layer={props.layer}
        />
        <PropertyPanel elevation={4} className={isSelected && openedPanel === 'properties' ? 'opened':''}>
            <PanelCloseButton
                onClick={() => setOpenedPanel('none')}
                size="large"
            >
                <CloseIcon/>
            </PanelCloseButton>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <PropertyColumnWrap className="left">
                        <PropertyH5>{ t('Position') }</PropertyH5>
                        <PropertyLabel htmlFor={"layer"+props.layer.get('id')+"_x"}>X</PropertyLabel>
                        <PropertyNumberInput 
                            id={"layer"+props.layer.get('id')+"_x"} 
                            value={props.layer.get('x')}
                            onChange={(value) => {
                                dispatch(EditLayer(props.layer.get('id'), {
                                    x: value,
                                }));
                            }}
                        />
                        
                        <PropertyLabel htmlFor={"layer"+props.layer.get('id')+"_y"}>Y</PropertyLabel>
                        <PropertyNumberInput 
                            id={"layer"+props.layer.get('id')+"_y"} 
                            value={props.layer.get('y')}
                            onChange={(value) => {
                                dispatch(EditLayer(props.layer.get('id'), {
                                    y: value,
                                }));
                            }}
                        />
                    </PropertyColumnWrap>
                </Grid>
                <Grid item xs={6}>
                    <PropertyColumnWrap className="right">
                        <PropertyH5>{ t('Transform') }</PropertyH5>
                        <PropertyLabel htmlFor={"layer"+props.layer.get('id')+"_rotation"}>{ t('Rotation') }</PropertyLabel>
                        <PropertyNumberInput 
                            id={"layer"+props.layer.get('id')+"_rotation"} 
                            value={props.layer.get('rotation')}
                            onChange={(value) => {
                                dispatch(EditLayer(props.layer.get('id'), {
                                    rotation: value,
                                }));
                            }}
                        />

                        <PropertyLabel htmlFor={"layer"+props.layer.get('id')+"_scale"}>{ t('Scale') }</PropertyLabel>
                        <PropertyNumberInput 
                            id={"layer"+props.layer.get('id')+"_scale"} 
                            value={props.layer.get('scaleX')}
                            onChange={(value) => {
                                dispatch(EditLayer(props.layer.get('id'), {
                                    scaleX: value,
                                    scaleY: value,
                                }));
                            }}
                        />
                    </PropertyColumnWrap>
                </Grid>
            </Grid>
        </PropertyPanel>

        <PropertyPanel elevation={4} className={isSelected && openedPanel === 'color' ? 'opened':''}>
            <PanelCloseButton
                onClick={() => setOpenedPanel('none')}
            >
                <CloseIcon/>
            </PanelCloseButton>
            <div style={{
                display: 'flex',
                height: '100%',
                gap: '10px',
            }}>
                <div>
                    <PropertyH5>{ t('Color') }</PropertyH5>
                    <PropertyLabel htmlFor={"layer"+props.layer.get('id')+"_color"}>{ t('Hex code') }</PropertyLabel>
                    <PropertyTextInput
                        id={"layer"+props.layer.get('id')+"_color"} 
                        value={props.layer.get('color')}
                        parse={(value) => {
                            try {
                                const colors = parseColor(value);
                                return rgbToHex(colors[0], colors[1], colors[2]);
                            } catch(e) {
                                //Default to black if invalid color
                                return '#000000';
                            }
                        }}
                        onChange={(newValue) => {
                            dispatch(EditLayer(props.layer.get('id'), {
                                color: newValue
                            }))
                        }}
                    />
                </div>
                <HexColorPicker 
                    style={{
                        height: 'auto',
                    }}
                    color={props.layer.get('color')}
                    onChange={(newColor) => {
                        dispatch(EditLayer(props.layer.get('id'), {
                            color: newColor
                        }))
                    }}
                />
            </div>
        </PropertyPanel>
        <LayerCard
            elevation={4}
            className={isSelected ? 'selected ':''}
        >
            <LayerCardContent
                className={isDragging ? 'dragging':''}
            >
                <div style={{
                    display: 'flex'
                }}>
                    { props.layer.get('type') === 'img' || props.layer.get('type') === 'pattern' ? <div style={{
                            width: '54px',
                            height: '54px',
                            marginRight: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            flexShrink: 0,
                            cursor: 'grab',
                        }}
                        { ...listeners }
                    >
                        <LayerImage src={props.layer.get('src')} />
                    </div> : null }

                    { props.layer.get('type') === 'text' ? <div style={{minWidth: 0}}>
                        <h4 style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                textShadow: 'rgba(0, 0, 0, 0.5) 0px 2px 3px',
                                padding: '10px',
                                margin: '-10px -10px 0px',
                                color: props.layer.get('color'),
                                fontFamily: '"'+props.layer.get('font')+'", sans-serif',
                                fontSize: '24px',
                                cursor: 'grab',
                                height: '55px',
                            }}
                            { ...listeners }
                        >{ props.layer.get('text') }</h4>
                        <LayerTextInput
                            placeholder={"Enter your text here"}
                            value={props.layer.get('text')}
                            onChange={(e) => {
                                dispatch(EditLayer(props.layer.get('id'), {
                                    text: e.target.value,
                                    width: 0, //This is used to force recalculating width/height of text
                                    height: 0,
                                }))
                            }}
                        />
                    </div> : null }
                    <div>
                        { props.layer.get('type') === 'img' ? <span>
                            <div style={{
                                fontSize: '12px',
                                textTransform: 'uppercase',
                                color: '#999',
                            }}>{ t('Quality (DPI)') }</div>
                            <strong
                                style={{
                                    color: dpiIndicator === 'good' ? '#4caf50' : (dpiIndicator === 'ok' ? '#ff9800' : '#f44336')
                                }}
                            >{ dpi }</strong>
                        </span> : null }

                        { props.layer.get('type') === 'pattern' ? <Button
                            onClick={() => {
                                setPatternBrowserOpened(true);
                            }}
                        >{ t('Change pattern') }</Button> : null }

                        { props.layer.get('type') === 'pattern' ? <PatternBrowser
                            opened={patternBrowserOpened}
                            onSelect={(item) => {
                                dispatch(EditLayer(props.layer.get('id'), {
                                    fileid: item.get('id'),
                                    src: item.get('repeat_src'),
                                    originalWidth: item.get('repeat_width'),
                                    originalHeight: item.get('repeat_height'),
                                    width: item.get('repeat_width'),
                                    height: item.get('repeat_height'),
                                }))
                            }}
                            onClose={() => {
                                setPatternBrowserOpened(false);
                            }}
                        /> : null }
                    </div>
                </div>
                <div>
                    { ['img','pattern'].includes(props.layer.get('type')) ? <RepeatTool 
                        opened={isSelected && openedPanel === 'repeat'}
                        onOpen={() => setOpenedPanel(openedPanel === 'repeat' ? 'none' : 'repeat')}
                        onClose={() => setOpenedPanel('none')}
                        layer={props.layer}
                    /> : null }
                </div>
            </LayerCardContent>
            <SideButtonsList>
                <Tooltip title={t('Delete')} placement="right">
                    <SideButton
                        color="secondary"
                        className={"btn-delete"}
                        onClick={() => {
                            dispatch(RemoveLayer(props.layer.get('id')));
                            if(isSelected) {
                                dispatch(SelectLayer(null));
                            }
                        }}
                    ><DeleteIcon/></SideButton>
                </Tooltip>

                <Tooltip title={t('Duplicate')} placement="right">
                    <SideButton 
                        color="secondary"
                        className={"btn-duplicate"}
                        onClick={duplicateLayer}
                    >
                        <img src={require('@resources/img/create/design-lab-v4/icons/duplicate.svg?url')} style={{height: '18px'}}/>
                    </SideButton>
                </Tooltip>

                <Tooltip title={t('Properties')} placement="right">
                    <SideButton 
                        color="secondary"
                        className={"btn-properties"}
                        onClick={() => {
                            setOpenedPanel(openedPanel === 'properties' ? 'none' : 'properties');
                        }}
                    >
                        <img src={require('@resources/img/create/design-lab-v4/icons/layer-properties.svg?url')} style={{height: '18px'}}/>
                    </SideButton>
                </Tooltip>
            </SideButtonsList>
            
            { props.layer.get('type') === 'img' || props.layer.get('type') === 'pattern' ? <ToolsList className={isSelected && !isDragging  ? 'opened':''}>
                { sceneData?.tools.includes('fit_side') ? <Tooltip title={t('Fit to Left Template')} placement="bottom">
                    <ToolButton
                        onClick={() => {
                            fitToSide(props.layer, 'left');
                        }}
                    ><img src={require('@resources/img/create/design-lab-v4/icons/fit-left.svg?url')} style={{height: '22px'}}/></ToolButton>
                </Tooltip> : null }

                { sceneData?.tools.includes('fit_side') ? <Tooltip title={t('Fit to Right Template')} placement="bottom">
                    <ToolButton
                        onClick={() => {
                            fitToSide(props.layer, 'right');
                        }}
                    ><img src={require('@resources/img/create/design-lab-v4/icons/fit-right.svg?url')} style={{height: '22px'}}/></ToolButton>
                </Tooltip> : null }

                { sceneData?.tools.includes('fit_center') ? <Tooltip title={t('Fit to Template')} placement="bottom">
                    <ToolButton
                        onClick={() => {
                            fitTemplate(props.layer);
                        }}
                    ><img src={require('@resources/img/create/design-lab-v4/icons/fit-template.svg?url')} style={{height: '18px'}}/></ToolButton>
                </Tooltip> : null }

                { sceneData?.tools.includes('fit_center') ? <Tooltip title={t('Center')} placement="bottom"><ToolButton
                    onClick={() => {
                        dispatch(EditLayer(props.layer.get('id'), {
                            x: LAB_TEMPLATE_WIDTH/2,
                            y: LAB_TEMPLATE_HEIGHT/2,
                        }))
                    }}
                ><img src={require('@resources/img/create/design-lab-v4/icons/center.svg?url')} style={{height: '22px'}}/></ToolButton>
                </Tooltip> : null }
                
                { /* <ToolButton
                    onClick={() => {
                        dispatch(EditLayer(props.layer.get('id'), {
                            rotation: props.layer.get('rotation') - 90
                        }))
                    }}
                ><img src={require('@resources/img/create/design-lab-v4/icons/rotate-right.svg?url')} style={{height: '22px'}}/></ToolButton>
                <ToolButton
                    onClick={() => {
                        dispatch(EditLayer(props.layer.get('id'), {
                            rotation: props.layer.get('rotation') + 90
                        }))
                    }}
                ><img src={require('@resources/img/create/design-lab-v4/icons/rotate-left.svg?url')} style={{height: '22px'}}/></ToolButton> */ }
            </ToolsList> : null }

            { props.layer.get('type') === 'text' ? <TextToolsList className={isSelected && !isDragging  ? 'opened':''}>
                <TextToolButton
                    onClick={() => setOpenedPanel(openedPanel === 'color' ? 'none' : 'color')}
                >
                    <TextToolIcon style={{
                        backgroundColor: props.layer.get('color')
                    }}/>
                    <TextToolTitle>{ props.layer.get('color') }</TextToolTitle>
                </TextToolButton>
                <TextToolButton
                    onClick={(e) => {
                        //setOpenedPanel(openedPanel === 'font' ? 'none' : 'font')
                        setFontAnchorEl(e.currentTarget);
                    }}
                >
                    <TextToolIcon>
                        <img src={require('@resources/img/create/design-lab-v4/icons/text-font.svg?url')} style={{height: '14px'}}/>
                    </TextToolIcon>
                    <TextToolTitle>{ props.layer.get('font') }</TextToolTitle>
                </TextToolButton>
                <Menu
                    anchorEl={fontAnchorEl}
                    open={Boolean(fontAnchorEl)}
                    onClose={() => setFontAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    { LAB_FONTS.map(font => {
                        //Remove appended weight
                        let name = font;
                        if(font.indexOf(':') !== -1) {
                            name = font.substring(0, font.indexOf(':'));
                        }
                        return <MenuItem
                            key={font}
                            style={{
                                fontFamily: name
                            }}
                            onClick={() => {
                                dispatch(EditLayer(props.layer.get('id'), {
                                    font: name,
                                    width: 0,
                                    height: 0,
                                }));
                                setFontAnchorEl(null);
                            }}
                        >{ name }</MenuItem>
                    }) }
                </Menu>
            </TextToolsList> : null }
            
        </LayerCard>
    </LayerContainer>
}