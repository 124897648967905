import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import WarningIcon from '@mui/icons-material/Warning';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Trans } from 'react-i18next';

type Props = {
	title:React.ReactNode
	message?:React.ReactNode
	actions?:{[name:string]:Function}
	input?:Function
	name?:string
	type?:string
	opened?:boolean
	dismissible: boolean
	dismissibleString?: React.ReactNode
	onClose?:Function
	onChange?:Function
	initialValue?:any
	messageType?: PromptMessageTypes
}

export enum PromptMessageTypes {
	PromptMessageTypeWarning 	= "WARNING",
	PromptMessageTypeError 		= "ERROR",
	PromptMessageTypeNormal 	= "NORMAL",
	PromptMessageTypeHelp 		= "HELP",
	PromptMessageTypeEdit 		= "EDIT",
	PromptMessageTypeSuccess 	= "SUCCESS",
}

const T = ({children, ...otherProps}:any) => <Trans>{children}</Trans>

export default class PromptContainer extends React.Component<Props> {
	private inputRef:HTMLDivElement;
	private portal:HTMLDivElement;
	static defaultProps:Props = {
		title: "",
		dismissible: true,
		messageType: PromptMessageTypes.PromptMessageTypeNormal
	}

	constructor(props:Props){
		super(props);
		this.getPortal();
	}

	private getPortal(){
		if(typeof document === 'undefined') return;

		this.portal = document.getElementById("modal-portal") as HTMLDivElement || (() => {
			const portal = document.createElement("div");
			portal.setAttribute("id", "modal-portal");
			document.body.appendChild(portal);
			return portal;
		})();
	}

	private onInput = (e:React.FormEvent) => {
		e.preventDefault();
		if(!this.props.input || !this.inputRef) return;
		let input = this.inputRef.querySelector('input');
		input && this.props.input(input.value);
		this.onClose();
		return false;
	}

	private handleChange = (e:React.FormEvent) => {
		e.preventDefault();
		if(!this.props.onChange) return;
		this.props.onChange(e.target.value);
	}

	private stopMouseEvent = (e:React.MouseEvent) => {
		e.stopPropagation();
	}

	private getIcon() {
		let messageType = this.props.messageType ? this.props.messageType : PromptMessageTypes.PromptMessageTypeNormal;
		if (messageType == PromptMessageTypes.PromptMessageTypeSuccess) {
			return <CheckIcon />
		} else if (messageType == PromptMessageTypes.PromptMessageTypeError) {
			return <ErrorIcon />
		} else if (messageType == PromptMessageTypes.PromptMessageTypeWarning) {
			return <WarningIcon />
		} else if (messageType == PromptMessageTypes.PromptMessageTypeHelp) {
			return <LiveHelpIcon />
		}  else if (messageType == PromptMessageTypes.PromptMessageTypeEdit) {
			return <EditIcon />
		}
		return <></>;
	}

	private onClose() {
		if (this.props.dismissible && this.props.onClose) {
			this.props.onClose()
		}
	}

	render() {
		if(!this.portal) return <></>;

		let closeAction = this.props.dismissible ? 'close' : '';
		return <> { ReactDOM.createPortal(this.props.opened ? 
				<Dialog className={"af-prompt prompt-" + this.props.messageType} open={this.props.opened} onClose={() => this.onClose()} onMouseDown={(e:React.MouseEvent) => this.stopMouseEvent(e)}>
					<DialogTitle className="af-prompt-header" >
						<div className="af-prompt-header-title" >
							{this.getIcon()}
							{this.props.title}
						</div>
						{this.props.dismissible && <CloseIcon className="af-prompt-close-icon" onClick={() => this.onClose()} />}
					</DialogTitle>
					<DialogContent>
						{this.props.message &&
							<p>{this.props.message}</p>
						}
						{ (this.props.input || this.props.onChange) && 
							<TextField inputProps={{autoFocus: true}} type={"default"} ref={ref => this.inputRef = ref} onChange={(e) => {this.handleChange(e)}} id={"prompt_input_"+this.props.title} label="Name" defaultValue={this.props.name} variant="filled" />
						}
					</DialogContent>
					<DialogActions>
						{this.props.dismissible && <button  type="button" onClick={() => this.onClose()} className="btn-flat waves-effect">{this.props.dismissibleString || <T>Cancel</T>}</button>}
						{this.props.actions && Object.keys(this.props.actions).map(name => {
								let action = this.props.actions[name];
								return <button type="button" onClick={()=>action()} key={name} className="btn-flat waves-effect">{name}</button>
						})}
						{this.props.input && <button type="button" onClick={this.onInput} className="btn-flat waves-effect"><T>Confirm</T></button>}
					</DialogActions>
				</Dialog>
			: null, this.portal) }
		</>
	}
}