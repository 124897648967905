import { Stack, styled } from '@mui/material';
import { useCallback } from 'react';
import useLabData from '../_hooks/useLabData';
import OptionTool from './OptionTool';
import DetailTool from './DetailTool';

type Props = {}

export default function OptionSelectors(props:Props) {
    const labData = useLabData();

    const renderOption = useCallback((option:string) => {
        if(!labData) return null
        const type = labData.options[option];

        return type == 'product-option' ? <OptionTool
            key={option}
            option={option}
        /> 
        : (type == 'product-detail' ? <DetailTool
            key={option}
            option={option}
        /> : null)
    }, [labData]);

    return <Stack direction="row" spacing={2}>
        { labData?.options ? Object.keys(labData.options).map(renderOption) : null }
    </Stack>
}