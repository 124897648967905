import { Collapse, Divider, Stack } from '@mui/material'
import CartItem from './CartItem'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { TransitionGroup } from 'react-transition-group'
import { useCallback, useMemo } from 'react'
import { type BaseItem } from '../../UserData/_stores/UserCartStore'
import { type StoreItemStore } from '../../UserData/_stores/StoreItemStore'
import StoreItem from './StoreItem'

export interface Props {
  cart: UserCartStore
  short?: boolean
  showReorder?: boolean
}

export default function CartListItems(props: Props) {
  const storeItems = useMemo(() => {
    return props.cart.get('storeOrder')?.get('listItems').valueSeq().toArray() ?? []
  }, [props.cart])

  const sortedItems = useMemo(() => {
    return props.cart.get('items')
      .filter(item => {
        return storeItems.find(storeItem => storeItem.get('id_cart_item') === item.get('id')) === undefined
      })
      .valueSeq()
      .sort((a, b) => a.get('type') === 'product' ? -1 : 1)
      .toArray()
  }, [props.cart, storeItems])

  const renderItem = useCallback((item: BaseItem) => {
    return <Collapse key={item.get('id')}>
      <CartItem
        cart={props.cart}
        item={item}
        short={props.short}
        showReorder={props.showReorder}
      />
    </Collapse>
  }, [props.cart, props.short, props.showReorder])

  const renderStoreItem = useCallback((storeItem: StoreItemStore) => {
    return <Collapse key={storeItem.get('id')}>
      <StoreItem
        cart={props.cart}
        storeItem={storeItem}
        short={props.short}
      />
    </Collapse>
  }, [props.cart, props.short])

  return <Stack
    divider={<Divider/>}
  >
    { storeItems.length > 0 ? <TransitionGroup
      component={Stack}
      divider={<Divider />}
    >
      {storeItems.map(renderStoreItem) }
    </TransitionGroup> : null }

    { sortedItems.length > 0 ? <TransitionGroup
      component={Stack}
      divider={<Divider />}
    >
      {sortedItems.map(renderItem) }
    </TransitionGroup> : null }
  </Stack>
}
