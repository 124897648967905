import { Button, styled } from '@mui/material'
import classnames from 'classnames'

const TopNotch = styled('div')({
  width: '30px',
  height: '15px',
  backgroundColor: '#fff',
  borderBottomLeftRadius: '17px',
  borderBottomRightRadius: '17px',
  border: '2px solid #c9c9c9',
  borderTop: '0',
  position: 'absolute',
  top: '0',
  left: '98px',
  transition: 'border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
})

const BottomNotch = styled('div')({
  width: '30px',
  height: '15px',
  backgroundColor: '#fff',
  borderTopLeftRadius: '17px',
  borderTopRightRadius: '17px',
  border: '2px solid #c9c9c9',
  borderBottom: '0',
  position: 'absolute',
  bottom: '0',
  left: '98px',
  transition: 'border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
})

const CouponContent = styled(Button)(({ theme }) => ({
  width: '100%',
  border: '2px solid #c9c9c9',
  borderRadius: '15px',
  backgroundColor: '#f6f6f6',
  display: 'flex',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  textAlign: 'left',
  padding: '0',

  '&:hover': {
    backgroundColor: '#f6f6f6',
    borderColor: '#c9c9c9'
  },

  '&.hasClick:hover': {
    backgroundColor: '#e9e9e9',
    borderColor: '#c9c9c9'
  }
}))

const CouponLeft = styled('div')(({ theme }) => ({
  borderRight: '2px dashed #c9c9c9',
  padding: theme.spacing(1.5, 1),
  width: '112px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0
}))

const CouponImg = styled('img')({
  width: '90px',
  height: '90px',
  objectFit: 'contain',
  display: 'block'
})

const CouponRight = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '15px'
})

const CouponText = styled('div')({
  color: '#939393',
  fontSize: '12px',
  fontWeight: '500',
  letterSpacing: '0',
  lineHeight: '1.5',

  '> span': {
    color: '#2d2d2d',
    fontSize: '22px',
    lineHeight: '1',
    marginRight: '4px'
  }
})

const CouponWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '350px',

  '&.fullWidth': {
    width: '100%'
  },

  '&.small': {
    [`${CouponLeft}`]: {
      width: '92px'
    },

    [`${TopNotch}`]: {
      left: '78px'
    },

    [`${BottomNotch}`]: {
      left: '78px'
    },

    [`${CouponImg}`]: {
      width: '70px',
      height: '70px'
    },

    [`${CouponText}`]: {
      fontSize: '11px',

      '> span': {
        fontSize: '16px'
      }
    }
  },

  '&.selected': {
    [`${CouponContent}`]: {
      borderColor: theme.palette.accent.main
    },

    [`${TopNotch}`]: {
      borderColor: theme.palette.accent.main
    },

    [`${BottomNotch}`]: {
      borderColor: theme.palette.accent.main
    }
  }
}))

export interface CouponProps {
  img: string
  htmlText: string
  fullWidth?: boolean
  small?: boolean
  selected?: boolean
  onClick?: () => void
}

export default function Coupon(props: CouponProps) {
  return <CouponWrapper
    className={classnames({
      fullWidth: props.fullWidth,
      selected: props.selected,
      hasClick: props.onClick,
      small: props.small
    })}
  >
    <CouponContent
      variant="outlined"
      color="secondary"
      disabled={!props.onClick}
      onClick={props.onClick}
    >
      <CouponLeft>
        <CouponImg src={props.img} />
      </CouponLeft>
      <CouponRight>
        <CouponText dangerouslySetInnerHTML={{ __html: props.htmlText }} />
      </CouponRight>
    </CouponContent>

    <TopNotch/>
    <BottomNotch/>
  </CouponWrapper>
}
