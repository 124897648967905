import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useCallback, useState } from 'react'
import * as React from 'react'
import DialogContent from '../../../Common/Dialog/DialogContent'
import DialogActions from '../../../Common/Dialog/DialogActions'
import DialogButton from '../../../Common/Buttons/DialogButton'
import { useTranslation } from 'react-i18next'
import { Alert, Stack } from '@mui/material'
import { CreateCreditCard, SetupIntentCreditCard } from '../../../UserData/_actions/UserCreditCardActions'
import { useAppDispatch } from '../../../Common/_hooks/useAppDispatch'
import { type StripePaymentElementChangeEvent } from '@stripe/stripe-js'

interface Props {
  setDisabled?: (value: boolean) => void
  onClose: () => void
}

export default function AddCreditCardForm(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')
  const [tR] = useTranslation('routes')

  const stripe = useStripe()
  const elements = useElements()

  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState('')

  const handleError = useCallback((error: string) => {
    setDisabled(false)
    setError(error)
  }, [])

  const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    void (async () => {
      e.preventDefault()

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }

      setDisabled(true)

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit()

      if (submitError) {
        console.log(submitError)
        handleError(submitError.message ?? t('An error occured. Please try again later.'))
        return
      }

      // Create the SetupIntent and obtain clientSecret
      dispatch(SetupIntentCreditCard().set({
        onSuccess: (response) => {
          void (async () => {
            // Confirm the SetupIntent using the details collected by the Payment Element
            const result = await stripe.confirmSetup({
              elements,
              clientSecret: response.data.client_secret,
              redirect: 'if_required',
              confirmParams: {
                return_url: window.SITE_URL + 'api/user/creditCards/create-callback?redirect=' + tR('/account/settings/credit-cards')
              }
            })

            if (result.setupIntent) {
              dispatch(CreateCreditCard(String(result.setupIntent.payment_method)).set({
                onSuccess: (response) => {
                  props.onClose()
                }
              }))
            } else {
              // This point is only reached if there's an immediate error when
              // confirming the setup. (for example, payment details incomplete)
              if (result.error.type === 'card_error') {
                handleError(result.error.message ?? t('An error occured. Please try again later.'))
              } else {
                handleError(t('An error occured. Please try again later.'))
                console.log(result.error)
              }
            }
          })()
        },
        onFailure: (response) => {
          handleError(t('An error occured. Please try again later.'))
          console.log(response)
        }
      }))

      // Customer is redirected to `return_url`.
      // For some payment methods like iDEAL, customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      return false
    })()
  }, [stripe, elements, t])

  const onChange = useCallback((e: StripePaymentElementChangeEvent) => {
    setDisabled(!e.complete)
  }, [])

  return <form onSubmit={onSubmit}>
    <DialogContent>
      <Stack spacing={2}>
        <PaymentElement
          onChange={onChange}
        />
        {error ? <Alert severity="error">{error}</Alert> : null}
      </Stack>
    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
      >{t('Cancel')}</DialogButton>
      <DialogButton
        color="primary"
        type="submit"
        disabled={disabled}
      >{t('Confirm')}</DialogButton>
    </DialogActions>
  </form>
}
