import { Stack } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductItem } from '../../UserData/_stores/UserCartStore'
import { Link } from 'react-router-dom'
import { CartHeaderH1 } from '../CartContent/CartHeader'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { selectCarts } from '../../UserData/_selectors/OrderSelectors'
import CheckoutShipping from '../../Checkout/Shipping/CheckoutShipping'

export default function CartShipping() {
  const [t] = useTranslation('cart')
  const [tR] = useTranslation('routes')

  const carts = useAppSelector(selectCarts)

  useEffect(() => {
    carts.forEach(cart => {
      const subtotal = cart.get('subtotal').toDinero()
      const discount = cart.get('discount').toDinero()
      const value = subtotal.subtract(discount).toUnit()
      const currency = subtotal.getCurrency()
      let totalQuantity = 0
      const contents: Array<{ id: string, quantity: number }> = []
      const contentIds: string[] = []
      const items: Array<{
        item_id: string
        item_name: string
        price: number
        quantity: number
      }> = []

      cart.get('items').forEach((item) => {
        totalQuantity += item.get('quantity')
        let id = item.get('type')
        let name: string = item.get('name')

        if (item instanceof ProductItem) {
          id += '-' + item.get('product_id')
          name = item.get('product_name')
        }

        contents.push({
          id,
          quantity: item.get('quantity')
        })
        contentIds.push(id)

        items.push({
          item_id: id,
          item_name: name,
          price: item.get('price').toDinero().toUnit(),
          quantity: item.get('quantity')
        })
      })

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      window.fbq && window.fbq('track', 'InitiateCheckout', {
        contentIds,
        value,
        totalQuantity,
        currency,
        contents
      })

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      window.gtag && window.gtag('event', 'begin_checkout', {
        currency,
        value,
        items
      })
    })
  }, [])

  return <Stack spacing={2}>
    <CartHeaderH1><Link to={tR('/cart')}>{t('Cart')}</Link> &gt; <span className="title">{t('Shipping')}</span></CartHeaderH1>
    <div>
      <CheckoutShipping
        orders={carts}
        paymentUrl={tR('/cart/payment')}
      />
    </div>
  </Stack>
}
