import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import EditProductPrices, { Props } from '../_components/EditProductPrices';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
});

export default connect(
	mapStateToProps,
	null
)(EditProductPrices);