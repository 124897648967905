import { createAjaxAction } from '../../Common/_actions/AjaxAction';
import { createAction } from '../../Common/_actions/Action';
import { Dispatch } from 'redux';
import * as Immutable from 'immutable';
import { EditLoader } from '../../UIData/_actions/UIDataActions';
import { PatternPayload } from '../_stores/PatternStore';
import { batch } from 'react-redux';
import { PatternCategoryPayload } from '../_stores/PatternCategoryStore';

export enum PatternDesignActionType {
	PatternDesignServiceImageAdd = "PATTERN_DESIGN_SERVICE_IMAGE_ADD",
	PatternDesignServiceCategoryAdd	= "PATTERN_DESIGN_SERVICE_CATEGORY_ADD",
	PatternDesignServiceSetColors = "PATTERN_DESIGN_SERVICE_SET_COLORS",
	SelectCategory = "PATTERN_DESIGN_SERVICE_SELECT_CATEGORY",
	ResetPatterns = "PATTERN_DESIGN_SERVICE_RESET_PATTERNS",
	PatternDesignServiceOffsetChange = "PATTERN_DESIGN_SERVICE_OFFSET_CHANGE",
	EditCount = "PATTERN_DESIGN_SERVICE_EDIT_COUNT",

	GetPatterns = 'PATTERN_DESIGN_SERVICE_GET_PATTERNS',
	GetPatternColors = 'PATTERN_DESIGN_SERVICE_GET_PATTERN_COLORS',
	GetPatternCategories = 'PATTERN_DESIGN_SERVICE_GET_PATTERN_CATEGORIES',
	RecolorPattern = 'PATTERN_DESIGN_SERVICE_RECOLOR_PATTERN',
}

const PatternDesignServiceImageAdd = (image:PatternPayload) => createAction(PatternDesignActionType.PatternDesignServiceImageAdd, {
	image:image
});

const PatternDesignServiceCategoryAdd = (id: number, title: string, thumbnail: string, categories: Array<{id: number, title: string, thumbnail: string}>) => createAction(PatternDesignActionType.PatternDesignServiceCategoryAdd, {
	id: id,
	title: title,
	thumbnail: thumbnail,
	categories: categories,
});

const PatternDesignServiceSetColors = (id: string, colors: Array<string>) => createAction(PatternDesignActionType.PatternDesignServiceSetColors, {
	id: id,
	colors: colors
});

export const SelectCategory = (id: number|null) => createAction(PatternDesignActionType.SelectCategory, {
	id: id
});

export const ResetPatterns = () => createAction(PatternDesignActionType.ResetPatterns);

export const GetPatterns = (categoryId: number|null, search: string, limit: number, offset: number) => {
	return createAjaxAction<{images: any[], count: number}>(PatternDesignActionType.GetPatterns, {
		url: "ajax/create/pattern-designs/patterns",
		method: "GET",
		data: {
			categoryId: categoryId !== null ? String(categoryId) : null,
			search: search,
			limit: limit,
			offset: offset,
		},
		limit: 500,
		onSuccess: (response, dispatch) => {
			batch(() => {
				dispatch(ResetPatterns());
				let images:PatternPayload[] = response.data.images.map(
					(image:any, index:number) => ({
						id: image.id, 
						name: image.title, 
						thumbnail_src: image.thumbnails.xlargeDiscreet.url,
						repeat_src: image.thumbnails.repeat.url,
						repeat_width: image.thumbnails.repeat.width,
						repeat_height: image.thumbnails.repeat.height,
						recolorable: image.metadata.recolorable,
						horizontalRapportsPostcard: image.metadata.horizontalRapportsPostcard,
						index: index,
						categoryId: +categoryId,
					} as PatternPayload)
				);

				if(images.length) {
					images.map((image:any) => {
						dispatch(PatternDesignServiceImageAdd(image))
					});
				} else {
					dispatch(EditLoader("patterns", false))
				}

				dispatch(EditCount(response.data.count));
			});
		}
	});
};

export const GetPatternColors = (id: string, callback: Function) => {
	return createAjaxAction<{colors: string[]}>(PatternDesignActionType.GetPatternColors, {
		url: "ajax/create/pattern-designs/colors?id="+id,
		method: "GET",
		onSuccess: (response, dispatch) => {
			let colors = response.data.colors;
			colors.map((color, i) => {
				colors[i] = '#'+color;
			});

			dispatch(PatternDesignServiceSetColors(id, colors));
			if(callback) {
				callback(Immutable.OrderedSet<string>(colors));
			}
		}
	});
};

export const GetPatternCategories = () => createAjaxAction<{categories: PatternCategoryPayload[]}>(PatternDesignActionType.GetPatternCategories, {
	url: "ajax/create/pattern-designs/categories",
	method: "GET",
	onSuccess: (response, dispatch) => {
		response.data.categories.map((category) => {
			dispatch(PatternDesignServiceCategoryAdd(category.id, category.title, category.thumbnail, category.categories));
		});
	}
});

export const RecolorPattern = (id: string) => createAjaxAction<{images: PatternPayload[]}>(PatternDesignActionType.RecolorPattern, {
	url: "ajax/create/pattern-designs/recolor-pattern?id="+id,
	method: "GET",
	onSuccess: (response, dispatch) => {
		response.data.images.map((image) => {
			dispatch(PatternDesignServiceImageAdd(image))
		});
	}
});

export const PatternDesignServiceOffsetChange = (offset: number) => createAction(PatternDesignActionType.PatternDesignServiceOffsetChange, {
	offset: offset
});

export const EditCount = (count: number) => createAction(PatternDesignActionType.EditCount, {
	count: count
});

export type PatternDesignAction = 
	ReturnType<typeof PatternDesignServiceImageAdd> |
	ReturnType<typeof PatternDesignServiceCategoryAdd> |
	ReturnType<typeof PatternDesignServiceSetColors> |
	ReturnType<typeof SelectCategory> |
	ReturnType<typeof ResetPatterns> |
	ReturnType<typeof GetPatterns> |
	ReturnType<typeof GetPatternCategories> |
	ReturnType<typeof GetPatternColors> |
	ReturnType<typeof PatternDesignServiceOffsetChange> |
	ReturnType<typeof EditCount>