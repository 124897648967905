import * as Immutable from 'immutable';
import { PhotoLabCreationPayload, PhotoLabCreationStore } from '../_stores/PhotoLabCreationStore';
import { PhotoLabCreationStoreAction, PhotoLabCreationStoreActionType } from '../_actions/PhotoLabCreationStoreActions';

const editPhotoLabCreation = (store: Immutable.OrderedMap<string, PhotoLabCreationStore>, action: PhotoLabCreationPayload) => {
  return store.withMutations(store => {
    const oldPhotoLabCreation = store.get(String(action.id));

    const { ...otherProps } = action;

    let newPhotoLabCreation = new PhotoLabCreationStore(otherProps);

    if (oldPhotoLabCreation) {
      newPhotoLabCreation = oldPhotoLabCreation.mergeDeep(newPhotoLabCreation);
    }

    store.set(String(action.id), newPhotoLabCreation);
  })
}

const PhotoLabCreationReducer = (
  store: Immutable.OrderedMap<string, PhotoLabCreationStore> = Immutable.OrderedMap<string, PhotoLabCreationStore>(),
  action: PhotoLabCreationStoreAction
): Immutable.OrderedMap<string, PhotoLabCreationStore> => {
  switch (action.type) {
    case PhotoLabCreationStoreActionType.EditPhotoLabCreation:
      store = editPhotoLabCreation(store, action.photoLabCreation);
      break;

    case PhotoLabCreationStoreActionType.EditManyPhotoLabCreations:
      Object.values(action.photoLabCreations).forEach(photoLabCreation => {
        store = editPhotoLabCreation(store, photoLabCreation);
      })
      break;

    case PhotoLabCreationStoreActionType.RemovePhotoLabCreation:
      store = store.remove(String(action.id));
      break;
  }
  return store;
}

export default PhotoLabCreationReducer