import { Card, CardActions, CardContent, Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type UserTaxNumberStore } from '../../../UserData/_stores/UserTaxNumberStore'
import DeleteTaxNumberDialog from './DeleteTaxNumberDialog'
import { useAppSelector } from '../../../Common/_hooks/useAppSelector'
import CardButton from '../../../Common/Buttons/CardButton'

export interface Props {
  taxNumber: UserTaxNumberStore
}

export default function TaxNumber(props: Props) {
  const [t] = useTranslation('account-v2')

  const addresses = useAppSelector(state => state.get('userData').get('addresses').get('data'))

  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)

  const onDeleteOpen = useCallback(() => {
    setDeleteDialogOpened(true)
  }, [])

  const onDeleteClose = useCallback(() => {
    setDeleteDialogOpened(false)
  }, [])

  const address = useMemo(() => {
    return addresses.get(String(props.taxNumber.get('id_address')))
  }, [addresses])

  return <>
    <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h3">{props.taxNumber.get('type').replace('-', ' ').toUpperCase()}</Typography>
        <Typography variant="body1" component="p">{props.taxNumber.get('tax_number')}</Typography>
        {address ? <Typography variant="caption">
          {address.get('address') + ', ' + address.get('city') + ', ' + address.get('province') + ', ' + address.get('country')}
        </Typography> : null}
      </CardContent>
      <CardActions>
        <CardButton
          color="secondary"
          onClick={onDeleteOpen}
        >{t('Delete')}</CardButton>
      </CardActions>
    </Card>

    <DeleteTaxNumberDialog
      taxNumber={props.taxNumber}
      opened={deleteDialogOpened}
      onClose={onDeleteClose}
    />
  </>
}
