import { styled } from "@mui/material";

export const ModalTitle = styled('h3')`
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.02em;
    color: #333333;
    @media (max-width: 600px){
        font-size: 16px;
    }
`;

export const ModalSubtitle = styled('p')`
    font-size: 14px;
    letter-spacing: 0.02em;
    margin: 0;
    color: #AFAFAF;
`;

export const OptionTitle = styled('p')`
    margin: 0;
    font-family: Poppins;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: #333333;
`;

export const OptionLabel = styled('p')`
	margin: 0;
	font-family: Poppins;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.02em;
	color: #333333;
`;

export const SuccessMsgTitle = styled('h3')`
    margin: 0;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.02em;
`;

export const SuccessMsgSubTitle = styled('p')`
    margin: 0;
    font-size: 0.875rem;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.02em;
`;

export const Paragraph500 = styled('p')`
    margin: 0;
    color: #333333;
    font-weight: 500;
`;
export const Paragraph600 = styled('p')`
    margin: 0;
    color: #333333;
    font-weight: 600;
`;

export const InlineParagraph500 = styled(Paragraph500)`
    display: inline;
`;
export const InlineParagraph600 = styled(Paragraph600)`
    display: inline;
`;
export const Paragraph400size14grey = styled('p')`
    margin: 0;
    letter-spacing: 0.02em;
    font-weight: 400;
    font-size: 14px;
    color: #AFAFAF;
`;
export const AccordionHeader = styled('p')`
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
`;
export const AccordionSubHeader = styled('p')`
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.01em;
    margin-left:10px;
`;

export const ColoredText = styled('p')<{color:string}>`
    color : ${props => props.color};
    margin: 0;
`;