import { Button as MuiButton, ButtonProps as MuiButtonProps, styled } from '@mui/material';


const StyledCardButton = styled(MuiButton)(({theme}) => ({
    padding: theme.spacing(1, 2),
    borderRadius: 0,
    height: 'auto',
}));

type Props = MuiButtonProps & {}

export default function CardButton(props: Props) {
    return <StyledCardButton 
        size="large"
        {...props}
    />
}