import * as Immutable from 'immutable';
import { DateTime } from 'luxon';


export type UserCreditCardPayload = {
	id: number
	name: string
	last4: string
	brand: string
	expiration: string
}

export interface IUserCreditCardStore {
	id: number
	name: string
	last4: string
	brand: string
	expiration: string
}

const defaultUserCreditCardStore = Immutable.Record<IUserCreditCardStore>({
	id: 0,
	name: '',
	last4: '',
	brand: '',
	expiration: DateTime.now().toISODate(),
});

export class UserCreditCardStore extends defaultUserCreditCardStore implements IUserCreditCardStore {}