import * as Immutable from 'immutable';

export interface StoreIntegrationPayload {
	slug: string
  name: string
  disabled: boolean
}

export interface IStoreIntegrationStore {
	slug: string
  name: string
  disabled: boolean
}

const defaultStoreIntegrationStore = Immutable.Record<IStoreIntegrationStore>({
	slug: '',
  name: '',
  disabled: false,
});

export default class StoreIntegrationStore extends defaultStoreIntegrationStore implements IStoreIntegrationStore {}