import * as Immutable from 'immutable';

export interface ShippingTypePayload {
	id: number
	id_country: number
	slug: string
	ships_from: string
	name: string
	description: string
}

export interface IShippingTypeStore {
	id: number
	id_country: number
	slug: string
	ships_from: string
	name: string
	description: string
}

const defaultShippingTypeStore = Immutable.Record<IShippingTypeStore>({
	id: 0,
	id_country: 0,
	slug: '',
	ships_from: '',
	name: '',
	description: '',
});

export default class ShippingTypeStore extends defaultShippingTypeStore implements IShippingTypeStore {}