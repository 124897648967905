import { createAction } from "../../Common/_actions/Action"
import ModelViewerStore from "../_stores/ModelViewerStore"

export enum ModelViewerActionType {
    SetFixedView = "MODEL_VIEWER_SET_FIXED_VIEW",
    SetFixedViewTime = "MODEL_VIEWER_SET_FIXED_VIEW_TIME",
}

export const SetFixedView = (view: ModelViewerStore['fixedView']) => createAction(ModelViewerActionType.SetFixedView, {
    view: view
})

export const SetFixedViewTime = (time: number) => createAction(ModelViewerActionType.SetFixedViewTime, {
    time: time
})

export type ModelViewerAction = 
    ReturnType<typeof SetFixedView>     |
    ReturnType<typeof SetFixedViewTime>
