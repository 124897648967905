import { createAction } from "../../Common/_actions/Action";
import { PhotoLabCreationPayload } from "../_stores/PhotoLabCreationStore";

export enum PhotoLabCreationStoreActionType {
  EditPhotoLabCreation = 'PHOTO_LAB_CREATION_EDIT_PHOTO_LAB_CREATION',
  EditManyPhotoLabCreations = 'PHOTO_LAB_CREATION_EDIT_MANY_PHOTO_LAB_CREATIONS',
  RemovePhotoLabCreation = 'PHOTO_LAB_CREATION_REMOVE_PHOTO_LAB_CREATION',
}

export const EditPhotoLabCreation = (photoLabCreation: PhotoLabCreationPayload) => createAction(PhotoLabCreationStoreActionType.EditPhotoLabCreation, {
	photoLabCreation: photoLabCreation
});

export const EditManyPhotoLabCreations = (photoLabCreations: Record<string, PhotoLabCreationPayload>) => createAction(PhotoLabCreationStoreActionType.EditManyPhotoLabCreations, {
	photoLabCreations: photoLabCreations
});

export const RemovePhotoLabCreation = (id: number) => createAction(PhotoLabCreationStoreActionType.RemovePhotoLabCreation, {
	id: id
});

export type PhotoLabCreationStoreAction =
    ReturnType<typeof EditPhotoLabCreation> |
    ReturnType<typeof EditManyPhotoLabCreations> |
    ReturnType<typeof RemovePhotoLabCreation>