import * as Immutable from 'immutable';

export interface IProductCategoryStore {
	id: number
	slug: string
	name: string
	theorder: number
}

const defaultProductCategoryStore = Immutable.Record<IProductCategoryStore>({
	id: 0,
	slug: '',
	name: '',
	theorder: 0,
});

export default class ProductCategoryStore extends defaultProductCategoryStore implements IProductCategoryStore {}