import { Alert, Box, Button, Chip, Stack, Typography, styled } from '@mui/material'
import { type StoreItemStore } from '../../UserData/_stores/StoreItemStore'
import { type BaseItem, type ProductItem } from '../../UserData/_stores/UserCartStore'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { useCallback, useMemo, useState } from 'react'
import CartItem from './CartItem'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import SyncProductDialog from '../../Account/Orders/SyncProductDialog'
import { FetchOneItem } from '../../UserData/_actions/UserDataActions'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { UnignoreStoreItem } from '../../UserData/_actions/OrderActions'
import IgnoreItemDialog from '../../Account/Orders/IgnoreItemDialog'

const ThumbnailWrapper = styled('div')(({ theme }) => ({
  width: '64px',
  height: '64px',
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,

  [theme.breakpoints.down('sm')]: {
    width: '64px',
    height: '64px'
  }
}))

const StoreBadge = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-8px',
  right: '-16px',
  padding: '4px',
  backgroundColor: '#ddd',
  border: '2px solid #fff',
  borderRadius: '50%'
}))

const StoreBadgeImg = styled('img')({
  width: '24px',
  height: '24px',
  objectFit: 'contain',
  display: 'block'
})

interface Props {
  cart: UserCartStore
  storeItem: StoreItemStore
  short?: boolean
}

export default function StoreItem(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const storeIntegration = useAppSelector(state => state.get('appData').get('storeIntegrations').get(props.cart.get('storeIntegration') ?? ''))
  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))

  const [syncProductDialogOpened, setSyncProductDialogOpened] = useState(false)
  const [ignoreDialogOpened, setIgnoreDialogOpened] = useState(false)

  const onSyncProductDialogOpen = useCallback(() => {
    setSyncProductDialogOpened(true)
  }, [])

  const onSyncProductDialogClose = useCallback(() => {
    setSyncProductDialogOpened(false)
    dispatch(FetchOneItem(props.cart.get('ref_id'), 'orders'))
  }, [props.cart.get('ref_id')])

  const onIgnoreDialogOpen = useCallback(() => {
    setIgnoreDialogOpened(true)
  }, [])

  const onIgnoreDialogClose = useCallback(() => {
    setIgnoreDialogOpened(false)
  }, [])

  const onUnignore = useCallback(() => {
    dispatch(UnignoreStoreItem(props.cart.get('id'), props.storeItem.get('id')))
  }, [props.cart.get('id'), props.storeItem.get('id')])

  const item = useMemo(() => {
    const tmpItem = props.cart.get('items').get(String(props.storeItem.get('id_cart_item')))
    return tmpItem ? tmpItem as ProductItem : undefined
  }, [props.storeItem, props.cart])

  return <Box
    component="div"
    sx={{
      px: {
        xs: 2,
        lg: 3
      },
      pt: {
        xs: 2,
        lg: 1.5
      },
      pb: 1.5
    }}
  >
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
    >
      <div style={{ position: 'relative' }}>
        <ThumbnailWrapper>
          {props.storeItem.get('preview') ? <img
            src={props.storeItem.get('preview')}
            style={{
              height: '100%',
              display: 'block'
            }}
          /> : null}
        </ThumbnailWrapper>
        { storeIntegration ? <StoreBadge>
          <StoreBadgeImg
            src={require('@resources/img/account/' + storeIntegration.get('slug') + '-icon.svg?url')}
          />
        </StoreBadge> : null }
      </div>

      <Stack
        justifyContent="center"
        alignItems="flex-start"
        flexGrow={1}
      >
        <Typography variant="h4">{props.storeItem.get('product_name')}</Typography>

        { props.storeItem.get('variant_name') !== '' ? <div style={{
          textTransform: 'uppercase',
          fontSize: '12px',
          fontWeight: '500'
        }}>{props.storeItem.get('variant_name')}</div> : null }

        { props.storeItem.get('ignored') ? <Chip
          label={t('Ignored')}
          variant="square-filled"
          size="small"
          color="gray"
          sx={{
            mt: 1
          }}
        /> : null }
      </Stack>

      { !props.short ? <Stack
        spacing={1}
        alignItems="flex-end"
      >
        { !props.storeItem.get('ignored') && ['ordered'].includes(props.cart.get('status')) ? <Button
          variant="outlined"
          color="primary"
          onClick={onSyncProductDialogOpen}
          disabled={cartLoader}
        >{ t('Sync product') }</Button> : null }

        { ['ordered'].includes(props.cart.get('status')) ? <>
          { props.storeItem.get('ignored') ? <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={onUnignore}
            disabled={cartLoader}
          >{ t('Unignore') }</Button> : <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={onIgnoreDialogOpen}
            disabled={cartLoader}
          >{ t('Ignore') }</Button> }
        </> : null }
      </Stack> : null }
    </Stack>
    { !props.storeItem.get('ignored') ? <Stack
      direction="row"
      spacing={0}
      alignItems="flex-start"
    >
      <div style={{
        width: '24px',
        height: '24px',
        marginTop: '24px',
        marginLeft: '28px',
        borderLeft: '2px solid #999',
        borderBottom: '2px solid #999'
      }}/>

      <div style={{ flexGrow: 1 }}>
        { item ? <Box
          component="div"
          sx={{
            mr: {
              xs: -2,
              lg: -3
            }
          }}
        >
          <CartItem
            cart={props.cart}
            item={item as BaseItem}
            short={true}
          />
        </Box> : <Box component="div" sx={{
          mt: 3,
          px: 3,
          display: 'flex',
          alignItems: 'flex-start'
        }}>
          <Alert
            severity="warning"
          >{t('Sync a product with this item or ignore it.')}</Alert>
        </Box> }
      </div>
    </Stack> : null }

    <SyncProductDialog
      idCart={props.cart.get('id')}
      item={item}
      storeItem={props.storeItem}
      opened={syncProductDialogOpened}
      onClose={onSyncProductDialogClose}
    />

    <IgnoreItemDialog
      idCart={props.cart.get('id')}
      storeItem={props.storeItem}
      opened={ignoreDialogOpened}
      onClose={onIgnoreDialogClose}
    />
  </Box>
}
