import { Avatar, Button, ListItemAvatar, ListItemText, Menu, MenuItem } from '@mui/material'
import { AddRounded as AddRoundedIcon, CheckRounded as CheckRoundedIcon } from '@mui/icons-material'
import { type MouseEvent, useCallback, useMemo, useState } from 'react'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { type BrandingOptionStore } from '../../UserData/_stores/BrandingOptionStore'
import { type BaseItem, type ProductItem } from '../../UserData/_stores/UserCartStore'
import { UpdateCartItem } from '../../UserData/_actions/UserCartActions'

interface Props {
  item: BaseItem
  typeId: number
}

export default function ItemBrandingOption(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const type = useAppSelector(state => state.get('appData').get('brandingOptionTypes').get('1'))
  const brandingOptions = useAppSelector(state => state.get('userData').get('brandingOptions')
    .filter(brandingOption => brandingOption.get('id_type') === type?.get('id')), {
    equalityFn: (a, b) => a.equals(b)
  })
  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))

  const [opened, setOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const hasOption = useMemo(() => {
    let tmpHasOption = false;

    (props.item as ProductItem).get('options').forEach(option => {
      if (['include-label'].includes(option.get('slug'))) {
        tmpHasOption = true
      }
    })

    return tmpHasOption
  }, [props.item])

  const onRemove = useCallback(() => {
    const fd = new FormData()
    fd.append('id', String(props.item.get('id')))
    fd.append('fields[]', 'options');

    // Add all base options
    (props.item as ProductItem).get('options').forEach(option => {
      // Exclude label
      if (option.get('type_slug') === 'include-label') {
        return
      }

      fd.append('options[]', String(option.get('id')))
    })

    dispatch(UpdateCartItem(fd))
  }, [props.item])

  const onMenuOpen = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setOpened(true)
  }, [])

  const onMenuClose = useCallback(() => {
    setOpened(false)
  }, [])

  const renderOption = useCallback((brandingOption: BrandingOptionStore) => {
    return <MenuItem
      key={brandingOption.get('id')}
      onClick={() => {
        const fd = new FormData()
        fd.append('id', String(props.item.get('id')))
        fd.append('fields[]', 'options');

        // Add all base options
        (props.item as ProductItem).get('options').forEach(option => {
          fd.append('options[]', String(option.get('id')))
        })

        // Add label option
        const id = String((props.item as ProductItem).get('brandingOptions').get('include-label'))
        fd.append('options[]', id)
        fd.append('brandingOptions[' + id + ']', String(brandingOption.get('id')))

        dispatch(UpdateCartItem(fd))
        setOpened(false)
      }}
    >
      <ListItemAvatar>
        <Avatar src={brandingOption.get('file_url')} />
      </ListItemAvatar>
      <ListItemText>{brandingOption.get('name')}</ListItemText>
    </MenuItem>
  }, [props.item])

  // Don't display if user doesn't have any
  if (!type || brandingOptions.count() === 0) return null

  return <>
    <Button
      variant={hasOption ? 'contained' : 'dashed'}
      color={hasOption ? 'primary' : 'secondary'}
      size="small"
      startIcon={hasOption ? <CheckRoundedIcon /> : <AddRoundedIcon />}
      onClick={hasOption ? onRemove : onMenuOpen}
      disabled={cartLoader}
    >{hasOption ? t('{{name}} added', { name: type.get('name') }) : t('Add {{name}}', { name: type.get('name') })}</Button>

    <Menu
      open={opened}
      onClose={onMenuClose}
      anchorEl={anchorEl}
    >
      { brandingOptions.valueSeq().map(renderOption)}
    </Menu>
  </>
}
