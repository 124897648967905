import { createSelector } from "reselect";
import { selectUserData } from "./UserDataSelectors";
import { BaseItem, ProductItem } from "../_stores/UserCartStore";
import Immutable from "immutable";
import { BrandingOptionStore } from "../_stores/BrandingOptionStore";
import WebsiteStore from "../../WebsiteStore";

export const selectBrandingOptionsForItem = createSelector(
  [selectUserData, (state:WebsiteStore, item: BaseItem) => item],
  (userData, item) => {
    if(item.get('type') !== 'product') return Immutable.OrderedMap<string, BrandingOptionStore>()

    return userData.get('brandingOptions').filter(brandingOption => 
      (item as ProductItem).get('options')?.find(option => 
        option.get('id_branding_option') === brandingOption.get('id') 
      ) !== undefined
    )
  }, {
  memoizeOptions: {
    resultEqualityCheck: (a: Immutable.OrderedMap<string, BrandingOptionStore>, b: Immutable.OrderedMap<string, BrandingOptionStore>) => {
      return a === b || (
        a instanceof Immutable.OrderedMap
        && b instanceof Immutable.OrderedMap
        && a.count() === b.count()
        && a.every((order, key) => order.equals(b.get(key)))
      )
    }
  }
});