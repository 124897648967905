import { useCallback } from 'react'
import Dialog from '../../Common/Dialog/Dialog'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import DialogContent from '../../Common/Dialog/DialogContent'
import DialogActions from '../../Common/Dialog/DialogActions'
import DialogButton from '../../Common/Buttons/DialogButton'
import { useTranslation } from 'react-i18next'
import { type UserImageStore } from '../../UserData/_stores/UserImageStore'
import { DeletePaginationItem } from '../../UserData/_actions/UserDataActions'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'

interface Props {
  image: UserImageStore
  opened: boolean
  onClose: () => void
}

export default function DeleteImageDialog(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')

  const onDelete = useCallback(() => {
    const fd = new FormData()
    fd.append('id', props.image.get('id'))
    dispatch(DeletePaginationItem(fd, 'images'))

    props.onClose()
  }, [props.image])

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <DialogTitle>{t('Delete {{name}}', { name: props.image.get('name') })}</DialogTitle>
    <DialogContent>
      <p>{t('Are you sure that you want to delete this image?')}</p>
    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
      >{t('Cancel')}</DialogButton>
      <DialogButton
        color="error"
        onClick={onDelete}
        autoFocus
      >{t('Delete')}</DialogButton>
    </DialogActions>
  </Dialog>
}
