import * as Immutable from 'immutable';
import { PaginationItem } from './PaginationStore'
import ProductOptionTypeStore from '../../AppData/_stores/ProductOptionTypeStore';
import { CollectionItemPayload, CollectionItemStore } from './CollectionItemStore';
import { DateTime } from 'luxon';

export type CollectionPayload = {
	id: number
	name: string
  date_created: string
	listItems: Record<string, CollectionItemPayload>,
}

interface ICollectionStore {
	id: number
	name: string
  date_created: string
	listItems: Immutable.Map<string, CollectionItemStore>
}

const defaultCollectionStore = Immutable.Record<ICollectionStore>({
	id: 0,
  name: '',
  date_created: DateTime.now().toISO(),
	listItems: Immutable.Map<string, CollectionItemStore>(),
});

export class CollectionStore extends defaultCollectionStore implements ICollectionStore {}