import { Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CartHeaderH1 } from '../CartContent/CartHeader'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { selectCarts } from '../../UserData/_selectors/OrderSelectors'
import CheckoutOverview from '../../Checkout/Overview/CheckoutOverview'

export default function CartOverview() {
  const [t] = useTranslation('cart')
  const [tR] = useTranslation('routes')

  const carts = useAppSelector(selectCarts)

  return <Stack spacing={2}>
    <Grid item xs={12}>
      <CartHeaderH1><span className="title">{carts.count() > 1 ? t('Carts') : t('Cart')}</span></CartHeaderH1>
    </Grid>
    <div>
      <CheckoutOverview
        orders={carts}
        shippingUrl={tR('/cart/shipping')}
        paymentUrl={tR('/cart/payment')}
      />
    </div>
  </Stack>
}
