import * as Immutable from 'immutable';
import DesignLabLayerStore from './DesignLabLayerStore';
import PatternDesignServiceStore from '../../../DesignLab/_stores/PatternDesignServiceStore';
import ModelViewerStore from '../../../ModelViewer/_stores/ModelViewerStore';

export interface IDesignLabStore {
	layers: Immutable.OrderedMap<string, DesignLabLayerStore>,
    selectedLayer: string | null
    activeScene: string | null
    activeDesignId: string | null
    activeProductId: number | null
    activeVariant: string
    activeSubproduct: string | null
    activeOptions: Immutable.OrderedMap<string, string>
    zoomScale: number,
    zoomOffsetX: number,
    zoomOffsetY: number,
    isPanning: boolean,
    mirrorModes: Immutable.OrderedMap<string, number>,
    autoDesignSubproducts: Immutable.OrderedMap<string, boolean>,
    backgroundColor: string | null,
    patternDesignService: PatternDesignServiceStore,
    textureUpdate: number,
    warnings: Immutable.Record<{
        resolution: Immutable.OrderedMap<string, boolean>
        templates_covered: Immutable.OrderedMap<string, boolean>
    }>
    designName: string,
    modelViewer: ModelViewerStore
}

const defaultDesignLabStore = Immutable.Record<IDesignLabStore>({
	layers: Immutable.OrderedMap<string, DesignLabLayerStore>(),
    selectedLayer: null,
    activeScene: null,
    activeDesignId: null,
    activeProductId: null,
    activeVariant: 'default',
    activeSubproduct: null,
    activeOptions: Immutable.OrderedMap<string, string>(),
    zoomScale: 1,
    zoomOffsetX: 0,
    zoomOffsetY: 0,
    isPanning: false,
    mirrorModes: Immutable.OrderedMap<string, number>(),
    autoDesignSubproducts: Immutable.OrderedMap<string, boolean>(),
    backgroundColor: null,
    patternDesignService: new PatternDesignServiceStore,
    textureUpdate: Date.now(),
    warnings: Immutable.Record<{
        resolution: Immutable.OrderedMap<string, boolean>
        templates_covered: Immutable.OrderedMap<string, boolean>
    }>({
        resolution: Immutable.OrderedMap<string, boolean>(),
        templates_covered: Immutable.OrderedMap<string, boolean>(),
    })(),
    designName: '',
    modelViewer: new ModelViewerStore,
});

export default class DesignLabStore extends defaultDesignLabStore implements IDesignLabStore {}