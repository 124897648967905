import { Alert, Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Link, NoSsr, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon } from '@mui/icons-material'
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SendFD from '../Common/Utils/SendFD';
import { AuthActionType, CloseAuthModal, OpenAuthModal, SubmitSignup } from './_actions/AuthActions';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import { useAppDispatch } from '../Common/_hooks/useAppDispatch';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../Common/_hooks/useAppSelector';
import useErrorText from '../Common/Error/useErrorText';
import { ApiError, EditLoader } from '../UIData/_actions/UIDataActions';
import FacebookLoginButton from './FacebookLoginButton';
import GoogleLoginButton from './GoogleLoginButton';
import config from '../../../config/config.json'
import { GoogleOAuthProvider } from '@react-oauth/google';

export type Props = {
  signup?: boolean
  redirect?: string
}

export default function AuthForm(props: Props) {
  const dispatch = useAppDispatch();
  const [t] = useTranslation('auth');
  const [tR] = useTranslation('routes')
  const theme = useTheme()
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const [searchParams] = useSearchParams()

  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('auth') || state.get('UIData').get('loaders').get('auth-navigating'))

  const errorKey = useMemo(() => props.signup ? AuthActionType.SubmitSignup : AuthActionType.Authenticate, [props.signup])
  const apiError = useAppSelector(state => state.get('UIData').get('apiErrors').get(errorKey))
  const fbApiError = useAppSelector(state => state.get('UIData').get('apiErrors').get(AuthActionType.SubmitFbLogin))
  const googleApiError = useAppSelector(state => state.get('UIData').get('apiErrors').get(AuthActionType.SubmitGoogleLogin))

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [newsletter, setNewsletter] = useState(false);
  const [forgotPasswordDialogOpened, setForgotPasswordDialogOpened] = useState(false);
  const [showPassword, setShowPassword] = useState(false)

  const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => setName(e.target.value), [])
  const onChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim()), [])
  const onChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value), [])
  const onChangeNewsletter = useCallback((e: ChangeEvent<HTMLInputElement>) => setNewsletter(e.target.checked), [])

  const onClickShowPassword = useCallback(() => setShowPassword(value => !value), [])

  const onOpenForgotPassword = useCallback(() => setForgotPasswordDialogOpened(true), [])
  const onCloseForgotPassword = useCallback(() => setForgotPasswordDialogOpened(false), [])

  const onClickLogin = useCallback(() => dispatch(OpenAuthModal(false)), [])
  const onClickSignup = useCallback(() => dispatch(OpenAuthModal(true)), [])

  const onLoginSuccess = useCallback(() => {
    dispatch(EditLoader('auth-navigating', true))
    window.location.href = props.redirect !== undefined ? props.redirect : tR('/account');
  }, [props.redirect])

  const onSignupSuccess = useCallback(() => {
    dispatch(EditLoader('auth-navigating', true))
    window.location.href = tR('/auth/signed-up');
  }, [tR])

  const onAccountLink = useCallback(() => {
    dispatch(EditLoader('auth-navigating', true))
    window.location.href = tR('/auth/signup-confirm-account-link');
  }, [tR])

  //Trigger API Error through url param
  useEffect(() => {
    const errorParam = searchParams.get('error')

    if (errorParam) {
      dispatch(ApiError(errorKey, {
        error: errorParam
      }))
    }
  }, [searchParams])

  const authFormSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const fd = new FormData();

    if (props.signup) {
      fd.append('signup_name', name);
      fd.append('signup_email', email);
      fd.append('signup_password', password);
      fd.append('signup_newsletter', newsletter ? 'on' : 'off');
    } else {
      fd.append('email', email);
      fd.append('password', password);
    }

    if (props.redirect) {
      fd.append('redirect', props.redirect);
    }

    if (props.signup) {
      dispatch(SubmitSignup(
        name,
        email,
        password,
        newsletter,
      ).set({
        onSuccess: onSignupSuccess
      }))
    } else {
      dispatch(EditLoader('auth-navigating', true))
      SendFD('/auth/authenticate', 'post', fd);
    }
  }, [props.signup, props.redirect, name, email, password, newsletter, tR, onSignupSuccess]);

  const validateLogin = useCallback(() => {
    return email !== "" && password !== "";
  }, [email, password]);

  const validateSignup = useCallback(() => {
    return name !== "" && email !== "" && password !== "";
  }, [name, email, password]);

  const authDisabled = useMemo(() => {
    return props.signup ? !validateSignup() : !validateLogin();
  }, [props.signup, validateSignup, validateLogin]);

  const error = useMemo(() => {
    return apiError?.error ?? searchParams.get('error')
  }, [searchParams, apiError])

  const errorText = useErrorText(errorKey);
  const fbErrorText = useErrorText(AuthActionType.SubmitFbLogin);
  const googleErrorText = useErrorText(AuthActionType.SubmitGoogleLogin);

  return <>
    <form onSubmit={authFormSubmit}>
      <Typography
        variant="body2"
        sx={{
          textAlign: {
            xs: 'left',
            sm: 'center',
          },
          mb: 2,
        }}
      >{props.signup
        ? <>{t('Already have an account?')} <Link component="button" type="button" onClick={onClickLogin}>{t('Login')}</Link></>
        : <>{t('Don\'t have an account?')} <Link component="button" type="button" onClick={onClickSignup}>{t('Sign up')}</Link></>
        }</Typography>
      <Stack
        spacing={4}
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        divider={<Divider orientation={breakpointSmDown ? 'horizontal' : 'vertical'} flexItem />}
        sx={{
          px: {
            xs: 0,
            md: 6,
          },
          py: {
            xs: 0,
            md: 2,
          },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
            }
          }}
        >
          {props.signup ? <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newsletter}
                  name="newsletter"
                  onChange={onChangeNewsletter}
                />
              }
              label={t('Subscribe to our newsletter')}
              disabled={!!loader}
            />
          </div> : null}
          <div>
            <NoSsr>
              <Stack spacing={2}>
                <FacebookLoginButton
                  signup={props.signup}
                  newsletter={props.signup && newsletter}
                  onLoginSuccess={onLoginSuccess}
                  onSignupSuccess={onSignupSuccess}
                  onAccountLink={onAccountLink}
                />
                <GoogleOAuthProvider
                  clientId={config.GOOGLE_CLIENT_ID}
                >
                  <GoogleLoginButton
                    signup={props.signup}
                    newsletter={props.signup && newsletter}
                    onLoginSuccess={onLoginSuccess}
                    onSignupSuccess={onSignupSuccess}
                    onAccountLink={onAccountLink}
                  />
                </GoogleOAuthProvider>
              </Stack>
            </NoSsr>
          </div>

          {['fb-exists-error', 'fb-email-error', 'fb-error','fb-login-cancelled'].includes(fbApiError?.error)
            ? <Alert
              severity="error"
            >{fbErrorText}</Alert>
            : null
          }

          {['g-exists-error', 'g-error'].includes(googleApiError?.error)
            ? <Alert
              severity="error"
            >{googleErrorText}</Alert>
            : null
          }
        </Stack>
        <Stack
          spacing={2}
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
            },
            alignItems: 'flex-start',
          }}
        >
          {props.signup ? <TextField
            label={t('Full name')}
            value={name}
            onChange={onChangeName}
            error={['blank-name'].includes(error ?? '')}
            disabled={!!loader}
          /> : null}

          <TextField
            label={t('Email')}
            value={email}
            onChange={onChangeEmail}
            error={['bad-login', 'email-exists', 'blank-email'].includes(error ?? '')}
            helperText={apiError?.error === 'email-exists' ? errorText : undefined}
            type="email"
            autoComplete="email"
            disabled={!!loader}
          />

          <TextField
            label={t('Password')}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={onChangePassword}
            error={['bad-login', 'blank-password'].includes(error ?? '')}
            helperText={error === 'bad-login'
              ? t('This email/password combination doesn\'t exist.')
              : undefined
            }
            autoComplete={props.signup ? 'new-password' : 'current-password'}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  onClick={onClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }}
            disabled={!!loader}
          />

          {props.signup ? <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newsletter}
                  name="newsletter"
                  onChange={onChangeNewsletter}
                />
              }
              label={t('Subscribe to our newsletter')}
              disabled={!!loader}
            />
          </div> : <p>{t('Forgot your password?')} <Link component="button" type="button" onClick={onOpenForgotPassword}>{t('Click here')}</Link></p>}

          <Button
            color="accent"
            variant="contained"
            size="large"
            disabled={authDisabled || !!loader}
            type="submit"
          >{props.signup ? t('Sign up') : t('Login')}</Button>
        </Stack>
      </Stack>

      {props.signup
        ? <Typography
          variant="body2"
          sx={{
            textAlign: {
              xs: 'left',
              sm: 'center',
            },
            mt: 2,
          }}
        >{t('By signing up with Art of Where, you\'re agreeing to our')} <a href="/info/terms-of-service" target="_blank">{t('Terms of use')}.</a></Typography>
        : null}
    </form>
    <ForgotPasswordDialog
      opened={forgotPasswordDialogOpened}
      onClose={onCloseForgotPassword}
    />
  </>;
}