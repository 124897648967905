import { Button, Grid } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FetchPaginationItems } from '../../../UserData/_actions/UserDataActions'
import { AddRounded as AddRoundedIcon } from '@mui/icons-material'
import AddTaxNumberDialog from './AddTaxNumberDialog'
import TaxNumber from './TaxNumber'
import { GetUserTaxNumbers } from '../../../UserData/_actions/UserTaxNumberActions'
import AccountHeader from '../../AccountHeader'
import { useAppDispatch } from '../../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../../Common/_hooks/useAppSelector'
import { type UserTaxNumberStore } from '../../../UserData/_stores/UserTaxNumberStore'

export default function TaxNumberList() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')

  const taxNumbers = useAppSelector(state => state.get('userData').get('taxNumbers'))

  const [addDialogOpened, setAddDialogOpened] = useState(false)

  useEffect(() => {
    const formData = new FormData()
    formData.append('offset', String(0))
    formData.append('limit', String(20))

    dispatch(FetchPaginationItems('tax-numbers', formData, 'addresses'))
    dispatch(GetUserTaxNumbers())
  }, [])

  const onAddOpen = useCallback(() => {
    setAddDialogOpened(true)
  }, [])

  const onAddClose = useCallback(() => {
    setAddDialogOpened(false)
  }, [])

  const renderTaxNumber = useCallback((taxNumber: UserTaxNumberStore) => {
    return <Grid item xs={12} md={6} lg={4} key={taxNumber.get('id')}>
      <TaxNumber taxNumber={taxNumber} />
    </Grid>
  }, [])

  return <>
    <AccountHeader
      title={t('Tax numbers')}
      actions={<Button
        variant="contained"
        color="accent"
        startIcon={<AddRoundedIcon />}
        onClick={onAddOpen}
      >{t('Add Tax number')}</Button>}
    />

    <Grid container spacing={2}>
      {taxNumbers.valueSeq().map(renderTaxNumber)}
    </Grid>

    <AddTaxNumberDialog
      opened={addDialogOpened}
      onClose={onAddClose}
    />
  </>
}
