import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import EditProductDetails, { Props } from '../_components/EditProductDetails';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
});

export default connect(
	mapStateToProps,
	null
)(EditProductDetails);