import i18next from 'i18next';
import { createAction } from '../../Common/_actions/Action';
import { createAjaxAction } from '../../Common/_actions/AjaxAction';
import { Toast } from '../../UIData/_actions/UIDataActions';

export enum AppDataActionType {
	EditAppData = "APP_DATA_EDIT",
	EditProductLabData = 'APP_DATA_EDIT_PRODUCT_LAB_DATA',

	GetAppData = 'GET_APP_DATA',
}

export const EditAppData = (data: object) => createAction(AppDataActionType.EditAppData, {
	data: data
});

export const EditProductLabData = (id: number, data: any) => createAction(AppDataActionType.EditProductLabData, {
	id: id,
	data: data,
});

export const GetAppData = () => createAjaxAction(AppDataActionType.GetAppData, {
	url: "ajax/api/get-app-data",
	method: "GET",
	raw: true,
	preventDuplicates: true,
	onSuccess: (response, dispatch) => {
		dispatch(EditAppData(response));
	},
	onFailure: (response, dispatch) => {
		dispatch(Toast(i18next.t('Something went wrong!')));
		console.log(response);
		throw "Could not retrieve app data.";
	}
});

export type AppDataAction = 
	ReturnType<typeof EditAppData> |
	ReturnType<typeof EditProductLabData>