import Point from "./Point";

export default function rotatePoint(origin:Point, point:Point, angle:number) {
	//Convert angle to radians
	angle = angle * Math.PI / 180;

	//Difference between point and origin
	var dx = point.x - origin.x;
	var dy = point.y - origin.y;

	//Calculate difference after rotation
	var new_dx = dx * Math.cos(angle) - dy * Math.sin(angle);
	var new_dy = dx * Math.sin(angle) + dy * Math.cos(angle);

	var newPoint = {
		x: new_dx + origin.x,
		y: new_dy + origin.y
	};
	
	return newPoint;
};