import { Alert, AlertTitle, Box, Button, Collapse, Grid, Stack, Typography, styled } from "@mui/material"
import DineroFactory, { Dinero } from "dinero.js"
import { useCallback, useMemo, useState } from "react"
import { UpdateCartItem } from "../UserData/_actions/UserCartActions"
import { useAppDispatch } from "../Common/_hooks/useAppDispatch"
import AddToCartButton from "../Cart/AddToCartButton"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const Price = styled('div')(({ theme }) => ({
  fontSize: '22px',
  fontWeight: '600',
  color: theme.palette.accent.main
}))

interface Props {
  type: string
  price: Dinero
  img: string
  title: string
  text: string
}

export default function SamplePack(props:Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()
  const [tR] = useTranslation('routes')

  const [addedToCart,setAddedToCart] = useState(false)

  const onAdd = useCallback((cartId?: number, newCartName?: string) => {
    const fd = new FormData()

    if (cartId != null) {
      fd.append('cart_id', String(cartId))
    } else if (newCartName) {
      fd.append('cart_name', newCartName)
    }

    fd.append('quantity', '1')
    fd.append('type', props.type)

    dispatch(UpdateCartItem(fd).set({
      onSuccess: () => {
        setAddedToCart(true)
      }
    }))
  }, [props.type])

  return <Box 
    component="div"
    sx={{
      p: {
        xs: 3,
        sm: 4
      }
    }}
  >
    <Grid container spacing={3}>
      <Grid item xs={12} sm={5}>
        <img
          src={props.img}
          alt={t('Picture of a {{title}}', {title: props.title})}
          style={{
            display: 'block',
            width: '100%',
            height: 'auto',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={7}>
        <Stack spacing={1} sx={{ alignItems: 'flex-start' }}>
          <Typography variant="h3">{props.title}</Typography>
          <Typography variant="body1">{props.text}</Typography>
          <Price>{props.price.toFormat()}</Price>

          <Collapse in={addedToCart}>
            <Stack spacing={2}>
              <Alert
                severity="success"
              ><AlertTitle>{t('Added to cart')}</AlertTitle>
                {'1x ' + props.title}
              </Alert>
              <Stack
                direction="row"
                spacing={2}
              >
                <Button
                  variant="contained"
                  color="accent"
                  component={Link}
                  to={tR('/cart')}
                  reloadDocument
                >{t('Go to Cart')}</Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setAddedToCart(false)}
                >{t('Order more')}</Button>
              </Stack>
            </Stack>
          </Collapse>
          
          <Collapse in={!addedToCart}>
            <AddToCartButton onAdd={onAdd} />
          </Collapse>
        </Stack>
      </Grid>
    </Grid>
  </Box>
}