import { useCallback, useMemo } from "react";
import DesignLabLayerStore from "../../UIData/_stores/DesignLab/DesignLabLayerStore";
import useLabData from "./useLabData";
import useSceneData from "./useSceneData";
import { useSelector } from "react-redux";
import WebsiteStore from "../../WebsiteStore";

export default function useCheckDpi() {
    const labData = useLabData();
    const activeVariant = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeVariant'));

    return useCallback((layer:DesignLabLayerStore):{ 
        dpi: number,
        minDpi: number,
        dpiIndicator: 'bad'|'ok'|'good',
        unavailableVariants: string[],
    } => {
        if(!labData) return {
            dpi: 0, 
            minDpi: 0,
            dpiIndicator: 'bad',
            unavailableVariants: [],
        };

        const sceneData = labData?.variants[activeVariant]?.scenes[layer.get('scene')];

        let dpi = 0;
        if(layer.get('type') === 'img' && labData && sceneData) {
            dpi = layer.get('originalWidth') / (layer.get('width')*layer.get('scaleX')) * sceneData.ratio;
            dpi = dpi * (labData.dpi / 300);
            dpi = Math.round(dpi);
        }
        
        // Minimum DPI is 100 at 150 dpi and 200 at 300 dpi.
        let minDpi = 0;
        if(layer.get('type') === 'img' && labData) {
            minDpi = labData.dpi - (50 * (labData.dpi / 150));
        }

        //MinDPI based on ratio variants (wall art)
        let product = window.Products.getProduct(labData.id);
        if(typeof product.getMinDPI == 'function') {
            let variantMinDpi = product.getMinDPI(activeVariant);
            if(typeof variantMinDpi != 'number'){
                Object.keys(variantMinDpi).forEach(key => {
                    if(variantMinDpi[key] < minDpi) {
                        minDpi = variantMinDpi[key];
                    }
                });
			} else {
                minDpi = variantMinDpi;
            }
        }
        
        let dpiIndicator:'bad'|'ok'|'good' = 'good';
        let unavailableVariants = [];
        if(layer.get('type') === 'img' && labData) {
            if(dpi < minDpi){
                dpiIndicator = 'bad';
            } else if (dpi >= minDpi && dpi < labData.dpi) {
                dpiIndicator = 'ok';
            } else if (dpi >= labData.dpi) {
                dpiIndicator = 'good';
            }

            if(typeof product.getUnavailableVariants == 'function'){
                unavailableVariants = product.getUnavailableVariants(activeVariant, dpi);
                if(unavailableVariants > 0 && dpiIndicator === 'good') {
                    dpiIndicator = 'ok';
                }
            }
        }

        return {
            dpi: dpi, 
            minDpi: minDpi, 
            dpiIndicator: dpiIndicator,
            unavailableVariants: unavailableVariants,
        };
    }, [labData, activeVariant]);
}