import { Checkbox, FormControlLabel, Grid, styled } from '@mui/material';
import Tab from '@mui/material/Tab';
import * as Immutable from 'immutable';
import * as React from 'react';
import { Trans, Translation } from 'react-i18next';
import ProductStore from '../../AppData/_stores/ProductStore';
import { AjaxCallback } from '../../Common/_actions/AjaxAction';
import { ButtonBlackHeight45, ButtonWhiteHeight45, PreviousButton } from '../../Common/_components/Buttons';
import { BlackCheckedIcon, UncheckedWhiteBackgroundIcon } from '../../Common/_components/CheckBoxes';
import { TabsPinkFullWidth } from '../../Common/_components/Tabs';
import { OptionLabel } from '../../Common/_components/Typography';
import VariantPriceStore from '../../Dashboard/_stores/VariantPriceStore';
import { UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore';
import UserStorePresetStore from '../../UserData/_stores/UserStorePresetStore';
import EditProductDetailsContainer from '../_containers/EditProductDetailsContainer';
import EditProductPricesContainer from '../_containers/EditProductPricesContainer';
import ListingStore from '../_stores/ListingStore';
import PriceStore from '../../UIData/_stores/PriceStore';

export type Props = {
	index ?: number
	handleStep ?: (step:number) => void
	massPresets ?: boolean
	isSingle ?: boolean
	handleCloseModal ?: () => void
	listing?: ListingStore
	listings?: Immutable.Map<string, ListingStore>
	product?: ProductStore
	productDesign?: UserProductDesignStore
	storePreset?: UserStorePresetStore
	store?: string
	loader?: boolean

	editProductListing?: Function
	saveStorePreset?: (store: string, jsonData: string, successCallback:AjaxCallback) => void
}

type State = {
	tabValue: number
	disableButtons: boolean

	title: string
	description: string
	price: PriceStore
	squarespace_store_page_id?: number
	update_preset: boolean
	disabled_preview_ids: Array<number>
	disabled_option_ids: Array<number>
	variant_prices: Immutable.Map<string, VariantPriceStore>

	applyToAll: boolean
}

const PreviousButtonContainer = styled(Grid)`
	margin-top: -22px;
	@media(max-width:960px){
		margin-top: -2px;
	}
`;
const TabsDiv = styled('div')`
	margin-bottom: 30px;
`;
const ButtonsGrid = styled(Grid)`
	display: flex;
	flex-direction: row;
	gap: 10px;
	width: 90%;
`;
const MassSendingMainButtonContainer = styled('div')`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items:center;
	background: #FFFFFF;
	box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
	border-radius: 0px 0px 30px 30px;
	width: 100%;
	bottom: 0;
	left: 0;
	text-align: center;
	padding: 12px;

	@media (max-width: 959px){
		background-color: transparent;
		box-shadow: none;
	}
`;

const MarginTopSaveButton = styled('div')`
	margin-top: 60px;
`;

const PinkButton = styled('button')`
	height: 45px;
	background: linear-gradient(0deg, #DA3192 0%, #F600BE 100%);
	border-radius: 22.5px;
	border: none;
	color: #ffffff;
	width: 90%;
	&:hover{
		background: #e83cae;
	}
	&:focus{
		background: linear-gradient(-180deg, #DA3192 0%, #F600BE 100%);
	}

	&[disabled] {
		background: #AAAFB4;
	}
`;
const ScrollableContent = styled('div')`
	padding: 50px 60px 0px;
	width: 100%;
	height: calc(100% - 69px);
	overflow: auto;

	@media (max-width: 1200px){
		padding: 44px 40px 0px;
	}

	@media (max-width: 959px) {
		height: auto;
		padding: 0;
		overflow: visible;
		padding-bottom: 60px;
	}
`;

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
  }
  
function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <div>
			{children}
		  </div>
		)}
	  </div>
	);
}

function a11yProps(index: number) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
}

const T = ({children, ...otherProps}:any) => <Trans ns="dashboard" {...otherProps}>{children}</Trans>

export default class EditProduct extends React.Component<Props> {

	public state = {
		tabValue: 0,
		disableButtons: false,

		title: "",
		description: "",
		price: new PriceStore(),
		update_preset: false,
		squarespace_store_page_id: undefined,
		disabled_preview_ids: [],
		disabled_option_ids: [],
		variant_prices: Immutable.Map<string, VariantPriceStore>(),

		applyToAll: false,
	} as State

	componentDidMount = () => {
		if(this.props.massPresets){
			if(this.props.storePreset) {
				this.setState({
					description: this.props.storePreset.get('description'),
					price: this.props.storePreset.get('price'),
					disabled_preview_ids: this.props.storePreset.get('disabled_preview_ids').valueSeq().toArray(),
					disabled_option_ids: this.props.storePreset.get('disabled_option_ids').valueSeq().toArray(),
					variant_prices: this.props.storePreset.get('variant_prices'),
				});
			}
		} else {
			if(this.props.listing) {
				this.setState({
					title: this.props.listing.get('title'),
					description: this.props.listing.get('description'),
					price: this.props.listing.get('price'),
					squarespace_store_page_id: this.props.listing.get('squarespace_store_page_id'),
					update_preset: this.props.listing.get('update_preset'),
					disabled_preview_ids: this.props.listing.get('disabled_preview_ids').valueSeq().toArray(),
					disabled_option_ids: this.props.listing.get('disabled_option_ids').valueSeq().toArray(),
					variant_prices: this.props.listing.get('variant_prices'),
				});
			}
		}
	}

	componentDidUpdate(prevProps:Props) {
		if(this.props.massPresets){
			if(this.props.storePreset && (!prevProps.storePreset || prevProps.storePreset.get('id_product') != this.props.storePreset.get('id_product'))) {
				this.setState({
					description: this.props.storePreset.get('description'),
					price: this.props.storePreset.get('price'),
					disabled_preview_ids: this.props.storePreset.get('disabled_preview_ids').valueSeq().toArray(),
					disabled_option_ids: this.props.storePreset.get('disabled_option_ids').valueSeq().toArray(),
					variant_prices: this.props.storePreset.get('variant_prices'),
				});
			}
		} else {
			if(this.props.listing && (!prevProps.listing || prevProps.listing.get('id') != this.props.listing.get('id'))) {
				this.setState({
					title: this.props.listing.get('title'),
					description: this.props.listing.get('description'),
					price: this.props.listing.get('price'),
					squarespace_store_page_id: this.props.listing.get('squarespace_store_page_id'),
					update_preset: this.props.listing.get('update_preset'),
					disabled_preview_ids: this.props.listing.get('disabled_preview_ids').valueSeq().toArray(),
					disabled_option_ids: this.props.listing.get('disabled_option_ids').valueSeq().toArray(),
					variant_prices: this.props.listing.get('variant_prices'),
				});
			}
		}
	}

	handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		this.setState({tabValue: newValue })
	  };

	private handleGoPreviousState = () => {
		this.props.handleStep(this.props.index - 1)
	}
	private handleCancelChanges = () => {
		this.props.massPresets ? this.props.handleCloseModal() : this.props.handleStep(this.props.index - 1)
	}
	private handleSaveChanges = () => {
		if(this.props.massPresets) {
			let jsonData = JSON.stringify({
				id: this.props.storePreset.get('id'),
				id_product: this.props.product.get('id'),
				description: this.state.description,
				price: this.state.price,
				disabled_preview_ids: this.state.disabled_preview_ids,
				disabled_option_ids: this.state.disabled_option_ids,
				variant_prices: this.state.variant_prices,
			});

			this.props.saveStorePreset(this.props.store, jsonData, (response, dispatch) => {
				this.props.handleCloseModal();
			});
		} else {
			this.props.editProductListing(
				this.props.listing.get('id_product_design'),
				this.props.listing.get('id_product'),
				this.state.title,
				this.state.description,
				this.state.price,
				this.state.update_preset,
				this.state.disabled_preview_ids,
				this.state.disabled_option_ids,
				this.state.variant_prices,
			);

			if(this.state.applyToAll) {
				//Update other listings 
				let listings = this.props.listings.filter((listing) => 
					listing.get('id_product') === this.props.listing.get('id_product')
					&& listing.get('id') !== this.props.listing.get('id'));

				listings.forEach((listing) => {
					this.props.editProductListing(
						listing.get('id_product_design'),
						listing.get('id_product'),
						listing.get('title'),
						this.state.description,
						this.state.price,
						this.state.update_preset,
						this.state.disabled_preview_ids,
						this.state.disabled_option_ids,
						this.state.variant_prices,
					);				
				});
			}

			this.props.massPresets ? this.props.handleStep(this.props.index + 1) : this.props.handleStep(this.props.index - 1);
		}
	}

	handleDisableButtons = () =>{
		this.setState({disableButtons: true})
	}
	handleEnableButtons = () => {
		this.setState({disableButtons: false})
	}

	private onFormChange = (key:keyof State, value: any) => {
		let newState:{[key:string]: any} = {};
		
		newState[key] = value;
		newState[key+'_changed'] = true;
		
		this.setState(newState);
	}

	render = () => {
		if(this.props.massPresets && !this.props.product) {
			return null;
		}

		let nbProducts = 0;

		if(!this.props.massPresets && this.props.listings) {
			nbProducts = this.props.listings.filter((listing) => 
				listing.get('id_product') === this.props.listing.get('id_product')
			).count();
		}

		let previews = this.props.massPresets ? this.props.product.get('previews') : this.props.productDesign.get('previews');

		//Check if any option type have 0 options
		let availableOptionTypes = this.props.massPresets ? 
			this.props.product.get('availableOptionTypes') 
			: this.props.productDesign.get('availableOptionTypes');

		let emptyOptionType = false;
		availableOptionTypes.forEach((type) => {
			let atLeastOneOption = false;
			type.get('options').forEach((option) => {
				if(this.state.disabled_option_ids.indexOf(option.get('id')) === -1) {
					atLeastOneOption = true;
				}
			});

			if(!atLeastOneOption) {
				emptyOptionType = true;
			}
		});

		return <>
			<ScrollableContent>
				<Grid container spacing={4} direction="column">
					{!this.props.massPresets && !this.props.isSingle && 
						<PreviousButtonContainer item>
							<PreviousButton onClick={this.handleGoPreviousState}>
								{"< "}
								<T>Back to Selected Products</T>
							</PreviousButton>
						</PreviousButtonContainer>
					}
					
					<Grid item>
						<Translation ns='dashboard'>{t =>
							<TabsDiv>
								<TabsPinkFullWidth value={this.state.tabValue} onChange={this.handleChangeTab} aria-label="basic tabs example" indicatorColor="secondary" textColor="inherit">
									<Tab label={t("Details")} {...a11yProps(0)} />
									<Tab label={t("Prices")} {...a11yProps(1)} />
								</TabsPinkFullWidth>
							</TabsDiv>}
						</Translation>
						<TabPanel value={this.state.tabValue} index={0}>
							<EditProductDetailsContainer 
								listing={this.props.listing}
								product={this.props.product}
								productDesign={this.props.productDesign}
								title={this.state.title}
								description={this.state.description}
								update_preset={this.state.update_preset}
								disabled_preview_ids={this.state.disabled_preview_ids}
								disabled_option_ids={this.state.disabled_option_ids}
								onFormChange={this.onFormChange}
								massPresets={this.props.massPresets}
							/>
						</TabPanel>
						<TabPanel value={this.state.tabValue} index={1}>
							<EditProductPricesContainer 
								listing={this.props.listing}
								product={this.props.product}
								productDesign={this.props.productDesign}
								price={this.state.price}
								variant_prices={this.state.variant_prices}
								onFormChange={this.onFormChange}
								disableButtons={this.handleDisableButtons} 
								enableButtons={this.handleEnableButtons} 
								massPresets={this.props.massPresets}
							/>
						</TabPanel>
					</Grid>

					{ !this.props.massPresets && nbProducts > 1 && <Grid item>
						<FormControlLabel
							control={
								<Checkbox
									color="secondary"
									checked={this.state.applyToAll}
									name="ask-update-selected-products"
									icon={<UncheckedWhiteBackgroundIcon />}
									checkedIcon={<BlackCheckedIcon />}
								/>
							}
							label={<OptionLabel>
								<T>Apply changes to all</T> ({ nbProducts }) { this.props.product.get('name') } <T>products you selected</T>
							</OptionLabel>}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ applyToAll: e.target.checked })}
						/>
					</Grid> }

				</Grid>
			</ScrollableContent>

			<MassSendingMainButtonContainer>
				{ !this.props.isSingle ? <ButtonsGrid>
					<ButtonWhiteHeight45 onClick={() => this.handleCancelChanges()}><T>Cancel</T></ButtonWhiteHeight45>
					<ButtonBlackHeight45 
						onClick={() => this.handleSaveChanges()} 
						disabled={
							this.state.disableButtons 
							|| (!this.props.massPresets && this.state.title === "")
							|| this.state.description === ""
							|| (previews.count() > 0 && this.state.disabled_preview_ids.length >= previews.count())
							|| emptyOptionType
						}
					><T>Save Changes</T></ButtonBlackHeight45>
				</ButtonsGrid>
				: <PinkButton disabled={
					this.state.disableButtons 
					|| this.state.title === ""
					|| this.state.description === ""
					|| (previews.count() > 0 && this.state.disabled_preview_ids.length >= previews.count())
					|| emptyOptionType
					|| this.props.loader
				} onClick={() => this.props.handleStep(this.props.index + 1)}>
					<T>Save</T> &amp; <T>continue</T>
				</PinkButton> }
			</MassSendingMainButtonContainer>
		</>
	}
}