import { useDispatch, useSelector } from 'react-redux'
import { SetFixedViewTime } from '../_actions/ModelViewerStoreActions';
import WebsiteStore from '../../WebsiteStore';
import ModelViewerStore from '../_stores/ModelViewerStore';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

export default function useFixedViewTime(): [ModelViewerStore['fixedViewTime'], (view: ModelViewerStore['fixedViewTime']) => void] {
    const dispatch = useAppDispatch();
    const fixedViewTime = useSelector((state: WebsiteStore) => state.get('UIData').get('designLab').get('modelViewer').get('fixedViewTime'))
    return [
        fixedViewTime,
        (time: number) => dispatch(SetFixedViewTime(time)),
    ]
}