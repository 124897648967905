import * as Immutable from 'immutable'
import ProductOptionTypeStore from './ProductOptionTypeStore'
import PreviewStore from './PreviewStore'
import ProductVariantStore from './ProductVariantStore'
import ViewerVersionStore from './ViewerVersionStore'
import ProductDetailStore from './ProductDetailStore'
import PriceStore from '../../UIData/_stores/PriceStore'

export interface IProductStore {
	id: number
	id_product_category: number,
	id_product_subcategory: number,
	slug: string
	url_slug: string
	name: string
	shop_description: string,
	production_time: number,
	has_subproducts: boolean,
	theorder: number
	visible: boolean
	price_retail: PriceStore
	price_wholesale: PriceStore
	price_dropship: PriceStore
	availableOptionTypes: Immutable.OrderedMap<string, ProductOptionTypeStore>
	variants: Immutable.OrderedMap<string, ProductVariantStore>
	previews: Immutable.OrderedMap<number, PreviewStore>
	viewCategories: Immutable.Set<number>,
	viewerVersion: ViewerVersionStore,
	details: Immutable.OrderedMap<string, ProductDetailStore>
	labData?: Record<string, any>,
}

const defaultProductStore = Immutable.Record<IProductStore>({
	id: 0,
	id_product_category: 0,
	id_product_subcategory: 0,
	slug: '',
	url_slug: '',
	name: '',
	shop_description: '',
	production_time: 0,
	has_subproducts: false,
	theorder: 0,
	visible: false,
	price_retail: new PriceStore(),
	price_wholesale: new PriceStore(),
	price_dropship: new PriceStore(),
	availableOptionTypes: Immutable.OrderedMap<string, ProductOptionTypeStore>(),
	variants: Immutable.OrderedMap<string, ProductVariantStore>(),
	previews: Immutable.OrderedMap<number, PreviewStore>(),
	viewCategories: Immutable.Set<number>(),
	viewerVersion: new ViewerVersionStore(),
	details: Immutable.OrderedMap<string, ProductDetailStore>(),
	labData: undefined,
});

export default class ProductStore extends defaultProductStore implements IProductStore {}