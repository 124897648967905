import SkeletonLoader, { type SkeletonLoaderProps } from '../../Common/Loader/SkeletonLoader'
import { styled } from '@mui/material'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'

const SummaryLineLoader = styled<typeof SkeletonLoader>((props: SkeletonLoaderProps) => {
  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))

  return <SkeletonLoader
    loading={cartLoader}
    variant="rounded"
    width={90}
    height={15}
    {...props}
  />
})()

export default SummaryLineLoader
