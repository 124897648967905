import { Rect, RectConfig } from 'konva/lib/shapes/Rect';
import { ForwardedRef, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Rect as RectNode } from 'react-konva';
import { LayerPatternMode } from '../../UIData/_stores/DesignLab/DesignLabLayerStore';
import { isBrowser, isNode } from "browser-or-node";
import { ImageLibraryContext } from '../_components/ImageLibrary';
import createCanvas from '../Utils/createCanvas';

export type Props = {
    src: string
    imageWidth: number
    imageHeight: number
    patternMode?:LayerPatternMode
    onLoad?: () => void
} & RectConfig;

const KonvaPatternImage = forwardRef(function KonvaPatternImage(props:Props, ref:ForwardedRef<Rect|null>) {
    const innerRef = useRef<Rect|null>(null);
    const patternCanvasRef = useRef<Canvas|Image>(createCanvas(props.imageWidth, props.imageHeight));
    const imageRef = useRef<Image|null>(null);
    const [image,setImage] = useState<Canvas|null>(null);
    const imageLibrary = useContext(ImageLibraryContext)

    const repeat = useMemo(() => {
        switch(props.patternMode) {
            case 'repeat-mirror':
            case 'repeat-mirror-x':
            case 'repeat-mirror-y':
            case 'half-drop':
            case 'half-brick':
                return 'repeat';

            case 'repeat-x-mirror-x':
                return 'repeat-x';

            case 'repeat-y-mirror-y':
                return 'repeat-y';

            default:
                return props.patternMode;
        }
    }, [props.patternMode])

    const handleLoad = useCallback(() => {
        if(imageRef.current === null) return

        patternCanvasRef.current = createCanvas(props.imageWidth, props.imageHeight);
        const patternCtx = patternCanvasRef.current.getContext("2d");

        switch(props.patternMode) {
            case 'repeat-mirror':
                patternCanvasRef.current.width = props.imageWidth*2;
                patternCanvasRef.current.height = props.imageHeight*2;
                patternCtx.drawImage(imageRef.current, 0, 0, props.imageWidth, props.imageHeight);

                patternCtx.save();
                patternCtx.scale(-1, 1);
                patternCtx.drawImage(imageRef.current, -props.imageWidth*2, 0, props.imageWidth, props.imageHeight);
                patternCtx.restore();

                patternCtx.scale(1, -1);
                patternCtx.drawImage(imageRef.current, 0, -props.imageHeight*2, props.imageWidth, props.imageHeight);
                patternCtx.scale(-1, 1);
                patternCtx.drawImage(imageRef.current, -props.imageWidth*2, -props.imageHeight*2, props.imageWidth, props.imageHeight);
                break;

            case 'repeat-mirror-x':
            case 'repeat-x-mirror-x':
                patternCanvasRef.current.width = props.imageWidth*2;
                patternCanvasRef.current.height = props.imageHeight;
                patternCtx.drawImage(imageRef.current, 0, 0, props.imageWidth, props.imageHeight);

                patternCtx.scale(-1, 1);
                patternCtx.drawImage(imageRef.current, -props.imageWidth*2, 0, props.imageWidth, props.imageHeight);
                break;

            case 'repeat-mirror-y':
            case 'repeat-y-mirror-y':
                patternCanvasRef.current.width = props.imageWidth;
                patternCanvasRef.current.height = props.imageHeight*2;
                patternCtx.drawImage(imageRef.current, 0, 0, props.imageWidth, props.imageHeight);

                patternCtx.scale(1, -1);
                patternCtx.drawImage(imageRef.current, 0, -props.imageHeight*2, props.imageWidth, props.imageHeight);
                break;

            case 'half-drop':
                patternCanvasRef.current.width = props.imageWidth*2;
                patternCanvasRef.current.height = props.imageHeight;
                patternCtx.drawImage(imageRef.current, 0, 0, props.imageWidth, props.imageHeight);
                patternCtx.drawImage(imageRef.current, props.imageWidth, -Math.floor(props.imageHeight/2), props.imageWidth, props.imageHeight);
                patternCtx.drawImage(imageRef.current, props.imageWidth, Math.floor(props.imageHeight/2), props.imageWidth, props.imageHeight);
                break;

            case 'half-brick':
                patternCanvasRef.current.width = props.imageWidth;
                patternCanvasRef.current.height = props.imageHeight*2;
                patternCtx.drawImage(imageRef.current, 0, 0, props.imageWidth, props.imageHeight);
                patternCtx.drawImage(imageRef.current, -Math.floor(props.imageWidth/2), props.imageHeight, props.imageWidth, props.imageHeight);
                patternCtx.drawImage(imageRef.current, Math.floor(props.imageWidth/2), props.imageHeight, props.imageWidth, props.imageHeight);
                break;

            default:
                patternCanvasRef.current.width = props.imageWidth;
                patternCanvasRef.current.height = props.imageHeight;
                patternCtx.drawImage(imageRef.current, 0, 0, props.imageWidth, props.imageHeight);
                break;
        }

        if(isBrowser) {
            setImage(patternCanvasRef.current);
        } else if(isNode) {
            // ---For skia-canvas only---
            // We convert to Image because the resolution gets messed up with skia-canvas when using a canvas
            // TODO: This is normally an async process but based on how skia-canvas is written, it seems like it should always work. 
            // May want to handle this more cleanly in the future, such as adding loadImageSync to skia-canvas
            /*let img = new Image({
                raw: {
                    width: props.imageWidth*2,
                    height: props.imageHeight*2,
                }
            })
            img.src = patternCanvasRef.current.toBufferSync('raw')
            patternCanvasRef.current = img;*/
        }

        //Apply filters to pattern canvas
        if(props.filters !== undefined && props.filters.length > 0) {
            const imageData = patternCtx.getImageData(0, 0, patternCanvasRef.current.width, patternCanvasRef.current.height);
            for(let filter of props.filters) {
                filter.call(innerRef.current ?? new Rect(props), imageData);
                patternCtx.putImageData(imageData, 0, 0);
            }
        }
    }, [props.patternMode, props.imageWidth, props.imageHeight, props.filters]);

    useEffect(() => {
        if(isBrowser) {      
            let tmpImg = new window.Image();

            //Bypassing ts checks here as I can't figure out a way to map this node-canvas' Image object for the types to work
            //@ts-ignore
            imageRef.current = tmpImg;

            tmpImg.crossOrigin = 'anonymous';
            tmpImg.addEventListener('load', handleLoad);
            tmpImg.src = props.src;

            return () => {
                tmpImg.removeEventListener('load', handleLoad);
                imageRef.current = null;
            }
        }
    }, [props.src]);

    useEffect(() => {
       if(!isBrowser) return;
       handleLoad(); 
    }, [handleLoad]);

    if(isNode) {
        //@ts-ignore
        imageRef.current = imageLibrary.retrieveImage(props.src);
        handleLoad();
    }

    useEffect(() => {
        props.onLoad && props.onLoad();
    }, [image]);

    useImperativeHandle(ref, () => innerRef.current, [])

    const {src, width, height, fillPatternX, fillPatternY, offsetX, offsetY, filters, ...otherProps} = props;

    const newWidth = useMemo(() => {
        if(repeat !== undefined && ['no-repeat','repeat-y'].includes(repeat)) {
            return props.imageWidth*(props.fillPatternScaleX ?? 1)
        }

        return width ?? 0;
    }, [repeat, width, props.imageWidth, props.fillPatternScaleX]);

    const [newPatternX, newOffsetX] = useMemo(() => {
        if(repeat !== undefined && ['no-repeat','repeat-y'].includes(repeat)) {
            return [newWidth/2, newWidth/2];
        }

        return [fillPatternX, offsetX];
    }, [repeat, newWidth, fillPatternX, offsetX]);

    const newHeight = useMemo(() => {
        if(repeat !== undefined && ['no-repeat','repeat-x'].includes(repeat)) {
            return props.imageHeight*(props.fillPatternScaleY ?? 1)
        }

        return height ?? 0;
    }, [repeat, height, props.imageHeight, props.fillPatternScaleY]);

    const [newPatternY, newOffsetY] = useMemo(() => {
        if(repeat !== undefined && ['no-repeat','repeat-x'].includes(repeat)) {
            return [newHeight/2, newHeight/2];
        }

        return [fillPatternY, offsetY];
    }, [repeat, newHeight, fillPatternY, offsetY]);
    
    return <RectNode ref={innerRef}
        //Konva says it doesn't support Canvas but it does work
        //@ts-ignore
        fillPatternImage={isNode ? patternCanvasRef.current : image}
        fillPatternRepeat={repeat !== 'none' ? 'repeat' : 'none'}
        width={newWidth}
        height={newHeight}
        fillPatternX={newPatternX}
        fillPatternY={newPatternY}
        offsetX={newOffsetX}
        offsetY={newOffsetY}
        {...otherProps}
    />
});

export default KonvaPatternImage;