import * as Immutable from 'immutable';

export type PatternPayload = {
    id: string,
	name: string,
	thumbnail_src: string,
	repeat_src: string,
	repeat_width: number,
	repeat_height: number,
	recolorable: boolean,
	categoryId: number,
	horizontalRapportsPostcard: number,
}

export interface IPatternStore {
	id: string,
	name: string,
	thumbnail_src: string,
	repeat_src: string,
	repeat_width: number,
	repeat_height: number,
	recolorable: boolean
	colors: Immutable.List<string>
	categoryId: number,
	horizontalRapportsPostcard: number,
}

const defaultStore = Immutable.Record<IPatternStore>({
	id: '',
	name: '',
	thumbnail_src: '',
	repeat_src: '',
	repeat_width: 0,
	repeat_height: 0,
	recolorable: false,
	colors: Immutable.List<string>(),
	categoryId: 0,
	horizontalRapportsPostcard: 1,
});

export default class PatternStore extends defaultStore implements IPatternStore {}