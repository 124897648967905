import { useCallback } from "react";
import { useSelector } from "react-redux";
import WebsiteStore from "../../WebsiteStore";
import { LAB_TEMPLATE_HEIGHT } from "../../config";
import useLabData from "./useLabData";
import useSceneData from "./useSceneData";

export default function useCalcFitTemplate() {
    const activeScene = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeScene'));
    const activeProductId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
    const productData = useSelector((state:WebsiteStore) => state.get('appData').get('products').get(String(activeProductId)));
    const labData = useLabData();
    const sceneData = useSceneData();

    return useCallback((layerWidth: number, layerHeight: number) => {
        //Defaults to first part
        const listParts = Object.keys(sceneData.parts);
        const part = listParts[0];
        const outputType = 'lab';

        let deltax = sceneData.parts[part][outputType].x;
        let deltay = sceneData.parts[part][outputType].y;

        let width = sceneData.parts[part][outputType].width,
            height = sceneData.parts[part][outputType].height;
        
        if(!labData.fit_accurate) {
            width += 2;
            height += 2;
            deltax -= 1;
            deltay -= 1;
        }

        // Products with sides but also a "fit all"
        if(labData.fit_all){
            var originalWidth = listParts.length > 1 ? sceneData.parts[listParts[1]][outputType].width + sceneData.parts[listParts[1]][outputType].x - sceneData.parts[listParts[0]][outputType].x 
                : sceneData.parts[listParts[0]][outputType].width;
            var originalHeight = listParts.length > 1 ? sceneData.parts[listParts[1]][outputType].height + sceneData.parts[listParts[1]][outputType].y - sceneData.parts[listParts[0]][outputType].y 
            : sceneData.parts[listParts[0]][outputType].height;

            //Inverse cause it's rotate fit
            width = originalWidth;
            height = originalHeight;
            deltax += width/2;
            deltay += height/2;
        } else {
            deltax += width/2;
            deltay += height/2;
        }

        //Keep ratio of image
        let scale = height / layerHeight;

        if(layerWidth*scale < width) {
            scale = width / layerWidth;
        }

        return {
            x: deltax,
            y: deltay,
            scaleX: scale,
            scaleY: scale,
            rotation: 0,
        }
    }, [activeScene, labData, sceneData]);
}