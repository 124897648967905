import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ItemList from '../../../Common/ItemList/ItemList'
import { Button, Grid, Skeleton } from '@mui/material'
import { AddRounded as AddRoundedIcon } from '@mui/icons-material'
import EditAddressDialog from './EditAddressDialog'
import Address from './Address'
import { type UserAddressStore } from '../../../UserData/_stores/UserAddressStore'
import AccountHeader from '../../AccountHeader'

const LIMIT = 12

export default function AddressList() {
  const [t] = useTranslation('account-v2')

  const [addDialogOpened, setAddDialogOpened] = useState(false)

  const loadingGrid = useMemo(() => {
    const listSkeletons = []

    for (let i = 0; i < LIMIT; i++) {
      listSkeletons.push(<Grid key={i} item xs={12} md={6} lg={4}>
        <Skeleton variant="rounded" height={198} />
      </Grid>)
    }

    return listSkeletons
  }, [])

  const onAddOpen = useCallback(() => {
    setAddDialogOpened(true)
  }, [])

  const onAddClose = useCallback(() => {
    setAddDialogOpened(false)
  }, [])

  const renderAddress = useCallback((item: UserAddressStore) => {
    return <Address address={item} />
  }, [])

  const renderList = useCallback((items: JSX.Element | null, links: JSX.Element | null, loading: boolean) => {
    return <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {loading
          ? loadingGrid
          : (items ?? <Grid item xs={12}>
            <span></span>
          </Grid>)
        }
      </Grid>

      {links}
    </>
  }, [loadingGrid])

  return <div>
    <AccountHeader
      title={t('Addresses')}
      actions={<Button
        variant="contained"
        color="accent"
        startIcon={<AddRoundedIcon />}
        onClick={onAddOpen}
      >{t('Add address')}</Button>}
    />
    <ItemList
      dataType="addresses"
      item={renderAddress}
      limit={LIMIT}
      render={renderList}
    />
    <EditAddressDialog
      opened={addDialogOpened}
      onClose={onAddClose}
    />
  </div>
}
