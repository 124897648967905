import Skeleton from '@mui/material/Skeleton';
import { Grid, styled } from '@mui/material';
import * as Immutable from 'immutable';
import { useEffect, useState } from 'react';
import { Trans, Translation, useTranslation } from 'react-i18next';
import { ModalSubtitle, ModalTitle } from '../../Common/_components/Typography';
import UserStorePresetStore from '../../UserData/_stores/UserStorePresetStore';
import ProductCardContainer from '../_containers/ProductCardContainer';
import ListingStore from '../_stores/ListingStore';
import ItemList from '../../Common/ItemList/ItemList';
import SearchBar from '../../Common/ItemList/SearchBar';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

export type Props = {
	listings?: Immutable.Map<string, ListingStore>
	index ?: number
	handleStep ?: (step:number) => void
	storePresets?: Immutable.OrderedMap<number, UserStorePresetStore>
	fetchStorePresets: Function
}

const MassSendingMainButtonContainer = styled('div')({
	position: 'fixed',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	background: '#fff',
	boxShadow: '2px 0px 4px rgba(0, 0, 0, 0.25)',
	borderRadius: '0px 0px 30px 30px',
	width: '100%',
	bottom: '0',
	left: '0',
	textAlign: 'center',
	padding: '12px',

	'@media (max-width: 959px)': {
		backgroundColor: 'transparent',
		boxAhadow: 'none',
	}
});
const PinkButton = styled('button')({
	height: '45px',
	backgroundColor: '#F600BE',
	background: 'linear-gradient(0deg, #DA3192 0%, #F600BE 100%)',
	borderRadius: '22.5px',
	border: 'none',
	color: '#ffffff',
	width: '90%',

	'&:hover': {
		background: '#e83cae',
	},

	'&:focus': {
		background: 'linear-gradient(-180deg, #DA3192 0%, #F600BE 100%)',
	},

	'&[disabled]': {
		background: '#AAAFB4',
	}
});
const PaginationGridSection = styled(Grid)({
	paddingTop: '20px',

	'.pagination-search': {
		margin: '30px 0',
		border: '1px solid #AFAFAF',
		boxSizing: 'border-box',
		boxShadow: 'none',
	},

	'.input-field input[type=search]:focus': {
		backgroundColor: 'unset',
	},
});
const ScrollableContent = styled('div')({
	padding: '50px 60px 0px',
	width: '100%',
	height: 'calc(100% - 69px)',
	overflow: 'auto',

	'@media (max-width: 1200px)': {
		padding: '44px 40px 0px',
	},

	'@media (max-width: 959px)': {
		height: 'auto',
		padding: '0',
		overflow: 'visible',
		paddingBottom: '60px',
	}
});

const T = ({children, ...otherProps}:any) => <Trans ns="dashboard" {...otherProps}>{children}</Trans>

export default function SelectProduct(props: Props) {
	//const [t] = useTranslation('account-v2');
    const dispatch = useAppDispatch();

	const [search,setSearch] = useState('');

	useEffect(() => {
		props.fetchStorePresets();
	}, [])

	const handleSelectProducts = () => {
		props.handleStep(props.index + 1)
	}

	return <>
		<ScrollableContent>
			<Grid container>
				<Grid item xs={12}>
					<ModalTitle>{ props.listings.count() }<T> Selected products</T></ModalTitle>
					<ModalSubtitle><T>Select Art of Where products that you want to add to your store</T></ModalSubtitle>
				</Grid>
				

				<Translation ns='dashboard'>{ t => 
					<PaginationGridSection item xs={12}>
						<SearchBar
							placeholder={t('Search by design or product name')}
							value={search}
							onChange={setSearch}
						/>
						<ItemList
							controlled
							dataType={'productDesigns'}
							item={(item) => <ProductCardContainer item={item} />}
							searchQuery={search}
							render={(items: JSX.Element, links: JSX.Element, loading: boolean) =>
								<Grid item xs={12}>
									{loading ?
										<Grid container spacing={2}>
											<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
											<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
											<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
											<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
											<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
											<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
											<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
											<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
											<Grid item xs={6} md={4}><Skeleton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} variant="rectangular" width={"100%"} height={296} /></Grid>
										</Grid> :
										(items ? <>
											<Grid container spacing={2}>
												{items}
											</Grid>
										</> : <span className="pagination-noitems"><T>No Results</T></span>
										)}

									{links}
								</Grid>
							}
						/>
					</PaginationGridSection>
					}
				</Translation>
			</Grid>
		</ScrollableContent>
		
			
		
		<MassSendingMainButtonContainer>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PinkButton disabled={props.listings.count() === 0} onClick={handleSelectProducts}> <T>Select</T> { props.listings.count() } <T>Products</T></PinkButton>
				</Grid>
			</Grid>
		</MassSendingMainButtonContainer>
	</>;
}
