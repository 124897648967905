import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';

const PRODUCT_ID = 17;
const VIEW_PRODUCT_ID = 139;

export default function GlitterFittedSkirtPage() {
  const [t] = useTranslation('page/products/glitter-fitted-skirt');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Glitter Fitted Skirts | Products')}
      description={t('Design and create your own custom printed Glitter Fitted Skirt featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#d7d967"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '37%',
      }}
      tag={t('The all-over print')}
      title={t('Glitter Fitted Skirt')}
      text={t('Discover print on-demand glitter fitted skirts, made in our high quality signature performance glitter fabric designed to accentuate your silhouette. Fitted glitter skirts hug your curves and add a touch of sparkle to any outfit. Custom printed and made on-demand for you in Montreal, Canada.Shine bright and stand out with our glittery fitted skirts.')}
      createUrl={productData.get('url_slug') + '?option_fabric=glitter-performance-knit'}
      img={require('@resources/img/pages/products/glitter-fitted-skirt/banner-1-' + locale + '.png')}
      alt={t('Picture of Glitter Fitted Skirt')}
      details={[
        {
          img: require('@resources/img/pages/products/glitter-fitted-skirt/icon-1.svg?url'),
          alt: t('Fabric icon'),
          text: t('Body hugging high-waisted fitted skirt falls to mid-thigh'),
        },
        {
          img: require('@resources/img/pages/products/glitter-fitted-skirt/icon-2.svg?url'),
          alt: t('Worldwide icon'),
          text: t('1.5” comfy elastic waistband'),
        },
        {
          img: require('@resources/img/pages/products/glitter-fitted-skirt/icon-3.svg?url'),
          alt: t('UPF icon'),
          text: t('Made from 88% polyester/12 % spandex performance glitter fabric'),
        },
        {
          img: require('@resources/img/pages/products/glitter-fitted-skirt/icon-4.svg?url'),
          alt: t('Ink icon'),
          text: t('Printed with vibrant and permanent sublimation inks'),
        },
        {
          img: require('@resources/img/pages/products/glitter-fitted-skirt/icon-5.svg?url'),
          alt: t('Yoga icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/glitter-fitted-skirt/gallery_0.jpg'),
      require('@resources/img/pages/products/glitter-fitted-skirt/gallery_1.jpg'),
      require('@resources/img/pages/products/glitter-fitted-skirt/gallery_2.jpg'),
      require('@resources/img/pages/products/glitter-fitted-skirt/gallery_3.jpg'),
      require('@resources/img/pages/products/glitter-fitted-skirt/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your glitter <br/>fitted skirt <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love glitter fitted skirts! At prices designed for reselling, glitter fitted skirt made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
      options={['glitter-performance-knit']}
    />

    <SectionSizes
      chart="bottoms"
      productId={PRODUCT_ID}
      title={t('Glitter Fitted Skirt Sizing')}
      text={t('All measurements are approximate. Since our products are made by hand, there may be variations in the finished size of our products between orders or even within an order.')}
    />

    <SectionBranding
      hasLabel
      title={t('Brand your Glitter Fitted Skirt')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your glitter Fitted Skirt with these great products to create your own unique styles.')}
      productSlugs={['premium-t-shirt', 'glitter-athletic-crop-top', 'day-tote']}
    />
  </div>
}