import { Stack, Typography } from '@mui/material'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

interface Props {
  title: string
  titleActions?: React.ReactNode
  breadcrumbs?: Array<{ title: string, url: string }>
  actions?: React.ReactNode
}

export default function AccountHeader(props: Props) {
  return <Stack
    direction="row"
    sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 2,
      flexWrap: 'wrap'
    }}
  >
    <Helmet>
      <title>{props.title}</title>
    </Helmet>
    <Stack
      direction="row"
      spacing={1}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      { props.breadcrumbs && props.breadcrumbs.length > 0 ? <Typography
        variant="h1"
        component="span"
        style={{
          fontFamily: 'Poppins, Roboto, sans-serif',
          fontSize: '1.8rem',
          fontWeight: '400',
          lineHeight: 'normal',
          color: '#999'
        }}
      >
        {props.breadcrumbs.map(breadcrumb => {
          return <React.Fragment key={breadcrumb.title}>
            <Link
              to={breadcrumb.url}
              style={{
                color: '#999'
              }}
            >{breadcrumb.title}</Link>
            {' > '}
          </React.Fragment>
        })}
      </Typography> : null}
      <Typography
        variant="h1"
        style={{
          fontFamily: 'Poppins, Roboto, sans-serif',
          fontSize: '1.8rem',
          fontWeight: '400',
          lineHeight: 'normal',
          color: 'inherit'
        }}
      >{props.title}</Typography>
      {props.titleActions}
    </Stack>
    {props.actions}
  </Stack>
}
