import { InputBaseProps as MuiInputBaseProps, styled, InputLabel, FormHelperText, Popover, Autocomplete, IconButton, TextField, Fade, Box, Button } from '@mui/material';
import { LanguageRounded as LanguageRoundedIcon, ArrowDropDownRounded as ArrowDropDownRoundedIcon } from '@mui/icons-material';
import { CountryCode, E164Number } from 'libphonenumber-js/core';
import { MouseEvent, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import LabelV2, { LabelV2Props } from './LabelV2';
import { useAppSelector } from '../_hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import useId from '@mui/utils/useId';

const StyledPhoneField = styled('div')(({theme}) => ({
  border: 0,
  outline: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.06)',
  borderRadius: '30px',
  transition: 'background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  padding: '10px 16px',
  display: 'flex',
  alignItems: 'center',
}))

const StyledPhoneInput = styled(Input)(({theme}) => ({
  margin: 0,
  padding: 0,
  border: 0,
  outline: 0,
  background: 'none',
  color: 'currentColor',
  font: 'inherit',
  letterSpacing: 'inherit',
  boxSizing: 'content-box',
  display: 'block',
  width: '100%',
  height: '1.4375em',

  '&[disabled]': {
    color: 'rgba(0, 0, 0, 0.38)',
    cursor: 'default',
  }
}))

const StyledIconButton = styled(Button)(({theme}) => ({
  margin: theme.spacing(-1),
  marginRight: theme.spacing(0.5),
  color: 'rgba(0, 0, 0, 0.54)',
  padding: '4px 0 4px 8px',
  minWidth: 0,
}))

export type PhoneFieldProps = {
  id?: string
  label?: React.ReactNode
  defaultCountry: number
  value?: E164Number
  onChange: (value?: E164Number) => void
  disabled?: boolean,
  error?: boolean
  helperText?: React.ReactNode
  InputLabelProps?: Omit<LabelV2Props, "children" | "ref">
}

export default function PhoneField(props: PhoneFieldProps) {
  const [t] = useTranslation()

  const id = useId(props.id)

  const countryInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);

  //Filter countries not supported by the Phone input library
  const countries = useAppSelector(state => state.get('appData').get('countries').filter(country => !['BV'].includes(country.get('alpha2_code'))), {
    equalityFn: (a, b) => a.equals(b)
  })

  const [listOpened,setListOpened] = useState(false)
  const [listAnchorEl,setListAnchorEl] = useState<HTMLButtonElement|null>(null)

  const [countryId,setCountryId] = useState<number|null>(props.defaultCountry);

  useEffect(() => {
    if(countryId === null || countryId === 0 || props.value === '' || !props.value) {
      setCountryId(props.defaultCountry)
    }
  }, [props.defaultCountry])

  const countryOptionValue = useMemo(() => {
    const countryObj = countries.get(String(countryId))
    if (!countryObj) return null

    return {
      id: countryObj.get('id'),
      label: countryObj.get('name')+' (+'+getCountryCallingCode(countryObj.get('alpha2_code') as CountryCode)+')',
    }
  }, [countryId, countries])

  const countryCode = useMemo(() => {
    const countryObj = countries.get(String(countryId))
    if (!countryObj) return undefined

    return countryObj.get('alpha2_code') as CountryCode
  }, [countries, countryId])

  const countryOptions = useMemo(() => {
    return countries.valueSeq().map(country => {
      return {
        id: country.get('id'),
        label: country.get('name')+' (+'+getCountryCallingCode(country.get('alpha2_code') as CountryCode)+')',
      }
    }).toArray()
  }, [countries])

  const onListOpen = useCallback((e:MouseEvent<HTMLButtonElement>) => {
    setListOpened(true)
    setListAnchorEl(e.currentTarget)
    
    setTimeout(() => {
      countryInputRef.current?.focus()
    })
  }, [])

  const onListClose = useCallback(() => {
    setListOpened(false)
  }, [])

  const onChange = useCallback((value?: E164Number) => {
    //Reset to default country if phone number is empty
    if(!value && props.defaultCountry) {
      setCountryId(props.defaultCountry)
    }

    props.onChange(value)
  }, [props.onChange, props.defaultCountry])

  const { InputLabelProps, ...rest } = props;

  return <div>
    {props.label ? <LabelV2
      htmlFor={id}
      error={props.error}
      disabled={props.disabled}
      {...InputLabelProps}
    >{props.label}</LabelV2> : <></>}

    <StyledPhoneField>
      <StyledIconButton
        variant="text"
        size="small"
        color="secondary"
        onClick={onListOpen}
        disabled={props.disabled}
      >
        { countryCode 
          ? <div style={{
              width: '20px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <img 
                src={require('flag-icons/flags/4x3/'+countryCode.toLowerCase()+'.svg?url')}
                width={20}
                height={15}
                style={{
                  display: 'block',
                  transition: 'opacity 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
                  opacity: props.disabled ? 0.38 : 1,
                }}
              />
            </div>
          : <LanguageRoundedIcon fontSize="small"/>}
        <ArrowDropDownRoundedIcon/>
      </StyledIconButton>
      <StyledPhoneInput
        ref={phoneInputRef}
        //TODO: We have this as a hack for materializecss
        className="PhoneInputInput"
        country={countryCode}
        value={props.value}
        onChange={onChange}
        disabled={props.disabled}
      />
    </StyledPhoneField>

    {props.helperText ? <FormHelperText
      error={props.error}
    >{props.helperText}</FormHelperText> : null}

    <Popover
      open={listOpened}
      anchorEl={listAnchorEl}
      onClose={onListClose}
      //We use Fade to avoid issues with autocomplete positioning
      TransitionComponent={Fade}
      keepMounted
      slotProps={{
        paper: {
          style: {
            minWidth: '240px',
          }
        },
      }}
    >
      <Autocomplete
        options={countryOptions}
        value={countryOptionValue}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(e, value) => {
          setCountryId(value ? value.id : null)
          setListOpened(false)
          setTimeout(() => {
            phoneInputRef.current?.focus()
          })
        }}
        autoComplete
        autoHighlight
        disableClearable
        openOnFocus
        disablePortal
        PopperComponent={(props) => <>{props.children}</>}
        PaperComponent={Box}
        renderInput={(params) => {
          return <TextField
            //label={t('Country')}
            inputRef={countryInputRef}
            {...params}
          />
        }}
      />
    </Popover>
  </div>
}