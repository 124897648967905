import { useMemo } from 'react';
import { Checkbox, FormControlLabel, styled } from "@mui/material"
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { EditAutoDesignSubproduct } from '../../UIData/_actions/DesignLabStoreActions';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

const ToolFormControlLabel = styled(FormControlLabel)({
    height: '40px',
    borderRadius: '30px',
    background: '#00aced',
    color: '#fff',
    padding: '0 15px 0 5px',
    marginRight: '0px',
})

type Props = {}

export default function AutoDesignTool(props:Props) {
    const dispatch = useAppDispatch();
    const [t] = useTranslation('design-lab');
    const activeProductId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
    const productData = useSelector((state:WebsiteStore) => state.get('appData').get('products').get(String(activeProductId)));
    const activeSubproduct = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeSubproduct'));
    const autoDesign = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('autoDesignSubproducts').get(activeSubproduct));

    const available = useMemo(() => {
        return productData.get('labData').has_subproducts && activeSubproduct !== Object.keys(productData.get('labData').subproducts)[0];
    }, [productData, activeSubproduct]);

    return available ? <ToolFormControlLabel
            label={t('Auto design')}
            value={true}
            control={<Checkbox 
                checked={Boolean(autoDesign)}
                onChange={(e) => {
                    dispatch(EditAutoDesignSubproduct(activeSubproduct, e.target.checked));
                }}
                sx={{
                    color: '#fff',
                    '&.Mui-checked': {
                        color: '#fff'
                    }
                }}
            />}
        /> : null
}