import { Autocomplete, type AutocompleteRenderGetTagProps, type AutocompleteRenderInputParams, Chip, type FilterOptionsState, InputBase, Stack, Typography, createFilterOptions, styled } from '@mui/material'
import { type HTMLAttributes, type SyntheticEvent, useCallback, useMemo } from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { type ItemListSearchParams } from '../../Common/ItemList/ItemList'

const StyledBar = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.06)',
  borderRadius: '30px',
  display: 'flex',
  minHeight: '48px',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(0, 2.5, 0, 2),
  alignItems: 'center',
  gap: '10px',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 0, 0, 2)
  }
}))

const filter = createFilterOptions<ProductSearchValue>()

export interface ProductSearchParams extends ItemListSearchParams {
  categories: string[]
  search: string[]
  collections: string[]
}

export interface ProductSearchValue {
  id: number
  label: string
  type: 'category' | 'query'
}

type Props = React.PropsWithChildren<{
  placeholder?: string
  value?: ProductSearchParams
  onChange?: (value: (value: ProductSearchParams) => ProductSearchParams) => void
}>

export default function ProductSearchBar(props: Props) {
  const [t] = useTranslation()

  const viewCategories = useAppSelector(state => state.get('appData').get('viewCategories'))

  const onChange = useCallback((e: SyntheticEvent<Element, Event>, value: Array<ProductSearchValue | string>) => {
    if (!props.onChange) return

    const categories: string[] = []
    const search: string[] = []

    value.forEach(option => {
      if (typeof option === 'string') {
        search.push(String(option))
        return
      }

      if (['category'].includes(option.type)) {
        categories.push(String(option.id))
      } else if (['query'].includes(option.type)) {
        search.push(String(option.label))
      }
    })

    props.onChange(value => ({
      ...value,
      categories,
      search
    }))
  }, [props.onChange])

  const options = useMemo(() => {
    const options: ProductSearchValue[] = []

    viewCategories
      .filter(category => category.get('id_parent') === null)
      .forEach(category => {
        // Main category
        options.push({
          id: category.get('id'),
          label: category.get('name'),
          type: 'category'
        })

        // Skip subcategories for fabric
        // TODO: Would be nice to find a cleaner way to do this instead of hardcoding
        if (category.get('slug') === 'fabrics') {
          return
        }

        // Subcategories
        viewCategories
          .filter(subCategory => subCategory.get('id_parent') === category.get('id'))
          .forEach(subCategory => {
            options.push({
              id: subCategory.get('id'),
              label: subCategory.get('name'),
              type: 'category'
            })
          })
      })

    return options
  }, [viewCategories])

  const renderTags = useCallback((value: ProductSearchValue[], getTagProps: AutocompleteRenderGetTagProps) => {
    return value.map((option, index) => {
      const category = option.type === 'category' ? viewCategories.get(String(option.id)) : undefined

      return <Chip
        label={option.label}
        avatar={category?.get('id_parent') != null ? <picture>
          <source
            type="image/webp"
            srcSet={require('@resources/img/all-products/nav/' + category.get('slug') + '.webp') + ' 1x, ' + require('@resources/img/all-products/nav/' + category.get('slug') + '@2x.webp') + ' 2x'}
            />
          <source
            type="image/jpeg"
            srcSet={require('@resources/img/all-products/nav/' + category.get('slug') + '.jpg') + ' 1x, ' + require('@resources/img/all-products/nav/' + category.get('slug') + '@2x.jpg') + ' 2x'}
          />
          <img
            src={require('@resources/img/all-products/nav/' + category.get('slug') + '.svg?url')}
            width="24"
            height="24"
            style={{
              height: '100%',
              width: 'auto',
              borderRadius: '50%'
            }}
          />
        </picture> : undefined}
        style={{
          fontWeight: category && category.get('id_parent') === null ? 600 : 400
        }}
        {...getTagProps({ index })}
      />
    })
  }, [viewCategories])

  const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
    return <div style={{
      display: 'flex'
    }}>
      <InputBase
        placeholder={props.placeholder ? props.placeholder : t('Search...')}
        fullWidth
        style={{
          padding: 0
        }}
        inputProps={params.inputProps}
        {...params.InputProps}
      />
    </div>
  }, [props.placeholder, props.children])

  const renderOption = useCallback((props: HTMLAttributes<HTMLLIElement>, option: ProductSearchValue) => {
    const category = option.type === 'category' ? viewCategories.get(String(option.id)) : undefined

    return <li
      style={{
        padding: 0
      }}
      {...props}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          py: 0.5,
          px: 1
        }}
      >
        { category?.get('id_parent') != null ? <picture>
          <source
            type="image/webp"
            srcSet={require('@resources/img/all-products/nav/' + category.get('slug') + '.webp') + ' 1x, ' + require('@resources/img/all-products/nav/' + category.get('slug') + '@2x.webp') + ' 2x'}
          />
          <source
            type="image/jpeg"
            srcSet={require('@resources/img/all-products/nav/' + category.get('slug') + '.jpg') + ' 1x, ' + require('@resources/img/all-products/nav/' + category.get('slug') + '@2x.jpg') + ' 2x'}
          />
          <img
            src={require('@resources/img/all-products/nav/' + category.get('slug') + '.svg?url')}
            width="36"
            height="36"
            style={{
              height: '36px',
              width: 'auto',
              borderRadius: '50%',
              display: 'block'
            }}
          />
        </picture> : null }
        <Typography
          variant="body1"
          sx={{
            fontWeight: category && category.get('id_parent') === null ? 600 : 400,
            lineHeight: '36px'
          }}
        >{option.label}</Typography>
      </Stack>
    </li>
  }, [viewCategories])

  const getOptionKey = useCallback((option: ProductSearchValue | string) => {
    return typeof option === 'string' ? option : option.type + '|' + (option.type === 'category' ? option.id : option.label)
  }, [])

  const getOptionLabel = useCallback((option: ProductSearchValue | string) => {
    return typeof option === 'string' ? option : option.label
  }, [])

  const filterOptions = useCallback((options: ProductSearchValue[], state: FilterOptionsState<ProductSearchValue>) => {
    const filtered = filter(options, state)

    const { inputValue } = state

    // Suggest the user input
    const isExisting = options.some((option) => inputValue === option.label)
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        id: 0,
        label: inputValue,
        type: 'query'
      })
    }

    return filtered
  }, [])

  const isOptionEqualToValue = useCallback((option: ProductSearchValue, value: ProductSearchValue) => {
    return option.type === value.type &&
      option.id === value.id &&
      option.label === value.label
  }, [])

  const value = useMemo(() => {
    if (!props.value) return []

    const valueArray: ProductSearchValue[] = []

    props.value.categories.forEach(categoryId => {
      const category = viewCategories.get(categoryId)
      if (!category) return

      valueArray.push({
        type: 'category',
        id: category.get('id'),
        label: category.get('name')
      })
    })

    props.value.search.forEach(searchTest => {
      valueArray.push({
        type: 'query',
        id: 0,
        label: searchTest
      })
    })

    return valueArray
  }, [props.value, viewCategories])

  return <StyledBar>
    <SearchIcon style={{ fontSize: '28px' }} />
    <Autocomplete
      multiple
      freeSolo
      autoHighlight
      clearOnBlur
      filterSelectedOptions
      options={options}
      value={value}
      onChange={onChange}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      renderTags={renderTags}
      renderInput={renderInput}
      renderOption={renderOption}
      filterOptions={filterOptions}
      style={{
        flexGrow: 1
      }}
    />

    {props.children}
  </StyledBar>
}
