import * as Immutable from 'immutable';
import PriceStore, { PricePayload } from '../../UIData/_stores/PriceStore';
import { RefundPayload, RefundStore } from './RefundStore';
import { PackagePayload, PackageStore } from './PackageStore';
import { PackageItemPayload, PackageItemStore } from './PackageItemStore';
import { StoreOrderPayload, StoreOrderStore } from './StoreOrderStore';
import { StoreItemStore } from './StoreItemStore';

////////////////
// Cart Items //
////////////////



export type CartItemType =
	"product"							|
	"artist_card"						|
	"sticker"							|
	"sample_pack_preprinted_synthetic"	|
	"sample_pack_preprinted_natural"	|
	"sample_pack_preprinted_paper"		|
	"pgroup"							|
	"face_mask_filter"					|
	"pattern_design"					|
	"invoice"							|
	"blank_product" |
	"branding_option"

export interface FeeItemPayload {
	id: number,
	name: string
	type: CartItemType
	price: PricePayload
}

export interface IFeeItem {
	id: number,
	name: string
	type: CartItemType
	price: PriceStore
}

const defaultFeeItem = Immutable.Record<IFeeItem>({
	id: 0,
	name: "",
	type: 'pattern_design',
	price: new PriceStore(),
});

export class FeeItem extends defaultFeeItem implements IFeeItem {}


export interface CartItemPayload {
	id: number,
	name: string
	type: CartItemType
	quantity: number
	price: PricePayload
	price_dropship: PricePayload
	price_wholesale: PricePayload
	fixedQuantity: boolean
	thumbnail: string
	category: string
	product_name: string
	fees: Record<string, FeeItemPayload>
}

export interface IBaseItem {
	id: number,
	name: string
	type: CartItemType
	quantity: number
	price: PriceStore
	price_dropship: PriceStore
	price_wholesale: PriceStore
	fixedQuantity: boolean
	thumbnail: string
	category: string
	product_name: string
	fees: Immutable.OrderedMap<string, FeeItem>
}

const defaultBaseItem = Immutable.Record<IBaseItem>({
	id: 0,
	name: "",
	type: 'blank_product',
	quantity: 0,
	price: new PriceStore(),
	price_dropship: new PriceStore(),
	price_wholesale: new PriceStore(),
	fixedQuantity: false,
	thumbnail: "",
	category: "",
	product_name: "",
	fees: Immutable.OrderedMap<string, FeeItem>(),
});

export class BaseItem extends defaultBaseItem implements IBaseItem {}

export interface IProductItemOption {
	type_id: number,
	type_name: string,
	type_slug: string,
	type_type: string,
	name: string,
	slug: string,
	id: number,
	price: PriceStore
	id_branding_option?: number
}

const defaultProductItemOption = Immutable.Record<IProductItemOption>({
	type_id: 0,
	type_name: "",
	type_slug: "",
	type_type: "",
	name: "",
	slug: "",
	id: 0,
	price: new PriceStore(),
	id_branding_option: undefined,
});

export class ProductItemOption extends defaultProductItemOption implements IProductItemOption {
	constructor(params?:IProductItemOption) {
		if(params) {
			let store = new ProductItemOption();
			let {price, ...rest} = params;
			store = store.withMutations(store => {
				store.merge(Immutable.fromJS(rest));
				store.set('price', new PriceStore(price));
			});

			super(store);
		} else super();
	}
}

export interface IProductItemDynamicOption {
	data: string,
}

const defaultProductItemDynamicOption = Immutable.Record<IProductItemDynamicOption>({
	data: "",
});

export class ProductItemDynamicOption extends defaultProductItemDynamicOption implements IProductItemDynamicOption {}

export interface IProductItem extends IBaseItem {
	options: Immutable.OrderedMap<string, ProductItemOption>
	dynamicOptions: Immutable.OrderedMap<string, ProductItemDynamicOption>
	product_id: number
	id_product_design: number
	brandingOptions: Immutable.OrderedMap<string, number>
	isParent: boolean
	variant: string
	previews: Immutable.OrderedMap<string, string>
	textures: Immutable.OrderedMap<string, string>
	fees: Immutable.OrderedMap<string, FeeItem>
}

const defaultProductItem = Immutable.Record<IProductItem>({
	id: 0,
	name: "",
	type: 'product',
	quantity: 0,
	price: new PriceStore(),
	price_dropship: new PriceStore(),
	price_wholesale: new PriceStore(),
	fixedQuantity: false,
	thumbnail: "",
	category: "",
	product_name: "",
	options: Immutable.OrderedMap<string, ProductItemOption>(),
	dynamicOptions: Immutable.OrderedMap<string, ProductItemDynamicOption>(),
	product_id: 0,
	id_product_design: 0,
	brandingOptions: Immutable.OrderedMap<string, number>(),
	isParent: false,
	variant: "",
	previews: Immutable.OrderedMap<string, string>(),
	textures: Immutable.OrderedMap<string, string>(),
	fees: Immutable.OrderedMap<string, FeeItem>(),
});

export interface IBrandingOptionItem extends IBaseItem {
	id_branding_option: number
}

const defaultBrandingOptionItem = Immutable.Record<IBrandingOptionItem>({
	id: 0,
	name: "",
	type: 'branding_option',
	quantity: 0,
	price: new PriceStore(),
	price_dropship: new PriceStore(),
	price_wholesale: new PriceStore(),
	fixedQuantity: false,
	thumbnail: "",
	category: "",
	product_name: "",
	fees: Immutable.OrderedMap<string, FeeItem>(),
	id_branding_option: 0,
});

const buildItem = (item:Immutable.Record<any>, params:any):Immutable.Record<any> => {
	return item.withMutations(item => {
		if(params.hasOwnProperty('id') && params.id !== null)
			item.set('id', params.id);

		if(params.hasOwnProperty('name') && params.name !== null)
			item.set('name', params.name);

		if(params.hasOwnProperty('type') && params.type !== null)
			item.set('type', params.type);
		
		if(params.hasOwnProperty('quantity') && params.quantity !== null)
			item.set('quantity', params.quantity);

		if(params.hasOwnProperty('price') && params.price !== null)
			item.set('price', new PriceStore(params.price));

		if(params.hasOwnProperty('price_dropship') && params.price_dropship !== null)
			item.set('price_dropship', new PriceStore(params.price_dropship));

		if(params.hasOwnProperty('price_wholesale') && params.price_wholesale !== null)
			item.set('price_wholesale', new PriceStore(params.price_wholesale));

		if(params.hasOwnProperty('fixedQuantity') && params.fixedQuantity !== null)
			item.set('fixedQuantity', params.fixedQuantity);

		if(params.hasOwnProperty('thumbnail') && params.thumbnail !== null)
			item.set('thumbnail', params.thumbnail);

		if(params.hasOwnProperty('category') && params.category !== null)
			item.set('category', params.category);

		if(params.hasOwnProperty('product_name') && params.product_name !== null)
			item.set('product_name', params.product_name);

		for(let fee in params.fees) {
			let feeItem = new FeeItem(params.fees[fee]);
			feeItem = feeItem.set('price', new PriceStore(params.fees[fee].price));
			item.setIn(['fees', fee], feeItem);
		}

		if(params.hasOwnProperty('type') && params.type == "product") {
			item.set('product_id', +params.product_id);
			item.set('id_product_design', Number(params.id_product_design));
			item.set('isParent', !!params.isParent);
			item.set('variant', params.variant);
			for(let option_type in params.options) {
				item.setIn(['options', option_type], new ProductItemOption(params.options[option_type]));
			}
			for(let option_type in params.dynamicOptions) {
				item.setIn(['dynamicOptions', option_type], new ProductItemDynamicOption(params.dynamicOptions[option_type]));
			}
			if(params.hasOwnProperty('previews') && params.previews !== null)
				item.set('previews', Immutable.OrderedMap(params.previews));
			
			if(params.hasOwnProperty('textures') && params.textures !== null)
				item.set('textures', Immutable.OrderedMap(params.textures));

			if(params.hasOwnProperty('brandingOptions') && params.brandingOptions !== null)
				item.set('brandingOptions', Immutable.OrderedMap(params.brandingOptions));
		}

		if(params.hasOwnProperty('type') && params.type == "branding_option") {
			item.set('id_branding_option', params.id_branding_option)
		}
	});
}

export class ProductItem extends defaultProductItem implements IProductItem { type: "product" }
export class ArtistCardItem extends BaseItem { type: "artist_card" }
export class StickerItemItem extends BaseItem { type: "sticker" }
export class SamplePackPreprintedSyntheticItem extends BaseItem { type: "sample_pack_preprinted_synthetic" }
export class SamplePackPreprintedNaturalItem extends BaseItem { type: "sample_pack_preprinted_natural" }
export class SamplePackPreprintedPaperItem extends BaseItem { type: "sample_pack_preprinted_paper" }
export class PgroupItem extends BaseItem { type: "pgroup" }
export class FaceMaskFilterItem extends BaseItem { type: "face_mask_filter" }
export class PatternDesignItem extends BaseItem { type: "pattern_design" }
export class InvoiceItem extends BaseItem { type: "invoice" }
export class BlankProductItem extends BaseItem { type: "blank_product" }
export class BrandingOptionItem extends defaultBrandingOptionItem implements IBrandingOptionItem { type: "branding_option" }

const isBaseItem = (c:any): c is BaseItem => c.type && c.quantity && c.price && c.thumbnail;
const isProduct = (c:IBaseItem): c is ProductItem => c.type == "product";
const isArtistCard = (c:IBaseItem): c is ArtistCardItem => c.type == "artist_card";
const isStickerItem = (c:IBaseItem): c is StickerItemItem => c.type == "sticker";
const isSamplePackPreprintedSynthetic = (c:IBaseItem): c is SamplePackPreprintedSyntheticItem => c.type == "sample_pack_preprinted_synthetic";
const isSamplePackPreprintedNatural = (c:IBaseItem): c is SamplePackPreprintedNaturalItem => c.type == "sample_pack_preprinted_natural";
const isSamplePackPreprintedPaper = (c:IBaseItem): c is SamplePackPreprintedPaperItem => c.type == "sample_pack_preprinted_paper";
const isPgroup = (c:IBaseItem): c is PgroupItem => c.type == "pgroup";
const isFaceMaskFilter = (c:IBaseItem): c is FaceMaskFilterItem => c.type == "face_mask_filter";
const isPatternDesign = (c:IBaseItem): c is PatternDesignItem => c.type == "pattern_design";
const isInvoice = (c:IBaseItem): c is InvoiceItem => c.type == "invoice";
const isBlankProduct = (c:IBaseItem): c is BlankProductItem => c.type == "blank_product";
const isBrandingOption = (c:IBaseItem): c is BrandingOptionItem => c.type == "branding_option";

export function CartItem(item:any):
	ProductItem |
	ArtistCardItem |
	StickerItemItem |
	SamplePackPreprintedSyntheticItem |
	SamplePackPreprintedNaturalItem |
	SamplePackPreprintedPaperItem |
	PgroupItem |
	FaceMaskFilterItem |
	PatternDesignItem |
	InvoiceItem |
	BlankProductItem |
	BrandingOptionItem |
	BaseItem 
{
	if(isProduct(item)) 						return buildItem(new ProductItem(), item) as ProductItem;
	if(isArtistCard(item)) 						return buildItem(new ArtistCardItem(), item) as ArtistCardItem;
	if(isStickerItem(item)) 					return buildItem(new StickerItemItem(), item) as StickerItemItem;
	if(isSamplePackPreprintedSynthetic(item)) 	return buildItem(new SamplePackPreprintedSyntheticItem(), item) as SamplePackPreprintedSyntheticItem;
	if(isSamplePackPreprintedNatural(item)) 	return buildItem(new SamplePackPreprintedNaturalItem(), item) as SamplePackPreprintedNaturalItem;
	if(isSamplePackPreprintedPaper(item)) 		return buildItem(new SamplePackPreprintedPaperItem(), item) as SamplePackPreprintedPaperItem;
	if(isPgroup(item)) 							return buildItem(new PgroupItem(), item) as PgroupItem;
	if(isFaceMaskFilter(item)) 					return buildItem(new FaceMaskFilterItem(), item) as FaceMaskFilterItem;
	if(isPatternDesign(item)) 					return buildItem(new PatternDesignItem(), item) as PatternDesignItem;
	if(isBrandingOption(item)) 					return buildItem(new BrandingOptionItem(), item) as BrandingOptionItem;

	return buildItem(new BaseItem(), item) as BaseItem;
}

////////////////////
// Shipping Types //
////////////////////

export interface IShippingType {
	id: number,
	id_country: number,
	slug: string,
	ships_from: string
	name: string
	description: string
	price: PriceStore
}

const defaultShippingTypeStore = Immutable.Record<IShippingType>({
	id: 0,
	id_country: 0,
	slug: "",
	ships_from: "",
	name: "",
	description: "",
	price: new PriceStore()
});

let x = {a: 1, b: 2, c: 3, z:26};
let {b, ...y} = x;

export class ShippingType extends defaultShippingTypeStore implements IShippingType {
	constructor(params?:IShippingType) {
		if(params) {
			let store = new ShippingType();
			let {price, ...rest} = params;
			store = store.withMutations(store => {
				store.merge(Immutable.fromJS(rest));
				store.set('price', new PriceStore(price));
			});

			super(store);
		} else super();
	}
}

///////////
// Carts //
///////////

export type CartStatus =
	"cancelled"		|
	"cart"			|
	"ignored"		|
	"in-production"	|
	"on-hold"		|
	"ordered"		|
	"paid"			|
	"ready"			|
	"shipped"

export const LIST_CART_STATUSES:CartStatus[] = [
	'cart',
	'ignored',
	'cancelled',
	'on-hold',
	'ordered',
	'paid',
	'in-production',
	'ready',
	'shipped',
]

export interface UserCartPayload {
	// Info
	id: number
	ref_id: string
	invoice_id: string|null
	cart_name: string
	status: CartStatus
	order_from: 'aow'|'invoice'
	storeIntegration?: string
	storeOrder?: StoreOrderPayload
	isCancelable?: boolean
	hidden: boolean
	promocode?: string
	id_reward?: number
	subtotal: PricePayload
	discount: PricePayload
	shipping: PricePayload
	taxes: PricePayload
	total: PricePayload
	discount_wholesale: PricePayload
	date_created: string
	date_paid: string
	pickup: boolean
	id_shipping_type: number
	availableShippingTypes: ShippingType[]
	shipbyDate: string|null
	arrivalDateMin: string|null
	arrivalDateMax: string|null

	// Shipping
	id_shipping_address: number|null
	name: string
	address: string
	address2: string
	city: string
	province: string
	postalCode: string
	country: string
	shipping_phone: string

	// Billing
	id_billing_address: number|null
	billing_name: string
	billing_address: string
	billing_address2: string
	billing_city: string
	billing_province: string
	billing_postalCode: string
	billing_country: string
	billing_phone: string

	// Cart Items
	items: Record<string, CartItemPayload>
	refunds?: Record<string, RefundPayload>
	reprints?: number[]
	packages?: Record<string, PackagePayload>
}

export interface IUserCartStore {
	// Info
	id: number
	ref_id: string
	invoice_id: string|null
	cart_name: string
	status: CartStatus
	order_from: 'aow'|'invoice'
	storeIntegration: string|null
	storeOrder: StoreOrderStore|null
	isCancelable: boolean
	hidden: boolean
	promocode: string|null
	id_reward: number|null
	subtotal: PriceStore
	discount: PriceStore
	shipping: PriceStore
	taxes: PriceStore
	total: PriceStore
	discount_wholesale: PriceStore
	date_created: string
	date_paid: string
	pickup: boolean
	id_shipping_type: number
	availableShippingTypes: Immutable.OrderedMap<number, ShippingType>
	shipbyDate: string|null
	arrivalDateMin: string|null
	arrivalDateMax: string|null

	// Shipping
	id_shipping_address: number|null
	name: string
	address: string
	address2: string
	city: string
	province: string
	postalCode: string
	country: string
	shipping_phone: string

	// Billing
	id_billing_address: number|null
	billing_name: string
	billing_address: string
	billing_address2: string
	billing_city: string
	billing_province: string
	billing_postalCode: string
	billing_country: string
	billing_phone: string

	// Cart Items
	items: Immutable.OrderedMap<string, BaseItem>
	refunds: Immutable.OrderedMap<string, RefundStore>
	reprints: Immutable.OrderedSet<number>
	packages: Immutable.OrderedMap<string, PackageStore>
}

const defaultStore = Immutable.Record<IUserCartStore>({
	// Info
	id: 0,
	ref_id: '',
	invoice_id: null,
	cart_name: "",
	status: 'cart',
	order_from: 'aow',
	storeIntegration: null,
	storeOrder: null,
	isCancelable: false,
	hidden: false,
	promocode: null,
	id_reward: null,
	subtotal: new PriceStore(),
	discount: new PriceStore(),
	shipping: new PriceStore(),
	taxes: new PriceStore(),
	total: new PriceStore(),
	discount_wholesale: new PriceStore(),
	date_created: undefined,
	date_paid: undefined,
	pickup: false,
	id_shipping_type: undefined,
	availableShippingTypes: Immutable.OrderedMap<number, ShippingType>(),
	shipbyDate: null,
	arrivalDateMin: null,
	arrivalDateMax: null,

	// Shipping
	id_shipping_address: null,
	name: "",
	address: "",
	address2: "",
	city: "",
	province: "",
	postalCode: "",
	country: "",
	shipping_phone: "",

	// Billing
	id_billing_address: null,
	billing_name: "",
	billing_address: "",
	billing_address2: "",
	billing_city: "",
	billing_province: "",
	billing_postalCode: "",
	billing_country: "",
	billing_phone: "",

	// Cart Items
	items: Immutable.OrderedMap<string, BaseItem>(),
	refunds: Immutable.OrderedMap<string, RefundStore>(),
	reprints: Immutable.OrderedSet<number>(),
	packages: Immutable.OrderedMap<string, PackageStore>(),
});

export const CartHasShipping = (cart:UserCartStore) => {
	return CheckCartAddress(cart, "shipping");
}

export const CartHasBilling = (cart:UserCartStore) => {
	return CheckCartAddress(cart, "billing");
}

export const CartHasItemOfType = (cart:UserCartStore, type: CartItemType) => {
	return !!cart.get('items').find(item => {
		return item.get('type') == type
	});
}

// Basically just make sure there's something
export const CheckCartAddress = (cart:UserCartStore, type: "shipping" | "billing") => {
	switch(type) {
		case "shipping" :
			return !!cart.get('name') &&
			!!cart.get('address') &&
			!!cart.get('city') &&
			!!cart.get('postalCode') &&
			!!cart.get('country') &&
			!!cart.get('shipping_phone');
		case "billing" :
			return !!cart.get('billing_name') &&
			!!cart.get('billing_address') &&
			!!cart.get('billing_city') &&
			!!cart.get('billing_postalCode') &&
			!!cart.get('billing_country') &&
			!!cart.get('billing_phone');
	}
}

export default class UserCartStore extends defaultStore implements IUserCartStore {
	constructor(params?:any) {
		if(params) {
			let store = new UserCartStore();
			store = store.withMutations(store => {
				if(params.hasOwnProperty('id') && params.id !== null) 
					store.set('id', params.id);

				if(params.hasOwnProperty('ref_id') && params.ref_id !== null) 
					store.set('ref_id', params.ref_id);

				if(params.hasOwnProperty('invoice_id') && params.invoice_id !== null) 
					store.set('invoice_id', params.invoice_id);

				if(params.hasOwnProperty('cart_name') && params.cart_name !== null) 
					store.set('cart_name', params.cart_name);

				if(params.hasOwnProperty('status') && params.status !== null) 
					store.set('status', params.status);

				if(params.hasOwnProperty('order_from') && params.order_from !== null) 
					store.set('order_from', params.order_from);

				if(params.hasOwnProperty('storeIntegration') && params.storeIntegration !== null) 
					store.set('storeIntegration', params.storeIntegration);

				if(params.hasOwnProperty('storeOrder') && params.storeOrder !== null) {
					const { listItems, ...rest } = params.storeOrder;

					let storeOrder = new StoreOrderStore(rest);
					let list = storeOrder.get('listItems')

					for(let item of listItems) {
						list = list.set(item.id, new StoreItemStore(item))
					}
					
					storeOrder = storeOrder.set('listItems', list)

					store.set('storeOrder', storeOrder);
				}

				if(params.hasOwnProperty('isCancelable') && params.isCancelable !== null) 
					store.set('isCancelable', params.isCancelable);

				if(params.hasOwnProperty('hidden') && params.hidden !== null) 
					store.set('hidden', params.hidden);

				if(params.hasOwnProperty('promocode') && params.promocode !== null) 
					store.set('promocode', params.promocode);

				if(params.hasOwnProperty('id_reward') && params.id_reward !== null) 
					store.set('id_reward', params.id_reward);

				if(params.hasOwnProperty('subtotal') && params.subtotal !== null)
					store.set('subtotal', new PriceStore(params.subtotal));

				if(params.hasOwnProperty('discount') && params.discount !== null)
					store.set('discount', new PriceStore(params.discount));

				if(params.hasOwnProperty('shipping') && params.shipping !== null)
					store.set('shipping', new PriceStore(params.shipping));

				if(params.hasOwnProperty('taxes') && params.taxes !== null)
					store.set('taxes', new PriceStore(params.taxes));

				if(params.hasOwnProperty('total') && params.total !== null)
					store.set('total', new PriceStore(params.total));

				if(params.hasOwnProperty('discount_wholesale') && params.discount_wholesale !== null)
					store.set('discount_wholesale', new PriceStore(params.discount_wholesale));

				if(params.hasOwnProperty('date_created') && params.date_created !== null)
					store.set('date_created', params.date_created);

				if(params.hasOwnProperty('date_paid') && params.date_paid !== null)
					store.set('date_paid', params.date_paid);

				if(params.hasOwnProperty('pickup') && params.pickup !== null)
					store.set('pickup', params.pickup == true);

				if(params.hasOwnProperty('id_shipping_type') && params.id_shipping_type !== null)
					store.set('id_shipping_type', Number(params.id_shipping_type));

				if(params.hasOwnProperty('availableShippingTypes') && params.availableShippingTypes !== null) 
					store.set('availableShippingTypes', Immutable.OrderedMap<number, ShippingType>());
					for(let typeIndex in params.availableShippingTypes) {
						if(!params.availableShippingTypes.hasOwnProperty(typeIndex)) continue;
						let type = params.availableShippingTypes[typeIndex];
						store.setIn(['availableShippingTypes', type.id], new ShippingType(type));
					}

				if(params.hasOwnProperty('shipbyDate') && params.shipbyDate !== null)
					store.set('shipbyDate', params.shipbyDate);

				if(params.hasOwnProperty('arrivalDateMin') && params.arrivalDateMin !== null)
					store.set('arrivalDateMin', params.arrivalDateMin);

				if(params.hasOwnProperty('arrivalDateMax') && params.arrivalDateMax !== null)
					store.set('arrivalDateMax', params.arrivalDateMax);

				if(params.hasOwnProperty('id_shipping_address') && params.id_shipping_address !== null)
					store.set('id_shipping_address', params.id_shipping_address);

				if(params.hasOwnProperty('name') && params.name !== null)
					store.set('name', params.name);

				if(params.hasOwnProperty('address') && params.address !== null)
					store.set('address', params.address);

				if(params.hasOwnProperty('address2') && params.address2 !== null)
					store.set('address2', params.address2);

				if(params.hasOwnProperty('city') && params.city !== null)
					store.set('city', params.city);

				if(params.hasOwnProperty('province') && params.province !== null)
					store.set('province', params.province);

				if(params.hasOwnProperty('postalCode') && params.postalCode !== null)
					store.set('postalCode', params.postalCode);

				if(params.hasOwnProperty('country') && params.country !== null)
					store.set('country', params.country);

				if(params.hasOwnProperty('shipping_phone') && params.shipping_phone !== null)
					store.set('shipping_phone', params.shipping_phone);

				if(params.hasOwnProperty('id_billing_address') && params.id_billing_address !== null)
					store.set('id_billing_address', params.id_billing_address);

				if(params.hasOwnProperty('billing_name') && params.billing_name !== null)
					store.set('billing_name', params.billing_name);

				if(params.hasOwnProperty('billing_address') && params.billing_address !== null)
					store.set('billing_address', params.billing_address);

				if(params.hasOwnProperty('billing_address2') && params.billing_address2 !== null)
					store.set('billing_address2', params.billing_address2);

				if(params.hasOwnProperty('billing_city') && params.billing_city !== null)
					store.set('billing_city', params.billing_city);

				if(params.hasOwnProperty('billing_province') && params.billing_province !== null)
					store.set('billing_province', params.billing_province);

				if(params.hasOwnProperty('billing_postalCode') && params.billing_postalCode !== null)
					store.set('billing_postalCode', params.billing_postalCode);

				if(params.hasOwnProperty('billing_country') && params.billing_country !== null)
					store.set('billing_country', params.billing_country);

				if(params.hasOwnProperty('billing_phone') && params.billing_phone !== null)
					store.set('billing_phone', params.billing_phone);

				if(params.hasOwnProperty('items') && params.items !== null) {
					store.set('items', Immutable.OrderedMap<string, BaseItem>());
					for(let itemId in params.items) {
						store.setIn(['items', itemId], CartItem(params.items[itemId]));
					}
				}

				if(params.hasOwnProperty('refunds') && params.refunds !== null) {
					let listRefunds = Immutable.OrderedMap<string, RefundStore>();

					for(let refund of Object.values(params.refunds) as RefundPayload[]) {
						const { amount, ...rest } = refund;
						let refundData = new RefundStore(rest);
						refundData = refundData.set('amount', new PriceStore(refund.amount))

						listRefunds = listRefunds.set(String(refund.id), refundData)
					}

					store.set('refunds', listRefunds)
				}

				if(params.hasOwnProperty('reprints') && params.reprints !== null)
					store.set('reprints', Immutable.OrderedSet<number>(params.reprints))

				if(params.hasOwnProperty('packages') && params.packages !== null) {
					let listPackages = Immutable.OrderedMap<string, PackageStore>();

					for(let data of Object.values(params.packages) as PackagePayload[]) {
						const { listItems, ...rest } = data;
						let dataStore = new PackageStore(rest);

						for(let itemData of Object.values(listItems) as PackageItemPayload[]) {
							let itemDataStore = new PackageItemStore(itemData);
							dataStore = dataStore.set('listItems', dataStore.get('listItems').set(String(itemData.id), itemDataStore))
						}

						listPackages = listPackages.set(String(data.id), dataStore)
					}

					store.set('packages', listPackages)
				}
			});

			super(store);
		} else super();
	}
}