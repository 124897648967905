import { Button, Grid, IconButton, InputBase, MenuItem, Pagination, Paper, Select, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import styledExtension from '../../Common/Utils/StyledExtension';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Common/_components/Loader';
import WebsiteStore from '../../WebsiteStore';
import { GetPatternCategories, GetPatterns, SelectCategory } from '../_actions/PatternDesignActions';
import { PatternsPerPage } from '../_stores/PatternDesignServiceStore';
import PatternStore from '../_stores/PatternStore';
import PatternBrowserItem from './PatternBrowserItem';
import CloseIcon from '@mui/icons-material/Close';
import SearchBar from '../../Common/ItemList/SearchBar';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import Dialog from '../../Common/Dialog/Dialog';
import DialogContent from '../../Common/Dialog/DialogContent';
import DialogPaper from '../../Common/Dialog/DialogPaper';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';


const StyledDialogContent = styled(DialogContent)({
	padding: '40px 50px 0',
	overflow: 'hidden',
	display: 'flex',

	'@media (max-width: 899px)': {
		padding: '20px 20px 0',
	}
})

const StyledDialogPaper = styled(DialogPaper)({
  height: '90%',
})

const OverflowGridContainer = styled(Grid)({
  '@media (max-width: 899px)': {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  }
})

const OverflowGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',

  '@media (max-width: 899px)': {
    '&.categories': {
      height: 'auto',
      flexBasis: 'auto',
      flexShrink: 0,
      flexGrow: 0,
    },

    '&:not(.categories)': {
      height: 'auto',
      flexBasis: 'auto',
      flexShrink: 1,
      flexGrow: 1,
      paddingTop: '15px',
      marginTop: '-15px',
    }
  }
})

const ScrollableGrid = styled(Grid)({
  overflow: 'auto',
  marginBottom: 0,
  paddingBottom: '30px',
  width: 'calc(100% + 16px)',
  paddingRight: '5px',
  flex: 1,
  alignContent: 'flex-start',

  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
    }
  },
})

const CategoryList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  marginBottom: '30px',

  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
    }
  }
})

const SubCategoryList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const CategoryButton = styledExtension(Typography).attrs({
  variant: 'h4',
})({
  display: 'block',
  textAlign: 'left',
  width: '100%',
  fontSize: '18px',
  padding: '5px 0',
  cursor: 'pointer',

  '&.active': {
    color: '#ea008a',
  },

  '&:hover': {
    color: '#ea008a',
  },
})

const MobileCategory = styled('div')({
  '@media (min-width: 900px)': {
    display: 'none',
  }
})

const DesktopCategory = styled('div')({
  flexShrink: 1,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',

  '@media (max-width: 899px)': {
    display: 'none',
  }
})

const StyledPagination = styled(Pagination)(({ theme }) => ({
  display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	padding: theme.spacing(2, 0),

	[theme.breakpoints.down('md')]: {
		padding: theme.spacing(1, 0),
	}
}))


export type Props = {
  opened: boolean
  onClose: () => void
  onSelect: (item: PatternStore) => void
}

export default function PatternBrowser(props: Props) {
  const dispatch = useAppDispatch();
  const [t] = useTranslation('design-lab');
  const theme = useTheme()
	const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const loading = useAppSelector(state => state.get('UIData').get('loaders').get('images'));
  const listCategories = useAppSelector(state => state.get('UIData').get('designLab').get('patternDesignService').get('categories'));
  const listPatterns = useAppSelector(state => state.get('UIData').get('designLab').get('patternDesignService').get('patterns'));
  const selectedCategoryId = useAppSelector(state => state.get('UIData').get('designLab').get('patternDesignService').get('selectedCategoryId'));
  const count = useAppSelector(state => state.get('UIData').get('designLab').get('patternDesignService').get('count'));
  const [openedCategory, setOpenedCategory] = useState<number | null>(null);

  const listSubcategories = useMemo(() => {
    return openedCategory !== null ? listCategories.get(openedCategory)?.get('categories') : undefined
  }, [listCategories, openedCategory]);

  useEffect(() => {
    dispatch(GetPatternCategories());
  }, []);

  useEffect(() => {
    dispatch(GetPatterns(selectedCategoryId ?? null, search, PatternsPerPage, (page - 1) * PatternsPerPage));
  }, [selectedCategoryId, search, page]);

  const renderItem = useCallback((pattern:PatternStore) => {
    return <PatternBrowserItem
      key={pattern.get('id')}
      item={pattern}
      onSelect={props.onSelect}
      onClose={props.onClose}
    />
  }, [props.onSelect, props.onClose])

  return <>
    <Dialog
      open={props.opened}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth="xl"
      PaperComponent={StyledDialogPaper}
      showCloseButton
    >
      <StyledDialogContent>
        <OverflowGridContainer container spacing={5}>
          <OverflowGrid
            className="categories"
            item
            xs={12}
            md="auto"
            sx={{
              width: {
                xs: 'auto',
                md: '300px',
              }
            }}
          >
            <Typography
              variant="h2"
              style={{
                marginBottom: '20px',
              }}
            >{t('Pattern Library')}</Typography>

            <Typography
              variant="body2"
              style={{
                marginBottom: '20px',
              }}
            >{t('All patterns in this library are from patterndesigns.com. Products created using patterns from this library will have an additional 12\u0025 fee when ordered.')}</Typography>

            <MobileCategory style={{ marginBottom: '16px' }}>
              <Stack spacing={2}>
                <Select
                  value={openedCategory ?? ''}
                  variant="standard"
                  //input={<InputSelect/>}
                  onChange={(e) => {
                    setPage(1);
                    dispatch(SelectCategory(e.target.value !== '' ? Number(e.target.value) : null));
                    setOpenedCategory(openedCategory !== e.target.value ? Number(e.target.value) : null);
                  }}
                >
                  <MenuItem value={''}>{t('All Categories')}</MenuItem>
                  {listCategories.valueSeq().map(category => {
                    return <MenuItem
                      key={category.get('id')}
                      value={category.get('id')}
                    >{category.get('title')}</MenuItem>
                  })}
                </Select>
                {openedCategory && listSubcategories ? <Select
                  value={(selectedCategoryId !== null && listSubcategories.has(selectedCategoryId) ? selectedCategoryId : openedCategory) ?? ''}
                  variant="standard"
                  //input={<InputSelect/>}
                  onChange={(e) => {
                    setPage(1);
                    dispatch(SelectCategory(Number(e.target.value)));
                  }}
                >
                  <MenuItem value={openedCategory}>{t('All Sub-Categories')}</MenuItem>
                  {listSubcategories.valueSeq().map(subCategory => {
                    return <MenuItem
                      key={subCategory.get('id')}
                      value={subCategory.get('id')}
                    >{subCategory.get('title')}</MenuItem>
                  })}
                </Select> : null}
              </Stack>
            </MobileCategory>

            <DesktopCategory>
              <Typography
                variant="h3"
                style={{
                  marginBottom: '10px',
                }}
              >{t('Categories:')}</Typography>
              <CategoryList>
                <CategoryButton
                  className={!openedCategory && !selectedCategoryId ? 'active' : ''}
                  onClick={() => {
                    setPage(1);
                    dispatch(SelectCategory(null));
                    setOpenedCategory(null);
                  }}
                >{t('All')}</CategoryButton>

                {listCategories.valueSeq().map(category => {
                  return <div key={category.get('id')}>
                    <CategoryButton
                      className={openedCategory === category.get('id') || selectedCategoryId === category.get('id') ? 'active' : ''}
                      onClick={() => {
                        setPage(1);
                        dispatch(SelectCategory(category.get('id')))
                        setOpenedCategory(openedCategory !== category.get('id') ? category.get('id') : null);
                      }}
                    >{category.get('title')}</CategoryButton>
                    {openedCategory === category.get('id') && <SubCategoryList style={{
                      paddingLeft: '20px',
                    }}>
                      {category.get('categories').valueSeq().map(subCategory => {
                        return <CategoryButton
                          key={subCategory.get('id')}
                          className={selectedCategoryId === subCategory.get('id') ? 'active' : ''}
                          style={{
                            fontSize: '16px',
                          }}
                          onClick={() => {
                            setPage(1);
                            dispatch(SelectCategory(subCategory.get('id')))
                          }}
                        >{subCategory.get('title')}</CategoryButton>
                      })}
                    </SubCategoryList>}
                  </div>
                })}
              </CategoryList>
            </DesktopCategory>
          </OverflowGrid>
          <OverflowGrid item xs={12} md>
            <SearchBar
              placeholder={t('Search')}
              value={search}
              onChange={(value) => {
                setSearch(value);
                setPage(1);
              }}
            ></SearchBar>
            <ScrollableGrid container spacing={2}>
              {listPatterns.valueSeq().map(renderItem)}
            </ScrollableGrid>
            <StyledPagination
              count={Math.ceil(count / PatternsPerPage)}
              showFirstButton={breakpointSmDown ? false : Math.ceil(count / PatternsPerPage) > 5}
              showLastButton={breakpointSmDown ? false : Math.ceil(count / PatternsPerPage) > 5}
              siblingCount={breakpointSmDown ? 0 : 1}
              page={page}
              onChange={(e, value) => {
                setPage(value);
              }}
            />
          </OverflowGrid>
        </OverflowGridContainer>
      </StyledDialogContent>
      {loading && <Loader noModal />}
    </Dialog>
  </>
}