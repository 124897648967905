import { Grid, styled } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { ButtonWhiteHeight45Weight500Larger } from '../../Common/_components/Buttons';
import { SuccessMsgSubTitle, SuccessMsgTitle } from '../../Common/_components/Typography';

export type Props = {
	index ?: number
	isSingle?: boolean
	isDonePublishing?: boolean
	handleStep ?: (step:number) => void
	handleCloseModal: () => void
	massSending ?: boolean
	total?: number
}
export type State = {
	value: number
	offset: number
}
const MsgContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	gap: '10px',
	marginTop: '30px',
});
const Margin120 = styled('div')({
	marginTop: '120px',
});
const ProgressContainer = styled(Grid)({
	width: '85%',
	marginTop: '65px',
	marginBottom: '30px',

	'@media (max-width:960px)': {
		marginTop: '40px',
	},
});
const ParagraphSmall = styled('p')({
	fontSize: '12px',
	margin: 0,
	letterSpacing: '0.01em',
	color: '#333333',
});
const FullWidthGrid = styled(Grid)({
	width: '100%',
});
const StyledLinearProgress = styled(LinearProgress)({
	backgroundColor: '#FCEFF7',
	height: '8px',
	borderRadius: '30px',

	'.MuiLinearProgress-barColorPrimary': {
		backgroundColor: 'rgba(246, 0, 190, 1)',
		borderRadius: '30px',
	}
});
const FlexDiv = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '3px',
});
const CloseIcon = styled('i')({
	fontWeight: 600,
	fontSize: '20px',
});

const ModalContent = styled('div')({
	padding: '50px 60px 30px',
	width: '100%',
	overflow: 'auto',

	'@media (max-width: 1200px)': {
		padding: '44px 40px 30px',
	},

	'@media (max-width: 959px)': {
		padding: 0,
		overflow: 'visible',
	}
});

const T = ({children, ...otherProps}:any) => <Trans ns="dashboard" {...otherProps}>{children}</Trans>

export default class Publishing extends React.Component<Props> {
	private timer?:NodeJS.Timeout;

	public state = {
		value: 0,
		offset: 0,
	} as State

	componentDidMount(){
		if(!this.props.isSingle && typeof window.aow.page_obj.refreshProductQueue !== 'undefined') {
			if(typeof window.aow.page_obj.productsInQueue !== 'undefined') {
				this.setState({
					offset: window.aow.page_obj.productsInQueue
				});

				window.aow.page_obj.productsInQueue += this.props.total;
			} else {
				window.aow.page_obj.productsInQueue = this.props.total;
			}

			window.aow.page_obj.refreshProductQueue();

			this.timer = setInterval(() => {
				if(typeof window.aow.page_obj.productsInQueue !== 'undefined') {
					this.setState({
						value: this.props.total - (window.aow.page_obj.productsInQueue - this.state.offset)
					});

					if(window.aow.page_obj.productsInQueue == 0) {
						clearInterval(this.timer);
					}
				}
			}, 500);
		}
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	private handleGoPreviousState = () => {
		this.props.handleStep(this.props.index - 2)
	}
	private handleShowResult = () => {
		this.props.handleStep(this.props.index + 1)
	}

	render = () => 
	{

		return <>
			<ModalContent>
				<Grid container direction='column'  alignItems='center'>
					<Grid item>
						<MsgContainer>
							<SuccessMsgTitle>
								<T>We got you !</T>
							</SuccessMsgTitle>
							<SuccessMsgSubTitle>
								<T>You can close this window while products are being published in the background.</T>
							</SuccessMsgSubTitle>
						</MsgContainer>
					</Grid>
					<ProgressContainer item>
						<StyledLinearProgress variant={this.state.value === 0 && !this.props.isDonePublishing ? "indeterminate":"determinate"} value={this.props.isDonePublishing ? 100 : (this.state.value / this.props.total * 100)} />
					</ProgressContainer>
					<Grid item>
						{ this.state.value < this.props.total && !this.props.isDonePublishing ?
							<ParagraphSmall><T>Publishing</T> { (this.state.value + 1) } <T>of</T> { this.props.total } <T>products</T></ParagraphSmall>
							: <ParagraphSmall><T>All done!</T></ParagraphSmall>
						}
					</Grid>
					<FullWidthGrid item>
						<Margin120>
							<ButtonWhiteHeight45Weight500Larger onClick={() => {
								this.props.handleCloseModal();
							}}>
								<FlexDiv><CloseIcon className='material-icons'>close</CloseIcon> <T>Close</T></FlexDiv>
							</ButtonWhiteHeight45Weight500Larger>
						</Margin120>
					</FullWidthGrid>
				</Grid>
			</ModalContent>
		</>
	}
}