import * as Immutable from 'immutable';
import UserStorePageStore, { IUserStorePageStore } from '../_stores/UserStorePageStore';
import { UserStorePageAction, UserStorePageActionType } from '../_actions/UserStorePageActions';

const UserStorePagesReducer = (
	store:Immutable.OrderedMap<string, UserStorePageStore> = Immutable.OrderedMap<string, UserStorePageStore>(),
	action: UserStorePageAction
):Immutable.OrderedMap<string, UserStorePageStore> => {
	let loading = false;
	switch (action.type) {
		case UserStorePageActionType.EditUserStorePages:
			store = store.withMutations(store => {
				for(let storePage in action.storePages) {
					if(!action.storePages.hasOwnProperty(storePage)) continue;
					let a = action.storePages[storePage];
					a.loading = a.loading || false;

					let  {...rest} = a as IUserStorePageStore;

					let page = new UserStorePageStore();
					page = page.withMutations(page => {
						page.merge(Immutable.fromJS(rest));
					});

					store.set(a.id, page);
				}
			})
		break;

		case UserStorePageActionType.RemoveUserStorePage:
			store = store.delete(Number(action.id));
			break;
	}
	return store;
}

export default UserStorePagesReducer