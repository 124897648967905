import { Alert, Box, Button, Collapse, Container, IconButton, InputAdornment, Paper, Stack, TextField, Typography, Link } from "@mui/material";
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon } from '@mui/icons-material'
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import tKey from "../Common/Utils/tKey";
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { useAppDispatch } from "../Common/_hooks/useAppDispatch";
import { AuthActionType, NewPassword, ResendValidationLink, SubmitAccountLink } from "./_actions/AuthActions";
import AuthForm from "./AuthForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import useErrorText from "../Common/Error/useErrorText";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import { useAppSelector } from "../Common/_hooks/useAppSelector";
import { EditLoader } from "../UIData/_actions/UIDataActions";

export default function AccountLink() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('auth')
  const [tR] = useTranslation('routes')

  const email = useAppSelector(state => state.get('Auth').get('signup_email'))
  const service = useAppSelector(state => state.get('Auth').get('signup_service'))
  const apiError = useAppSelector(state => state.get('UIData').get('apiErrors').get(AuthActionType.SubmitAccountLink))
  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('auth') || state.get('UIData').get('loaders').get('auth-navigating'))

  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [forgotPasswordDialogOpened, setForgotPasswordDialogOpened] = useState(false);

  const onChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value), [])
  const onClickShowPassword = useCallback(() => setShowPassword(value => !value), [])

  const validPasswords = useMemo(() => {
    return password !== ''
  }, [password])

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(SubmitAccountLink(
      password
    ).set({
      onSuccess: () => {
        dispatch(EditLoader('auth-navigating', true))
        window.location.href = tR('/account');
      }
    }))
  }, [tR, password])

  const onOpenForgotPassword = useCallback(() => setForgotPasswordDialogOpened(true), [])
  const onCloseForgotPassword = useCallback(() => setForgotPasswordDialogOpened(false), [])

  const errorText = useErrorText(AuthActionType.SubmitAccountLink)

  return <Box component="div" sx={{
    backgroundImage: 'url(' + require('@resources/img/theme/page/ornaments-1.svg?url') + ')',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    pt: 6,
    pb: 15,
    flex: '1 0 auto',
  }}>
    <Container
      maxWidth="sm"
    >
      <Helmet>
        <title>{t('Link your account')}</title>
      </Helmet>

      <Stack spacing={3}>
        <Typography variant="h1" textAlign="center">{t('Link your account')}</Typography>
        <Paper
          elevation={0}
          sx={{
            p: 4,
          }}
        >
          <form onSubmit={onSubmit}>
            <Stack spacing={2} alignItems="flex-start">
              <Typography variant="body1">
                <Trans
                  t={t}
                  i18nKey={tKey('An Art of Where account with the email <strong>{{email}}</strong> already exists. Please enter the password for this account to confirm that this is you, and we\'ll link your {{service}} account to this Art of Where account.')}
                  values={{
                    email: email,
                    service: service,
                  }}
                  components={{
                    strong: <strong />
                  }}
                />
              </Typography>

              <TextField
                label={t('Password')}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={onChangePassword}
                error={['invalid-password'].includes(apiError?.error ?? '')}
                helperText={apiError?.error === 'invalid-password' ? errorText : undefined}
                autoComplete="current-password"
                disabled={!!loader}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      onClick={onClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                }}
              />

              <p>{t('Forgot your password?')} <Link component="button" type="button" onClick={onOpenForgotPassword}>{t('Click here')}</Link></p>

              {['account-link-error'].includes(apiError?.error ?? '') ? <Alert
                severity="error"
              >{errorText}</Alert> : null}

              <Button
                color="accent"
                variant="contained"
                size="large"
                disabled={!validPasswords || !!loader}
                type="submit"
              >{t('Confirm')}</Button>
            </Stack>
          </form>
        </Paper>
      </Stack>
    </Container>

    <ForgotPasswordDialog
      opened={forgotPasswordDialogOpened}
      onClose={onCloseForgotPassword}
    />
  </Box>
}