// App
export const IS_SERVER:boolean = false
export const DATA_VERSION:string = "1.0.0"

// Design Lab
export const LAB_ZOOM_MULTIPLIER:number = 400
export const LAB_TEMPLATE_WIDTH:number = 790
export const LAB_TEMPLATE_HEIGHT:number = 525
export const LAB_FONTS = [
    "Norican",
    "Mr Dafoe",
    "Yesteryear",
    "Lobster",
    "Pattaya",
    "Kalam",
    "Fredoka One",
    "Chewy",
    "Noto Serif",
    "PT Serif",
    "Basic",
    "Patua One",
    "Roboto",
    "Anton",
    "Aclonica",
    "UnifrakturCook:700",
    "UnifrakturMaguntia"
];