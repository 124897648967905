import { Avatar, Box, Button, Chip, Divider, ListItemButton, Paper, Stack, Typography, styled } from '@mui/material'
import { type BaseItem } from '../../UserData/_stores/UserCartStore'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { useCallback, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useOrderStatusProps from '../../Common/_hooks/Order/useOrderStatusProps'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'

const ThumbnailWrapper = styled('div')({
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  border: '2px solid #fff'
})

interface Props {
  order: UserCartStore
  index: number
}

export default function OrderRow(props: Props) {
  const [t] = useTranslation('account-v2')

  const storeIntegration = useAppSelector(state => state.get('appData').get('storeIntegrations').get(props.order.get('storeIntegration') ?? ''))

  const orderDate = useMemo(() => {
    return DateTime.fromISO(props.order.get('date_paid')
      ? props.order.get('date_paid')
      : props.order.get('date_created'))
  }, [props.order])

  const {
    text: statusText,
    color: statusColor
  } = useOrderStatusProps(props.order.get('status'))

  const renderItemThumbnail = useCallback((item: BaseItem) => {
    return <ThumbnailWrapper key={item.get('id')}>
      {item.get('thumbnail') ? <img
        src={item.get('thumbnail')}
        alt={t('Preview of {{name}}', { name: item.get('name') })}
        style={{
          height: '100%',
          display: 'block'
        }}
      /> : null}
    </ThumbnailWrapper>
  }, [t])

  return <>
    <Paper
      elevation={0}
      sx={{
        display: {
          xs: 'block',
          lg: 'none'
        }
      }}
    >
      <Stack divider={<Divider/>}>
        <Stack sx={{ p: 2 }} spacing={1}>
          <div>
            <Stack direction="row" spacing={1}>
              <Typography variant="h3">#{props.order.get('ref_id').toUpperCase()}</Typography>
              <Chip
                variant="square-filled"
                size="small"
                label={statusText}
                color={statusColor}
              />
            </Stack>
            <Typography variant="body1">{orderDate.toLocaleString(DateTime.DATETIME_MED)}</Typography>
          </div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
            >
              { props.order.get('order_from') === 'invoice' ? <Chip
                label={t('Invoice')}
              /> : null }
              { storeIntegration ? <div>
                <Avatar
                  src={require('@resources/img/account/' + storeIntegration.get('slug') + '-icon.svg?url')}
                  alt={storeIntegration.get('name')}
                  sx={{
                    width: 32,
                    height: 32,
                    p: 0.5,
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    display: {
                      xs: 'flex',
                      sm: 'none'
                    }
                  }}
                />
                <Chip
                  avatar={<img src={require('@resources/img/account/' + storeIntegration.get('slug') + '-icon.svg?url')} />}
                  label={storeIntegration.get('name')}
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'inline-flex'
                    }
                  }}
                />
              </div> : null }
              <Stack
                direction="row"
                spacing={-1}
              >
                { props.order.get('items').take(3).valueSeq().map(renderItemThumbnail)}
              </Stack>
            </Stack>
            <Typography variant="h4">{props.order.get('total').toDinero().toFormat()}</Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{ p: 2 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <div>
            <Typography variant="h4">{t('Ship to')}</Typography>
            <Typography variant="body1">{props.order.get('name')}</Typography>
          </div>
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to={props.order.get('ref_id')}
          >{t('View details')}</Button>
        </Stack>
      </Stack>
    </Paper>

    <Box
      component="div"
      sx={{
        display: {
          xs: 'none',
          lg: 'block'
        }
      }}
    >
      {props.index > 0 ? <Divider /> : null}
      <ListItemButton
        component={Link}
        to={props.order.get('ref_id')}
        sx={{
          width: '100%',
          padding: 0,
          textAlign: 'left',
          display: 'block',
          py: 2,
          px: 2
        }}
      >
        <Stack
          direction="row"
          spacing={4}
          sx={{
            alignItems: 'center'
          }}
        >
          <Box
            component="div"
            sx={{ width: '100px', flexShrink: 0 }}
          >
            <Typography variant="h4">#{props.order.get('ref_id').toUpperCase()}</Typography>
          </Box>
          <Box
            component="div"
            sx={{ width: '170px', flexShrink: 0 }}
          >
            <Typography variant="body1">{orderDate.toLocaleString(DateTime.DATETIME_MED)}</Typography>
          </Box>
          <Box
            component="div"
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {props.order.get('name')}
          </Box>
          <Box
            component="div"
            sx={{ width: '130px', flexShrink: 0 }}
          >
            <Typography variant="h4">{props.order.get('total').toDinero().toFormat()}</Typography>
          </Box>
          <Box
            component="div"
            sx={{
              width: '150px',
              flexShrink: 0,
              display: {
                md: 'none',
                lg: 'block'
              }
            }}
          >
            <Chip
              variant="square-filled"
              size="small"
              label={statusText}
              color={statusColor}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Chip
            variant="square-filled"
            size="small"
            label={statusText}
            color={statusColor}
            sx={{
              display: {
                md: 'inline-flex',
                lg: 'none'
              }
            }}
          />
          { props.order.get('order_from') === 'invoice' ? <Chip
            label={t('Invoice')}
          /> : null }
          { storeIntegration ? <Chip
            avatar={<img src={require('@resources/img/account/' + storeIntegration.get('slug') + '-icon.svg?url')} />}
            label={storeIntegration.get('name')}
          /> : null }
          <Stack
            direction="row"
            spacing={-1}
          >
            { props.order.get('items').take(5).valueSeq().map(renderItemThumbnail)}
          </Stack>
        </Stack>
      </ListItemButton>
    </Box>
  </>
}
