import { styled } from '@mui/material';

const TableSimpleHeader = styled('th')`
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.02em;
`;
const TableBoldHeader = styled('th')`
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
`;
const TableSimpleHeaderTextEnd = styled(TableSimpleHeader)`
    text-align: end;
`;
const TableSimpleHeaderTextCenter = styled(TableSimpleHeader)`
    text-align: center;
`;
const TableBoldHeaderTextEnd = styled(TableBoldHeader)`
    text-align: end;
`;
const TableBoldHeaderTextCenter = styled(TableBoldHeader)`
    text-align: center;
`;
const TdTextCenter = styled('td')`
    text-align: center;
`;
const TdTextEnd = styled('td')`
    text-align: end;
`;

export {TableSimpleHeader, TableBoldHeader, TableBoldHeaderTextEnd, TableBoldHeaderTextCenter, TableSimpleHeaderTextCenter, TableSimpleHeaderTextEnd ,TdTextCenter, TdTextEnd}