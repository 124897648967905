import * as Immutable from 'immutable';
import { PaginationItem } from './PaginationStore'
import { DateTime } from 'luxon';

export type PhotoLabCreationPayload = {
	id: number
  id_product_design: number
  file_url: string
  date_created: string
}

interface IPhotoLabCreationStore extends PaginationItem {
	id: number
  id_product_design: number
  file_url: string
  date_created: string
}

const defaultPhotoLabCreationStore = Immutable.Record<IPhotoLabCreationStore>({
	id: 0,
  id_product_design: 0,
  file_url: '',
  date_created: DateTime.now().toISO(),
});

export class PhotoLabCreationStore extends defaultPhotoLabCreationStore implements IPhotoLabCreationStore {}