import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { Avatar, IconButton, ListItemText, Menu, MenuItem, Stack, Tooltip, styled } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { EditActiveOption } from '../../UIData/_actions/DesignLabStoreActions';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import Immutable from 'immutable';

const ColorCircle = styled('div')({
  width: '40px',
  height: '40px',
  borderRadius: '40px',
})

const ChooseMe = styled('div')({
  borderRadius: 50,
  width: 16,
  height: 16,
  padding: 1,
  backgroundColor: '#e33250',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
  animation: 'pulse 1s infinite',

  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.3)',
    },
    '100%': {
      transform: 'scale(1)',
    }
  },

  '&.valid': {
    backgroundColor: '#88d232',
    animation: 'none',
  }
});

const ResizedCheckIcon = styled(CheckIcon)({
  color: '#fff',
  fontSize: 14,
});

const ResizedCloseIcon = styled(CloseIcon)({
  color: '#fff',
  fontSize: 14,
})

export class LabOptionValue extends Immutable.Record<{
  name: string,
  slug: string,
  color?: string,
}>({
  name: '',
  slug: '',
  color: undefined,
}) { }

type Props = {
  type: 'option'|'detail'
  layout: 'color'|'icon'|'img-badge'
  name: string,
  option: string,
  listValues: Immutable.OrderedMap<string, LabOptionValue>
}

export default function LabOptionTool(props: Props) {
  const dispatch = useAppDispatch();
  const activeProductId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
  const productData = useSelector((state:WebsiteStore) => state.get('appData').get('products').get(String(activeProductId)));
  const activeOptions = useSelector((state: WebsiteStore) => state.get('UIData').get('designLab').get('activeOptions'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onIconClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (open) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }, [open]);

  const onMenuClose = useCallback(() => setAnchorEl(null), []);

  const isSelected = useMemo(() => Boolean(activeOptions.get(props.option)), [activeOptions, props.option]);

  let badgeIconSrc = null
  if(props.layout === 'img-badge' && activeOptions.has(props.option)) {
    badgeIconSrc = require('@resources/img/create/design-lab-v4/icons/options/'+props.option+'/'+activeOptions.get(props.option)+'.jpg');
  }

  const iconSrc = useMemo(() => {
    if(!productData) return null

    if(props.layout === 'icon' && activeOptions.has(props.option)) {
      return require('@resources/img/create/design-lab-v4/icons/options/'+props.option+'/'+activeOptions.get(props.option)+'.svg?url');
    } else {
      const filename = props.type === 'option' ? productData.get('slug')+'_'+props.option : props.option;
      return require('@resources/img/create/design-lab-v4/icons/options/'+filename+ '.svg?url')
    }
    
  }, [productData, props.type, props.layout, activeOptions])

  return <>
    <Tooltip title={props.name}>
      <IconButton
        onClick={onIconClick}
        style={{
          padding: '0',
          marginLeft: '15px',
        }}
      >
        { props.type === 'detail' ? <ChooseMe className={isSelected ? 'valid' : ''}>
          {isSelected ? <ResizedCheckIcon /> : <ResizedCloseIcon />}
        </ChooseMe> : null }
        { iconSrc ? <ReactSVG
          src={iconSrc}
          beforeInjection={(svg) => {
            svg.style.display = 'block';
            svg.style.width = '56px';
            svg.style.height = '56px';

            if(props.layout === 'color') {
              const color = props.listValues.get(activeOptions.get(props.option) ?? '')?.get('color');

              if (!color) return null;

              svg.querySelectorAll('.' + props.option).forEach(elem => {
                elem.setAttribute('fill', color);
              });
            }
          }}
        /> : null }
        { props.layout === 'img-badge' ? <>
            <Avatar
                src={badgeIconSrc}
                sx={{
                    width: 32,
                    height: 32,
                    position: 'absolute',
                    top: '-8px',
                    right: '-12px',
                    border: '2px solid #fff',
                    borderRadius: '50%',
                }}
            />
        </> : null }
      </IconButton>
    </Tooltip>
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onMenuClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      slotProps={{
        paper: {
          style: {
              borderRadius: props.layout === 'color' ? '50px' : undefined,
              backgroundColor: '#ebebeb',
          }
        }
      }}
    >
      {props.listValues.valueSeq().map(value => {
        if (['icon','img-badge'].includes(props.layout)) {
          let img
          if(props.layout === 'icon') {
            img = require('@resources/img/create/design-lab-v4/icons/options/'+props.option+'/' + value.get('slug') + '.svg?url');
          } else {
            img = require('@resources/img/create/design-lab-v4/icons/options/'+props.option+'/' + value.get('slug') + '.jpg');
          }

          return <MenuItem
            key={value.get('slug')}
            onClick={() => {
              dispatch(EditActiveOption(props.option, value.get('slug')));
              setAnchorEl(null);
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                src={img}
                sx={{
                  width: 48,
                  height: 48,
                }}
              />
              <ListItemText>{value.get('name')}</ListItemText>
            </Stack>
          </MenuItem>
        }

        const color = value.get('color')

        if (!color) return null;

        return <Tooltip
          key={value.get('slug')}
          title={value.get('name')}
          placement="left"
        >
          <MenuItem
            onClick={() => {
              dispatch(EditActiveOption(props.option, value.get('slug')));
              setAnchorEl(null);
            }}
          >
            <ColorCircle style={{ backgroundColor: color }} />
          </MenuItem>
        </Tooltip>
      })}
    </Menu>
  </>
}