import { useCallback } from 'react'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { Button, type ButtonProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { CartCheckoutFree } from '../../UserData/_actions/UserCartActions'

type Props = ButtonProps & {
  orders: Immutable.Map<string, UserCartStore>
  confirmationUrl: string
  onErrorText: (error: string) => void
  onDisable: (disable: boolean) => void
}

export default function FreePaymentButton(props: Props) {
  const { orders, confirmationUrl, onErrorText, onDisable, ...otherProps } = props

  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const navigate = useNavigate()

  const onConfirm = useCallback(() => {
    props.onErrorText('')
    props.onDisable(true)

    // Provide id order if we're paying an 'ordered' order
    let idOrder: number | undefined
    if (props.orders.count() === 1 && props.orders.first()?.get('status') === 'ordered') {
      idOrder = props.orders.first()?.get('id')
    }

    dispatch(CartCheckoutFree(idOrder).set({
      onSuccess: (response) => {
        navigate(props.confirmationUrl + '?payment_id=' + encodeURIComponent(response.data.payment_id))
      },
      onFailure: (response) => {
        props.onDisable(false)

        if (response.data?.error) {
          props.onErrorText(response.data.error)
        } else {
          props.onErrorText(t('An error occured processing your order. Please try again later.'))
        }
      }
    }))
  }, [props.onErrorText, props.onDisable, props.confirmationUrl])

  return <Button
    onClick={onConfirm}
    {...otherProps}
  />
}
