import { UserDataAction, UserDataActionType } from '../_actions/UserDataActions';
import UserPrefsStore from '../_stores/UserPrefsStore';

const UserPrefsReducer = (store:UserPrefsStore = new UserPrefsStore(), action: UserDataAction):UserPrefsStore => {
	switch (action.type) {
		case UserDataActionType.EditUserPrefs:
			let {
				currency,
				addressAtCheckout,
				quickDeleteFromCart,
				massDesignLabSaveDismissed,
				accountProductsLayout,
				accountPageLimit,
				lang,
			} = action.updates;
			store = store.withMutations((store) => {
				if(currency) store = store.set('currency', currency);
				if(addressAtCheckout) store = store.set('addressAtCheckout', !!+addressAtCheckout);
				if(quickDeleteFromCart) store = store.set('quickDeleteFromCart', !!+quickDeleteFromCart);
				if(massDesignLabSaveDismissed) store = store.set('massDesignLabSaveDismissed', !!+massDesignLabSaveDismissed);
				if(accountProductsLayout) store = store.set('accountProductsLayout', ['grid','list'].includes(accountProductsLayout) ? accountProductsLayout : 'grid');
				if(accountPageLimit) store = store.set('accountPageLimit', Number(accountPageLimit));
				if(lang) store = store.set('lang', lang);
			});
	}
	return store;
}
export default UserPrefsReducer