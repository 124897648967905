import Dialog from '../../../Common/Dialog/Dialog'
import DialogTitle from '../../../Common/Dialog/DialogTitle'
import { useTranslation } from 'react-i18next'
import { Elements } from '@stripe/react-stripe-js'
import { type StripeElementLocale, loadStripe } from '@stripe/stripe-js'
import config from '../../../../../config/config.json'
import AddCreditCardForm from './AddCreditCardForm'
import { useAppSelector } from '../../../Common/_hooks/useAppSelector'

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY)

interface Props {
  opened: boolean
  onClose: () => void
}

export default function AddCreditCardDialog(props: Props) {
  const [t] = useTranslation('account-v2')

  const userCurrency = useAppSelector(state => state.get('userData').get('prefs').get('currency'))

  return <Dialog
    open={props.opened}
    maxWidth="xs"
    fullWidth
    onClose={props.onClose}
    showCloseButton
  >
    <DialogTitle>{t('Add Credit card')}</DialogTitle>

    <Elements
      stripe={stripePromise}
      options={{
        mode: 'setup',
        currency: userCurrency.toLowerCase(),
        locale: window.LOCALE.replace('_', '-') as StripeElementLocale,
        appearance: {
          theme: 'flat',
          variables: {
            fontFamily: 'Poppins, sans-serif',
            borderRadius: '0px',
            colorPrimaryText: '#000000',
            colorBackground: '#ffffff',
            iconCheckmarkColor: '#ffffff'
          },
          rules: {
            '.Input': {
              backgroundColor: 'rgba(0, 0, 0, 0.06)',
              borderRadius: '25px',
              fontSize: '14px',
              padding: '10px 20px',
              border: '0',
              outline: '0'
            },
            '.Input:focus': {
              boxShadow: 'none',
              outline: '0'
            },
            '.Input--invalid': {
              boxShadow: 'none',
              outline: '0',
              border: '0'
            },
            '.Label': {
              color: '#000',
              fontSize: '14px'
            },
            '.Label--invalid': {
              color: '#df1b41'
            },
            '.CheckboxInput': {
              border: '1px solid #afafaf',
              borderRadius: '3px'
            },
            '.CheckboxInput--checked': {
              backgroundColor: '#000',
              borderColor: '#000'
            },
            '.Error': {
              fontSize: '.8rem'
            },
            '.Tab': {
              padding: '10px 12px 8px 12px',
              border: '1px solid #afafaf',
              borderRadius: '8px'
            },
            '.Tab:hover, .Tab:focus': {
              border: '1px solid #000',
              outline: 'none',
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
              border: '1px solid transparent',
              backgroundColor: '#fff',
              boxShadow: '0 0 0 1.5px #000, 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
            }
          }
        }
      }}
    >
      <AddCreditCardForm
        onClose={props.onClose}
      />
    </Elements>

  </Dialog>
}
