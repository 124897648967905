import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import WebsiteStore from "../../WebsiteStore";
import { Layer as LayerNode, Rect, Stage as StageNode, Text, Image as ImageNode, Group as GroupNode } from "react-konva";
import KonvaPatternImage from "../KonvaNode/KonvaPatternImage";
import KonvaImage from "../KonvaNode/KonvaImage";
import { isBrowser, isNode } from "browser-or-node";
import LayerRenderer from "./LayerRenderer";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";

function getMirrorData(mirrorMode: number, variantData: any, scene: string): any {
  let sourceData, outputData, sourcePart, outputPart;

  // Transform the mirrored image accordingly
  switch (mirrorMode) {
    case 1: // Mirror left
    case 3: // Clone left
      if (!variantData.scenes[scene].parts.left || !variantData.scenes[scene].parts.right) {
        return {};
      }

      sourceData = variantData.scenes[scene].parts.left;
      sourcePart = 'left';
      outputData = variantData.scenes[scene].parts.right;
      outputPart = 'right';
      break;

    case 2: // Mirror right
    case 4: // Clone right
      if (!variantData.scenes[scene].parts.left || !variantData.scenes[scene].parts.right) {
        return {};
      }

      sourceData = variantData.scenes[scene].parts.right;
      sourcePart = 'right';
      outputData = variantData.scenes[scene].parts.left;
      outputPart = 'left';
      break;

    case 5: // Mirror Front
    case 7: //Clone Front
      // We're Processing front so obviously don't do anything if we're on the back
      if (scene === 'back') {
        return {};
      }

      sourceData = variantData.scenes[scene].parts.default;
      sourcePart = 'default';
      outputData = variantData.scenes.back.parts.default;
      outputPart = 'default';
      break;

    case 6: // Mirror Back
    case 8: // Clone Back
      // We're processing back so obviously don't do anything if we're on the front (main)
      if (scene === 'main') {
        return {};
      }

      sourceData = variantData.scenes[scene].parts.default;
      sourcePart = 'default';
      outputData = variantData.scenes.main.parts.default;
      outputPart = 'default';
      break;

    default:
      return {};
  }

  return {
    source: sourceData,
    sourcePart: sourcePart,
    output: outputData,
    outputPart: outputPart,
  };
}

type Props = {
  subproduct: string | null,
  subproductRef?: string,
  scene: string,
  sceneData: Record<string, any>,
  labData: Record<string, any>,
  subproductRef_labData?: Record<string, any>,
  inputType: 'lab',
  outputType: 'texture' | 'render',
}

export default function MirrorRenderer(props: Props) {
  const visibleLayers = useAppSelector(state => state.get('UIData').get('designLab').get('layers'));
  const mirrorModes = useAppSelector(state => state.get('UIData').get('designLab').get('mirrorModes'));
  const variant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'));
  const autoDesign = useAppSelector(state => props.subproduct !== null ? state.get('UIData').get('designLab').get('autoDesignSubproducts').get(props.subproduct) : undefined);
  const backgroundColor = useAppSelector(state => state.get('UIData').get('designLab').get('backgroundColor'));

  const sceneMirror = useMemo(() => {
    let subproductLayer = props.subproduct;
    if (autoDesign && props.subproductRef) {
      subproductLayer = props.subproductRef;
    }

    return mirrorModes.get((subproductLayer ? subproductLayer + '-' : '') + props.scene);
  }, [props.subproduct, props.subproductRef, autoDesign, mirrorModes]);

  const { mirrorData, subproductRef_mirrorData } = useMemo(() => {
    if (!sceneMirror) return {};

    return {
      mirrorData: getMirrorData(sceneMirror, props.labData.variants[variant], props.scene),
      subproductRef_mirrorData: props.subproductRef_labData ? getMirrorData(sceneMirror, props.subproductRef_labData.variants[variant], props.scene) : undefined,
    }
  }, [sceneMirror, props.labData, variant, props.scene]);

  const renderLayer = useCallback((layerId: string) => {
    return <LayerRenderer
      key={layerId}
      layerId={layerId}
      subproduct={props.subproduct}
      subproductRef={props.subproductRef}
      scene={props.scene}
      part={mirrorData.sourcePart}
      partData={mirrorData.source}
      subproductRef_partData={subproductRef_mirrorData?.source}
      inputType={props.inputType}
      outputType={props.outputType}
      labData={props.labData}
    />
  }, [props.labData, props.subproduct, props.subproductRef, subproductRef_mirrorData, mirrorData, props.scene, props.inputType, props.outputType])

  if (!sceneMirror || !mirrorData) return null;

  const partData = mirrorData.source[props.outputType];
  const outputData = mirrorData.output[props.outputType];

  let clipX = 0,
    clipY = 0,
    clipWidth = partData.width,
    clipHeight = partData.height,
    rotation = partData.rotation ? partData.rotation : 0;

  //Crop the part so it doesn't overflow into other parts
  if (partData.crop) {
    clipX = partData.crop.x;
    clipY = partData.crop.y;
    clipWidth = partData.crop.width;
    clipHeight = partData.crop.height;
  }

  let outputClipX = 0,
    outputClipY = 0,
    outputClipWidth = outputData.width,
    outputClipHeight = outputData.height,
    outputRotation = outputData.rotation ? outputData.rotation : 0;

  if (outputData.crop) {
    outputClipX = outputData.crop.x;
    outputClipY = outputData.crop.y;
    outputClipWidth = outputData.crop.width;
    outputClipHeight = outputData.crop.height;
  }

  //Flips
  const flipX = [1, 2, 5, 6].includes(sceneMirror) || ([3, 4].includes(sceneMirror) && props.sceneData.rotate_fit) ? -1 : 1;
  const flipY = ([3, 4].includes(sceneMirror) && props.sceneData.rotate_fit) ? -1 : 1;

  let x = outputData.x + outputClipWidth / 2 - outputClipX * flipX;
  let y = outputData.y + outputClipHeight / 2 - outputClipY * flipY;

  //TODO: Replace this with proper math that works for all rotations
  if (outputRotation === -90) {
    x = outputData.x + outputClipHeight / 2 - outputClipY * flipY;
    y = outputData.y + outputClipWidth / 2 + outputClipX * flipX;
  }
  if (outputRotation === 90) {
    x = outputData.x + outputClipHeight / 2 + outputClipY * flipY;
    y = outputData.y + outputClipWidth / 2 - outputClipX * flipX;
  }

  let scaleX = mirrorData.source[props.outputType].width / mirrorData.source[props.inputType].width;
  let scaleY = mirrorData.source[props.outputType].height / mirrorData.source[props.inputType].height;

  return <LayerNode
    key={props.scene + '_mirror' + String(sceneMirror)}
    x={x}
    y={y}
    offsetX={outputClipWidth / 2}
    offsetY={outputClipHeight / 2}
    clipX={outputClipX}
    clipY={outputClipY}
    clipWidth={outputClipWidth}
    clipHeight={outputClipHeight}
    rotation={outputRotation}
    scaleX={flipX}
    scaleY={flipY}
    listening={false}
  >
    <GroupNode
      x={outputClipWidth / 2}
      y={outputClipHeight / 2}
      offsetX={clipWidth / 2}
      offsetY={clipHeight / 2}
      clipX={clipX}
      clipY={clipY}
      clipWidth={clipWidth}
      clipHeight={clipHeight}
      //rotation={rotation}
      listening={false}
    >
      {!props.labData.transparent_texture ? <Rect
        width={partData.width}
        height={partData.height}
        x={0}
        y={0}
        fill={backgroundColor ? backgroundColor : "#fff"}
      /> : null}
      <GroupNode
        scaleX={scaleX}
        scaleY={scaleY}
        listening={false}
      >
        {visibleLayers.keySeq().map(renderLayer)}
      </GroupNode>
    </GroupNode>
  </LayerNode>
}