import { InputLabel as MuiInputLabel, InputLabelProps as MuiInputLabelProps, styled } from '@mui/material';

const StyledLabel = styled(MuiInputLabel)({
    marginBottom: '5px',
    color: '#000',
});

export type LabelV2Props = MuiInputLabelProps & {}

export default function LabelV2(props: LabelV2Props) {
    return <StyledLabel 
        {...props} 
    />
}