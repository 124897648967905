import * as React from 'react';
import Eid from '../../Common/Utils/ElementId';
import AwareComponent from "../_components/AwareComponent";

export default function ScrollBlock<T>(Component:React.ComponentType<T>) {
    type Props = {
        opened: boolean
        registerScrollBlock: (blockerID:string) => void
        unregisterScrollBlock: (blockerID:string) => void
    }

    return class extends AwareComponent<T & Props> {
        private id:string;

        constructor(props:T & Props) {
            super(props);
            this.id = Eid.new("scrollBlock");
        }

        componentDidMount(): void {
            this.updateScrollBlock();
        }

        componentWillUnmount(): void {
            this.props.unregisterScrollBlock(this.id);
        }

        private updateScrollBlock = () => {
            if(this.props.opened) {
                this.props.registerScrollBlock(this.id);
            } else {
                this.props.unregisterScrollBlock(this.id);
            }
        }

        componentDidUpdate(prevProps: Readonly<T & Props>): void {
            if(this.propChanged(prevProps, 'opened')) this.updateScrollBlock();
        }

        render = () => <Component {...this.props} />
    }
}