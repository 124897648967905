import Dialog from '../../Common/Dialog/Dialog'
import type Immutable from 'immutable'
import { type ProductItem } from '../../UserData/_stores/UserCartStore'
import OrderDialogContent from './OrderDialogContent'

interface Props {
  productDesignId?: string
  item?: ProductItem
  onConfirm?: (listOptions: Immutable.Map<number, number>, listBrandingOptions: Immutable.Map<number, number>) => void
  selectedOptions?: Array<[number, number]>
  selectedBrandingOptions?: Array<[number, number]>
  opened: boolean
  onClose: () => void
}

export default function OrderDialog(props: Props) {
  return <Dialog
    open={props.opened}
    onClose={props.onClose}
    maxWidth="lg"
    showCloseButton
  >
    <OrderDialogContent
      {...props}
    />
  </Dialog>
}
