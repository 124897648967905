import * as Immutable from 'immutable';

export type UserStorePagePayload = {
	id: string
	is_enabled: boolean
	title: string
	url_slug: string
}

export interface IUserStorePageStore {
	id: string,
	title: string,
}

const defaultStore = Immutable.Record<IUserStorePageStore>({
	id: "",
	title: "",
});

export default class UserStorePageStore extends defaultStore implements IUserStorePageStore {}

