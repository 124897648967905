import { DialogContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LabLoader from "../../_components/LabLoader";

type Props = {
}

export default function UpscaleGenerating(props:Props) {
    const [t] = useTranslation('design-lab');

    return <>
        <DialogContent
            style={{
                padding: '60px 100px',
                textAlign: 'center',
            }}
        >
            <Typography
                variant="h2" 
                style={{
                    marginBottom: '10px',
                }}
            >{ t('Scaling up your image with AI!') }</Typography>
            <Typography
                variant="body1"
                style={{
                    marginBottom: '30px',
                }}
            >{ t('We\'re using machine learning technology to scale up your image. Our little robots will analyze your image and use different shapes to produce a scaled up version of your image. Since this is not a perfect science, we\'ll give you a chance to review the result and an option to try again using a different AI model.') }</Typography>

            <LabLoader />

            <img src={require('@resources/img/create/design-lab-v4/product-illustration.svg?url')} />
        </DialogContent>
    </>
}