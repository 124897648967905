import { useTranslation } from "react-i18next";
import CheckoutOverview from "../Checkout/Overview/CheckoutOverview";
import UserCartStore from "../UserData/_stores/UserCartStore";
import { useMemo } from "react";
import Immutable from "immutable";
import CheckoutShipping from "../Checkout/Shipping/CheckoutShipping";
import CheckoutPayment from "../Checkout/Payment/CheckoutPayment";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../Common/_hooks/useAppSelector";

export default function DashboardOrderPagePayment() {
  const [tR] = useTranslation('routes')
  const params = useParams<{ storeSlug: string, refId: string }>()

  const order = useAppSelector(state => params.refId ? state.get('userData').get('orders').get('data').find(value => value.get('ref_id') === params.refId) : undefined)

  const orders = useMemo(() => {
    return order ? Immutable.OrderedMap({[order.get('id')]: order}) : Immutable.OrderedMap<string, UserCartStore>()
  }, [order])

  if(!order) return null

  return <CheckoutPayment
    orders={orders}
    confirmationUrl={tR('/d/{{store}}/orders/{{ref_id}}', {store: params.storeSlug, ref_id: order.get('ref_id')})}
  />
}